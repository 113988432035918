import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IndividualNeighborhood from './IndividualNeighborhood';

const IndividualRegion = props => {
  const [showNeighborhoods, setShowNeighborhoods] = useState(false);

  const {
    region,
    selected,
    handleSelect,
    hoodFilter,
  } = props;

  const {
    selectedNeighborhoods,
    selectedRegions,
  } = selected;

  const selectedRegionalNeighborhoods = selectedNeighborhoods
    .filter(neighborhood => neighborhood.region === region.name);
  const regionSelected = selectedRegions.filter(_region => _region.name === region.name).length > 0;
  const partiallySelected = selectedRegionalNeighborhoods.length > 0;

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.currentTarget.click();
    }
  };

  return (
    <>
      <div className="d-flex position-relative">
        <div
          role="button"
          tabIndex={0}
          data-name={region.name}
          className={`${regionSelected || partiallySelected ? 'matrix-checkbox-checked' : 'matrix-checkbox'} ${hoodFilter.length ? 'disabled' : ''} ${partiallySelected ? '' : ''}`}
          onMouseDown={() => handleSelect(region.name, 'regions')}
        >
          <span className={regionSelected ? 'checkmark' : ''} />
          <span className={partiallySelected ? 'partial-checkmark ' : ''} />
        </div>
        <div style={{
          cursor: 'pointer', userSelect: 'none', margin: '3px 8px',
        }}
        >
          <span
            role="button"
            tabIndex={0}
            style={{ fontSize: '14px' }}
            onMouseDown={() => handleSelect(region.name, 'regions')}
          >
            {region.name}
          </span>
          <div className="d-flex flex-wrap" style={{ marginLeft: '-0.5rem' }}>
            {showNeighborhoods
              && region.neighborhoods
              && region.neighborhoods.map(neighborhood => (
                <IndividualNeighborhood
                  key={`Location-${region.name}-${neighborhood.id}`}
                  id={neighborhood.id}
                  neighborhood={neighborhood}
                  regionSelected={regionSelected}
                  selected={selected}
                  handleSelect={handleSelect}
                />
              ))}
          </div>
        </div>
        { !showNeighborhoods ? (
          <div
            role="button"
            tabIndex={0}
            style={{
              position: 'absolute', right: 0, margin: '8px 20px', cursor: 'pointer',
            }}
            onClick={() => setShowNeighborhoods(true)}
            onKeyPress={handleKeyPress}
          >
            <i className="nookons-chevron-down ml-2" />
          </div>
        ) : (
          <div
            role="button"
            tabIndex={0}
            style={{
              position: 'absolute', right: 0, margin: '8px 20px', cursor: 'pointer',
            }}
            onClick={() => setShowNeighborhoods(false)}
            onKeyPress={handleKeyPress}
          >
            <i className="nookons-chevron-up ml-2" />
          </div>
        ) }
      </div>
      <hr style={{ margin: '5px 20px 5px 0' }} />
    </>
  );
};

IndividualRegion.propTypes = {
  region: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    neighborhoods: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
  }).isRequired,
  selected: PropTypes.shape({
    selectedRegions: PropTypes.arrayOf(PropTypes.shape({})),
    selectedNeighborhoods: PropTypes.arrayOf(PropTypes.shape({
      region: PropTypes.string,
    })),
  }).isRequired,
  hoodFilter: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default IndividualRegion;
