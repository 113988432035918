import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateAgentMatches } from 'reducers/agentTypeahead';
import SingleAgent from './SingleAgent';
import SinglePropertyOwner from './SinglePropertyOwner';

class AgentDropdown extends Component {

  render() {
    const { agentMatches, agentType } = this.props;
    return (
        <div
          className='agent-dropdown-container'
          style={{
            height: '300px',
            width: '325px',
            position: 'relative',
            backgroundColor: '#000',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
      >
        {agentType !== 'propertyOwner' && agentMatches.length > 0 && agentMatches.map(agent => <SingleAgent agentType={agentType} key={agent.id} {...agent} />)}
        {agentType === 'propertyOwner' && agentMatches.length > 0 && agentMatches.map(agent => <SinglePropertyOwner agentType={agentType} key={agent.id} {...agent} />)}
    </div>
    );
  }
}

AgentDropdown.propTypes = {
  _updateAgentMatches: PropTypes.func,
};

AgentDropdown.defaultProps = {
  _updateAgentMatches: () => {},
};

const mapStateToProps = (state, ownProps) => ({
  agentMatches: state.agentTypeahead[ownProps.agentType].agentMatches || [],
});


const mapDispatchToProps = (dispatch, ownProps) => ({
  _updateAgentMatches: () => dispatch(updateAgentMatches(ownProps.agentType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentDropdown);
