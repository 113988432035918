import React from 'react'
import HelloSign from 'hellosign-embedded'
import axios from 'axios'
import { getMetaContent } from '../../helpers/util'
import { SkipDisclosureButton } from '../../containers/SkipDisclosureButton'

const client = new HelloSign({
  clientId: process.env.DROPBOX_CLIENT_ID,
})

export default function JumbotronDisclosure(props) {
  const {
    rental_application,
    lease_client,
    fetchRentalApp,
    submitForm,
    lease_client_id,
    fetchLeaseClient,
    setMessage,
    submitted,
  } = props

  const token = rental_application ? rental_application.token : lease_client.rental_application_id

  const [isProcessing, setIsProcessing] = React.useState(false)
  const [isSigned, setIsSigned] = React.useState(false)

  const cancelToken = axios.CancelToken.source().token

  React.useEffect(() => {
    client.on('sign', async (data) => {
      if (data.signatureId && !isSigned) {
        await axios
          .post(
            `/rental_applications/${token}/sign_disclosure`,
            { authenticity_token: getMetaContent('csrf-token') },
            {
              cancelToken,
            }
          )
          .then((res) => {
            if (res.data && res.data?.message === 'Signed') {
              fetchRentalApp()

              setTimeout(() => {
                window.location.reload(true)
              }, 300)
            }

            setIsSigned(true)
          })
      }
    })
  }, [token, cancelToken, fetchRentalApp, isSigned])

  const handleLaunchHelloSign = React.useCallback(async () => {
    if (isProcessing) {
      return
    }

    setIsProcessing(true)
    axios
      .post(
        `/rental_applications/${token}/review_disclosure`,
        { authenticity_token: getMetaContent('csrf-token') },
        {
          cancelToken,
        }
      )
      .then((res) => {
        if (res.data && res.data.url) {
          client.open(res.data.url, {})
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsProcessing(false)
      })
  }, [token, cancelToken, isProcessing])

  return (
    <div>
      <h1>NYS Disclosure Forms</h1>
      <p>
        In order to proceed, New York State requires that you acknowledge the following forms. These are required forms
        to complete, but they are not contracts.
      </p>

      <div className="row justify-content-start mt-2">
        <button onClick={handleLaunchHelloSign} className="button btn-rounded btn-transparent-yellow">
          Review
        </button>

        <SkipDisclosureButton
          {...{
            token,
            rental_application,
            lease_client,
            fetchRentalApp,
            submitForm,
            lease_client_id,
            fetchLeaseClient,
            setMessage,
            submitted,
          }}
        />
      </div>
    </div>
  )
}
