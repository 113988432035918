import React, { useEffect } from 'react';
import ContainerWrapper from './ContainerWrapper';
import { Stepper } from './Stepper';
import VerifyModal from './VerifyPassbase';

const SummaryBox = (props) => {
  const {
    active, completed, disabled, children, handleEdit, handleStart, summaryDisabled,
  } = props;
  const classes = `${active ? ' active' : ''}${completed ? ' completed' : ''}${
    disabled ? ' disabled' : ''
  }`;

  return (
    <div className={`summary-box${classes}`}>
      {active && <i className="step-icon-active nookons-marker-fill" />}
      {completed && <i className="step-icon-completed nookons-check-circle-fill" />}
      {disabled && <i className="step-icon-disabled nookons-lock-fill" />}
      <div className="summary-text">{children}</div>
      {active && (
        <button
          disabled={summaryDisabled}
          onClick={handleStart}
          className="button btn-transparent-yellow btn-block btn-rounded"
        >
          Start
        </button>
      )}
      {completed && (
        <button
          disabled={summaryDisabled}
          onClick={handleEdit}
          className="button btn-transparent-white btn-block btn-rounded"
        >
          Edit
        </button>
      )}
    </div>
  );
};

const VerifySummaryBox = (props) => {
  const {
    active, completed, disabled, children, summaryDisabled, currentUser,
  } = props;
  const classes = `${active ? ' active' : ''}${completed ? ' completed' : ''}${
    disabled ? ' disabled' : ''
  }`;

  return (
    <div className={`summary-box${classes}`}>
      {active && <i className="step-icon-active nookons-marker-fill" />}
      {completed && <i className="step-icon-completed nookons-check-circle-fill" />}
      {disabled && <i className="step-icon-disabled nookons-lock-fill" />}
      <div className="summary-text">{children}</div>
      {active && currentUser && (
        <VerifyModal agentId={currentUser.agent_id}>
          <button
            disabled={summaryDisabled}
            className="button btn-transparent-yellow btn-block btn-rounded"
          >
            Start
          </button>
        </VerifyModal>
      )}
    </div>
  );
};

const SummaryStepper = (props) => {
  const {
    steps, step, setActiveStep, setShowSummary, lastActiveStep,
  } = props;

  switch (step) {
    case 'Verify':
      return (
        <VerifySummaryBox {...props}>
          <h6>Verify Identity</h6>
          <span>
            We have a 0 tolerance policy for creepiness. So we need to check your ID before you can
            list your sublet on Nooklyn.
          </span>
        </VerifySummaryBox>
      );
    case 'Apartment':
      return (
        <SummaryBox
          {...props}
          handleEdit={() => {
            setActiveStep(steps.indexOf('Apartment'));
            setShowSummary(false);
          }}
          handleStart={() => {
            setActiveStep(lastActiveStep);
            setShowSummary(false);
          }}
        >
          <h6>The Apartment</h6>
          <span>
            We’ll ask you a set of questions about the apartment. This is key - renters want to know
            details about the apartment and building.
          </span>
        </SummaryBox>
      );
    case 'Photos':
      return (
        <SummaryBox
          {...props}
          handleEdit={() => {
            setActiveStep(steps.indexOf('Photos'));
            setShowSummary(false);
          }}
          handleStart={() => {
            setActiveStep(lastActiveStep);
            setShowSummary(false);
          }}
        >
          <h6>Upload Photos</h6>
          <span>Take a couple photos of the space including the bedrooms.</span>
        </SummaryBox>
      );
    case 'Rooms':
      return (
        <SummaryBox
          {...props}
          handleEdit={() => {
            setActiveStep(steps.indexOf('Rooms'));
            setShowSummary(false);
          }}
          handleStart={() => {
            setActiveStep(lastActiveStep);
            setShowSummary(false);
          }}
        >
          <h6>The Rooms</h6>
          <span>
            You’ll create ads for vacant rooms and optionally invite your current roommates.
          </span>
        </SummaryBox>
      );
    case 'Submit':
      return (
        <SummaryBox
          {...props}
          handleStart={() => {
            setActiveStep(lastActiveStep);
            setShowSummary(false);
          }}
        >
          <h6>Approval</h6>
          <span>
            A human at Nooklyn will verify all the details and ensure your ads comply with housing
            laws and our community guidelines.
          </span>
        </SummaryBox>
      );
    default:
      return <div />;
  }
};

const Summary = (props) => {
  const { activeStep, steps, lastActiveStep } = props;

  useEffect(() => {
    document.title = 'Create: Listings';
  }, []);

  return (
    <ContainerWrapper>
      <Stepper
        activeStep={activeStep}
        lastActiveStep={lastActiveStep}
        connector={false}
        orientation="vertical"
      >
        {!!steps.length
          && steps.map(label => <SummaryStepper key={label} step={label} {...props} />)}
      </Stepper>
    </ContainerWrapper>
  );
};

export default Summary;
