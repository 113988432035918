import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { deepCopy, getSearch, setSearch, isSearch } from 'components/listings/search/util'

// Components
import SearchView from 'components/listings/search/SearchView'
import ListingSearchBar from 'components/listings/search/SearchBar'
import ListingMap from 'components/listings/ListingMap'

import ListingMapGoogle from 'components/listings/ListingMapGoogle'

const SearchPage = (props) => {
  const { initData, type } = props

  // Shared State Between SearchBar and SearchView
  const [selectedFilters, setSelectedFilters] = useState(initData)
  const [selectedListing, setSelectedListing] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [pageHeight, sePageHeight] = useState(
    window.innerHeight - document.getElementById('nooklyn-navbar').offsetHeight
  )

  // Handles Resizing of Search
  useEffect(() => {
    let timeoutId = null
    const resizeListener = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(
        () => sePageHeight(window.innerHeight - document.getElementById('nooklyn-navbar').offsetHeight),
        150
      )
    }
    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  // Set selectedFilters on load from initData
  useEffect(() => {
    const search = getSearch()

    if (initData) {
      Object.entries(initData).forEach(([filter, element]) => {
        search[filter] = element
      })
    }

    // Handles Url Strings to Arrays
    ;[
      'regions',
      'neighborhoods',
      'amenities',
      'bedrooms',
      'ppsf',
      'price',
      'squareFeet',
      'subways',
      'subwayStops',
      'topLeft',
      'bottomRight',
    ].forEach((filter) => {
      if (typeof search[filter] === 'string') {
        search[filter] = search[filter].split(',')
      }
    })

    // Handles Url String to Bools
    ;['noFeeOnly', 'likedOnly', 'subletOnly'].forEach((filter) => {
      if (typeof search[filter] === 'string') {
        search[filter] = search[filter] === 'true'
      }
    })

    // Handles Url String to Num
    ;['page'].forEach((filter) => {
      if (typeof search[filter] === 'string') {
        search[filter] = parseInt(search[filter], 10)
      }
    })

    setSelectedFilters(search)
  }, [initData])

  // Handles Filter Change
  const onChange = (filterType, value) => {
    const tempHoldingArray = deepCopy(selectedFilters)
    let search = isSearch() ? getSearch() : tempHoldingArray

    if (isSearch() === false && filterType === 'page') {
      search = {}
    }

    switch (filterType) {
      case 'location':
      case 'subwayStops':
      case 'map':
      case 'more':
      case 'bathAndBeds':
      case 'mobileFilter':
        Object.entries(value).forEach(([key, element]) => {
          if (value[key] && (!Array.isArray(value[key]) || value[key].length)) {
            tempHoldingArray[key] = element
            search[key] = element
          } else {
            delete tempHoldingArray[key]
            delete search[key]
          }
        })

        delete tempHoldingArray.page
        delete search.page

        setSearch(search, true, type)
        break
      case 'page':
        if (value) {
          tempHoldingArray[filterType] = value
          search[filterType] = value
        } else {
          delete tempHoldingArray[filterType]
          delete search[filterType]
        }

        setSearch(search, false, type)
        break
      case 'clearAll':
        setSearch({}, true, type)
        return setSelectedFilters({})
      default:
        if (value && (!Array.isArray(value) || value.length)) {
          tempHoldingArray[filterType] = value
          search[filterType] = value
        } else {
          delete tempHoldingArray[filterType]
          delete search[filterType]
        }

        delete tempHoldingArray.page
        delete search.page

        setSearch(search, true, type)
        break
    }

    return setSelectedFilters(tempHoldingArray)
  }

  const MapComponent = process.env.MAP_PROVIDER === 'apple' ? ListingMap : ListingMapGoogle

  return (
    <div className="nklyn-search-container">
      <ListingSearchBar type={type} selectedFilters={selectedFilters} onFilterChange={onChange} />
      <div className="listings-index-container">
        <div className="col nooklyn_listings_index_container p-0" id="nooklyn_listings_index_container">
          <div className="listings-col">
            <SearchView
              onFilterChange={onChange}
              onListingSelect={setSelectedListing}
              onSearchUpdate={setSearchResults}
              selectedFilters={selectedFilters}
              type={type}
            />
          </div>
        </div>
        <MapComponent
          isSearch
          onMapBoundChange={onChange}
          selectedBounds={{
            topLeft: selectedFilters.topLeft || undefined,
            bottomRight: selectedFilters.bottomRight || undefined,
          }}
          selectedListing={selectedListing}
          setSelectedListing={setSelectedListing}
          markers={searchResults[type]}
        />
      </div>
    </div>
  )
}

SearchPage.defaultProps = {
  initData: {},
}

SearchPage.propTypes = {
  initData: PropTypes.shape({}),
  type: PropTypes.string.isRequired,
}

export default SearchPage
