import React from 'react'

const JumbotronContinueApply = (props) => {
  const { lease_client, lease_client_type, feedback, rental_application } = props

  return (
    <div className="container">
      <h1>{lease_client_type === 'guarantor' ? `Finish Guarantor Application` : `Finish Applying`}</h1>
      <p>
        {lease_client_type === 'guarantor'
          ? `NYC apartments typically require a guarantors income to be 80x
        the monthly rent when the guarantor is providing financial
        support to pay rent, or may be called on to cover rent.
        If you are not currently employed, showing assets instead of income
        is fine. If you have any questions, feel free to reach out.`
          : `OK! Time to apply. We need to verify your identity, prove your income,
        check your credit score and run a background check.
        If you have any questions, feel free to reach out.`}
      </p>
      <a
        href={
          rental_application
            ? `/rental_applications/${rental_application.token}`
            : `/rental_applications/${lease_client.rental_application_id}`
        }
        className="button btn-rounded btn-transparent-yellow"
      >
        Continue Application
      </a>
      <a
        className="button btn-rounded btn-transparent-white"
        href={`/support?feedback[message_context]=${feedback}`}
        target="_blank"
      >
        Help
      </a>
    </div>
  )
}

export default JumbotronContinueApply
