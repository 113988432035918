import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HoodPop from '../searchPopovers/HoodPop';
import HoodButton from '../sentenceSearchButtons/HoodButton';
import Reposition from '../searchBarComponents/Reposition';

class Hood extends Component {
  getRegionRef = (ref) => {
    this.region = ref;
  }

  render() {
    const { popoverWrapper, popoverContainer, popover } = this.props;
    return (
      <div
        ref={popoverContainer}
        className="button btn-transparent"
        id="listing-neighborhood-selector"
        style={{ border: 'none' }}
      >
        <HoodPop
          ref={{
            popoverWrapper,
            popover,
          }}
          displayProp="sentenceHoodDisplay"
          searchContext="sentenceSearch"
          getRegionRef={this.getRegionRef}
        />
        <HoodButton />
      </div>
    );
  }
}

Hood.defaultProps = {
  popoverWrapper: null,
  popoverContainer: null,
  popover: null,
};

Hood.propTypes = {
  popoverWrapper: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popoverContainer: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popover: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

export default Reposition(Hood);
