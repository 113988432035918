import React from 'react';
import PropTypes from 'prop-types';
import directionImg from 'images/icons/svg/direction.svg';

const NearbyTrainStations = props => {
  const { subwayStops } = props;

  return (
    <div className="nearby-subway-station">
      <hr />
      <h5>Nearby Subway Stations</h5>
      {subwayStops.length > 0
        && subwayStops.map(stop => (
          <div className="nearby-subway-card row no-gutters" key={stop.id}>
            <div className="col-10">
              <div className="nearby-subway-name">{stop.name}</div>
              { stop.lines
                && stop.lines.length
                && stop.lines.map(line => (
                  <img key={line} className="train" alt={`${line} train`} src={line && require(`images/subway/2x/${line}`)} />
                ))}
            </div>
            <div className="col-2">
              <a href={`/subway_stops/${stop.id}`}>
                <img
                  alt="direction"
                  src={directionImg}
                  className="img-fluid mx-auto"
                  style={{ height: '42px', margin: '8px' }}
                />
              </a>
            </div>
          </div>
        ))}
    </div>
  );
};

NearbyTrainStations.propTypes = {
  subwayStops: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    name: PropTypes.string,
    lines: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
};

export default NearbyTrainStations;
