import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class BathButton extends Component {

  render() {
    const { selectedBath, togglePopover } = this.props;
    return (
      <a
        name='sentenceBath'
        onMouseDown={togglePopover}
        tabIndex="0"
        className="button btn-transparent"
        role="button"
        title="">{selectedBath} baths </a>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedBath: state.search[state.search.context].selectedBath,
});

const mapDispatchToProps = (dispatch) => ({
  togglePopover: (e) => dispatch(togglePopover(e))
});

export default connect(mapStateToProps, mapDispatchToProps)(BathButton);
