/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingFocusManager,
  useId,
} from '@floating-ui/react'

import Neighborhoods from 'components/listings/search/SearchBar/SearchBarButtons/Location/Neighborhoods'
import { useClickOutside } from 'hooks/useClickOutside'

import normalizeRegions from './normalizeRegions'

import s from './Location.module.css'

function Location(props) {
  const [showPopup, setShowPopup] = useState(false)
  const [selectedRegions, setSelectedRegions] = useState(props.selected_regions)
  const containerRef = useRef(null)
  const handleShowPopup = () => setShowPopup((state) => !state)

  useClickOutside(containerRef, () => setShowPopup(false))

  const handleChange = (changes) => {
    const regionsHiddenInput = document.querySelector('#listing-neighborhood-values')
    regionsHiddenInput.value = changes?.neighborhoods?.join(',')
    setSelectedRegions(changes?.neighborhoods)
  }

  const hasSelected = Array.isArray(selectedRegions) && selectedRegions.length
  const buttonText = hasSelected ? `Location (${selectedRegions.length})` : 'Location'

  const { refs, floatingStyles, context } = useFloating({
    open: showPopup,
    onOpenChange: setShowPopup,
    middleware: [offset(15), shift()],
    placement: 'bottom',
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role])
  const headingId = useId()

  return (
    <div ref={containerRef} className={s.root}>
      <div
        className={clsx(
          `button ${hasSelected ? 'btn-transparent-yellow' : ''} btn-transparent dropdown-toggle`,
          s.button
        )}
        onClick={handleShowPopup}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {buttonText}
      </div>

      {showPopup && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            aria-labelledby={headingId}
            className={s.popup}
            {...getFloatingProps()}
            ref={refs.setFloating}
            style={floatingStyles}
          >
            <Neighborhoods
              selected={{
                regions: [],
                neighborhoods: props.selected_regions,
              }}
              onChange={(newValue) => handleChange(newValue)}
              options={{
                regions: normalizeRegions(props.regions_sorted),
              }}
              onApply={() => setShowPopup(false)}
            />
          </div>
        </FloatingFocusManager>
      )}
    </div>
  )
}

Location.propTypes = {
  regions_sorted: PropTypes.shape({}),
}

export default Location
