import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class PriceButton extends Component {

  componentDidMount() {
    this.node.addEventListener('click', (e) => {
      e.stopPropagation();
    });
    this.node.addEventListener('mouseup', (e) => {
      e.stopPropagation();
    });
  }

  render() {

    const { togglePopover, min, max } = this.props;
    let value = 'Price';
    let finalMin = '';
    let finalMax = '';
    if (!(min === '' && max === '')) {
      finalMin += min === '' ? `: ${'0'}` : `: $${min}`;
      finalMax += max === '' ? '+' : ` - $${max}`;
    }
    value += finalMin + finalMax;
    const className = min !== '' || max !== '' ? 'btn-transparent-yellow' : 'btn-transparent-gray';
    return (
      <input
        ref={me => this.node = me}
        name='price'
        onMouseDown={togglePopover}
        type='button'
        className={`button btn-rounded btn-search-bar-size ${className}`}
        value={value}/>
    );
  }
}

const mapStateToProps = (state) =>{
  return ({
    min: state.search[state.search.context].minPrice,
    max: state.search[state.search.context].maxPrice,
  });
};

const mapDispatchToProps = (dispatch) => ({
  togglePopover: (e) => dispatch(togglePopover(e))
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceButton);
