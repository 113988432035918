
/*
  const paramsUrl = '/listings/id=[0-9]+/order=[asc]|[desc]/'
  returnRegexUrl(paramsUrl) => 'listings/[0-9]+/[asc]|[desc]'

  const currentUrl = 'listings/1094/asc'

  structureUrlVariables(paramsUrl, currentUrl) => { id: '1094', order: 'asc' }

  cleanUpUrl('/listings/') => 'listings'
*/
function extractKeysFromUrl(paramsUrl) {
  const splitParamsUrl = paramsUrl.split('/');
  // Extract the parameters out of URL and keep track of their location in URL;
  return splitParamsUrl.reduce((acc, string, index) => {
    if (string.match('=')) {
      const [token] = string.split('=');
      acc[index] = token;
    }
    return acc;
  }, {});
}

function createParamsObject(currentUrl, paramsKeys) {
  const splitUrl = currentUrl.split('/');
  // Match URL parameter values to their appropriate keys
  return splitUrl.reduce((acc, string, index) => {
    if (paramsKeys[index]) {
      acc[paramsKeys[index]] = string;
    }
    return acc;
  }, {});
}

/*
This function will be exposed inside Route and RouteChangeHandler to return requested url params.
*/
export function structureUrlVariables(paramsUrl, currentUrl) {
  // extractKeysFromUrl('listings/id=[0-9]+/order=[asc]|[desc]') => { 1: 'id', 2: 'order' }
  const paramsKeys = extractKeysFromUrl(paramsUrl);
  // createParamsObject('listings/1094/asc', {1: 'id', 2: 'order'}) => { id: '1094', order: 'asc' }
  const params = createParamsObject(currentUrl, paramsKeys);
  // Return the object of structured key value pairs
  return params;
}


export function cleanUpUrl(url) {
  let result = url;
  if (!url.startsWith('/')) {
    result = '/' + result;
  }
  if (!url.endsWith('/')) {
    result = result + '/';
  }
  return result;
}

export function returnRegexUrl(paramsUrl) {
  // ?<= is positive lookbehind, which means find where backslash occurs before the first match
  // ?! is negative lookahead, which means don't allow the backslash to repeat in the match
  // ?= is lookahead,
  // so what immediately follows the backslash should be some characters and then '='
  return paramsUrl.replace(/\/(((?!\/).)*)(?=.*)=/g, '/');
}
