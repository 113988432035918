import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AmenitiesPop from '../searchPopovers/Amenities';
import AmenitiesButton from '../sentenceSearchButtons/AmenitiesButton';
import Reposition from '../searchBarComponents/Reposition';

class Amenities extends Component {
  render() {
    const { popoverWrapper, popoverContainer, popover } = this.props;
    return (
      <div
        ref={popoverContainer}
        className="button btn-transparent"
        style={{ border: 'none' }}
        id="listing-amenity-selector"
      >
        <AmenitiesPop
          ref={{
            popoverWrapper,
            popover,
          }}
          displayProp="sentenceAmenityDisplay"
          searchContext="sentenceSearch"
        />
        <AmenitiesButton />
      </div>
    );
  }
}

Amenities.defaultProps = {
  popoverWrapper: null,
  popoverContainer: null,
  popover: null,
};

Amenities.propTypes = {
  popoverWrapper: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popoverContainer: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popover: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

export default Reposition(Amenities);
