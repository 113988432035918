import React, { PureComponent, Component, forwardRef } from 'react';
import PropTypes from 'prop-types';

const propTypeObj = {
  style: PropTypes.object,
  className: PropTypes.string,
  ['data-name']: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchMove: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseOver: PropTypes.func,
};

const defaultPropsObj = {
  style: {},
  className: '',
  children: React.createElement('div')
};

const Column = forwardRef((props, ref) => {
  const { children, className } = props;
   return (
     <div ref={ref} {...props}  className={`${className} flex-col`}>
       {children}
     </div>
   )
})

Column.propTypes = propTypeObj;
Column.defaultProps = defaultPropsObj;

const Row = forwardRef((props, ref) => {
  const { children, className } = props;
   return (
     <div ref={ref} {...props}  className={`${className} flex-row`}>
       {children}
     </div>
   )
})

Row.propTypes = propTypeObj;
Row.defaultProps = defaultPropsObj;

class Field extends PureComponent {

  render() {
    const { style, className, children, noBorder } = this.props;
    return(
      <Row
        style={ noBorder ? { borderBottom:'0px' } : {} }
      >
        <Column
          style={style}
          className={className}
        >
          {children}
        </Column>
      </Row>
    );
  }
}

Field.propTypes = propTypeObj;
Field.defaultProps = defaultPropsObj;

class Modal extends PureComponent {

  render() {
    const { overlayStyle, contentStyle, overlayClass, contentClass, custom, children } = this.props;
    const defaultContentStyle = custom ? {} : {
      flexBasis: 'auto',
      flexGrow: 'auto',
      flexShrink: 'auto',
      position: 'absolute',
      width: '25%',
      height: '25%',
      top: '50%',
      bottom: '50%',
      left: '50%',
      right: '50%',
      transform: 'translate(-50%,-50%)',
      zIndex: 2001,
      border: '1px solid #ccc',
      background: '#fff',
      borderRadius: '4px',
      outline: 'none',
      padding: '0px',
    };
    const defaultOverlayStyle = custom ? {} : {
      position: 'fixed',
      zIndex: 2000,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    };
    return(
      <div
        className={overlayClass}
        style={{
          ...defaultOverlayStyle,
          ...overlayStyle,
        }}>
        <Column
          className={contentClass}
          style={{
            ...defaultContentStyle,
            ...contentStyle,
          }}>
          {children}
        </Column>
      </div>
    );
  }
}

Modal.defaultProps = {
  overlayStyle: {},
  contentStyle: {},
  overlayClass: '',
  contentClass: '',
};

export { Row, Column, Field, Modal };
