//INITIAL STATE
const initialState = {
  selectedMate: null,
};

//ACTION TYPES
const SELECT_MATE = 'SELECT_MATE';

// ACTION CREATORS
export const selectMate = (payload) => ({ type: SELECT_MATE, payload });


// REDUCER
export default function reducer( state = initialState, action){
  switch (action.type) {
  case SELECT_MATE:
    return { ...state, selectedMate: action.payload };
  default:
    return state;
  }
}
