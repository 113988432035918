import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class NoFeeButton extends Component {

  render() {
    const { val, togglePopover } = this.props;
    return (
      <a
        name='sentenceNoFee'
        onMouseDown={togglePopover}
        tabIndex="0"
        className="button btn-transparent"
        role="button"
        title="">{val}
      </a>
    );
  }
}

const mapStateToProps = (state) =>{
  const noFeeOnly = state.search[state.search.context].noFeeOnly;
  const val = noFeeOnly ? 'no fee' : 'fee/no fee';
  return ({
    val: val,
  });
};

const mapDispatchToProps = (dispatch) => ({
  togglePopover: (e) => dispatch(togglePopover(e))
});

export default connect(mapStateToProps, mapDispatchToProps)(NoFeeButton);
