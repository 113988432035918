import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CropperComponent from 'components/CropperComponent';

const CropperModalContent = props => {
  const {
    imageURL,
    onCrop,
    imageFileType,
    stopCropping,
    buttonContent,
  } = props;

  const [modalReady, setModalReady] = useState(false);

  useEffect(() => {
    setModalReady(true);
  }, []);

  return (
    <div className="modal-content cropper-modal-content">
      <button type="button" className="close" onClick={stopCropping}>
        <i className="nookons-close" />
      </button>
      {
        modalReady && (
        <CropperComponent
          onCrop={onCrop}
          imageURL={imageURL}
          imageFileType={imageFileType}
          buttonContent={buttonContent}
        />
        )
    }
    </div>
  );
};

CropperModalContent.propTypes = {
  imageURL: PropTypes.string.isRequired,
  onCrop: PropTypes.func.isRequired,
  imageFileType: PropTypes.oneOf([
    'image/png',
    'image/jpeg',
  ]).isRequired,
  stopCropping: PropTypes.func.isRequired,
  buttonContent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]).isRequired,
};

export default CropperModalContent;
