import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'wrappers/FlexTable';

const AgentTag = (props) => {
  const {
    tagValue, id, removeChosen,
  } = props;
  return (
    <Row
      style={{
        position: 'relative',
        color: '#000',
        backgroundColor: 'rgb(255, 192, 58)',
        opacity: '1',
        zIndex: 100000,
        height: '20px',
        padding: '0px',
        margin: '2px',
      }}
    >
      <input
        type="button"
        className="option-tag"
        style={{
          backgroundColor: 'rgb(255, 192, 58)',
          opacity: '1',
          color: '#000',
        }}
        value={`${tagValue}`}
        readOnly
      />
      <button
        type="button"
        data-id={id}
        style={{
          cursor: 'pointer',
          backgroundColor: 'rgb(255, 192, 58)',
          opacity: '1',
          color: '#000',
          position: 'relative',
          outline: 'none',
          border: '0px',
          lineHeight: '2.5px',
        }}
        onClick={removeChosen}
      >
      x
      </button>
    </Row>
  );
};

AgentTag.propTypes = {
  tagValue: PropTypes.string,
  id: PropTypes.number,
  removeChosen: PropTypes.func,
};

AgentTag.defaultProps = {
  tagValue: '',
  id: 0,
  removeChosen: () => {},
};

export default AgentTag;
