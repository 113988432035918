import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class SubwayButton extends Component {

  render() {
    const { selectedSubways, togglePopover } = this.props;
    let value = '';
    if (selectedSubways.length === 0) {
      value = 'these trains:';
    } else {
      value = `these trains: ${selectedSubways.join(', ')}`;
    }
    return (
      <a
        name='sentenceSubway'
        onMouseDown={togglePopover}
        tabIndex="0"
        className="button btn-transparent"
        role="button"
        title="">{value}
      </a>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedSubways: state.search[state.search.context].selectedSubways,
});

const mapDispatchToProps = (dispatch) => ({
  togglePopover: (e) => dispatch(togglePopover(e))
});

export default connect(mapStateToProps, mapDispatchToProps)(SubwayButton);
