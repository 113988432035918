import React, { useState } from 'react';
import { connect } from 'react-redux';
import { navigate, useParams } from '@reach/router';
import { Create } from 'components/listRoom/Rooms/index';
import { setMessage } from 'reducers/flashMessage';
import { useListing } from './ProStore';

const RoomAdd = (props) => {
  const { setFlashMessage } = props;

  const [create, setCreate] = useState(false);
  const { listingId } = useParams();
  const { dataLoaded, listing } = useListing(listingId);

  const submitRoom = status => {
    if (status.ok) {
      setFlashMessage('You have successfully create a new room.');
      navigate(`/pro/listing_rooms/${status.room.id}`);
    } else {
      setFlashMessage('Something went wrong. Please try again.');
      navigate('/pro/listings');
    }
  };

  return dataLoaded ? (
    <div className="pro-container">
      <div className="create-listing-container mx-auto" style={{ maxWidth: '100%' }}>
        <div className="detail-container">
          <div className="active-step-container">
            <div className="title">
              <h3>Time to Create Rooms!</h3>
              <p>You can skip this, but why would you.</p>
            </div>
            {create ? (
              <Create setCreate={setCreate} submitRoom={submitRoom} listingId={listingId} fetchedImages={listing.images} />
            ) : (
              <>
                <form>
                  <div className="room-create-container" onClick={() => setCreate(true)}>
                    <i className="nklyn-3x nookons-add" />
                    <p>Create Room</p>
                  </div>
                </form>
                <div className="row">
                  <div className="mx-auto">
                    <button
                      type="button"
                      onClick={() => {
                        navigate(`/pro/listings/${listing.id}`);
                      }}
                      className="button btn-transparent-yellow btn-rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <h1>Loading</h1>
  );
};

RoomAdd.defaultProps = {
  listing: {},
  rooms: [],
};

const mapDispatchToProps = dispatch => ({
  setFlashMessage(message) {
    dispatch(setMessage(message));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(RoomAdd);
