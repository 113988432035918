import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ListingSearchBar from 'components/listingSearchComponents/ListingSearchBar'
import RoomCollection from 'components/listings/pages/rooms/RoomCollection'
import { setReducer, initializeSearch } from 'reducers/search'
import ScreenOverlay from 'components/listingSearchComponents/ScreenOverlay'
import loggedIn from 'helpers/loggedIn'
import ListingIndexMap from 'components/listings/map/ListingIndexMap'

import RoomsMap from 'components/listings/map/googleMap/RoomsMap'

const RoomIndexContainer = (props) => {
  const { context, initialized, reducer, setReducer, setSearch } = props

  const [loading, setLoading] = useState(true)
  const [initSearch, setInitSearch] = useState(false)

  useEffect(() => {
    if (context && initialized && initSearch && loading) {
      setLoading(false)
    }
  }, [context, initialized, initSearch])

  useEffect(() => {
    setReducer(reducer)
    loggedIn().then((signedIn) => {
      if (!initSearch) {
        setSearch(signedIn).then(() => {
          setInitSearch(true)
        })
      }
    })
  }, [reducer])

  return (
    <div>
      {!loading && (
        <>
          <ListingSearchBar reducer="listings" />
          <div className="listings-index-container">
            <ScreenOverlay />
            <div className="col nooklyn_listings_index_container p-0" id="nooklyn_listings_index_container">
              <div className="listings-col">
                <RoomCollection />
              </div>
            </div>
            {process.env.MAP_PROVIDER === 'apple' ? <ListingIndexMap /> : <RoomsMap />}
          </div>
        </>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setReducer: (payload) => dispatch(setReducer(payload)),
  setSearch: async (signedIn) => dispatch(initializeSearch(signedIn)),
})

const mapStateToProps = (state) => {
  const { context } = state.search
  return {
    context,
    initialized: context && state.search[context].initialized,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomIndexContainer)
