import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';
import SortResultsByPop from '../searchPopovers/SortResultsBy';
import SortResultsByButton from '../searchbarButtons/SortResultsByButton';
import RenderOutsideContainer from './RenderOutsideContainer';
import Reposition from './Reposition';

class SortResultsBy extends Component {
  render() {
    const {
      popoverWrapper,
      popoverContainer,
      popover,
      searchContext,
    } = this.props;
    return (
      <Column
        ref={popoverContainer}
        className="searchbar-col"
      >
        <RenderOutsideContainer>
          <SortResultsByPop
            ref={{
              popoverWrapper,
              popover,
            }}
            searchContext={searchContext}
            displayProp="sortResultsByDisplay"
          />
        </RenderOutsideContainer>
        <SortResultsByButton />
      </Column>
    );
  }
}

SortResultsBy.defaultProps = {
  popoverWrapper: null,
  popoverContainer: null,
  popover: null,
};

SortResultsBy.propTypes = {
  popoverWrapper: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popoverContainer: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popover: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  searchContext: PropTypes.string.isRequired,
};

export default Reposition(SortResultsBy);
