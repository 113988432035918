import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import s from './ApplyClear.module.css'

const ApplyClear = (props) => {
  const { handleUpdate, style, children, className } = props

  const handleClick = (action) => {
    handleUpdate(action)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.currentTarget.click()
    }
  }

  return (
    <div className={clsx('searchbar-apply-clear', s.root, className)} style={style}>
      <div
        role="button"
        className="clear-button"
        tabIndex={0}
        onClick={() => {
          handleClick('clear')
        }}
        onKeyPress={handleKeyPress}
      >
        Clear
      </div>
      {children}
      <div
        role="button"
        className="apply-button"
        tabIndex={0}
        onClick={() => {
          handleClick('apply')
        }}
        onKeyPress={handleKeyPress}
      >
        Apply
      </div>
    </div>
  )
}

ApplyClear.defaultProps = {
  children: null,
  style: {},
  className: '',
}

ApplyClear.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  handleUpdate: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  className: PropTypes.string,
}

export default ApplyClear
