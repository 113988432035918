import React, { useEffect } from 'react';
import ContainerWrapper from '../ContainerWrapper';

const title = "That's it for details.";

const ReviewEdit = ({
  label, value, jumpTo, handleJump, setUserIsEditing,
}) => (
  <div className="row">
    <div className="col-11">
      <label className="m-1">{label}</label>
      <span className="float-right m-1">{value}</span>
    </div>
    <div className="col-1">
      <i
        className="nklyn-2x nookons-pencil edit"
        onClick={() => {
          handleJump(jumpTo);
          setUserIsEditing(true);
        }}
      />
    </div>
  </div>
);

const ApartmentReview = (props) => {
  const {
    beds,
    fullBaths,
    halfBaths,
    sqFootage,
    petPolicy,
    dateAvailable,
    moveOutDate,
    amenities,
    description,
    handleJump,
    handleBack,
    handleSubmit,
    setUserIsEditing,
    listingId,
    submitting,
  } = props;

  useEffect(() => {
    document.title = `Create: Apartments - ${title}`;
  }, []);

  return (
    <ContainerWrapper>
      <div>
        <div className="title">
          <h3>{title}</h3>
          <p>You can still review and update everything.</p>
        </div>
        <form
          className="summary-form"
          onKeyDown={(evt) => {
            if (evt.which === 13) evt.preventDefault();
          }}
        >
          <div className="border-box striped-box">
            <ReviewEdit
              label="Bedrooms"
              value={beds}
              jumpTo="Beds"
              handleJump={handleJump}
              setUserIsEditing={setUserIsEditing}
            />
            <ReviewEdit
              label="Bathrooms"
              value={fullBaths}
              jumpTo="Bathrooms"
              handleJump={handleJump}
              setUserIsEditing={setUserIsEditing}
            />
            <ReviewEdit
              label="Half Bath"
              value={halfBaths}
              jumpTo="Bathrooms"
              handleJump={handleJump}
              setUserIsEditing={setUserIsEditing}
            />
            <ReviewEdit
              label="Square Footage"
              value={sqFootage}
              jumpTo="SqFootage"
              handleJump={handleJump}
              setUserIsEditing={setUserIsEditing}
            />
            <ReviewEdit
              label="Pet Policy"
              value={petPolicy}
              jumpTo="Pets"
              handleJump={handleJump}
              setUserIsEditing={setUserIsEditing}
            />
            <ReviewEdit
              label="Date Available"
              value={dateAvailable}
              jumpTo="MoveInDay"
              handleJump={handleJump}
              setUserIsEditing={setUserIsEditing}
            />
            <ReviewEdit
              label="Last Day"
              value={moveOutDate}
              jumpTo="MoveOutDay"
              handleJump={handleJump}
              setUserIsEditing={setUserIsEditing}
            />
            <ReviewEdit
              label="Amenities"
              value={amenities && amenities.join(', ')}
              jumpTo="Amenities"
              handleJump={handleJump}
              setUserIsEditing={setUserIsEditing}
            />
            <ReviewEdit
              label="Description"
              value={description}
              jumpTo="Description"
              handleJump={handleJump}
              setUserIsEditing={setUserIsEditing}
            />
          </div>
          <div className="row button-row">
            <div className="col-6 button-right">
              <button
                onClick={handleBack}
                className="float-right button btn-transparent-white btn-rounded"
                disabled={submitting}
              >
                Back
              </button>
            </div>
            <div className="col-6">
              <button
                onClick={(evt) => {
                  evt.preventDefault();
                  if (listingId) {
                    handleSubmit('edit');
                  } else {
                    handleSubmit('create');
                  }
                }}
                className="button btn-transparent-yellow btn-rounded"
                disabled={submitting}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </ContainerWrapper>
  );
};

export default ApartmentReview;
