import React, { Component } from 'react';
import RoomMatrix from './RoomMatrix';

export default class RoomMatrixIndex extends Component {
  render() {
    const { rooms } = this.props;
    return(
      rooms.map(room => <RoomMatrix key={room} id={room} />)
    );
  }
}
