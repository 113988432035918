import React from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from 'wrappers/FlexTable';

// Buttons
import {
  TourButton,
  LikeButton,
  LeadModerationButton,
} from 'components/listings/detailView/InfoPanel/components';
import ToggleMatrixBtn from './ToggleMatrixBtn';
import BackBtn from './BackBtn';
import CopyBtn from './CopyBtn';

const ShortcutBar = props => {
  const {
    loading,
    selected,
    showMatrix,
    onMatrixChange,
  } = props;

  const {
    listing,
    currentUser,
  } = selected;

  const signedIn = currentUser.ok || false;
  const salesAgentID = listing.salesAgent ? listing.salesAgent.id : -1;

  return (
    <Row
      className="col-sm-12 nklyn-listing-search-bar"
    >
      <ToggleMatrixBtn
        currentUser={currentUser.current || null}
        showMatrix={showMatrix}
        onMatrixChange={onMatrixChange}
        listing={listing}
        loading={loading}
      />
      <Column className="searchbar-col">
        <TourButton
          loading={loading}
          listing={listing}
          salesAgentID={salesAgentID}
          currentUser={currentUser.current || null}
        />
      </Column>
      <Column className="searchbar-col">
        <LikeButton
          loading={loading}
          listing={listing}
          signedIn={signedIn}
        />
      </Column>
      <CopyBtn loading={loading} />
      <Column className="searchbar-col">
        {loading
          ? (
            null
          ) : (
            <LeadModerationButton
              listing={listing}
              currentUser={currentUser.current}
            />
          )}
      </Column>
    </Row>
  );
};

ShortcutBar.defaultProps = {
  loading: false,
  showMatrix: false,
  onMatrixChange: () => {},
};

ShortcutBar.propTypes = {
  loading: PropTypes.bool,
  selected: PropTypes.shape({
    listing: PropTypes.shape({
      salesAgent: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
    currentUser: PropTypes.shape({
      ok: PropTypes.bool,
      current: PropTypes.shape({}),
    }),
  }).isRequired,
  showMatrix: PropTypes.bool,
  onMatrixChange: PropTypes.func,
};

export default ShortcutBar;
