import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Column, Row } from 'wrappers/FlexTable';

const Dropdown = (props) => {
  const { children, style } = props;
  return (
    <div {...props}>
      {children}
    </div>
  );
};

Dropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
};

export default Dropdown;
