import React from 'react';
import PropTypes from 'prop-types';

const Success = props => {
  const {
    onBack,
  } = props;

  return (
    <>
      <div className="tour-success">
        <i className="nookons-check-circle" />
        <div className="tour-success-header">Your request was successfully received.</div>
        <div className="tour-notes" style={{ fontSize: '16px' }}>We will email you soon with instructions on how to proceed.</div>
        <div className="d-flex m-5">
          {(onBack !== undefined) && (
            <div className="button btn-transparent-yellow btn-rounded text-left btn-request mr-3" onClick={onBack}>
              <span>Go back</span>
              <i className="nookons-reply ml-2" id="nklyn-listing-tour-img" style={{ lineHeight: '1.7', fontWeight: '500' }} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Success.propTypes = {
  onBack: PropTypes.func,
};

Success.defaultProps = {
  onBack: undefined,
};

export default Success;
