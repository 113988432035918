import React from 'react';
import PropTypes from 'prop-types';
import ListingCard from 'components/listings/cards/Listings';
import RoomCard from 'components/listings/cards/Rooms';

const SearchCard = props => {
  const {
    listing,
    signedIn,
    handleChange,
    type,
  } = props;

  const handleMouseOver = selectedListing => {
    handleChange(selectedListing);
  };

  switch (type) {
    case 'room':
      return (
        <RoomCard
          wrapperProps={{
            onMouseOver: () => handleMouseOver(listing),
            onFocus: () => handleMouseOver(listing),
          }}
          room={listing}
          signedIn={signedIn}
        />
      );

    default:
      return (
        <ListingCard
          wrapperProps={{
            onMouseOver: () => handleMouseOver(listing),
            onFocus: () => handleMouseOver(listing),
          }}        
          listing={listing}
          signedIn={signedIn}
        />
      );
  }
};

SearchCard.defaultProps = {
  signedIn: false,
  handleChange: () => {},
};

SearchCard.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.shape({
      wide: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func,
  signedIn: PropTypes.bool,
  type: PropTypes.oneOf([
    'listing', 'room',
  ]).isRequired,
};

export default SearchCard;
