import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class HoodButton extends Component {

  render() {
    const { selectedHoods } = this.props;
    let value = '';
    if (selectedHoods.length === 0) {
      value = 'all neighborhoods';
    } else if (selectedHoods.length === 1) {
      value = selectedHoods[0].name;
    } else {
      value = `${selectedHoods.length} neighborhoods`;
    }
    return (
      <a
        name='sentenceHood'
        onMouseDown={this.props.togglePopover}
        tabIndex="0"
        className="button btn-transparent"
        role="button"
      >{value}</a>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedHoods: state.search[state.search.context].selectedHoods,
});

const mapDispatchToProps = (dispatch) => ({
  togglePopover: (e) => dispatch(togglePopover(e))
});

export default connect(mapStateToProps, mapDispatchToProps)(HoodButton);
