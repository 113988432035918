import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal/Modal';
import ScheduleTourPage from 'components/renterForms/ScheduleTour';
import { fetchCurrentAgent } from 'api/agents'

const ScheduleTourButton = props => {
  const { building, openModalType, acceptApply } = props;

  const [modalIsOpen, setModalIsOpen] = useState(openModalType == 'TourRequest');
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    setLoading(true)
    fetchCurrentAgent().then((currentUser) => {
      if (currentUser.ok) {
        setCurrentUser(currentUser.current)
      }
      setLoading(false)
    })
  }, [])

  const handleClick = () => {
    if (!loading) {
      setModalIsOpen(true)
    }
  };

  const closeModal = () => {
    setModalIsOpen(false)
  };

  const handleKeyDown = () => evt => {
    if (evt.key === 'Enter') {
      handleClick();
    }
  };
  
  return (
    <div className="lead_schedule">
      <div
        onClick={handleClick}
        className="button btn-yellow-black btn-rounded btn-search-bar-size text-left d-flex "
        style={{ justifyContent: 'space-between' }}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex="0"
      >
        <span className="d-flex">Request a tour</span>
        <i className="nookons-arrow-right d-flex ml-2" id="nklyn-listing-tour-img" style={{ lineHeight: '1.7', fontWeight: '500' }} />
      </div>
      { !loading && Object.keys(building).length ? (
        <Modal
          className="modal-dialog request-tour-modal"
          closeTimeoutMS={150}
          isOpen={ modalIsOpen }
          onRequestClose={ closeModal }
        >
          <div className="modal-content">
            <ScheduleTourPage 
              acceptApply={ acceptApply }
              property={ {
                id: building.id,
                slug: building.slug
              } }
              type="buildings"
              closeModal={ closeModal }
              currentUser={ currentUser }
            />
          </div>
        </Modal>
        ) : null
      }
    </div>
  );
};

ScheduleTourButton.propTypes = {
  building: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    slug: PropTypes.string,
  }).isRequired,
  openModalType: PropTypes.string,
  acceptApply: PropTypes.bool,
};

export default ScheduleTourButton;
