import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row } from 'wrappers/FlexTable';
import Fade from 'wrappers/Fade';
import IndividualSubway from './IndividualSubway';
import ApplyClear from './ApplyClear';

const SubwayPop = forwardRef((props, { popover, popoverWrapper }) => {
  const onMouseDown = (e) => {
    e.stopPropagation();
  };

  const { display, subways, searchContext } = props;
  return (
    <div
      ref={popoverWrapper}
      className={display ? 'popover subway-pop' : 'popover subway-pop hide'}
      style={{ position: 'fixed', width: 0, border: 'none' }}
    >
      <Fade
        customStyles={{
          color: '#fff',
          position: 'relative',
          right: '150px',
        }}
        fadeStart="expand-start"
        fadeEnd="expand-end"
        visible={display}
      >
        <div
          role="button"
          tabIndex={0}
          onMouseDown={onMouseDown}
          className="popover fade show"
          style={{
            backgroundColor: '#111',
            color: '#fff',
            willChange: 'transform',
            position: 'relative',
            padding: '0px',
          }}
        >
          <div
            ref={popover}
            className="popover-body subway-popover"
            style={{
              border: '1px solid rgb(64,64,64)',
              backgroundColor: '#111',
              color: '#fff',
              borderRadius: '0.3em',
              zIndex: '1004',
              fontSize: '12px',
            }}
          >
            <div
              className="container-fluid"
              style={{ padding: '10px 2px', backgroundColor: 'rgb(17,17,17)' }}
            >
              <div className="tab-content">
                <Row
                  style={{
                    flexWrap: 'wrap',
                    paddingLeft: '0px',
                    border: '0px',
                  }}
                >
                  {subways.map(subway => <IndividualSubway subway={subway} key={subway} />)}
                </Row>
              </div>
              {searchContext === 'searchbar' && <ApplyClear style={{ top: '10px' }} name="selectedSubways" />}
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
});

const mapStateToProps = (state, ownProps) => {
  const display = state.search[state.search.context][ownProps.displayProp];
  return ({
    display: display || false,
    subways: state.search[state.search.context].subways,
  });
};

SubwayPop.propTypes = {
  display: PropTypes.bool.isRequired,
  searchContext: PropTypes.string.isRequired,
  subways: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SubwayPop);
