function normalizeRegions(regions) {
  return regions?.reduce((result, current) => {
    const region = [
      ...result,
      {
        name: current.name,
        id: current.name,
        type: 'region',
        searchName: current.name,
      },
    ]

    return [
      ...region,
      ...current.neighborhoods?.map((neighborhood) => ({
        name: `${neighborhood.name}, ${current.name}`,
        id: String(neighborhood.id),
        secondId: String(current.name),
        type: 'neighborhood',
        searchName: neighborhood.name,
      })),
    ]
  }, [])
}

export default normalizeRegions
