/* REDUCERS */
import methods from './methods';

function reducerCreator(ApiEndpoint) {
  return function subReducer(state = {}, action) {
    const a = methods(state, action);
    switch (action.type) {
      case 'ADD_POPOVER_REF':
        return a.addPopoverRef();
      case 'CLEAR_SINGLE_POPOVER':
        return a.clearSinglePopover();
      case 'CONVERT_HOOD_SEARCH_TO_SELECTED_VALUES':
        return a.convertHoodSearchToSelectedValues();
      case 'CONVERT_SAVED_SEARCH_TO_SELECTED_VALUES':
        return a.convertSavedSearchToSelectedValues();
      case 'CONVERT_SUBWAY_STOP_TO_SELECTED_VALUES':
        return a.convertSubwayStopToSelectedValues();
      case 'FETCH_NEW_PAGE':
        return a.fetchNewPage(ApiEndpoint);
      case 'GENERATE_NEW_SEARCH':
        return a.generateNewSearch(ApiEndpoint);
      case 'HISTORY_INDUCED_SEARCH':
        return a.historyInducedSearch(ApiEndpoint, action.source);
      case 'INITALIZE_SEARCH':
        return a.initializeSearch(ApiEndpoint, action.signedIn);
      case 'REMOVE_POPOVER_REF':
        return a.removePopoverRef();
      case 'RESET_SEARCH':
        return a.resetSearch();
      case 'REVERT_SELECTED_HOODS_TO_PREVIOUS':
        return a.revertSelectedHoodsToPrevious();
      case 'SEARCH_THIS_AREA':
        return a.searchThisArea(ApiEndpoint);
      case 'SET_SELECTED_ITEM':
        return a.setSelectedItem();
      case 'SET_STORE':
        return a.setStore();
      case 'TOGGLE_ALL_FALSE':
        return a.toggleFalse();
      case 'TOGGLE_POPOVER':
        return a.togglePopover();
      case 'UPDATE_ACTIVE_SEARCH':
        return a.updateActiveSearch();
      case 'UPDATE_AMENITY_MATCHES':
        return a.updateAmenityMatches();
      case 'UPDATE_HOOD_MATCHES':
        return a.updateHoodMatches();
      case 'UPDATE_SELECTED_BEDS':
        return a.updateSelectedBeds();
      case 'UPDATE_SELECTED_HOODS':
        return a.updateSelectedHoods();
      case 'UPDATE_SELECTED_SUBWAYS':
        return a.updateSelectedSubways();
      case 'UPDATE_SUBWAY_STOP_MATCHES':
        return a.updateSubwayStopMatches();
      case 'UPDATE_SUBWAY_LINE_MATCHES':
        return a.updateSubwayLineMatches();
      case 'UPDATE_SUBWAY_LINES':
        return a.updateSubwayLines();
      case 'UPDATE_REGIONAL_HOODS':
        return a.updateRegionalHoods();
      case 'UPDATE_REGIONAL_HOOD_MATCHES':
        return a.updateRegionalHoodMatches();
      case 'UPDATE_SELECTED_REGIONS':
        return a.updateSelectedRegions();
      default:
        return state;
    }
  };
}

export default reducerCreator;
