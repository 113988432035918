import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Props:
// --------------------------------------------------
// onTransition - Callback function for moving slider (Required)
// childSize - Size of each slide (Required)
// children - Child Nodes being wrapped (Required)

// NotSoSlick's TouchWrapper:
// Wow my fingers move the slides
const TouchWrapper = props => {
  const {
    onTransition,
    childSize,
    children,
  } = props;

  const [touchStart, setTouchStart] = useState([0, 0]);
  const [touchEnd, setTouchEnd] = useState([0, 0]);
  const [dragging, setDragging] = useState(false);

  const handleTouchStart = (event, mouse) => {

    let x = 0;
    let y = 0;

    if (mouse) {
      x = event.clientX;
      y = event.screenY;
    } else {
      x = event.touches[0].clientX;
      y = event.touches[0].screenY;
    }

    setTouchStart([x, y]);
    setDragging(true);
  };

  const handleTouchMove = (event, mouse) => {
    event.preventDefault();
    event.stopPropagation();
    let x = 0;
    let y = 0;

    if (mouse) {
      x = event.clientX;
      y = event.screenY;
    } else {
      x = event.touches[0].clientX;
      y = event.touches[0].screenY;
    }

    setTouchEnd([x, y]);
  };

  const handleTouchEnd = (event, mouse) => {
    let minSwipingDist = 120;

    if (mouse) {
      minSwipingDist = childSize / 2;
    }

    setDragging(false);
    const deltaX = touchStart[0] - touchEnd[0];
    const deltaY = touchStart[1] - touchEnd[1];

    if (Math.abs(deltaX) > minSwipingDist && Math.abs(deltaY) < minSwipingDist) {
      if (deltaX < 0) {
        onTransition('prev');
      } else {
        onTransition('next');
      }
    }
  };

  return (
    <div
      tabIndex={0}
      role="menu"
      aria-hidden
      className={`slider ${dragging ? 'noScroll' : ''}`}
      onMouseDown={e => handleTouchStart(e, true)}
      onMouseMove={e => { if (dragging) { handleTouchMove(e, true); } }}
      onMouseUp={e => handleTouchEnd(e, true)}
      onTouchStart={e => handleTouchStart(e, false)}
      onTouchEnd={e => handleTouchEnd(e, false)}
      onTouchMove={e => handleTouchMove(e, false)}
    >
      {children}
    </div>
  );
};

TouchWrapper.propTypes = {
  onTransition: PropTypes.func.isRequired,
  childSize: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default TouchWrapper;
