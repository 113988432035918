import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fade from 'wrappers/Fade';
import { SubwayStopList } from './subwayStopsComponents/index';
import ApplyClear from './ApplyClear';

const SubwayStopsPop = forwardRef((props, { popover, popoverWrapper }) => {
  const onMouseDown = (e) => {
    e.stopPropagation();
  };

  const { display, searchContext, selectedSubwayStops } = props;
  return (
    <div
      ref={popoverWrapper}
      className={display ? 'popover' : 'popover hide'}
      style={{ position: 'fixed', width: 0, border: 'none' }}
    >
      <Fade
        customStyles={{
          color: '#fff',
          position: 'relative',
          right: '280px',
        }}
        fadeStart="expand-start"
        fadeEnd="expand-end"
        visible={display}
      >
        <div
          role="button"
          tabIndex={0}
          onMouseDown={onMouseDown}
          className="popover fade show"
          style={{
            border: 'none',
            backgroundColor: '#111',
            color: '#fff',
            willChange: 'transform',
            position: 'relative',
            left: '30px',
          }}
        >
          <div
            ref={popover}
            className="popover-body subway-stop-popover"
            style={{
              border: '1px solid rgb(64,64,64)',
              backgroundColor: '#111',
              color: '#fff',
              borderRadius: '0.3em',
              zIndex: '1004',
              fontSize: '12px',
              position: 'relative',
            }}
          >
            <div className="container-fluid">
              <div
                className="tab-content"
              >
                <br />
                <SubwayStopList />
                {searchContext === 'searchbar' && (
                <ApplyClear name="selectedSubwayStops">
                  <span>{!!selectedSubwayStops.length && `${selectedSubwayStops.length} Selected`}</span>
                </ApplyClear>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
});

const mapStateToProps = (state, ownProps) => {
  const display = state.search[state.search.context][ownProps.displayProp];
  return ({
    display: display || false,
    selectedSubwayStops: state.search[state.search.context].selectedSubwayStops,
  });
};

SubwayStopsPop.propTypes = {
  display: PropTypes.bool.isRequired,
  searchContext: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true },
)(SubwayStopsPop);
