import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Alert from './Alert';

class FlashMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
    };
    this.removeMessage = this.removeMessage.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      messages: [...prevState.messages, nextProps.message],
    };
  }

  removeMessage(message) {
    const filteredMessages = this.state.messages.filter(m => m != message);
    this.setState({ messages: filteredMessages });
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        transitionName="alerts"
        transitionEnter={false}
        transitionLeaveTimeout={500}
      >
        {this.state.messages.map((message, idx) => (
          <Alert
            key={idx}
            message={message}
            onClose={() => this.removeMessage(message)}
          />
        ))}
      </ReactCSSTransitionGroup>
    );
  }
}

export default FlashMessage;
