import React from 'react';
import Downshift from 'downshift';
import matchSorter from 'match-sorter';

import {
  DropDownMenu,
  DropDownItem,
  SimpleSearchStyles,
  ControllerButton,
} from './DropdownStyles';

function getFilteredOptions(filter, allItems) {
  return filter
    ? matchSorter(allItems, filter)
    : allItems;
}

const SearchDropdown = props => {
  const { onChange, placeholder, options, inputStyle, caretStyle, prefill, inputClass, beforeValue, value } = props;

  const onInputChange = e => {
    const { value } = e.target;
    const formattedValue = value.startsWith(beforeValue) ? value.replace(beforeValue, '') : value;
    onChange(formattedValue);
  };

  return (
    <SimpleSearchStyles>
      <Downshift
        onChange={onChange}
        itemToString={item => (item === null ? '' : item.text)}
        defaultHighlightedIndex={0}
        initialSelectedItem={prefill || null}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          highlightedIndex,
          getMenuProps,
          selectedItem,
          getToggleButtonProps,
          inputValue,
        }) => (
          <div className="react-dropdown">
            <div style={{ position: 'relative' }}>
              <input
                className={inputClass}
                style={{ ...inputStyle }}
                {...getInputProps({
                  placeholder: `${placeholder}`,
                  onChange: onInputChange,
                  value,
                })}
              />
              <ControllerButton
                {...getToggleButtonProps()}
                style={{ color: '#fff' }}
                tabIndex="-1"
              >
                {isOpen ? (
                  <i className="nookons-chevron-up pr-2" style={caretStyle} />
                ) : (
                  <i className="nookons-chevron-down pr-2" style={caretStyle} />
                )}
              </ControllerButton>
            </div>
            <div>
              {isOpen && (
                <DropDownMenu {...getMenuProps({ isOpen })} style={{
                  background: '#000',
                  top: '40px',
                  border: '1px solid #404040',
                  borderTop: '0',
                  borderRadius: '0 0 5px 5px',
                  color: '#fff',
                }}>
                  {getFilteredOptions(inputValue, options).map(
                    (item, index) => (
                      <DropDownItem
                        {...getItemProps({
                          item,
                          index,
                          isActive: highlightedIndex === index,
                          isSelected: selectedItem === item,
                        })}
                        key={item}
                        style={{
                          border: 'none',
                          color: '#fff',
                        }}
                      >
                        ${item}
                      </DropDownItem>
                    ),
                  )}
                </DropDownMenu>
              )}
            </div>
          </div>
        )}
      </Downshift>
    </SimpleSearchStyles>
  );
};

export default SearchDropdown;
