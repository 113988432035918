import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchAgentList } from 'api/agents';
import ButtonSelectContainer from './ButtonSelectContainer';
import AgentTypeahead from './components/AgentTypeahead';
import AgentSelectorContext from './AgentSelectorContext';

// Example Data:
// --------------------------------------------------
// initSelected = [id, value]

// Props:
// --------------------------------------------------
// button - Use Button Container instead of searchbar (Optional) (Default: false)
// initSelected - Selected Options on load (Optional) (Default: [])
// onUpdate - Callback function when this updates (Optional) (Default: () => {})
// singleSelect - Sing Selects Only (Optional) (Default: false)

const AgentSelector = props => {
  const {
    button,
    initSelected,
    onUpdate,
    singleSelect,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [agents, setAgents] = useState([]);
  const [selected, setSelected] = useState([]);

  // Loading in Agent Data based on type
  useEffect(() => {
    if (isLoading) {
      fetchAgentList()
        .then(res => {
          setAgents(res);
          setSelected([].concat(initSelected));
          setIsLoading(false);
        })
        .catch(res => {
          console.log(`AgentSelector: ${res}`);
        });
    }
  }, [isLoading, initSelected]);

  // Sending onUpdate New Data on change of selected
  useEffect(() => {
    if (!isLoading) {
      onUpdate(selected);
    }
  }, [isLoading, selected]);

  // Creating Data for our Context
  const agentSelectorData = {
    agents,
    isLoading,
    onUpdate,
    selected,
    setSelected,
    singleSelect,
  };

  return (
    <AgentSelectorContext.Provider value={agentSelectorData}>
      {button
        ? <ButtonSelectContainer />
        : <AgentTypeahead type="popover" />}
    </AgentSelectorContext.Provider>
  );
};

AgentSelector.defaultProps = {
  button: false,
  initSelected: [],
  onUpdate: () => {},
  singleSelect: false,
};

AgentSelector.propTypes = {
  button: PropTypes.bool,
  initSelected: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  ),
  onUpdate: PropTypes.func,
  singleSelect: PropTypes.bool,
};

export default AgentSelector;
