import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';
import Popover from 'components/generic/Popover';
import PricePopover from './PricePopover';

const Price = props => {
  const {
    selected,
    onChange,
    loading,
    type,
  } = props;

  const targetRef = useRef(null);
  const [value, setValue] = useState(selected);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  let buttonTxt = 'Price';
  if (!loading) {
    if (value[0] === '' || value[0] === '0') {
      if (value[1] !== '') {
        buttonTxt += ' Under ';
      }
    } else {
      buttonTxt += (value[1] !== '')
        ? ` $${value[0]} - `
        : ` $${value[0]}`;
    }

    if (value[1] === '') {
      if (value[0] !== '') {
        buttonTxt += '+';
      }
    } else {
      buttonTxt += `$${value[1]}`;
    }
  }

  const className = ((value[0] !== '' || value[1] !== '') && !loading) ? 'btn-transparent-yellow' : 'btn-transparent-gray';

  return (
    <Column className="searchbar-col">
      <Popover type="click" targetRef={targetRef}>
        <PricePopover
          value={value}
          onChange={onChange}
          loading={loading}
          targetRef={targetRef}
        />
      </Popover>
      <input
        ref={targetRef}
        name="price"
        type="button"
        className={`button btn-rounded ${className} btn-search-bar-size`}
        value={buttonTxt}
      />
    </Column>
  );
};

Price.defaultProps = {
  selected: ['', ''],
  onChange: () => {},
  loading: false,
  type: 'listings',
};

Price.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  type: PropTypes.string,
};

export default Price;
