import React from 'react';
import ListingPackageModal from './ListingPackageModal';

const AddListingPackageButton = props => (
  <ListingPackageModal {...props}>
    <div className="button btn-black-yellow btn-rounded">
      Add Concession
    </div>
  </ListingPackageModal>
);

export default AddListingPackageButton;
