import axios from 'axios';
import keysToCamel, { keysToSnake } from './util';

// API call for fetching a room
// Param(s):
// roomId - Room ID for room we want to fetch
const fetchRoom = async roomId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const roomUrl = `/api/v2/rooms.fetch?room_id=${roomId}`;

  return axios.get(roomUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.room)
    .then(room => keysToCamel(room));
};

// API call for fetching a list of rooms
// Param(s):
// roomIds - Room IDs for list of rooms we want to fetch
export const fetchListOfRooms = async roomIds => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const roomsUrl = `/api/v2/rooms.list?room_ids=${roomIds}`;

  return axios.get(roomsUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.rooms)
    .then(rooms => rooms.map(room => keysToCamel(room)));
};

// API call to pull up list of rooms that match searchParams
// Param(s):
// searchParams (Search Params Object holding filters for search):
// {
//  amenityList: Array of amenities,
//  address: Address String,
//  minBaths: Number of Minimum Bathrooms,
//  bedList: Array of Number of Bedrooms in a Listing,
//  liked: Boolean to see LikedOnly Listings,
//  marketAs: String for Listing Type To Filter,
//  moveIn: String for Move In Data,
//  noFee: Boolean to see NoFee Listings,
//  order: String for Sort By Filter,
//  pets: String for Pets Filter,
//  platformListing: Boolean to see SubletOnly Listings,
//  minPpsf: Number of Minimum Price per Square Foot,
//  maxPpsf: Number of Maximum Price per Square Foot,
//  minPrice: Number of Minimum Price,
//  maxPrice: Number of Maximum Price,
//  minSquareFeet: Number of Minimum Square Feet,
//  maxSquareFeet: Number of Maximum Square Feet,
//  network: String of Network Type for Listings to see,
//  page: Number of page number of search results to pull,
//  neighborhoodIdList: Array of Neighborhood Ids to see listings from,
//  regionIdList: Array of Region Ids to see listings from,
//  subwayIdList: Array of Subway Ids to see listings near to,
//  subwayStopList: Array of Subway Ids to see listings near to,,
//  topLeft: Array of Coordinates for Top Left of the Map,
//  bottomRight: Array of Coordinates for Bottom Right of the Map,
// }
export const searchRooms = async searchParams => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const roomsUrl = '/api/v2/rooms.search';
  const formattedSearchParams = keysToSnake(searchParams);

  return axios.post(
    roomsUrl,
    formattedSearchParams,
    { headers: { API_TOKEN: apiToken }, cancelToken },
  )
    .catch(err => ({
      okay: false,
      err,
    }));
};

export default fetchRoom;
