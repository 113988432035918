import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'wrappers/Fade';

const Amenities = props => {
  const { amenities } = props;

  if (!amenities) {
    return null;
  }

  if (amenities.length === 0) {
    return null;
  }

  return (
    <Fade>
      <h5>Amenities</h5>
      <div className="amenities">
        {
          amenities.map(amenity => <div className="amenities-item" key={amenity}>{`•  ${amenity}`}</div>)
        }
      </div>
    </Fade>
  );
};

Amenities.propTypes = {
  amenities: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Amenities;
