import { useEffect } from 'react'

const usePanToPoint = (map, point) => {
  useEffect(() => {
    if (map && point) {
      map.panTo({ lat: point?.latitude, lng: point?.longitude })
    }
  }, [point, map])
}

export default usePanToPoint
