import React from 'react';
import PropTypes from 'prop-types';
import missing from 'images/logo/gold-on-black-wide.png';

const RentedNotice = props => {
  const {
    leases,
    currentUser,
    status,
  } = props;

  const isEmployee = currentUser && currentUser.permissions && currentUser.permissions.length > 0;

  return (
    <>
      {status === 'Rented'
        && (
          <div className="rented-notice">
            This listing is currently rented
          </div>
        )}

      {isEmployee
        && (
          <>
            {leases && leases.length > 0 && <div className="lease-notice">This listing may have a lease.</div>}
            {leases && leases.length > 0
              && (
                <div className="really-dark-gray-container text-center">
                  <br />
                  {leases.map(lease => (
                    <div key={lease.id} className="new-payment-matrix-card container-fluid new-matrix-card">
                      <div className="row listing-admin-area-title-row">
                        <div className="col-sm-12">
                          <ul className="list-inline list-unstyled">
                            <li className="list-inline-item">
                              <a target="_blank" rel="noreferrer" href={`/matrix/leases/${lease.id}`}>
                                {lease.address}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row listing-admin-area-content-section">
                        <div className="col-lg-4">
                          <div className="row text-center">
                            <div className="col-sm-12">
                              <b>Sales Agent</b>
                              <br />
                              <br />
                              <a href={`/agents/${lease.salesAgent.id}`}>
                                {lease.salesAgent.avatarThumb
                                  ? <img className="rounded-circle img-fluid rounded-circle-small mx-auto" alt={lease.salesAgent.name} src={lease.salesAgent.avatarThumb} />
                                  : <img className="rounded-circle" alt={lease.salesAgent.name} src={missing} />}
                              </a>
                              <br />
                              {lease.salesAgent.name}
                              <br />
                              {lease.salesAgent.phone && lease.salesAgent.phone}
                              <small />
                              <br />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-8 listing-first-data-col">
                          <div className="row listing-admin-area-data-row">
                            <div className="col-sm-4 text-left">
                              <b>Status</b>
                            </div>
                            <div className="col-sm-8 text-left">
                              {lease.status && lease.status}
                            </div>
                          </div>
                          <div className="row listing-admin-area-data-row">
                            <div className="col-sm-4 text-left">
                              <b>Move In</b>
                            </div>
                            <div className="col-sm-8 text-left">
                              {lease.moveIn}
                            </div>
                          </div>
                          <div className="row listing-admin-area-data-row">
                            <div className="col-sm-4 text-left">
                              <b>Transactions</b>
                            </div>
                            <div className="col-sm-8 text-left">
                              {lease.transactions}
                            </div>
                          </div>
                          <div className="row listing-admin-area-data-row">
                            <div className="col-sm-4 text-left">
                              <b>Total ($)</b>
                            </div>
                            <div className="col-sm-8 text-left">
                              {lease.total}
                            </div>
                          </div>
                          <div className="row listing-admin-area-data-row">
                            <div className="col-sm-4 text-left">
                              <b>Created by</b>
                            </div>
                            <div className="col-sm-8 text-left">
                              {lease.createdBy && lease.createdBy}
                              {lease.renterCreated && (
                                <>
                                  &middot;
                                  <span className="label label-nklyn-blue">Renter</span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
          </>
        )}
    </>
  );
};

RentedNotice.defaultProps = {
  currentUser: null,
};

RentedNotice.propTypes = {
  leases: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    address: PropTypes.string,
    salesAgent: PropTypes.shape({
      avatarThumb: PropTypes.string,
      formattedPhone: PropTypes.string,
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.string,
      phone: PropTypes.string,
    }),
    status: PropTypes.string,
    moveIn: PropTypes.string,
    transactions: PropTypes.number,
    total: PropTypes.number,
    createdBy: PropTypes.string,
    renterCreated: PropTypes.bool,
  })).isRequired,
  currentUser: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  status: PropTypes.string.isRequired,
};

export default RentedNotice;
