import axios from 'axios';
import loggedIn from 'helpers/loggedIn';

// Retrieves Listings/Rooms data and if user is signed in
const fetchData = async (data, type) => {
  let apiData = [];

  const signedIn = await loggedIn();

  switch (type) {
    case 'rooms': {
      const roomsResult = await axios.get(`/api/v2/rooms.list?room_ids=${data}`, {
        headers: { API_TOKEN: localStorage.getItem('apiToken') },
        cancelToken: axios.CancelToken.source().token,
      });
      apiData = roomsResult.data.rooms;
      break;
    }
    case 'listings':
    default: {
      const listingsResult = await axios.get(`/api/v2/listings.list?listing_ids=${data}`, {
        headers: { API_TOKEN: localStorage.getItem('apiToken') },
        cancelToken: axios.CancelToken.source().token,
      });
      apiData = listingsResult.data.listings;
    }
  }

  return { data: apiData, signedIn };
};

export default fetchData;
