import React, { Component } from 'react';
import { connect } from 'react-redux';
import { truncate } from 'helpers/util';

class IndividualHood extends Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.checked !== this.props.checked) {
      return true;
    }
    return false;
  }

  render() {
    const { checked, id, hood, handleSelectHood } = this.props;

    return (
      <div
        className="individual-regional-hood"
        data-id={id}
        onMouseDown={e => handleSelectHood(id)}
      >
        <div
          data-name={id}
          style={{ cursor: 'pointer'}}
          className={`${checked ? "matrix-checkbox-checked" : "matrix-checkbox"} d-flex`}
          >
          <span className={checked ? 'checkmark' : ''} />
        </div>
        <div className="d-flex" data-name={hood.name} style={{ userSelect: 'none' }}>
          <div style={{ fontWeight: '500', fontSize: '12px', paddingLeft: '10px'}}>{truncate(hood.name, 32)}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const checked = state.search[state.search.context].selectedHoods.some(hood => hood.id === ownProps.id);
  return { checked };
};

export default connect(mapStateToProps)(IndividualHood);
