import React, { Component } from 'react';
import { connect } from 'react-redux';
import IndividualRegion from './IndividualRegion';
import { updateSelectedRegions, updateSelectedHoods, generateNewSearch, setStore, updateHoodMatches } from '../../../../reducers/search';

class HoodList extends Component {

  handleSelectRegion = (e, region) => {
    e.preventDefault();
    const { updateSelectedRegions, generateNewSearch, hoodFilter } = this.props;
    if (!hoodFilter.length) {
      updateSelectedRegions(region);
      generateNewSearch();
    }
  };

  handleSelectHood= id => {
    const { selectedHoods, updateHoodMatches, setStore, hoods, generateNewSearch } = this.props;
    // toggle
    if (selectedHoods.some(hood => hood.id === id)) {
      setStore({ selectedHoods: selectedHoods.filter(hood => hood.id !== id) });
    } else {
      setStore({ selectedHoods: [...selectedHoods, hoods.find(hood => id === hood.id)], hoodFilter: '' });
      updateHoodMatches();
    }
    generateNewSearch();
  }

  render() {
    const { regionalHoods } = this.props;
    return (
      <div className='region-hood-content hood-list'>
        { !!regionalHoods.length && regionalHoods.map(hood => (
          <IndividualRegion
            handleSelectHood={this.handleSelectHood}
            handleSelectRegion={this.handleSelectRegion}
            key={hood.region}
            hood={hood}
          />
        ))}
      </div>
    );
  }
}

HoodList.defaultProps = {
  hoods: [],
  regionalHoods: [],
};

const mapStateToProps = state => ({
  hoods: state.search[state.search.context].hoodMatches,
  regionalHoods: state.search[state.search.context].regionalHoodMatches,
  selectedRegions: state.search[state.search.context].selectedRegions,
  selectedHoods: state.search[state.search.context].selectedHoods,
  hoodFilter: state.search[state.search.context].hoodFilter,
});

const mapDispatchToProps = dispatch => ({
  setStore: payload => dispatch(setStore(payload)),
  updateHoodMatches: () => dispatch(updateHoodMatches()),
  updateSelectedHoods: hoods => dispatch(updateSelectedHoods(hoods)),
  updateSelectedRegions: hoods => dispatch(updateSelectedRegions(hoods)),
  generateNewSearch: () => dispatch(generateNewSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HoodList);
