import React, { useState, useEffect } from 'react';
import Modal from './Modal';

const ModalContent = (props) => {
  const { status, handleStatusChange, closeModal } = props;

  return (
    <div className="container invite-container">
      <h1>
        Mark as {' '} {status}
      </h1>
      <hr className="entire-width" />
      <form onSubmit={evt => handleStatusChange(evt, status)}>
        <fieldset>
          <textarea
            placeholder="Tell us why the status is changing."
            className="general-text-area limit_500"
            type="text"
            rows="6"
            name={`${status}Notes`}
            required
          />
        </fieldset>
        <br />
        <input
          type="submit"
          name="commit"
          value={`Mark as ${status}`}
          className="button btn-black-white btn-rounded"
        />
      </form>
    </div>
  );
};

const ListingStatusChangeModal = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { status } = props;
  const color = status === 'rejected' ? '#969797' : '#000';

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    console.log('closeModal');
    setModalIsOpen(false);
  };

  useEffect(() => {
    const { closeChangeStatusModal } = props;
    if (closeChangeStatusModal && modalIsOpen) {
      console.log('inside!');
      closeModal();
    }
  }, [props]);

  return (
    <>
      <a onClick={openModal} style={{ color }}>{props.children}</a>
      {modalIsOpen && (
      <Modal
        className="Modal__Bootstrap modal-dialog dark-gray-container invite-modal"
        closeTimeoutMS={150}
        isOpen={modalIsOpen}
      >
        <div className="modal-content modal-content-dark">
          <button className="close" onClick={closeModal}>
            <i className="nookons-close-circle-fill" />
          </button>
          <ModalContent {...props} closeModal={closeModal} />
        </div>
      </Modal>
      )}
    </>
  );
};

export default ListingStatusChangeModal;
