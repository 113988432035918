import React, { useState } from 'react';
import { useParams, navigate } from '@reach/router';
import { useListing, useProContext } from 'pro/ProStore';

import {
  Rent,
  MoveInCosts,
  Utilities,
  Beds,
  Bathrooms,
  SqFootage,
  Pets,
  MoveInDay,
  MoveOutDay,
  Amenities,
} from 'components/listRoom/Apartment/index';

import ApartmentDetail from './ApartmentDetail';

const wrapper = children => (
  <div className="create-listing-container mx-auto" style={{ maxWidth: '100%' }}>
    <div className="detail-container">
      <div className="active-step-container">{children}</div>
    </div>
  </div>
);

const ListingDetails = (props) => {
  const { dispatch } = useProContext();
  const [activeStep, setActiveStep] = useState('');

  const { listingId } = useParams();

  const redirectToEditListing = () => {
    setActiveStep(''); // Renders Default
  };

  const handleEdit = (attribute, value) => {
    dispatch.updateListingAttribute(listingId, attribute, value);
  };

  const { dataLoaded, listing } = useListing(listingId);

  if (!dataLoaded) {
    return (
      <h1>Loading</h1>
    );
  }

  const saveListing = () => {
    dispatch.persistListing(listingId);
    navigate(`/pro/listings/${listingId}`);
  };

  const getStepContent = (step) => {
    const {
      submitting,
      handleBack,
      handleSubmit,
    } = props;

    switch (step) {
      case 'Rent':
        return (
          wrapper(<Rent
            price={listing.price}
            setPrice={value => handleEdit('price', +value)}
            userIsEditing
            handleJump={redirectToEditListing}
          />)
        );
      case 'MoveInCosts':
        return (
          wrapper(<MoveInCosts
            moveInCosts={listing.moveInCosts}
            setMoveInCosts={value => handleEdit('moveInCosts', value)}
            userIsEditing
            handleJump={redirectToEditListing}
          />)
        );
      case 'Utilities':
        return (
          wrapper(<Utilities
            utilities={listing.utilities}
            setUtilities={value => handleEdit('utilities', value)}
            userIsEditing
            handleJump={redirectToEditListing}
          />)
        );
      case 'Beds':
        return (
          wrapper(<Beds
            beds={listing.beds}
            setBeds={value => handleEdit('beds', +value)}
            userIsEditing
            handleJump={redirectToEditListing}
          />)
        );
      case 'Bathrooms':
        return (
          wrapper(<Bathrooms
            halfBaths={listing.halfBaths}
            fullBaths={listing.fullBaths}
            setHalfBaths={value => handleEdit('halfBaths', +value)}
            setFullBaths={value => handleEdit('fullBaths', +value)}
            userIsEditing
            handleJump={redirectToEditListing}
          />)
        );
      case 'SqFootage':
        return (
          wrapper(<SqFootage
            sqFootage={listing.sqFootage}
            setSqFootage={value => handleEdit('sqFootage', +value)}
            userIsEditing
            handleJump={redirectToEditListing}
          />)
        );
      case 'Pets':
        return (
          wrapper(<Pets
            petPolicy={listing.petPolicy}
            setPetPolicy={value => handleEdit('petPolicy', value)}
            userIsEditing
            handleJump={redirectToEditListing}
          />)
        );
      case 'MoveInDay':
        return (
          wrapper(<MoveInDay
            dateAvailable={listing.dateAvailable}
            setDateAvailable={value => handleEdit('dateAvailable', value)}
            setMoveOutDate={value => handleEdit('moveOutDate', value)}
            userIsEditing
            handleJump={redirectToEditListing}
          />)
        );
      case 'MoveOutDay':
        return (
          wrapper(<MoveOutDay
            moveOutDate={listing.moveOutDate}
            setMoveOutDate={value => handleEdit('moveOutDate', value)}
            userIsEditing
            handleJump={redirectToEditListing}
          />)
        );
      case 'Amenities':
        return (
          wrapper(<Amenities
            amenities={listing.amenities}
            setAmenities={value => handleEdit('amenities', value)}
            userIsEditing
            handleJump={redirectToEditListing}
          />)
        );
      default:
        return (
          <div className="summary-container">
            <ApartmentDetail
              listing={listing}
              submitting={submitting}
              handleJump={setActiveStep}
            />
          </div>
        );
    }
  };

  return (
    <>
      <div className="pro-header" style={{ cursor: 'pointer' }}>
        <div className="pro-nav">
          <span onClick={saveListing}>
            <i className="nookons-check-circle nkyln-2x" />
            Save
          </span>
        </div>
      </div>
      <div className="pro-container">
        {getStepContent(activeStep)}
      </div>
    </>
  );
};

export default ListingDetails;
