import React from 'react';
import { Column } from 'wrappers/FlexTable';
import PropTypes from 'prop-types';

const BackBtn = props => {
  const {
    loading,
  } = props;

  const back = () => {
    if (window.history && loading === false) {
      window.history.back();
    }
  };

  return (
    <Column className="searchbar-col">
      <button
        style={{ height: '32.78px' }}
        onClick={back}
        type="button"
        className="button btn-rounded btn-black-white btn-search-bar-size"
      >
        <div
          style={{
            position: 'relative',
            right: '3px',
          }}
          className="listing-show-back-btn"
        >
          <i
            style={{
              position: 'relative',
              fontSize: 11,
              paddingRight: '3px',
            }}
            className="nookons-chevron-left"
          />
          Back
        </div>
      </button>
    </Column>
  );
};

BackBtn.defaultProps = {
  loading: false,
};

BackBtn.propTypes = {
  loading: PropTypes.bool,
};

export default BackBtn;
