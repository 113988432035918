import React from 'react';
import { connect } from 'react-redux';
import { getTrueDate } from 'helpers/util';
import { truncate } from '../../helpers/util';
import LikeButtonMate from '../../containers/LikeButtonMate';

const strftime = require('strftime');

const MatePostCard = (props) => {
  const { mate, handleClick, selected } = props;
  return (
    <div
      className={
        selected ? 'roommate_card selected' : 'roommate_card'
      }
      id={mate.id}
      onClick={evt => handleClick(evt, mate)}
    >
      <div className="roommate_image">
        {mate.profile_picture && (
          <img
            src={mate.profile_picture}
            className="lazy"
            alt={`${mate.agent.first_name} photo`}
          />
        )}
      </div>
      <div
        className="roommate_heart nooklyn_square_heart"
        id={`roommateLikeButton${mate.id}`}
      >
        <LikeButtonMate liked={mate.meta.liked} id={mate.id} agent_id={mate.agent.id} />
      </div>
      <div className="roommate-card-stub">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 roomate-name">
              {mate.agent.first_name}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <i className="nookons-buildings" />
              {truncate(mate.neighborhood.name, 23)}
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <i className="nookons-money" />
              {accounting.formatMoney(mate.price, { precision: 0 })}
            </div>
            <div className="col-6">
              <i className="nookons-calendar" />
              {strftime('%b %d', getTrueDate(mate.when))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const selected = state.roommate.selectedMate === ownProps.mate.id;
  return ({
    selected,
  });
};

export default connect(mapStateToProps)(MatePostCard);
