import React from 'react';

const StepConnector = ({ count = 5 }) => (
  <React.Fragment>
    {[...Array(count)].map((el, i) => (
      <span className="connector-dot" key={i} />
    ))}
  </React.Fragment>
);

const defaultConnector = <StepConnector />;

const Stepper = (props) => {
  const {
    activeStep = 0,
    lastActiveStep = 0,
    children,
    connector: connectorProp = defaultConnector,
    orientation = 'horizontal',
  } = props;

  const childrenArray = React.Children.toArray(children);
  const steps = childrenArray.map((step, index) => {
    const controlProps = {
      last: index + 1 === childrenArray.length,
      orientation,
    };

    const state = {
      index,
      active: false,
      completed: false,
      disabled: false,
      count: 5,
    };
    if (activeStep === index) {
      if (activeStep === lastActiveStep) state.active = true;
      if (activeStep < lastActiveStep) state.completed = true;
      if (activeStep > lastActiveStep) state.disabled = true;
    } else if (activeStep > index) {
      state.completed = true;
    } else if (activeStep < index) {
      if (index === lastActiveStep) state.active = true;
      if (index < lastActiveStep) state.completed = true;
      if (index > lastActiveStep) state.disabled = true;
    }

    // set connector count
    if (index === 1) state.count = 2;
    if (index === 2) state.count = 16;
    if (index === 3) state.count = 2;
    if (index === 4) state.count = 2;

    const connector = React.isValidElement(connectorProp) ? connectorProp : null;

    return [
      connector && index !== 0 && React.cloneElement(connector, { key: index, ...state }),
      React.cloneElement(step, { ...controlProps, ...state, ...step.props }),
    ];
  });

  return <div className={`stepper-root ${orientation}`}>{steps}</div>;
};

const StepIcon = (props) => {
  const { active, completed, disabled } = props;

  return (
    <span className="step-icon">
      {active && <i className="step-icon-active nookons-marker-fill" />}
      {completed && <i className="step-icon-completed nookons-check-circle-fill" />}
      {disabled && <i className="step-icon-disabled nookons-lock-fill" />}
    </span>
  );
};

const StepLabel = props => (
  <span className="step-label">
    <StepIcon {...props} />
    <span>{props.children}</span>
  </span>
);

export { Stepper, StepLabel };
