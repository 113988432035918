export const toggleFalse = {
  amenitiesDisplay: false,
  bathDisplay: false,
  bedDisplay: false,
  hoodDisplay: false,
  petsDisplay: false,
  moreDisplay: false,
  marketAsDisplay: false,
  priceDisplay: false,
  ppsfDisplay: false,
  squareFeetDisplay: false,
  sentenceAmenityDisplay: false,
  sentenceBathDisplay: false,
  sentenceBedDisplay: false,
  sentenceHoodDisplay: false,
  sentenceNoFeeDisplay: false,
  sentenceLikedDisplay: false,
  sentencePetsDisplay: false,
  sentenceSubwayDisplay: false,
  sortResultsByDisplay: false,
  subwayStopsDisplay: false,
  subwayDisplay: false,
  addressDisplay: false,
};

export const clearSearch = {
  allAmenitiesSelected: false,
  maxPrice: '',
  minPrice: '',
  minPpsf: '',
  maxPpsf: '',
  minSquareFeet: '',
  maxSquareFeet: '',
  moveBy: '',
  noFeeOnly: false,
  likedOnly: false,
  selectedAmenities: [],
  selectedBath: '',
  selectedBeds: [],
  selectedHoods: [],
  selectedPet: '',
  selectedMarketAs: '',
  selectedRegions: [],
  selectedSortResultsBy: '',
  selectedSubwayStops: [],
  selectedSubways: [],
  subletOnly: false,
  address: '',
};

/* INITIAL STATE */

export const createInitialState = () => ({
  ...toggleFalse,
  ...clearSearch,
  aPopoverIsOpen: false,
  amenities: [],
  amenityFilter: '',
  amenityMatches: [],
  currentUIGeneratedSearchParams: {},
  currentUIGeneratedSearchPromise: null,
  firstTime: true,
  historyInduced: false,
  hoodFilter: '',
  hoodMatches: [],
  initialized: false,
  items: [],
  lastAppliedSearchParams: {},
  loading: true,
  pets: [
    'Any',
    'All Pets Allowed',
    'Pets Upon Approval',
    'No Pets Allowed',
  ],
  marketAs: [
    'Apartment',
    'Auction',
    'Building',
    'Condo',
    'Condop',
    'Co Op',
    'Timeshare',
    'House',
    'Multi Family',
    'Townhouse',
    'Other Type',
  ],
  popoverPositionStamp: performance.now(),
  popoverRefs: [],
  searchThisArea: false,
  selectedItem: null,
  sentenceSearchDisplay: false,
  signedIn: false,
  sortResultsByOptions: [
    'relevance',
    'roommate interest',
    'price: low to high',
    'price: high to low',
    'unit number',
    'date available',
    'recently updated',
    'recently created',
  ],
  subwayStopFilter: '',
  subwayStopMatches: [],
  subwayLineMatches: [],
  regionalHoodMatches: [],
  subwayStops: [],
  hoods: [],
  subways: [],
  address: '',
  selectedRegions: [],
  selectedHoods: [],
});
