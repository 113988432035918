import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { stripNumericCharacters } from 'helpers/util';

function getLastTenDigits(phone) {
  const strippedPhone = stripNumericCharacters(phone);
  if (strippedPhone && strippedPhone.length >= 10) {
    return strippedPhone.slice(strippedPhone.length - 10, strippedPhone.length);
  }
  return '';
}

function formatDefault(phone) {
  let result = '';
  const lastTenDigits = getLastTenDigits(phone);
  if (lastTenDigits) {
    const splitPhone = lastTenDigits.match(/([\d]{3})([\d]{3})([\d]{4})/);
    result = `(${splitPhone[1]}) ${splitPhone[2]}-${splitPhone[3]}`;
  }
  return result;
}

function sanitizeInput(phone) {
  let result = '';
  if (phone) {
    const phoneArray = phone.split(/[ ?(]|[) ]|-|_+/);
    result = phoneArray.join('');
  }
  return result;
}

const PhoneNumber = (props) => {
  const {
    defaultValue,
    className,
    placeholder,
    onChange: onChangeProp,
    value: valueProp,
  } = props;
  if ((onChangeProp && !valueProp) || (!onChangeProp && valueProp)) throw new Error('must include onChange AND value props');

  const [value, setValue] = useState(formatDefault(defaultValue));
  function onChange(e) {
    const { value: newValue } = e.target;
    setValue(newValue);
  }
  return (
    <React.Fragment>
      <InputMask
        maskPlaceholder={null}
        onChange={onChangeProp || onChange}
        value={valueProp || value}
        formatChars={{ 9: '[0-9]', '?': '([0-9]{1})?' }}
        mask="(999) 999-9999"
      >
        {inputProps => <input autoComplete="tel" {...inputProps} type="tel" className={className} placeholder={placeholder} />}
      </InputMask>
      <input type="hidden" {...props} value={sanitizeInput(value)} />
    </React.Fragment>
  );
};

PhoneNumber.defaultProps = {
  className: 'general-text-field-dark',
  placeholder: '',
  defaultValue: '',
  onChange: null,
  value: null,
};

PhoneNumber.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default PhoneNumber;
