import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import strftime from 'strftime';

// Props:
// --------------------------------------------------
// intialDate: Inital Time for Form Step (Optional) (Default: undefined)
// onChange: Callback Function on Change (Required)

const DateSelection = props => {
  const {
    initialDate,
    onChange,
  } = props;

  const [preferredDays, setPreferredDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState(initialDate);

  useEffect(() => {
    const today = new Date();
    const oneDayLater = new Date(new Date().setDate(new Date().getDate() + 1));
    const twoDayLater = new Date(new Date().setDate(new Date().getDate() + 2));
    const dates = [today, oneDayLater, twoDayLater].map((date, idx) => {
      let weekday = strftime('%A', date);
      return ({
        weekday,
        date,
      });
    });
    setPreferredDays(dates);
  }, []);

  useEffect(() => { 
    onChange(selectedDate)
  }, [selectedDate])

  const changeDate = (date) => {
    if (selectedDate == date) {
      setSelectedDate(undefined)
    } else {
      setSelectedDate(date)
    }
  }

  return (
    <div className='date-selection'>
      <label>When <span className='detail'>(optional)</span></label>
      <div className="preferred-date-select">
        { preferredDays && (
          preferredDays.map(day => (
            <div className={`preferred-date-card ${selectedDate == day.date ? 'active' : ''}`} key={day.date} onClick={() => changeDate(day.date)}>
              <div className="preferred-date-card-top">
                <div className="mx-auto weekday">{day.weekday}</div>
              </div>
              <div className="preferred-date-card-bottom">
                <div className="mx-auto date">{strftime('%e', day.date)}</div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

DateSelection.propTypes = {
  initialDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
};

DateSelection.defaultProps = {
  initialDate: undefined,
};

export default DateSelection;
