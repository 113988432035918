/* eslint-disable no-restricted-syntax */
import React, { useRef, useState } from 'react'

import { useSelector } from 'react-redux'

import { mapSearchItemsSelector } from 'selectors/map'
import GoogleMap from 'components/GoogleMap/rooms/GoogleMap'
import MarkerPoint from 'components/GoogleMap/rooms/MarkerPoint'
import Annotation from 'components/GoogleMap/rooms/Annotation'
import useFitMarkerBounds from 'hooks/useFitMarkerBounds'
import useGoogleMapCluster from 'hooks/useGoogleMapCluster'
import ClusterPoint from 'components/GoogleMap/common/ClusterPoint'
import MultiMarker from 'components/GoogleMap/common/MultiMarker'

import ListingSearchThisArea from '../ListingSearchThisArea'

const Marker = ({ children }) => children

export default function RoomsMap() {
  const [loadedMap, setLoadedMap] = useState(null)
  const mapRef = useRef(null)
  const markers = useSelector(mapSearchItemsSelector)

  useFitMarkerBounds(loadedMap, markers)

  const { clusters, supercluster, onClusterMapChange, points } = useGoogleMapCluster(markers)

  return (
    <GoogleMap
      onLoaded={(map) => setLoadedMap(map)}
      ref={mapRef}
      containerChildren={<ListingSearchThisArea />}
      mapProps={{
        onChange: (data) => onClusterMapChange(data),
      }}
    >
      {clusters &&
        clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates
          const {
            cluster: isCluster,
            point_count: pointCount,
            multilisting: isMultilisting,
            markers: markersList,
          } = cluster.properties

          const marker = markersList?.[0]

          if (isCluster) {
            return (
              <Marker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
                <ClusterPoint
                  onClick={() => {
                    const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 22)
                    loadedMap.setZoom(expansionZoom)
                    loadedMap.panTo({ lat: latitude, lng: longitude })
                  }}
                  pointCount={pointCount}
                  size={points.length}
                />
              </Marker>
            )
          }

          if (isMultilisting) {
            return (
              <MultiMarker lat={latitude} lng={longitude} markersNum={markersList.length}>
                {markersList.map((multiMarker) => (
                  <Annotation isMulti marker={multiMarker} />
                ))}
              </MultiMarker>
            )
          }

          return (
            <MarkerPoint key={marker.id} marker={marker} lat={marker.latitude} lng={marker.longitude}>
              <Annotation marker={marker} />
            </MarkerPoint>
          )
        })}
    </GoogleMap>
  )
}
