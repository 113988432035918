import React, { Component } from 'react';
import { Column, Row } from 'wrappers/FlexTable';

export default class CustomCheckBox extends Component {

  shouldComponentUpdate(nextProps) {
    if (nextProps.checked !== this.props.checked) {
      return true;
    }
    return false;
  }

  render() {

    const {
      checkmarkStyle,
      checkmarkClass,
      boxcheckedStyle,
      boxcheckedClass,
      boxStyle,
      boxClass,
      onMouseEnter,
      onMouseLeave,
      onMouseOver,
      onMouseDown,
      onMouseUp,
      onClick,
      children,
      checked,
      dataName,
    } = this.props;
    const style = checked ? boxcheckedStyle : boxStyle;
    const className = checked ? boxcheckedClass : boxClass;
    return (
      <Row
        style={{ borderBottom: '0px' }}>
        <Column
          data-name={dataName}
          style={{ cursor: 'pointer', ...style }}
          className={`${className}`}
          onMouseUp={onMouseUp}
          onMouseEnter={onMouseEnter}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          onMouseDown={onMouseDown}
          onClick={onClick}
        >
          <span
            data-name={dataName}
            className={checked ? checkmarkClass : ''}
            style={checked ? checkmarkStyle : {}}
          />
        </Column>
        {children}
      </Row>
    );
  }
}
