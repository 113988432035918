import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'wrappers/Fade';
import NoticeComponent from './NoticeComponent';

class FlashNotice extends Component {
  state = {
    visible: false,
    displayed: false,
  }

  componentDidMount() {
    window.addEventListener('transitionend', this.transitionCallback);
  }

  componentDidUpdate(prevProps, prevState) {
    const { displayed } = this.state;
    const { displayed: prevDisplayed } = prevState;
    if (displayed && !prevDisplayed) {
      this.setState({
        visible: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('transitionend', this.transitionCallback);
  }

  transitionCallback = (e) => {
    const { visible } = this.state;
    if (this.fade && e.target === this.fade.el) {
      if (visible) { this.setState({ visible: false }); return; }
      this.setState({ displayed: false });
    }
  }


  flashNotice = () => {
    this.setState({ displayed: true });
  }

  render() {
    const { children, noticeComponent } = this.props;
    const { displayed, visible } = this.state;
    const { flashNotice } = this;
    return (
      <React.Fragment>
        { displayed
        && (
          <Fade
            ref={(fade) => { this.fade = fade; }}
            visible={visible}
            fadeStart="flash-start"
            fadeEnd="flash-end"
          >
            {noticeComponent}
          </Fade>
        )
      }
        {React.Children.map(children, child => React.cloneElement(
          child,
          {
            flashNotice,
          },
        ))}
      </React.Fragment>
    );
  }
}

FlashNotice.defaultProps = {
  children: null,
  noticeComponent: <NoticeComponent text="Flashing a Notice!" />,
};

FlashNotice.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  noticeComponent: PropTypes.element,
};

export default FlashNotice;
