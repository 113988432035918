import React, { useState, useEffect } from 'react';
import { useGlobalState } from 'components/listRoom/customEffects';
import PropTypes from 'prop-types';
import { ButtonStepper } from './Buttons';
import { RoomCard, Create } from './Rooms/index';
import ContainerWrapper from './ContainerWrapper';

const title = 'Time to Create Rooms!';

const Rooms = (props) => {
  const [create, setCreate] = useState(false);
  const {
    listingId, handleRoomSubmit, setShowSummary, listing,
  } = props;
  const [globalState, setGlobalState] = useGlobalState();
  const { rooms } = globalState;

  useEffect(() => {
    document.title = `Create: Rooms - ${title}`;
  }, []);

  return (
    <ContainerWrapper>
      <div>
        <div className="title">
          <h3>{title}</h3>
          <p>You can skip this, but why would you.</p>
        </div>
        {create ? (
          <Create setCreate={setCreate} listingId={listingId} setGlobalState={setGlobalState} />
        ) : (
          <React.Fragment>
            <form>
              <div className="room-create-container" onClick={() => setCreate(true)}>
                <i className="nklyn-3x nookons-add" />
                <p>Create Room</p>
              </div>
            </form>
            <div className="room-card-container">
              {!!rooms.length
                && rooms.map(room => (
                  <RoomCard key={room.id} room={room} image="" listing={listing} setGlobalState={setGlobalState} />
                ))}
            </div>
            <ButtonStepper handleNext={handleRoomSubmit} handleBack={() => setShowSummary(true)} />
          </React.Fragment>
        )}
      </div>
    </ContainerWrapper>
  );
};

Rooms.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  setShowSummary: PropTypes.func.isRequired,
  listingId: PropTypes.number.isRequired,
  setRooms: PropTypes.func.isRequired,
  handleRoomSubmit: PropTypes.func.isRequired,
  listing: PropTypes.shape({ id: PropTypes.number }),
  rooms: PropTypes.arrayOf(PropTypes.object),
};

Rooms.defaultProps = {
  rooms: [],
};

export default Rooms;
