import axios from 'axios';
import { keysToCamel } from 'pro/util/inflector';

// eslint-disable-next-line no-underscore-dangle
const _formatDate = date => {
  const [year, month, day] = date.split('-');
  return [month, day, year].join('/');
};

// eslint-disable-next-line no-underscore-dangle
const _dateFlip = date => {
  const [month, day, year] = date.split('/');
  return [day, month, year].join('/');
};

// eslint-disable-next-line no-underscore-dangle
const _formatListing = listing => ({
  id: listing.id.toString(),
  address: {
    latitude: listing.latitude,
    longitude: listing.longitude,
    full_address: listing.address,
    lineOne: listing.address_line_one,
    unit: listing.unit,
    city: listing.city,
    state: listing.state,
    zip_code: listing.zip_code,
    short: listing.short_address,
  },
  leaseTerm: listing.term,
  utilities: listing.included_utilities,
  description: listing.description,
  price: listing.price,
  moveInCosts: listing.move_in_cost,
  brokerFee: listing.broker_fee,
  beds: listing.bedrooms,
  fullBaths: listing.full_baths,
  halfBaths: listing.half_baths,
  sqFootage: listing.square_feet,
  petPolicy: listing.pets,
  dateAvailable: listing.date_available && _formatDate(listing.date_available),
  moveOutDate: listing.move_out_date && _formatDate(listing.move_out_date),
  amenities: listing.amenities && listing.amenities.split('\n'),
  neighborhood: listing.neighborhood,
  image: listing.image,
  status: listing.status,
  url: listing.url,
  subwayLines: listing.subway_line.split(' '),
});

export const deleteRoomAsync = (roomId, apiToken) => {
  const cancelToken = axios.CancelToken.source().token;
  const url = '/api/v2/rooms.delete';

  return axios.post(url, { room_id: roomId }, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data)
    .catch(err => err.response.data);
};

export const fetchUserListingsAsync = apiToken => {
  const cancelToken = axios.CancelToken.source().token;
  const url = '/api/v2/user.listings';

  return axios.get(url, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.listings)
    .then(listings => listings.filter(l => l.network === 'platform'))
    .then(listings => listings.map(l => ({ ...l, id: l.id.toString() })));
};

export const fetchListingAsync = (listingId, apiToken) => {
  const cancelToken = axios.CancelToken.source().token;

  const listingUrl = `/api/v2/listings.fetch?listing_id=${listingId}`;
  const listingRequest = axios.get(listingUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.listing)
    .then(listing => _formatListing(listing));

  const imageUrl = `/api/v2/listings.images?listing_id=${listingId}&active_only=false`;
  const imageRequest = axios.get(imageUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(imagesResult => imagesResult.data.images);

  return Promise.all([listingRequest, imageRequest])
    .then(([listing, images]) => ({ ...listing, images }));
};

export const fetchListingRoomsAsync = (listingId, apiToken) => {
  const cancelToken = axios.CancelToken.source().token;
  const url = `/api/v1/listings.rooms.list?listing_id=${listingId}&active_only=false`;

  return axios.get(url, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(roomsResult => roomsResult.data.rooms)
    .then(rooms => keysToCamel(rooms))
    .then(rooms => rooms.map(room => ({ ...room, listingId: room.listingId.toString(), id: room.id.toString() })));
};

export const fetchRoomAsync = (roomId, apiToken) => {
  const cancelToken = axios.CancelToken.source().token;
  const url = `/api/v2/rooms.fetch?room_id=${roomId}`;

  return axios.get(url, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(roomsResult => roomsResult.data.room)
    .then(rooms => keysToCamel(rooms))
    .then(room => ({ ...room, listingId: room.listingId.toString(), id: room.id.toString() }));
};

export const markRoomAsAvailableAsync = (roomId, apiToken) => {
  const cancelToken = axios.CancelToken.source().token;
  const url = '/api/v2/rooms.mark_as_available';

  return axios.post(url, { room_id: roomId }, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data)
    .catch(err => err.response.data);
};

export const markRoomAsRentedAsync = (roomId, apiToken) => {
  const cancelToken = axios.CancelToken.source().token;
  const url = '/api/v2/rooms.mark_as_rented';

  return axios.post(url, { room_id: roomId }, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data)
    .catch(err => err.response.data);
};

export const persistListingAsync = (listingId, data, apiToken) => {
  const cancelToken = axios.CancelToken.source().token;
  const url = `/api/v2/listings.edit?listing_id=${listingId}`;

  const apartment = {
    address: data.address,
    lease_term: data.leaseTerm,
    included_utilities: data.utilities,
    description: data.description,
    price: +data.price,
    move_in_costs: data.moveInCosts,
    broker_fee: data.brokerFee,
    beds: data.beds,
    full_baths: data.fullBaths,
    half_baths: data.halfBaths,
    sq_footage: data.sqFootage,
    pet_policy: data.petPolicy,
    date_available: data.dateAvailable && _dateFlip(data.dateAvailable),
    move_out_date: data.moveOutDate && _dateFlip(data.moveOutDate),
    amenities: data.amenities,
  };

  return axios.post(url, apartment, { headers: { API_TOKEN: apiToken }, cancelToken })
};
