export const stateOptions = [
  { key: 'AL', value: 'AL', text: 'AL - Alabama' },
  { key: 'AK', value: 'AK', text: 'AK - Alaska' },
  { key: 'AZ', value: 'AZ', text: 'AZ - Arizona' },
  { key: 'AR', value: 'AR', text: 'AR - Arkansas' },
  { key: 'CA', value: 'CA', text: 'CA - California' },
  { key: 'CO', value: 'CO', text: 'CO - Colorado' },
  { key: 'CT', value: 'CT', text: 'CT - Connecticut' },
  { key: 'DE', value: 'DE', text: 'DE - Delaware' },
  { key: 'DC', value: 'DC', text: 'DC - District Of Columbia' },
  { key: 'FL', value: 'FL', text: 'FL - Florida' },
  { key: 'GA', value: 'GA', text: 'GA - Georgia' },
  { key: 'HI', value: 'HI', text: 'HI - Hawaii' },
  { key: 'ID', value: 'ID', text: 'ID - Idaho' },
  { key: 'IL', value: 'IL', text: 'IL - Illinois' },
  { key: 'IN', value: 'IN', text: 'IN - Indiana' },
  { key: 'IA', value: 'IA', text: 'IA - Iowa' },
  { key: 'KS', value: 'KS', text: 'KS - Kansas' },
  { key: 'KY', value: 'KY', text: 'KY - Kentucky' },
  { key: 'LA', value: 'LA', text: 'LA - Louisiana' },
  { key: 'ME', value: 'ME', text: 'ME - Maine' },
  { key: 'MD', value: 'MD', text: 'MD - Maryland' },
  { key: 'MA', value: 'MA', text: 'MA - Massachusetts' },
  { key: 'MI', value: 'MI', text: 'MI - Michigan' },
  { key: 'MN', value: 'MN', text: 'MN - Minnesota' },
  { key: 'MS', value: 'MS', text: 'MS - Mississippi' },
  { key: 'MO', value: 'MO', text: 'MO - Missouri' },
  { key: 'MT', value: 'MT', text: 'MT - Montana' },
  { key: 'NE', value: 'NE', text: 'NE - Nebraska' },
  { key: 'NV', value: 'NV', text: 'NV - Nevada' },
  { key: 'NH', value: 'NH', text: 'NH - New Hampshire' },
  { key: 'NJ', value: 'NJ', text: 'NJ - New Jersey' },
  { key: 'NM', value: 'NM', text: 'NM - New Mexico' },
  { key: 'NY', value: 'NY', text: 'NY - New York' },
  { key: 'NC', value: 'NC', text: 'NC - North Carolina' },
  { key: 'ND', value: 'ND', text: 'ND - North Dakota' },
  { key: 'OH', value: 'OH', text: 'OH - Ohio' },
  { key: 'OK', value: 'OK', text: 'OK - Oklahoma' },
  { key: 'OR', value: 'OR', text: 'OR - Oregon' },
  { key: 'PA', value: 'PA', text: 'PA - Pennsylvania' },
  { key: 'RI', value: 'RI', text: 'RI - Rhode Island' },
  { key: 'SC', value: 'SC', text: 'SC - South Carolina' },
  { key: 'SD', value: 'SD', text: 'SD - South Dakota' },
  { key: 'TN', value: 'TN', text: 'TN - Tennessee' },
  { key: 'TX', value: 'TX', text: 'TX - Texas' },
  { key: 'UT', value: 'UT', text: 'UT - Utah' },
  { key: 'VT', value: 'VT', text: 'VT - Vermont' },
  { key: 'VA', value: 'VA', text: 'VA - Virginia' },
  { key: 'WA', value: 'WA', text: 'WA - Washington' },
  { key: 'WV', value: 'WV', text: 'WV - West Virginia' },
  { key: 'WI', value: 'WI', text: 'WI - Wisconsin' },
  { key: 'WY', value: 'WY', text: 'WY - Wyoming' },
]

export const countryOptions = [
  { key: 'af', value: 'af', text: 'Afghanistan' },
  { key: 'ax', value: 'ax', text: 'Aland Islands' },
  { key: 'al', value: 'al', text: 'Albania' },
  { key: 'dz', value: 'dz', text: 'Algeria' },
  { key: 'as', value: 'as', text: 'American Samoa' },
  { key: 'ad', value: 'ad', text: 'Andorra' },
  { key: 'ao', value: 'ao', text: 'Angola' },
  { key: 'ai', value: 'ai', text: 'Anguilla' },
  { key: 'ag', value: 'ag', text: 'Antigua' },
  { key: 'ar', value: 'ar', text: 'Argentina' },
  { key: 'am', value: 'am', text: 'Armenia' },
  { key: 'aw', value: 'aw', text: 'Aruba' },
  { key: 'au', value: 'au', text: 'Australia' },
  { key: 'at', value: 'at', text: 'Austria' },
  { key: 'az', value: 'az', text: 'Azerbaijan' },
  { key: 'bs', value: 'bs', text: 'Bahamas' },
  { key: 'bh', value: 'bh', text: 'Bahrain' },
  { key: 'bd', value: 'bd', text: 'Bangladesh' },
  { key: 'bb', value: 'bb', text: 'Barbados' },
  { key: 'by', value: 'by', text: 'Belarus' },
  { key: 'be', value: 'be', text: 'Belgium' },
  { key: 'bz', value: 'bz', text: 'Belize' },
  { key: 'bj', value: 'bj', text: 'Benin' },
  { key: 'bm', value: 'bm', text: 'Bermuda' },
  { key: 'bt', value: 'bt', text: 'Bhutan' },
  { key: 'bo', value: 'bo', text: 'Bolivia' },
  { key: 'ba', value: 'ba', text: 'Bosnia' },
  { key: 'bw', value: 'bw', text: 'Botswana' },
  { key: 'bv', value: 'bv', text: 'Bouvet Island' },
  { key: 'br', value: 'br', text: 'Brazil' },
  { key: 'vg', value: 'vg', text: 'British Virgin Islands' },
  { key: 'bn', value: 'bn', text: 'Brunei' },
  { key: 'bg', value: 'bg', text: 'Bulgaria' },
  { key: 'bf', value: 'bf', text: 'Burkina Faso' },
  { key: 'bi', value: 'bi', text: 'Burundi' },
  { key: 'tc', value: 'tc', text: 'Caicos Islands' },
  { key: 'kh', value: 'kh', text: 'Cambodia' },
  { key: 'cm', value: 'cm', text: 'Cameroon' },
  { key: 'ca', value: 'ca', text: 'Canada' },
  { key: 'cv', value: 'cv', text: 'Cape Verde' },
  { key: 'ky', value: 'ky', text: 'Cayman Islands' },
  { key: 'cf', value: 'cf', text: 'Central African Republic' },
  { key: 'td', value: 'td', text: 'Chad' },
  { key: 'cl', value: 'cl', text: 'Chile' },
  { key: 'cn', value: 'cn', text: 'China' },
  { key: 'cx', value: 'cx', text: 'Christmas Island' },
  { key: 'cc', value: 'cc', text: 'Cocos Islands' },
  { key: 'co', value: 'co', text: 'Colombia' },
  { key: 'km', value: 'km', text: 'Comoros' },
  { key: 'cg', value: 'cg', text: 'Congo Brazzaville' },
  { key: 'cd', value: 'cd', text: 'Congo' },
  { key: 'ck', value: 'ck', text: 'Cook Islands' },
  { key: 'cr', value: 'cr', text: 'Costa Rica' },
  { key: 'ci', value: 'ci', text: 'Cote Divoire' },
  { key: 'hr', value: 'hr', text: 'Croatia' },
  { key: 'cu', value: 'cu', text: 'Cuba' },
  { key: 'cy', value: 'cy', text: 'Cyprus' },
  { key: 'cz', value: 'cz', text: 'Czech Republic' },
  { key: 'dk', value: 'dk', text: 'Denmark' },
  { key: 'dj', value: 'dj', text: 'Djibouti' },
  { key: 'dm', value: 'dm', text: 'Dominica' },
  { key: 'do', value: 'do', text: 'Dominican Republic' },
  { key: 'ec', value: 'ec', text: 'Ecuador' },
  { key: 'eg', value: 'eg', text: 'Egypt' },
  { key: 'sv', value: 'sv', text: 'El Salvador' },
  { key: 'gb', value: 'gb', text: 'England' },
  { key: 'gq', value: 'gq', text: 'Equatorial Guinea' },
  { key: 'er', value: 'er', text: 'Eritrea' },
  { key: 'ee', value: 'ee', text: 'Estonia' },
  { key: 'et', value: 'et', text: 'Ethiopia' },
  { key: 'eu', value: 'eu', text: 'European Union' },
  { key: 'fk', value: 'fk', text: 'Falkland Islands' },
  { key: 'fo', value: 'fo', text: 'Faroe Islands' },
  { key: 'fj', value: 'fj', text: 'Fiji' },
  { key: 'fi', value: 'fi', text: 'Finland' },
  { key: 'fr', value: 'fr', text: 'France' },
  { key: 'gf', value: 'gf', text: 'French Guiana' },
  { key: 'pf', value: 'pf', text: 'French Polynesia' },
  { key: 'tf', value: 'tf', text: 'French Territories' },
  { key: 'ga', value: 'ga', text: 'Gabon' },
  { key: 'gm', value: 'gm', text: 'Gambia' },
  { key: 'ge', value: 'ge', text: 'Georgia' },
  { key: 'de', value: 'de', text: 'Germany' },
  { key: 'gh', value: 'gh', text: 'Ghana' },
  { key: 'gi', value: 'gi', text: 'Gibraltar' },
  { key: 'gr', value: 'gr', text: 'Greece' },
  { key: 'gl', value: 'gl', text: 'Greenland' },
  { key: 'gd', value: 'gd', text: 'Grenada' },
  { key: 'gp', value: 'gp', text: 'Guadeloupe' },
  { key: 'gu', value: 'gu', text: 'Guam' },
  { key: 'gt', value: 'gt', text: 'Guatemala' },
  { key: 'gw', value: 'gw', text: 'Guinea-Bissau' },
  { key: 'gn', value: 'gn', text: 'Guinea' },
  { key: 'gy', value: 'gy', text: 'Guyana' },
  { key: 'ht', value: 'ht', text: 'Haiti' },
  { key: 'hm', value: 'hm', text: 'Heard Island' },
  { key: 'hn', value: 'hn', text: 'Honduras' },
  { key: 'hk', value: 'hk', text: 'Hong Kong' },
  { key: 'hu', value: 'hu', text: 'Hungary' },
  { key: 'is', value: 'is', text: 'Iceland' },
  { key: 'in', value: 'in', text: 'India' },
  { key: 'io', value: 'io', text: 'Indian Ocean Territory' },
  { key: 'id', value: 'id', text: 'Indonesia' },
  { key: 'ir', value: 'ir', text: 'Iran' },
  { key: 'iq', value: 'iq', text: 'Iraq' },
  { key: 'ie', value: 'ie', text: 'Ireland' },
  { key: 'il', value: 'il', text: 'Israel' },
  { key: 'it', value: 'it', text: 'Italy' },
  { key: 'jm', value: 'jm', text: 'Jamaica' },
  { key: 'jp', value: 'jp', text: 'Japan' },
  { key: 'jo', value: 'jo', text: 'Jordan' },
  { key: 'kz', value: 'kz', text: 'Kazakhstan' },
  { key: 'ke', value: 'ke', text: 'Kenya' },
  { key: 'ki', value: 'ki', text: 'Kiribati' },
  { key: 'kw', value: 'kw', text: 'Kuwait' },
  { key: 'kg', value: 'kg', text: 'Kyrgyzstan' },
  { key: 'la', value: 'la', text: 'Laos' },
  { key: 'lv', value: 'lv', text: 'Latvia' },
  { key: 'lb', value: 'lb', text: 'Lebanon' },
  { key: 'ls', value: 'ls', text: 'Lesotho' },
  { key: 'lr', value: 'lr', text: 'Liberia' },
  { key: 'ly', value: 'ly', text: 'Libya' },
  { key: 'li', value: 'li', text: 'Liechtenstein' },
  { key: 'lt', value: 'lt', text: 'Lithuania' },
  { key: 'lu', value: 'lu', text: 'Luxembourg' },
  { key: 'mo', value: 'mo', text: 'Macau' },
  { key: 'mk', value: 'mk', text: 'Macedonia' },
  { key: 'mg', value: 'mg', text: 'Madagascar' },
  { key: 'mw', value: 'mw', text: 'Malawi' },
  { key: 'my', value: 'my', text: 'Malaysia' },
  { key: 'mv', value: 'mv', text: 'Maldives' },
  { key: 'ml', value: 'ml', text: 'Mali' },
  { key: 'mt', value: 'mt', text: 'Malta' },
  { key: 'mh', value: 'mh', text: 'Marshall Islands' },
  { key: 'mq', value: 'mq', text: 'Martinique' },
  { key: 'mr', value: 'mr', text: 'Mauritania' },
  { key: 'mu', value: 'mu', text: 'Mauritius' },
  { key: 'yt', value: 'yt', text: 'Mayotte' },
  { key: 'mx', value: 'mx', text: 'Mexico' },
  { key: 'fm', value: 'fm', text: 'Micronesia' },
  { key: 'md', value: 'md', text: 'Moldova' },
  { key: 'mc', value: 'mc', text: 'Monaco' },
  { key: 'mn', value: 'mn', text: 'Mongolia' },
  { key: 'me', value: 'me', text: 'Montenegro' },
  { key: 'ms', value: 'ms', text: 'Montserrat' },
  { key: 'ma', value: 'ma', text: 'Morocco' },
  { key: 'mz', value: 'mz', text: 'Mozambique' },
  { key: 'na', value: 'na', text: 'Namibia' },
  { key: 'nr', value: 'nr', text: 'Nauru' },
  { key: 'np', value: 'np', text: 'Nepal' },
  { key: 'an', value: 'an', text: 'Netherlands Antilles' },
  { key: 'nl', value: 'nl', text: 'Netherlands' },
  { key: 'nc', value: 'nc', text: 'New Caledonia' },
  { key: 'pg', value: 'pg', text: 'New Guinea' },
  { key: 'nz', value: 'nz', text: 'New Zealand' },
  { key: 'ni', value: 'ni', text: 'Nicaragua' },
  { key: 'ne', value: 'ne', text: 'Niger' },
  { key: 'ng', value: 'ng', text: 'Nigeria' },
  { key: 'nu', value: 'nu', text: 'Niue' },
  { key: 'nf', value: 'nf', text: 'Norfolk Island' },
  { key: 'kp', value: 'kp', text: 'North Korea' },
  { key: 'mp', value: 'mp', text: 'Northern Mariana Islands' },
  { key: 'no', value: 'no', text: 'Norway' },
  { key: 'om', value: 'om', text: 'Oman' },
  { key: 'pk', value: 'pk', text: 'Pakistan' },
  { key: 'pw', value: 'pw', text: 'Palau' },
  { key: 'ps', value: 'ps', text: 'Palestine' },
  { key: 'pa', value: 'pa', text: 'Panama' },
  { key: 'py', value: 'py', text: 'Paraguay' },
  { key: 'pe', value: 'pe', text: 'Peru' },
  { key: 'ph', value: 'ph', text: 'Philippines' },
  { key: 'pn', value: 'pn', text: 'Pitcairn Islands' },
  { key: 'pl', value: 'pl', text: 'Poland' },
  { key: 'pt', value: 'pt', text: 'Portugal' },
  { key: 'pr', value: 'pr', text: 'Puerto Rico' },
  { key: 'qa', value: 'qa', text: 'Qatar' },
  { key: 're', value: 're', text: 'Reunion' },
  { key: 'ro', value: 'ro', text: 'Romania' },
  { key: 'ru', value: 'ru', text: 'Russia' },
  { key: 'rw', value: 'rw', text: 'Rwanda' },
  { key: 'sh', value: 'sh', text: 'Saint Helena' },
  { key: 'kn', value: 'kn', text: 'Saint Kitts and Nevis' },
  { key: 'lc', value: 'lc', text: 'Saint Lucia' },
  { key: 'pm', value: 'pm', text: 'Saint Pierre' },
  { key: 'vc', value: 'vc', text: 'Saint Vincent' },
  { key: 'ws', value: 'ws', text: 'Samoa' },
  { key: 'sm', value: 'sm', text: 'San Marino' },
  { key: 'gs', value: 'gs', text: 'Sandwich Islands' },
  { key: 'st', value: 'st', text: 'Sao Tome' },
  { key: 'sa', value: 'sa', text: 'Saudi Arabia' },
  { key: 'sn', value: 'sn', text: 'Senegal' },
  { key: 'cs', value: 'cs', text: 'Serbia' },
  { key: 'rs', value: 'rs', text: 'Serbia' },
  { key: 'sc', value: 'sc', text: 'Seychelles' },
  { key: 'sl', value: 'sl', text: 'Sierra Leone' },
  { key: 'sg', value: 'sg', text: 'Singapore' },
  { key: 'sk', value: 'sk', text: 'Slovakia' },
  { key: 'si', value: 'si', text: 'Slovenia' },
  { key: 'sb', value: 'sb', text: 'Solomon Islands' },
  { key: 'so', value: 'so', text: 'Somalia' },
  { key: 'za', value: 'za', text: 'South Africa' },
  { key: 'kr', value: 'kr', text: 'South Korea' },
  { key: 'es', value: 'es', text: 'Spain' },
  { key: 'lk', value: 'lk', text: 'Sri Lanka' },
  { key: 'sd', value: 'sd', text: 'Sudan' },
  { key: 'sr', value: 'sr', text: 'Suriname' },
  { key: 'sj', value: 'sj', text: 'Svalbard' },
  { key: 'sz', value: 'sz', text: 'Swaziland' },
  { key: 'se', value: 'se', text: 'Sweden' },
  { key: 'ch', value: 'ch', text: 'Switzerland' },
  { key: 'sy', value: 'sy', text: 'Syria' },
  { key: 'tw', value: 'tw', text: 'Taiwan' },
  { key: 'tj', value: 'tj', text: 'Tajikistan' },
  { key: 'tz', value: 'tz', text: 'Tanzania' },
  { key: 'th', value: 'th', text: 'Thailand' },
  { key: 'tl', value: 'tl', text: 'Timorleste' },
  { key: 'tg', value: 'tg', text: 'Togo' },
  { key: 'tk', value: 'tk', text: 'Tokelau' },
  { key: 'to', value: 'to', text: 'Tonga' },
  { key: 'tt', value: 'tt', text: 'Trinidad' },
  { key: 'tn', value: 'tn', text: 'Tunisia' },
  { key: 'tr', value: 'tr', text: 'Turkey' },
  { key: 'tm', value: 'tm', text: 'Turkmenistan' },
  { key: 'tv', value: 'tv', text: 'Tuvalu' },
  { key: 'ug', value: 'ug', text: 'Uganda' },
  { key: 'ua', value: 'ua', text: 'Ukraine' },
  { key: 'ae', value: 'ae', text: 'United Arab Emirates' },
  { key: 'us', value: 'us', text: 'United States' },
  { key: 'uy', value: 'uy', text: 'Uruguay' },
  { key: 'um', value: 'um', text: 'Us Minor Islands' },
  { key: 'vi', value: 'vi', text: 'Us Virgin Islands' },
  { key: 'uz', value: 'uz', text: 'Uzbekistan' },
  { key: 'vu', value: 'vu', text: 'Vanuatu' },
  { key: 'va', value: 'va', text: 'Vatican City' },
  { key: 've', value: 've', text: 'Venezuela' },
  { key: 'vn', value: 'vn', text: 'Vietnam' },
  { key: 'wf', value: 'wf', text: 'Wallis and Futuna' },
  { key: 'eh', value: 'eh', text: 'Western Sahara' },
  { key: 'ye', value: 'ye', text: 'Yemen' },
  { key: 'zm', value: 'zm', text: 'Zambia' },
  { key: 'zw', value: 'zw', text: 'Zimbabwe' },
]
