import React, { Component } from 'react';

class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: 5000,
    };
  }

  componentDidMount() {
    this.timer = setTimeout(this.props.onClose, this.state.timeout);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { message } = this.props;

    return (
      <div className="alert in alert-react">
        <button className="alert-close" data-dismiss="alert">
          x
        </button>
        {message}
      </div>
    );
  }
}

export default Alert;
