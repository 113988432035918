import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomInput from 'components/CustomInput';
import { setStore, generateNewSearch } from 'reducers/search';
import { Column } from 'wrappers/FlexTable';

class MinPrice extends Component {
  onChange = value => {
    this.props.setStore({ minPrice: value });
    this.props.generateNewSearch();
  }

  render() {
    const { minPrice } = this.props;
    const value = minPrice;
    return (
      <Column
        style={{ width: '50%', padding: '10px' }}>
        <CustomInput
          onChange={this.onChange}
          className='btn-text-field btn-rounded btn-black-yellow btn-price-field'
          style={{ border: '1px solid', fontSize: '14px', width: '130px'}}
          placeholder='Min'
          value={value}
        />
      </Column>
    );
  }
}

const mapStateToProps = state => ({
  minPrice: state.search[state.search.context].minPrice,
});

const mapDispatchToProps = dispatch => ({
  setStore: payload => dispatch(setStore(payload)),
  generateNewSearch: () => dispatch(generateNewSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MinPrice);
