import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getMetaContent } from 'helpers/util';

const MatrixFieldPinned = props => {
  const {
    listing,
    myClass,
    changeField,
  } = props;

  const handleChangeField = e => {
    e.preventDefault();
    axios
      .patch(`/matrix/listings/${listing.id}/react.json`, {
        listing: { pinned: !listing.pinned },
        authenticity_token: getMetaContent('csrf-token'),
      });
    changeField(
      !listing.pinned,
      'pinned'.replace(/(\_\w)/g, text => (text[1].toUpperCase())),
    );
  };

  return (
    <div className={myClass}>
      { listing && listing.pinned ? 'True' : 'False'}
      <button
        className="button btn-rounded btn-transparent m-0 ml-2"
        type="button"
        style={{ height: '24px', lineHeight: '0' }}
        onClick={handleChangeField}
      >
        {listing.pinned ? 'Unpin' : 'Pin'}
      </button>
    </div>
  );
};

MatrixFieldPinned.propTypes = {
  changeField: PropTypes.func.isRequired,
  myClass: PropTypes.string.isRequired,
  listing: PropTypes.shape({
    id: PropTypes.number,
    pinned: PropTypes.bool,
  }).isRequired,
};

export default MatrixFieldPinned;
