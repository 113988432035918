import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setStore, updateAgentMatches, initializeAgents } from 'reducers/agentTypeahead';
import Fade from 'wrappers/Fade';
import AgentDropdown from './AgentDropdown';
import AgentFilter from './AgentFilter';
import { AsyncSubscribe } from 'asyncHandlers/AsyncBase';

class AgentPopover extends Component {

  componentDidMount() {
    window.addEventListener('click', this.clickHandler);
  }

  shouldComponentUpdate(nextProps) {
    const { agents, display, _updateAgentMatches, _initializeAgents } = this.props;
    if (agents !== nextProps.agents) {
      _initializeAgents(nextProps.agents);
      _updateAgentMatches();
    }
    if (display !== nextProps.display) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.clickHandler)
  }

  clickHandler = (e) => {
    const { _setStore } = this.props;
    _setStore({ agentDisplay: false });
  }

  onClick = (e) => {
    e.stopPropagation();
  }

  render() {
    const { display, agentType, matrixContext, popover, popoverWrapper } = this.props;
    return (
      <div
        ref={popoverWrapper}
        className = 'popover show'
        style={{ position: 'absolute', width: 0, border: 'none' }}
      >
        <Fade
          customStyles={{
            top: '3px',
            right: '165px',
            color: '#fff',
            position: 'relative',
          }}
          fadeStart='expand-start'
          fadeEnd='expand-end'
          visible={display}
        >
          <div
            onClick={this.onClick}
            className='popover fade show'
            style={{
              backgroundColor: '#111',
              color: '#fff',
              willChange: 'transform',
              position: 'relative',
              border: 'none'
            }}>
            <i className='nookons-caret-up-fill' style={{
              left: '145px',
              top: '-31.5px',
              zIndex: 1004,
              fontSize: '35px',
              position: 'absolute',
              color: 'rgb(34,34,34)',
            }}/>
            <div
              ref={popover}
              className='matrix-agent-popover'
            >
              <div>
                <div
                  className='tab-content'>
        <AgentFilter agentType={agentType} matrixContext={matrixContext} />
        <AgentDropdown agentType={agentType} />
        </div>
      </div>
          </div>
        </div>
      </Fade>
    </div>
    );
  }
}

AgentPopover.propTypes = {
  display: PropTypes.bool,
  agents: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
  _setStore: PropTypes.func.isRequired,
  _updateAgentMatches: PropTypes.func.isRequired,
  _initializeAgents: PropTypes.func.isRequired,
  popover: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popoverWrapper: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

AgentPopover.defaultProps = {
  display: false,
  agents: [],
  popoverWrapper: null,
  popover: null,
};

const mapStateToProps = (state, ownProps) => {
  const { agentType } = ownProps;
  const myState = state.agentTypeahead[agentType];
  return {
    display: myState.agentDisplay,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { agentType } = ownProps;
  return {
    _setStore: payload => dispatch(setStore(payload, agentType)),
    _updateAgentMatches: () => dispatch(updateAgentMatches(agentType)),
    _initializeAgents: payload => dispatch(initializeAgents(payload, agentType)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AsyncSubscribe(['agents'])(AgentPopover));
