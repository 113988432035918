import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const CopyTextButton = props => {
  const {
    text,
    id,
    callback,
    children,
  } = props;

  useEffect(() => {
    const clipboard = new Clipboard(`#${id}`);

    if (callback) {
      clipboard.on('success', callback);
    }
  }, [id, callback]);

  function sp(e) {
    e.stopPropagation();
  }

  return (
    <div
      role="button"
      tabIndex="0"
      onClick={sp}
      onKeyUp={sp}
      id={id}
      data-original-title="Copied"
      data-clipboard-text={text}
    >
      {children}
    </div>
  );
};

CopyTextButton.defaultProps = {
  children: null,
  text: '',
  callback: null,
};

CopyTextButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  text: PropTypes.string,
  id: PropTypes.string.isRequired,
  callback: PropTypes.func,
};

export default CopyTextButton;
