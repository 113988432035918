import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from 'components/LoadingSpinner'
import ApplyClear from 'components/listings/search/SearchBar/SearchBarButtons/ApplyClear'

import getPriceWarningText from '../../Price/getPriceWarningText'
import Price from './Price'
import s from './Filters.module.css'
import Radio from '../../common/Radio'
import Amenities from './Amenities'
import Pets from './Pets'

const Filters = (props) => {
  const { loading, selected, onChange, options } = props

  const [changes, setChanges] = useState(selected)
  const [priceWarningText, setPriceWarningText] = useState(null)

  const amenitiesSearchRef = useRef()

  const handleChangePrice = (newPrice) => {
    setChanges((state) => ({
      ...state,
      price: newPrice,
    }))
  }

  const handleChangeBeds = (newBeds) => {
    setChanges((state) => ({
      ...state,
      bedrooms: newBeds,
    }))
  }

  const handleChangeBaths = (newBaths) => {
    setChanges((state) => ({
      ...state,
      baths: newBaths,
    }))
  }

  const handleChangeFee = (fee) => {
    setChanges((state) => ({
      ...state,
      noFeeOnly: fee,
    }))
  }

  const handleChangeAmenities = (amenityId) => {
    setChanges((state) => {
      let newAmenities

      if (state.amenities?.includes(amenityId)) {
        newAmenities = state.amenities?.filter((amenitylistId) => amenitylistId !== amenityId)
      } else {
        newAmenities = [...(state.amenities || []), amenityId]
      }

      return {
        ...state,
        amenities: newAmenities,
      }
    })
  }

  const handleChangePets = (petValue) => {
    setChanges((state) => ({
      ...state,
      pets: petValue,
    }))
  }

  // Temporary solution to close Popover
  const closePopover = () => {
    document.querySelector('button[name="mobileFilter"]').click()
  }

  const handleUpdate = useCallback(
    (actionType) => {
      if (actionType === 'clear') {
        setChanges({
          price: [],
          bedrooms: [],
          baths: [],
          amenities: [],
          pets: '',
          noFeeOnly: null,
        })
        onChange({
          price: [],
          bedrooms: [],
          baths: [],
          amenities: [],
          pets: '',
          noFeeOnly: null,
        })
        amenitiesSearchRef.current.value = ''
        return
      }

      if (getPriceWarningText(changes.price || ['', ''])) {
        setPriceWarningText(getPriceWarningText(changes.price || ['', '']))
        return
      }

      onChange(changes)
      closePopover()
    },
    [changes, onChange]
  )

  return (
    <div className={s.root}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className={s.fields}>
            <div className={s.field}>
              <div className={s.fieldTitle}>What’s your budget?</div>
              <Price value={changes?.price} onChange={handleChangePrice} warningText={priceWarningText} />
            </div>

            <div className={s.field}>
              <div className={s.fieldTitle}>How many beds?</div>
              <Radio value={changes?.bedrooms} onChange={handleChangeBeds} options={options.bedrooms} />
            </div>

            <div className={s.field}>
              <div className={s.fieldTitle}>How many baths?</div>
              <Radio value={changes?.baths} onChange={handleChangeBaths} options={options.baths} />
            </div>

            <div className={s.field}>
              <div className={s.fieldTitle}>Broker Fee</div>
              <Radio
                isRadio
                value={changes?.noFeeOnly}
                onChange={handleChangeFee}
                options={[
                  { value: false, name: 'No Fee Only' },
                  { value: true, name: 'Fee+No Fee' },
                ]}
              />
            </div>

            <div className={s.field}>
              <div className={s.fieldTitle}>Pets Policy</div>
              <Pets pets={changes.pets} options={options.pets} onSelect={handleChangePets} />
            </div>

            <div className={s.field}>
              <div className={s.fieldTitle}>Amenities</div>
              <Amenities
                ref={amenitiesSearchRef}
                amenities={changes.amenities}
                options={options.amenities}
                onSelect={handleChangeAmenities}
              />
            </div>
          </div>
          <ApplyClear className={s.applyClear} handleUpdate={handleUpdate} />
        </>
      )}
    </div>
  )
}

Filters.propTypes = {
  loading: PropTypes.bool.isRequired,
  selected: PropTypes.shape({
    price: PropTypes.arrayOf(PropTypes.string),
    bedrooms: PropTypes.arrayOf(PropTypes.string),
    baths: PropTypes.arrayOf(PropTypes.string),
    amenities: PropTypes.arrayOf(PropTypes.string),
    pets: PropTypes.string,
    noFeeOnly: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    bedrooms: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    baths: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    amenities: PropTypes.arrayOf(PropTypes.shape({})),
    pets: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default Filters
