import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  __RouterContext__ as RouterContext,
  __ParamsContext__ as ParamsContext,
  __RouteChangeContext__ as RouteChangeContext,
} from './RouterContext';
import calculateParamsFromUrl from './calculateParamsFromUrl';
import { __BeforeRouteChange__ as BeforeRouteChange } from './BeforeRouteChange';

/*
  Router should be placed at the top of the component hierarchy:

  <Router>
    <ListingRoute/>
    <RoomsRoute/>
    <ListingMatrixRoute/>
  </Router>
*/

export default class Router extends PureComponent {
  // State is initialized with the current URL and its Search Query Params
  state = {
    currentPath: window.location.pathname,
    paramsObject: calculateParamsFromUrl(),
  }

  historyInduced = false;

  /* On Mount, replace current url with any title customizations
     Add event listeners for history back/forward and additions
     Update the path to dispatch down to all route consumers
   */
  componentDidMount() {
    window.history.replaceState({}, document.title, document.location.href);
    this.replaceStateCB = (e) => {
      if (e.detail.paramsOnly) {
        this.propagateParamsChange();
      } else {
        this.propagateRouteChange(true);
      }
    };
    this.pushParamsCB = () => this.propagateParamsChange();
    this.popStateCB = () => this.propagateRouteChange(true);
    this.pushStateCB = () => this.propagateRouteChange(false);
    window.addEventListener('replacestate', this.replaceStateCB);
    window.addEventListener('pushparams', this.pushParamsCB);
    window.addEventListener('popstate', this.popStateCB);
    window.addEventListener('pushstate', this.pushStateCB);
  }

  componentWillUnmount() {
    window.removeEventListener('replacestate', this.replaceStateCB);
    window.removeEventListener('pushParams', this.pushParamsCB);
    window.removeEventListener('popstate', this.popStateCB);
    window.removeEventListener('pushstate', this.pushStateCB);
  }

  beginMountUnmountPhase = () => {
    this.setPath(window.location.pathname);
  }

  beforeRouteChange = new BeforeRouteChange(this.beginMountUnmountPhase);

  propagateRouteChange = (historyInduced) => {
    this.historyInduced = historyInduced;
    if (this.beforeRouteChange.listeners.length === 0) {
      this.setPath(window.location.pathname);
      return true;
    }
    this.beforeRouteChange.propagateSideEffects(historyInduced);
    return true;
  }

  /*
    If a param already exists, reset it, if not, append it as a new param.
    If the new value is empty, delete it or return.
    Then update the window and internal state with the new params.
  */
  updateParam = (name, value) => {
    let { paramsObject } = this.state;
    if (paramsObject.get(name)) {
      if (value === '' || !value) {
        paramsObject.delete(name);
      } else {
        paramsObject.set(name, value);
      }
    } else if (value === '' || !value) {
      return;
    } else {
      paramsObject.append(name, value);
    }
    paramsObject = new URLSearchParams(paramsObject);
    const newUrl = `${window.location.path}?${paramsObject.toString()}`;
    window.history.pushState({}, document.title, newUrl);
    this.setPath();
  }

  // update params
  propagateParamsChange = () => {
    const paramsObject = calculateParamsFromUrl();
    this.setState({ paramsObject });
  }

  // Update path in internal state
  setPath = (currentPath) => {
    const paramsObject = calculateParamsFromUrl();
    this.setState({ currentPath, paramsObject });
  }

  render() {
    const { children } = this.props;
    const { currentPath, paramsObject } = this.state;
    const { historyInduced } = this;
    return (
      <RouterContext.Provider value={currentPath}>
        <ParamsContext.Provider value={{ paramsObject, historyInduced }}>
          <RouteChangeContext.Provider value={this.beforeRouteChange}>
            {children}
          </RouteChangeContext.Provider>
        </ParamsContext.Provider>
      </RouterContext.Provider>
    );
  }
}

Router.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
