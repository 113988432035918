import axios from 'axios';
import { getMetaContent } from 'helpers/util';
import { __AsyncBase__ } from './AsyncBase';

export default class SearchPopoverAsync extends __AsyncBase__ {
  get amenities() {
    if (!this._amenities) {
      this._amenities = this.fetchAmenities();
    }
    return this._amenities;
  }

  get regions() {
    if (!this._regions) {
      this._regions = this.fetchRegions();
    }
    return this._regions;
  }

  get hoods() {
    if (!this._hoods) {
      this._hoods = this.regions.then((regions) => {
        const hoodPromises = regions.map(region => this.fetchHoodsFromRegion(region));
        return Promise.all(hoodPromises).then(this.getAllHoodsDataFormatted);
      });
    }
    return this._hoods;
  }

  get subways() {
    if (!this._subways) {
      this._subways = this.fetchSubways();
    }
    return this._subways;
  }

  get subwayStops() {
    if (!this._subwayStops) {
      this._subwayStops = this.fetchSubwayStops();
    }
    return this._subwayStops;
  }

  fetchRegions = () => axios.get('/api/v1/regions', {
    headers: { API_TOKEN: localStorage.getItem('apiToken') },
    cancelToken: this.source.token,
  }).then(res => this.getRegionsDataFormatted(res.data.data))

  fetchHoodsFromRegion = region => axios.get(`/api/v1/regions/${region.id}/neighborhoods`, {
    headers: { API_TOKEN: localStorage.getItem('apiToken') },
    cancelToken: this.source.token,
  }).then(res => this.getHoodsDataFormatted(res.data.data, region))

  fetchAmenities = () => axios
    .get('/amenities.json', {
      cancelToken: this.source.token,
    })
    .then(res => res.data.map(amenity => amenity.name));

  fetchSubways = () => axios
    .get('./subway_lines.json', {
      cancelToken: this.source.token,
    })
    .then(res => res.data)

  fetchSubwayStops = () => axios.get('/subway_stops_json.json', {
    authenticity_token: getMetaContent('csrf-token'),
    cancelToken: this.source.token,
  })
    .then(res => res.data)

  getRegionsDataFormatted = regions => regions.map(
    region => ({
      ...region.attributes,
      id: region.id,
    }),
  )

  getAllHoodsDataFormatted = hoods => hoods.reduce((acc, hood) => ([...acc, ...hood]), []);

  getHoodsDataFormatted = (hoods, region) => (
    hoods.map(hood => ({ ...hood.attributes, id: hood.id, region: region.name }))
  );

  getRegionalHoodsDataFormatted = (hoods, region) => ({
    region: region.name,
    neighborhoods: hoods.map(hood => ({ ...hood.attributes, id: hood.id, region: region.name }))
      .sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      }),
  });
}
