import { useState } from 'react';

function checkForObject(obj, objectName) {
  if (typeof obj !== 'object') throw new Error(`${objectName} may only be passed an object`);
}

export default function useSetState(initialState = {}) {
  checkForObject(initialState, 'useSetState');

  const [state, _setState] = useState(initialState);

  function setState(newState) {
    checkForObject(newState, 'setState');
    _setState({ ...state, ...newState });
  }

  return [state, setState];
}
