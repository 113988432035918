import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { addCollection } from 'reducers/matrixListings';
import { setMessage } from 'reducers/flashMessage';
import { getMetaContent } from 'helpers/util';

class CreateListingCollectionModalContent extends Component {

  state={
    collectionName: '',
    clientEmail: '',
    clientPhone: '',
    notes: '',
    errors: [],
  }

  onChangeCollectionName = (e) => {
    this.setState({ collectionName: e.target.value });
  }

  onChangeClientEmail = (e) => {
    this.setState({ clientEmail: e.target.value });
  }

  onChangeClientPhone = (e) => {
    this.setState({ clientPhone: e.target.value });
  }

  onChangeNotes = (e) => {
    this.setState({ notes: e.target.value });
  }

  onSubmit = (e) => {
    e.preventDefault();
    axios.post('/listing_collections.json', {
        listing_collection: {
          agent_id: localStorage.getItem('agentId'),
          name: this.state.collectionName,
          description: this.state.notes,
          client_email: this.state.clientEmail,
          client_phone: this.state.clientPhone,
        },
        authenticity_token: getMetaContent('csrf-token')
    }).then(res => {
      this.props.addCollection(res.data)
      this.props.closeModal();
      this.props.setMessage('You successfully created a new collection!');
    }).catch(res => {
      const errors = [];
      const data = res.response.data;
      Object.keys(data).forEach(field => data[field].forEach(
        error => {
          const string = this.prettyPrintError(field, error);
          errors.push(string);
        }
      ));
      this.setState({ errors });
    });
  }

  prettyPrintError = (field, error) => {
    let string = field + ' ' + error;
    string = string.replace('_', ' ');
    string = string.charAt(0).toUpperCase() + string.slice(1);
    return string;
  }

  render() {
    const { collectionName, clientEmail, clientPhone, notes, errors } = this.state;
    const { closeModal } = this.props
    const { onChangeCollectionName, onChangeClientEmail, onChangeClientPhone, onChangeNotes } = this;
    return (
      <div className="narrow-container agent-edit-form">
        <div className="row no-gutters">
          <div className="col-sm-12">
            <div className="agent-general-settings-form">
              <form
                className="new_listing_collection"
                id="new_listing_collection"
                action="/listing_collections"
                acceptCharset="UTF-8"
                method="post">
                {errors.map(error => <p key={String(error)} style={{color: '#dd4a38'}} className="error">{String(error)}</p>)}
                <div className="field">
                  <label
                    className="general-text-label"
                    htmlFor="listing_collection_name">
                    Collection Name
                  </label>
                  <br/>
                  <input
                    value={collectionName}
                    onChange={onChangeCollectionName}
                    className="general-text-field"
                    type="text"
                    name="listing_collection[name]"
                    id="listing_collection_name"/>
                  <p
                    className="form-help-block">
                    (Public) This shouldn't be the client's name.
                  </p>
                </div>
                <div className="field">
                  <label
                    className="general-text-label" htmlFor="listing_collection_client_email">
                    Client email
                  </label>
                  <br/>
                  <input
                    value={clientEmail}
                    onChange={onChangeClientEmail}
                    className="general-text-field"
                    type="text"
                    name="listing_collection[client_email]"
                    id="listing_collection_client_email"/>
                </div>
                <div className="field">
                  <label
                    className="general-text-label"
                    htmlFor="listing_collection_client_phone">
                    Client phone
                  </label>
                  <br/>
                  <input
                    value={clientPhone}
                    onChange={onChangeClientPhone}
                    className="general-text-field"
                    type="text"
                    name="listing_collection[client_phone]"
                    id="listing_collection_client_phone"/>
                </div>
                <div className="field">
                  <label
                    className="general-text-label"
                    htmlFor="listing_collection_description">
                    Notes
                  </label>
                  <br/>
                  <textarea
                    value={notes}
                    onChange={onChangeNotes}
                    className="general-text-area"
                    name="listing_collection[description]"
                    id="listing_collection_description">
                  </textarea>
                  <p
                    className="form-help-block">
                    (Private) This information is private and only you can see it, feel free to put the client's name or other info.
                  </p>
                </div>
                <br/>
                <div
                  className="actions text-center">
                  <button
                    onClick={this.onSubmit}
                    name="commit"
                    className="button btn-black-yellow btn-rounded btn-3x"
                    data-disable-with="Submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addCollection: (collection) => dispatch(addCollection(collection)),
  setMessage: (message) => dispatch(setMessage(message))
})

export default connect(null, mapDispatchToProps)(CreateListingCollectionModalContent)
