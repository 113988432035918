import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import ListingPagination from 'components/paginator/ListingPagination';
import Cat404 from 'components/listings/pages/Cat404';
import {
  setStore,
  fetchNewPage,
  convertSavedSearchToSelectedValues,
  historyInducedSearch,
} from 'reducers/search';
import LoadingSpinner from 'components/LoadingSpinner';
// import SearchParamDisplay from 'components/listings/search/SearchView/SearchParamDisplay';
import SearchCard from './OldSearchCard';

class RoomCollection extends Component {
  source = axios.CancelToken.source();

  shouldComponentUpdate(nextProps) {
    if (nextProps.loading !== this.props.loading && !nextProps.loading) {
      return true;
    }
    if (nextProps.lastAppliedSearchPromise !== this.props.lastAppliedSearchPromise && nextProps.loading) {
      this.fetchListingsViaRedux(nextProps.lastAppliedSearchPromise);
      return true;
    }
    if (nextProps.items !== this.props.items) {
      return true;
    }
    if (nextProps.currentPage !== this.props.currentPage) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.firstTime = true;
    this.fetchListingsViaRedux(this.props.lastAppliedSearchPromise);
    window.addEventListener('popstate', e => {
      this.firstTime = true;
      const newSource = axios.CancelToken.source();
      if (this.historyInducedSource) {
        this.historyInducedSource.cancel('canceled');
      }
      this.historyInducedSource = newSource;
      this.props.historyInducedSearch(newSource);
    });
  }

  componentWillUnmount() {
    this.source.cancel('api call cancelled as component will unmount');
  }

  fetchListingsViaRedux = promise => {
    promise
      .then(res => {
        if (res) {
          const { setStore, convertSavedSearchToSelectedValues } = this.props;
          if (this.firstTime) {
            if (history.replaceState) {
              var newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?_page=${this.props.currentPage}&q=${res.data.search_token}`;
              window.history.replaceState({ path: newurl }, '', newurl);
            }
            setStore({
              lastAppliedSearchParams: res.data.search_params,
            });
            convertSavedSearchToSelectedValues();
            this.props.setStore({ firstTime: true });
            const event = new CustomEvent('paramsinitialized');
            window.dispatchEvent(event);
            this.firstTime = false;
          } else if (history.pushState) {
            var newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?_page=${this.props.currentPage}&q=${res.data.search_token}`;
            window.history.pushState({ path: newurl }, '', newurl);
          }
          setStore({
            items: res.data[this.props.context],
            loading: false,
            pageCount: res.data.page_count,
            totalCount: res.data.total_count,
          });
        }
      })
      .catch(err => console.dir(err));
  }

  handlePaginationChange = page => {
    const { currentPage, setStore, fetchNewPage } = this.props;
    if (page !== currentPage) {
      setStore({
        currentPage: page,
      });
      fetchNewPage(page);
    }
  };

  render() {
    const {
      currentPage,
      pageCount,
      signedIn,
      loading,
      context,
      items,
      totalCount,
    } = this.props;

    if (loading) {
      return (
        <div key="loading-icon" style={{ height: 'calc(100vh - 106px)' }}>
          <div style={{ height: '250px' }} />
          <LoadingSpinner />
        </div>
      );
    }

    return items && items.length > 0
      ? (
        <>
          <div style={{ height: 'calc(100vh - 106px)' }}>
            {/* ToDo: ReImplement Once Converted to Listing Search Format */}
            {/* <SearchParamDisplay /> */}
            <div style={{
              fontFamily: '"Chronicle Display A", "Chronicle Display B", serif',
              fontSize: '16px',
              display: 'flex',
              color: '#fff',
              margin: '8px',
            }}
            >
              {totalCount.toLocaleString()}
              {' results'}
            </div>
            {items.map(item => (
              <React.Fragment key={`${context}-${item.id}`}>
                <SearchCard
                  type="room"
                  listing={item}
                  signedIn={signedIn}
                />
              </React.Fragment>
            ))}
            <div style={{ height: '50px' }} />
          </div>
          <div id="listing-paginator" className="text-center">
            <ListingPagination
              onChange={this.handlePaginationChange}
              current={currentPage}
              pageCount={pageCount}
              key={`${currentPage}${pageCount}`}
            />
          </div>
        </>
      ) : <Cat404 context={context} key="no-results" />;
  }
}

const mapStateToProps = state => {
  const myState = state.search[state.search.context];
  return {
    items: myState.items,
    signedIn: myState.signedIn,
    loading: myState.loading,
    context: state.search.context,
    totalCount: myState.totalCount,
    lastAppliedSearchPromise: myState.lastAppliedSearchPromise,
    bottom_right: myState.bottom_right,
    currentPage: myState.currentPage,
    pageCount: myState.pageCount,
  };
};

const mapDispatchToProps = dispatch => ({
  setStore: payload => dispatch(setStore(payload)),
  fetchNewPage: page => dispatch(fetchNewPage(page)),
  convertSavedSearchToSelectedValues: () => dispatch(convertSavedSearchToSelectedValues()),
  historyInducedSearch: source => dispatch(historyInducedSearch(source)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomCollection);
