/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { appleResidentialImageMarker, appleCurrentImageMarker } from 'helpers/mapHelpers'
import { useClickOutside } from 'hooks/useClickOutside'

import s from './MarkerPoint.module.css'

export default function MarkerPoint({ marker, children, selected, setSelectedListing }) {
  const divRef = useRef(null)

  const handleSelectIem = () => setSelectedListing(marker)

  useEffect(() => {
    const parentNode = divRef?.current?.parentNode
    if (!parentNode) {
      return
    }

    parentNode.style.zIndex = selected ? 1 : ''
  }, [divRef.current, selected])

  useClickOutside(divRef, () => setSelectedListing(null))

  return (
    <div
      ref={divRef}
      role="contentinfo"
      className={clsx(s.marker, marker.price && s.markerPrice, selected && s.markerSelected)}
      onClick={handleSelectIem}
    >
      {marker.price ? (
        <>
          <div className={s.triangle} />
          {window.accounting.formatMoney(marker.price / 100, { precision: 0 })}
        </>
      ) : (
        <img
          alt="map-point"
          className={s.icon}
          src={selected ? appleCurrentImageMarker['2'] : appleResidentialImageMarker['2']}
        />
      )}

      {selected && children}
    </div>
  )
}

MarkerPoint.propTypes = {
  setSelectedListing: PropTypes.func,
  marker: PropTypes.shape({
    price: PropTypes.number,
  }),
  selected: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

MarkerPoint.defaultProps = {
  selected: false,
  marker: null,
  setSelectedListing: () => {},
}
