import React, { PureComponent } from 'react';
import { AsyncSubscribe } from 'asyncHandlers/AsyncBase';

export default class ImageCarousel extends PureComponent {

  componentDidMount() {
    $('.carousel').carousel();
  }

  componentDidUpdate(prevProps) {
    $('.carousel').carousel();
  }

  render() {
    const { images }  = this.props;
    const mapImage = $('#carousel_map_image').val();
    return(
      images && images.length > 0 && <div
        ref={me => this.node = me}
        id='carousel-example-generic'
        className='carousel slide carousel-fade'
        data-ride='carousel'>
        <ol className='carousel-indicators'>
          {images && images.map((image, index) => {
            return <li className='' key={`indicator-${image.id}`} data-target='#carousel-example-generic' data-slide-to={`${index}`}></li>
          })}
            {mapImage && <li className='' key={`indicator-map-image`} data-target='#carousel-example-generic' data-slide-to={`${images.length}`}></li>}
        </ol>
        <div className='carousel-inner' role='listbox'>
          {images && images.map((image, index) => <div key={image.id} className={index !== 0 ? 'carousel-item' : 'carousel-item active'}>
            { image.src ? <img className='d-block w-100' src={image.src} alt={image.src.split('.jpg')[0].split('/').slice(-1)[0]} /> : <img className='d-block w-100' src={image.wide} alt={image.wide.split('.jpg')[0].split('/').slice(-1)[0]} />}
          </div>)}
          {mapImage && <div key="map-image" className={'carousel-item'}>
            <img className='d-block w-100' src={mapImage} alt="Map Image" />
          </div>}
        </div>
        {images && <a className='carousel-control-prev' href='#carousel-example-generic' role='button' data-slide='prev'>
          <span className='carousel-control-prev-icon' aria-hidden='true'></span>
          <span className='sr-only'>Previous</span>
        </a>}
        {images && <a className='carousel-control-next' href='#carousel-example-generic' role='button' data-slide='next'>
          <span className='carousel-control-next-icon' aria-hidden='true'></span>
          <span className='sr-only'>Next</span>
        </a>}
      </div> || null
    );
  }
}
