import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Fade from 'wrappers/Fade';


const FlashNotice = props => {
  const [visible, setVisible] = useState(true);
  const fadeRef = useRef(null);

  const transitionCallback = e => {
    if (fadeRef && e.target === fadeRef.current.el) {
      if (visible) { setVisible(false); }
    }
  };

  useEffect(() => {
    window.addEventListener('transitionend', transitionCallback);

    return () => {
      window.removeEventListener('transitionend', transitionCallback);
    };
  }, []);

  const { text } = props;
  return (
    <Fade
      ref={fadeRef}
      visible={visible}
      fadeStart="flash-start"
      fadeEnd="flash-end"
    >
      <div
        className=""
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <h1>
          {text}
        </h1>
      </div>
    </Fade>
  );
};


FlashNotice.defaultProps = {
  text: '',
};

FlashNotice.propTypes = {
  text: PropTypes.string,
};

export default FlashNotice;
