import axios from 'axios';
import { __AsyncBase__ } from './AsyncBase';

// Converted to api
export default class MateAsync extends __AsyncBase__ {
  constructor(id, agentId) {
    super();
    this._id = id;
    this._agentId = agentId;
  }

  get mate() {
    if (!this._mate) {
      this._mate = axios.get(`/api/v2/roommates.fetch?mate_id=${this._id}`, {
        headers: { API_TOKEN: localStorage.getItem('apiToken') },
        cancelToken: this._source.token,
      })
        .then(res => res.data.agent);
    }
    return this._mate;
  }

  get photos() {
    if (!this._photos) {
      this._photos = axios.get(`/api/v1/mates.photos?mate_id=${this._id}`, {
        cancelToken: this._source.token,
      })
        .then(res => res.data.mate_photos);
    }
    return this._photos;
  }

  get favMates() {
    if (!this._favMates) {
      if (this._agentId) {
        this._favMates = this.getFavMates(this._agentId);
      } else {
        this._favMates = this.mate.then(mate => this.getFavMates(mate.id));
      }
    }
    return this._favMates;
  }

    getFavMates = userId => axios.get(`/api/v2/roommates.favorites?user_id=${userId}`, {
      headers: { API_TOKEN: localStorage.getItem('apiToken') },
      cancelToken: this._source.token,
    })
      .then(res => res.data.roommates);
}
