import { unstable_batchedUpdates as batch } from 'react-dom';
import { structureUrlVariables, returnRegexUrl, cleanUpUrl } from './structureUrlVariables';
/* TLDR; The onBeforeRouteChange event handler allows nested components to intercept
   the new route and react to it before the router removes them from the DOM or
   forces an update. The event enables nested reactions,
   so we can have one Route at the top level for each container (ListingIndexRoute, ListingDetailRoute),
   and have route change handlers in the children and grandchildren (Image, ListingCard, etc. ).
   React elements receive the event in the order that they are subscribed (top to bottom),
   but I haven't yet built a parallel to 'stopPropagation' or bubbling because that's more complex.

   The name 'BeforeRouteChange' may be a little ambiguous.
   The BeforeRouteChange event occurs AFTER the browser path has changed,
   but BEFORE the route change gets propogated down to
   Route components from the Router.
   The 'mount/unmount phase' is when components will actually respond to
   the new route by mounting, updating, or unmounting.
   Every RouteChangeHandler with an onBeforeRouteChange handler is attached to a promise,
   which resolves automatically when its main stack execution is done.
  */


/* __BeforeRouteChange__ will propagate side effects to all RouteChangeHandlers
 before the unmounting and mounting phase of route change */
class __BeforeRouteChange__ {
  constructor(beginMountUnmountPhase) {
    /* The beginMountUnmountPhase parameter gets passed in from the router.
    When side effects are finished,
    call beginMountUnmountPhase to begin propagation of mount unmount phase */
    this.beginMountUnmountPhase = beginMountUnmountPhase;
  }

  propagateSideEffects(historyInduced) {
    // Every RouteChangeHandler component passes in a promise that
    Promise.all(this.listeners.map(l => l[1]))
      .then(() => {
        this.listeners.forEach((l) => {
          // This method call resets the subscription for each listener
          const subReset = l[3];
          subReset();
        });
        // Once subscriptions are reset, begin mount and unmount phase
        this.beginMountUnmountPhase();
      });
        /* React's batch API for batching many nested updates in the correct order.
        This solves the issue of stale props in concurrent nested updates.
        React batch API will update in the correct order.
        (now used in redux v7) */
        batch(() => {
          this.listeners.forEach((l) => {
            const [onBeforeRouteChange,, resolve,, paths] = l;
            // calculate the params if there are paths for the RouteChangeHandler
            let params = {};
            if (paths) {
              const currentPath = window.location.pathname;
              const pathsArray = paths.map(r => cleanUpUrl(r));
              const cp = cleanUpUrl(currentPath);
              let match;
              pathsArray.some((r) => {
                const regexUrl = returnRegexUrl(r);
                if (cp.match(`^${regexUrl}$`)) {
                  match = r;
                  return true;
                }
                return false;
              });
              if (match) params = structureUrlVariables(match, cp);
            }
            // Call the onBeforeRouteChange callback for each listener
            // Each listener must call resolve() when it is ready for unmount phase.
          try {
              onBeforeRouteChange(params, historyInduced);
              resolve();
          }
          catch (e) {
            resolve();
            console.log(e);
          }
          });
        });
  }

  // Initialize listeners array
  listeners = [];

  // Add a new listener
  trySubscribe(listener) {
    this.listeners.push(listener);
  }

  /* Filter out an old listener.
  This gets called when RouteChangeHandler unmounts, resolves its callback, or updates. */
  tryUnsubscribe(listener) {
    this.listeners = this.listeners.filter(l => l[0] !== listener);
  }
}

export { __BeforeRouteChange__ };
