import React from 'react';
import PropTypes from 'prop-types';

const Option = (props) => {
  const {
    children,
    value,
    id,
    selected,
  } = props;
  return (
    <div role="option" aria-selected={selected} data-value={value} data-id={id}>
      {children}
    </div>
  );
};
export default Option;

Option.defaultProps = {
  children: null,
  selected: false,
  id: null,
};

Option.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  selected: PropTypes.bool,
};
