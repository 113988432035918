import axios from 'axios'

function gtagReportConversion(url) {
  var callback = function () {
    if (typeof url != 'undefined') {
      window.location = url
    }
  }
  // eslint-disable-next-line no-undef
  gtag('event', 'likelisting', {
    send_to: 'AW-968054781/WSr4CPT3orwYEP2vzc0D',
    event_callback: callback,
  })
  return false
}

export default class LikeAsync {
  constructor(context, url, objectName) {
    this.url = url
    this.context = context
    this.objectName = objectName
  }

  toggleLike = (e) => {
    e.preventDefault()
    const that = this.context
    const { liked, heartsCount } = that.state
    const { id } = that.props[this.objectName]

    if (liked === true) {
      that.setState({ liked: false, heartsCount: heartsCount - 1 })
      axios.get(`${this.url}/${id}/unlike.json`).catch((err) => console.dir(err))
    } else {
      that.setState({ liked: true, heartsCount: heartsCount + 1 })
      axios.get(`${this.url}/${id}/like.json`).catch((err) => console.dir(err))
      gtagReportConversion()
    }
  }
}
