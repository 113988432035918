import React from 'react';
import { CSSTransition } from 'react-transition-group';

const ContainerWrapper = props => (
  <CSSTransition in appear timeout={300} classNames="stepper">
    {props.children}
  </CSSTransition>
);

export default ContainerWrapper;
