import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import ModalContent from './LeaseSalesAgentModalContent';
import { leaseSalesAgentUpdated } from '../../reducers/lease';

class LeaseSalesAgentModal extends Component {
  state = {
    modalIsOpen: false,
    updateSalesAgent: false,
  };

  openModal = evt => {
    evt.preventDefault();
    this.setState({ modalIsOpen: true });
  };

  closeModal = evt => {
    evt.preventDefault();
    this.setState({ modalIsOpen: false });
    if (this.state.updateSalesAgent) {
      const { id: lease_id, leaseSalesAgentUpdated } = this.props;
      leaseSalesAgentUpdated(lease_id);
    }
  };

  updateSalesAgentOnModalClose = () => {
    this.setState({ updateSalesAgent: true });
  };

  render() {
    const { modalIsOpen } = this.state;

    return (
      <div>
        <a onClick={this.openModal}>Sales Team</a>
        {modalIsOpen && (
          <Modal
            className="Modal__Bootstrap modal-dialog dark-gray-container invite-modal"
            closeTimeoutMS={150}
            isOpen={modalIsOpen}
            onRequestClose={this.closeModal}
          >
            <div className="modal-content modal-content-dark">
              <button className="close" onClick={this.closeModal}>
                <i className="nookons-close-circle-fill" />
              </button>
              <ModalContent
                {...this.props}
                updateSalesAgentOnModalClose={this.updateSalesAgentOnModalClose}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

const mapState = state => ({
  leaseWithUpdatedSalesAgent: state.lease.leaseWithUpdatedSalesAgent,
});

const mapDispatch = dispatch => ({
  leaseSalesAgentUpdated(lease_id) {
    dispatch(leaseSalesAgentUpdated(lease_id));
  },
});

export default connect(
  mapState,
  mapDispatch,
)(LeaseSalesAgentModal);
