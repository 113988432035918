import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';
import { connect } from 'react-redux';
import {
  togglePopover, toggleAllFalse, resetSearch,
  setStore, updateActiveSearch, generateNewSearch,
} from 'reducers/search';
import SentenceSearchModal from './SentenceSearchModal';
import {
  Hood, Price, Bed,
  SubwayStops,
  SortResultsBy, MoveBy, SubletOnly, Ppsf, MarketAs, SquareFeet, More, NoFeeOnly, LikedOnly,
} from './searchBarComponents';
import TopLevelUpdateHandler from './TopLevelUpdateHandler';
import RenderOutsideContainer from './searchBarComponents/RenderOutsideContainer';

const ListingSearchBarButtons = props => {
  const {
    context,
    generateNewSearch,
    resetSearch,
    setStore,
    toggleAllFalse,
    updateActiveSearch,
  } = props;

  useEffect(() => {
    // Add event listener for mousedown on Init
    window.addEventListener('mousedown', toggleAllFalse);

    // Remove event listener on cleanup
    return () => {
      toggleAllFalse();
      window.removeEventListener('mousedown', toggleAllFalse);
    };
  }, []);

  const toggleSentenceSearch = e => {
    e.stopPropagation();
    e.preventDefault();
    setStore({
      sentenceSearchDisplay: true,
    });
  };

  const resetSearchbar = () => {
    resetSearch();
    generateNewSearch();
    updateActiveSearch();
  };

  return (
    <>
      {context === 'listings'
        && (
          <>
            <Price key="listing-searchbar-price" searchContext="searchbar" />
            <Hood key="listing-searchbar-hood" searchContext="searchbar" />
            <Bed key="listing-searchbar-bed" searchContext="searchbar" />
            <SubwayStops key="listing-searchbar-subway-stops" searchContext="searchbar" />
            <More key="listing-searchbar-more" searchContext="searchbar" pets baths amenities likedOnly address />
            <NoFeeOnly key="listing-searchbar-no-fee" />
            <SortResultsBy key="list-searchbar-order" searchContext="searchbar" />
          </>
        )}
      { context === 'roommates'
        && (
          <>
            <Price key="listing-searchbar-price" searchContext="searchbar" />
            <Hood key="listing-searchbar-hood" searchContext="searchbar" />
            <MoveBy key="listing-searchbar-moveby" />
          </>
        )}
      { context === 'rooms'
        && (
          <>
            <Price key="listing-searchbar-price" searchContext="searchbar" />
            <Hood key="listing-searchbar-hood" searchContext="searchbar" />
            <SubwayStops key="listing-searchbar-subway-stops" searchContext="searchbar" />
            <More key="listing-searchbar-more" searchContext="searchbar" pets amenities likedOnly />
            <NoFeeOnly key="listing-searchbar-no-fee" />
            <SortResultsBy key="list-searchbar-order" searchContext="searchbar" />
            <SubletOnly key="listing-searchbar-sublet" searchContext="searchbar" />
          </>
        )}
      { context === 'commercials'
        && (
          <>
            <Price key="listing-searchbar-price" searchContext="searchbar" />
            <Hood key="listing-searchbar-hood" searchContext="searchbar" />
            <SubwayStops key="listing-searchbar-subway-stops" searchContext="searchbar" />
            <LikedOnly key="listing-searchbar-liked" />
            <Ppsf key="listing-searchbar-ppsf" searchContext="searchbar" />
          </>
        )}
      { context === 'sales'
        && (
          <>
            <Price key="listing-searchbar-price" searchContext="searchbar" />
            <Hood key="listing-searchbar-hood" searchContext="searchbar" />
            <Bed key="listing-searchbar-bed" searchContext="searchbar" />
            <SubwayStops key="listing-searchbar-subway-stops" searchContext="searchbar" />
            <More key="listing-searchbar-more" searchContext="searchbar" baths amenities likedOnly />
            <MarketAs key="listing-searchbar-market-as" searchContext="searchbar" />
            <SquareFeet key="listing-searchbar-square-feet" searchContext="searchbar" />
            <SortResultsBy key="list-searchbar-order" searchContext="searchbar" />
          </>
        )}
      <Column className="searchbar-col">
        <input
          type="button"
          className="button btn-rounded btn-transparent btn-search-bar-size"
          onClick={resetSearchbar}
          value="Reset"
        />
      </Column>
      {context === 'listings' && (
        <RenderOutsideContainer>
          <SentenceSearchModal />
        </RenderOutsideContainer>
      )}
      <TopLevelUpdateHandler />
    </>
  );
};

ListingSearchBarButtons.propTypes = {
  context: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  context: state.search.context,
});

const mapDispatchToProps = dispatch => ({
  setStore: payload => dispatch(setStore(payload)),
  togglePopover: name => dispatch(togglePopover(name)),
  toggleAllFalse: () => dispatch(toggleAllFalse()),
  resetSearch: () => dispatch(resetSearch()),
  updateActiveSearch: () => dispatch(updateActiveSearch()),
  generateNewSearch: () => dispatch(generateNewSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingSearchBarButtons);
