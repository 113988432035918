import React from 'react';
import DivInput from '../DivInput';
import { Row } from 'wrappers/FlexTable';

const Filter = (props) => {
  const { onFocus, onBlur, placeholder, onChange, filter, style, className, selectedOptions } = props;
  return (
    <Row style={{
      backgroundColor: '#000', width: '325px', padding: '12px', position: 'relative',
    }}
    >
      <DivInput
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        className={className}
        value={filter}
        onChange={onChange}
        style={style}
        focusClass="nothingness"
      />
    </Row>
  );
};
export default Filter;

Filter.defaultProps = {
  className: "btn-text-field btn-rounded btn-black-yellow",
  style: {
    overflowY: 'scroll',
    borderRadius: '2px',
    border: '1px solid',
    minHeight: '38px',
    width: '300px',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    padding: '5px',
    paddingTop: '7px',
    paddingLeft: '12px',
    paddingRight: '5px',
  },

}
