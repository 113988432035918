import React from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';
import LoadingSpinner from 'components/LoadingSpinner';
import AmenityFilter from './filters/AmenityFilter';
import LikedOnlyFilter from './filters/LikedOnlyFilter';
import PetFilter from './filters/PetFilter';

const MorePopover = props => {
  const {
    value,
    options,
    onChange,
    loading,
    address,
    amenities,
    likedOnly,
    pets,
  } = props;

  const handleChange = (selected, type) => {
    const tempQueuedValue = { ...value };
    tempQueuedValue[type] = selected;
    return onChange(tempQueuedValue);
  };

  return (
    <div className="more-pop">
      <Column
        style={{
          height: '600px',
          width: '440px',
          background: '#000',
          border: '1px solid #404040',
          borderRadius: '21px',
          overflow: 'scroll',
          padding: '15px',
        }}
        className="more-popover"
      >
        {pets && (
          <PetFilter
            selected={value.pets || ''}
            onChange={newPets => handleChange(newPets, 'pets')}
            options={options.pets}
          />
        )}
        {amenities && (
          <AmenityFilter
            selected={value.amenities || []}
            onChange={newAmenities => handleChange(newAmenities, 'amenities')}
            options={options.amenities}
          />
        )}
        {address && (
          <Column>
            <hr />
            <div style={{
              margin: '24px 0px',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '19px',
              color: '#fff',
            }}
            >
              Address
            </div>
            {!loading
              ? (
                <input
                  onChange={e => handleChange(e.target.value, 'address')}
                  className="btn-text-field btn-rounded btn-black-yellow btn-price-field w-100"
                  placeholder="Address"
                  value={value.address || ''}
                />
              )
              : <LoadingSpinner />}
          </Column>
        )}
        {likedOnly && (
          <LikedOnlyFilter
            selected={value.likedOnly || false}
            loading={loading}
            onChange={newLikedOnly => handleChange(newLikedOnly, 'likedOnly')}
          />
        )}
      </Column>
    </div>
  );
};

MorePopover.propTypes = {
  value: PropTypes.shape({
    address: PropTypes.string,
    amenities: PropTypes.arrayOf(PropTypes.string),
    baths: PropTypes.string,
    likedOnly: PropTypes.bool,
    pets: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    amenities: PropTypes.arrayOf(PropTypes.shape({})),
    baths: PropTypes.arrayOf(PropTypes.shape({})),
    pets: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  address: PropTypes.bool.isRequired,
  amenities: PropTypes.bool.isRequired,
  baths: PropTypes.bool.isRequired,
  likedOnly: PropTypes.bool.isRequired,
  pets: PropTypes.bool.isRequired,
};

export default MorePopover;
