import React, { useState, Component } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import Modal from '../modal/Modal';
import { cleanCountryData, loadRegions } from '../../helpers/util';
import Dropdown from '../Dropdown/PaymentDropdown';

const defaultRegionOptions = loadRegions(cleanCountryData, 'US');

class ModalContent extends Component {
	state = {
		idNumber: '',
		firstName: '',
		lastName: '',
		idFront: '',
		idBack: '',
		sex: '',
		dob: '',
		state: '',
		country: '',
		apiError: '',
		documentType: '',
		submitting: false,
		dobError: '',
		source: axios.CancelToken.source(),
		regionOptions: defaultRegionOptions,
		documentTypeOptions: [{ value: 'passport', text: 'Passport' }, { value: 'others', text: 'Others' }]
	};

	handleSubmit = (evt) => {
		evt.preventDefault();
		const { id, handleModalCloseRequest } = this.props;
		const { idNumber, firstName, lastName, idFront, idBack, sex, dob, state, source, country } = this.state;

		this.setState({ submitting: true });
		const data = new FormData();

		data.append('id_number', idNumber);
		data.append('first_name', firstName);
		data.append('last_name', lastName);
		data.append('sex', sex);
		data.append('dob', dob);
		data.append('state', state);
		data.append('country', country);
		data.append('image_front', idFront, idFront.name);
		if (idBack) {
			data.append('image_back', idBack, idBack.name);
		}
		data.append('agent_id', id);

		axios
			.post('/api/v2/user.state_id.manual_verify', data, {
				headers: {
					API_TOKEN: localStorage.getItem('apiToken'),
					cancelToken: source.token
				}
			})
			.then((res) => {
				this.setState({ submitting: false });
				handleModalCloseRequest();
			})
			.catch((err) => {
				this.setState({ submitting: false });

				if (err.response && err.response.data) {
					this.setState({ apiError: err.response.data.error.friendlyMessage });
				}
			});
	};

	handleValueChange = (evt) => {
		const { name, value } = evt.target;
		this.setState({ [name]: value });

		if (name === 'dob') {
			const dateOfBirth = new Date(value);
			if (!dateOfBirth.getDate()) {
				this.setState({ dobError: 'Please enter correct date of birth information. ' });
			} else {
				const startDate = new Date('01/01/1900');
				const endDate = new Date();
				if (startDate < dateOfBirth && dateOfBirth < endDate) {
					this.setState({ dobError: '' });
				} else {
					this.setState({ dobError: 'Please enter correct date of birth information. ' });
				}
			}
		}
	};

	handleFileChange = (evt) => {
		const { name } = evt.target;
		this.setState({ [name]: evt.target.files[0] });
	};

	handleCountryChange = (item) => {
		if (item && item.value) {
			const regions = loadRegions(cleanCountryData, item.value);
			this.setState({ country: item.value, regionOptions: regions });
		} else {
			this.setState({ country: 'US', regionOptions: defaultRegionOptions });
		}
	};

	handleStateChange = (item) => {
		if (item) {
			this.setState({ state: item.value });
		} else {
			this.setState({ state: '' });
		}
	};

	handleDocumentChange = (item) => {
		if (item) {
			this.setState({ documentType: item.value });
		} else {
			this.setState({ documentType: '' });
		}
	};

	handleDobChange = (evt) => {
		const dob = evt.target.value;
	};

	render() {
		const {
			idNumber,
			firstName,
			lastName,
			sex,
			state,
			country,
			dob,
			apiError,
			dobError,
			submitting,
			regionOptions,
			documentType,
			documentTypeOptions
		} = this.state;
		const { name } = this.props;

		return (
			<div className="container invite-container manual-verification-modal">
				<h1>Manual Verification for {name} </h1>
				<hr className="entire-width" />
				<form onSubmit={this.handleSubmit}>
					{apiError && <p className="error text-center">{apiError}</p>}
					{dobError && <p className="error text-center">{dobError}</p>}
					<p className="form-help-block">
						Please enter the legal information as printed on official identification document.
					</p>
					<label>ID number</label>
					<input
						className="general-text-field mb-3"
						name="idNumber"
						value={idNumber}
						onChange={this.handleValueChange}
						required
					/>
					<label>First Name</label>
					<input
						className="general-text-field mb-3"
						name="firstName"
						value={firstName}
						onChange={this.handleValueChange}
						required
					/>
					<label>Last Name</label>
					<input
						className="general-text-field mb-3"
						name="lastName"
						value={lastName}
						onChange={this.handleValueChange}
						required
					/>
					<label>Sex</label>
					<select
						className="custom-select mb-3"
						onChange={this.handleValueChange}
						name="sex"
						value={sex}
						required
					>
						<option value="">Select</option>
						<option value="M">Male</option>
						<option value="F">Female</option>
					</select>
					<label>Date of Birth</label>
					<InputMask
						mask="99/99/9999"
						className="general-text-field"
						placeholder="MM/DD/YYYY"
						name="dob"
						value={dob}
						onChange={this.handleValueChange}
						style={dobError ? { borderColor: 'red' } : {}}
						required
					/>
					<br />
					<br />
					<label>Country</label>
					<Dropdown
						onChange={this.handleCountryChange}
						options={cleanCountryData}
						placeholder="Select Country"
						value={country}
						required
					/>
					<br />
					<label>State/Region</label>
					<Dropdown
						onChange={this.handleStateChange}
						options={regionOptions}
						placeholder="Select State"
						value={state}
						required
					/>
					<br />
					<label>Document Type</label>
					<Dropdown
						onChange={this.handleDocumentChange}
						options={documentTypeOptions}
						placeholder="Select Document Type"
						value={documentType}
						required
					/>
					<div>
						<hr />
						<label>Identification Image (front)</label>
						<br />
						<input
							type="file"
							accept="image/png, image/jpeg, image/jpg, application/pdf"
							name="idFront"
							className="mb-3"
							onChange={this.handleFileChange}
							required
						/>
						<br />
						<label>Identification Image (back)</label>
						<br />
						<input
							type="file"
							accept="image/png, image/jpeg, image/jpg, application/pdf"
							name="idBack"
							className="mb-3"
							onChange={this.handleFileChange}
						/>
					</div>
					<input
						type="submit"
						name="commit"
						value="Submit"
						className="button btn-black-white btn-rounded mt-3"
						disabled={submitting || apiError || dobError || !documentType || !country || !state}
					/>
				</form>
			</div>
		);
	}
}

const ManualVerification = (props) => {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const openModal = (evt) => {
		evt.preventDefault();
		setModalIsOpen(true);
	};

	const closeModal = (evt) => {
		evt.preventDefault();
		setModalIsOpen(false);
	};

	const handleModalCloseRequest = () => {
		setModalIsOpen(false);
		window.location.reload();
	};

	return (
		<div style={{ display: 'inline' }}>
			<a onClick={openModal} onKeyDown={openModal} className="button btn-black-white btn-2x btn-rounded">
				Verify
			</a>
			{modalIsOpen && (
				<Modal
					className="Modal__Bootstrap modal-dialog dark-gray-container invite-modal"
					closeTimeoutMS={150}
					isOpen={modalIsOpen}
				>
					<div className="modal-content">
						<button className="close" onClick={closeModal}>
							<i className="nookons-close-circle-fill" />
						</button>
						<ModalContent {...props} handleModalCloseRequest={handleModalCloseRequest} />
					</div>
				</Modal>
			)}
		</div>
	);
};

export default ManualVerification;
