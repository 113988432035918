import React, { useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

// Props:
// --------------------------------------------------
// children - Child Nodes being wrapped (Required)
// offsets - Page Size Offsets from props (Required)
// sliderWidth - Width of Slider on page (Required)
// propData - List of Ids to API Call (Required)
// seeAllBtn - Bool telling if there is a See All Btn (Required)
// setNeedSlider - Callback function to set if slider controls are needed (Required)
// setSliderDimensions - Callback function to set sliders dimensions (Required)
// setSlideCount - Callback function to set number of slides to be in the slider (Required)
// slideOrientation - Alignment of Slides: Left, Center (Required)
// slideSize - Overall Size of Each Slide including spacers (Required)

// NotSoSlick's ResizeWrapper:
// One resize to rule them all
const ResizeWrapper = props => {
  const {
    children,
    offsets,
    sliderWidth,
    propData,
    seeAllBtn,
    setNeedSlider,
    setSliderDimensions,
    setSlideCount,
    slideOrientation,
    slideSize,
  } = props;

  // Making a Reference to Parent Holding Slider to Get sliderWidth
  const sliderRef = useRef(null);

  // Returns width and sets states of: sliderWidth + pageOffset
  const getWidth = () => {
    if (sliderRef.current.offsetWidth !== 0 && sliderWidth !== sliderRef.current.offsetWidth) {
      if (sliderRef.current.offsetWidth <= 600) {
        setSliderDimensions({
          offset: offsets.smOffset,
          width: sliderRef.current.offsetWidth,
        });
      } else if (sliderRef.current.offsetWidth <= 1250) {
        setSliderDimensions({
          offset: offsets.mdOffset,
          width: sliderRef.current.offsetWidth,
        });
      } else {
        setSliderDimensions({
          offset: offsets.lgOffset,
          width: sliderRef.current.offsetWidth,
        });
      }
    }

    return {
      offset: offsets.lgOffset,
      width: sliderRef.current.offsetWidth,
    };
  };

  // Calculates Slides To Show on a Page
  const calcSlidesToScroll = () => {
    const sliderDimensions = getWidth();

    const { offset, width } = sliderDimensions;

    let slideCount = 0;

    // Slides for center is calculated differently then left and right
    // Centered Slider requires one less slide to add as equal padding on sides
    if (slideOrientation === 'center') {
      slideCount = Math.round((width - offset) / slideSize) - 1;
    } else {
      slideCount = Math.floor((width - offset) / slideSize);
    }

    // Handling edge cases for slide count if 0 or less makes it 1
    // if it is greater than propdata length + seeAllBtn, set it to propdata length + seeAllBtn
    if (slideCount <= 0) {
      slideCount = 1;
    } else if ((slideCount > (propData.length + 1)) && seeAllBtn) {
      slideCount = (propData.length + 1);
    } else if ((slideCount > (propData.length)) && !seeAllBtn) {
      slideCount = (propData.length);
    }

    if ((slideCount === propData.length + 1) && seeAllBtn) {
      setNeedSlider(false);
      return setSlideCount(slideCount);
    } if ((slideCount === propData.length) && !seeAllBtn) {
      setNeedSlider(false);
      return setSlideCount(slideCount);
    }
    setNeedSlider(true);
    return setSlideCount(slideCount);
  };

  // Update Function Run on Resize Change
  useEffect(() => {
    // Debounce so it doesn't spam resize function
    const resizeListener = debounce(() => {
      calcSlidesToScroll();
    }, 100);

    // Add event listener for Resize and Running on Init
    window.addEventListener('resize', resizeListener);
    calcSlidesToScroll();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return (
    <div className="special-listings-container" style={{ justifyContent: 'left', overflow: 'visible' }} ref={sliderRef}>
      {children}
    </div>
  );
};

ResizeWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  offsets: PropTypes.shape({
    smOffset: PropTypes.number,
    mdOffset: PropTypes.number,
    lgOffset: PropTypes.number,
  }).isRequired,
  sliderWidth: PropTypes.number.isRequired,
  propData: PropTypes.arrayOf(PropTypes.number).isRequired,
  seeAllBtn: PropTypes.bool.isRequired,
  setNeedSlider: PropTypes.func.isRequired,
  setSliderDimensions: PropTypes.func.isRequired,
  setSlideCount: PropTypes.func.isRequired,
  slideOrientation: PropTypes.string.isRequired,
  slideSize: PropTypes.number.isRequired,
};

export default ResizeWrapper;
