import React from 'react';
import { connect } from 'react-redux';
import IndividualSubway from './IndividualSubway';
import {
  setStore, updateSubwayStopMatches, generateNewSearch, updateSelectedSubways,
} from '../../../../reducers/search';

class SubwayStopList extends React.Component {
  handleSelectSubwayLine = (e, subwayLine) => {
    e.preventDefault();
    const { updateSelectedSubways, generateNewSearch, subwayStopFilter } = this.props;
    if (!subwayStopFilter.length) {
      updateSelectedSubways(subwayLine);
      generateNewSearch();
    }
  };

  handleSelectMultipleSubwayStops = stopIds => {
    const { subwayStops, selectedSubwayStops } = this.props;
    const addedSubwayStops = subwayStops.filter(id => stopIds.includes(id));
    setStore({ selectedSubwayStops: [...selectedSubwayStops, ...addedSubwayStops] });
  }

  handleSelectSubwayStop = id => {
    const {
      selectedSubwayStops, updateSubwayStopMatches, setStore, subwayStops, generateNewSearch,
    } = this.props;
    if (selectedSubwayStops.some(stop => stop.id === id)) {
      setStore({ selectedSubwayStops: selectedSubwayStops.filter(subwayStop => subwayStop.id !== id) });
    } else {
      setStore({ selectedSubwayStops: [...selectedSubwayStops, subwayStops.find(stop => id === stop.id)], subwayStopFilter: '' });
      updateSubwayStopMatches();
    }
    generateNewSearch();
  }

  render() {
    const { subwayLines } = this.props;
    return (
      <div className="region-hood-content subway-stop-list">
        { subwayLines && subwayLines.map(subwayLine => (
          <IndividualSubway
            handleSelectSubwayLine={this.handleSelectSubwayLine}
            handleSelectSubwayStop={this.handleSelectSubwayStop}
            key={subwayLine.line}
            subwayLine={subwayLine}
          />
        ))}
      </div>
    );
  }
}

SubwayStopList.defaultProps = {
  subways: [],
  subwayStops: [],
  subwayLines: [],
};

const mapStateToProps = state => ({
  subwayStops: state.search[state.search.context].subwayStopMatches,
  subwayLines: state.search[state.search.context].subwayLineMatches,
  subways: state.search[state.search.context].subways,
  selectedSubway: state.search[state.search.context].selectedSubways,
  selectedSubwayStops: state.search[state.search.context].selectedSubwayStops,
  subwayStopFilter: state.search[state.search.context].subwayStopFilter,
});

const mapDispatchToProps = dispatch => ({
  setStore: payload => dispatch(setStore(payload)),
  updateSubwayStopMatches: () => dispatch(updateSubwayStopMatches()),
  updateSelectedSubways: payload => dispatch(updateSelectedSubways(payload)),
  generateNewSearch: () => dispatch(generateNewSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubwayStopList);
