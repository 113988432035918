import React, { forwardRef } from 'react';
import { Column, Row } from 'wrappers/FlexTable';
import Fade from 'wrappers/Fade';
import { connect } from 'react-redux';
import IndividualLiked from './IndividualLiked';

const LikedPop = forwardRef((props, { popover, popoverWrapper }) => {
  const onMouseDown = (e) => {
    e.stopPropagation();
  };

  const { display } = props;
  return (
    <div
      className={display ? 'popover fee-pop' : 'popover fee-pop hide'}
      style={{ position: 'fixed', width: 0, border: 'none' }}
      ref={popoverWrapper}
    >
      <Fade
        fadeStart="expand-start"
        fadeEnd="expand-end"
        visible={display}
        customStyles={{
          position: 'relative',
          top: '2px',
          right: '165px',
          flexWrap: 'wrap',
          fontSize: '15px',
        }}
      >
        <Column
          ref={popover}
          role="button"
          tabIndex={0}
          onMouseDown={onMouseDown}
          style={{
            border: '1px solid rgb(64,64,64)',
            backgroundColor: 'rgb(34,34,34)',
            width: '270px',
            height: '60px',
            left: '20px',
            position: 'relative',
          }}
          className="Likeds"
        >
          <Row
            style={{
              backgroundColor: '#000',
              height: '100%',
              marginTop: '10px',
              marginBottom: '10px',
              marginLeft: '5px',
              marginRight: '5px',
              fontWeight: '500',
            }}
          >
            <IndividualLiked val="Any" toggler={false} key="Any" />
            <IndividualLiked val="Liked Only" toggler key="Liked Only" />
          </Row>
        </Column>
      </Fade>
    </div>
  );
});

const mapStateToProps = (state, ownProps) => {
  const display = state.search[state.search.context][ownProps.displayProp];
  return ({
    display: display || false,
  });
};

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true },
)(LikedPop);
