import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row } from 'wrappers/FlexTable';
import IndividualAmenity from './IndividualAmenity';
import { setStore, updateAmenityMatches, generateNewSearch, } from '../../../../reducers/search';

class AmenityList extends Component {

  onCheck = (e, name) => {
    const { selectedAmenities, updateAmenityMatches, setStore, generateNewSearch } = this.props;
    if (selectedAmenities.includes(name)) {
      setStore({ selectedAmenities: selectedAmenities.filter(amenity => amenity !== name) });
      generateNewSearch();
    } else {
      setStore({ selectedAmenities: [...selectedAmenities, name], amenityFilter: '' });
      generateNewSearch();
      updateAmenityMatches();
    }
  }

  render() {
    const { amenities } = this.props;
    return(
      <Row
        style={{
          flexWrap: 'wrap',
          paddingLeft: '0px',
          border: '0px' }}
        className='region-hood-content amenity-list'>
        { amenities.map(amenity => <IndividualAmenity onCheck={this.onCheck} key={amenity} amenity={amenity}/>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  amenities: state.search[state.search.context].amenityMatches,
  selectedAmenities: state.search[state.search.context].selectedAmenities,
});

const mapDispatchToProps = (dispatch) => ({
  setStore: (payload) => dispatch(setStore(payload)),
  updateAmenityMatches: () => dispatch(updateAmenityMatches()),
  generateNewSearch: () => dispatch(generateNewSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AmenityList);
