import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fade from 'wrappers/Fade';
import IndividualPet from './IndividualPet';

const PetsPop = forwardRef((props, { popover, popoverWrapper }) => {
  const onMouseDown = (e) => {
    e.stopPropagation();
  };

  const { display, pets, searchContext } = props;
  return (
    <div
      className={display ? 'popover pets-pop' : 'popover pets-pop hide'}
      style={{ position: 'fixed', width: 0, border: 'none' }}
      ref={popoverWrapper}
    >
      <Fade
        customStyles={{
          color: '#fff',
          position: 'relative',
          right: '125px',
        }}
        fadeStart="expand-start"
        fadeEnd="expand-end"
        visible={display}
      >
        <div
          role="button"
          tabIndex={0}
          onMouseDown={onMouseDown}
          className="popover fade show"
          style={{
            backgroundColor: '#111',
            color: '#fff',
            willChange: 'transform',
            position: 'relative',
            padding: '0px',
            left: '0px',
          }}
        >
          <div
            ref={popover}
            className="popover-body pet-popover"
            style={{
              border: '1px solid rgb(64,64,64)',
              backgroundColor: '#111',
              color: '#fff',
              borderRadius: '0.3em',
              zIndex: '1004',
              fontSize: '12px',
            }}
          >
            <div
              className="container-fluid"
              style={{ padding: '15px' }}
            >
              <div className="tab-content">
                {pets.map(pet => (
                  <IndividualPet
                    searchContext={searchContext}
                    pet={pet}
                    key={pet}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
});

const mapStateToProps = (state, ownProps) => {
  const display = state.search[state.search.context][ownProps.displayProp];
  return ({
    display: display || false,
    pets: state.search[state.search.context].pets,
  });
};

PetsPop.propTypes = {
  display: PropTypes.bool.isRequired,
  searchContext: PropTypes.string.isRequired,
  pets: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(PetsPop);
