import React, { Component } from 'react';
import { Column } from 'wrappers/FlexTable';
import { connect } from 'react-redux';
import { updateSelectedBeds, generateNewSearch, updateActiveSearch } from 'reducers/search';

class IndividualBed extends Component {

  onMouseDown = (e) => {
    e.preventDefault();
    this.toggleSelect();
  }

  toggleSelect = () => {
    let bedVal = this.props.val;

    if (bedVal === 'Studio') {
      bedVal = '0';
    } else if (bedVal === '5+') {
      bedVal = '5';
    }

    this.props.updateSelectedBeds(bedVal);
    this.props.generateNewSearch();
    if (this.props.searchContext === 'searchbar') {
      this.props.updateActiveSearch();
    }
  }

  render () {
    const { val, selected } = this.props;
    return (
      <Column className={selected ? 'paint-layer bed-div bed-div-selected' : ' paint-layer bed-div'} onMouseDown={this.onMouseDown}>
        {val}
      </Column>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let selected = false;

  if (state.search[state.search.context].selectedBeds.includes(ownProps.val)) {
    selected = true;
  } else if (ownProps.val === 'Studio' && state.search[state.search.context].selectedBeds.includes('0')) {
    selected = true;
  } else if (ownProps.val === '5+' && (state.search[state.search.context].selectedBeds.includes('5'))) {
    selected = true;
  }

  return {
    selected: selected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSelectedBeds: (payload) => dispatch(updateSelectedBeds(payload)),
  generateNewSearch: () => dispatch(generateNewSearch()),
  updateActiveSearch: () => dispatch(updateActiveSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualBed);
