import React from 'react';
import PropTypes from 'prop-types';
import AgentAsync from 'asyncHandlers/AgentAsync';
import { AsyncProvider } from 'asyncHandlers/AsyncBase';
import AgentTypeahead from './Agent';

const ListingSalesContainer = (props) => {
  const {
    sales_agents: salesAgents,
    listing_agents: listingAgents,
    matrix_context: matrixContext,
  } = props;
  return (
    <AsyncProvider handler={new AgentAsync()}>
      <AgentTypeahead
        agents={salesAgents}
        agentType="salesAgent"
        matrixContext={matrixContext}
        searchContext="matrix"
      />
      <AgentTypeahead
        agents={listingAgents}
        agentType="listingAgent"
        matrixContext={matrixContext}
        searchContext="matrix"
      />
    </AsyncProvider>
  );
};

ListingSalesContainer.defaultProps = {
  sales_agents: [],
  listing_agents: [],
};

ListingSalesContainer.propTypes = {
  sales_agents: PropTypes.arrayOf(PropTypes.string),
  listing_agents: PropTypes.arrayOf(PropTypes.string),
  matrix_context: PropTypes.oneOf(['listing', 'lease', 'check_request', 'listing_room', 'commission_check']).isRequired,
};

export default ListingSalesContainer;
