import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import missing from 'images/missing.png';
import Fade from 'wrappers/Fade';
import fetchAgent from 'api/agents';
import { getParamsFromLink } from 'helpers/util';

// Shows Contact Agent for a listing
const Agent = props => {
  const { listingID, contact, listingNetwork } = props;
  const style = { width: 56, height: 56 };
  let agentSrc = missing;

  const brokerage = (listingNetwork === 'brokerage');
  const [referralContact, setReferralContact] = useState(undefined);

  const {
    'sales-agent': referralAgentId = undefined,
  } = getParamsFromLink();

  useEffect(() => {
    if (!referralAgentId) {
      return;
    }

    fetchAgent(referralAgentId)
      .then(res => {
        const contactAlt = [];

        if (res.agent.licensedAs) {
          contactAlt.push(`Licensed as: ${res.agent.licensedAs}`);
        }

        if (res.agent.agentCard) {
          if (res.agent.agentCard.realEstateLicenseNumber) {
            contactAlt.push(`License #: ${res.agent.agentCard.realEstateLicenseNumber}`);
          }
        }

        setReferralContact({
          name: res.agent.fullName,
          url: `${window.location.origin}/agents/${res.agent.slug}`,
          image: res.agent.avatarThumb,
          title: 'Licensed Real Estate Salesperson',
          alt: contactAlt,
        });
      })
      .catch(res => {
        console.log(`Sales Agent not Found: ${referralAgentId}`);
        console.log(`Error: ${res}`);
      });
  }, [referralAgentId]);

  const contactInfo = referralContact ?? contact;

  if (contactInfo && contactInfo.image) {
    if (contactInfo.image.includes('white-on-gray')) {
      agentSrc = missing;
    } else if (contactInfo.image.includes('gold-on-black')) {
      agentSrc = "";
    } else {
      agentSrc = contactInfo.image;
    }
  }

  return (
    <>
      {!!brokerage && <h4 className="text-left">Listed by Nooklyn</h4> }
      <>
        <h4 className="text-left">
          Listing ID:
          {listingID}
        </h4>
        <hr />
      </>

      { contactInfo
      && (
      <Fade>
        <h5>Contact</h5>
        <a href={contactInfo.url || ''}>
          <img className="rounded-circle" alt={contactInfo.name} src={agentSrc} style={style} />
        </a>
        <h3><a href={contactInfo.url}>{contactInfo.name}</a></h3>
        <h4>{contactInfo.title}</h4>
        {
          contactInfo.alt && contactInfo.alt.map(val => (<h4 key={val}>{val}</h4>))
        }
      </Fade>
      )}
    </>
  );
};

Agent.propTypes = {
  listingID: PropTypes.number,
  contact: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    alt: PropTypes.arrayOf(PropTypes.string),
  }),
  listingNetwork: PropTypes.string,
};

Agent.defaultProps = {
  listingID: -1,
  contact: {
    name: '',
    url: '',
    image: '',
    title: '',
    alt: [],
  },
  listingNetwork: '',
};

export default Agent;
