import axios from 'axios';
import keysToCamel, { keysToSnake } from './util';

const formatDate = date => {
  const [year, month, day] = date.split('-');
  return [month, day, year].join('/');
};

// Formatting Listing to go from ruby format to react
// Param(s):
// listing - Listing object from API Call (Snake format)
const formatListing = listing => {
  const formattedListing = keysToCamel(listing);

  formattedListing.amenities = formattedListing.amenities
    && formattedListing.amenities.split('\n');
  formattedListing.dateAvailable = formattedListing.dateAvailable
    && formatDate(formattedListing.dateAvailable);
  formattedListing.moveOutDate = formattedListing.moveOutDate
    && formatDate(formattedListing.moveOutDate);
  formattedListing.slug = formattedListing.url
    && /[^/]*$/.exec(formattedListing.url).pop();
  formattedListing.price = formattedListing.price
    && (formattedListing.price / 100);

  return formattedListing;
};

// API call for fetching interested roommates for a listing
// Param(s):
// listingId - Listing ID or slug for listing we want interested roommates for
export const fetchInterestedRoommates = async listingId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;
  const slug = !String(listingId).match('^[0-9]*$');

  const roommatesUrl = `/api/v2/listings.interested_roommates?${slug ? 'slug=' : 'listing_id='}${listingId}`;

  return axios.get(roommatesUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.roommates)
    .then(roommates => keysToCamel(roommates));
};

// API call for fetching price history for a listing
// Param(s):
// listingId - Listing ID or slug for listing we want price history for
export const fetchPriceHistory = async listingId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;
  const slug = !String(listingId).match('^[0-9]*$');

  const priceHistoryUrl = `/api/v2/listings.price_history?${slug ? 'slug=' : 'listing_id='}${listingId}`;

  return axios.get(
    priceHistoryUrl,
    {
      headers: { API_TOKEN: apiToken },
      cancelToken,
    },
  )
    .then(priceHistoryResult => priceHistoryResult.data.priceHistory)
    .then(priceHistory => keysToCamel(priceHistory));
};

// API call for fetching images for a listing
// Param(s):
// listingId - Listing ID or slug for listing we want images for
export const fetchImages = async listingId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;
  const slug = !String(listingId).match('^[0-9]*$');

  const imageUrl = `/api/v2/listings.images?${slug ? 'slug=' : 'listing_id='}${listingId}&active_only=false`;

  return axios.get(imageUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(imagesResult => imagesResult.data.images)
    .then(images => keysToCamel(images));
};

// API call for fetching rooms for a listing
// Param(s):
// listingId - Listing ID or slug for listing we want rooms for
export const fetchRooms = async listingId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;
  const slug = !String(listingId).match('^[0-9]*$');

  const listingUrl = `/api/v1/listings.rooms.list?${slug ? 'slug=' : 'listing_id='}${listingId}`;

  return axios.get(listingUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.rooms)
    .then(rooms => keysToCamel(rooms));
};

// API call for fetching nearby points of interests for a listing
// Param(s):
// latitude - latitude for area we want nearby points of interests for
// longitude - longitude for area we want nearby points of interests for
export const fetchNearbyPointsOfInterest = async (latitude, longitude) => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const listingUrl = `/nearby_points_of_interest?latitude=${encodeURIComponent(latitude)}&longitude=${encodeURIComponent(longitude)}`;

  return axios.get(listingUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => keysToCamel(result.data));
};

// API call for fetching leases for a listing
// Param(s):
// listingId - Listing ID or slug for listing we want leases for
export const fetchLeases = async listingId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;
  const slug = !String(listingId).match('^[0-9]*$');

  const listingUrl = `/api/v2/leases.list?${slug ? 'slug=' : 'listing_id='}${listingId}&recent=true`;

  return axios.get(listingUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => keysToCamel(result.data.leases));
};

// API call for fetching all general information on a listing
// Param(s):
// listingId - Listing ID or slug for the listing we want to fetch
const fetchListing = async listingId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;
  const slug = !String(listingId).match('^[0-9]*$');

  const listingUrl = `/api/v2/listings.fetch?${slug ? 'slug=' : 'listing_id='}${listingId}`;

  const listingRequest = axios.get(listingUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.listing)
    .then(listing => formatListing(listing));

  const imageRequest = fetchImages(listingId);
  const priceHistoryRequest = fetchPriceHistory(listingId);
  const interestedRoommatesRequest = fetchInterestedRoommates(listingId);

  return Promise.all([
    listingRequest,
    imageRequest,
    priceHistoryRequest,
    interestedRoommatesRequest,
  ])
    .then(([
      listing,
      images,
      priceHistory,
      interestedRoommates,
    ]) => ({
      ...listing,
      images,
      priceHistory,
      interestedRoommates,
    }));
};

// API call for fetching all matrix listings
// Param(s):
// listingId - Listing ID or slug for the listing we want to fetch
export const fetchMatrixListings = async listingIds => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const listingUrl = `/api/v2/listings.list?context=matrix&listing_ids=${listingIds}`;

  return axios.get(listingUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.listings)
    .then(listings => listings.map(listing => formatListing(listing)));
};

// API call for fetching general information on a list of listings
// Param(s):
// listingIds - Listing IDs for the listings we want to fetch
export const fetchListOfListings = async listingIds => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const listingUrl = `/api/v2/listings.list?listing_ids=${listingIds}`;

  return axios.get(listingUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.listings)
    .then(listings => listings.map(listing => formatListing(listing)));
};

// API call for liking a listing
// Param(s):
// listingId - Listing ID or slug for listing we want to like
export const likeListing = async listingId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;
  const slug = !String(listingId).match('^[0-9]*$');

  const listingUrl = `/api/v2/listings.like?${slug ? 'slug=' : 'listing_id='}${listingId}`;

  return axios.post(listingUrl, null, { headers: { API_TOKEN: apiToken }, cancelToken })
    .catch(err => ({
      okay: false,
      err,
    }));
};

// API call for unliking a listing
// Param(s):
// listingId - Listing ID or slug for listing we want to unlike
export const unlikeListing = async listingId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;
  const slug = !String(listingId).match('^[0-9]*$');

  const listingUrl = `/api/v2/listings.unlike?${slug ? 'slug=' : 'listing_id='}${listingId}`;

  return axios.post(listingUrl, null, { headers: { API_TOKEN: apiToken }, cancelToken })
    .catch(err => ({
      okay: false,
      err,
    }));
};

// API call to fetch nearby map markers
// Param(s):
// latitude - latitude for area we want nearby map markers for
// longitude - longitude for area we want nearby map markers for
export const fetchMapMarkers = async (latitude, longitude) => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const listingUrl = `/nearby_points_of_interest?latitude=${encodeURIComponent(latitude)}&longitude=${encodeURIComponent(longitude)}`;

  return axios.get(listingUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .catch(err => ({
      okay: false,
      err,
    }));
};

// API call to pull up list of listings that match searchParams
// Param(s):
// searchParams (Search Params Object holding filters for search):
// {
//  amenityList: Array of amenities,
//  address: Address String,
//  minBaths: Number of Minimum Bathrooms,
//  bedList: Array of Number of Bedrooms in a Listing,
//  liked: Boolean to see LikedOnly Listings,
//  marketAs: String for Listing Type To Filter,
//  moveIn: String for Move In Data,
//  noFee: Boolean to see NoFee Listings,
//  order: String for Sort By Filter,
//  pets: String for Pets Filter,
//  platformListing: Boolean to see SubletOnly Listings,
//  minPpsf: Number of Minimum Price per Square Foot,
//  maxPpsf: Number of Maximum Price per Square Foot,
//  minPrice: Number of Minimum Price,
//  maxPrice: Number of Maximum Price,
//  minSquareFeet: Number of Minimum Square Feet,
//  maxSquareFeet: Number of Maximum Square Feet,
//  network: String of Network Type for Listings to see,
//  page: Number of page number of search results to pull,
//  neighborhoodIdList: Array of Neighborhood Ids to see listings from,
//  regionIdList: Array of Region Ids to see listings from,
//  subwayIdList: Array of Subway Ids to see listings near to,
//  subwayStopList: Array of Subway Ids to see listings near to,,
//  topLeft: Array of Coordinates for Top Left of the Map,
//  bottomRight: Array of Coordinates for Bottom Right of the Map,
// }

export const searchListings = async searchParams => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const listingUrl = '/api/v2/listings.search';
  const formattedSearchParams = keysToSnake(searchParams);

  return axios.post(
    listingUrl,
    formattedSearchParams,
    { headers: { API_TOKEN: apiToken }, cancelToken },
  )
    .catch(err => ({
      okay: false,
      err,
    }))
    .then(results => keysToCamel(results));
};

// API call to pull up list of listings on sale that match searchParams
// Param(s):
// searchParams (Search Params Object holding filters for search):
// {
//  amenityList: Array of amenities,
//  address: Address String,
//  minBaths: Number of Minimum Bathrooms,
//  bedList: Array of Number of Bedrooms in a Listing,
//  liked: Boolean to see LikedOnly Listings,
//  marketAs: String for Listing Type To Filter,
//  moveIn: String for Move In Data,
//  noFee: Boolean to see NoFee Listings,
//  order: String for Sort By Filter,
//  pets: String for Pets Filter,
//  platformListing: Boolean to see SubletOnly Listings,
//  minPpsf: Number of Minimum Price per Square Foot,
//  maxPpsf: Number of Maximum Price per Square Foot,
//  minPrice: Number of Minimum Price,
//  maxPrice: Number of Maximum Price,
//  minSquareFeet: Number of Minimum Square Feet,
//  maxSquareFeet: Number of Maximum Square Feet,
//  network: String of Network Type for Listings to see,
//  page: Number of page number of search results to pull,
//  neighborhoodIdList: Array of Neighborhood Ids to see listings from,
//  regionIdList: Array of Region Ids to see listings from,
//  subwayIdList: Array of Subway Ids to see listings near to,
//  subwayStopList: Array of Subway Ids to see listings near to,,
//  topLeft: Array of Coordinates for Top Left of the Map,
//  bottomRight: Array of Coordinates for Bottom Right of the Map,
// }
export const searchSales = async searchParams => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const listingUrl = '/api/v2/sales.search';
  const formattedSearchParams = keysToSnake(searchParams);

  return axios.post(
    listingUrl,
    formattedSearchParams,
    { headers: { API_TOKEN: apiToken }, cancelToken },
  )
    .catch(err => ({
      okay: false,
      err,
    }))
    .then(results => keysToCamel(results));
};

// API call to pull up list of commercial listings that match searchParams
// Param(s):
// searchParams (Search Params Object holding filters for search):
// {
//  amenityList: Array of amenities,
//  address: Address String,
//  minBaths: Number of Minimum Bathrooms,
//  bedList: Array of Number of Bedrooms in a Listing,
//  liked: Boolean to see LikedOnly Listings,
//  marketAs: String for Listing Type To Filter,
//  moveIn: String for Move In Data,
//  noFee: Boolean to see NoFee Listings,
//  order: String for Sort By Filter,
//  pets: String for Pets Filter,
//  platformListing: Boolean to see SubletOnly Listings,
//  minPpsf: Number of Minimum Price per Square Foot,
//  maxPpsf: Number of Maximum Price per Square Foot,
//  minPrice: Number of Minimum Price,
//  maxPrice: Number of Maximum Price,
//  minSquareFeet: Number of Minimum Square Feet,
//  maxSquareFeet: Number of Maximum Square Feet,
//  network: String of Network Type for Listings to see,
//  page: Number of page number of search results to pull,
//  neighborhoodIdList: Array of Neighborhood Ids to see listings from,
//  regionIdList: Array of Region Ids to see listings from,
//  subwayIdList: Array of Subway Ids to see listings near to,
//  subwayStopList: Array of Subway Ids to see listings near to,,
//  topLeft: Array of Coordinates for Top Left of the Map,
//  bottomRight: Array of Coordinates for Bottom Right of the Map,
// }
export const searchCommercials = async searchParams => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const listingUrl = '/api/v2/commercials.search';
  const formattedSearchParams = keysToSnake(searchParams);

  return axios.post(
    listingUrl,
    formattedSearchParams,
    { headers: { API_TOKEN: apiToken }, cancelToken },
  )
    .catch(err => ({
      okay: false,
      err,
    }))
    .then(results => keysToCamel(results));
};

export default fetchListing;
