import React, { useEffect } from 'react';
import { ButtonSave, ButtonStepper } from '../Buttons';
import ContainerWrapper from '../ContainerWrapper';

const title = "What's the pet situation?";

const Pets = (props) => {
  const petOptions = ['All Pets Allowed', 'Pets Upon Approval', 'No Pets Allowed'];
  const {
    petPolicy, setPetPolicy, userIsEditing, setUserIsEditing,
  } = props;

  useEffect(() => {
    document.title = `Create: Apartments - ${title}`;
  }, []);

  return (
    <ContainerWrapper>
      <div>
        <div className="title">
          <h3>{title}</h3>
        </div>
        <form
          className="narrow"
          onKeyDown={(evt) => {
            if (evt.which === 13) evt.preventDefault();
          }}
        >
          {petOptions.map(petOption => (
            <div key={petOption} className="checkbox-row" onClick={() => setPetPolicy(petOption)}>
              <label className={petPolicy === petOption ? 'active' : ''}>
                {petOption}
                {petPolicy === petOption && <i className="nklyn-3x nookons-check-circle active" />}
              </label>
              <input type="checkbox" className="float-right" />
            </div>
          ))}
          {userIsEditing ? (
            <ButtonSave
              handleJump={props.handleJump}
              setUserIsEditing={setUserIsEditing}
              jumpTo={props.jumpTo || 'ApartmentReview'}
            />
          ) : (
            <ButtonStepper
              handleNext={props.handleNext}
              handleBack={props.handleBack}
              disabled={!petPolicy}
            />
          )}
        </form>
      </div>
    </ContainerWrapper>
  );
};

export default Pets;
