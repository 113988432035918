import React, { Component } from 'react';
import { throttle } from '../../helpers/animations';

export default class DraggableTag extends Component {

  state = {
    startPosition: this.props.startPosition, // start position mousedown event
    currentPosition: 0, // count current translateX value
    distancePosition: 0, // count distance between 'down' & 'move' event
    needForRAF: true,  // to prevent redundant rAF calls
  }
  //
  componentDidMount() {
    const { left, offset } = this.props;

    if ('onmousemove' in this.me) {
      this.mousemove = document.addEventListener('mousemove', this.dragTagMove);
    }
    if ('ontouchmove' in this.me) {
      this.touchmove = document.addEventListener('touchmove', this.dragTagMove);
    }
    if ('onmousedown' in this.me) {
        this.mouseup = document.addEventListener('mouseup', this.mouseUp);
    }
    if ('ontouchend' in this.me) {
      this.touchend =  document.addEventListener('touchend', this.mouseUp);
    }
    this.overflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    this.me.style.zIndex= 30;
    this.me.style.transform = `translate(${left}px, -${offset}px)`;

  }

  mouseUp = () => {
    this.props.mouseUp();
    if (this.animation) {
      cancelAnimationFrame(this.animation)
    }
    document.body.style.overflow = this.overflow;
      document.removeEventListener('mousemove', this.dragTagMove)
      document.removeEventListener('mouseup', this.mouseUp)
      document.removeEventListener('touchmove', this.dragTagMove, false);
      document.removeEventListener('touchend', this.mouseUp)
      document
  }

  update = () => {
    const { left } = this.props;
    const { distancePosition } = this.state;
    this.setState({ needForRAF: true });
    // rAF now consumes the movement instruction so a new one can come
    if (this.me) {
      this.me.style.transform = 'translate(' + `${left}px, ` + (distancePosition) + 'px)';// move it!
      let position = this.me.style.transform
    }
  }

  componentWillUnmount() {
    if (this.animation) {
      cancelAnimationFrame(this.animation)
    }
    if (this.mousemove){
      document.removeEventListener('mousemove', this.dragTagMove)}
    if (this.mouseup){
      document.removeEventListener('mouseup', this.mouseUp)}
    if (this.touchmove){
      document.removeEventListener('touchmove', this.dragTagMove, false);
    }
    if (this.touchend){
      document.removeEventListener('touchend', this.mouseUp)}
  }

   dragTagMove = throttle((e) => {
     e.preventDefault();
     e.stopPropagation();
     const { startPosition, needForRAF } = this.state;
     const { offset } = this.props;
     let clientX = e.clientX || e.touches[0].clientX;
     let clientY = e.clientY || e.touches[0].clientY;
     this.setState({
       distancePositionX: clientX,
       distanceTraveled: clientY - startPosition,
       distancePosition: (clientY - startPosition - offset - 10)
     }, () => {
       if (needForRAF) {
         this.setState({ needForRAF: false });
         // no need to call rAF up until next frame
        this.animation = requestAnimationFrame(this.update);
         // request 60fps animation
       }
     });
     this.checkCollideWithSiblings();
   }, 1);

    checkCollideWithSiblings = () => {
      const { index, height, shiftChosen } = this.props;
      const { distanceTraveled, distancePosition } = this.state;
      let el = this.me;
      let intIndex = parseInt(index);
      let numberTraveled = ((distanceTraveled - 10) / height);
      let targetIndex
      if (numberTraveled > 0.75) {
        targetIndex = intIndex + Math.round(numberTraveled)
      } else if (numberTraveled < -0.75) {
        targetIndex = intIndex + Math.round(numberTraveled)
      } else {
        targetIndex = intIndex
      }
      if (el) {
          shiftChosen(targetIndex);
      }
    }



    render() {
      const { height, option } = this.props;
      return (
        <div
          className='tag-container'
          ref={me => this.me = me}
          style={{
            position: 'absolute',
            height: `${height + 2}`,
            padding: '2px',
            opacity: '0.95'
          }}
        >
          <input
            onMouseEnter={this.onHover}
            type='button'
            className='option-tag'
            value={option}
            readOnly>
          </input>
          <button
            style={{ position:'relative' }}
            className='round-x'>x
          </button>
        </div>
      );
    }
}
