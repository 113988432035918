import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import buildReducer from 'pro/util/buildReducer';

import {
  FETCH_LISTING,
  FETCH_LISTING_ROOMS,
  FETCH_ROOM,
  FETCH_USER_LISTINGS,
  INVALIDATE_LISTING,
  INVALIDATE_ROOM,
  RECEIVE_LISTING,
  RECEIVE_LISTING_ROOMS,
  RECEIVE_ROOM,
  RECEIVE_USER_LISTINGS,
  UPDATE_LISTING_ATTRIBUTE,
} from './actions';

const initialState = {
  user: {
  },
  listings: {
  },
  rooms: {
  },
};

const fetchListing = (state, action) => {
  const { listingId } = action;

  const { listings: { [listingId]: listing = {} } } = state;

  if (typeof listing.storageId === 'undefined') {
    listing.storageId = uuidv4();
  }

  return {
    ...state,
    listings: {
      ...state.listings,
      [listingId]: {
        ...listing,
        isFetching: true,
        didInvalidate: false,
      },
    },
  };
};

const fetchRoom = (state, action) => {
  const { roomId } = action;

  const { rooms: { [roomId]: room = {} } } = state;

  if (typeof room.storageId === 'undefined') {
    room.storageId = uuidv4();
  }

  return {
    ...state,
    rooms: {
      ...state.rooms,
      [roomId]: {
        ...room,
        isFetching: true,
        didInvalidate: false,
      },
    },
  };
};

const fetchListingRooms = (state, action) => {
  const { listingId } = action;

  const {
    listings: {
      [listingId]: listing,
    },
  } = state;

  return {
    ...state,
    listings: {
      ...state.listings,
      [listingId]: {
        ...listing,
        rooms: {
          isFetching: true,
          didInvalidate: false,
          data: [],
        },
      },
    },
  };
};

const fetchUserListings = (state, action) => {
  return {
    ...state,
    user: {
      ...state.user,
      listings: {
        isFetching: true,
        didInvalidate: false,
        data: [],
      },
    },
  };
};

const invalidateListing = (state, action) => {
  const { listingId } = action;

  const {
    listings: {
      [listingId]: listing,
    },
  } = state;

  return {
    ...state,
    listings: {
      ...state.listings,
      [listingId]: {
        ...listing,
        didInvalidate: true,
      },
    },
  };
};

const invalidateRoom = (state, action) => {
  const { roomId } = action;

  const {
    rooms: {
      [roomId]: room,
    },
  } = state;

  const { data: { listingId } } = room;

  const {
    listings: {
      [listingId]: listing,
    },
  } = state;

  const updatedListings = {
    ...state.listings,
  };

  if (typeof listing !== 'undefined') {
    updatedListings[listingId] = {
      ...listing,
      rooms: {
        ...listing.rooms,
        didInvalidate: true,
      },
    };
  }

  const updatedRooms = {
    ...state.rooms,
    [roomId]: {
      ...room,
      didInvalidate: true,
    },
  };

  return {
    ...state,
    listings: {
      ...updatedListings,
    },
    rooms: {
      ...updatedRooms,
    },
  };
};

const receiveListing = (state, action) => {
  const { listing, receivedAt } = action;
  const listingId = listing.id

  const {
    listings: {
      [listingId]: oldStorageContainer,
    },
  } = state;

  const storageContainer = {
    ...oldStorageContainer,
    isFetching: false,
    didInvalidate: false,
    lastUpdated: receivedAt,
    data: listing,
  };

  return {
    ...state,
    listings: {
      ...state.listings,
      [listingId]: storageContainer,
    },
  };
};

const receiveListingRooms = (state, action) => {
  const { listingId, roomIds, receivedAt } = action;

  const {
    listings: {
      [listingId]: listing,
    },
  } = state;

  return {
    ...state,
    listings: {
      ...state.listings,
      [listingId]: {
        ...listing,
        rooms: {
          ...listing.rooms,
          isFetching: false,
          lastUpdated: receivedAt,
          data: roomIds,
        },
      },
    },
  };
};

const receiveRoom = (state, action) => {
  const { room, receivedAt } = action;
  const roomId = room.id;

  const {
    rooms: {
      [roomId]: oldStorageContainer = {},
    },
  } = state;

  if (typeof oldStorageContainer.storageId === 'undefined') {
    oldStorageContainer.storageId = uuidv4();
  }

  const storageContainer = {
    ...oldStorageContainer,
    isFetching: false,
    didInvalidate: false,
    lastUpdated: receivedAt,
    data: room,
  };

  return {
    ...state,
    rooms: {
      ...state.rooms,
      [roomId]: storageContainer,
    },
  };
};

const receiveUserListings = (state, action) => {
  const { listingIds } = action;

  return {
    ...state,
    user: {
      ...state.user,
      listings: {
        isFetching: false,
        lastUpdated: Date.now(),
        data: listingIds,
      },
    },
  };
};

const updateListingAttribute = (state, action) => {
  const { listingId, attribute, value } = action;

  const {
    listings: {
      [listingId]: listing,
    },
  } = state;

  if (typeof listing === 'undefined') {
    return state;
  }

  return {
    ...state,
    listings: {
      [listingId]: {
        ...listing,
        data: {
          ...listing.data,
          [attribute]: value,
        },
      },
    },
  };
};

const handleAction = (state, action) => {
  switch (action.type) {
    case FETCH_LISTING:
      return fetchListing(state, action);
    case FETCH_LISTING_ROOMS:
      return fetchListingRooms(state, action);
    case FETCH_ROOM:
      return fetchRoom(state, action);
    case FETCH_USER_LISTINGS:
      return fetchUserListings(state, action);
    case INVALIDATE_LISTING:
      return invalidateListing(state, action);
    case INVALIDATE_ROOM:
      return invalidateRoom(state, action);
    case RECEIVE_LISTING:
      return receiveListing(state, action);
    case RECEIVE_ROOM:
      return receiveRoom(state, action);
    case RECEIVE_LISTING_ROOMS:
      return receiveListingRooms(state, action);
    case RECEIVE_USER_LISTINGS:
      return receiveUserListings(state, action);
    case UPDATE_LISTING_ATTRIBUTE:
      return updateListingAttribute(state, action);
    default:
      return state;
  }
};

const [reducer, getState] = buildReducer(handleAction, initialState);

export default reducer;

export { getState, initialState };
