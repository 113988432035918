import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import JumbotronAgreement from './JumbotronAgreement'
import JumbotronPay from './JumbotronPay'
import JumbotronRentalApp from './JumbotronRentalApp'
import { setMessage } from '../../reducers/flashMessage'
import { fetchLeaseClient } from '../../reducers/lease'
import JumbotronDisclosure from './JumbotronDisclosure'
import JumbotronContinueApply from './JumbotronContinueApply'

class Jumbotron extends React.Component {
  state = {
    rental_application: {},
  }

  static defaultProps = {
    lease: {},
    lease_client: {},
    lease_client_type: '',
    feedback: '',
  }

  componentDidMount() {
    const { lease_client_id, fetchLeaseClient } = this.props

    if (lease_client_id) {
      fetchLeaseClient(lease_client_id)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.lease_client.rental_application_id !== prevProps.lease_client.rental_application_id &&
      this.props.lease_client.rental_application_id
    ) {
      this.fetchRentalApp()
    }
  }

  fetchRentalApp = () => {
    const { lease_client } = this.props
    const { rental_application } = this.state
    const rentalId = lease_client.rental_application_id || rental_application.id
    if (rentalId) {
      axios
        .get(`/rental_applications/${rentalId}.json`)
        .then((res) => {
          this.setState({ rental_application: res.data })
        })
        .catch((err) => console.dir(err))
    }
  }

  updateRentalApplication = (newData) => {
    this.setState({ rental_application: newData })
  }

  render() {
    const { lease, fetchLeaseClient, lease_client } = this.props
    const { rental_application } = this.state

    const hasSignedOrSkippedAt = rental_application.disclosure_signed_at || rental_application.disclosure_skipped_at

    return (
      <div className="container">
        {!lease_client.full_legal_name && <JumbotronAgreement {...this.props} fetchLeaseClient={fetchLeaseClient} />}
        {!rental_application.disclosure_signed_at &&
          !rental_application.disclosure_skipped_at &&
          lease_client.full_legal_name &&
          rental_application.status === 'pending' && (
            <JumbotronDisclosure
              fetchRentalApp={this.fetchRentalApp}
              rental_application={rental_application}
              {...this.state}
              {...this.props}
            />
          )}
        {lease_client.full_legal_name &&
          lease.accepts_nooklyn_apply &&
          ((rental_application.id && rental_application.status !== 'submitted') || !rental_application.id) && (
            <>
              {rental_application.id && hasSignedOrSkippedAt && (
                <JumbotronContinueApply {...this.state} {...this.props} />
              )}

              {!this.props.lease_client.rental_application_id && rental_application.status !== 'pending' && (
                <JumbotronRentalApp
                  updateRentalApplication={this.updateRentalApplication}
                  {...this.state}
                  {...this.props}
                />
              )}
            </>
          )}
        {rental_application && rental_application.status === 'submitted' && <JumbotronPay {...this.props} />}
      </div>
    )
  }
}
const mapState = (state) => ({
  lease_client: state.lease.leaseClient,
})

const mapDispatch = (dispatch) => ({
  setMessage(message) {
    dispatch(setMessage(message))
  },
  fetchLeaseClient(lease_client_id) {
    dispatch(fetchLeaseClient(lease_client_id))
  },
})

export default connect(mapState, mapDispatch)(Jumbotron)
