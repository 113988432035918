import React from 'react';
import PropTypes from 'prop-types';
import { AsyncSubscribe } from 'asyncHandlers/AsyncBase';
import ListingCard from 'components/listings/cards/Listings';

const AgentListings = ({
  listings,
}) => (
  listings.length ? listings.map(listing => (
    <ListingCard
      wrapperProps={{
        className: 'nooklyn_listing_square nklyn-listing roommate_profile_listing_square',
      }}
      key={listing.id}
      listing={listing}
      signedIn={false}
    />
  ))
    : null
);

AgentListings.defaultProps = {
  listings: [],
};

AgentListings.propTypes = {
  listings: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

export default AsyncSubscribe(['listings'])(AgentListings);
