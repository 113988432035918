import React, { useState } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

const inputStyle = {
  base: {
    fontSize: 14,
    color: '#ffffff',
    letterSpacing: '0.025em',
    fontWeight: '400',
    fontFamily:
      '"Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif',
    '::placeholder': {
      color: '#686868',
    },
  },
  invalid: {
    color: '#F45536',
  },
};

const SimpleCardForm = props => {
  const {
    onCancel,
    onSetupIntentConfirmed = () => {},
    setupIntent,
    disabled,
    setDisabled,
  } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [name, setName] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [numberComplete, setNumberComplete] = useState(false);
  const [expiryComplete, setExpiryComplete] = useState(false);
  const [cvcComplete, setCVCComplete] = useState(false);
  const [error, setError] = useState('');

  const nameComplete = name.length > 0;

  const handleSubmit = evt => {
    evt.preventDefault();
    setDisabled(true);

    const cardElement = elements.getElement(CardNumberElement);

    stripe.confirmCardSetup(setupIntent.clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name,
          address: {
            postal_code: zipcode,
          },
        },
      },
    })
      .then(res => {
        if (res.setupIntent) {
          setError('');
          onSetupIntentConfirmed(res.setupIntent.payment_method);
        } else if (res.error) {
          setError(res.error.message);
          setDisabled(false);
        }
      })
      .catch(() => {
        setError('An error has occured. Please try again later or contact Nooklyn Support.');
        setDisabled(false);
      });
  };

  const handleCancel = evt => {
    evt.preventDefault();

    onCancel();
  };

  const complete = cvcComplete
    && expiryComplete
    && nameComplete
    && numberComplete;

  return (
    <>
      <br />
      <br />
      <br />
      <h6 className="standard-type nklyn-white">Payment Method</h6>
      <br />
      <div className="row payment-card-row">
        <div className="col-12">
          <label className="general-text-label-dark">
            Billing Name
          </label>
          <input
            type="text"
            className="general-text-field-dark"
            placeholder="Name on Card"
            onChange={e => setName(e.target.value)}
          />
        </div>
      </div>
      <div className="row payment-card-row">
        <div className="col-12">
          <label className="general-text-label-dark">
            Credit Card #
          </label>
          <CardNumberElement
            options={{ style: inputStyle }}
            onChange={e => setNumberComplete(e.complete)}
          />
        </div>
      </div>
      <div className="row payment-card-row">
        <div className="col-4">
          <label className="general-text-label-dark">
            Expiry
          </label>
          <CardExpiryElement
            options={{ style: inputStyle }}
            onChange={e => setExpiryComplete(e.complete)}
          />
        </div>
        <div className="col-4">
          <label className="general-text-label-dark">
            CV2
          </label>
          <CardCvcElement
            options={{ style: inputStyle }}
            onChange={e => setCVCComplete(e.complete)}
          />
        </div>
        <div className="col-4">
          <label className="general-text-label-dark">
            Zip Code
          </label>
          <input
            className="StripeElement StripeElement--empty"
            options={{ style: inputStyle }}
            onChange={e => setZipcode(e.target.value)}
          />
        </div>
      </div>
      <br />
      { error && <p className="error text-danger">{error}</p>}
      <button
        type="submit"
        className="button btn-rounded btn-yellow-black"
        onClick={handleSubmit}
        disabled={!complete || disabled}
      >
        Save this Payment Method
      </button>
      { !!onCancel && (
        <button
          type="submit"
          className="button btn-rounded btn-transparent"
          onClick={handleCancel}
        >
          Cancel
        </button>
      )}
    </>
  );
};

export default SimpleCardForm;
