import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from 'components/LoadingSpinner';
import loggedIn from 'helpers/loggedIn';
import { fetchUser } from 'components/renterForms/api';
import CopyTextButton from './CopyTextButton';

const CopyUrlButton = props => {
  const { children } = props;
  const [shareUrl, setShareUrl] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loggedIn()
      .then(res => {
        if (res === false) {
          setShareUrl(`https://nooklyn.com${window.location.pathname}`);
          setLoading(false);
        } else {
          fetchUser()
            .then(user => {
              const loggedInAgent = user.data.current;
              if (loggedInAgent.account_type === 'nooklyn_agent') {
                const paramsToShare = loggedInAgent.agent_id ? `?sales-agent=${loggedInAgent.agent_id}` : '';
                setShareUrl(`https://nooklyn.com${window.location.pathname}${paramsToShare}`);
                setLoading(false);
              } else {
                setShareUrl(`https://nooklyn.com${window.location.pathname}`);
                setLoading(false);
              }
            });
        }
      });
  }, []);

  return (
    <CopyTextButton
      text={shareUrl}
      id="copy-url-button-clipboard"
    >
      {(loading === false)
        ? children
        : <LoadingSpinner />}
    </CopyTextButton>
  );
};

CopyUrlButton.defaultProps = {
  children: null,
};

CopyUrlButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default CopyUrlButton;
