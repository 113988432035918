import React, { Component } from 'react';
import CreateListingCollectionModalContent from './CreateListingCollectionModalContent';
import { Modal } from 'wrappers/FlexTable';
import Fade from 'wrappers/Fade';
import ReactDOM from 'react-dom';
import { debounce } from 'helpers/animations';

export default class CreateListingCollectionModal extends Component {

  state = {
    start: 'rotate-start',
  }

  componentDidMount() {
    this.setState({ start: 'rotate-close' });
    this.positionModal();
    this.callback = debounce(() => {
      this.positionModal();
    }, 200);
    const callback = (e) => {
      if (e.propertyName === 'transform') {
        this.setState({start: 'rotate-close'});
      }
    }
    window.addEventListener('transitions', callback);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.callback)
  }

  positionModal = () => {
    let node = ReactDOM.findDOMNode(this.node)
    let offsetTop = (window.innerHeight - node.offsetHeight) / 2
    let offsetLeft = (window.innerWidth - node.offsetWidth) / 2
    node.style.top = offsetTop;
    node.style.left = offsetLeft;
  }

  render() {
    const { closeModal, visible } = this.props;
    const { start } = this.state;
    return (
      <Modal
        ref={me => this.node = me}
        overlayStyle={{
          cursor: 'default'
        }}
        contentStyle={{
          position:'absolute',
          backgroundColor: 'transparent',
          bottom: '',
          width: '90%',
          maxWidth: '780px',
          height: '572px',
          border: '0px',
          opacity: '1',
          cursor: '',
        }}
      >
      <Fade
        customStyles={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#fff',
          padding: '10px',
          width: '100%',
          borderRadius: '5px',
        }}
        visible={visible}
        fadeStart={start}
        fadeEnd='rotate-end'
        >
        <div style={{overflow: 'scroll'}}>
        <button
          style={{ zIndex:'1000', width: '20px', cursor: 'pointer', position: 'absolute', right: 0, top: 0, margin: '20px' }}
          className="close"
          onClick={closeModal}>
          <i className="nookons-close" />
        </button>
        <CreateListingCollectionModalContent closeModal={closeModal} />
        </div>
      </Fade>
      </Modal>
    );
  }
}
