import React from 'react';
import PropTypes from 'prop-types';
import RoomCardImage from './RoomCardImage';
import RoomCardBottom from './RoomCardBottom';

const RoomCard = props => {
  const {
    wrapperProps,
    room,
    signedIn,
  } = props;

  const {
    image,
    url,
    id,
  } = room;

  return (
    <div
      id={`listings_card_${id}`}
      className="nooklyn_listing_square nklyn-listing flex-listing"
      {...wrapperProps}
    >
      <RoomCardImage
        image={image}
        url={url}
        room={room}
      />
      <RoomCardBottom room={room} signedIn={signedIn} />
    </div>
  );
};

RoomCard.defaultProps = {
  wrapperProps: {},
  signedIn: false,
};

RoomCard.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.shape({
      wide: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
  wrapperProps: PropTypes.shape({
    ref: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    onMouseOver: PropTypes.func,
  }),
  signedIn: PropTypes.bool,
};

export default RoomCard;
