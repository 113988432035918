/* eslint-disable import/prefer-default-export */
import React from 'react'
import { connect } from 'react-redux'
import Modal from '../Modal'
import SkipDisclosuresContent from './SkipDisclosuresContent'
import { submitForm, submitted } from '../../../reducers/form'

class SkipDisclosures extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
    }
  }

  openModal = () => {
    this.setState({ modalIsOpen: true })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
  }

  render() {
    const { className, children } = this.props
    const { modalIsOpen } = this.state

    return (
      <div className={className}>
        <button className="button btn-rounded btn-transparent-yellow" onClick={this.openModal}>
          {children}
        </button>
        {modalIsOpen && (
          <Modal
            className="Modal__Bootstrap modal-dialog dark-gray-container agreement-modal"
            closeTimeoutMS={150}
            isOpen={modalIsOpen}
            onRequestClose={this.closeModal}
          >
            <div className="modal-content modal-content-dark">
              <button className="close" onClick={this.closeModal}>
                <i className="nookons-close-circle-fill" />
              </button>
              <SkipDisclosuresContent handleModalClose={this.closeModal} {...this.props} />
            </div>
          </Modal>
        )}
      </div>
    )
  }
}

const mapState = (state) => ({
  submitting: state.form.submitting,
})

const mapDispatch = (dispatch) => ({
  submitForm() {
    dispatch(submitForm())
  },
  submitted() {
    dispatch(submitted())
  },
})

export const SkipDisclosuresConnected = connect(mapState, mapDispatch)(SkipDisclosures)
