import React from 'react';
import PropTypes from 'prop-types';
import { ImageAnnotation } from 'components/AppleMap';
import {
  appleCurrentImageMarker,
  appleLocationImageMarker,
  appleResidentialImageMarker,
} from 'helpers/mapHelpers';
import missing from 'images/logo/white-on-black-square.png';

const ListingNearbyPOIMarkers = props => {
  const {
    markers,
    selectedListing,
    map,
  } = props;

  const {
    latitude,
    longitude,
    shortAddress,
    creator,
    network,
  } = selectedListing || {};

  const { creatorName } = creator || {};
  const platform = (network === 'platform');
  const contextLat = parseFloat(latitude);
  const contextLon = parseFloat(longitude);

  return (
    <>
      {markers && markers.map(marker => (
        <ImageAnnotation
          map={map}
          key={marker.id}
          url={(marker.type === 'apartment' || marker.type === 'building') ? appleResidentialImageMarker : appleLocationImageMarker}
          latitude={marker.latitude}
          longitude={marker.longitude}
          title={marker.title}
          subtitle={marker.subtitle}
        >
          <a href={marker.url}>
            <div className="nearby-callout-content">
              <div className="nearby-callout-img-container">
                <img src={marker.imageUrl} alt={marker.subtitle} className={`nearby-callout-img ${marker.type === 'apartment' && 'img-fluid'}`} onError={e => { e.target.onerror = null; e.target.src = missing; }} />
              </div>
              <div className="nearby-callout-title">{marker.title}</div>
              <div className="nearby-callout-subtitle">{marker.subtitle}</div>
            </div>
          </a>
        </ImageAnnotation>
      ))}
      {contextLat && (
      <ImageAnnotation
        map={map}
        latitude={contextLat}
        longitude={contextLon}
        selected
        title={platform ? `${creatorName}'s home` : shortAddress}
        url={appleCurrentImageMarker}
      />
      )}
    </>
  );
};

ListingNearbyPOIMarkers.defaultProps = {
  map: null,
};

ListingNearbyPOIMarkers.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      imageUrl: PropTypes.string,
      subtitle: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string,
      url: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  ).isRequired,
  selectedListing: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    shortAddress: PropTypes.string,
    creator: PropTypes.shape({}),
    network: PropTypes.string,
  }).isRequired,
  map: PropTypes.shape({}),
};

export default ListingNearbyPOIMarkers;
