import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { keysToCamel } from 'pro/util/inflector';
import Modal from '../modal/Modal';
import {
  clearPayment,
  createPayment,
  submitForm,
  updateLinkToken,
  getError,
  updateAchPaymentChargeDescriptor,
  generateStripePaymentIntent,
  cancelStripePaymentIntent,
} from '../../reducers/payment';
import PaymentAmount from './PaymentAmount';
import PaymentForm from './PaymentForm';
import PaymentCredit from './PaymentCredit';
import PaymentBank from './PaymentBank';
// import PaymentApple from './payment/PaymentApple';
import FlashMessage from '../FlashMessage';
import AgreementModalContent from '../modal/AgreementModalContent';
import { fetchLeaseClient } from '../../reducers/lease';
import { submitted } from '../../reducers/form';
import axios from 'axios';

const getCookieValue = name => (
  document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || ''
);

class PayModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      payWithBank: false,
      payWithCredit: false,
      closeWarning: false,
      achPaymentDetails: {},
      oauthFlow: false,
      oauthStateId: '',
    };
  }

  componentDidMount() {
    const { fetchLeaseClient, lease_client_id, lease_client } = this.props;

    const searchParams = new URLSearchParams(window.location.search);
    const oauthStateId = searchParams.get('oauth_state_id');

    if (oauthStateId) {
      const achPaymentChargeDescriptor = getCookieValue('ach_payment_charge_descriptor');
      if (achPaymentChargeDescriptor) {
        this.fetchPaymentDescription(achPaymentChargeDescriptor);
      } else {
        window.location.href = window.location.href.split('?oauth_state_id')[0];
      }

      this.setState({
        modalIsOpen: true,
        payWithBank: true,
        oauthFlow: true,
        oauthStateId,
      });
    }

    if (fetchLeaseClient && lease_client_id) fetchLeaseClient(lease_client_id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.ok) {
      $('.ReactModal__Overlay').scrollTop(0);
    }
  }

  fetchPaymentDescription = paymentDescriptor => {
    const apiToken = localStorage.getItem('apiToken');
    const { updateLinkToken, getError } = this.props;

    fetch('/api/v2/payments.describe_payment', {
      headers: {
        API_TOKEN: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ payment_descriptor: decodeURIComponent(paymentDescriptor) }),
    }).then(response => response.json())
      .then(response => keysToCamel(response))
      .then(response => {
        const {
          ok,
          paymentDescription = {},
        } = response;

        if (ok) {
          paymentDescription.signature = paymentDescription.id;
          this.setState({ achPaymentDetails: paymentDescription });
          updateLinkToken(paymentDescription.linkToken);
        } else {
          getError({ errors: { oauth: 'An error occurred. Please try again after page redirects.' } });
          setTimeout(() => {
            window.location.href = window.location.href.split('?oauth_state_id')[0];
          }, 200);
        }
      })
      .catch(() => {
        getError({ errors: { oauth: 'An error occurred. Please try again after page redirects.' } });
        setTimeout(() => {
          window.location.href = window.location.href.split('?oauth_state_id')[0];
        }, 200);
      });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  handleClose = () => {
    this.setState({ closeWarning: true });
  };

  handleUnclose = () => {
    this.setState({ closeWarning: false });
  };

  closeModal = () => {
    const { paymentIntent, clearPayment, cancelStripePaymentIntent, creditPaymentRequest } = this.props;
    this.setState({
      modalIsOpen: false,
      payWithBank: false,
      payWithCredit: false,
      closeWarning: false,
    });
    clearPayment();
    if (this.state.oauthFlow) {
      window.location.href = window.location.href.split('?')[0];
    }
    if (paymentIntent && paymentIntent.id) {
      cancelStripePaymentIntent(creditPaymentRequest.signature, paymentIntent.id);
    }
  };

  handleCredit = () => {
    this.setState({
      payWithCredit: true,
      payWithBank: false,
      closeWarning: false,
    });
  };

  handleBank = () => {
    const { updateAchPaymentChargeDescriptor, lease_id, achPaymentRequest} = this.props;
    this.setState({
      payWithBank: true,
      payWithCredit: false,
      closeWarning: false,
    });
    updateAchPaymentChargeDescriptor(lease_id, achPaymentRequest.id)
  };

  logPlaidInitiatedAfterOauth = (body, payment_descriptor) => {
    const cancelToken = axios.CancelToken.source().token;
    const apiToken = localStorage.getItem('apiToken');
    axios.post('/api/v2/payments.audit_record.log.plaid_initiated_after_oauth', {body, payment_descriptor}, { headers: { API_TOKEN: apiToken }, cancelToken });
  };

  render() {
    const {
      payWithBank, payWithCredit, closeWarning, achPaymentDetails, oauthFlow, oauthStateId,
    } = this.state;
    const {
      achPaymentRequest,
      creditPaymentRequest,
      createPayment,
      ok,
      submitForm,
      submitted,
      submitting,
      errors,
      children,
      className,
      lease_client,
      fetchLeaseClient,
      lease_id,
      linkToken,
      paymentIntent,
      generateStripePaymentIntent,
      cancelStripePaymentIntent,
    } = this.props;

    const achApiKey = achPaymentRequest?.stripeKey ?? achPaymentDetails.stripeKey;


    return (
      <div className={className}>
        <div onClick={this.openModal}>{children}</div>
        <Modal
          className="Modal__Bootstrap modal-dialog dark-gray-container payment-modal"
          closeTimeoutMS={150}
          isOpen={this.state.modalIsOpen}
        >
          <div className="modal-content">
            {ok && <FlashMessage message="Payment successful!" />}
            { errors && errors.oauth && <FlashMessage message={errors.oauth} />}
            <div>
              {closeWarning ? (
                <div className="pt-3 text-center">
                  You haven't finished filling out the form. Are you sure you want to exit?
                  <button
                    className="button btn-rounded btn-1x btn-white-black ml-2"
                    onClick={this.handleUnclose}
                  >
                    Cancel
                  </button>
                  <button
                    className="button btn-rounded btn-1x btn-black-white ml-2"
                    onClick={this.closeModal}
                  >
                    Yes, exit
                  </button>
                </div>
              ) : (
                <button className="close" onClick={this.handleClose}>
                  <i className="nookons-close-circle-fill" />
                </button>
              )}
              {!lease_client.full_legal_name && !oauthFlow && (
              <AgreementModalContent
                submitForm={submitForm}
                lease_client_id={lease_client.id}
                fetchLeaseClient={fetchLeaseClient}
                submitted={submitted}
              />
              )}
              {lease_client.full_legal_name
                && !creditPaymentRequest
                && !achPaymentRequest && !oauthFlow && (
                <PaymentAmount lease_id={this.props.lease_id} submitForm={submitForm} />
              )}
              {creditPaymentRequest
                  && achPaymentRequest
                  && !payWithBank
                  && !payWithCredit && (
                    <PaymentForm
                      achPaymentRequest={achPaymentRequest}
                      creditPaymentRequest={creditPaymentRequest}
                      payWithBank={this.handleBank}
                      payWithCredit={this.handleCredit}
                    />
              )}
              {payWithBank && achApiKey && (
                <Elements stripe={loadStripe(achApiKey)}>
                  <PaymentBank achPaymentRequest={achPaymentRequest || achPaymentDetails} lease_id={lease_id} linkToken={linkToken} oauthFlow={oauthFlow} oauthStateId={oauthStateId} logPlaidInitiatedAfterOauth={this.logPlaidInitiatedAfterOauth} />
                </Elements>
              )}
              {payWithCredit && (
                <Elements stripe={loadStripe(creditPaymentRequest.stripeKey)}>
                  <PaymentCredit
                    creditPaymentRequest={creditPaymentRequest}
                    createPayment={createPayment}
                    submitForm={submitForm}
                    submitting={submitting}
                    errors={errors}
                    paymentIntent={paymentIntent}
                    generateStripePaymentIntent={generateStripePaymentIntent}
                    cancelStripePaymentIntent={cancelStripePaymentIntent}
                  />
                </Elements>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

PayModal.defaultProps = {
  lease_client: {},
  linkToken: '',
};

const mapState = (state, ownProps) => ({
  signupStatus: state.auth.signupStatus,
  selectRegionStatus: state.auth.selectRegionStatus,
  payment: state.payment,
  paymentToken: ownProps.token,
  achPaymentRequest: state.payment.achPaymentRequest,
  creditPaymentRequest: state.payment.creditPaymentRequest,
  ok: state.payment.ok,
  submitting: state.payment.submitting,
  errors: state.payment.errors,
  lease_client: state.lease.leaseClient,
  linkToken: state.payment.linkToken,
  errors: state.payment.errors,
  paymentIntent: state.payment.paymentIntent,
});

const mapDispatch = dispatch => ({
  clearPayment() {
    dispatch(clearPayment());
  },
  createPayment(payment_request, signature) {
    dispatch(createPayment(payment_request, signature));
  },
  submitForm() {
    dispatch(submitForm());
  },
  fetchLeaseClient(lease_client_id) {
    dispatch(fetchLeaseClient(lease_client_id));
  },
  submitted() {
    dispatch(submitted());
  },
  updateLinkToken(linkToken) {
    dispatch(updateLinkToken(linkToken));
  },
  getError(error) {
    dispatch(getError(error));
  },
  updateAchPaymentChargeDescriptor(lease_id, signature) {
    dispatch(updateAchPaymentChargeDescriptor(lease_id, signature))
  },
  generateStripePaymentIntent(paymentChargeDescriptorSignature) {
    dispatch(generateStripePaymentIntent(paymentChargeDescriptorSignature))
  },
  cancelStripePaymentIntent(paymentChargeDescriptorSignature, paymentIntentId) {
    dispatch(cancelStripePaymentIntent(paymentChargeDescriptorSignature, paymentIntentId))
  }

});

export default connect(
  mapState,
  mapDispatch,
)(PayModal);
