import axios from 'axios';

// ACTION TYPES
const GET_LEASE_CLIENT = 'GET_LEASE_CLIENT';
const LEASE_SALES_AGENT_UPDATED = 'LEASE_SALES_AGENT_UPDATED';

// ACTION CREATORS
const getLeaseClient = payload => ({
  type: GET_LEASE_CLIENT,
  payload,
});

export const leaseSalesAgentUpdated = payload => ({
  type: LEASE_SALES_AGENT_UPDATED,
  payload,
});

// THUNK CREATOR
export const fetchLeaseClient = lease_client_id => dispatch =>
  axios
    .get(`/lease_members/${lease_client_id}.json`)
    .then(res => {
      dispatch(getLeaseClient(res.data));
    })
    .catch(err => console.dir(err));

// REDUCERS
export default function(state = {}, action) {
  switch (action.type) {
    case GET_LEASE_CLIENT:
      return { leaseClient: action.payload };
    case LEASE_SALES_AGENT_UPDATED:
      return { leaseWithUpdatedSalesAgent: action.payload };
    default:
      return state;
  }
}
