import React, { useState } from 'react';
import NumberFormat from 'react-number-format';

const range = {
  Low: 0.15,
  Medium: 0.25,
  High: 0.35,
};

const RentRange = props => {
  const { income, tier } = props;

  const calculateLowerBound = () => {
    const incomeRange = +range[tier] * +income;
    const lowerBound = incomeRange < 3600 ? 0 : incomeRange / 12 - 300;
    return window.accounting.formatMoney(lowerBound, { precision: 0 });
  };

  const calculateUpperBound = () => {
    const incomeRange = +range[tier] * +income;
    const upperBound = incomeRange < 0 ? 0 : incomeRange / 12 + 300;
    return window.accounting.formatMoney(upperBound, { precision: 0 });
  };

  return (
    <div className="rent-calculator-range-container">
      <div>
        <div className="range-title">
          <h4 className="standard-type">{`${tier} Range`}</h4>
        </div>
        <div className="range-detail">
          {`-${+range[tier] * 100}% of income`}
        </div>
      </div>
      <div className="range-text d-flex align-items-center">
        { !!income && (
          `${calculateLowerBound()} - ${calculateUpperBound()}`
        )}
      </div>
    </div>
  );
};

const RentCalculator = () => {
  const [income, setIncome] = useState(0);

  return (
    <div className="dark-mega-container container-fluid rent-calculator">
      <div className="row h-100">
        <div className="col-sm-5 rent-calculator-text-side text-left">
          <div className="vertical-center">
            <h1>How much should you spend on rent?</h1>
            <p className="standard-type">Our calculator will tell you how much you should spend on rent based on expert advice.</p>
          </div>
        </div>
        <div className="col-sm-7 rent-calculator-text-side">
          <div className="vertical-center">
            <div className="container rent-calculator-form">
              <h6 className="standard-type">Annual income before taxes</h6>
              <NumberFormat className="general-text-field-dark" thousandSeparator prefix="$" onValueChange={values => setIncome(values.value)} />
              <RentRange income={income} tier="Low" />
              <RentRange income={income} tier="Medium" />
              <RentRange income={income} tier="High" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentCalculator;
