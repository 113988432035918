import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { mapSelectedAndMarkerSelector } from 'selectors/map'

import usePanToPoint from 'hooks/usePanToPoint'
import Map from '../common/Map'
import s from './GoogleMap.module.css'

const GoogleMap = forwardRef(({ className, children, containerChildren, onLoaded, mapProps }, ref) => {
  const [map, setMap] = useState(null)

  const { selectedItem } = useSelector(mapSelectedAndMarkerSelector)

  usePanToPoint(map, selectedItem)

  const handleMapLoaded = loadedMap => {
    setMap(loadedMap)
    if (onLoaded) {
      onLoaded(loadedMap)
    }
  }
  return (
    <Map
      ref={ref}
      className={clsx(s.container, className)}
      containerChildren={containerChildren}
      onLoaded={handleMapLoaded}
      mapProps={mapProps}
    >
      {children}
    </Map>
  )
})

export default GoogleMap

GoogleMap.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  containerChildren: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onLoaded: PropTypes.func,
  mapProps: PropTypes.shape({}),
}

GoogleMap.defaultProps = {
  className: undefined,
  children: null,
  containerChildren: null,
  onLoaded: null,
  mapProps: {},
}
