import React, { Component } from 'react';
import RoomField from './RoomField';

export default class SecondCol extends Component {
  render() {
    const { status,
      id: roomId,
      name,
      description,
      salesAgent,
      listingAgent,
      changeField,
    } = this.props;
    const { id: salesAgentId, firstName='', lastName='' } = salesAgent || {};
    const salesAgentName = `${firstName} ${lastName}`
    const { id: listingAgentId, name: listingAgentName } = listingAgent || {};
    return (
      <div className="col-lg-4 listing-first-data-col">
        <div className="row listing-admin-area-data-row">
          <div className="col-sm-4 text-left">
            <b>Status</b>
          </div>
          <div className="col-sm-8 text-left">
            {status}
          </div>
        </div>
        <div className="row listing-admin-area-data-row">
          <div className="col-sm-4 text-left">
            <b>Room ID</b>
          </div>
          <div className="col-sm-8 text-left">
            {roomId}
          </div>
        </div>
        <div className="row listing-admin-area-data-row">
          <div className="col-sm-4 text-left">
            <b>Room Name</b>
          </div>
          <RoomField
            edit={true}
            myValue={name}
            changeField={changeField}
            editAttr='name'
            roomId={roomId}
            myClass="col-sm-8 text-left">
            {name}
          </RoomField>
        </div>
        <div className="row listing-admin-area-data-row">
          <div className="col-sm-4 text-left">
            <b>Description</b>
          </div>
          <RoomField
            edit={true}
            myValue={description}
            changeField={changeField}
            editAttr='description'
            roomId={roomId}
            myClass="col-sm-8 text-left">
            {description}
          </RoomField>
        </div>
        <div className="row listing-admin-area-data-row">
          <div className="col-sm-4 text-left">
            <b>Sales Agent</b>
          </div>
          <div className="col-sm-8 text-left">
            <a
              id="special-link"
              href={salesAgentName && `/agents/${salesAgentId}-${salesAgentName.replace('/ /g, '-'')}`}>{salesAgentName}</a>
          </div>
        </div>
        <div className="row listing-admin-area-data-row">
          <div className="col-sm-4 text-left">
            <b>Listing Agent</b>
          </div>
          <div className="col-sm-8 text-left">
            <a
              id="special-link"
              href={listingAgentName && `/agents/${listingAgentId}-${listingAgentName.replace('/ /g, '-'')}`}>
              {listingAgentName}
            </a>
          </div>
        </div>
      </div>
    );
  }
}
