import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from 'components/LoadingSpinner';

import {
  About,
  Agent,
  Amenities,
  BuildingCard,
  Details,
  InterestedMates,
  LeaseDetails,
  LikeButton,
  ListingMainImage,
  Matrix,
  NearbyTrainStations,
  NeighborhoodCard,
  PriceHistory,
  RentedNotice,
  Rooms,
  SocialButtons,
  TourButton,
} from './components';

// Info Panel on Left of Detail View Page with All Listing Data
const InfoPanel = props => {
  const {
    selected,
    loading,
    showMatrix,
    openModal,
  } = props;

  const {
    listing,
    leases,
    neighborhood,
    region,
    rooms,
    roommates,
    building,
    currentUser,
  } = selected;

  // Shows Spinner If Prop Loading is True
  if (loading) {
    return (
      <div className="listing-show-container">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    // Right Section of Info Panel Holding Mainly Listing Data
    <div className="listing-show-container">
      <div
        className="listing-show-content-side"
        style={{ position: 'relative', overflowY: 'scroll' }}
      >
        {/* Matrix View of Listing If User Is Logged In */}
        {currentUser.ok
          && (
          <Matrix
            currentUser={currentUser.current}
            showMatrix={showMatrix}
            id={listing.id}
          />
          )}
        {/* Warning Banners if Listing is Rented */}
        <RentedNotice
          leases={leases}
          currentUser={currentUser.current}
          status={listing.status}
        />
        {/* Slideshow showing Listing Images */}
        <ListingMainImage images={listing.images} mainImage={listing.image} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-8 nklyn-listing-about-area">
              {/* General Details Shown in Card Format */}
              <Details listing={listing} />
              {/* Generated Description for Listing */}
              <About
                listing={listing}
                neighborhood={neighborhood}
                region={region}
              />
              {/* Amenity List of Listing */}
              <Amenities amenities={listing.amenities} />
              {/* Details for Lease of Listing */}
              <LeaseDetails listing={listing} />
              {/* Nearby Subway Info */}
              <NearbyTrainStations subwayStops={listing.subwayStops} />
              {/* Price History Table */}
              <PriceHistory priceHistory={listing.priceHistory} />
              {/* Interested Roomates Connected to Listing */}
              <InterestedMates
                residential={listing.residential}
                roommates={roommates}
              />
              {/* Rooms Connected to Listing */}
              <Rooms
                rooms={rooms}
                listing={listing}
                signedIn={currentUser.ok}
              />
            </div>
            {/* Left Panel of InfoPanel mostly holding agent info and buttons */}
            <div style={{ right: '0px' }} className="col-sm-4">
              <div className="nklyn-listing-contact-area">
                <TourButton
                  listing={listing}
                  salesAgentID={listing.salesAgent ? listing.salesAgent.id : -1}
                  openModal={openModal}
                  currentUser={currentUser.current}
                />
                <div className="row no-gutters">
                  <div className="col-6">
                    <LikeButton listing={listing} signedIn={currentUser.ok} />
                  </div>
                  <div className="col-6">
                    <SocialButtons listing={listing} />
                  </div>
                </div>
                <hr />
                {/* Agent Contact Info */}
                <Agent
                  listingID={listing.id}
                  contact={listing.contact}
                  listingNetwork={listing.network}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Info of Listing Building */}
        <BuildingCard building={building} />
        {/* Info of Listing Neighborhood */}
        <NeighborhoodCard
          neighborhood={neighborhood}
          region={region}
        />
      </div>
    </div>
  );
};

InfoPanel.defaultProps = {
  loading: false,
  showMatrix: false,
};

InfoPanel.propTypes = {
  loading: PropTypes.bool,
  selected: PropTypes.shape({
    listing: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      salesAgent: PropTypes.shape({
        id: PropTypes.number,
      }),
      status: PropTypes.string,
      image: PropTypes.shape({}),
      images: PropTypes.arrayOf(PropTypes.shape({})),
      amenities: PropTypes.arrayOf(PropTypes.string),
      subwayStops: PropTypes.arrayOf(PropTypes.shape({})),
      priceHistory: PropTypes.arrayOf(PropTypes.shape({})),
      residential: PropTypes.bool,
      contact: PropTypes.shape({}),
      network: PropTypes.string,
    }),
    currentUser: PropTypes.shape({
      ok: PropTypes.bool,
      current: PropTypes.shape({}),
    }),
    leases: PropTypes.arrayOf(PropTypes.shape({})),
    neighborhood: PropTypes.shape({}),
    region: PropTypes.shape({}),
    rooms: PropTypes.arrayOf(PropTypes.shape({})),
    roommates: PropTypes.arrayOf(PropTypes.shape({})),
    building: PropTypes.shape({}),
  }).isRequired,
  showMatrix: PropTypes.bool,
};

export default InfoPanel;
