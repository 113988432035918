import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from 'components/LoadingSpinner'
import ApplyClear from 'components/listings/search/SearchBar/SearchBarButtons/ApplyClear'

import Radio from '../../common/Radio'

import s from './BedAndBath.module.css'

function BedAndBath(props) {
  const { loading, selected, onChange, options } = props
  const [changes, setChanges] = useState(selected)

  const handleChangeBeds = (newBeds) => {
    setChanges((state) => ({
      ...state,
      bedrooms: newBeds,
    }))
  }

  const handleChangeBaths = (newBaths) => {
    setChanges((state) => ({
      ...state,
      baths: newBaths,
    }))
  }

  // Temporary solution to close Popover
  const closePopover = () => {
    document.querySelector('input[name="bed"]').click()
  }

  const handleUpdate = useCallback(
    (actionType) => {
      if (actionType === 'clear') {
        setChanges({
          bedrooms: [],
          baths: [],
        })
        onChange({
          bedrooms: [],
          baths: [],
        })
        return
      }

      onChange(changes)
      closePopover()
    },
    [changes, onChange]
  )

  return (
    <div className={s.root}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className={s.fields}>
            <div className={s.field}>
              <div className={s.fieldTitle}>How many beds?</div>
              <Radio value={changes.bedrooms} onChange={handleChangeBeds} options={options.bedrooms} />
            </div>

            <div className={s.field}>
              <div className={s.fieldTitle}>How many baths?</div>
              <Radio value={changes.baths} onChange={handleChangeBaths} options={options.baths} />
            </div>
          </div>
          <ApplyClear className={s.applyClear} handleUpdate={handleUpdate} />
        </>
      )}
    </div>
  )
}

BedAndBath.propTypes = {
  loading: PropTypes.bool.isRequired,
  selected: PropTypes.shape({
    bedrooms: PropTypes.arrayOf(PropTypes.string),
    baths: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    bedrooms: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    baths: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }).isRequired,
}

export default BedAndBath
