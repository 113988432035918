import React, { Component } from 'react';
import ChangeStatusAsync from 'asyncHandlers/ChangeStatusAsync';

export default class ChangeStatus extends Component {
  state = {
    status: this.props.status,
  }
  asyncHandler = new ChangeStatusAsync(this)
  render() {
    const { superAdmin } = this.props;
    const { status } = this.state;
    const { asyncHandler } = this;
    return (
      <div className="row listing-admin-area-data-row">
        <div className="col-sm-4 text-left">
          <b>Status</b>
        </div>
        <div className="col-sm-8 text-left">
          <label
            className="general-text-label-action">
            <input
              onChange={asyncHandler.changeStatus}
              type="radio"
              value="Available"
              checked="checked"
              checked={status === 'Available'}
              id="listing_room_status_available"/>
            Available
          </label>
          <br/>
          <label
            className="general-text-label-action">
            <input
              onChange={asyncHandler.changeStatus}
              type="radio"
              value="Pending"
              checked={status === 'Pending'}
              id="listing_room_status_pending"/>
            Pending
          </label>
          <br/>
          <label className="general-text-label-action">
            <input
              onChange={asyncHandler.changeStatus}
              type="radio"
              value="Rented"
              checked={status === 'Rented'}
              id="listing_room_status_rented"/>
            Rented
          </label>
          {superAdmin && <React.Fragment><br/>
            <label className="general-text-label-action">
              <input
                onChange={asyncHandler.changeStatus}
                type="radio"
                value="Awaiting Approval"
                checked={status === 'Awaiting Approval'}
                id="listing_room_status_awaiting_approval"/>
              Awaiting Approval
            </label>
            <br/>
            <label className="general-text-label-action">
              <input
                onChange={asyncHandler.changeStatus}
                type="radio"
                value="Rejected"
                checked={status === 'Rejected'}
                id="listing_room_status_rejected"/>
              Rejected
            </label></React.Fragment>}
          <br/>
        </div>
      </div>
    );
  }
}
