import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/generic/Popover';
import ShareDropdown from './ShareDropdown';

const ShareButton = props => {
  const { type, url } = props;
  const targetRef = useRef(null);

  const shareBtnClass = (type === 'row') ? 'button btn-transparent btn-block btn-rounded btn-search-bar-size' : 'col-2 text-center'
  const shareBtnStyle = (type === 'col')
    ? {
      padding: '7px 15px',
      position: 'relative',
      cursor: 'pointer',
      zIndex: 10,
    }
    : {};

  return (
    <>
      <div
        className={shareBtnClass}
        ref={targetRef}
        role="menu"
        style={shareBtnStyle}
      >
        <i className="nookons-share" id="nklyn-listing-share-img" />
      </div>

      <Popover type="click" targetRef={targetRef} className="share-dropdown">
        <ShareDropdown url={url} />
      </Popover>
    </>
  );
};

ShareButton.defaultProps = {
  type: 'row',
};

ShareButton.propTypes = {
  type: PropTypes.oneOf(['row', 'col']),
  url: PropTypes.string.isRequired,
};

export default ShareButton;
