const isNumeric = (stringToCheck) => /^\d+$/.test(stringToCheck)

function getPriceWarningText(queuedValue) {
  if (parseInt(queuedValue[0], 10) > parseInt(queuedValue[1], 10)) {
    return 'Warning: Minimum is greater then Maximum'
  }
  if (queuedValue[0] === queuedValue[1] && queuedValue[0] !== '' && queuedValue[1] !== '') {
    return 'Warning: Minimum and Maximum are equal'
  }
  if (!isNumeric(queuedValue[0]) || !isNumeric(queuedValue[1])) {
    if (
      !(isNumeric(queuedValue[0]) && queuedValue[1] === '') &&
      !(isNumeric(queuedValue[1]) && (queuedValue[0] === '' || queuedValue[0] === 0)) &&
      !(queuedValue[0] === '' && queuedValue[1] === '')
    ) {
      return 'Warning: Input must be positive whole numbers'
    }
  }

  return null
}

export default getPriceWarningText
