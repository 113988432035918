import React from 'react';
import { connect } from 'react-redux';
import InviteModal from '../components/modal/InviteModal';

const InviteButton = props => {
  const { className, lease_id } = props;

  return (
    <InviteModal className={`${className} modal-wrapper`} lease_id={lease_id}>
      <button
        type="button"
        className={
          className || 'button btn-rounded btn-black-white float-right'
        }
      >
        Invite
      </button>
    </InviteModal>
  );
};

export default connect()(InviteButton);
