import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import suntrustImg from 'images/icons/banks/bank-suntrust.png';
import bbtImg from 'images/icons/banks/bank-bbt.png';
import boaImg from 'images/icons/banks/bank-boa.png';
import wellsImg from 'images/icons/banks/bank-wells-fargo.png';
import chaseImg from 'images/icons/banks/bank-chase.png';
import appleImg from 'images/icons/banks/apply-pay.png';
import bankImg from 'images/icons/svg/bank_sym.svg';
import paymentImg from 'images/icons/svg/credit_card.svg';

const Payment = props => {
  const {
    payWithBank,
    payWithCredit,
    achPaymentRequest,
    creditPaymentRequest,
  } = props;

  const [achDisabled, setAchDisabled] = useState(true);
  const [disableMessage, setDisableMessage] = useState('');

  useEffect(() => {
    if (process.env.NOOKLYN_PAY_ACH_DISABLE_MESSAGE) {
      setAchDisabled(true);
      setDisableMessage(process.env.NOOKLYN_PAY_ACH_DISABLE_MESSAGE);
    } else {
      setAchDisabled(false);
      setDisableMessage('');
    }
  }, []);

  return (
    <div className="container payment-container">
      <h1>NOOKLYN PAY</h1>
      <hr className="entire-width" />
      <div className="row">
        <div className="col-md-6">
          <div className="payment-option-card">
            <a href="https://nooklyn.com/support">
              <i className="nookons-question-fill payment-support" />
            </a>
            <img
              alt="bank"
              src={bankImg}
              className="img-fluid mx-auto"
              style={{ height: '27px' }}
            />
            <h2>Bank Debit</h2>
            <h3>&nbsp;</h3>
            <br />
            <ul className="list-unstyled list-inline">
              <li className="list-inline-item">
                <img alt="suntrust" src={suntrustImg} />
              </li>
              <li className="list-inline-item">
                <img alt="bbt" src={bbtImg} />
              </li>
              <li className="list-inline-item">
                <img alt="wellsFargo" src={wellsImg} />
              </li>
              <li className="list-inline-item">
                <img alt="chase" src={chaseImg} />
              </li>
              <li className="list-inline-item">
                <img alt="boa" src={boaImg} />
              </li>
            </ul>
            <hr />
            <div className="container-fluid">
              <div className="row payment-option-key-value-pair justify-content-between">
                <span className="text-left">
                  <b>{achPaymentRequest.baseDescription}</b>
                </span>
                <span className="text-right">
                  {accounting.formatMoney(achPaymentRequest.baseAmount / 100)}
                </span>
              </div>
              <div className="row payment-option-key-value-pair justify-content-between">
                <span className="text-left">
                  <b>Convenience Fee</b>
                </span>
                <span className="text-right">
                  {accounting.formatMoney(
                    achPaymentRequest.convenienceFee / 100
                  )}
                </span>
              </div>
              <div className="row payment-option-key-value-pair justify-content-between">
                <span className="text-left">
                  <b className="total">Total</b>
                </span>
                <span className="text-right">
                  <b className="total total-payment">
                    {accounting.formatMoney(
                      achPaymentRequest.totalAmount / 100
                    )}
                  </b>
                </span>
              </div>
            </div>
            <hr />
            <button
              onClick={payWithBank}
              className="button btn-white-black btn-rounded btn-2x btn-payment"
              disabled={achDisabled}
            >
              Pay With ACH
            </button>
            { achDisabled && disableMessage && (
              <div>
                {disableMessage}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="payment-option-card">
            <a href="https://nooklyn.com/support">
              <i className="nookons-question-fill payment-support" />
            </a>
            <img
              alt="payment"
              src={paymentImg}
              className="img-fluid mx-auto"
              style={{ height: '27px' }}
            />
            <h2>Credit Card</h2>
            <h3>&nbsp;</h3>
            <br />
            <ul className="list-unstyled list-inline">
              <li className="list-inline-item">
                <i className="nookons-brand-visa nklyn-3x" />
              </li>
              <li className="list-inline-item">
                <i className="nookons-brand-discover nklyn-3x" />
              </li>
              <li className="list-inline-item">
                <i className="nookons-brand-amex nklyn-3x" />
              </li>
              <li className="list-inline-item">
                <i className="nookons-brand-mastercard nklyn-3x" />
              </li>
            </ul>
            <hr />
            <div className="container-fluid">
              <div className="row payment-option-key-value-pair justify-content-between">
                <span className="text-left">
                  <b>{creditPaymentRequest.baseDescription}</b>
                </span>
                <span className="text-right">
                  {accounting.formatMoney(
                    creditPaymentRequest.baseAmount / 100
                  )}
                </span>
              </div>
              <div className="row payment-option-key-value-pair justify-content-between">
                <span className="text-left">
                  <b>Convenience Fee</b>
                </span>
                <span className="text-right">
                  {accounting.formatMoney(
                    creditPaymentRequest.convenienceFee / 100
                  )}
                </span>
              </div>
              <div className="row payment-option-key-value-pair justify-content-between">
                <span className="text-left">
                  <b className="total">Total</b>
                </span>
                <span className="text-right">
                  <b className="total total-payment">
                    {accounting.formatMoney(
                      creditPaymentRequest.totalAmount / 100
                    )}
                  </b>
                </span>
              </div>
            </div>
            <hr />
            <a
              onClick={payWithCredit}
              className="button btn-white-black btn-rounded btn-2x btn-payment"
            >
              Pay With Credit
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  null,
  null,
)(Payment);
