import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'wrappers/FlexTable';
import { importAll, splitArrayAtIndex } from 'helpers/util';

const subwayImages = importAll(require.context('images/subway/2x/', true, /\.png/));

const SubwayRow = ({
  subwayLines,
}) => {
  const subwayLineDisplayLimit = 3;
  const [truncatedSubwayLines, remaining] = splitArrayAtIndex(subwayLines, subwayLineDisplayLimit);

  return (
    <Row className="subway-row">
      {truncatedSubwayLines.map(train => (
        <img
          className="nooklyn_listing_square_subway-img"
          key={`${train}-img`}
          src={subwayImages[`./${train.toUpperCase()}.png`]}
          alt="subway"
        />
      ))
      }
      <span className="nooklyn_listing_square_subway_text">{remaining && `+${remaining.length}`}</span>
    </Row>
  );
};

SubwayRow.defaultProps = {
  subwayLines: [],
};

SubwayRow.propTypes = {
  subwayLines: PropTypes.arrayOf(
    PropTypes.oneOf([
      '1', '2', '3', '4', '5', '6', '7', 'A', 'B', 'C',
      'D', 'E', 'F', 'G', 'J', 'L', 'M', 'N', 'Q', 'R',
      'S', 'T', 'W', 'Z',
    ]),
  ),
};

export default memo(SubwayRow);
