import {
  deleteRoomAsync,
  fetchListingAsync,
  fetchListingRoomsAsync,
  fetchRoomAsync,
  fetchUserListingsAsync,
  markRoomAsAvailableAsync,
  markRoomAsRentedAsync,
  persistListingAsync,
} from './api';

const FETCH_LISTING       = 'FETCH_LISTING';
const FETCH_LISTING_ROOMS = 'FETCH_LISTING_ROOMS';
const FETCH_USER_LISTINGS = 'FETCH_USER_LISTINGS';
const FETCH_ROOM          = 'FETCH_ROOM';

const INVALIDATE_LISTING = 'INVALIDATE_LISTING';
const INVALIDATE_ROOM = 'INVALIDATE_ROOM';

const RECEIVE_LISTING       = 'RECEIVE_LISTING';
const RECEIVE_LISTING_ROOMS = 'RECEIVE_LISTING_ROOMS';
const RECEIVE_ROOM          = 'RECEIVE_ROOM';
const RECEIVE_USER_LISTINGS = 'RECEIVE_USER_LISTINGS';

const UPDATE_LISTING_ATTRIBUTE = 'UPDATE_LISTING_ATTRIBUTE';

export {
  FETCH_LISTING,
  FETCH_LISTING_ROOMS,
  FETCH_ROOM,
  FETCH_USER_LISTINGS,
  INVALIDATE_LISTING,
  INVALIDATE_ROOM,
  RECEIVE_LISTING,
  RECEIVE_LISTING_ROOMS,
  RECEIVE_ROOM,
  RECEIVE_USER_LISTINGS,
  UPDATE_LISTING_ATTRIBUTE,
};

const CACHE_TTL = 10 * 60 * 1000;

/*
 * NOTES:
 *
 * setFlashMessage is passed in a couple of functions. This is because the
 * flash message is being controlled by Redux. Ideally it would be handled
 * natively within the ProContext and then it can just be a dispatch action.
 *
 */

const deleteRoom = (roomId, setFlashMessage) => (dispatch, apiToken) => {
  setFlashMessage('Deleting Room...');

  deleteRoomAsync(roomId, apiToken)
    .then(result => {
      if (result.ok) {
        dispatch(invalidateRoom(roomId));
        setFlashMessage('Room draft has been successfully deleted');
      } else {
        setFlashMessage(result.error.friendlyMessage);
      }
    })
    .catch(result => {
      setFlashMessage(result.error.friendlyMessage);
    });
};

const fetchListing = listingId => (dispatch, apiToken, getState) => {
  const {
    listings: {
      [listingId]: listingEntry = {}
    }
  } = getState();

  const {
    isFetching = false,
    didInvalidate = false,
    lastUpdated = new Date(0),
  } = listingEntry;

  if ((isFetching || (Date.now() - lastUpdated) <= CACHE_TTL) && !didInvalidate) {
    return;
  }

  dispatch({ type: FETCH_LISTING, listingId });

  fetchListingAsync(listingId, apiToken)
    .then(listing => dispatch(receiveListing(listing)));
};

const fetchListingRooms = listingId => (dispatch, apiToken, getState) => {
  const {
    listings: {
      [listingId]: {
        rooms = {},
      },
    } = {},
  } = getState();

  const {
    isFetching = false,
    didInvalidate = false,
    lastUpdated = new Date(0),
  } = rooms;

  if ((isFetching || (Date.now() - lastUpdated) <= CACHE_TTL) && !didInvalidate) {
    return;
  }

  dispatch({ type: FETCH_LISTING_ROOMS, listingId });

  fetchListingRoomsAsync(listingId, apiToken)
    .then(rooms => {
      rooms.forEach(room => {
        dispatch(receiveRoom(room));
      });
      dispatch(receiveListingRooms(listingId, rooms));
    });
};

const fetchRoom = (roomId) => (dispatch, apiToken, getState) => {
  const {
    rooms: {
      [roomId]: room = {},
    },
  } = getState();

  const {
    isFetching = false,
    didInvalidate = false,
    lastUpdated = new Date(0),
  } = room;

  if ((isFetching || (Date.now() - lastUpdated) <= CACHE_TTL) && !didInvalidate) {
    return;
  }

  dispatch({ type: FETCH_ROOM, roomId });

  fetchRoomAsync(roomId, apiToken)
    .then(room => {
      dispatch(receiveRoom(room));
      dispatch(fetchListing(room.listingId));
    });
};

const fetchUserListings = () => (dispatch, apiToken, getState) => {
  const {
    user: {
      listings: userListings = {},
    },
  } = getState();

  const {
    isFetching = false,
    lastUpdated = new Date(0),
  } = userListings;

  if (isFetching || (Date.now() - lastUpdated) <= CACHE_TTL) {
    return;
  }

  dispatch({ type: FETCH_USER_LISTINGS });

  fetchUserListingsAsync(apiToken)
    .then(listings => listings.map(listing => listing.id))
    .then(listingIds => {
      listingIds.forEach(listingId => dispatch(fetchListing(listingId)));
      dispatch(receiveUserListings(listingIds));
    });
};

const invalidateListing = listingId => ({
  type: INVALIDATE_LISTING,
  listingId,
});

const invalidateRoom = roomId => ({
  type: INVALIDATE_ROOM,
  roomId,
});

const markRoomAsAvailable = (roomId, setFlashMessage) => (dispatch, apiToken) => {
  markRoomAsAvailableAsync(roomId, apiToken)
    .then(result => {
      if (result.ok) {
        dispatch(invalidateRoom(roomId));
        setFlashMessage('Your room has been marked as available.');
      } else {
        setFlashMessage(result.error.friendlyMessage);
      }
    })
    .catch(result => {
      setFlashMessage(result.error.friendlyMessage);
    });
};

const markRoomAsRented = (roomId, setFlashMessage) => (dispatch, apiToken) => {
  markRoomAsRentedAsync(roomId, apiToken)
    .then(result => {
      if (result.ok) {
        dispatch(invalidateRoom(roomId));
        setFlashMessage('Your room has been marked as rented.');
      } else {
        setFlashMessage(result.error.friendlyMessage);
      }
    })
    .catch(result => {
      setFlashMessage(result.error.friendlyMessage);
    });

};

const persistListing = listingId => (dispatch, apiToken, getState) => {
  const {
    listings: {
      [listingId]: listing,
    },
  } = getState();

  if (typeof listing === 'undefined') {
    return;
  }

  persistListingAsync(listingId, listing.data, apiToken)
    .then(() => dispatch(fetchListing(listingId)));
};

const receiveListing = listing => ({
  type: RECEIVE_LISTING,
  listing,
  receivedAt: Date.now(),
});

const receiveListingRooms = (listingId, rooms) => ({
  type: RECEIVE_LISTING_ROOMS,
  listingId,
  roomIds: rooms.map(room => room.id),
  receivedAt: Date.now(),
});


const receiveRoom = room => ({
  type: RECEIVE_ROOM,
  room,
  receivedAt: Date.now(),
});

const receiveUserListings = listingIds => ({
  type: RECEIVE_USER_LISTINGS,
  listingIds,
});

const updateListingAttribute = (listingId, attribute, value) => ({
  type: UPDATE_LISTING_ATTRIBUTE,
  listingId,
  attribute,
  value,
});

export {
  deleteRoom,
  fetchListing,
  fetchListingRooms,
  fetchRoom,
  fetchUserListings,
  invalidateListing,
  invalidateRoom,
  markRoomAsAvailable,
  markRoomAsRented,
  receiveListing,
  receiveRoom,
  persistListing,
  updateListingAttribute,
};
