import React from 'react';
import AuthModal from '../modal/AuthModal';

const ApplyButton = props => (
  <AuthModal listingId={props.listingId}>
    <a className="button btn-black-yellow btn-rounded btn-block">
      Apply Now
    </a>
  </AuthModal>
);

export default ApplyButton;
