import React from 'react';
import AuthModal from '../modal/AuthModal';

const AuthWrapper = props => {
  const { redirect, content } = props;

  const renderContent = () => {
    switch (content) {
      case 'ListMyProperty':
        return <ListMyPropertyButton />;
      case 'SubscriptionContinue':
        return <SubscriptionContinueButton />;
      case 'Login':
        return <LoginButton />;
      case 'Signup':
        return <SignupButton />;
      case 'NavSignup':
        return <NavSignupButton />;
      default:
        return '';
    }
  };

  return (
    <AuthModal redirect={redirect} signup={content !== 'Login'}>
      {content && renderContent()}
    </AuthModal>
  );
};

export default AuthWrapper;

const ListMyPropertyButton = () => (
  <div className="button btn-yellow-black btn-rounded btn-3x">
    {'List My Property '}
    <i className="nookons-arrow-right" />
  </div>
);

const SubscriptionContinueButton = () => (
  <div className="button btn-rounded btn-block btn-black-yellow">
    {'Continue '}
    <i className="nookons-arrow-right" />
  </div>
);

const LoginButton = () => (
  <div className="button btn-black-yellow-login btn-rounded">
    Log In
  </div>
);

const SignupButton = () => (
  <div className="nav-link button btn-white-black btn-rounded btn-3x">
    Sign Up
  </div>
);

const NavSignupButton = () => (
  <div className="button btn-black-yellow-signup btn-rounded">
    Sign Up
  </div>
);
