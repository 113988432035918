import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import FlashNotice from 'components/flash/FlashNotice';
import { getMetaContent } from 'helpers/util';
import ListingStatusChangeModal from 'components/modal/ListingStatusChangeModal';
import ListingSyndicationModal from 'components/modal/ListingSyndicationModal';
import { setMessage } from 'reducers/flashMessage';
import PropTypes from 'prop-types';
import AddToCollection from './AddToCollection';

const MatrixListingTopBar = props => {
  const {
    listing,
    setFlashMessage,
    changeField,
    approveOrReject,
    readOnly,
    superAdmin,
  } = props;

  const {
    id: listingId,
    platformListing,
    shortAddress,
    subwayListing,
    status,
    slug,
    floorplan,
    exclusive,
    hasLease,
  } = listing;

  const platformNeedsApproval = status === 'Submitted for Review';
  const brokerageNeedsApproval = status === 'Awaiting Approval';
  let clipboardUrl = `${window.location.origin}/listings/${slug}`;
  const stickyAgent = localStorage.getItem('agentId');
  if (stickyAgent) {
    clipboardUrl = `${clipboardUrl}?sales-agent=${stickyAgent}`;
  }

  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [displayCopyNotice, setDisplayCopyNotice] = useState(false);
  const [closeChangeStatusModal, setCloseChangeStatusModal] = useState(false);

  const changeListingStatus = (_status, display, notes = '') => {
    const listingType = !subwayListing ? 'matrix' : 'subway';

    axios.post(`/${listingType}/listings/${listingId}/change_status.json`, { status: _status, notes, authenticity_token: getMetaContent('csrf-token') }).then(() => {
      setFlashMessage(
        `You have successfully marked the listing at ${shortAddress} as ${_status}`,
      );
      changeField(display, 'status');
      setCloseChangeStatusModal(true);
    });
  };

  const handleReject = evt => {
    evt.preventDefault();
    const notes = evt.target.rejectedNotes.value;
    const listingStatus = platformListing ? 'needs_changes' : 'rejected';
    const humanableStatus = platformListing ? 'Needs Changes' : 'Rejected';

    changeListingStatus(listingStatus, humanableStatus, notes);
    setApproved(false);
    setRejected(true);
    approveOrReject();
  };

  const markAsAvailable = evt => {
    evt.preventDefault();
    changeListingStatus('available', 'Available');
  };

  const handleApprove = evt => {
    markAsAvailable(evt);
    setApproved(true);
    setRejected(false);
    approveOrReject();
  };

  const markAsAwaitingApproval = evt => {
    evt.preventDefault();
    changeListingStatus('awaiting_approval', 'Awaiting Approval');
  };

  const markAsPending = evt => {
    evt.preventDefault();
    const notes = evt.target.pendingNotes.value;
    changeListingStatus('pending', 'Application Pending', notes);
  };

  const markAsRented = evt => {
    evt.preventDefault();
    const notes = evt.target.rentedNotes.value;
    changeListingStatus('rented', 'Rented', notes);
  };

  const handleDisplayCopyNotice = evt => {
    evt.preventDefault();
    setDisplayCopyNotice(true);
    setTimeout(() => {
      setDisplayCopyNotice(false);
    }, 1200);
  };

  return (
    <div className="row listing-admin-area-title-row">
      <div className="col-sm-12">
        <ul className="list-inline list-unstyled">
          <span>
            <li className="list-inline-item">
              <a
                href={listing ? `/listings/${slug}` : ''}
                target="_blank"
                rel="noreferrer"
              >
                {listing && shortAddress
                  ? shortAddress
                  : ''}
              </a>
            </li>
            { readOnly && (
              <li className="dropdown list-inline-item">
                <div
                  className="button btn-rounded btn-transparent status-button"
                >
                  <span className={`status-dot ${!!status && status.replace(/\s+/g, '-').toLowerCase()}`} />
                  {status}
                </div>
              </li>
            )}
            {
              (platformNeedsApproval || brokerageNeedsApproval)
              && (!approved && !rejected)
              && superAdmin
              && !readOnly
              && (
                <>
                  <li className="list-inline-item">
                    <a onClick={handleApprove}>
                      Approve
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <ListingStatusChangeModal handleStatusChange={handleReject} status="rejected" closeChangeStatusModal={closeChangeStatusModal} style={{ color: '#fff' }}>
                      Reject
                    </ListingStatusChangeModal>
                  </li>
                </>
              )
            }
            {!platformNeedsApproval && !brokerageNeedsApproval && status !== 'Draft' && !readOnly && (
              <>
                <li className="dropdown list-inline-item">
                  <a
                    className="button btn-rounded btn-transparent status-button"
                    href="#"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    role="button"
                  >
                    <span className={`status-dot ${!!status && status.replace(/\s+/g, '-').toLowerCase()}`} />
                    {status}
                    <i className="ml-2 nookons-chevron-down" />
                  </a>
                  <ul
                    className="dropdown-menu nklynbar-nav-dark nklyn-listing-manage-dropdown"
                    role="menu"
                  >
                    {status === 'Available' && (
                      <>
                        <li className="dropdown-item">
                          <ListingStatusChangeModal handleStatusChange={markAsPending} status="pending" closeChangeStatusModal={closeChangeStatusModal}>
                            Mark as Pending
                          </ListingStatusChangeModal>
                        </li>
                        <li className="dropdown-item">
                          <ListingStatusChangeModal handleStatusChange={markAsRented} status="rented" closeChangeStatusModal={closeChangeStatusModal}>
                            Mark as Rented
                          </ListingStatusChangeModal>
                        </li>
                      </>
                    )}
                    {(status === 'Rented' || status === 'Delisted') && superAdmin && (
                      <li className="dropdown-item">
                        <a onClick={markAsAvailable}>
                          Mark as Available (admin)
                        </a>
                      </li>
                    )}
                    {(status === 'Rented' || status === 'Delisted') && (
                      <li className="dropdown-item">
                        <a onClick={markAsAwaitingApproval}>
                          Mark as Available
                        </a>
                      </li>
                    )}
                    {status === 'Application Pending' && (
                      <li className="dropdown-item">
                        <ListingStatusChangeModal handleStatusChange={markAsRented} status="rented" closeChangeStatusModal={closeChangeStatusModal}>
                          Mark as Rented
                        </ListingStatusChangeModal>
                      </li>
                    )}
                    {status === 'Application Pending' && superAdmin && (
                      <li className="dropdown-item">
                        <a onClick={markAsAvailable}>
                          Mark as Available (admin)
                        </a>
                      </li>
                    )}
                    {status === 'Application Pending' && (
                      <li className="dropdown-item">
                        <a onClick={markAsAwaitingApproval}>
                          Mark as Available
                        </a>
                      </li>
                    )}
                    {superAdmin && status !== 'Rejected' && status !== 'Needs Changes' && (
                      <li className="dropdown-item">
                        <ListingStatusChangeModal handleStatusChange={handleReject} closeChangeStatusModal={closeChangeStatusModal} status="rejected" style={{ color: '#fff' }}>
                          Mark as Rejected
                        </ListingStatusChangeModal>
                      </li>
                    )}
                  </ul>
                </li>
              </>
            )}
            {listing
              && exclusive && (
                <li className="dropdown list-inline-item">
                  <span className="label label-outline">Exclusive</span>
                </li>
            )}
            {listing
              && hasLease && (
                <li className="dropdown list-inline-item">
                  <span className="label label-nklyn-blue">Deposit</span>
                </li>
            )}
            {approved && (
              <li className="dropdown list-inline-item">
                <span className="label label-outline">Approved</span>
              </li>
            )}
            {rejected && (
              <li className="dropdown list-inline-item">
                <span className="label label-outline">Rejected</span>
              </li>
            )}
          </span>
          <span className="float-right">
            {listing && !subwayListing
              && (
                <li className="dropdown list-inline-item">
                  {displayCopyNotice && (
                    <FlashNotice text="URL copied" />
                  )}
                  <div
                    onClick={handleDisplayCopyNotice}
                    id="social-circle-button"
                    className="social-circle-button text-white"
                    title="Copied"
                    data-toggle="tooltip"
                    data-clipboard-text={clipboardUrl}
                  >
                    <i className="nookons-copy mr-1" />
                    Copy
                  </div>
                </li>
              )}
            {!readOnly && <AddToCollection listingId={listingId} />}
            {((platformListing && superAdmin) || !platformListing) && (
              <>
                <li className="dropdown list-inline-item">
                  <a
                    href="#"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    role="button"
                  >
                    <i className="nookons-settings-fill mr-1" />
                    Manage
                    <span />
                  </a>
                  <ul
                    className="dropdown-menu nklynbar-nav-dark nklyn-listing-manage-dropdown"
                    role="menu"
                  >
                    {listing && !readOnly && (
                      <li className="dropdown-item">
                        <a href={subwayListing ? `/subway/listings/${slug}/edit` : `/matrix/listings/${slug}/edit`}>
                          <i className="nookons-edit" />
                          {' '}
                          Edit
                        </a>
                      </li>
                    )}
                    {listing && !readOnly && (
                      <li className="dropdown-item">
                        <a
                          href={
                            subwayListing
                              ? `/subway/listings/${slug}/listing_details`
                              : `/matrix/listings/${slug}/edit_details`
                          }
                        >
                          <i className="nookons-history" />
                          {' '}
                          Details
                        </a>
                      </li>
                    )}
                    {listing && !readOnly && (
                      <li className="dropdown-item">
                        <a
                          href={
                            subwayListing
                              ? `/subway/listings/${slug}/listing_packages`
                              : `/matrix/listings/${slug}/edit_packages`
                          }
                        >
                          <i className="nookons-rental-sale-a" />
                          {' '}
                          Concessions
                        </a>
                      </li>
                    )}
                    {listing && !subwayListing && !readOnly
                      && (
                        <li className="dropdown-item">
                          <a
                            href={
                              listing
                                ? `/matrix/listings/${slug}/edit_agents`
                                : ''
                            }
                          >
                            <i className="nookons-group" />
                            {' '}
                            Agents
                          </a>
                        </li>
                      )}
                    {listing && !readOnly
                      && (
                        <li className="dropdown-item">
                          <a
                            href={
                              subwayListing ? `/subway/listings/${slug}/listing_photos` : `/matrix/listings/${slug}/edit_photos`
                            }
                          >
                            <i className="nookons-camera" />
                            {' '}
                            Photos
                          </a>
                        </li>
                      )}
                    {listing && !readOnly
                      && (
                        <li className="dropdown-item">
                          <a
                            href={
                              subwayListing ? `/subway/listings/${slug}/rooms` : `/matrix/listings/${slug}/edit_rooms`
                            }
                          >
                            <i className="nookons-bed" />
                            {' '}
                            Rooms
                          </a>
                        </li>
                      )}
                    {listing && !readOnly
                      && (
                        <li className="dropdown-item">
                          <a
                            href={
                              subwayListing ? `/subway/listings/${slug}/review` : `/matrix/listings/${slug}/review`
                            }
                          >
                            <i className="nookons-flag" />
                            {' '}
                            Review
                          </a>
                        </li>
                      )}
                    {listing && !subwayListing
                      && (
                        <li className="dropdown-item">
                          <a
                            href={
                              listing ? `/matrix/listings/${slug}/comparables` : ''
                            }
                          >
                            <i className="nookons-pay-dollar" />
                            {' '}
                            Comparables
                          </a>
                        </li>
                      )}
                    {listing
                      && (
                        <li className="dropdown-item">
                          <a
                            href={
                              listing ? `/listings/${slug}/manage_history` : ''
                            }
                          >
                            <i className="nookons-clock" />
                            {' '}
                            History
                          </a>
                        </li>
                      )}
                    <hr />
                    {listing && floorplan && floorplan.url ? (
                      <li className="dropdown-item">
                        <a
                          href={floorplan.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {' '}
                          <i className="nookons-home" />
                          {' '}
                          Download Floorplan
                        </a>
                      </li>
                    ) : (
                      ''
                    )}
                    <li className="dropdown-item">
                      <ListingSyndicationModal listingId={listing.id} />
                    </li>
                    <li className="dropdown-item">
                      <a href={listing ? `/listings/${slug}/gallery` : ''}>
                        <i className="nookons-file-export" />
                        {' '}
                        Download Photos
                      </a>
                    </li>
                    { !readOnly && (
                    <li className="dropdown-item">
                      <a
                        href={
                          listing ? `/listings/${slug}/request_photos` : ''
                        }
                      >
                        <i className="nookons-grid" />
                        {' '}
                        Request Photos
                      </a>
                    </li>
                    ) }
                    {listing && !subwayListing && !readOnly
                      && (
                        <li className="dropdown-item">
                          <a
                            href={
                              listing ? `/matrix/listings/${slug}/offers/new` : ''
                            }
                          >
                            <i className="nookons-compose" />
                            {' '}
                            Create Offer
                          </a>
                        </li>
                      )}
                    <hr />
                    {listing && !subwayListing && !readOnly
                      && (
                        <li className="dropdown-item">
                          <a href={listing ? `/listings/${slug}/craigslist` : ''}>
                            <i className="nookons-globe" />
                            {' '}
                            Craigslist
                          </a>
                        </li>
                      )}
                    {listing && !subwayListing && !readOnly
                      && (
                        <li className="dropdown-item">
                          <a
                            href={listing ? `/matrix/listings/${slug}/duplicate` : ''}
                          >
                            <i className="nookons-copy" />
                            Duplicate
                          </a>
                        </li>
                      )}
                  </ul>
                </li>
                <li className="d-none list-inline-item">
                  <form
                    action={listing ? `/listings/${slug}/gallery` : ''}
                    method="get"
                  >
                    <input type="submit" value="Download Photos" />
                  </form>
                </li>
              </>
            )}
          </span>
        </ul>
      </div>
    </div>
  );
};

MatrixListingTopBar.defaultProps = {
  setFlashMessage: () => { },
};

MatrixListingTopBar.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.number,
    platformListing: PropTypes.bool,
    shortAddress: PropTypes.string,
    subwayListing: PropTypes.bool,
    status: PropTypes.string,
    slug: PropTypes.string,
    exclusive: PropTypes.bool,
    hasLease: PropTypes.bool,
    floorplan: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  setFlashMessage: PropTypes.func,
  changeField: PropTypes.func.isRequired,
  approveOrReject: PropTypes.func.isRequired,
  superAdmin: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setFlashMessage(message) {
    dispatch(setMessage(message));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(MatrixListingTopBar);
