import React, { useEffect, useState } from 'react';
import { getParamsFromLink } from 'helpers/util';
import PropTypes from 'prop-types';
import Modal from 'components/modal/Modal';
import ScheduleTourPage from 'components/renterForms/ScheduleTour';

const ScheduleTour = props => {
  const {
    listing,
    loading,
    openModal,
    currentUser,
  } = props;

  const {
    id,
    status,
  } = listing || {};

  const [modalIsOpen, setModalIsOpen] = useState(openModal == 'TourRequest');

  const handleClick = () => {
    if (!loading) {
      setModalIsOpen(true)
    }
  };

  const closeModal = () => {
    setModalIsOpen(false)
  };

  const handleKeyDown = () => evt => {
    if (evt.key === 'Enter') {
      handleClick();
    }
  };

  if (status === 'Rented') {
    return null;
  }
  
  return (
    <div className="lead_schedule">
      <div
        onClick={handleClick}
        className="button btn-yellow-black btn-rounded btn-search-bar-size text-left d-flex "
        style={{ justifyContent: 'space-between' }}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex="0"
      >
        <span className="d-flex">Request a tour</span>
        <i className="nookons-arrow-right d-flex ml-2" id="nklyn-listing-tour-img" style={{ lineHeight: '1.7', fontWeight: '500' }} />
      </div>
      { Object.keys(listing).length ? (
        <Modal
          className="modal-dialog request-tour-modal"
          closeTimeoutMS={150}
          isOpen={ modalIsOpen }
          onRequestClose={ closeModal }
        >
          <div className="modal-content">
            <ScheduleTourPage 
              acceptApply={ listing.acceptsApply }
              property={ {
                id: listing.id,
                slug: listing.slug
              } }
              type="listings"
              closeModal={ closeModal }
              currentUser={ currentUser }
            />
          </div>
        </Modal>
        ) : null
      }
    </div>
  );
};

ScheduleTour.defaultProps = {
  loading: false,
};

ScheduleTour.propTypes = {
  loading: PropTypes.bool,
  listing: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    slug: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  currentUser: PropTypes.object
};

export default ScheduleTour;
