import React, { useEffect, useState } from 'react';
import { Redirect } from '@reach/router';
import PropTypes from 'prop-types';
import LoadingSpinner from 'components/LoadingSpinner';
import specialLinks from './specialLinks.json';

const NotFound = props => {
  const { uri } = props;

  // States for Data Management
  const [result, setResult] = useState(null);

  // States for Page Loading Management
  const [loading, setLoading] = useState(true);
  const [found, setFound] = useState(false);

  // Checking specialLinks json for matching url
  const checkForSpecialURL = url => {
    const foundLink = specialLinks.find(value => value.url === url);

    return foundLink !== undefined ? foundLink.redirect : undefined;
  };

  // Grab URL Segments from Router
  useEffect(() => {
    if (uri !== null) {
      const specialUrlResult = checkForSpecialURL(uri);

      if (specialUrlResult) {
        setResult(specialUrlResult);
        setFound(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [uri]);

  // If still loading, show loading screen
  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (found && result) {
    return (<Redirect to={result} />);
  }

  return (<Redirect to="/404" />);
};

NotFound.defaultProps = {
  uri: '',
};

NotFound.propTypes = {
  uri: PropTypes.string,
};

export default NotFound;
