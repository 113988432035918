import React from 'react';
import RecordDepositModal from '../components/modal/RecordDepositModal';

const RecordDepositButton = props => (
  <RecordDepositModal {...props}>
    <a className="dropdown-item">Record Deposit</a>
  </RecordDepositModal>
);

export default RecordDepositButton;
