import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setStore, generateNewSearch } from 'reducers/search';

class MaxPrice extends Component {

  onChange = (e) => {
    e.preventDefault();
    this.props.setStore({
      maxPrice: e.target.value,
    });
    this.props.generateNewSearch();
  }

  render() {
    const { maxPrice } = this.props;
    const value = maxPrice === 'Infinity' ? '' : maxPrice;
    return (
      <input
        onChange={this.onChange}
        value={value}
        placeholder="9,999+"
        type="text"
        name="listing_search_form[max_price]"
        id="listing_search_form_max_price"
      />
    );
  }
}

const mapStateToProps = (state) => ({
  maxPrice: state.search[state.search.context].maxPrice,
});

const mapDispatchToProps = (dispatch) => ({
  setStore: payload => dispatch(setStore(payload)),
  generateNewSearch: () => dispatch(generateNewSearch())
});

export default connect(mapStateToProps, mapDispatchToProps)(MaxPrice);
