import React, { Component } from 'react';
import Fade from 'wrappers/Fade';
import ShareDropdown from './ShareButton/ShareDropdown';

export default function ShareHOC(WrapperComponent) {
  return class extends Component {
    state = {
      isOpen: false,
    }

    componentDidMount() {
      this.callback = (e) => {
        const { isOpen } = this.state;
        if (isOpen && e.target !== this.node) {
          this.onClick(e);
        }
      };
      window.addEventListener('click', this.callback);
    }

    componentWillUnmount() {
      window.removeEventListener('click', this.callback);
    }

    onClick = evt => {
      evt.stopPropagation();
      this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
    }

    render() {
      const { onClick } = this;
      const { isOpen } = this.state;
      return (
        <WrapperComponent ref={(me) => { this.node = me; }} onClick={onClick}>
          <i className="nookons-share" id="nklyn-listing-share-img" />
          { isOpen
            ? (
              <Fade
                customStyles={{ position: 'absolute' }}
                fadeStart="expand-start"
                fadeEnd="expand-end"
              >
                <ShareDropdown {...this.props} />
              </Fade>
            ) : ''
              }
        </WrapperComponent>
      );
    }
  };
}
