import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Map from '../common/Map'
import s from './GoogleMap.module.css'

const GoogleMap = forwardRef(({ className, children, containerChildren, onLoaded, mapProps }, ref) => (
  <Map
    ref={ref}
    className={clsx(s.container, className)}
    containerChildren={containerChildren}
    onLoaded={onLoaded}
    mapProps={mapProps}
  >
    {children}
  </Map>
))

export default GoogleMap

GoogleMap.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  containerChildren: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onLoaded: PropTypes.func,
  mapProps: PropTypes.shape({}),
}

GoogleMap.defaultProps = {
  className: undefined,
  children: null,
  containerChildren: null,
  onLoaded: null,
  mapProps: {},
}
