/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'

import PropTypes from 'prop-types'
import SubwayRow from 'components/listings/cards/components/SubwayRow'

import FilterCheckbox from '../../common/FilterCheckbox'

import s from './SubwayStop.module.css'

function SubwayStop({ stop, selected, handleSelect }) {
  return (
    <div className={s.root}>
      <FilterCheckbox handleSelect={handleSelect} item={stop} selected={selected} />

      <SubwayRow subwayLines={stop.lines} />
    </div>
  )
}

SubwayStop.propTypes = {
  stop: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    lines: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
}

export default SubwayStop
