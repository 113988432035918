import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'routing';
import { appleResidentialImageMarker, appleCurrentImageMarker } from 'helpers/mapHelpers';
import { ImageAnnotation } from 'components/AppleMap';
import missing from 'images/logo/white-on-black-square.png';

function ListingIndexAnnotation(props) {
  const {
    map,
    selected,
    showAnnotation,
    url,
    image: {
      medium: imageUrl,
    },
    bedrooms,
    bathrooms,
    price,
    short_address: shortAddress,
    latitude,
    longitude,
    id,
    context,
    neighborhood: {
      name: neighborhoodName,
    },
    platform_listing: platformListing,
  } = props;
  const listingSearch = (context === 'listings' || context === 'commercials');

  const title = listingSearch
    ? `${bedrooms} Bed / ${bathrooms} Bath - ${window.accounting.formatMoney(price / 100, { precision: 0 })}`
    : `Private Room - ${window.accounting.formatMoney(price / 100, { precision: 0 })}`;
  let subtitle;
  if (shortAddress) {
    if (!shortAddress.includes('Room')) {
      subtitle = shortAddress;
    } else if (!platformListing) {
      subtitle = shortAddress;
    } else {
      subtitle = neighborhoodName;
    }
  } else {
    subtitle = neighborhoodName;
  }
  const callout = (
    <div className="nearby-callout-content">
      <div className="nearby-callout-img-container">
        <img src={imageUrl} className="nearby-callout-img" alt="apartment" onError={e => { e.target.onerror = null; e.target.src = missing; }} />
      </div>
      <div className="nearby-callout-title">{title}</div>
      <div className="nearby-callout-subtitle">{subtitle}</div>
    </div>
  );
  return (
    <ImageAnnotation
      url={selected || showAnnotation ? appleCurrentImageMarker : appleResidentialImageMarker}
      map={map}
      latitude={latitude}
      longitude={longitude}
      selected={showAnnotation}
      data={{ id }}
    >
      {listingSearch ? (
        <Link href={url}>
          {callout}
        </Link>
      ) : (
        <a href={url}>
          {callout}
        </a>
      )
    }
    </ImageAnnotation>
  );
}

ListingIndexAnnotation.defaultProps = {
  short_address: null,
  neighborhood: {
    name: null,
  },
  map: null,
};

ListingIndexAnnotation.propTypes = {
  map: PropTypes.shape({}),
  showAnnotation: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.shape({
    medium: PropTypes.string,
  }).isRequired,
  bedrooms: PropTypes.number.isRequired,
  bathrooms: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  short_address: PropTypes.string,
  neighborhood: PropTypes.shape({
    name: PropTypes.string,
  }),
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  context: PropTypes.oneOf([
    'listings',
    'commercials',
    'rooms',
    'roommates',
    'sales',
  ]).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { context } = state.search;
  const { selectedItem, selectedMarker } = state.search[context];
  const selected = !!(selectedItem && selectedItem.id === ownProps.id);
  const showAnnotation = !!(selectedMarker && selectedMarker.id === ownProps.id);
  return {
    selected,
    showAnnotation,
    context,
  };
};

export default connect(mapStateToProps)(ListingIndexAnnotation);
