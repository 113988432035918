import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'cropperjs';

const CropperComponent = props => {
  const {
    imageURL,
    imageFileType,
    onCrop: onCropProp,
    config,
    containerClass,
    imageClass,
    buttonClass,
    buttonContent,
  } = props;

  const imageRef = useRef();
  const [cropper, updateCropper] = useState(null);

  useEffect(() => {
    const image = imageRef.current;
    updateCropper(new Cropper(image, config));
  }, [imageRef]);

  function onCrop() {
    const fileType = imageFileType;
    const croppedImageURL = cropper.getCroppedCanvas().toDataURL(fileType);
    return onCropProp(croppedImageURL);
  }

  return (
    <div
      className={containerClass}
    >
      <img
        className={imageClass}
        src={imageURL}
        ref={imageRef}
        alt="cropped-img"
      />
      <button
        onClick={onCrop}
        type="button"
        className={buttonClass}
      >
        {buttonContent}
      </button>
    </div>
  );
};

CropperComponent.defaultProps = {
  config: {
    aspectRatio: 16 / 9,
    minContainerHeight: 400,
    zoomOnWheel: false,
    zoomOnTouch: false,
    dragMode: 'crop',
    viewMode: 2,
    fillColor: '#fff',
  },
  containerClass: 'crop-img-wrapper',
  imageClass: 'crop-img-canvas img-fluid mx-auto',
  buttonClass: 'button btn-transparent-yellow btn-rounded',
  buttonContent: 'Done Cropping',
};

CropperComponent.propTypes = {
  imageURL: PropTypes.string.isRequired,
  onCrop: PropTypes.func.isRequired,
  config: PropTypes.shape({
    aspectRatio: PropTypes.number,
    minContainerHeight: PropTypes.number,
    zoomOnWheel: PropTypes.bool,
    dragMode: PropTypes.oneOf([
      'crop',
      'move',
      'none',
    ]),
    viewMode: PropTypes.oneOf([
      0, 1, 2, 3,
    ]),
  }),
  containerClass: PropTypes.string,
  imageClass: PropTypes.string,
  buttonClass: PropTypes.string,
  imageFileType: PropTypes.oneOf([
    'image/png',
    'image/jpeg',
  ]).isRequired,
  buttonContent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
};

export default CropperComponent;
