/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ListingCardView from 'components/listings/cards/Listings';
import RoomCardView from 'components/listings/cards/Rooms';

// Comonent  for Empty/Loading Slide
const emptySlide = (slideSize, isLoading, key) => (
  <div className={`slider-slides ${(isLoading === true) ? '' : 'poof'}`} style={{ width: slideSize }} key={key}>
    <div className="slider-loading" />
  </div>
);

// Component for See All Btn Slide
const seeAllSlide = (slideSize, url) => {
  let link = '';

  if (url) {
    link = `${window.location.protocol}//${window.location.host}${url}`;
  }

  return (
    <a href={link}>
      <div className="slider-seeAllBtnHolder" style={{ width: slideSize }}>
        <div className="slider-seeAllBtn">
          <div>See All</div>
          {' '}
          <i className="nookons-chevron-right" />
        </div>
      </div>
    </a>
  );
};

// Props:
// --------------------------------------------------
// childSize - Size of each slide (Required)
// currentIndex - currentIndex of Active Slide (Optional) (Default: -1)
// data - Data of what is going to show in slide (Optional) (Default: Undefined)
// firstClick - If clicked for first time (Optional) (Default: false)
// handleClick - Callback for when slides are clicked on (Optional) (Default: () => {})
// isLoading - Tells whether this is a loading slide or not (Required)
// signedIn - Whether user is signed in or not (Optional) (Default: false)
// slideKey - Identifier key for slide (Required)
// sliderLength - Amount of Active Slides to Show (Optional) (Default: -1)
// slideToDisplay - Index of Displayed Slide (Optional) (Default: -1)
// transition - If slider is in the middle of transition (Optional) (Default: '')
// type - Type of Items being Called: rooms or listings (Required) (Default: '')

// NotSoSlick's Slides:
// The slider needs some slides
const Slide = props => {
  const {
    childSize,
    currentIndex,
    data,
    firstClick,
    handleClick,
    isLoading,
    signedIn,
    slideKey,
    sliderLength,
    slideToDisplay,
    transition,
    type,
  } = props;

  const [slide, setSlide] = useState(undefined);

  // Fetches Slide based on type
  const fetchSlide = (slideData, slideType, userSignedIn) => {
    let fetchedSlide = <></>;

    if (slideType === 'rooms') {
      fetchedSlide = (
        <RoomCardView
          wrapperProps={{
            className: 'nooklyn_listing_square nklyn-listing roommate_profile_listing_square',
          }}
          key={slideData.id}
          room={slideData}
          signedIn={userSignedIn}
        />
      );
    } else {
      fetchedSlide = (
        <ListingCardView
          wrapperProps={{
            className: 'nooklyn_listing_square nklyn-listing roommate_profile_listing_square',
          }}
          key={slideData.id}
          listing={slideData}
          signedIn={userSignedIn}
        />
      );
    }

    setSlide(fetchedSlide);
  };

  // Checks if slide is active
  const checkActive = () => {
    if (((currentIndex <= slideToDisplay) && (slideToDisplay < (currentIndex + sliderLength))) && transition === '') {
      return ('');
    }

    if (slideToDisplay < 0 && firstClick) {
      return ('poof');
    }

    return ('inactive');
  };

  const handleOnClick = () => {
    const inactive = (checkActive() === 'inactive');

    handleClick(inactive, slideToDisplay);
  };

  useEffect(() => {
    if (data !== undefined && !isLoading) {
      if (data.NSSOverride === undefined) {
        fetchSlide(data, type, signedIn);
      } else if (data.NSSOverride.startsWith('EmptySlot')) {
        setSlide(emptySlide(childSize, false, data));
      } else {
        setSlide(seeAllSlide(childSize, data.NSSOverride));
      }
    }
  }, [data, type, signedIn, childSize, isLoading, slideToDisplay]);

  return (isLoading === true || slide === undefined) ? (
    emptySlide(childSize, true, slideKey)
  ) : (
    <div
      role="menuitem"
      aria-hidden
      className={`slider-slides ${checkActive()}`}
      onClick={handleOnClick}
      index={slideToDisplay}
      key={slideKey}
    >
      {slide}
    </div>
  );
};

Slide.propTypes = {
  childSize: PropTypes.number.isRequired,
  currentIndex: PropTypes.number,
  data: PropTypes.shape({
    id: PropTypes.number,
    NSSOverride: PropTypes.string,
  }),
  firstClick: PropTypes.bool,
  handleClick: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  signedIn: PropTypes.bool,
  slideKey: PropTypes.string.isRequired,
  sliderLength: PropTypes.number,
  slideToDisplay: PropTypes.number,
  transition: PropTypes.string,
  type: PropTypes.string,
};

Slide.defaultProps = {
  currentIndex: -1,
  data: undefined,
  firstClick: false,
  handleClick: () => { },
  signedIn: false,
  sliderLength: -1,
  slideToDisplay: -1,
  transition: '',
  type: '',
};

export default Slide;
