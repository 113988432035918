import React, { useState, useEffect } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import Dropdown from '../Dropdown/PaymentDropdown';
import PaymentSummary from './PaymentSummary';
import FlashMessage from '../FlashMessage';
import { cleanCountryData, loadRegions } from '../../helpers/util';

const createOptions = fontSize => ({
  style: {
    base: {
      fontSize,
      color: '#495057',
      letterSpacing: '0.025em',
      fontWeight: '500',
      fontFamily:
        '"Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: 'red',
    },
  },
});

const defaultRegionOptions = loadRegions(cleanCountryData, 'US');

const PaymentRequestForm = props => {
  const {
    creditPaymentRequest, submitting, errors, submitForm, createPayment, generateStripePaymentIntent, paymentIntent,
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [regionOptions, setRegionOptions] = useState(defaultRegionOptions);
  const [country, setCountry] = useState('US');
  const [state, setState] = useState('');
  const [disable, setDisable] = useState(true);
  const [complete, setComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });
  const [postal, setPostal] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (creditPaymentRequest.signature) {
      generateStripePaymentIntent(creditPaymentRequest.signature);
    }
  }, [creditPaymentRequest]);

  const handleChange = change => {
    const changeObj = {};
    changeObj[change.elementType] = change.complete;
    setComplete({ complete, changeObj });
    if (Object.keys(complete).every(k => complete[k])) {
      setDisable(false);
    } else if (!disable) {
      setDisable(true);
    }
  };

  const handleStateChange = item => {
    if (item) {
      setState(item.value);
    } else {
      setState('');
    }
  };

  const handleCountryChange = item => {
    if (item && item.value) {
      const regions = loadRegions(cleanCountryData, item.value);
      setRegionOptions(regions);
      setCountry(item.value);
    } else {
      setRegionOptions(defaultRegionOptions);
      setCountry('US');
    }
  };

  const cleanUpCardDetails = details => Object.keys(details).forEach(key => {
    if (details[key] === undefined) {
      delete details[key];
    }
  });

  const handleSubmit = async evt => {
    evt.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (country === 'US' && !state) {
      setErrorMessage('Please input state.');
      return;
    }

    const address_one = evt.target['payment_detail[address_one]'].value;
    const address_two = evt.target['payment_detail[address_two]'].value;
    const city = evt.target['payment_detail[city]'].value;
    const name = evt.target['payment_detail[name]'].value;
    const telephone = evt.target['payment_detail[phone_number]'].value;

    submitForm();

    const cardElement = elements.getElement(CardNumberElement);

    const address = cleanUpCardDetails({
      city,
      state,
      country,
      line1: address_one,
      line2: address_two,
      postal_code: postal,
    });

    const payload = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name,
          address,
        },
        metadata: {
          audit_token: creditPaymentRequest.audit_token,
        },
      },
    });

    if (payload.error) {
      setErrorMessage(payload.error.message);
    } else {
      setErrorMessage(null);
      createPayment(
        {
          payment_intent_id: paymentIntent.id,
          address_one,
          address_two,
          city,
          country,
          name,
          state,
          telephone,
          zip_code: postal,
        },
        creditPaymentRequest.signature,
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="payment-detail">
        <h1>PAY WITH CREDIT</h1>
        <hr className="entire-width" />
        {errors
            && errors.fields
            && errors.fields.includes('base')
            && !!errors.messages.length && (
              <FlashMessage message={errors.messages[0]} />
        )}
        <h2>PAYMENT INFO</h2>
        <label>Card Number</label>
        <div className="credit-card-icons">
          <li className="list-inline-item">
            <i className="nookons-pay-visa nklyn-2x" />
          </li>
          <li className="list-inline-item">
            <i className="nookons-pay-discover nklyn-2x" />
          </li>
          <li className="list-inline-item">
            <i className="nookons-pay-amex nklyn-2x" />
          </li>
          <li className="list-inline-item">
            <i className="nookons-pay-mastercard nklyn-2x" />
          </li>
        </div>
        <CardNumberElement
          onChange={handleChange}
          placeholder=""
          className={
              errors && errors.fields && errors.fields.includes('base')
                ? 'general-text-field form-control stripe-error'
                : 'general-text-field form-control'
            }
          options={createOptions('14')}
        />
        <div className="row mt-3">
          <div className="col-sm-6">
            <label>Expiration Date</label>
            <CardExpiryElement
              onChange={handleChange}
              placeholder=""
              className={
                  errors && errors.fields && errors.fields.includes('base')
                    ? 'general-text-field form-control stripe-error'
                    : 'general-text-field form-control'
                }
              options={createOptions('14')}
            />
          </div>
          <div className="col-sm-6">
            <label>CVC</label>
            <CardCvcElement
              onChange={handleChange}
              placeholder=""
              className={
                  errors && errors.fields && errors.fields.includes('base')
                    ? 'general-text-field form-control stripe-error'
                    : 'general-text-field form-control'
                }
              options={createOptions('14')}
            />
          </div>
        </div>
        <hr className="entire-width" />
        <h2>BILLING INFO</h2>
        <label>Country</label>
        <Dropdown
          onChange={handleCountryChange}
          options={cleanCountryData}
          placeholder="Select Country"
          value={country}
        />
        <label>Full Name</label>
        <input
          type="text"
          name="payment_detail[name]"
          id="payment_detail_name"
          className="general-text-field form-control"
          required
        />
        <div className="row">
          <div className="col-sm-8">
            <label>Billing Address</label>
            <input
              type="text"
              name="payment_detail[address_one]"
              className="general-text-field form-control"
              id="payment_detail_address_one"
              style={
                  errors && errors.fields && errors.fields.includes('address_one')
                    ? { borderColor: 'red' }
                    : {}
                }
              required
            />
          </div>
          <div className="col-sm-4">
            <label>Unit Number (Optional)</label>
            <input
              type="text"
              name="payment_detail[address_two]"
              className="general-text-field form-control"
              id="payment_detail_address_two"
            />
          </div>
        </div>
        <label>Billing City</label>
        <input
          type="text"
          name="payment_detail[city]"
          className="general-text-field form-control"
          id="payment_detail_city"
          style={
              errors && errors.fields && errors.fields.includes('city')
                ? { borderColor: 'red' }
                : {}
            }
          required
        />
        <div className="row">
          <div className="col-sm-6">
            <label>Billing State / Region</label>
            <Dropdown
              onChange={handleStateChange}
              options={regionOptions}
              placeholder="Select State"
              value={state}
              style={
                  errors && errors.fields && errors.fields.includes('state')
                    ? { borderColor: 'red' }
                    : {}
                }
              required
            />
          </div>
          <div className="col-sm-6">
            <label>Billing Zipcode</label>
            <input
              id="postal"
              required
              placeholder=""
              value={postal}
              onChange={e => {
                setPostal(e.target.value);
              }}
              className={
                  errors && errors.fields && errors.fields.includes('base')
                    ? 'general-text-field form-control stripe-error'
                    : 'general-text-field form-control'
                }
              style={
                  errors && errors.fields && errors.fields.includes('zip_code')
                    ? { borderColor: 'red' }
                    : {}
                }
            />
            <input
              type="hidden"
              name="payment_detail[zip_code]"
              className="general-text-field form-control"
              id="payment_detail_address_zip_code"
            />
          </div>
        </div>
        <label>Phone Number</label>
        <input
          type="text"
          name="payment_detail[phone_number]"
          className="general-text-field form-control"
          id="payment_detail_address_phone_number"
          style={
              errors && errors.fields && errors.fields.includes('telephone')
                ? { borderColor: 'red' }
                : {}
            }
          required
        />
        <hr className="entire-width" />
        <p>
          Nooklyn does not store any credit card or bank account information.
          I authorize Nooklyn NYC LLC to charge my credit card for the total
          amount listed.
        </p>
        <PaymentSummary payment={creditPaymentRequest} />
        { errorMessage && <p className="error">{errorMessage}</p>}
        <div className="row justify-content-center">
          <div className="col-sm-3">
            <input
              type="submit"
              name="commit"
              value="Pay"
              className="button btn-black-yellow btn-rounded btn-pay"
              disabled={!stripe || submitting || disable || !paymentIntent}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default PaymentRequestForm;
