import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'wrappers/FlexTable';
import {
  togglePopover,
  setStore,
  updateActiveSearch,
  convertSavedSearchToSelectedValues,
  revertSelectedHoodsToPrevious,
} from 'reducers/search';
import Fade from 'wrappers/Fade';
import {
  MinPrice, MaxPrice, Hood, Bed, Bath, Amenities, Subway, NoFee, Pets, Liked,
} from './sentenceSearchComponents/index';

const SentenceSearchModal = (props) => {
  const node = useRef(null);
  const onClose = (e) => {
    e.stopPropagation();
    const {
      _setStore,
      _convertSavedSearchToSelectedValues,
      _revertSelectedHoodsToPrevious,
    } = props;
    _setStore({ sentenceSearchDisplay: false });
    _convertSavedSearchToSelectedValues();
    _revertSelectedHoodsToPrevious();
  };

  const cleanup = () => {
    node.current.el.removeEventListener('transitionend', transitionCallback);
  };

  const { sentenceSearchDisplay, _updateActiveSearch } = props;

  const transitionCallback = () => {
    const event = new CustomEvent('paramsinitialized');
    window.dispatchEvent(event);
  };

  const addTransitionListener = () => {
    node.current.el.addEventListener('transitionend', transitionCallback);
  };

  useEffect(() => {
    addTransitionListener();
    return cleanup;
  }, []);

  return (
    <Fade
      ref={node}
      fadeStart="sentence-search-start"
      fadeEnd="sentence-search-end"
      visible={sentenceSearchDisplay}
      customStyles={{
        width: '100%',
        height: '100%',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
      }}
    >
      <Modal
        overlayStyle={{
          backgroundColor: 'rgba(0,0,0,0.95)',
        }}
        contentStyle={{
          backgroundColor: 'transparent',
          border: '0px',
          opacity: '1',
          width: '100%',
          height: '100%',
        }}
        overlayClass="sentence-search-wrapper"
        contentClass="listing-search-modal-content modal-content"
      >
        <div
          style={{
            position: 'relative',
            border: 'none',
            zIndex: 5000,
          }}
          className="modal-header sentence-close-button"
        >
          <div className="listings-index-search-bar">
            <button
              onClick={onClose}
              style={{ cursor: 'pointer', outline: 0 }}
              type="button"
              className="close text-white"
              aria-label="Close"
            >
              <span>×</span>
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div
            className="listings-index-search-bar sentence-search-body"
            style={{
              textAlign: 'left',
              position: 'relative',
            }}
          >
          My budget is $<MinPrice/> to $<MaxPrice/>, find me apartments in
          &nbsp;<Hood/> with <Bed/> / <Bath/> and these
            <Amenities/>. <span
              id="listing-search-sentence-transit" ></span>I'd like to live near <Subway />
            &nbsp;I am interested in listings with <NoFee/>.
            &nbsp;Pets: <Pets />
            &nbsp;Liked:
            {' '}
            <Liked />
            <br />
            <br />
            <br />
            <input
              onClick={_updateActiveSearch}
              type="submit"
              name="commit"
              value="Search"
              id="search-listing-filters"
              className="button btn-yellow-black btn-rounded sentence-search-button"
            />
          </div>
        </div>
      </Modal>
    </Fade>
  );
};

SentenceSearchModal.propTypes = {
  _setStore: PropTypes.func.isRequired,
  _convertSavedSearchToSelectedValues: PropTypes.func.isRequired,
  _revertSelectedHoodsToPrevious: PropTypes.func.isRequired,
  _updateActiveSearch: PropTypes.func.isRequired,
  sentenceSearchDisplay: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => ({
  _togglePopover: name => dispatch(togglePopover(name)),
  _setStore: payload => dispatch(setStore(payload)),
  _updateActiveSearch: () => dispatch(updateActiveSearch()),
  _convertSavedSearchToSelectedValues: () => dispatch(convertSavedSearchToSelectedValues()),
  _revertSelectedHoodsToPrevious: () => dispatch(revertSelectedHoodsToPrevious()),
});

const mapStateToProps = state => ({
  sentenceSearchDisplay: state.search[state.search.context].sentenceSearchDisplay || false,
});

export default connect(mapStateToProps, mapDispatchToProps)(SentenceSearchModal);
