import axios from 'axios'
import { getMetaContent, redirectApplyPath } from '../helpers/util'

// INITIAL STATE
const defaultUser = {
  selectRegionStatus: false,
  signupStatus: true,
  submitting: false,
  currentUserFetched: false,
}

// ACTION TYPES
const GET_USER = 'GET_USER'
const REMOVE_USER = 'REMOVE_USER'
const SELECT_REGION = 'SELECT_REGION'
const SUBMIT_FORM = 'SUBMIT_FORM'
const SUBMITTED = 'SUBMITTED'
const FETCH_CURRENT_USER_TRUE = 'FETCH_CURRENT_USER_TRUE'
const REMOVE_REDIRECT_APPLY = 'REMOVE_REDIRECT_APPLY'

// ACTION CREATORS
const getUser = (user) => ({ type: GET_USER, user })
export const submitForm = () => ({ type: SUBMIT_FORM })
const submitted = () => ({ type: SUBMITTED })
export const fetchCurrentUserTrue = () => ({ type: FETCH_CURRENT_USER_TRUE })
export const removeRedirectApply = () => ({ type: REMOVE_REDIRECT_APPLY })

// THUNK CREATORS
export const fetchCurrentUser = () => (dispatch) =>
  axios
    .get('/auth/is_signed_in')
    .then((res) => {
      dispatch(getUser(res.data || defaultUser))
    })
    .catch((err) => console.log(err))

function gtagReportConversion(url) {
  var callback = function () {
    if (typeof url != 'undefined') {
      window.location = url
    }
  }
  // eslint-disable-next-line no-undef
  gtag('event', 'signup', {
    send_to: 'AW-968054781/na1QCIPDoLwYEP2vzc0D',
    event_callback: callback,
  })
  return false
}

export const login = (email, password, remember_me, redirect) => (dispatch) =>
  axios
    .post(`/sign_in.json`, {
      agent: { email, password, remember_me },
      authenticity_token: getMetaContent('csrf-token'),
    })
    .then((res) => {
      dispatch(getUser(res.data))

      localStorage.setItem('agentId', res.data.api_token.agent_id)
      localStorage.setItem('apiToken', res.data.api_token.token)

      // Clean up unused localStorage keys on devices
      if (localStorage.getItem('agentToken')) {
        localStorage.removeItem('agentToken')
      }

      if (redirect) {
        window.location = redirect.includes('/-1') ? redirect.replace(/-1/, res.data.slug) : redirect
      } else {
        window.location.reload()
      }
    })
    .catch((err) => {
      dispatch(submitted())
      if (err.response.data.errors && err.response.data.errors.profile_picture_content_type) {
        window.location.reload()
      } else if (err.response.data.error) {
        dispatch(getUser({ loginError: err.response.data.error }))
      } else {
        dispatch(getUser({ loginError: 'Please refresh the page, your session has expired.' }))
      }
    })

export const signup =
  (email, password, password_confirmation, first_name, last_name, email_opt_in, redirect) => (dispatch) => {
    axios
      .post(`/sign_up.json`, {
        agent: { email, password, password_confirmation, first_name, last_name, email_opt_in },
        authenticity_token: getMetaContent('csrf-token'),
      })
      .then((res) => {
        gtagReportConversion()
        dispatch(getUser(res.data))
        localStorage.setItem('agentId', res.data.api_token.agent_id)
        localStorage.setItem('apiToken', res.data.api_token.token)

        // Clean up unused localStorage keys on devices
        if (localStorage.getItem('agentToken')) {
          localStorage.removeItem('agentToken')
        }

        if (redirect) {
          window.location = redirect.includes('/-1') ? redirect.replace(/-1/, res.data.slug) : redirect
        } else {
          window.location.href = `/agents/${res.data.slug}`
        }
      })
      .catch((err) => {
        dispatch(submitted())
        dispatch(getUser({ signupError: err.response.data.errors }))
      })
  }

export const selectRegion = (agent_id, region_id, redirect) => (dispatch) =>
  axios
    .put(`/agents/${agent_id}.json`, {
      agent: { agent_id, region_id },
      authenticity_token: getMetaContent('csrf-token'),
    })
    .then((res) => {
      dispatch(getUser(res.data))
      // TODO add case 'apply'
      switch (redirect) {
        case 'create':
          window.location.href = '/create'
          break
        default:
          window.location.href = `/agents/${res.data.slug}`
      }
    })
    .catch((err) => console.log(err))

export const clearErrorMessages = () => (dispatch) => {
  dispatch(getUser({ signupError: '' }))
  dispatch(getUser({ loginError: '' }))
}

// REDUCERS
export default function (state = defaultUser, action) {
  switch (action.type) {
    case GET_USER:
      return { ...state, ...action.user }
    case REMOVE_USER:
      return defaultUser
    case SELECT_REGION:
      return { ...state, selectRegionStatus: true, signupStatus: false }
    case SUBMIT_FORM:
      return { ...state, submitting: true }
    case SUBMITTED:
      return { ...state, submitting: false }
    case FETCH_CURRENT_USER_TRUE:
      return { ...state, currentUserFetched: true }
    default:
      return state
  }
}
