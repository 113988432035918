import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/generic/Popover';
import Dropdown from './components/Dropdown';
import Filter from './components/Filter';
import SearchableDropdownContext from './SearchableDropdownContext';

// Example Data:
// --------------------------------------------------
// initSelected = [id, value]

const SearchableDropdown = props => {
  const {
    children,
    containerClass,
    dropdownClass,
    filterClass,
    loading: parentLoading,
    noCheck,
    onUpdate,
    placeholder,
    initSelected,
    showTagsInFilter,
    singleSelect,
    tagClass,
    type,
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const targetRef = useRef(null);

  const handleSearchUpdate = () => {
    setSearchValue(targetRef.current.value);
  };

  useEffect(() => {
    if (!parentLoading && isLoading) {
      setSelected([].concat(initSelected));
      setIsLoading(false);
    }
  }, [isLoading, parentLoading, initSelected]);

  useEffect(() => {
    if (!isLoading) {
      onUpdate(selected);
    }
  }, [isLoading, selected]);

  const dropdownData = {
    searchValue,
    setSelected,
    selected,
    showTagsInFilter,
    singleSelect,
  };

  return (
    <SearchableDropdownContext.Provider value={dropdownData}>
      <div className={`searchable-dropdown-container ${containerClass}`}>
        <Filter
          className={filterClass}
          ref={targetRef}
          onChange={handleSearchUpdate}
          placeholder={placeholder}
          showTagsInFilter={showTagsInFilter}
          style={{}}
          tagClassName={tagClass}
        />
        {(type === 'popover')
          ? (
            <Popover type="focus" targetRef={targetRef} style={{ width: targetRef.current ? `${targetRef.current.width}` : '325px', backgroundColor: '#111111', textAlign: 'center' }}>
              <Dropdown
                className={dropdownClass}
                noCheck={noCheck}
              >
                {children}
              </Dropdown>
            </Popover>
          )
          : (
            <Dropdown
              className={dropdownClass}
              noCheck={noCheck}
            >
              {children}
            </Dropdown>
          )}
      </div>
    </SearchableDropdownContext.Provider>
  );
};

SearchableDropdown.defaultProps = {
  containerClass: '',
  filterClass: '',
  dropdownClass: '',
  loading: false,
  noCheck: false,
  onUpdate: () => {},
  placeholder: '',
  showTagsInFilter: true,
  singleSelect: false,
  tagClass: '',
  type: 'popover',
};

SearchableDropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  containerClass: PropTypes.string,
  dropdownClass: PropTypes.string,
  filterClass: PropTypes.string,
  initSelected: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  ).isRequired,
  loading: PropTypes.bool,
  noCheck: PropTypes.bool,
  onUpdate: PropTypes.func,
  placeholder: PropTypes.string,
  showTagsInFilter: PropTypes.bool,
  singleSelect: PropTypes.bool,
  tagClass: PropTypes.string,
  type: PropTypes.oneOf(['popover', 'modal']),
};

export default SearchableDropdown;
