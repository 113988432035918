import { useEffect } from 'react'

export function checkIsOutsideClick(rootElement, evt) {
  if (rootElement === evt.target || rootElement.contains(evt.target)) {
    return false
  }

  return true
}

export function useClickOutside(ref, onClose) {
  useEffect(() => {
    const rootElement = ref.current

    function handleClickOutside(evt) {
      if (!rootElement || !checkIsOutsideClick(rootElement, evt)) {
        return
      }

      onClose?.()
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [ref, onClose])
}
