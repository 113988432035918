import { createContext } from 'react';

const AgentSelectorContext = createContext({
  agents: [],
  isLoading: true,
  selected: '',
  setSelected: () => { },
  singleSelect: false,
  onUpdate: () => {},
});

export default AgentSelectorContext;
