import React from 'react';
import { numberToCurrency } from 'helpers/railsMethods';
import Fade from 'wrappers/Fade';
import PropTypes from 'prop-types';

const Details = props => {
  const {
    listing,
  } = props;

  const {
    price,
    bedrooms,
    bathrooms,
    neighborhood,
    noFee,
    subwayLines,
    residential,
    squareFeet,
    shortAddress,
    rental,
    concessions,
  } = listing;

  return (
    <>
      { listing
        && (
        <Fade>
          <div className="listing-details-area">
            <div className="d-flex justify-content-between align-items-center">
              <span className="details-title">{`${numberToCurrency(price)}${rental ? '/month' : ''}`}</span>
              { noFee
                && (
                  <button className="button btn-rounded btn-transparent details-button m-0">No Fee</button>
                )}
            </div>
            { !!concessions.length && (
              <h5 style={{ color: '#7A7A7A', paddingLeft: '0' }}>Net-Effective Options</h5>
            )}
            { !!concessions.length && concessions.map(concession => (
              <div className="concession-option-card" key={concession.id}>
                <div>
                  <div className="concession-price">
                    { accounting.formatMoney(concession.netEffectiveRent, { precision: 0 }) }
                  </div>
                  <div className="concession-notes">Net-effective cost with {concession.unitFree} {concession.unitType}{concession.unitFree > 1 ? 's':''} free</div>
                  <div className="concession-notes">when you sign a {concession.leaseTerm}-month lease</div>
                </div>
                <div>
                  <div className="concession-save">
                    Save { accounting.formatMoney(price - concession.netEffectiveRent, { precision: 0 }) } per month
                  </div>
                  <div className="concession-save">
                    ({(((price - concession.netEffectiveRent) / price) * 100).toFixed(2)}% off)
                  </div>
                </div>
              </div>
            ))}
            {residential
              ? (
                <div className="d-flex">
                  <div className="flex-fill">
                    {bedrooms}
                    {' '}
                    { bedrooms > 1 ? 'Beds' : 'Bed' }
                  </div>
                  <div className="flex-fill border-left">
                    {bathrooms}
                    {' '}
                    { bathrooms > 1 ? 'Baths' : 'Baths' }
                  </div>
                </div>
              ) : (
                <div className="d-flex">
                  <div className="flex-fill">
                    {squareFeet}
                    {' '}
                    SF
                  </div>
                  <div className="flex-fill border-left">
                   {numberToCurrency(price * 12 / squareFeet)}
                    {' '}
                    PPSF
                  </div>
                </div>
              )}
            <div className="d-flex align-items-center">
              {neighborhood && neighborhood.name}
            </div>
            <div className="d-flex align-items-center">
              {shortAddress}
            </div>
            <div className="d-flex align-items-center border-bottom-0 mb-4">
              {subwayLines.length > 0 && (
                <div className="">
                  {subwayLines.map(line => <img key={line} className="train" alt={`${line} train`} src={line && require(`images/subway/2x/${line}`)} />)}
                </div>
              )}
            </div>
          </div>
        </Fade>
        )}
    </>
  );
};

Details.propTypes = {
  listing: PropTypes.shape({
    price: PropTypes.number,
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    neighborhood: PropTypes.shape({
      name: PropTypes.string,
    }),
    noFee: PropTypes.bool,
    subwayLines: PropTypes.arrayOf(PropTypes.string),
    residential: PropTypes.bool,
    squareFeet: PropTypes.number,
    shortAddress: PropTypes.string,
    rental: PropTypes.bool,
    concessions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      leaseTerm: PropTypes.number,
      listingId: PropTypes.number,
      netEffectiveRent: PropTypes.number,
      unitFree: PropTypes.number,
      unitType: PropTypes.string,
    })),
  }).isRequired,
};

export default Details;
