import React from 'react';
import logo from 'images/logo/primaryLogo.png';
import missing from 'images/logo/white-on-black-wide.png';

const ListingCardImage = props => {
  const {
    image,
    url,
    listing,
  } = props;

  const {
    no_fee: noFee,
    rental,
    brokerage_listing: brokerageListing,
    rooms,
    rooms_available: roomsAvailable,
    short_address: shortAddress,
  } = listing || {};

  return (
    <a href={url} target="_blank" rel="noreferrer">
      <div className="listing-card-img-container">
        <img className="nklyn-listing-card-apartment-img" src={image.wide} alt={shortAddress} onError={e => { e.target.onerror = null; e.target.src = missing; }} />
        <div className="nooklyn_listing_tag">
          {brokerageListing && (
            <div className="nooklyn_listing_square_home_icon">
              <img alt="nooklyn" src={logo} className="nook-logo" />
            </div>
          )}
          {noFee ? (
            <div className="nooklyn_listing_square_fee">No Fee</div>
          ) : (
            ''
          )}
          {!rental && (
            <div className="nooklyn_listing_square_for_sale">For Sale</div>
          )}
          {rooms && rooms.count ? (
            <div className="nooklyn_listing_square_rooms_available">{`${roomsAvailable}/${rooms.count} Rooms Available`}</div>
          ) : ''}
        </div>
      </div>
    </a>
  );
};

export default ListingCardImage;
