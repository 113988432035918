import React from 'react';
import PropTypes from 'prop-types';
import IndividualRegion from './IndividualRegion';

const HoodList = props => {
  const {
    selected,
    onChange,
    options,
    hoodFilter,
    setHoodFilter,
  } = props;

  const selectedNeighborhoods = options.neighborhoods
    .filter(neighborhood => selected.neighborhoods.includes(neighborhood.id));
  const selectedRegions = options.regions
    .filter(region => selected.regions.includes(region.name));

  const updateSelectedRegions = region => {
    const originalRegions = selected.regions ? selected.regions : [];
    const neighborhoods = options.neighborhoods
      .filter(neighborhood => neighborhood.region === region);

    let newSelectedRegions = [];
    let neighhborhoodIds = [];

    if (neighborhoods.length) {
      neighhborhoodIds = neighborhoods.map(neighborhood => neighborhood.id);
    }

    if (selected.regions.includes(region)) {
      newSelectedRegions = originalRegions.filter(value => value !== region);
    } else {
      newSelectedRegions = [...originalRegions, region];
    }
    return {
      regions: newSelectedRegions,
      neighborhoods: [
        ...selectedNeighborhoods
          .filter(neighborhood => !neighhborhoodIds.includes(neighborhood.id)),
      ].map(neighborhood => neighborhood.id),
    };
  };

  const updateSelectedNeighborhood = neighborhood => {
    const originalNeighborhoods = selected.neighborhoods ? selected.neighborhoods : [];
    const originalRegions = selected.regions ? selected.regions : [];

    let newSelectedRegions = [];
    let newSelectedNeighborhoods = [];

    // Grab Info of Neighborhood and Associated Region
    const selectedNeighborhoodInfo = options.neighborhoods
      .filter(_neighborhood => _neighborhood.id === neighborhood)[0];
    const selectedRegionInfo = options.regions
      .filter(_regions => _regions.name === selectedNeighborhoodInfo.region)[0];

    // Checks for if region completely selected or neighborhood is just selected
    const neighboorhoodRegionSelected = selectedRegions
      .some(region => region.name === selectedRegionInfo.name);
    const neighborhoodAlreadySelected = selectedNeighborhoods
      .some(_neighborhood => _neighborhood.id === neighborhood);

    if (neighborhoodAlreadySelected || neighboorhoodRegionSelected) {
      // Remove Neighborhood from selected
      newSelectedNeighborhoods = selectedNeighborhoods
        .filter(_neighborhoods => _neighborhoods.id !== neighborhood)
        .map(_neighborhood => _neighborhood.id);

      // If complete region was selected,
      // add in all neighborhoods that arent neighborhood being removed
      if (neighboorhoodRegionSelected) {
        newSelectedNeighborhoods = [
          ...newSelectedNeighborhoods,
          ...selectedRegionInfo.neighborhoods
            .filter(_neighborhood => `${_neighborhood.id}` !== neighborhood)
            .map(_neighborhood => `${_neighborhood.id}`),
        ];
      }

      // If region selected, filter it out else filter leaves everything
      newSelectedRegions = selectedRegions
        .filter(region => (neighboorhoodRegionSelected
          ? region.name !== selectedRegionInfo.name
          : true
        ))
        .map(region => region.name);
    } else {
      setHoodFilter('');
      newSelectedNeighborhoods = [
        ...originalNeighborhoods,
        selectedNeighborhoodInfo.id,
      ];

      let filteredRegions = originalRegions;

      if (selectedNeighborhoodInfo !== undefined) {
        const notFoundNeighborhoods = selectedRegionInfo.neighborhoods
          .filter(_neighborhood => !newSelectedNeighborhoods
            .some(newNeighborhood => `${_neighborhood.id}` === newNeighborhood));

        if (notFoundNeighborhoods.length === 0) {
          filteredRegions = [...originalRegions, selectedNeighborhoodInfo.region];
          newSelectedNeighborhoods = newSelectedNeighborhoods
            .filter(neighborhoodId => !selectedRegionInfo.neighborhoods
              .some(selectedRegionInfoNeighborhood => (
                `${selectedRegionInfoNeighborhood.id}` === neighborhoodId
              )));
        }
      }

      newSelectedRegions = filteredRegions;
    }

    return {
      regions: newSelectedRegions,
      neighborhoods: newSelectedNeighborhoods,
    };
  };

  const handleSelect = (value, type) => {
    switch (type) {
      case 'neighborhoods':
        onChange(updateSelectedNeighborhood(value));
        break;
      default:
        if (!hoodFilter.length) {
          onChange(updateSelectedRegions(value));
        }
        break;
    }
  };

  return (
    <div
      className="region-hood-content"
      style={{
        width: '110%',
        maxHeight: '250px',
        overflowY: 'scroll',
      }}
    >
      { options.regions && options.regions.map(region => (
        <IndividualRegion
          key={`Location-${region.name}`}
          region={region}
          selected={{
            selectedRegions,
            selectedNeighborhoods,
          }}
          handleSelect={handleSelect}
          hoodFilter={hoodFilter}
        />
      ))}
    </div>
  );
};

HoodList.propTypes = {
  selected: PropTypes.shape({
    regions: PropTypes.arrayOf(PropTypes.string),
    neighborhoods: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  hoodFilter: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    regions: PropTypes.arrayOf(PropTypes.shape({})),
    neighborhoods: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  setHoodFilter: PropTypes.func.isRequired,
};

export default HoodList;
