import React from 'react';
import { QRCode } from 'react-qr-svg';
import PhoneNumber from 'components/PhoneNumber';
import sentSvg from 'images/icons/svg/sent.svg';
import approvalSvg from 'images/icons/svg/approval.svg';
import PassbaseVerifyButton from '../PassbaseVerifyButton';

export const VerifyIdentity = props => {
  const {
    handleVerifyPhotoId, handleVerifyRealEstateId, photoIdStatus, realEstateLicenseStatus, setPhotoIdStatus,
  } = props;

  return (
    <div className="container id-verification-modal">
      <div className="title">
        Verify your identity
      </div>
      { photoIdStatus === 'completed' && realEstateLicenseStatus === 'completed' && (
      <p className="form-help-block text-center">
        <b>You&apos;re all set!</b>
        <div>There&apos;s nothing left to do</div>
      </p>
      )}
      <div className="verify-id-block">
        <div className="mr-4">
          <div className="verify-id-step">Step 1</div>
          <div className="verify-id-title">Photo ID</div>
          <div className="verify-id-description">Scan your photo ID (like a driver’s license or passport) and show us a selfie.</div>
        </div>
        <div>
          { photoIdStatus !== 'pending' && photoIdStatus !== 'completed'
            && <PassbaseVerifyButton setPhotoIdStatus={setPhotoIdStatus} />}
          { photoIdStatus === 'pending'
            && <button className="button btn-transparent btn-rounded" style={{minWidth: '125px', pointerEvents: 'none'}}>In progress</button>}
          { photoIdStatus === 'completed'
            && <button className="button btn-transparent btn-rounded" style={{minWidth: '125px', pointerEvents: 'none'}}>Done<i className="nookons-check ml-2" style={{ color: "#26AE90", fontWeight: 'bold'}}/></button>}
        </div>
      </div>
      <hr className="entire-width" />
      <div className="verify-id-block">
        <div className="mr-4">
          <div className="verify-id-step">Step 2</div>
          <div className="verify-id-title">Real estate license</div>
          <div className="verify-id-description">Enter the details about your real estate license like the number and expiration date.</div>
        </div>
        <div>
          { realEstateLicenseStatus !== 'completed' && realEstateLicenseStatus !== 'pending'
            && <button className="button btn-yellow-black btn-rounded" style={{minWidth: '125px'}} onClick={handleVerifyRealEstateId}>Start</button>}
          { realEstateLicenseStatus === 'pending'
            && <button className="button btn-transparent btn-rounded" style={{minWidth: '125px', pointerEvents: 'none'}}>In progress</button>}
          { realEstateLicenseStatus === 'completed'
            && <button className="button btn-transparent btn-rounded" style={{minWidth: '125px', pointerEvents: 'none'}}>Done<i className="nookons-check ml-2" style={{ color: "#26AE90", fontWeight: 'bold'}}/></button>}
        </div>
      </div>
    </div>
  );
};

export const VerifyPhotoId = props => {
  const { verificationSession, error, phoneNumber, handlePhotoIdSubmit, handlePhoneNumberChange} = props;

  return (
    <div className="container id-verification-modal">
      <div className="title">
        Verify with photo ID
      </div>
      <form onSubmit={handlePhotoIdSubmit}>
        {error && <p className="error text-center">{error}</p>}
        <div className="description-notes">
          Scan this QR code with your smart device to open the verification camera
        </div>
        {verificationSession && verificationSession.url
          && (
            <div className="qr-code-canvas">
              <QRCode value={verificationSession.url} className="qr-code" />
            </div>
          )}
        <p className="or">or</p>
        <div className="description-notes">
          Enter your phone number so we can text you a link
        </div>
        <input
          className="general-text-field mb-3 d-flex mx-auto"
          name="phoneNumber"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder="555-555-5555"
          style={{ width: '440px' }}
          required
        />
        <PhoneNumber
          name="phoneNumber"
          placeholder="Your Cell Phone Number"
          className="general-text-field mt-2 mb-4"
        />
        <input
          type="submit"
          name="commit"
          value="Send me the link"
          className="button btn-yellow-black btn-rounded mt-3"
          style={{ minWidth: '200px'}}
          disabled={!!phoneNumber && !!phoneNumber.length}
        />
      </form>
      <div className="description-notes text-muted">
        We do <b style={{ fontStyle: 'italic'}}>not</b> save your phone number. We use it only to text you a link.
      </div>
    </div>
  );
};

export const PhoneTextSent = props => {
  const { phoneNumber, handleVerifyIdentity } = props;

  return (
    <div className="container id-verification-modal text-center">
      <img alt="message" src={sentSvg} />
      <div className="title mt-3">Sent!</div>
      <div className="description-notes" style={{ maxWidth: '400px'}}>
        We sent a link to {phoneNumber}. Tap the link in the message to continue verifying your state ID.
      </div>
      <div>
        <button className="button btn-transparent-yellow btn-rounded ml-2" onClick={handleVerifyIdentity}>Continue</button>
      </div>
    </div>
  );
};

export const StateIdApproved = props => {
  const { closeModal, handleVerifyIdentity } = props;

  return (
    <div className="container id-verification-modal text-center">
      <img alt="approved" src={approvalSvg} />
      <div className="title mt-3">Your state ID was approved!</div>
      <div className="description-notes" style={{ maxWidth: '400px'}}>
        Thanks for doing all that work! You’re all set now!
      </div>
      <div>
        <button className="button btn-transparent btn-rounded" onClick={closeModal}>Dismiss</button>
        <button className="button btn-transparent-yellow btn-rounded ml-2" onClick={handleVerifyIdentity} >Continue</button>
      </div>
    </div>
  );
};

export const RealEstateIdApproved = props => {
  const { handleContinue } = props;

  return (
    <div className="container id-verification-modal text-center">
      <img alt="approved" src={approvalSvg} />
      <div className="title mt-3">Your real estate license was approved!</div>
      <div className="description-notes" style={{ maxWidth: '400px'}}>
        Thanks for doing all that work! You’re all set now!
      </div>
      <div>
        <button className="button btn-transparent-yellow btn-rounded">Dismiss</button>
      </div>
    </div>
  );
};
