import React from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from 'wrappers/FlexTable';
import CustomCheckBox from 'components/CustomCheckBox';

const IndividualSortResultsBy = props => {
  const {
    value,
    option,
    onChange,
  } = props;

  const handleClick = selected => {
    onChange(selected);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.currentTarget.click();
    }
  };

  const selected = option.value === value;

  return (
    <Row
      style={{
        border: '0px',
        width: '100%',
        paddingLeft: '10px',
        paddingTop: '10px',
        color: '#fff',
        fontSize: '13px',
        fontWeight: '500',

      }}
    >
      <Column
        style={{
          width: '100%',
          cursor: 'pointer',
          userSelect: 'none',
        }}
        onClick={() => handleClick(option.value)}
        onKeyPress={handleKeyPress}
      >
        <CustomCheckBox
          checked={selected}
          boxClass="radio-box"
          boxcheckedClass="radio-box-selected"
          checkmarkClass="radio-button"
        >
          <Column
            style={{
              color: '#fff',
              fontSize: '13px',
              fontWeight: '500',
              paddingLeft: '10px',
            }}
          >
            {option.name}
          </Column>
        </CustomCheckBox>
      </Column>
    </Row>
  );
};

IndividualSortResultsBy.propTypes = {
  value: PropTypes.string.isRequired,
  option: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default IndividualSortResultsBy;
