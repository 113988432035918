import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { amenitySelectAll } from 'reducers/search';
import CustomCheckBox from 'components/CustomCheckBox';
import { Row, Column } from 'wrappers/FlexTable';

class AmenitySelectAll extends PureComponent {

  render() {
    const { amenitySelectAll, allAmenitiesSelected, amenityFilter } = this.props;
    return (
      <Row
        onMouseDown={amenitySelectAll}
        className='individual-amenity'
        style={{
          cursor: 'pointer',
          border:'0px',
          display: 'block',
          paddingLeft: '0px',
          paddingBottom: '10px',
          userSelect: 'none'
        }}>
        { amenityFilter.length === 0 &&
          (allAmenitiesSelected ?
            <CustomCheckBox
              boxClass='matrix-checkbox'
              boxcheckedClass='matrix-checkbox-checked'
              checkmarkClass='checkmark'
              checked={true}
              type='checkbox'
            >
              <Column
                style={{
                  color: '#fff',
                  fontSize: '13px',
                  fontWeight:  '500',
                  paddingLeft: '10px',
                }}
              >
              Select All
              </Column>
            </CustomCheckBox> :
            <CustomCheckBox
              boxClass='matrix-checkbox'
              boxcheckedClass='matrix-checkbox-checked'
              checkmarkClass='checkmark'
              checked={false}
              type='checkbox'
            >
              <Column
                style={{
                  color: '#fff',
                  fontSize: '13px',
                  fontWeight:  '500',
                  paddingLeft: '10px',
                }}
              >
          Select All
              </Column>
            </CustomCheckBox>
          )
        }
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  allAmenitiesSelected : state.search[state.search.context].allAmenitiesSelected,
  amenityFilter: state.search[state.search.context].amenityFilter,
});

const mapDispatchToProps = (dispatch) => ({
  amenitySelectAll: () => dispatch(amenitySelectAll())
});

export default connect(mapStateToProps, mapDispatchToProps)(AmenitySelectAll);
