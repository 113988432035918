/* CROSS-COMPATIBLE REDUCER METHODS */
import axios from 'axios';
import { convertSortBy, generateFilterRegExp } from './helpers';
import { clearSearch, toggleFalse } from './initialStates';

const methods = (state, action) => {
  const {
    activeSource,
    amenities,
    amenityFilter,
    bottom_right,
    currentUIGeneratedSearchParams,
    currentUIGeneratedSearchPromise,
    hoodFilter,
    inactiveSource,
    items,
    lastAppliedSearchParams,
    lastAppliedSearchPromise,
    maxPrice,
    minPrice,
    maxPpsf,
    minPpsf,
    maxSquareFeet,
    minSquareFeet,
    moveBy,
    noFeeOnly,
    likedOnly,
    popoverRefs,
    regions,
    searchThisArea,
    selectedAmenities,
    selectedBath,
    selectedBeds,
    selectedHoods,
    selectedPet,
    selectedMarketAs,
    selectedSortResultsBy,
    selectedSubwayStops,
    selectedSubways,
    signedIn,
    subletOnly,
    subwayStopFilter,
    subwayStops,
    top_left,
    address,
    subways,
    subwayLines,
    hoods,
    selectedRegions,
    regionalHoods,
    network,
  } = state;
  const p = lastAppliedSearchParams;
  const selectedNeighborhoodIds = p.neighborhood_id_list || [];
  function getRegionalSelectedHoods(neighborhoods) {
    return neighborhoods.filter(
      neighborhood => selectedNeighborhoodIds.some(
        neighborhoodId => parseInt(neighborhoodId, 10) === parseInt(neighborhood.id, 10),
      ),
    );
  }
  function reduceRegionalSelectedHoods(callback) {
    return regions ? regions.reduce((accumulator, region) => {
      const neighborhoods = state[region.name] || [];
      const regionalSelectedHoods = getRegionalSelectedHoods(neighborhoods);
      return callback(accumulator, { region, neighborhoods, regionalSelectedHoods });
    }, []) : [];
  }
  function getSelectedHoodsFromParams() {
    return reduceRegionalSelectedHoods(
      (acc, { regionalSelectedHoods }) => acc.concat(regionalSelectedHoods),
    );
  }
  function getSelectedRegionsFromParams() {
    return reduceRegionalSelectedHoods((acc, { region, neighborhoods, regionalSelectedHoods }) => {
      const regionIsSelected = regionalSelectedHoods.length > neighborhoods.length * 2 / 3;
      if (regionIsSelected) {
        acc.push(region);
      }
      return acc;
    });
  }

  const newSource = axios.CancelToken.source();
  const apiToken = localStorage.getItem('apiToken');
  let headers;
  if (apiToken) {
    headers = { API_TOKEN: apiToken };
  }
  const grabSearchTokenFromUrl = () => window.location.href.split('=')[2];
  const grabPageFromUrl = () => parseInt(window.location.href.split('=')[1], 10) || 1;

  return {
    addPopoverRef: () => {
      const newPopoverRefs = popoverRefs.filter(ref => ref.references.popoverContainer.current);
      return { ...state, popoverRefs: [...newPopoverRefs, action.payload] };
    },
    clearSinglePopover: () => {
      const param = action.name;
      if (param === 'price') {
        return { ...state, minPrice: '', maxPrice: '' };
      }
      if (param === 'ppsf') {
        return { ...state, minPpsf: '', maxPpsf: '' };
      }
      if (param === 'squareFeet') {
        return { ...state, minSquareFeet: '', maxSquareFeet: '' };
      }
      if (param === 'selectedHoods') {
        return { ...state, selectedHoods: [], selectedRegions: [] };
      }
      if (param === 'selectedSubwayStops') {
        return { ...state, selectedSubwayStops: [], selectedSubways: [] };
      }
      if (Array.isArray(state[param])) {
        return { ...state, [param]: [] };
      }
      if (typeof state[param] === 'string') {
        return { ...state, [param]: '' };
      }
      if (param === 'more') {
        return { ...state, selectedBath: '', selectedPet: '', selectedAmenities: [], address: '', noFeeOnly: false };
      }
      return { ...state };
    },
    convertHoodSearchToSelectedValues: () => {
      if (lastAppliedSearchParams.neighborhood_id_list && hoods.length) {
        const convSelectedHoods = hoods.filter(
          hood => lastAppliedSearchParams.neighborhood_id_list.includes(+hood.id),
        );
        return { ...state, selectedHoods: convSelectedHoods };
      }
      return { ...state };
    },
    convertSavedSearchToSelectedValues: () => {
      const initialSelectedHoods = getSelectedHoodsFromParams();
      const initialSelectedRegions = getSelectedRegionsFromParams();
      let filteredSelectedBedArray;

      if (p.bed_list) {
        filteredSelectedBedArray = p.bed_list.filter((item, pos) => p.bed_list.indexOf(item) === pos);
      }

      return {
        ...state,
        selectedAmenities: p.amenity_list || [],
        selectedBath: p.min_baths ? `${p.min_baths}+` : '',
        selectedBeds: (filteredSelectedBedArray || []).map(bed => bed.toString()),
        selectedPet: p.pets || '',
        selectedMarketAs: p.marketAs || '',
        minPrice: (p.min_price || '').toString(),
        maxPrice: p.max_price === 100000 ? '' : (p.max_price || '').toString(),
        minPpsf: (p.min_ppsf || '').toString(),
        maxPpsf: p.max_ppsf === 1000 ? '' : (p.max_ppsf || '').toString(),
        minSquareFeet: (p.min_square_feet || '').toString(),
        maxSquareFeet: p.max_square_feet === 1000 ? '' : (p.max_square_feet || '').toString(),
        noFeeOnly: p.no_fee || false,
        likedOnly: p.liked || false,
        moveBy: p.move_in || '',
        selectedSubways: p.subway_id_list || [],
        selectedSortResultsBy: Object.keys(convertSortBy).find(key => convertSortBy[key] === p.order) || '',
        selectedRegions: initialSelectedRegions,
        selectedHoods: initialSelectedHoods,
        selectedSubwayStops: subwayStops && subwayStops.length > 0 && p.subway_stop_list
          ? subwayStops.filter(stop => p.subway_stop_list.some(id => stop.id === id)) : [],
        subletOnly: p.platform_listing,
        address: p.address,
      };
    },
    convertSubwayStopToSelectedValues: () => {
      if (lastAppliedSearchParams.subway_stop_list) {
        const convSelectedSubwayStops = subwayStops.filter(
          stop => lastAppliedSearchParams.subway_stop_list.some(
            id => stop.id === id,
          ),
        );
        return { ...state, selectedSubwayStops: convSelectedSubwayStops };
      }
      return { ...state };
    },
    fetchNewPage: (url) => {
      activeSource.cancel('canceled');
      const bounds = searchThisArea ? {
        top_left,
        bottom_right,
      } : {};
      return {
        ...state,
        loading: true,
        currentPage: action.page,
        lastAppliedSearchPromise: axios({
          method: 'post',
          url,
          data: { page: action.page, ...lastAppliedSearchParams, ...bounds },
          headers,
          cancelToken: newSource.token,
        }),
        activeSource: newSource,
        signedIn,
      };
    },
    generateNewSearch: url => {
      if (inactiveSource) {
        inactiveSource.cancel('canceled');
      }
      const searchParams = {
        amenity_list: selectedAmenities,
        min_baths: parseInt(selectedBath.split('')[0], 10) || 0,
        bed_list: selectedBeds.map(bed => parseInt(bed, 10) || 0),
        min_price: parseInt(minPrice, 10) || null,
        max_price: parseInt(maxPrice, 10) || null,
        min_ppsf: parseInt(minPpsf, 10) || null,
        max_ppsf: parseInt(maxPpsf, 10) || null,
        min_square_feet: parseInt(minSquareFeet, 10) || null,
        max_square_feet: parseInt(maxSquareFeet, 10) || null,
        neighborhood_id_list: selectedHoods.map(hood => hood.id),
        no_fee: noFeeOnly,
        liked: likedOnly,
        pets: selectedPet,
        market_as: selectedMarketAs,
        network,
        region_id_list: [],
        subway_id_list: selectedSubways,
        subway_stop_list: selectedSubwayStops.map(stop => stop.id),
        order: convertSortBy[selectedSortResultsBy],
        move_in: moveBy,
        platform_listing: subletOnly,
        address,
      };
      const bounds = searchThisArea ? {
        top_left,
        bottom_right,
      } : {};
      return {
        ...state,
        currentUIGeneratedSearchParams: searchParams,
        currentUIGeneratedSearchPromise: axios({
          method: 'post',
          url,
          data: { page: 1, ...searchParams, ...bounds },
          headers,
          cancelToken: newSource.token,
        }),
        inactiveSource: newSource,
        signedIn,
      };
    },
    historyInducedSearch: (ApiEndpoint, source) => {
      if (inactiveSource) {
        inactiveSource.cancel('canceled');
      }
      if (activeSource) {
        activeSource.cancel('canceled');
      }
      return {
        ...state,
        historyInduced: true,
        activeSource: source,
        lastAppliedSearchPromise: axios({
          method: 'post',
          url: ApiEndpoint,
          data: { page: grabPageFromUrl(), search_token: grabSearchTokenFromUrl(), network },
          headers,
          cancelToken: source.token,
        }),
        currentPage: grabPageFromUrl() || 1,
        loading: true,
      };
    },
    initializeSearch: (ApiEndpoint, _signedIn) => ({
      ...state,
      signedIn: _signedIn,
      initialized: true,
      firstTime: true,
      activeSource: newSource,
      lastAppliedSearchPromise: axios({
        method: 'post',
        url: ApiEndpoint,
        data: { page: grabPageFromUrl(), search_token: grabSearchTokenFromUrl(), network },
        headers,
        cancelToken: newSource.token,
      }),
      currentPage: grabPageFromUrl() || 1,
    }),
    removePopoverRef: () => {
      const newPopoverRefs = popoverRefs.filter(ref => ref !== action.payload);
      return { ...state, popoverRefs: newPopoverRefs };
    },
    resetSearch: () => ({ ...state, ...clearSearch }),
    revertSelectedHoodsToPrevious: () => {
      const newSelectedHoods = getSelectedHoodsFromParams();
      const newSelectedRegions = getSelectedRegionsFromParams();
      return { ...state, selectedHoods: newSelectedHoods, selectedRegions: newSelectedRegions };
    },
    searchThisArea: (url) => {
      if (searchThisArea) {
        activeSource.cancel('canceled');
        return {
          ...state,
          loading: true,
          currentPage: 1,
          lastAppliedSearchPromise: axios({
            method: 'post',
            url,
            data: {
              page: 1,
              ...lastAppliedSearchParams,
              top_left,
              bottom_right,
            },
            headers,
            cancelToken: newSource.token,
          }),
          activeSource: newSource,
          signedIn,
        };
      }
      return { ...state };
    },
    setSelectedItem: () => {
      let { selectedItem } = action;
      if (!selectedItem.id) {
        selectedItem = items.find(item => item.id === selectedItem);
      }
      return { ...state, selectedItem, selectedMarker: selectedItem };
    },
    setStore: () => ({ ...state, ...action.payload }),
    toggleFalse: () => ({ ...state, ...toggleFalse, aPopoverIsOpen: false }),
    togglePopover: () => {
      window.currentlyRunningAnimation = true;
      const { name } = action.payload;
      const prop = `${name}Display`;
      const next = !state[prop];
      return {
        ...state,
        ...toggleFalse,
        [prop]: next,
        aPopoverIsOpen: next,
      };
    },
    updateActiveSearch: () => {
      if (inactiveSource) {
        activeSource.cancel();
      }
      return {
        ...state,
        loading: true,
        currentPage: 1,
        lastAppliedSearchParams: currentUIGeneratedSearchParams || lastAppliedSearchParams,
        lastAppliedSearchPromise: currentUIGeneratedSearchPromise || lastAppliedSearchPromise,
        sentenceSearchDisplay: false,
        signedIn,
        activeSource: inactiveSource || activeSource,
      };
    },
    updateAmenityMatches: () => {
      if (amenityFilter.length === 0) {
        if (amenities) {
          return { ...state, amenityMatches: amenities };
        }
        return { ...state };
      }
      const amenityRegex = generateFilterRegExp(amenityFilter);
      const newAmenityMatches = amenities.filter(option => option.match(amenityRegex));
      return { ...state, amenityMatches: newAmenityMatches };
    },
    updateRegionalHoods: () => {
      if (regions.length && hoods.length) {
        const newRegionalHoods = regions.map(region => {
          return { region: region.name, neighborhoods: hoods.filter(hood => hood.region === region.name) }});
        return { ...state, regionalHoods: newRegionalHoods };
      }
      return { ...state };
    },
    updateRegionalHoodMatches: () => {
      if (hoodFilter.length === 0) {
        return { ...state, regionalHoodMatches: regionalHoods };
      }
      const hoodRegex = generateFilterRegExp(hoodFilter);
      const newHoodMatches = regionalHoods.filter(hood => {
        const regionMatches = hood.region.match(hoodRegex);
        const hoodMatches = hood.neighborhoods.some(neighborhood => neighborhood.name.match(hoodRegex));
        return regionMatches || hoodMatches;
      }).map(hood => {
        const newHoods = hood.neighborhoods.filter(neighborhood => neighborhood.name.match(hoodRegex));
        return ({ region: hood.region, neighborhoods: newHoods });
      });
      return { ...state, regionalHoodMatches: newHoodMatches };
    },
    updateHoodMatches: () => {
      if (hoodFilter.length === 0) {
        return { ...state, hoodMatches: hoods };
      }
      const hoodRegex = generateFilterRegExp(hoodFilter);
      const newHoodMatches = hoods.filter(hood => hood.name.match(hoodRegex));
      return { ...state, hoodMatches: newHoodMatches };
    },
    updateSelectedBeds: () => {
      const bed = action.payload;
      let newSelectedBeds;
      if (selectedBeds.includes(bed)) {
        newSelectedBeds = selectedBeds.filter(val => val !== bed);
      } else {
        newSelectedBeds = [...selectedBeds, bed];
      }
      return { ...state, selectedBeds: newSelectedBeds };
    },
    updateSelectedRegions: () => {
      const region = action.payload;
      const neighborhoods = hoods.filter(neighborhood => neighborhood.region === region);
      let newSelectedRegions = [];
      let newSelectedHoods = [];
      if (selectedRegions.includes(region)) {
        let hoodIds = [];
        if (neighborhoods.length) hoodIds = neighborhoods.map(hood => hood.id);
        newSelectedHoods = selectedHoods.filter(hood => !hoodIds.includes(hood.id));
        newSelectedRegions = selectedRegions.filter(selectedRegion => selectedRegion !== region);
      } else {
        const selectedHoodIds = selectedHoods.map(hood => hood.id);
        newSelectedRegions = [...selectedRegions, region];
        newSelectedHoods = [...selectedHoods, ...neighborhoods.filter(hood => !selectedHoodIds.includes(hood.id))];
      }
      return { ...state, selectedRegions: newSelectedRegions, selectedHoods: newSelectedHoods };
    },
    updateSelectedHoods: () => {
      const hoodId = action.payload.hood;
      let newSelectedHoods = [];
      if (selectedHoods.some(hood => hood.id === hoodId)) {
        newSelectedHoods = selectedHoods.filter(hood => hood.id !== hoodId);
      } else {
        newSelectedHoods = [...selectedHoods, hoods.find(
          hood => hood.id === hoodId,
        )];
      }
      return {
        ...state,
        selectedHoods: newSelectedHoods,
        hoodFilter: '',
      };
    },
    updateSelectedSubways: () => {
      const subway = action.payload;
      const stops = subwayStops.filter(stop => stop.lines.includes(subway));
      let newSelectedSubways = [];
      let newSelectedSubwayStops = [];
      if (selectedSubways.includes(subway)) {
        let stopIds = [];
        if (stops.length) stopIds = stops.map(stop => stop.id);
        newSelectedSubwayStops = selectedSubwayStops.filter(stop => !stopIds.includes(stop.id));
        newSelectedSubways = selectedSubways.filter(value => value !== subway);
      } else {
        const selectedStopIds = selectedSubwayStops.map(stop => stop.id);
        newSelectedSubways = [...selectedSubways, subway];
        newSelectedSubwayStops = [...selectedSubwayStops, ...stops.filter(stop => !selectedStopIds.includes(stop.id))];
      }
      return { ...state, selectedSubways: newSelectedSubways, selectedSubwayStops: newSelectedSubwayStops };
    },
    updateSubwayStopMatches: () => {
      if (subwayStopFilter.length === 0) {
        return { ...state, subwayStopMatches: subwayStops };
      }
      const subwayStopRegex = generateFilterRegExp(subwayStopFilter);
      const newSubwayStopMatches = subwayStops.filter(option => option.name.match(subwayStopRegex));
      return { ...state, subwayStopMatches: newSubwayStopMatches };
    },
    updateSubwayLines: () => {
      const lines = subways.map(subway => {
        const stops = subwayStops.filter(stop => stop.lines.includes(subway));
        return { line: subway, stops };
      });
      return { ...state, subwayLines: lines };
    },
    updateSubwayLineMatches: () => {
      if (subwayStopFilter.length === 0) {
        return { ...state, subwayLineMatches: subwayLines };
      }
      const subwayStopRegex = generateFilterRegExp(subwayStopFilter);
      const newSubwayLineMatches = subwayLines.filter(line => {
        const lineMatches = line.line.match(subwayStopRegex);
        const stopMatches = line.stops.some(stop => stop.name.match(subwayStopRegex));
        return lineMatches || stopMatches;
      }).map(subwayLine => {
        const newStops = subwayLine.stops.filter(stop => stop.name.match(subwayStopRegex));
        return ({ line: subwayLine.line, stops: newStops });
      });
      return { ...state, subwayLineMatches: newSubwayLineMatches };
    },
  };
};

export default methods;
