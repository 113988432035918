export function getMapBounds(map) {
  const {
    region,
  } = map;
  const {
    eastLongitude,
    northLatitude,
    southLatitude,
    westLongitude,
  } = region.toBoundingRegion();
  const topLeft = [northLatitude, westLongitude];
  const bottomRight = [southLatitude, eastLongitude];
  return { topLeft, bottomRight };
}

export function onRegionChangeEnd() {
  let firstTime = true;
  return function callback(e) {
    const map = e.target;
    if (firstTime && map.center.latitude) {
      const { _setStore } = this.props;
      firstTime = false;
      const { topLeft, bottomRight } = getMapBounds(map);
      _setStore({ top_left: topLeft, bottom_right: bottomRight });
    }
  };
}

export function onUserInteractionEnd(e) {
  const { topLeft, bottomRight } = getMapBounds(e.target);
  const { _setStore, _searchThisArea } = this.props;
  this.disableAutoCenter = true;
  _setStore({ top_left: topLeft, bottom_right: bottomRight });
  _searchThisArea();
}
