import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from '@reach/router'

// API Calls
import fetchListing, { fetchLeases, fetchRooms, fetchNearbyPointsOfInterest } from 'api/listing'
import fetchBuilding from 'api/building'
import fetchNeighborhood from 'api/neighborhood'
import { fetchCurrentAgent } from 'api/agents'

// Components
import InfoPanel from 'components/listings/detailView/InfoPanel'
import ShortcutBar from 'components/listings/detailView/ShortcutBar'
import ListingMap from 'components/listings/ListingMap'
import ListingMapGoogle from 'components/listings/ListingMapGoogle'

const DetailPage = (props) => {
  const { type, openModal } = props

  const params = useParams()
  const { id } = params

  const [selectedListing, setSelectedListing] = useState({
    listing: {},
    building: {},
    region: {},
    neighborhood: {},
    roommates: [],
    leases: [],
    currentUser: {},
    rooms: [],
    nearbyPOI: [],
  })
  const [showMatrix, setShowMatrix] = useState(false)
  const [loading, setLoading] = useState(true)
  const [pageHeight, sePageHeight] = useState(
    window.innerHeight - document.getElementById('nooklyn-navbar').offsetHeight
  )

  // Handles Resizing of Search
  useEffect(() => {
    let timeoutId = null
    const resizeListener = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(
        () => sePageHeight(window.innerHeight - document.getElementById('nooklyn-navbar').offsetHeight),
        150
      )
    }
    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  // Fetch All Listing Info Needed
  useEffect(() => {
    if (id) {
      fetchListing(id).then((listing) => {
        const roomsFetch = fetchRooms(id)

        const leaseAndUserFetch = fetchCurrentAgent().then((currentUser) => {
          const isEmployee =
            currentUser.current && currentUser.current.permissions && currentUser.current.permissions.length > 0

          if (currentUser.ok && isEmployee) {
            return fetchLeases(id).then((leases) => ({
              currentUser,
              leases,
            }))
          }

          return {
            currentUser,
            leases: [],
          }
        })

        const locationFetch = fetchBuilding(listing.buildingId).then((building) =>
          fetchNeighborhood(building.neighborhood.id).then((neighborhood) => ({
            building,
            neighborhood,
          }))
        )

        const nearbyPOIFetch = fetchNearbyPointsOfInterest(listing.latitude, listing.longitude)

        Promise.all([locationFetch, roomsFetch, leaseAndUserFetch, nearbyPOIFetch]).then(
          ([location, rooms, leaseAndUser, nearbyPOI]) => {
            setSelectedListing({
              listing,
              building: location.building,
              region: location.neighborhood.region,
              neighborhood: location.neighborhood,
              roommates: listing.interestedRoommates,
              leases: leaseAndUser.leases,
              currentUser: leaseAndUser.currentUser,
              rooms,
              nearbyPOI,
            })
            setLoading(false)
          }
        )
      })
    }
  }, [id])

  const MapComponent = process.env.MAP_PROVIDER === 'apple' ? ListingMap : ListingMapGoogle

  return (
    <div className="nklyn-search-container">
      <ShortcutBar
        selected={selectedListing}
        loading={loading}
        showMatrix={showMatrix}
        onMatrixChange={setShowMatrix}
      />
      <div className="listing-show-container">
        <div className="listings-col" id="nooklyn_listings_index_container">
          <InfoPanel
            loading={loading}
            selected={selectedListing}
            showMatrix={showMatrix}
            onMatrixChange={setShowMatrix}
            openModal={openModal}
          />
        </div>
        <MapComponent selectedListing={selectedListing.listing} markers={selectedListing.nearbyPOI} />
      </div>
    </div>
  )
}

DetailPage.propTypes = {
  type: PropTypes.string.isRequired,
}

export default DetailPage
