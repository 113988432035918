/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'

import clsx from 'clsx'

import s from './SearchInput.module.css'

const SearchInput = forwardRef(({ onChange, className, ...props }, ref) => {
  const debouncedOnChange = debounce(onChange, 500)

  const handleChange = (e) => {
    debouncedOnChange(e.target.value)
  }

  return <input ref={ref} className={clsx(s.input, className)} onChange={handleChange} type="text" {...props} />
})

SearchInput.defaultProps = {
  className: null,
}

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default SearchInput
