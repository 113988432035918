import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Column } from 'wrappers/FlexTable'
import Popover from 'components/generic/Popover'
import BedAndBath from './BedAndBath'

const Bed = (props) => {
  const { selected, onChange, loading, options } = props

  const targetRef = useRef(null)

  const hasSelected = selected.baths?.length > 0 || selected.bedrooms?.length > 0

  const className = hasSelected && !loading ? 'btn-transparent-yellow' : 'btn-transparent-gray'
  const baths = []
  const bedrooms = []

  if (!loading) {
    options?.baths?.map((option) => (selected?.baths?.includes(option.value) ? baths.push(option.name) : null))
    options?.bedrooms?.map((option) => (selected?.bedrooms?.includes(option.value) ? bedrooms.push(option.name) : null))
  }

  function getButtonText() {
    if (!hasSelected) {
      return 'Beds / Baths'
    }

    if (baths.length > 0 && bedrooms.length > 0) {
      return `Beds: ${bedrooms.join(',')}, Baths: ${baths.join(',')}`
    }

    if (baths.length > 0) {
      return `Baths: ${baths.join(',')}`
    }

    if (bedrooms.length > 0) {
      return `Beds: ${bedrooms.join(',')}`
    }

    return 'Beds / Baths'
  }

  return (
    <Column className="searchbar-col">
      <Popover type="click" targetRef={targetRef}>
        <BedAndBath selected={selected} onChange={onChange} loading={loading} options={options} />
      </Popover>
      <input
        ref={targetRef}
        name="bed"
        type="button"
        className={`button btn-rounded ${className} btn-search-bar-size`}
        value={getButtonText()}
      />
    </Column>
  )
}

Bed.defaultProps = {
  selected: [],
  onChange: () => {},
  loading: false,
}

Bed.propTypes = {
  selected: PropTypes.shape({
    bedrooms: PropTypes.arrayOf(PropTypes.string),
    baths: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  options: PropTypes.shape({
    bedrooms: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    baths: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }).isRequired,
}

export default Bed
