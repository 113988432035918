import { keysToCamel } from 'pro/util/inflector';

export const formatDate = date => {
  const [year, month, day] = date.split('-');
  return [month, day, year].join('/');
};

export const dateFlip = date => {
  const [month, day, year] = date.split('/');
  return [day, month, year].join('/');
};

// Formatting Agent to go from ruby format to react
export const formatAgent = agent => {
  const formattedAgent = keysToCamel(agent);

  return formattedAgent;
};

// Formatting Listing to go from ruby format to react
const formatListing = listing => {
  const formattedListing = keysToCamel(listing);

  formattedListing.amenities = formattedListing.amenities
    && formattedListing.amenities.split('\n');
  formattedListing.dateAvailable = formattedListing.dateAvailable
    && formatDate(formattedListing.dateAvailable);
  formattedListing.moveOutDate = formattedListing.moveOutDate
    && formatDate(formattedListing.moveOutDate);
  formattedListing.slug = formattedListing.url
    && /[^/]*$/.exec(formattedListing.url).pop();
  formattedListing.price = formattedListing.price
    && (formattedListing.price / 100);

  return formattedListing;
};

export default formatListing;
