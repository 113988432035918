import React, { useState, useEffect } from 'react';
import { useGlobalState } from 'components/listRoom/customEffects';
import { Sort as PhotoSort, Upload as PhotoUpload } from './Photos/index';

function getPhotoSteps() {
  return ['PhotoUpload', 'PhotoSort'];
}
const Photos = (props) => {
  const {
    listingId, handlePhotoSubmit, setShowSummary,
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const steps = getPhotoSteps();
  function handleNext() {
    if (activeStep < steps.length - 1) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  }

  function handleBack() {
    if (activeStep > 0) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    } else {
      setShowSummary(true);
    }
  }
  const [globalState, setGlobalState] = useGlobalState();

  function getStepContent(step) {
    switch (step) {
      case 'PhotoUpload':
        return (
          <PhotoUpload
            globalState={globalState}
            setGlobalState={setGlobalState}
            listingId={listingId}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 'PhotoSort':
        return (
          <PhotoSort
            globalState={globalState}
            setGlobalState={setGlobalState}
            listingId={listingId}
            handlePhotoSubmit={handlePhotoSubmit}
            handleBack={handleBack}
          />
        );
      default:
        return <div />;
    }
  }
  return <div>{getStepContent(steps[activeStep])}</div>;
};

export default Photos;
