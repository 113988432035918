import React, { Component } from 'react';
import { FirstCol, SecondCol, ThirdCol, TopBar } from './RoomMatrix/index';
import RoomMatrixAsync from 'asyncHandlers/RoomMatrixAsync';
import { AsyncProvider, AsyncSubscribe } from 'asyncHandlers/AsyncBase';

export default class RoomMatrix extends Component {

  state = {
    handler: new RoomMatrixAsync(this.props.id)
  }
  render() {
    const { handler } = this.state;
    return (
      <AsyncProvider handler={handler}>
        <Consumer changeField={this.changeField} />
      </AsyncProvider>
    );
  }
}

const subscriptions = ['room'];
class Subscriber extends Component {

  state = {
    room: null,
    approvedOrRejected: false,
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.room !== this.props.room) {
      this.setState({ room: nextProps.room });
      return false;
    }
    return true;
  }

  approveOrReject = () => {
    this.setState({
      approvedOrRejected: true,
    });
  }

  changeField = (val, attr) => {
    this.setState(({ room }) => ({
      room: { ...room, [attr]: val },
    }));
  };
  render() {
    const { room, approvedOrRejected } = this.state;
    const { changeField } = this;
    return (
      room && <div
        style={{
          display: approvedOrRejected ? 'none' : 'block',
        }}
        className="container new-matrix-card"
        id="listing_room_1534">
        <TopBar {...room} changeField={changeField} approveOrReject={this.approveOrReject} />
        <div className="row listing-admin-area-content-section">
          <FirstCol { ...room } />
          <SecondCol changeField={changeField} { ...room } />
          <ThirdCol { ...room } />
        </div>
      </div> || null
    );
  }
}
const Consumer = AsyncSubscribe(subscriptions)(Subscriber);
