import countryData from '../lib/country-region-data.json';

export function getMetaContent(name) {
  const metas = document.getElementsByTagName('meta');
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') == name) {
      return metas[i].getAttribute('content');
    }
  }
  return '';
}

export function validateSignupFields(
  email,
  password,
  password_confirmation,
  first_name,
  last_name,
) {
  let error = {};
  if (!email) error = { warning: 'Email cannot be empty', errorType: 'email' };
  else if (password.length < 6) {
    error = {
      warning: 'Password must be at least 6 characters',
      errorType: 'password',
    };
  } else if (password !== password_confirmation) { error = { warning: 'Passwords do not match!', errorType: 'password' }; } else if (!first_name || !last_name) { error = { warning: 'Name cannot be empty', errorType: 'name' }; }
  return error;
}

export function redirectApplyPath(currentUrl, listingId) {
  const [domain, route, params] = currentUrl.split(/listings|\?/);
  // in the case there is a sticky agent
  if (params && params.length && params.includes('=')) {
    return `${domain}listings/${listingId}/apply?${params}`;
  }
  return `${domain}listings/${listingId}/apply`;
}

export function getMatePostSearchToken(currentUrl) {
  if (currentUrl.includes('mate_posts?q=')) {
    const token = currentUrl.split('mate_posts?q=').pop();
    if (token.slice(-3) === '%3D') return token.slice(0, -3);
    return token;
  }
}

export const truncate = (
  text,
  length = 30,
  separator = null,
  omission = '...',
) => {
  let content = '';
  if (text) {
    if (text.length <= length) {
      return text;
    }

    content = text.substring(0, length);
    if (separator && content.lastIndexOf(separator)) {
      content = content.substring(0, content.lastIndexOf(separator));
    }
  }
  content = content.substring(0, content.length - 3);
  return (content += omission);
};

function cleanRegionData(inputArray) {
  inputArray.map(country => {
    country.text = country.countryName;
    country.key = country.countryShortCode;
    country.value = country.countryShortCode;
    delete country.countryName;
    delete country.countryShortCode;
    if (country.regions) {
      country.regions.map(region => {
        region.key = region.shortCode;
        region.value = region.shortCode;
        if (country.key == 'US') {
          region.text = `${region.shortCode} - ${region.name}`;
        } else {
          region.text = region.name;
        }
        delete region.name;
        delete region.shortCode;
      });
    }
  });
  return inputArray;
}
export const cleanCountryData = cleanRegionData(countryData);

export function loadRegions(inputArray, country) {
  return inputArray.find(c => c.key === country).regions;
}

export function parseForSubtitle(body) {
  // '#', '##', '###', '####', '#####', '######'
  const headerArray = body.split('\r\n').filter(str => str.includes('#'));
  return headerArray
    .map(a => a.replace(/^#+/g, '')) // trim leading #
    .map(a => a.replace(/^\s+|\s+$/g, '')); // trim leading and trailing whitespace
}

export function calMoveInCost(monthly, moveInCost) {
  if (moveInCost === true) return monthly * 3;
  if (moveInCost === false) return monthly * 2;
  return 'N/A';
}

export function moveInTerm(moveInCost) {
  if (moveInCost === true) return 'First, Last and Security';
  if (moveInCost === false) return 'First and Security';
  return 'N/A';
}

export function getTrueDate(date, type = 'dayFirst') {
  if (!date) {
    return null;
  }
  const splitDate = date.match(/(\d+)/g);

  if (type === 'yearFirst') {
    splitDate.unshift(splitDate[2]);
    splitDate.splice(3, 1);
  }

  const [year, month, day, hour = 0, second = 0, millisecond = 0] = splitDate;
  return new Date(year, parseInt(month) - 1, day, hour, second, millisecond);
}

export function convertSnakeObjectToCamel(snakeObject) {
  if (!snakeObject) {
    return snakeObject;
  } if (Array.isArray(snakeObject)) {
    return snakeObject.map(o => convertSnakeObjectToCamel(o));
  } if (typeof snakeObject === 'object') {
    const result = {};
    for (const prop in snakeObject) {
      const val = snakeObject[prop];
      const newProp = convertSnakeToCamel(prop);
      result[newProp] = convertSnakeObjectToCamel(val);
    }
    return result;
  }
  return snakeObject;
}

function convertSnakeToCamel(string) {
  return string.replace(/(\_\w)/g, text => text[1].toUpperCase());
}

export function present(value) {
  return !!value || value === 0 || value === '';
}

export function importAll(r) {
  return r.keys().reduce((acc, key) => {
    acc[key] = r(key);
    return acc;
  }, {});
}

export function isCurrency(value) {
  if (!value && value !== 0) return false;
  return !!value.toString().match(/^[0-9]+(\.[0-9]{1,2})?$/);
}

export function stripNumericCharacters(string) {
  if (typeof string !== 'string') return '';
  return string.replace(/\D/g, '');
}

export function splitArrayAtIndex(arr, index) {
  let firstHalf = arr;
  if (arr.length > index) {
    firstHalf = arr.splice(0, index);
    return [firstHalf, arr];
  }
  return [arr, null];
}
export const checkEmail = email => {
  // Regex for valid email address
  // Based off of RFC 2822
  // https://stackoverflow.com/a/13178771
  const emailRegex = new RegExp(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i);

  return emailRegex.test(email);
};

export const getParamsFromLink = url => {
  let query = '';

  if (url !== undefined) {
    if (url.includes('?')) {
      const filteredUrl = url.split('?');
      query = filteredUrl[1];
    }
  } else {
    query = window.location.search.substring(1);
  }

  let filteredParams = {};

  if (query !== '') {
    filteredParams = (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        const [key, value] = param.split('=');
        const urlParams = params;
        urlParams[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return urlParams;
      }, {});
  }

  return filteredParams;
};
