import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isCurrency } from 'helpers/util';
import Modal from './Modal';

class ModalContent extends Component {
  state = {
    error: '',
    refund_amount: '',
    refund_reason: '',
    apiError: '',
    submitting: false,
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    this.setState({ submitting: true });
    const notes = evt.target.notes.value;

    const { lease_id, client_id } = this.props;
    const { refund_amount, refund_reason } = this.state;

    axios
      .post('/api/v2/leases.request_refund', {
        lease_id,
        client_id,
        refund_amount,
        refund_reason,
        notes,
      }, {
        headers: {
          API_TOKEN: localStorage.getItem('apiToken'),
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        this.setState({ submitting: false });
        if (err.response && err.response.data) {
          this.setState({ apiError: err.response.data.error.friendlyMessage });
        }
      });
  };

  handleAmountChange = (evt) => {
    const { value } = evt.target;
    const cleanedInput = value.replace(/[^\d\.\-]/g, '');
    if (Number.isNaN(cleanedInput) || cleanedInput === 0 || !isCurrency(cleanedInput)) {
      this.setState({
        error: 'amount must be a valid currency amount',
        refund_amount: cleanedInput,
      });
    } else {
      this.setState({
        error: '',
        refund_amount: cleanedInput,
      });
    }
  };

  handleReasonChange = (evt) => {
    evt.preventDefault();
    this.setState({ refund_reason: evt.target.value });
  };

  render() {
    const { client_name } = this.props;
    const {
      error,
      refund_amount,
      refund_reason,
      apiError,
      submitting,
    } = this.state;

    return (
      <div className="container invite-container">
        <h1>
Lease Refund for
          {' '}
          {client_name}
        </h1>
        <hr className="entire-width" />
        <form onSubmit={this.handleSubmit}>
          {apiError && <p className="error text-center">{apiError}</p>}
          <label>Refund Amount</label>
          <input
            placeholder="2000"
            className="general-text-field mb-3"
            type="text"
            value={refund_amount}
            onChange={this.handleAmountChange}
            required
          />
          {error && <p className="error">{String(error)}</p>}
          <label>Refund Reason</label>
          <select
            className="custom-select mb-3"
            onChange={this.handleReasonChange}
            value={refund_reason}
            required
          >
            <option value="">Select a reason</option>
            <option value="client_backed_out">Client Backed Out</option>
            <option value="application_rejected">Application Rejected</option>
            <option value="overpayment">Overpayment</option>
            <option value="accepted_another_file">Accepted Another File</option>
            <option value="other">Other</option>
          </select>
          <label>Notes</label>
          <textarea
            placeholder="Notes"
            className="general-text-area limit_500"
            type="text"
            rows="6"
            name="notes"
            required={refund_reason === 'Other'}
          />
          <input
            type="submit"
            name="commit"
            value="Submit"
            className="button btn-black-white btn-rounded mt-3"
            disabled={submitting}
          />
        </form>
      </div>
    );
  }
}

ModalContent.propTypes = {
  lease_id: PropTypes.string.isRequired,
  client_id: PropTypes.number.isRequired,
  client_name: PropTypes.string.isRequired,
};

class RefundModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleModalCloseRequest = () => {
    this.setState({ modalIsOpen: false });
    window.location.reload();
  };

  render() {
    const { className } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <div className={className}>
        <div onClick={this.openModal}><a className="dropdown-item">Refund</a></div>
        <Modal
          className="Modal__Bootstrap modal-dialog dark-gray-container invite-modal"
          closeTimeoutMS={150}
          isOpen={modalIsOpen}
        >
          <div className="modal-content modal-content-dark">
            <button type="button" className="close" onClick={this.closeModal}>
              <i className="nookons-close-circle-fill" />
            </button>
            <ModalContent
              {...this.props}
              handleModalCloseRequest={this.handleModalCloseRequest}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default RefundModal;
