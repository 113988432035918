import React from 'react';
import PropTypes from 'prop-types';

const BuildingCard = props => {
  const { building } = props;

  const {
    activeListingCount,
    description,
    name,
    imagePresent,
    image,
    slug,
    yearBuilt,
  } = building;

  const url = `https://${window.location.hostname}/buildings/${slug}`;

  return (
    <>
      {imagePresent
      && (
        <>
          <hr />
          <div className="neighborhood-jumbotron">
            <h5 className="neighborhood-title">the Building</h5>
            <div className="neighborhood-img" style={{ backgroundImage: `url(${image.large})` }} />
            <h3 className="neighborhood-text">
              {name}
            </h3>
            {description && (
              <p>
                { description }
              </p>
            )}
            <div className="neighborhood-info">
              {yearBuilt !== -1 && `Built in ${yearBuilt}  •  `}
              {`${activeListingCount} apartments `}
            </div>
            <a className="button btn-rounded btn-transparent neighborhood-button" href={url}>
              See the rest of the building
              <i className="nookons-chevron-right pl-3" />
            </a>
          </div>
        </>
      )}
    </>
  );
};

BuildingCard.defaultProps = {
  building: {
    yearBuilt: -1,
    description: '',
  },
};

BuildingCard.propTypes = {
  building: PropTypes.shape({
    activeListingCount: PropTypes.number.isRequired,
    description: PropTypes.string,
    name: PropTypes.string.isRequired,
    imagePresent: PropTypes.bool.isRequired,
    image: PropTypes.shape({ large: PropTypes.string.isRequired }).isRequired,
    slug: PropTypes.string.isRequired,
    yearBuilt: PropTypes.number,
  }),
};

export default BuildingCard;
