import React from 'react';
import PropTypes from 'prop-types';
import LikeButton from './buttons/LikeButton';
import ShareButton from './buttons/ShareButton';
import TourButton from './buttons/TourButton';

const LikeTourShareRow = props => {
  const { signedIn, room } = props;

  const {
    id,
    meta,
    sales_agent: salesAgent,
    url,
  } = room;

  return (
    <>
      { id
      && (
        <div className="listing-card-stub container-fluid card-bottom-row-border">
          <div style={{ padding: '0px' }} className="row top-row bottom-row">
            <LikeButton id={id} type="room" liked={meta.liked} signedIn={signedIn} />
            <ShareButton url={url} type="col" />
            <TourButton propertyId={id} salesAgent={salesAgent} signedIn={signedIn} />
          </div>
        </div>
      )}
    </>

  );
};

LikeTourShareRow.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number,
    meta: PropTypes.shape({
      liked: PropTypes.bool,
    }),
    sales_agent: PropTypes.shape({
      id: PropTypes.number,
    }),
    url: PropTypes.string,
  }),
  signedIn: PropTypes.bool,
};

LikeTourShareRow.defaultProps = {
  room: {},
  signedIn: false,
};

export default LikeTourShareRow;
