import React from 'react';
import PropTypes from 'prop-types';
import { Column, Row } from 'wrappers/FlexTable';
import CustomCheckBox from 'components/CustomCheckBox';
import LoadingSpinner from 'components/LoadingSpinner';

const MarketAsPopover = props => {
  const {
    loading,
    value,
    onChange,
    options,
  } = props;

  const handleClick = selected => {
    onChange(selected);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.currentTarget.click();
    }
  };

  return (
    <div
      className="market-as-pop"
    >
      <Column
        role="button"
        tabIndex={0}
        style={{
          width: '320px',
          background: '#000',
          border: '1px solid #404040',
          borderRadius: '21px',
          padding: '15px',
        }}
      >
        {(options.length && !loading)
          ? options.map(
            option => (
              <Row
                key={`marketAs-${option.value}`}
                style={{
                  border: '0px',
                  width: '100%',
                  paddingLeft: '10px',
                  paddingTop: '10px',
                  color: '#fff',
                  fontSize: '13px',
                  fontWeight: '500',
                }}
              >
                <Column
                  onClick={() => handleClick(option.value)}
                  onKeyPress={handleKeyPress}
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                >
                  <CustomCheckBox
                    checked={value === option.value}
                    boxClass="radio-box"
                    boxcheckedClass="radio-box-selected"
                    checkmarkClass="radio-button"
                  >
                    <Column
                      style={{
                        color: '#fff',
                        fontSize: '13px',
                        fontWeight: '500',
                        paddingLeft: '10px',
                      }}
                    >
                      {option.name}
                    </Column>
                  </CustomCheckBox>
                </Column>
              </Row>
            ),
          )
          : <LoadingSpinner />}
      </Column>
    </div>
  );
};

MarketAsPopover.propTypes = {
  loading: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

export default MarketAsPopover;
