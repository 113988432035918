import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchListingLikes, dispatchLike, dispatchUnlike } from '../reducers/likedListings'
import { fetchListingLikesTrue } from '../reducers/fetchStatus'
import { fetchCurrentUserTrue, fetchCurrentUser } from '../reducers/auth'
import AuthModal from '../components/modal/AuthModal'
import { HeartListing } from '../components/HeartListing'

function gtagReportConversion(url) {
  var callback = function () {
    if (typeof url != 'undefined') {
      window.location = url
    }
  }
  // eslint-disable-next-line no-undef
  gtag('event', 'likelisting', {
    send_to: 'AW-968054781/WSr4CPT3orwYEP2vzc0D',
    event_callback: callback,
  })
  return false
}

class ListingLikeButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listingId: props.id,
      buttonStatus: 'unlike-button',
    }
    this.toggleLike = this.toggleLike.bind(this)
  }

  componentDidMount() {
    if (!this.props.currentUserFetched) {
      this.props.fetchCurrentUser()
      this.props.fetchCurrentUserTrue()
    }
    if (this.props.likedListings.includes(this.state.listingId)) {
      this.setState({ buttonStatus: 'like-button' })
    }
    if (!this.props.fetchStatus.likedListingsFetch) {
      this.props.fetchListingLikes()
      this.props.fetchListingLikesTrue()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.likedListings.includes(this.state.listingId)) {
      this.setState({ buttonStatus: 'like-button' })
    }
  }

  toggleLike(e) {
    e.stopPropagation()
    const listingId = this.state.listingId
    e.preventDefault()
    if (this.state.buttonStatus === 'unlike-button') {
      this.setState({ buttonStatus: 'like-button' })
      this.props.dispatchLike(listingId)
      gtagReportConversion()
    } else if (this.state.buttonStatus === 'like-button') {
      this.setState({ buttonStatus: 'unlike-button' })
      this.props.dispatchUnlike(listingId)
    }
  }

  render() {
    return (
      <div>
        {this.props.signed_in ? (
          <HeartListing handleClick={this.toggleLike} buttonStatus={this.state.buttonStatus} />
        ) : (
          <AuthModal>
            <HeartListing buttonStatus="unlike-button" />
          </AuthModal>
        )}
      </div>
    )
  }
}

const mapState = (state) => ({
  likedListings: state.likedListings || [],
  fetchStatus: state.fetchStatus || [],
  currentUserFetched: state.auth.currentUserFetched,
  signed_in: state.auth.signed_in,
})

const mapDispatch = (dispatch) => ({
  fetchListingLikes() {
    dispatch(fetchListingLikes())
  },
  dispatchLike(listingId) {
    dispatch(dispatchLike(listingId))
  },
  dispatchUnlike(listingId) {
    dispatch(dispatchUnlike(listingId))
  },
  fetchListingLikesTrue() {
    dispatch(fetchListingLikesTrue())
  },
  fetchCurrentUserTrue() {
    dispatch(fetchCurrentUserTrue())
  },
  fetchCurrentUser() {
    dispatch(fetchCurrentUser())
  },
})

export default connect(mapState, mapDispatch)(ListingLikeButton)
