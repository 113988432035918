import reducerCreator from './reducer';
import { createInitialState } from './initialStates';

export * from './actions';
/* State batches */

const initialRootState = {
  commercials: createInitialState('/api/v2/commercials.search'),
  sales: createInitialState('/api/v2/sales.search'),
  listings: createInitialState('/api/v2/listings.search'),
  rooms: createInitialState('/api/v2/rooms.search'),
  roommates: createInitialState('/api/v2/roommates.search'),
};

export default function rootReducer(rootState = initialRootState, action) {
  switch (action.type) {
    case 'SET_REDUCER':
      return {
        ...rootState, context: action.context,
      };
    default:
      if (rootState.context === 'listings') {
        return {
          ...rootState,
          listings: listingsReducer(rootState.listings, action),
        };
      } else if (rootState.context === 'rooms') {
        return {
          ...rootState,
          rooms: roomsReducer(rootState.rooms, action)
        };
      } else if (rootState.context === 'roommates') {
        return {
          ...rootState,
          roommates: roommatesReducer(rootState.roommates, action)
        };
      } else if (rootState.context === 'commercials') {
        return {
          ...rootState,
          commercials: commercialsReducer(rootState.commercials, action)
        };
      } else if (rootState.context === 'sales') {
        return {
          ...rootState,
          sales: salesReducer(rootState.sales, action)
        };
      } else {
        return {
          ...rootState,
          commercials: commercialsReducer(rootState.commercials, action),
          sales: salesReducer(rootState.sales, action),
          listings: listingsReducer(rootState.listings, action),
          rooms: roomsReducer(rootState.rooms, action)
        };
      }
  }
}

const listingsReducer = reducerCreator('/api/v2/listings.search');
const roomsReducer = reducerCreator('/api/v2/rooms.search');
const roommatesReducer = reducerCreator('/api/v2/roommates.search');
const commercialsReducer = reducerCreator('/api/v2/commercials.search');
const salesReducer = reducerCreator('/api/v2/sales.search');

/* search convert */
