import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';
import Popover from 'components/generic/Popover';
import SubwayStopsPopover from './SubwayStopsPopover';

const SubwayStops = props => {
  const {
    selected,
    onChange,
    loading,
    options,
  } = props;

  const targetRef = useRef(null);
  const [value, setValue] = useState(selected);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  const buttonTxt = (Array.isArray(value.subways) && value.subways.length && !loading) ? `Subway: (${value.subways.length})` : 'Subway';
  const className = (
    ((Array.isArray(value.subways) && value.subways.length)
      || (Array.isArray(value.subwayStops) && value.subwayStops.length))
    && !loading
  ) ? 'btn-transparent-yellow' : 'btn-transparent-gray';

  return (
    <Column className="searchbar-col">
      <Popover type="click" targetRef={targetRef}>
        <SubwayStopsPopover value={value} onChange={onChange} loading={loading} options={options} />
      </Popover>
      <input
        ref={targetRef}
        name="subwayStops"
        type="button"
        className={`button btn-rounded ${className} btn-search-bar-size`}
        value={buttonTxt}
      />
    </Column>
  );
};

SubwayStops.defaultProps = {
  selected: {
    subway: [],
    subwayStops: [],
  },
  onChange: () => {},
  loading: false,
};

SubwayStops.propTypes = {
  selected: PropTypes.shape({
    subways: PropTypes.arrayOf(PropTypes.string),
    subwayStops: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  options: PropTypes.shape({
    subwayLines: PropTypes.arrayOf(PropTypes.shape({})),
    subwayStops: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default SubwayStops;
