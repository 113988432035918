/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import s from './Radio.module.css'

function Radio({ value, onChange, options, isRadio }) {
  const handleClick = (selected) => {
    const tempValue = value ? [...value] : []

    if (isRadio) {
      onChange([selected])
      return
    }

    if (tempValue.includes(selected)) {
      tempValue.splice(tempValue.indexOf(selected), 1)
      onChange(tempValue.sort())
    } else {
      tempValue.push(selected)
      onChange(tempValue.sort())
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.currentTarget.click()
    }
  }

  return (
    <div className={s.root}>
      {options.map((option) => (
        <div
          role="button"
          tabIndex={0}
          key={option.value}
          className={clsx(s.option, value?.includes(option.value) && s.optionSelected)}
          onClick={() => handleClick(option.value)}
          onKeyDown={handleKeyDown}
        >
          {option.name}
        </div>
      ))}
    </div>
  )
}

Radio.defaultProps = {
  isRadio: false,
}

Radio.propTypes = {
  isRadio: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
}

export default Radio
