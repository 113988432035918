import React from 'react';
import PropTypes from 'prop-types';

const LoadingSpinner = props => {
  const { className, style } = props;

  return (
    <div style={style} className={`load-more loading-spinner ${className}`}>
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  );
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

// Offset default values pulled from responsive css spacing done in nooklyn homepage
LoadingSpinner.defaultProps = {
  className: '',
  style: {},
};

export default LoadingSpinner;
