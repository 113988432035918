import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Column, Row } from 'wrappers/FlexTable';
import Fade from 'wrappers/Fade';
import { connect } from 'react-redux';
import { MinPrice, MaxPrice } from './priceComponents/index';
import ApplyClear from './ApplyClear';

const generatePriceArray = (max, min, interval) => {
  const arrayLength = Math.round((max - min) / interval) + 1;
  return Array.from(Array(arrayLength), (_, i) => min + i * interval);
};

const PricePop = forwardRef((props, { popover, popoverWrapper }) => {
  const onMouseDown = (e) => {
    e.stopPropagation();
  };

  const { display, searchContext, context } = props;
  const listingChoices = generatePriceArray(5000, 1000, 500);
  const commercialChoices = generatePriceArray(7000, 2000, 500);
  const roomChoices = generatePriceArray(1500, 700, 200);
  const salesChoices = generatePriceArray(1500000, 500000, 100000);

  let choices = [];
  if (context === 'listings') {
    choices = listingChoices;
  } else if (context === 'rooms' || context === 'roommates') {
    choices = roomChoices;
  } else if (context === 'commercials') {
    choices = commercialChoices;
  } else if (context === 'sales') {
    choices = salesChoices;
  }

  return (
    <div
      className={display ? 'popover fee-pop' : 'popover fee-pop hide'}
      style={{ position: 'fixed', border: 'none' }}
      ref={popoverWrapper}
    >
      <Fade
        fadeStart="expand-start"
        fadeEnd="expand-end"
        visible={display}
        customStyles={{
          position: 'relative',
          top: '2px',
          right: '180px',
          backgroundColor: 'rgb(17,17,17)',
          flexWrap: 'wrap',
        }}
      >
        <Column
          ref={popover}
          style={{
            position: 'absolute',
            background: '#000',
            border: '1px solid #404040',
            borderRadius: '21px',
          }}
          role="button"
          tabIndex={0}
          onMouseDown={onMouseDown}
          className="price popover-body"
        >
          <Row style={{ borderRight: '0px' }}>
            <MinPrice choices={choices} />
            <span className="text-white d-flex align-items-center"> - </span>
            <MaxPrice choices={choices} />
          </Row>
          {searchContext === 'searchbar' && <ApplyClear style={{ top: '20px', border: 'none' }} name="price" />}
        </Column>
      </Fade>
    </div>
  );
});

const mapStateToProps = (state, ownProps) => {
  const display = state.search[state.search.context][ownProps.displayProp];
  return ({
    context: state.search.context,
    display: display || false,
  });
};

PricePop.propTypes = {
  display: PropTypes.bool.isRequired,
  searchContext: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps, null, null, { forwardRef: true },
)(PricePop);
