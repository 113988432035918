import React from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from 'components/LoadingSpinner'
import SubwayStopList from './SubwayStopList'
import SubwaysList from './SubwaysList'

const SubwayStopsPopover = (props) => {
  const { loading, value, onChange, options } = props

  const handleChange = (selected) => onChange({ ...selected })

  return (
    <div
      style={{
        border: '1px solid rgb(64,64,64)',
        backgroundColor: '#111',
        color: '#fff',
        borderRadius: '0.3em',
        fontSize: '12px',
        width: '350px',
        maxHeight: '700px !important',
        overflow: 'hidden',
      }}
    >
      <div className="container-fluid">
        <div className="tab-content">
          <br />
          {options.subwayStops && !loading ? (
            <SubwaysList
              stops={options.subwayStops}
              onChange={(newValue) => {
                handleChange(newValue)
              }}
              selectedStops={value.subwayStops}
            />
          ) : (
            <>
              <LoadingSpinner />
              <br />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

SubwayStopsPopover.propTypes = {
  loading: PropTypes.bool.isRequired,
  value: PropTypes.shape({
    subways: PropTypes.arrayOf(PropTypes.string),
    subwayStops: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    subwayLines: PropTypes.arrayOf(PropTypes.shape({})),
    subwayStops: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default SubwayStopsPopover
