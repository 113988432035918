import React from 'react';
import PropTypes from 'prop-types';
import { getTrueDate, present } from 'helpers/util';
import ChangeStatus from './ChangeStatus';

const strftime = require('strftime');

const ThirdCol = (props) => {
  const {
    listingId,
    bedrooms,
    bathrooms,
    ownerPays,
    access,
    utilities,
    agentNotes,
    createdAt,
    updatedAt,
    superAdmin,
    status,
  } = props;
  return (
    <div className="col-md-4 listing-second-data-col">
      <div className="row listing-admin-area-data-row">
        <div className="col-sm-4 text-left">
          <b>Listing ID</b>
        </div>
        <div className="col-sm-8 text-left">
          <a href={`/listings/${listingId}`}>{listingId}</a>
        </div>
      </div>
      <div className="row listing-admin-area-data-row">
        <div className="col-sm-4 text-left">
          <b>Bed/Bath</b>
        </div>
        <div className="col-sm-8 text-left">
          {bedrooms}
          &nbsp;Bed /&nbsp;
          {bathrooms}
          &nbsp;Bath
        </div>
      </div>
      <div className="row listing-admin-area-data-row">
        <div className="col-sm-4 text-left">
          <b>OP</b>
        </div>
        <div className="col-sm-8 text-left">
          {present(ownerPays) && `${ownerPays}%`}
        </div>
      </div>
      <div className="row listing-admin-area-data-row">
        <div className="col-sm-4 text-left">
          <b>Access</b>
        </div>
        <div className="col-sm-8 text-left">
          {access}
        </div>
      </div>
      <div className="row listing-admin-area-data-row">
        <div className="col-sm-4 text-left">
          <b>Utilities</b>
        </div>
        <div className="col-sm-8 text-left">
          {utilities}
        </div>
      </div>
      <div className="row listing-admin-area-data-row">
        <div className="col-sm-4 text-left">
          <b>Notes</b>
        </div>
        <div className="col-sm-8 text-left">
          {agentNotes}
        </div>
      </div>
      {createdAt && (
        <div className="row listing-admin-area-data-row">
          <div className="col-sm-4 text-left">
            <b>Created at</b>
          </div>
          <div className="col-sm-8 text-left">
            {strftime(
              '%B %e, %Y at %I:%M%P',
              getTrueDate(createdAt),
            )}
          </div>
        </div>
      )}
      {updatedAt && (
        <div className="row listing-admin-area-data-row">
          <div className="col-sm-4 text-left">
            <b>Updated at</b>
          </div>
          <div className="col-sm-8 text-left">
            {strftime(
              '%B %e, %Y at %I:%M%P',
              getTrueDate(updatedAt),
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ThirdCol.defaultProps = {
  ownerPays: null,
  access: null,
  agentNotes: null,
  createdAt: null,
  updatedAt: null,
};

ThirdCol.propTypes = {
  listingId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  bedrooms: PropTypes.number.isRequired,
  bathrooms: PropTypes.number.isRequired,
  ownerPays: PropTypes.number,
  access: PropTypes.string,
  utilities: PropTypes.string.isRequired,
  agentNotes: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  status: PropTypes.string.isRequired,
  superAdmin: PropTypes.bool.isRequired,
};

export default ThirdCol;
