import React from 'react';
import { createReducer, stateContext } from '../components/listRoom/customEffects';
import ProgressStepper from '../components/listRoom/ProgressStepper';
import ErrorBoundary from '../components/listRoom/ErrorBoundary';

const ListARoom = props => (
  <ErrorBoundary>
    <stateContext.Provider value={createReducer({ images: [], rooms: [] })}>
      <ProgressStepper {...props} />
    </stateContext.Provider>
  </ErrorBoundary>
);

export default ListARoom;
