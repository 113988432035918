import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import missing from 'images/logo/white-on-black-square.png'
import SubwayRow from 'components/listings/cards/components/SubwayRow'

import s from './Annotation.module.css'

function Annotation(props) {
  const { marker, isMulti } = props

  let subtitle
  if (marker.short_address) {
    if (!marker.short_address.includes('Room')) {
      subtitle = marker.short_address
    } else if (!marker.platform_listing) {
      subtitle = marker.short_address
    } else {
      subtitle = marker.neighborhood?.name
    }
  } else {
    subtitle = marker.neighborhood?.name
  }

  return (
    <a href={marker.url} rel="noreferrer" target="_blank">
      <div className={clsx(s.root, isMulti && s.multi)}>
        <div className={s.content}>
          <div className={s.imageWrapper}>
            <img
              src={marker.image?.medium}
              className={s.image}
              alt={subtitle}
              onError={(e) => {
                e.target.onerror = null
                e.target.src = missing
              }}
            />
          </div>
          <div className={s.desc}>
            <div className={s.descPrice}>
              {window.accounting.formatMoney(marker.price / 100, { precision: 0 })}/month
            </div>
            <div className={s.descBedBath}>
              <div className={s.descBed}>{marker.bedrooms} Bed</div>
              <div className={s.descBath}>{marker.bathrooms} Bath</div>
            </div>

            <div className={s.subway}>
              <SubwayRow subwayLines={marker.subway_line} />
            </div>
          </div>
        </div>

        <div className={s.address}>{subtitle}</div>
      </div>
    </a>
  )
}

Annotation.defaultProps = {
  isMulti: false,
  marker: null,
}

Annotation.propTypes = {
  isMulti: PropTypes.bool,
  marker: PropTypes.shape({
    url: PropTypes.string,
    image: PropTypes.shape({
      medium: PropTypes.string,
    }),
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    price: PropTypes.number,
    short_address: PropTypes.string,
    neighborhood: PropTypes.shape({
      name: PropTypes.string,
    }),
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    platform_listing: PropTypes.bool,
    id: PropTypes.number,
    subway_line: PropTypes.arrayOf(PropTypes.string),
  }),
}

export default Annotation
