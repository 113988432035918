import React from 'react'
import PropTypes from 'prop-types'
import { Column, Row } from 'wrappers/FlexTable'
import CustomInput from 'components/CustomInput'

import s from './Price.module.css'

function Price(props) {
  const { value, onChange, warningText } = props

  return (
    <>
      <Row style={{ borderRight: '0px', marginBottom: '10px' }}>
        <Column style={{ width: '50%' }}>
          <CustomInput
            className={s.input}
            placeholder="Min"
            value={value[0]}
            beforeValue="$"
            onChange={(newMin) => onChange([newMin, value[1]])}
          />
        </Column>
        <span className="text-white d-flex align-items-center"> - </span>
        <Column style={{ width: '50%', alignItems: 'flex-end' }}>
          <CustomInput
            className={s.input}
            placeholder="Max"
            value={value[1]}
            beforeValue="$"
            onChange={(newMax) => onChange([value[0], newMax])}
          />
        </Column>
      </Row>

      {warningText && <div className={s.warningText}>{warningText}</div>}
    </>
  )
}

Price.defaultProps = {
  value: ['', ''],
}

Price.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  targetRef: PropTypes.shape({
    current: PropTypes.shape({
      click: PropTypes.func,
    }),
  }).isRequired,
  warningText: PropTypes.string.isRequired,
}

export default Price
