import React, { useEffect } from 'react';
import { ButtonSave, ButtonStepper } from '../Buttons';
import ContainerWrapper from '../ContainerWrapper';

const title = "What is the apartment's monthly rent?";

const Rent = (props) => {
  const {
    price, setPrice, userIsEditing, setUserIsEditing,
  } = props;

  const changePrice = (value) => {
    setPrice(value * 100);
  }

  useEffect(() => {
    document.title = `Create: Apartments - ${title}`;
  }, []);

  return (
    <ContainerWrapper>
      <div>
        <div className="title">
          <h3>{title}</h3>
          <p>
            We&apos;d like to know the full apartment&apos;s rent even if you are listing a room.
          </p>
        </div>
        <form
          className="narrow"
          onKeyDown={(evt) => {
            if (evt.which === 13) evt.preventDefault();
          }}
        >
          <div className="row">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text general-text-field-dark">$</span>
              </div>
              <input
                type="number"
                className="form-control general-text-field-dark"
                value={price / 100}
                onChange={evt => changePrice(evt.target.value)}
              />
            </div>
          </div>
          {userIsEditing ? (
            <ButtonSave
              handleJump={props.handleJump}
              setUserIsEditing={setUserIsEditing}
              jumpTo={props.jumpTo || 'PricingReview'}
            />
          ) : (
            <ButtonStepper
              handleNext={props.handleNext}
              handleBack={props.handleBack}
              disabled={!price}
            />
          )}
        </form>
      </div>
    </ContainerWrapper>
  );
};

export default Rent;
