import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from 'components/LoadingSpinner';
import PhotoButtons from './PhotoButtons';

const Previews = (props) => {
  const {
    error,
    previews,
    images,
    onError,
    listingId,
    onCrop,
  } = props;

  return (
    <>
      <aside className="preview-container">
        {error && <div>{error}</div>}
        {!!previews.length
          && previews.map(preview => (
            <div className="preview-card" key={preview}>
              <LoadingSpinner />
              <img src={preview} className="img-fluid uploading" alt="preview" />
            </div>
          ))}
      </aside>
      <aside className="preview-container">
        {!!images.length
          && images.map(image => (
            <div className="preview-card" key={image.id}>
              <PhotoButtons
                onCrop={onCrop}
                onError={onError}
                listingId={listingId}
                image={image}
                cropEndpoint="/api/v2/listings.images.crop"
                deleteEndpoint="/api/v2/listings.images.delete"
              >
                <img src={image.thumb} className="img-fluid" alt="preview" />
              </PhotoButtons>
            </div>
          ))}
      </aside>
    </>
  );
};

Previews.defaultProps = {
  error: null,
  previews: [],
  images: [],
};

Previews.propTypes = {
  error: PropTypes.string,
  previews: PropTypes.arrayOf(PropTypes.string),
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      thumb: PropTypes.string,
    }),
  ),
  onError: PropTypes.func.isRequired,
  listingId: PropTypes.number.isRequired,
  onCrop: PropTypes.func.isRequired,
};

export default Previews;
