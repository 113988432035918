import React from 'react';

export const ButtonStepper = props => (
  <div className="row button-row">
    <div className="col-md-6 button-right">
      <button
        type="button"
        onClick={props.handleBack}
        className="button btn-transparent-white btn-rounded float-right"
        disabled={props.submitting || false}
      >
        Back
      </button>
    </div>
    <div className="col-md-6 button-left">
      <button
        type="button"
        onClick={props.handleNext}
        className="button btn-transparent-yellow btn-rounded"
        disabled={props.disabled || props.submitting || false}
      >
        Next
      </button>
    </div>
  </div>
);
