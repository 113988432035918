import React from 'react';
import PayModal from '../components/payment/PayModal';
import PayPreviewModal from '../components/payment/PayPreviewModal';

export const PayButton = props => {
  const { className, lease_id, lease_client_id, button_class_name } = props;

  return (
    <div>
      <PayModal
        className={`${className} modal-wrapper`}
        lease_id={lease_id}
        lease_client_id={lease_client_id}
        {...props}
      >
        <button
          type="button"
          className={
            button_class_name || 'button btn-rounded btn-black-white float-right'
          }
        >
          Pay
        </button>
      </PayModal>
    </div>
  );
};

export const PayButtonJumbotron = props => (
  <PayModal {...props}>
    <a className="button btn-rounded btn-yellow-black" style={{ color: 'black' }}>
      Pay
    </a>
  </PayModal>
);

export const PaymentPreview = ({ lease_id, payment, client_name }) => (
  <PayPreviewModal
    lease_id={lease_id}
    payment={payment}
    clientName={client_name}
  >
    <a>Pre-Payment Preview</a>
  </PayPreviewModal>
);
