import React, { useEffect, useState } from 'react'
import AuthModal from 'components/modal/AuthModal'
import like, { unlike } from 'components/listings/cards/api'

function gtagReportConversion(url) {
  var callback = function () {
    if (typeof url != 'undefined') {
      window.location = url
    }
  }
  // eslint-disable-next-line no-undef
  gtag('event', 'likelisting', {
    send_to: 'AW-968054781/WSr4CPT3orwYEP2vzc0D',
    event_callback: callback,
  })
  return false
}

const LikeButton = (props) => {
  const { id, liked, signedIn, type } = props

  const [isLiked, setIsLiked] = useState(false)

  useEffect(() => {
    setIsLiked(liked)
  }, [liked])

  const toggleLike = (e) => {
    e.preventDefault()

    if (signedIn === false) {
      return
    }

    if (isLiked === false) {
      like(id, type)
        .then(() => {
          setIsLiked(true)
          gtagReportConversion()
        })
        .catch((err) => console.dir(err))
    } else {
      unlike(id, type)
        .then(() => {
          setIsLiked(false)
        })
        .catch((err) => console.dir(err))
    }
  }

  return (
    <div {...(signedIn && { onClick: toggleLike })} style={{ padding: '7px' }} className="col-2 text-center">
      {!signedIn ? (
        <AuthModal>
          <i className={isLiked ? 'nookons-heart-fill nklyn-red' : 'nookons-heart'} id="nklyn-listing-like-img" />
        </AuthModal>
      ) : (
        <i className={isLiked ? 'nookons-heart-fill nklyn-red' : 'nookons-heart'} id="nklyn-listing-like-img" />
      )}
    </div>
  )
}

export default LikeButton
