import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initializeAgents } from 'reducers/agentTypeahead';
import { AsyncProvider, AsyncSubscribe } from 'asyncHandlers/AsyncBase';
import { useDidUpdate, usePrevious } from 'components/agentTypeaheadComponents/customEffects';
import Typeahead, { Option } from 'components/typeaheadComponents';
import AgentAsync from 'asyncHandlers/AgentAsync';
import SingleAgent from './SingleAgent';

const AgentTeamTypeaheadContainer = props => (
  <AsyncProvider handler={new AgentAsync()}>
    <ConnectedAgentTeamTypeahead {...props} />
  </AsyncProvider>
);

const AgentTeamTypeahead = (props) => {
  const { agents, selectedAgents, handleAddAgent, handleRemoveAgent, singleSelect } = props;
  const agentType = 'salesTeamAgent';
  const prevProps = usePrevious(props);
  const initialized = useRef(false);
  useDidUpdate(() => {
    const { _initializeAgents } = props;
    if (prevProps.agents !== agents && !initialized.current) {
      _initializeAgents(agents);
      initialized.current = true
    }
    if (prevProps.selectedAgents.length < selectedAgents.length) {
      const agent = selectedAgents[selectedAgents.length - 1]
      handleAddAgent({value: agent.id})
    }
    if (prevProps.selectedAgents.length > selectedAgents.length) {
      const agent = prevProps.selectedAgents.find(agent => !selectedAgents.includes(agent));
      if (singleSelect) {
        handleAddAgent({value: agent.id})
      } else {
        handleRemoveAgent(null, agent.id);
      }
    }
  });
  return (
    <Typeahead
      dropdownClass="agent-dropdown-container"
      dropdownStyle={{
        height: '300px',
        width: '325px',
        position: 'relative',
        backgroundColor: '#000',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      filterClass="btn-text-field btn-rounded btn-black-yellow"
      filterStyle={{
        overflowY: 'auto',
        borderRadius: '2px',
        border: '1px solid',
        minHeight: '38px',
        display: 'flex',
        flexWrap: 'wrap',
        position: 'relative',
        padding: '5px',
        paddingTop: '7px',
        paddingLeft: '12px',
        paddingRight: '5px',
      }}
      containerStyle={{
        display: 'block',
        margin: 'auto',
        width: '325px',
        paddingTop: '10px',
      }}
      placeholder="Search Agents"
    >
      {
        agents && agents.map(agent => (
          <Option key={agent.id} value={`${agent.firstName} ${agent.lastName}`} id={agent.id}>
            <SingleAgent noCheck={singleSelect} agentType={agentType} {...agent} />
          </Option>
        ))
      }
    </Typeahead>
  );
};

AgentTeamTypeahead.defaultProps = {
  agents: [],
};

AgentTeamTypeahead.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  })),
};

const mapStateToProps = state => ({
  selectedAgents: state.agentTypeahead.salesTeamAgent.selectedAgents,
});

const mapDispatchToProps = dispatch => ({
  _initializeAgents: agents => dispatch(initializeAgents(agents)),
});

const AgentTeamTypeaheadAsync = AsyncSubscribe(['agents'])(AgentTeamTypeahead);
const ConnectedAgentTeamTypeahead = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgentTeamTypeaheadAsync);

export default AgentTeamTypeaheadContainer;
