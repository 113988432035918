import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';
import PricePop from '../searchPopovers/Price';
import PriceButton from '../searchbarButtons/PriceButton';
import RenderOutsideContainer from './RenderOutsideContainer';
import Reposition from './Reposition';

class Price extends Component {
  render() {
    const {
      popoverWrapper,
      popoverContainer,
      popover,
      searchContext,
    } = this.props;
    return (
      <Column
        ref={popoverContainer}
        className="searchbar-col"
      >
        <RenderOutsideContainer>
          <PricePop
            ref={{
              popoverWrapper,
              popover,
            }}
            searchContext={searchContext}
            displayProp="priceDisplay"
          />
        </RenderOutsideContainer>
        <PriceButton />
      </Column>
    );
  }
}

Price.defaultProps = {
  popoverWrapper: null,
  popoverContainer: null,
  popover: null,
};

Price.propTypes = {
  popoverWrapper: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popoverContainer: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popover: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  searchContext: PropTypes.string.isRequired,
};

export default Reposition(Price);
