import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Column } from 'wrappers/FlexTable'
import fetchFilterData from 'api/search'

import s from './SearchBar.module.css'

// Buttons
// import SentenceSearchModal from './SentenceSearchModal';
import {
  Bed,
  LikedOnly,
  Location,
  MarketAs,
  More,
  MoveBy,
  NoFeeOnly,
  Ppsf,
  Price,
  SortResultsBy,
  SquareFeet,
  SubletOnly,
  SubwayStops,
  MobileFilter,
} from './SearchBarButtons'

const SearchBar = (props) => {
  const { type, selectedFilters, onFilterChange } = props

  const [filterData, setFilterData] = useState({
    regions: [],
    neighborhoods: [],
    subways: [],
    subwayStops: [],
    amenities: [],
    baths: [],
    bedrooms: [],
    pets: [],
    sortResultsBy: [],
    marketAs: [],
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchFilterData().then((results) => {
      setFilterData(results)
      setLoading(false)
    })
  }, [])

  return (
    <Row className="col-sm-12 nklyn-listing-search-bar">
      {type === 'listings' && (
        <>
          <div className={s.mobile}>
            <MobileFilter
              key="listing-searchbar-mobile-filter"
              selected={{
                price: selectedFilters.price,
                bedrooms: selectedFilters.bedrooms,
                baths: selectedFilters.baths,
                amenities: selectedFilters.amenities,
                noFeeOnly: selectedFilters.noFeeOnly,
                pets: selectedFilters.pets,
              }}
              onChange={(x) => {
                onFilterChange('mobileFilter', x)
              }}
              options={{
                bedrooms: filterData.bedrooms,
                baths: filterData.baths,
                pets: filterData.pets,
                amenities: filterData.amenities,
              }}
              loading={loading}
              type={type}
            />
          </div>
          <div className={s.desktop}>
            <Price
              key="listing-searchbar-price"
              selected={selectedFilters.price}
              onChange={(x) => {
                onFilterChange('price', x)
              }}
              loading={loading}
              type={type}
            />
          </div>
          <Location
            key="listing-searchbar-hood"
            selected={{
              regions: selectedFilters.regions,
              neighborhoods: selectedFilters.neighborhoods,
            }}
            onChange={(x) => {
              onFilterChange('location', x)
            }}
            loading={loading}
            options={{
              regions: filterData.regions,
              neighborhoods: filterData.neighborhoods,
            }}
          />
          <div className={s.desktop}>
            <Bed
              key="listing-searchbar-bed"
              selected={{
                bedrooms: selectedFilters.bedrooms,
                baths: selectedFilters.baths,
              }}
              onChange={(x) => onFilterChange('bathAndBeds', x)}
              loading={loading}
              options={{
                bedrooms: filterData.bedrooms,
                baths: filterData.baths,
              }}
            />
          </div>
          <SubwayStops
            key="listing-searchbar-subway-stops"
            selected={{
              subways: selectedFilters.subways,
              subwayStops: selectedFilters.subwayStops,
            }}
            onChange={(x) => {
              onFilterChange('subwayStops', x)
            }}
            loading={loading}
            options={{
              subwayLines: filterData.subwayLines,
              subwayStops: filterData.subwayStops,
            }}
          />
          <div className={s.desktop}>
            <More
              key="listing-searchbar-more"
              selected={{
                amenities: selectedFilters.amenities,
                address: selectedFilters.address,
                likedOnly: selectedFilters.likedOnly,
                pets: selectedFilters.pets,
              }}
              onChange={(x) => {
                onFilterChange('more', x)
              }}
              loading={loading}
              options={{
                amenities: filterData.amenities,
                pets: filterData.pets,
              }}
              amenities
              address
              likedOnly
              pets
            />

            <NoFeeOnly
              key="listing-searchbar-no-fee"
              selected={selectedFilters.noFeeOnly}
              onChange={(x) => {
                onFilterChange('noFeeOnly', x)
              }}
              loading={loading}
            />
          </div>
          <SortResultsBy
            key="list-searchbar-order"
            selected={selectedFilters.sortBy}
            onChange={(x) => {
              onFilterChange('sortBy', x)
            }}
            loading={loading}
            options={filterData.sortResultsBy}
          />
        </>
      )}
      {type === 'roommates' && (
        <>
          <Price
            key="listing-searchbar-price"
            selected={selectedFilters.price}
            onChange={(x) => {
              onFilterChange('price', x)
            }}
            loading={loading}
          />
          <Location
            key="listing-searchbar-hood"
            selected={{
              regions: selectedFilters.regions,
              neighborhoods: selectedFilters.neighborhoods,
            }}
            onChange={(x) => {
              onFilterChange('location', x)
            }}
            loading={loading}
            options={{
              regions: filterData.regions,
              neighborhoods: filterData.neighborhoods,
            }}
          />
          <MoveBy
            key="listing-searchbar-moveby"
            selected={selectedFilters.moveBy}
            onChange={(x) => {
              onFilterChange('moveBy', x)
            }}
            loading={loading}
          />
        </>
      )}
      {type === 'rooms' && (
        <>
          <Price
            key="listing-searchbar-price"
            selected={selectedFilters.price}
            onChange={(x) => {
              onFilterChange('price', x)
            }}
            loading={loading}
            type={type}
          />
          <Location
            key="listing-searchbar-hood"
            selected={{
              regions: selectedFilters.regions,
              neighborhoods: selectedFilters.neighborhoods,
            }}
            onChange={(x) => {
              onFilterChange('location', x)
            }}
            loading={loading}
            options={{
              regions: filterData.regions,
              neighborhoods: filterData.neighborhoods,
            }}
          />
          <SubwayStops
            key="listing-searchbar-subway-stops"
            selected={{
              subways: selectedFilters.subways,
              subwayStops: selectedFilters.subwayStops,
            }}
            onChange={(x) => {
              onFilterChange('subwayStops', x)
            }}
            loading={loading}
            options={{
              subwayLines: filterData.subwayLines,
              subwayStops: filterData.subwayStops,
            }}
          />
          <More
            key="listing-searchbar-more"
            selected={{
              amenities: selectedFilters.amenities,
              likedOnly: selectedFilters.likedOnly,
              pets: selectedFilters.pets,
            }}
            onChange={(x) => {
              onFilterChange('more', x)
            }}
            loading={loading}
            options={{
              amenities: filterData.amenities,
              baths: filterData.baths,
              pets: filterData.pets,
            }}
            amenities
            likedOnly
            pets
          />
          <NoFeeOnly
            key="listing-searchbar-no-fee"
            selected={selectedFilters.noFeeOnly}
            onChange={(x) => {
              onFilterChange('noFeeOnly', x)
            }}
            loading={loading}
          />
          <SortResultsBy
            key="list-searchbar-order"
            selected={selectedFilters.sortBy}
            onChange={(x) => {
              onFilterChange('sortBy', x)
            }}
            loading={loading}
            options={filterData.sortResultsBy}
          />
          <SubletOnly
            key="listing-searchbar-sublet"
            selected={selectedFilters.subletOnly}
            onChange={(x) => {
              onFilterChange('subletOnly', x)
            }}
            loading={loading}
          />
        </>
      )}
      {type === 'commercials' && (
        <>
          <Price
            key="listing-searchbar-price"
            selected={selectedFilters.price}
            onChange={(x) => {
              onFilterChange('price', x)
            }}
            loading={loading}
            type={type}
          />
          <Location
            key="listing-searchbar-hood"
            selected={{
              regions: selectedFilters.regions,
              neighborhoods: selectedFilters.neighborhoods,
            }}
            onChange={(x) => {
              onFilterChange('location', x)
            }}
            loading={loading}
            options={{
              regions: filterData.regions,
              neighborhoods: filterData.neighborhoods,
            }}
          />
          <SubwayStops
            key="listing-searchbar-subway-stops"
            selected={{
              subways: selectedFilters.subways,
              subwayStops: selectedFilters.subwayStops,
            }}
            onChange={(x) => {
              onFilterChange('subwayStops', x)
            }}
            loading={loading}
            options={{
              subwayLines: filterData.subwayLines,
              subwayStops: filterData.subwayStops,
            }}
          />
          <LikedOnly
            key="listing-searchbar-liked"
            selected={selectedFilters.likedOnly}
            onChange={(x) => {
              onFilterChange('likedOnly', x)
            }}
            loading={loading}
          />
          <Ppsf
            key="listing-searchbar-ppsf"
            selected={selectedFilters.ppsf}
            onChange={(x) => {
              onFilterChange('ppsf', x)
            }}
            loading={loading}
          />
        </>
      )}
      {type === 'sales' && (
        <>
          <Price
            key="listing-searchbar-price"
            selected={selectedFilters.price}
            onChange={(x) => {
              onFilterChange('price', x)
            }}
            loading={loading}
            type={type}
          />
          <Location
            key="listing-searchbar-hood"
            selected={{
              regions: selectedFilters.regions,
              neighborhoods: selectedFilters.neighborhoods,
            }}
            onChange={(x) => {
              onFilterChange('location', x)
            }}
            loading={loading}
            options={{
              regions: filterData.regions,
              neighborhoods: filterData.neighborhoods,
            }}
          />
          <Bed
            key="listing-searchbar-bed"
            selected={selectedFilters.bedrooms}
            onChange={(x) => {
              onFilterChange('bedrooms', x)
            }}
            loading={loading}
            options={filterData.bedrooms}
          />
          <SubwayStops
            key="listing-searchbar-subway-stops"
            selected={{
              subways: selectedFilters.subways,
              subwayStops: selectedFilters.subwayStops,
            }}
            onChange={(x) => {
              onFilterChange('subwayStops', x)
            }}
            loading={loading}
            options={{
              subwayLines: filterData.subwayLines,
              subwayStops: filterData.subwayStops,
            }}
          />
          <More
            key="listing-searchbar-more"
            selected={{
              amenities: selectedFilters.amenities,
              baths: selectedFilters.baths,
              likedOnly: selectedFilters.likedOnly,
            }}
            onChange={(x) => {
              onFilterChange('more', x)
            }}
            loading={loading}
            options={{
              amenities: filterData.amenities,
              baths: filterData.baths,
              pets: filterData.pets,
            }}
            amenities
            baths
            likedOnly
          />
          <MarketAs
            key="listing-searchbar-market-as"
            selected={selectedFilters.marketAs}
            onChange={(x) => {
              onFilterChange('marketAs', x)
            }}
            loading={loading}
            options={filterData.marketAs}
          />
          <SquareFeet
            key="listing-searchbar-square-feet"
            selected={selectedFilters.squareFeet}
            onChange={(x) => {
              onFilterChange('squareFeet', x)
            }}
            loading={loading}
          />
          <SortResultsBy
            key="list-searchbar-order"
            selected={selectedFilters.sortBy}
            onChange={(x) => {
              onFilterChange('sortBy', x)
            }}
            loading={loading}
            options={filterData.sortResultsBy}
          />
        </>
      )}
      <Column className="searchbar-col">
        <input
          type="button"
          className="button btn-rounded btn-transparent btn-search-bar-size"
          onClick={() => {
            if (!loading) {
              onFilterChange('clearAll')
            }
          }}
          value="Reset"
        />
      </Column>
      {/* {type === 'listings' && (
        <SentenceSearchModal />
      )} */}
    </Row>
  )
}

SearchBar.propTypes = {
  type: PropTypes.string.isRequired,
  selectedFilters: PropTypes.shape({
    price: PropTypes.arrayOf(PropTypes.string),
    regions: PropTypes.arrayOf(PropTypes.string),
    neighborhoods: PropTypes.arrayOf(PropTypes.string),
    bedrooms: PropTypes.arrayOf(PropTypes.string),
    subways: PropTypes.arrayOf(PropTypes.string),
    subwayStops: PropTypes.arrayOf(PropTypes.string),
    amenities: PropTypes.arrayOf(PropTypes.string),
    address: PropTypes.string,
    baths: PropTypes.string,
    likedOnly: PropTypes.bool,
    pets: PropTypes.string,
    noFeeOnly: PropTypes.bool,
    sortBy: PropTypes.string,
    moveBy: PropTypes.string,
    subletOnly: PropTypes.bool,
    ppsf: PropTypes.arrayOf(PropTypes.string),
    marketAs: PropTypes.string,
    squareFeet: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
}

export default SearchBar
