import axios from 'axios';

// ACTION TYPES
const GET_LIKED_LISTINGS = 'GET_LIKED_LISTINGS';
const UNLIKE_LISTING = 'UNLIKE_LISTING';
const LIKE_LISTING = 'LIKE_LISTING';

// ACTION CREATORS
const getListingLikes = likes => ({ type: GET_LIKED_LISTINGS, likes });
const likeListing = listingId => ({ type: LIKE_LISTING, listingId });
const unlikeListing = listingId => ({ type: UNLIKE_LISTING, listingId });

// THUNK CREATORS
export function fetchListingLikes() {
  return function thunk(dispatch) {
    return axios
      .get('/current/liked_listings')
      .then(res => res.data)
      .then(likes => {
        dispatch(getListingLikes(likes));
      })
      .catch(error => console.log(error));
  };
}

export function dispatchLike(listingId) {
  return function thunk(dispatch) {
    return axios
      .get(`/listings/${listingId}/like`)
      .then(res => {
        if (!res.request.responseURL.includes(`${listingId}`)) {
          // window.location.href = "/login";
          $(`#listingLikeButton${listingId}`).append(
            "<div id='auth-modal'></div>"
          );
        } else {
          dispatch(likeListing(listingId));
        }
      })
      .catch(error => console.log(error));
  };
}

export function dispatchUnlike(listingId) {
  return function thunk(dispatch) {
    return axios
      .get(`/listings/${listingId}/unlike`)
      .then(res => dispatch(unlikeListing(listingId)))
      .catch(error => console.log(error));
  };
}

// REDUCER
export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_LIKED_LISTINGS:
      return action.likes;
    case LIKE_LISTING:
      return [...state, action.listingId];
    case UNLIKE_LISTING:
      return state.filter(id => id !== action.listingId);
    default:
      return state;
  }
}
