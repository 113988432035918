import React from 'react';
import { connect } from 'react-redux';
import registerImg from 'images/illustrations/SignUp_Illustration.png';
import { signup, submitForm } from '../../reducers/auth';

const SignupForm = props => {
  const {
    name,
    handleSubmit,
    error,
    closeModal,
    submitting,
    handleLogin,
  } = props;

  return (
    <div className="login-page">
      <div className="row no-no-gutters">
        <div className="col-md-6 login-form-side">
          <button className="close" onClick={closeModal}>
            <i className="nookons-close-circle-fill" />
          </button>
          <h1>Signup</h1>
          <form
            onSubmit={handleSubmit}
            name={name}
            className="new_agent"
            id="new_agent"
          >
            <input name="utf8" type="hidden" value="✓" />
            <input type="hidden" name="authenticity_token" value="" />
            {error && <ul style={{color: 'red'}}>
              {Object.keys(error).map((fieldName) => {
                const prettyFieldName = (fieldName.charAt(0).toUpperCase() + fieldName.slice(1)).replace(/_/g,' ')
                const message = error[fieldName]
                const fullMessage = prettyFieldName + ' ' + message;
              return (<li>
                  {fullMessage}
                </li>)
              })}
            </ul>}
            <label className="auth-label">Name</label>
            <div className="input-group">
              <div className="ipnut-group-prepend">
                <span className="input-group-text"><i className="nookons-human" /></span>
              </div>
              <input
                className="general-text-field mb-3 form-control"
                placeholder="Monty Thecat"
                type="text"
                name="agent[name]"
                id="agent_name"
                autoComplete="given-name"
                required
                style={
                  error && (error.first_name || error.last_name) ? { borderColor: 'red' } : {}
                }
              />
            </div>
            <label className="auth-label">Email</label>
            <div className="input-group">
              <div className="ipnut-group-prepend">
                <span className="input-group-text"><i className="nookons-email-fill" /></span>
              </div>
              <input
                placeholder="Email"
                className="general-text-field mb-3 form-control"
                type="email"
                name="agent[email]"
                id="agent_email"
                autoComplete="off"
                style={error && error.email ? { borderColor: 'red' } : {}}
              />
            </div>
            <label className="auth-label">Password</label>
            <div className="input-group">
              <div className="ipnut-group-prepend">
                <span className="input-group-text"><i className="nookons-lock-fill" /></span>
              </div>
              <input
                placeholder="Password"
                className="general-text-field mb-3 form-control"
                type="password"
                name="agent[password]"
                id="agent_password"
                autoComplete="off"
                style={error && error.password ? { borderColor: 'red' } : {}}
              />
            </div>
            <div className="input-group">
              <div className="ipnut-group-prepend">
                  <span className="input-group-text"><i className="nookons-lock-fill" /></span>
                </div>
              <input
                placeholder="Password Again"
                className="general-text-field mb-3 form-control"
                type="password"
                name="agent[password_confirmation]"
                id="agent_password_confirmation"
                autoComplete="off"
                style={
                  error && error.password_confirmation
                    ? { borderColor: 'red' }
                    : {}
                }
              />
            </div>
            <div className="row login-action-row">
              <div className="col-sm-12">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name="agent[email_opt_in]"
                    id="agent_email_opt_in"
                    className="custom-control-input"
                  />
                  <label
                    style={{ paddingLeft: '5px' }}
                    className="custom-control-label"
                    htmlFor="agent_email_opt_in"
                  >
                    Sign up for email updates on New York’s changing rental laws, local listings, community events, and more. Unsubscribe anytime.
                  </label>
                </div>
              </div>
            </div>
            <button
              type="submit"
              name="commit"
              className="button btn-yellow-black btn-login btn-rounded w-100"
              style={{ fontSize: '12px' }}
              disabled={submitting}
            >
              Create account
              <i className="nookons-arrow-right float-right" style={{ lineHeight: '1.5' }} />
            </button>
            <hr/>
            <div className="auth-notes mb-5">Already have an account?{' '}
              <span onClick={handleLogin}>Log in</span>
            </div>
          </form>
          {error &&
            error.email &&
            error.email.includes('has already been taken') && (
              <a className="forgot-password" href="/agents/password/new">
                Forgot your password? Click here to reset
              </a>
            )}
        </div>
        <div className="col-md-6 d-none d-md-inline-block auth-text border-0">
          <img
            alt="Login"
            src={registerImg}
            className="register-illustration-side"
          />
          <h4>Why?</h4>
          <p>
            With an account, you can save apartments, schedule apartment tours,
            message roommates and securely apply and pay for apartments. Nooklyn
            is trusted by over 50,000 renters and owners in NYC.
          </p>
        </div>
      </div>
    </div>
  );
};

const mapState = (state, ownProps) => ({
  name: 'sign_up',
  error: state.auth.signupError,
  submitting: state.auth.submitting,
  closeModal: ownProps.closeModal,
  handleLogin: ownProps.handleLogin,
  redirect: ownProps.redirect,
});

const mergeProps = (stateProps, dispatchProps) => {
  const {
    name,
    error,
    submitting,
    closeModal,
    handleLogin,
    redirect,
  } = stateProps;
  const { dispatch } = dispatchProps;
  return {
    name,
    error,
    submitting,
    closeModal,
    handleLogin,
    handleSubmit(evt) {
      evt.preventDefault();
      const email = evt.target['agent[email]'].value;
      const password = evt.target['agent[password]'].value;
      const password_confirmation =
        evt.target['agent[password_confirmation]'].value;
      const name = evt.target['agent[name]'].value;
      const first_name = name.substr(0, name.indexOf(' '));
      const last_name = name.substr(name.indexOf(' ')+1);
      const email_opt_in = evt.target['agent[email_opt_in]'].value;
      dispatch(submitForm());
      dispatch(
        signup(email, password, password_confirmation, first_name, last_name, email_opt_in, redirect),
      );
    },
  };
};

export default connect(
  mapState,
  null,
  mergeProps,
)(SignupForm);
