import React, { useState } from 'react';
import loadScript from 'scriptjs';

const LoadExternalScripts = (Component, externalScripts) => (props) => {
  const [scriptsLoaded, updateScriptsLoaded] = useState(false);
  loadScript(externalScripts, () => {
    updateScriptsLoaded(true);
  });

  return (
    scriptsLoaded ? <Component {...props} /> : null
  );
};

export default LoadExternalScripts;
