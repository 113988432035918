export function debounce(func, interval) {
  var timeout;
  return function () {
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, interval || 200);
  };
}

export function throttle(func, interval) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function () {
      timeout = false;
    };
    if (!timeout) {
      func.apply(context, args);
      timeout = true;
      setTimeout(later, interval);
    }
  };
}

export function getTranslate(item) {
           var transArr = [];

           if (!window.getComputedStyle) return;
           var style     = getComputedStyle(item),
               transform = style.transform || style.webkitTransform || style.mozTransform || style.msTransform;
           var mat       = transform.match(/^matrix3d\((.+)\)$/);
           if (mat) return parseFloat(mat[1].split(', ')[13]);

           mat = transform.match(/^matrix\((.+)\)$/);
           mat ? transArr.push(parseFloat(mat[1].split(', ')[4])) : transArr.push(0);
           mat ? transArr.push(parseFloat(mat[1].split(', ')[5])) : transArr.push(0);

           return transArr;
       }
