import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';

const strftime = require('strftime');

const MoveBy = props => {
  const {
    selected,
    onChange,
    loading,
  } = props;

  const [value, setValue] = useState(selected);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  useEffect(() => {
    $('.datepicker').pickadate({
      container: '#root-picker-outlet',
      min: [new Date()],
    });
    window.addEventListener('click', e => {
      const cn = e.target.className;
      if (cn === 'picker__day picker__day--infocus' || cn === 'picker__day picker__day--infocus picker__day--today') {
        onChange(e.target.attributes['aria-label'].value);
      } else if (cn === 'picker__button--today') {
        onChange(strftime('%B %e, %Y', new Date()));
      } else if (cn === 'picker__button--clear') {
        e.stopPropagation();
        onChange(null);
      }
    }, true);
  }, []);

  if (loading) {
    return (
      <Column className="searchbar-col">
        <input
          name="moveBy"
          type="button"
          className="datepicker button btn-rounded btn-transparent-yellow btn-search-bar-size"
          value="Move By"
        />
      </Column>
    );
  }

  const className = value ? 'btn-yellow-black btn-search-bar-size' : 'btn-transparent-yellow btn-search-bar-size';
  const buttonTxt = !value || value.length === 0 ? 'Move By' : value.length > 0 ? value : '';
  return (
    <Column className="searchbar-col">
      <div id="root-picker-outlet" />
      <input
        name="moveBy"
        type="button"
        className={`datepicker button btn-rounded ${className} picker__input`}
        value={buttonTxt}
      />
    </Column>
  );
};

MoveBy.defaultProps = {
  selected: '',
  onChange: () => {},
  loading: false,
};

MoveBy.propTypes = {
  selected: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default MoveBy;
