import React from 'react';
import { connect } from 'react-redux';
import InviteModalAgent from '../components/modal/InviteModalAgent';

const InviteButton = props => (
  <InviteModalAgent {...props}>
    <a className="button btn-rounded btn-black-white float-right">
      Invite
    </a>
  </InviteModalAgent>
);

export default connect()(InviteButton);
