import React from 'react'
import axios from 'axios'
import { getMetaContent } from '../../../helpers/util'

function gtagReportConversion(url) {
  const callback = () => {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }
  // eslint-disable-next-line no-undef
  gtag('event', 'skip_disclosure', {
    send_to: 'AW-968054781/HW9wCMGMo7wYEP2vzc0D',
    event_callback: callback,
  })
  return false
}

export default class SkipDisclosuresContent extends React.Component {
  source = axios.CancelToken.source()

  state = { error: '', submitError: '' }

  componentWillUnmount() {
    this.source.cancel()
  }

  handleSubmit = (evt) => {
    evt.preventDefault()
    const { submitForm, lease_client_id, fetchLeaseClient, setMessage, submitted, token } = this.props

    submitForm()

    axios
      .post(`/rental_applications/${token}/skip_disclosure`, {
        authenticity_token: getMetaContent('csrf-token'),
      })
      .then((res) => {
        submitted()
        if (fetchLeaseClient) fetchLeaseClient(lease_client_id)
        if (setMessage) setMessage('You have successfully signed the Nooklyn deposit agreement.')

        gtagReportConversion()
      })
      .catch((err) => {
        submitted()
        this.setState({
          submitError: 'Please refresh the page, your session has expired.',
        })
        console.dir(err)
      })
  }

  render() {
    const { submitting, client } = this.props
    const { submitError } = this.state

    return (
      <div className="container skip_disclosures-container">
        <h1>NYS DISCLOSURE FORMS</h1>
        <hr className="entire-width" />
        <p className="mb-5">
          The disclosure forms are not contracts, they simply disclose that Nooklyn is acting as a dual agency,
          representing both the owner and you. Additionally, the second disclosure informs of you rights as a
          renterunder NYS law. You do not need to sign the disclosure, but skipping the disclosure acknowledges that you
          were presented with the disclosure form and decided to forego signing.
        </p>
        {submitError && <p className="error text-center">{submitError}</p>}
        <form onSubmit={this.handleSubmit} className="skip_disclosures_form" id="skip_disclosures_form">
          <input type="hidden" name="authenticity_token" value="" />
          <div className="row justify-content-start mt-2">
            <div className="col-sm-2">
              <input
                type="submit"
                name="commit"
                value="Skip Disclosures"
                className="button btn-rounded btn-yellow-black"
                disabled={submitting || !!client}
              />
            </div>
          </div>
        </form>
      </div>
    )
  }
}
