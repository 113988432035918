import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MatrixListingFirstCol from './MatrixListingFirstCol';
import MatrixListingSecondCol from './MatrixListingSecondCol';
import MatrixListingThirdCol from './MatrixListingThirdCol';
import MatrixListingTopBar from './MatrixListingTopBar';

const MatrixListingCard = props => {
  const {
    edit,
    listing,
    readOnly,
    superAdmin,
  } = props;

  const [currenListing, setCurrentListing] = useState(listing);
  const [approvedOrRejected, setApprovedOrRejected] = useState(false);

  useEffect(() => {
    if (listing !== currenListing) {
      setCurrentListing(listing);
    }
  }, [listing]);

  const changeField = (value, attr) => {
    setCurrentListing({ ...currenListing, [attr]: value });
  };

  const approveOrReject = () => {
    setApprovedOrRejected(true);
  };

  return (
    <div
      style={{
        display: approvedOrRejected ? 'none' : 'block',
      }}
      className="container new-matrix-card"
    >
      <MatrixListingTopBar
        approveOrReject={approveOrReject}
        listing={listing}
        changeField={changeField}
        readOnly={readOnly}
        superAdmin={superAdmin}
      />
      <div className="row listing-admin-area-content-section">
        <MatrixListingFirstCol
          edit={edit || null}
          changeField={changeField}
          listing={listing}
          superAdmin={superAdmin}
        />
        <MatrixListingSecondCol
          edit={edit || null}
          changeField={changeField}
          listing={listing}
          superAdmin={superAdmin}
        />
        <MatrixListingThirdCol
          edit={edit || null}
          changeField={changeField}
          listing={listing}
          superAdmin={superAdmin}
        />
      </div>
    </div>
  );
};

MatrixListingCard.propTypes = {
  edit: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  superAdmin: PropTypes.bool.isRequired,
  listing: PropTypes.shape({}).isRequired,
};

export default MatrixListingCard;
