import axios from 'axios';
import keysToCamel from './util';

// Formatting Building to go from ruby format to react
// Param(s):
// building - Building object from API Call (Snake format)
const formatBuilding = building => {
  const formattedBuilding = keysToCamel(building);

  return formattedBuilding;
};

// API call for fetching a building
// Param(s):
// buildingId - Building ID for building we want to fetch
const fetchBuilding = async buildingId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const buildingUrl = `/api/v2/buildings.fetch?building_id=${buildingId}`;

  return axios.get(buildingUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.building)
    .then(building => formatBuilding(building));
};

// API call for fetching interested roommates for a building
// Param(s):
// buildingId - Building ID for building we want interested roommates for
export const fetchInterestedBuildingRoommates = async buildingId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const roommatesUrl = `/api/v2/buildings.interested_roommates?building_id=${buildingId}`;

  return axios.get(roommatesUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.roommates)
    .then(roommates => formatBuilding(roommates));
};

export default fetchBuilding;
