import React, { useEffect, useState } from 'react';
import { keysToCamel } from 'pro/util/inflector';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SimpleCardForm from './SimpleCardForm';

const BillingAccountPaymentMethod = props => {
  const { billing_account_id: billingAccountId } = props;

  const [showPaymentMethodForm, setShowPaymentMethodForm] = useState(false);
  const [error, setError] = useState(null);

  const [defaultPayment, setDefaultPayment] = useState({});

  const [setupIntent, setSetupIntent] = useState('');
  const [cardSubmitDisabled, setCardSubmitDisabled] = useState(false);

  const fetchBillingAccount = id => {
    const apiToken = localStorage.getItem('apiToken');

    fetch(`/api/v2/billing_accounts.fetch?billing_account_id=${id}`, {
      headers: {
        API_TOKEN: apiToken,
      },
    }).then(response => response.json())
      .then(response => keysToCamel(response))
      .then(response => {
        const {
          ok,
          billingAccount = {},
        } = response;

        if (ok) {
          const { defaultPayment } = billingAccount;

          console.log('Billing Account: ', billingAccount);

          if (defaultPayment === null) {
            setShowPaymentMethodForm(true);
          } else {
            setDefaultPayment(defaultPayment);
          }
        }
      });
  };

  const generateStripeSetupIntent = () => {
    const apiToken = localStorage.getItem('apiToken');

    const payload = {
      billing_account_id: billingAccountId,
    };

    fetch('/api/v2/payments.generate_stripe_setup_intent', {
      method: 'post',
      headers: {
        API_TOKEN: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(res => keysToCamel(res))
      .then(res => {
        if (res.ok) {
          setSetupIntent(res.setupIntent);
        }
      });
  };


  useEffect(() => {
    fetchBillingAccount(billingAccountId);
  }, [billingAccountId]);

  useEffect(() => {
    generateStripeSetupIntent();
  }, [billingAccountId]);

  const SetupIntentConfirmed = paymentMethod => {
    const apiToken = localStorage.getItem('apiToken');

    const payload = {
      billing_account_id: billingAccountId,
      payment_method: paymentMethod,
    };

    setError(null);

    fetch('/api/v2/billing_accounts.update_default_payment', {
      method: 'post',
      headers: {
        API_TOKEN: apiToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(response => keysToCamel(response))
      .then(response => {
        setCardSubmitDisabled(false);
        const {
          ok,
          error: {
            friendlyMessage: friendlyErrorMessage,
          } = {},
        } = response;

        if (ok) {
          setShowPaymentMethodForm(false);
          fetchBillingAccount(billingAccountId);
          generateStripeSetupIntent();
        } else {
          setError(friendlyErrorMessage);
        }
      });
  };

  const {
    brand,
    expMonth,
    expYear,
    last4,
    stripeKey,
  } = defaultPayment;

  return showPaymentMethodForm ? (
    <Elements stripe={loadStripe(stripeKey)}>
      <>
        { error && (
          <div className="error-block">{error}</div>
        )}
        <SimpleCardForm
          setupIntent={setupIntent}
          onSetupIntentConfirmed={SetupIntentConfirmed}
          disabled={cardSubmitDisabled}
          setDisabled={setCardSubmitDisabled}
        />
      </>
    </Elements>
  ) : (
    <>
      <h6 className="standard-type nklyn-white">Payment Method</h6><br />
      <div className="nklyn-pay-method-card">
        <p className="nklyn-pay-method-card-summary">{`${brand} *${last4} Exp. ${expMonth}/${expYear}`}</p>
        <p className="nklyn-pay-method-card-button" onClick={e => setShowPaymentMethodForm(true)}><i className="nookons-edit"></i></p>
      </div>
    </>
  );
};

export default BillingAccountPaymentMethod;
