import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import React from 'react';

/** sub reducers * */
import likedListings from './likedListings';
import fetchStatus from './fetchStatus';
import auth from './auth';
import payment from './payment';
import form from './form';
import lease from './lease';
import roommate from './roommate';
import flashMessage from './flashMessage';
import search from './search';
import matrixListings from './matrixListings';
import listingShow from './listingShow';
import agentTypeahead from './agentTypeahead';

const reducer = combineReducers({
  likedListings,
  fetchStatus,
  auth,
  payment,
  form,
  lease,
  roommate,
  flashMessage,
  search,
  matrixListings,
  listingShow,
  agentTypeahead,
});

const middlewares = [thunkMiddleware];
if (process.env.NODE_ENV === `development`) {
  middlewares.push(createLogger({ collapsed: true }));
}

export const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export function composeWithRedux(Component, props) {
  return (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  );
}
