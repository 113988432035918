import React, { useState } from 'react';
import Modal from 'components/modal/Modal';
import PropTypes from 'prop-types';
import PassbaseVerifyButton from '../Verification/PassbaseVerifyButton';

const ModalContent = () => (
  <div className="pro-id-verification-modal">
    <div className="title">
      <h3>Photo ID Verification</h3>
    </div>
    <PassbaseVerifyButton />
  </div>
);

const VerifyModal = props => {
  const { agentId, children } = props;

  const apiToken = localStorage.getItem('apiToken');

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => { setModalOpen(false); };

  return (
    <>
      <div onClick={openModal}>{children}</div>
      <Modal
        className="Modal__Bootstrap modal-dialog verification-modal"
        closeTimeoutMS={150}
        isOpen={modalOpen}
      >
        <div className="modal-content">
          <button type="button" className="close" onClick={closeModal}>
            <i className="nookons-close-circle-fill" />
          </button>
          <ModalContent agentId={agentId} apiToken={apiToken} />
        </div>
      </Modal>
    </>
  );
};

VerifyModal.propTypes = {
  agentId: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default VerifyModal;
