import React from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';
import LoadingSpinner from 'components/LoadingSpinner';

const LikedOnly = props => {
  const {
    selected,
    loading,
    onChange,
  } = props;

  const handleClick = newValue => {
    onChange(newValue);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.currentTarget.click();
    }
  };

  const className = selected ? 'btn-yellow-black btn-search-bar-size' : 'btn-transparent-yellow btn-search-bar-size';

  return (
    <Column>
      <hr />
      <div style={{
        margin: '24px 0px',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '19px',
        color: '#fff',
      }}
      >
        Liked Only
      </div>
      {
        !loading
          ? (
            <input
              name="likedOnly"
              onClick={() => handleClick(!selected)}
              onKeyPress={handleKeyPress}
              type="button"
              className={`button btn-rounded ${className}`}
              value="Liked Only"
            />
          )
          : <LoadingSpinner />
      }
    </Column>
  );
};

LikedOnly.propTypes = {
  selected: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LikedOnly;
