import React from 'react';
import PropTypes from 'prop-types';
import { Column, Row } from 'wrappers/FlexTable';
import CustomCheckBox from 'components/CustomCheckBox';
import { truncate } from 'helpers/util';
import LoadingSpinner from 'components/LoadingSpinner';

const AmenityFilter = props => {
  const {
    selected,
    onChange,
    options,
  } = props;

  const handleClick = option => {
    const tempValue = selected;

    if (tempValue.includes(option)) {
      tempValue.splice(tempValue.indexOf(option), 1);
      onChange(tempValue);
    } else {
      tempValue.push(option);
      onChange(tempValue);
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.currentTarget.click();
    }
  };

  return (
    <Column>
      <hr />
      <div style={{
        margin: '24px 0px',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '19px',
        color: '#fff',
      }}
      >
        Amenities
      </div>
      <div className="d-flex">
        <Row
          style={{
            flexWrap: 'wrap',
            paddingLeft: '0px',
            border: '0px',
          }}
          className="region-hood-content amenity-list"
        >
          {
            options.length
              ? options.map(
                option => (
                  <Column
                    key={`amenity-${option.value}`}
                    className="individual-amenity"
                    style={{
                      display: 'block',
                      paddingTop: '2px',
                      paddingBottom: '10px',
                      paddingRight: '0px',
                      paddingLeft: '0px',
                    }}
                    onClick={() => handleClick(option.value)}
                    onKeyPress={handleKeyPress}
                  >
                    <CustomCheckBox
                      boxClass="matrix-checkbox"
                      boxcheckedClass="matrix-checkbox-checked"
                      checkmarkClass="checkmark"
                      dataName={option.value}
                      checked={selected.includes(option.value)}
                      type="checkbox"
                      name={option.value}
                      value={option.value}
                    >
                      <Column
                        style={{
                          color: '#fff',
                          fontSize: '13px',
                          fontWeight: '500',
                          paddingLeft: '10px',
                          cursor: 'pointer',
                          userSelect: 'none',
                        }}
                      >
                        {truncate(option.name, 23)}
                      </Column>
                    </CustomCheckBox>
                  </Column>
                ),
              )
              : <LoadingSpinner />
          }
        </Row>
      </div>
    </Column>
  );
};

AmenityFilter.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

export default AmenityFilter;
