// eslint-disable-next-line no-unused-vars
import React, { useReducer } from 'react';

const augmentDispatch = (defaultDispatch, apiToken, getState) => (
  action => {
    if (typeof action === 'function') {
      const augmentedDispatch = augmentDispatch(defaultDispatch, apiToken, getState);
      return action(augmentedDispatch, apiToken, getState);
    }

    return defaultDispatch(action);
  }
);

const useAsyncReducer = (reducer, initialState, apiToken, getState) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const asyncDispatch = augmentDispatch(dispatch, apiToken, getState);

  return [state, asyncDispatch];
};

export default useAsyncReducer;
