import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import InputMask from 'react-input-mask';
import { ButtonSave, ButtonStepper } from '../Buttons';
import ContainerWrapper from '../ContainerWrapper';

const title = "What's the move out day?";

const MoveOutDay = (props) => {
  const {
    moveOutDate, setMoveOutDate, userIsEditing, setUserIsEditing,
  } = props;
  const datepickerRef = useRef(null);

  useEffect(() => {
    $(ReactDOM.findDOMNode(datepickerRef.current)).pickadate({
      format: 'mm/dd/yyyy',
      min: true,
      onSet() {
        const val = this.get('select', 'mm/dd/yyyy');
        setMoveOutDate(val);
      },
    });
  }, []);

  useEffect(() => {
    document.title = `Create: Apartments - ${title}`;
  }, []);

  return (
    <ContainerWrapper>
      <div>
        <div className="title">
          <h3>{title}</h3>
          <p>The last day of the lease.</p>
        </div>
        <form
          className="narrow"
          onKeyDown={(evt) => {
            if (evt.which === 13) evt.preventDefault();
          }}
        >
          <div className="row">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text general-text-field-dark">
                  <i className="nookons-calendar" />
                  <div id="root-picker-outlet" />
                  <input
                    ref={datepickerRef}
                    className="datepicker general-text-field picker__input"
                    type="text"
                    value={moveOutDate}
                    readOnly
                  />
                </span>
              </div>
              <InputMask
                mask="99/99/9999"
                className="form-control general-text-field-dark"
                value={moveOutDate}
                onChange={evt => setMoveOutDate(evt.target.value)}
              />
            </div>
          </div>
          {userIsEditing ? (
            <ButtonSave
              handleJump={props.handleJump}
              setUserIsEditing={setUserIsEditing}
              jumpTo={props.jumpTo || 'ApartmentReview'}
            />
          ) : (
            <ButtonStepper
              handleNext={props.handleNext}
              handleBack={props.handleBack}
              disabled={!/(19|20)\d{2}/i.test(moveOutDate)}
            />
          )}
        </form>
      </div>
    </ContainerWrapper>
  );
};

export default MoveOutDay;
