import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';
import Popover from 'components/generic/Popover';
import SortResultsByPopover from './SortResultsByPopover';

const SortResultsBy = props => {
  const {
    selected,
    onChange,
    loading,
    options,
  } = props;

  const targetRef = useRef(null);
  const [value, setValue] = useState(selected);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  const className = value.length > 0 && !loading ? 'btn-transparent-yellow' : 'btn-transparent-gray';

  return (
    <Column className="searchbar-col">
      <Popover type="click" targetRef={targetRef}>
        <SortResultsByPopover
          value={value}
          onChange={onChange}
          loading={loading}
          options={options}
        />
      </Popover>
      <input
        ref={targetRef}
        name="sortResultsBy"
        type="button"
        className={`button btn-rounded ${className} btn-search-bar-size`}
        value="Sort"
      />
    </Column>
  );
};

SortResultsBy.defaultProps = {
  selected: '',
  onChange: () => {},
  loading: false,
};

SortResultsBy.propTypes = {
  selected: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

export default SortResultsBy;
