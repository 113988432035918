import React from 'react';
import PropTypes from 'prop-types';
import AgentListingsAsync from 'asyncHandlers/AgentListingsAsync';
import { AsyncProvider } from 'asyncHandlers/AsyncBase';
import AgentListings from './AgentListings';

const AgentListingsContainer = ({
  listingIds,
}) => (
  <AsyncProvider handler={new AgentListingsAsync(listingIds)}>
    <AgentListings />
  </AsyncProvider>
);

AgentListingsContainer.propTypes = {
  listingIds: PropTypes.arrayOf(
    PropTypes.number,
  ).isRequired,
};

export default AgentListingsContainer;
