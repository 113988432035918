import React, { useMemo, useRef, useState, forwardRef } from 'react'

import PropTypes from 'prop-types'
import s from './Amenities.module.css'
import SearchInput from '../../common/SearchInput'
import FilterCheckbox from '../../common/FilterCheckbox'

const Amenities = forwardRef((props, ref) => {
  const { amenities, onSelect, options } = props

  const [search, setSearch] = useState('')

  const amenitiesToShow = useMemo(
    () => options.filter((item) => item.name?.match(new RegExp(search, 'i'))),
    [options, search]
  )

  const handleSearch = (value) => setSearch(value)

  return (
    <div className={s.root}>
      <SearchInput ref={ref} onChange={handleSearch} placeholder="Filter by Amenity" />

      <div className={s.list}>
        {amenitiesToShow?.map((amenity) => {
          const isSelected = amenities?.includes(amenity.value)

          return (
            <div className={s.listItem}>
              <FilterCheckbox
                key={amenity.value}
                handleSelect={onSelect}
                item={{
                  id: amenity.value,
                  name: amenity.name,
                }}
                selected={isSelected}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
})

Amenities.propTypes = {
  amenities: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default Amenities
