import React from 'react';
import PropTypes from 'prop-types';
import ImageCarousel from 'components/listings/detailView/InfoPanel/components/ImageCarousel';

const buildGalleryImages = (primaryImage, secondaryImages) => {
  const galleryImages = secondaryImages.filter(image => image.id !== primaryImage.id);

  galleryImages.unshift(primaryImage);

  return galleryImages.map(image => ({
    id: image.id,
    src: image.xwide,
  }));
};

const ListingMainImage = props => {
  const {
    images,
    mainImage,
  } = props;

  const galleryImages = buildGalleryImages(mainImage, images);

  return (
    <ImageCarousel images={galleryImages} />
  );
};

ListingMainImage.propTypes = {
  mainImage: PropTypes.shape({
    xwide: PropTypes.string.isRequired,
  }).isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      xwide: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default ListingMainImage;
