import React, { PureComponent, Component, createContext } from 'react';
import axios from 'axios';
const AsyncContext = createContext(null);

// Converted to api
class AsyncProvider extends Component {
  // USES REACT CONTEXT API TO SHARE ASYNCHRONOUS DATA THROUGH MULTIPLE LEVELS
  componentDidUpdate(prevProps) {
    if (prevProps.handler && prevProps.handler !== this.props.handler) {
      prevProps.handler.source.cancel('canceling');
    }
  }

  componentWillUnmount() {
    const { handler } = this.props;
    handler && handler.source.cancel('canceling');
  }

  render() {
    const { children, handler } = this.props;
    return (
      <AsyncContext.Provider value={handler}>
        <ChildWrap>
          {children}
        </ChildWrap>
      </AsyncContext.Provider>
    );
  }
}

class ChildWrap extends PureComponent {
  // Prevent Updates
  render() {
    return ( this.props.children );
  }
}

function AsyncSubscribe(subscriptions) {
  // Higher Order Component that passes context down as props to all subscribers
  return function (WrappedComp) {
    const Consumer = function(props) {
      return(
        <AsyncContext.Consumer>
          { handler => <Emitter subscriptions={subscriptions} handler={handler} data={props} /> }
        </AsyncContext.Consumer>
      );
    };
    const Emitter = class extends Component {
      // Emits data to subscribers as it arrives asynchronously
      state = {

      }
      componentDidMount() {
        this.setSubscriptions(this.props);
      }
      // When handler context is updated, reset subscription data
      shouldComponentUpdate(nextProps) {
        if (nextProps.handler !== this.props.handler) {
          this.setSubscriptions(nextProps);
          return false;
        }
        return true;
      }
      setSubscriptions(props) {
        const { handler, subscriptions } = props;
        if (handler) {
          subscriptions.forEach(sub => {
            handler[sub].then(data => {
              this.setState({ [sub]: data });
            })
              .catch(
                err => {
                  console.log(err);
                }
              );
          });
        } else {
          subscriptions.forEach(sub => {
            this.setState({ [sub]: null })
          })
        }
      }
      // pass down subscriptions and ownprops to wrapped components
      render() {
        return <WrappedComp {...this.props.data} {...this.state} />;
      }
    };
    return Consumer;
  };
}

class __AsyncBase__ {
  constructor() {
    this._source = axios.CancelToken.source();
  }

  get source() {
    return this._source
  }
}

export { AsyncProvider, AsyncSubscribe, __AsyncBase__ };
