/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { useClickOutside } from 'hooks/useClickOutside'
import MultiContainer from 'components/GoogleMap/common/MultiContainer'

import s from './MultiMarker.module.css'

const GLOBAL_BODY_MULTILISTING_CLASSNAME = 'google-maps-multilisting'

export default function MarkerPoint({ children, markersNum }) {
  const [selected, setSelected] = useState(false)
  const divRef = useRef(null)

  useEffect(() => {
    const parentNode = divRef?.current?.parentNode
    if (!parentNode) {
      return
    }

    parentNode.style.zIndex = selected ? 1 : ''
  }, [divRef.current, selected])

  useEffect(() => {
    if (selected) {
      document.body.classList.add(GLOBAL_BODY_MULTILISTING_CLASSNAME)
    } else {
      document.body.classList.remove(GLOBAL_BODY_MULTILISTING_CLASSNAME)
    }
  }, [selected])

  const handleSelectIem = () => setSelected((state) => !state)

  useClickOutside(divRef, () => setSelected(false))

  const handleClose = () => setSelected(false)

  return (
    <div
      ref={divRef}
      role="contentinfo"
      className={clsx(s.marker, selected && s.markerSelected)}
      onClick={handleSelectIem}
    >
      <div className={s.triangle} />
      {markersNum} listings
      {selected && (
        <MultiContainer onClose={handleClose} title={`${markersNum} Listings at this address`}>
          {children}
        </MultiContainer>
      )}
    </div>
  )
}

MarkerPoint.propTypes = {
  markersNum: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}
