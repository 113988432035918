import React, { useEffect, useRef } from 'react';
import axios from 'axios';

const FrozenMap = (props) => {
  const zoomConfig = {
    // zoom: 13
    latitudeDelta: 0.07692307692,
    longitudeDelta: 0.07692307692,
  };

  const { latitude, longitude, style } = props;

  const staticMapEl = useRef(null);

  const initMap = () => {
    // check if map has been initiated
    if (!window.mapkit.maps.length) {
      window.mapkit.init({
        authorizationCallback(done) {
          axios
            .get('/apple_maps_jwt')
            .then(res => res.data)
            .then(done);
        },
        language: 'en-US',
      });
    }

    const map = new window.mapkit.Map(staticMapEl.current, {
      showsPointsOfInterest: false,
      isZoomEnabled: false,
      isScrollEnabled: false,
      showsCompass: window.mapkit.FeatureVisibility.Hidden,
      showsMapTypeControl: false,
      showsZoomControl: false,
      colorScheme: window.mapkit.Map.ColorSchemes.Dark,
    });

    map.addTileOverlay(new window.mapkit.TileOverlay(''));

    if (typeof latitude !== 'undefined' || typeof longitude !== 'undefined') {
      const currentCoordinate = new window.mapkit.Coordinate(latitude, longitude);
      const currentMarker = new window.mapkit.MarkerAnnotation(currentCoordinate, {
        enabled: false,
      });

      const zoomSpan = new window.mapkit.CoordinateSpan(
        zoomConfig.latitudeDelta,
        zoomConfig.longitudeDelta,
      );

      map.showItems([currentMarker], {
        minimumSpan: zoomSpan,
      });
    }
  };

  useEffect(() => {
    initMap();
  }, []);

  return <div style={style} className="img-fluid" ref={staticMapEl} />;
};

export default FrozenMap;
