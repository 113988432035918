import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateAgentMatches, updateSelectedAgents, setStore } from 'reducers/agentTypeahead';
import { Column, Row } from 'wrappers/FlexTable';
import DivInput from '../DivInput';
import AgentTag from './AgentTag';
import { debounce } from 'helpers/animations';
import AgentHiddenField from './AgentHiddenField';

class AgentFilter extends Component {
  shouldComponentUpdate(nextProps) {
    const {
      filter, selectedAgents, _updateAgentMatches, _setStore,
    } = this.props;
    if (filter !== nextProps.filter) {
      return true;
    }
    if (selectedAgents.length !== nextProps.selectedAgents.length) {
      _setStore({ filter: '' });
      _updateAgentMatches();
      return true;
    }
    return false;
  }

  updateAgentMatches = debounce(this.props._updateAgentMatches, 20)

  onChange = (e) => {
    const filter = e.target.value;
    const { _setStore, _updateAgentMatches } = this.props;
    _setStore({ filter });
    this.updateAgentMatches();

  }

  removeChosen = (e) => {
    const { id } = e.target.dataset;
    const { _updateSelectedAgents } = this.props;
    _updateSelectedAgents(parseInt(id));
  }

  onFocus = (e, inputDiv) => {
    inputDiv.scrollTop = inputDiv.scrollHeight;
  }

  computeContextualVariables = () => {
    const { agentType, matrixContext } = this.props;
    let agentListName, inputId;
    switch (matrixContext) {
      case 'listing':
      inputId = '_id_list';
      break;
      case 'check_request':
      inputId = 'check_agent_values';
      break;
      case 'lease':
      inputId = '_id_list';
      case 'listing_room':
      inputId = 'room_agent_values';
      break;
    }
    switch (agentType) {
      case 'salesAgent':
      agentListName = 'sales_agent_id_list';
      inputId = 'sales_agent' + inputId;
      break;
      case 'listingAgent':
      agentListName = 'listing_agent_id_list';
      inputId = 'listing_agent' + inputId;
      break;
      case 'propertyOwner':
      agentListName = 'property_owner_id_list';
      inputId = 'property_owner' + inputId;
      break;
      case 'all':
      agentListName = 'agent_id_list'
      break;
    }
    return { agentListName, inputId };
  }

  render() {
    const { selectedAgents, filter, matrixContext, agentType } = this.props;
    const placeholder = !(selectedAgents.length > 0) ? 'Search Agents' : '';
    const { agentListName, inputId } = this.computeContextualVariables();
    const inputName = `${matrixContext}_search_form[${agentListName}]`;
    return (
      <>
        <Row style={{
          backgroundColor: '#000', width: '325px', position: 'relative', placeContent: 'flex-end',
        }}
        >
          <Column style={{
            width: '50px',
            height: '38px',
            borderRight: '1px solid #404040',
            alignItems: 'center',
          }}
          >
            <i className="nookons-search" style={{ fontSize: '20px', padding: '7px' }} />
          </Column>
          <Column style={{
            width: '210px',
            height: '38px',
          }}
          >
            <DivInput
              placeholder={placeholder}
              onFocus={this.onFocus}
              className="btn-text-field btn-rounded btn-black-yellow"
              value={filter}
              onChange={this.onChange}
              style={{
                overflowY: 'auto',
                height: '38px',
                display: 'flex',
                flexWrap: 'wrap',
                position: 'relative',
                paddingTop: '7px',
                paddingLeft: '12px',
                paddingRight: '5px',
              }}
              focusClass="nothingness"
            >
              {
              selectedAgents.map(agent => (
                <AgentTag
                  key={agent.id}
                  removeChosen={this.removeChosen}
                  id={agent.id}
                  tagValue={agentType === 'propertyOwner' ? `${agent.companyName}` : `${agent.firstName} ${agent.lastName}`}
                />
              ))
          }
            </DivInput>
          </Column>
          <Column style={{
            width: '65px',
            height: '38px',
            borderLeft: '1px solid #404040',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            <p style={{ fontSize: '15px', marginBottom: '4px', color: '#ffc03a' }}>
              Search
            </p>
          </Column>
        </Row>
        <AgentHiddenField>
          <input
            type="hidden"
            value={selectedAgents.map(a => a.id)}
            name={inputName}
            id={inputId}
          />
        </AgentHiddenField>
      </>
    );
  }
}

AgentFilter.propTypes = {
  selectedAgents: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.string,
  _updateAgentMatches: PropTypes.func,
  _updateSelectedAgents: PropTypes.func,
  _setStore: PropTypes.func,
};

AgentFilter.defaultProps = {
  selectedAgents: [],
  filter: '',
  _updateAgentMatches: () => {},
  _updateSelectedAgents: () => {},
  _setStore: () => {},
};

const mapStateToProps = (state, ownProps) => {
  const { agentType } = ownProps;
  const myState = state.agentTypeahead[agentType];
  return {
    selectedAgents: myState.selectedAgents || [],
    filter: myState.filter,
    matches: myState.agentMatches,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { agentType } = ownProps;
  return {
    _updateAgentMatches: matches => dispatch(updateAgentMatches(matches, agentType)),
    _updateSelectedAgents: agent => dispatch(updateSelectedAgents(agent, agentType)),
    _setStore: payload => dispatch(setStore(payload, agentType)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgentFilter);
