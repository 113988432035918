import React from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import { submitForm } from '../../reducers/form';

const RentalAppModalContent = props => {
  const { lease, handleStartApplication } = props;
  const short_address = `${lease.address.split(',')[0]} #${lease.unit}`;

  return (
    <div className="container agreement-container">
      <h1>RENTAL APPLICATION</h1>
      <hr className="entire-width" />
      <p className="m-4">
        You have recently filled out a rental application with Nooklyn. Would
        you like to start a new rental application or transfer your current
        application to {short_address} ?
      </p>
      <button
        onClick={handleStartApplication}
        className="button btn-rounded btn-2x btn-black-yellow w-100"
      >
        Start a New Rental Application
      </button>
      <a
        href={`/leases/${lease.token}/transfer_rental_application`}
        className="button btn-rounded btn-2x btn-white-black w-100"
      >
        Transfer your current application to this lease
      </a>
    </div>
  );
};

class RentalAppModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { className, children } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <div className={className}>
        <div onClick={this.openModal}>{children}</div>
        <Modal
          className="Modal__Bootstrap modal-dialog dark-gray-container agreement-modal"
          closeTimeoutMS={150}
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
        >
          <div className="modal-content">
            <button className="close" onClick={this.closeModal}>
              <i className="nookons-close-circle-fill" />
            </button>
            <RentalAppModalContent
              {...this.props}
              handleModalClose={this.closeModal}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapState = state => ({
  submitting: state.form.submitting,
});

const mapDispatch = dispatch => ({
  submitForm() {
    dispatch(submitForm());
  },
});

export default connect(
  mapState,
  mapDispatch,
)(RentalAppModal);
