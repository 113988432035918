import React from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';
import LoadingSpinner from 'components/LoadingSpinner';
import IndividualSortResultsBy from './IndividualSortResultsBy';

const SortResultsByPop = props => {
  const {
    value,
    loading,
    onChange,
    options,
  } = props;

  const handleChange = optionSelected => {
    onChange(optionSelected);
  };

  return (
    <div className="popover sort-results-by-pop">
      <Column
        role="button"
        tabIndex={0}
        style={{
          backgroundColor: '#111',
          color: '#fff',
          willChange: 'transform',
          position: 'relative',
          padding: '0px',
          left: '0px',
        }}
      >
        <div
          className="popover-body sort-results-by-popover"
          style={{
            border: '1px solid rgb(64,64,64)',
            backgroundColor: '#111',
            color: '#fff',
            borderRadius: '0.3em',
            zIndex: '1004',
            fontSize: '12px',
          }}
        >
          <div
            className="container-fluid"
            style={{ padding: '15px' }}
          >
            <div className="tab-content">
              {(options.length && !loading)
                ? options.map(option => (
                  <IndividualSortResultsBy
                    value={value}
                    onChange={x => handleChange(x)}
                    option={option}
                    key={`sortResultsBy-${option.value}`}
                  />
                ))
                : <LoadingSpinner />}
            </div>
          </div>
        </div>
      </Column>
    </div>
  );
};

SortResultsByPop.propTypes = {
  loading: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

export default SortResultsByPop;
