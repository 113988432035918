import React from 'react';
import { connect } from 'react-redux';
import { Router } from '@reach/router';
import { setMessage } from 'reducers/flashMessage';
import ErrorBoundary from './ErrorBoundary';
import ProProvider from './ProStore';
import ListingSplitView from './ListingSplitView';

import RoomAdd from './RoomAdd';
import ListingHome from './ListingHome';
import ListingDetail from './ListingDetail';
import ListingManagePhotos from './ListingManagePhotos';
import ListingEdit from './ListingEdit';
import RoomDetail from './RoomDetail';
import RoomEdit from './RoomEdit';

const Application = props => {
  const { firstName } = props;
  const apiToken = localStorage.getItem('apiToken');

  const handleListingSubmit = () => {
    //  .then(() => {
    //    this.setState({ submitting: false });
    //    this.props.setFlashMessage(
    //      `Your ${currentListing.address.lineOne} listing has been updated.`,
    //    );
    //    // this.fetchListings();
    //  })
    //  .catch((err) => {
    //    this.setState({ submitting: false });
    //    if (err.response && err.response.data) {
    //      this.setState({ error: err.response.data.error.friendlyMessage });
    //    }
    //  });
  };

  return (
    <ErrorBoundary>
      <ProProvider apiToken={apiToken}>
        <div className="pro-listing-container">
          <Router>
            <ListingSplitView path="/pro/listings" firstName={firstName}>
              <ListingHome path="/" />
              <ListingDetail path=":listingId" />
            </ListingSplitView>
            <ListingEdit path="/pro/listings/:listingId/edit" />
            <ListingManagePhotos path="/pro/listings/:listingId/photos" />
            <RoomAdd path="/pro/listings/:listingId/new_room" />
            <RoomDetail path="/pro/listing_rooms/:roomId" />
            <RoomEdit path="/pro/listing_rooms/:roomId/edit" />
          </Router>
        </div>
      </ProProvider>
    </ErrorBoundary>
  );
};

const mapDispatchToProps = dispatch => ({
  setFlashMessage(message) {
    dispatch(setMessage(message));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(Application);

Application.defaultProps = {
  firstName: '',
};
