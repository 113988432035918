import React from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import AgreementModalContent from './AgreementModalContent';
import { submitForm, submitted } from '../../reducers/form';

class Agreement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { className, children } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <div className={className}>
        <div onClick={this.openModal}>{children}</div>
        {modalIsOpen && (
          <Modal
            className="Modal__Bootstrap modal-dialog dark-gray-container agreement-modal"
            closeTimeoutMS={150}
            isOpen={modalIsOpen}
            onRequestClose={this.closeModal}
          >
            <div className="modal-content modal-content-dark">
              <button className="close" onClick={this.closeModal}>
                <i className="nookons-close-circle-fill" />
              </button>
              <AgreementModalContent
                handleModalClose={this.closeModal}
                {...this.props}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

const mapState = state => ({
  submitting: state.form.submitting,
});

const mapDispatch = dispatch => ({
  submitForm() {
    dispatch(submitForm());
  },
  submitted() {
    dispatch(submitted());
  },
});

export default connect(
  mapState,
  mapDispatch,
)(Agreement);
