import React from 'react';
import PropTypes from 'prop-types';
import ShareButton from 'components/listings/cards/components/buttons/ShareButton';

const SocialButtons = props => {
  const { listing } = props;
  const {
    listingUrl,
    status,
  } = listing;

  const shareUrl = listingUrl;

  if (status === 'Rented') {
    return null;
  }

  return (
    <ShareButton url={shareUrl} />
  );
};

SocialButtons.propTypes = {
  listing: PropTypes.shape({
    listingUrl: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default SocialButtons;
