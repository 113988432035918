import { useContext } from 'react';
import {
  deleteRoom,
  fetchListing,
  fetchListingRooms,
  fetchRoom,
  fetchUserListings,
  invalidateListing,
  invalidateRoom,
  markRoomAsAvailable,
  markRoomAsRented,
  persistListing,
  updateListingAttribute,
} from './actions';
import context from './context';

const wrapDispatch = dispatch => ({
  deleteRoom: (...args) => {
    dispatch(deleteRoom(...args));
  },
  invalidateListing: (...args) => {
    dispatch(invalidateListing(...args));
  },
  invalidateRoom: (...args) => {
    dispatch(invalidateRoom(...args));
  },
  markRoomAsAvailable: (...args) => {
    dispatch(markRoomAsAvailable(...args));
  },
  markRoomAsRented: (...args) => {
    dispatch(markRoomAsRented(...args));
  },
  persistListing: (...args) => {
    dispatch(persistListing(...args));
  },
  updateListingAttribute: (...args) => {
    dispatch(updateListingAttribute(...args));
  },
});

const useProContext = () => {
  const {
    state,
    dispatch: rawDispatch,
    apiToken,
  } = useContext(context);

  const dispatch = wrapDispatch(rawDispatch);

  return { state, dispatch, apiToken };
};

const useListings = () => {
  const { state, dispatch } = useContext(context);

  dispatch(fetchUserListings());

  const {
    user: {
      listings: {
        isFetching = true,
        lastUpdated,
        data: listingIds = [],
      } = {},
    },
  } = state;

  if (isFetching && !lastUpdated) {
    return { isFetching, listings: [] };
  }

  const listings = listingIds.map(listingId => state.listings[listingId] || {});

  return { isFetching: false, listings };
};

const useListing = listingId => {
  const { state, dispatch } = useContext(context);

  if (listingId === null || typeof listingId === 'undefined') {
    return { isFetching: false, dataLoaded: false, listing: {} };
  }

  dispatch(fetchListing(listingId));

  const {
    listings: {
      [listingId]: {
        isFetching = true,
        lastUpdated,
        data: listing = {},
      } = {},
    },
  } = state;

  return { isFetching, listing, dataLoaded: !!lastUpdated };
};

const useRoom = roomId => {
  const { state, dispatch } = useContext(context);

  if (roomId === null || typeof roomId === 'undefined') {
    return { isFetching: false, dataLoaded: false, room: {} };
  }

  dispatch(fetchRoom(roomId));

  const {
    rooms: {
      [roomId]: {
        isFetching = true,
        lastUpdated,
        data: room = {},
      } = {},
    },
  } = state;

  return { isFetching, room, dataLoaded: !!lastUpdated };
};

const useRooms = listingId => {
  const { state, dispatch } = useContext(context);

  dispatch(fetchListingRooms(listingId));

  const {
    listings: {
      [listingId]: {
        rooms: {
          isFetching = true,
          lastUpdated,
          data: roomIds = []
        } = {},
      } = {},
    },
  } = state;

  if (isFetching && !lastUpdated) {
    return { isFetching, rooms: [] }
  }

  const rooms = roomIds.map(roomId => state.rooms[roomId] || {});

  return { isFetching: false, rooms };
};

export {
  useListing,
  useListings,
  useProContext,
  useRoom,
  useRooms,
};
