import React, { useEffect, useState } from 'react';
import { Redirect } from '@reach/router';
import SearchPage from 'components/listings/pages/SearchPage';
import validSearch from './validSearch.json';

const UniqueLinkSearchHandler = props => {
  // Annoying ESlint not liking how we pull reach router data
  // eslint-disable-next-line react/destructuring-assignment
  const uri = props['*'];

  // States for Data Management
  const [result, setResult] = useState(null);

  // States for Page Loading Management
  const [loading, setLoading] = useState(true);
  const [found, setFound] = useState(false);

  // Checking validSearch json for matching keywords
  const checkForSpecialURL = url => {
    const foundLink = validSearch.find(value => value.url === url);

    if (foundLink !== undefined) {
      const data = {};

      foundLink.data.forEach(linkDataSegment => {
        data[linkDataSegment.dataType] = linkDataSegment.value;
      });

      return data;
    }
    return undefined;
  };

  // Grab URL Segments from Router
  useEffect(() => {
    if (uri !== null) {
      const searchResult = checkForSpecialURL(uri);

      if (searchResult) {
        setResult(searchResult);
        setFound(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [uri]);

  // If still loading, show loading screen
  if (loading) {
    return (
      <div className="App">
        <h1>Loading</h1>
      </div>
    );
  }

  if (found && result) {
    return (<SearchPage initData={result} type="listings" />);
  }

  return (<Redirect to="/404" />);
};

export default UniqueLinkSearchHandler;
