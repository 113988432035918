import React from 'react';

const ApartmentDetails = (props) => {
  const { listing } = props;

  return (
    <form className="summary-form">
      <div className="row submit-info-row">
        <div className="col">
          <h6 className="d-inline-block ml-1">Apartment Details</h6>
          <span data-toggle="collapse" data-target="#apartmentDetails" className="float-right pt-2">
            <i className="nklyn-2x nookons-caret-down-fill" />
          </span>
        </div>
      </div>
      <div className="border-box submit-collapse-box striped-box collapse" id="apartmentDetails">
        <div className="row">
          <div className="col">
            <label className="m-1">Address</label>
            <span className="float-right m-1">{listing.address.full_address}</span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="m-1">Unit</label>
            <span className="float-right m-1">{listing.address.unit}</span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="m-1">Monthly Rent</label>
            <span className="float-right m-1">
              {accounting.formatMoney(listing.price / 100, { precision: 0 })}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="m-1">Move-In Costs</label>
            <span className="float-right m-1">{listing.moveInCosts.join(', ')}</span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="m-1">Included Utilities</label>
            <span className="float-right m-1">{listing.utilities.join(', ')}</span>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ApartmentDetails;
