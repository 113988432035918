import React from 'react';
import PropTypes from 'prop-types';
import { Column, Row } from 'wrappers/FlexTable';
import CustomCheckBox from 'components/CustomCheckBox';
import LoadingSpinner from 'components/LoadingSpinner';

const PetsFilter = props => {
  const {
    selected,
    onChange,
    options,
  } = props;

  const handleClick = option => {
    onChange(option);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.currentTarget.click();
    }
  };

  return (
    <Column>
      <hr />
      <div style={{
        margin: '24px 0px',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '19px',
        color: '#fff',
      }}
      >
        Pet Policy
      </div>
      <div className="tab-content">
        {options.length
          ? options.map(
            option => (
              <Row
                key={`pet-${option.value}`}
                style={{
                  border: '0px',
                  width: '100%',
                  marginTop: '14px',
                  color: '#fff',
                  fontSize: '13px',
                  fontWeight: '500',
                }}
                onClick={() => handleClick(option.value)}
                onKeyPress={handleKeyPress}
              >
                <Column
                  className={selected === option.value ? 'paint-layer bed-div bed-div-selected' : ' paint-layer bed-div'}
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                >
                  <CustomCheckBox
                    checked={selected === option.value}
                    boxClass="matrix-checkbox"
                    boxcheckedClass="matrix-checkbox-checked"
                    checkmarkClass="checkmark"
                  >
                    <Column
                      style={{
                        color: '#fff',
                        fontSize: '13px',
                        fontWeight: '500',
                        paddingLeft: '10px',
                      }}
                    >
                      {option.name}
                    </Column>
                  </CustomCheckBox>
                </Column>
              </Row>
            ),
          )
          : <LoadingSpinner />}
      </div>
    </Column>
  );
};

PetsFilter.propTypes = {
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

export default PetsFilter;
