import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isCurrency } from 'helpers/util';
import Modal from './Modal';

class ModalContent extends Component {
  state = {
    error: '',
    disabled: true,
    payment_amount: '',
    payment_method: '',
    envelope_front: '',
    check_front: '',
    check_back: '',
    office_id: '',
    tx_id: '',
    apiError: '',
    offices: [],
    submitting: false,
  };

  componentDidMount() {
    axios
      .get('/offices.json')
      .then((res) => {
        this.setState({ offices: res.data });
      });
  }

  handleSubmit = (evt) => {
    evt.preventDefault();
    this.setState({ submitting: true });

    const { lease_id, client_id } = this.props;
    let {
      payment_amount,
      payment_method,
      office_id,
      envelope_front,
      check_front,
      check_back,
    } = this.state;
    payment_amount = Math.ceil(payment_amount * 100);
    const data = new FormData();
    data.append('lease_id', lease_id);
    data.append('client_id', client_id);
    data.append('payment_amount', payment_amount);
    data.append('payment_method', payment_method);
    data.append('office_id', office_id);
    data.append('envelope_front', envelope_front, envelope_front.name);
    if (payment_method === 'Bank Check') {
      data.append('check_front', check_front, check_front.name);
      data.append('check_back', check_back, check_back.name);
    }

    axios
      .post('/api/v1/leases.record_deposit', data, {
        headers: {
          API_TOKEN: localStorage.getItem('apiToken'),
        },
      })
      .then((res) => {
        this.setState({ tx_id: res.data.transaction.id, submitting: false });
      })
      .catch((err) => {
        this.setState({ submitting: false });
        if (err.response && err.response.data) {
          this.setState({ apiError: err.response.data.error.friendlyMessage });
        }
      });
  };

  handleAmountChange = (evt) => {
    const cleanedInput = parseFloat(evt.target.value.replace(/[^\d\.\-]/g, ''));
    if (Number.isNaN(cleanedInput) || cleanedInput === 0 || !isCurrency(cleanedInput)) {
      this.setState({
        error: 'amount must be a valid currency amount',
        disabled: true,
        payment_amount: cleanedInput,
      });
    } else {
      this.setState({
        error: '',
        disabled: false,
        payment_amount: cleanedInput,
      });
    }
  };

  handleTypeChange = (evt) => {
    evt.preventDefault();
    this.setState({ payment_method: evt.target.value });
  };

  handleOfficeChange = (evt) => {
    evt.preventDefault();
    this.setState({ office_id: evt.target.value });
  };

  handleEnvelopeFrontChange = (evt) => {
    evt.preventDefault();
    this.setState({ envelope_front: evt.target.files[0] });
  };

  handleCheckFrontChange = (evt) => {
    evt.preventDefault();
    this.setState({ check_front: evt.target.files[0] });
  };

  handleCheckBackChange = (evt) => {
    evt.preventDefault();
    this.setState({ check_back: evt.target.files[0] });
  };

  render() {
    const { client_name, handleModalCloseRequest } = this.props;
    const {
      error,
      disabled,
      payment_amount,
      payment_method,
      check_front,
      check_back,
      envelope_front,
      office_id,
      tx_id,
      apiError,
      offices,
      submitting,
    } = this.state;

    return (
      <div className="container invite-container">
        {tx_id ? (
          <h1>Deposit Completed for {client_name} </h1>
        ) : (
          <h1>Record a deposit for {client_name} </h1>
        )}
        <hr className="entire-width" />
        {tx_id ? (
          <div>
            <p>
              Please write the lease transaction number below on the deposit
              envelope:
            </p>
            <h4 className="text-center m-4">
              <b>{tx_id}</b>
            </h4>
            <button
              type="button"
              className="button btn-rounded btn-yellow-black btn-2x"
              onClick={handleModalCloseRequest}
            >
              Ok, I've Recorded the Transaction Number
            </button>
          </div>
        ) : (
          <form onSubmit={this.handleSubmit}>
            {apiError && <p className="error text-center">{apiError}</p>}
            <label>Deposit Amount</label>
            <input
              placeholder="2000"
              className="general-text-field mb-3"
              type="number"
              value={payment_amount}
              onChange={this.handleAmountChange}
            />
            {error && <p className="error">{String(error)}</p>}
            <label>Payment Method</label>
            <select
              className="custom-select mb-3"
              onChange={this.handleTypeChange}
              value={payment_method}
            >
              <option value="">Select a type</option>
              <option value="Cash">Cash</option>
              <option value="Bank Check">Bank Check</option>
              <option value="Money Order">Money Order</option>
            </select>
            <label>Office</label>
            <select
              className="custom-select mb-3"
              onChange={this.handleOfficeChange}
              value={office_id}
            >
              <option value="">Select an office</option>
              {offices &&
                offices.length &&
                offices.map(office => (
                  <option key={office.id} value={office.id}>
                    {office.name}
                  </option>
                ))}
            </select>
            <div>
              {payment_method && (
              <>
                <hr />
                <h5 className="text-center">
                  Fields required for {payment_method}
                </h5>
                <label>Envelope Image (front)</label>
                <br />
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  name="envelopeFront"
                  className="mb-3"
                  onChange={this.handleEnvelopeFrontChange}
                />
              </>
              )}
                <br />
                {payment_method === 'Bank Check' && (
                  <>
                    <label>Check Image (front)</label>
                    <br />
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, application/pdf"
                      name="checkBack"
                      className="mb-3"
                      onChange={this.handleCheckFrontChange}
                    />
                    <br />
                    <label>Check Image (back)</label>
                    <br />
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, application/pdf"
                      name="checkBack"
                      className="mb-3"
                      onChange={this.handleCheckBackChange}
                    />
                  </>
                )}
                <br />
              </div>
            <input
              type="submit"
              name="commit"
              value="Submit"
              className="button btn-black-white btn-rounded mt-3"
              disabled={
                submitting ||
                !payment_amount ||
                !payment_method ||
                !envelope_front ||
                disabled ||
                (payment_method == 'Bank Check' && (!check_back || !check_front))
              }
            />
          </form>
        )}
      </div>
    );
  }
}

ModalContent.propTypes = {
  lease_id: PropTypes.string.isRequired,
  client_id: PropTypes.number.isRequired,
  client_name: PropTypes.string.isRequired,
  handleModalCloseRequest: PropTypes.func.isRequired,
};

export default class RecordDepositModal extends Component {
  state = {
    modalIsOpen: false,
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleModalCloseRequest = () => {
    this.setState({ modalIsOpen: false });
    window.location.reload();
  };

  render() {
    const { className, children } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <div className={className}>
        <div onClick={this.openModal}>{children}</div>
        <Modal
          className="Modal__Bootstrap modal-dialog dark-gray-container invite-modal"
          closeTimeoutMS={150}
          isOpen={modalIsOpen}
        >
          <div className="modal-content modal-content-dark">
            <button type="button" className="close" onClick={this.closeModal}>
              <i className="nookons-close-circle-fill" />
            </button>
            <ModalContent
              {...this.props}
              handleModalCloseRequest={this.handleModalCloseRequest}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
