import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BaseInput from './BaseInput';
import userImg from 'images/icons/svg/user.svg';

const FullNameInput = props => {
  const {
    initialValue,
    onChange,
    error,
  } = props;

  return (
    <>
      <BaseInput
        initialValue={initialValue}
        onChange={onChange}
        inputType='text'
        inputName='full_name'
        labelText='Full Name'
        inputImg={userImg}
        error={error}
      />
    </>
  );
};

FullNameInput.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

FullNameInput.defaultProps = {
  initialValue: '',
  error: false,
};

export default FullNameInput;
