import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, navigate, useParams } from '@reach/router';
import LoadingSpinner from 'components/LoadingSpinner';
import Dropzone from 'react-dropzone';
import { useProContext, useRoom } from './ProStore';

const RoomEdit = (props) => {
  const { dispatch, apiToken } = useProContext();
  const { roomId } = useParams();

  const { dataLoaded, room } = useRoom(roomId);

  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [price, setPrice] = useState();
  const [photoUrl, setPhotoUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setName(room.name);
    setDescription(room.description);
    setPrice(room.price / 100);
  }, [dataLoaded]);

  const handleRoomSubmit = evt => {
    evt.preventDefault();
    setSubmitting(true);

    axios.post(
      `/api/v2/rooms.edit?room_id=${room.id}`,
      {
        name,
        description,
        price,
      },
      {
        headers: { API_TOKEN: apiToken },
      },
    ).then(res => {
      if (photoUrl) {
        return axios.post(
          '/api/v2/rooms.images.upload',
          {
            room_id: room.id,
            url: photoUrl,
          },
          { headers: { API_TOKEN: localStorage.getItem('apiToken') } },
        );
      }
      return res;
    }).then(() => {
      dispatch.invalidateRoom(roomId);
      navigate(`/pro/listing_rooms/${room.id}`);
      setSubmitting(false);
    });
  };

  const handleFileDrop = acceptedFiles => {
    if (!acceptedFiles.length) {
      return;
    }
    const acceptedFile = acceptedFiles[0]; // file can only be uploaded one at a time
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFile);

    reader.onload = evt => {
      setPhotoUrl(evt.target.result);
      setPreviewUrl(URL.createObjectURL(acceptedFile));
    };
  };

  return dataLoaded ? (
    <>
      <div className="pro-header" style={{ cursor: 'pointer' }}>
        <Link to={`/pro/listing_rooms/${roomId}`}>
          <span className="pro-header-back">
            <i className="nookons-chevron-left nklyn-3x align-middle" />
            <span className="align-middle">Back</span>
          </span>
        </Link>
        <div className="pro-nav">
          <span onClick={handleRoomSubmit}>
            <i className="nookons-check-circle nkyln-2x" />
            { submitting ? <LoadingSpinner /> : 'Save' }
          </span>
        </div>
      </div>
      <div className="pro-container">
        <div className="summary-container">
          <div className="apartment-container">
            <div className="row no-gutters apartment-container-header">
              <div className="col-11">
                Room Details
              </div>
              <div className="show-hide col-1 text-right">
              </div>
            </div>
            <form className="summary-form apartment-container-content">
              <div className="row">
                <label className="col-3">Name</label>
                <input
                  className="edit-room-input"
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>
              <div className="row">
                <label className="col-3">Price</label>
                <input
                  className="edit-room-input"
                  type="text"
                  value={window.accounting.formatMoney(price, { precision: 0, format: "%v" })}
                  onChange={e => setPrice(e.target.value)}
                />
              </div>
              <div className="row">
                <label className="col-3">Description</label>
                <textarea
                  className="description-textarea col-9 general-text-area-dark"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </div>
              <div className="row">
                <label className="col-3">Cover Image</label>
                <div className="w-100">
                  <Dropzone onDrop={acceptedFiles => handleFileDrop(acceptedFiles)} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} style={{ width: '100%' }}>
                        <input {...getInputProps()} />
                        <div className="photo-upload-container">
                          <i className="nklyn-3x nookons-camera" />
                          <p>Drop photos here or click the camera.</p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="preview-container">
                    {previewUrl && (
                      <div className="preview-card">
                        <i
                          className="nookons-close nklyn-2x m-0"
                          onClick={() => {
                            setPhotoUrl('');
                            setPreviewUrl('');
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              setPhotoUrl('');
                              setPreviewUrl('');
                            }
                          }}
                        />
                        <img src={previewUrl} className="img-fluid" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  ) : (
    <h1>Loading</h1>
  );
};

export default RoomEdit;
