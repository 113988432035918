import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CSSTransition } from 'react-transition-group';
import { ButtonStepper } from './Buttons';

const title = "That's it for photos.";

function reorder(list, startIdx, endIdx) {
  const [removed] = list.splice(startIdx, 1);
  list.splice(endIdx, 0, removed);
  return list;
}

const PhotoSort = (props) => {
  const [photoArr, setPhotoArr] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const source = axios.CancelToken.source();

  const sortImage = (evt) => {
    setSubmitting(true);
    evt.preventDefault();

    if (photoArr.length) {
      axios
        .post(
          '/api/v2/listings.images.sort',
          {
            listing_id: props.listingId,
            sort_order: photoArr.map(photo => photo.id),
          },
          { headers: { API_TOKEN: localStorage.getItem('apiToken') } },
          { cancelToken: source.token },
        )
        .then((res) => {
          setSubmitting(false);
          props.handlePhotoSubmit({ ok: res.data.ok });
        });
    } else {
      props.handlePhotoSubmit({ noPhoto: false });
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    setPhotoArr(reorder(photoArr, result.source.index, result.destination.index));
  };

  useEffect(() => {
    axios
      .get(`/api/v2/listings.images?listing_id=${props.listingId}`, { cancelToken: source.token })
      .then((res) => {
        setPhotoArr(res.data.images);
      });
    return () => {
      source.cancel('Operation canceled');
    };
  }, []);

  useEffect(() => {
    document.title = `Create: Photos - ${title}`;
  }, []);

  return (
    <CSSTransition in appear timeout={300} classNames="stepper">
      <div>
        <div className="title">
          <h3>{title}</h3>
          <p>Drag &apos;em around to re-sort them.</p>
        </div>
        <form className="photo-sort-container">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div
                  {...provided.droppableProps}
                  className="droppable-container"
                  ref={provided.innerRef}
                >
                  {photoArr.map((photo, index) => (
                    <Draggable key={photo.id} draggableId={photo.id} index={index}>
                      {provided => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <img key={photo.id} src={photo.wide} className="img-fluid" />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {!!photoArr.length && (
            <div className="primary-label">
              <label className="button btn-rounded btn-black-white">primary</label>
            </div>
          )}
          <ButtonStepper
            handleNext={evt => sortImage(evt)}
            handleBack={props.handleBack}
            submitting={submitting}
          />
        </form>
      </div>
    </CSSTransition>
  );
};

export default PhotoSort;
