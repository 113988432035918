import axios from 'axios';
import keysToCamel, { keysToSnake } from './util';

// API call for fetching photos for a roommate
// Param(s):
// roommateId - Roommate IDs for roommate we want to fetch photos for
export const fetchPhotos = async roommateId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const imageUrl = `/api/v1/mates.photos?mate_id=${roommateId}`;

  return axios.get(imageUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(photosResult => photosResult.data.mate_photos)
    .then(photos => keysToCamel(photos));
};

// API call for fetching favorites for roommate
// Param(s):
// roommateId - Roommate IDs for roommate we want to fetch favorites for
export const fetchFavorites = async userId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const favoriteUrl = `/api/v2/roommates.favorites?user_id=${userId}`;

  return axios.get(favoriteUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.roommates)
    .then(roommates => keysToCamel(roommates));
};

// API call for fetching a roommate
// Param(s):
// roommateId - Roommate IDs for roommate we want to fetch
const fetchRoommate = async roommateId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const roommateUrl = `/api/v2/roommates.fetch?mate_id=${roommateId}`;

  return axios.get(roommateUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.agent)
    .then(agent => keysToCamel(agent));
};

// API call to pull up list of roommates that match searchParams
// Param(s):
// searchParams (Search Params Object holding filters for search):
// {
//  amenityList: Array of amenities,
//  address: Address String,
//  minBaths: Number of Minimum Bathrooms,
//  bedList: Array of Number of Bedrooms in a Listing,
//  liked: Boolean to see LikedOnly Listings,
//  marketAs: String for Listing Type To Filter,
//  moveIn: String for Move In Data,
//  noFee: Boolean to see NoFee Listings,
//  order: String for Sort By Filter,
//  pets: String for Pets Filter,
//  platformListing: Boolean to see SubletOnly Listings,
//  minPpsf: Number of Minimum Price per Square Foot,
//  maxPpsf: Number of Maximum Price per Square Foot,
//  minPrice: Number of Minimum Price,
//  maxPrice: Number of Maximum Price,
//  minSquareFeet: Number of Minimum Square Feet,
//  maxSquareFeet: Number of Maximum Square Feet,
//  network: String of Network Type for Listings to see,
//  page: Number of page number of search results to pull,
//  neighborhoodIdList: Array of Neighborhood Ids to see listings from,
//  regionIdList: Array of Region Ids to see listings from,
//  subwayIdList: Array of Subway Ids to see listings near to,
//  subwayStopList: Array of Subway Ids to see listings near to,,
//  topLeft: Array of Coordinates for Top Left of the Map,
//  bottomRight: Array of Coordinates for Bottom Right of the Map,
// }
export const searchRoommates = async searchParams => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const roommateUrl = '/api/v2/roommates.search';
  const formattedSearchParams = keysToSnake(searchParams);

  return axios.post(
    roommateUrl,
    formattedSearchParams,
    { headers: { API_TOKEN: apiToken }, cancelToken },
  )
    .catch(err => ({
      okay: false,
      err,
    }));
};

export default fetchRoommate;
