import React from 'react';
import PropTypes from 'prop-types';
import Recaptcha from 'react-google-recaptcha';

// Props:
// --------------------------------------------------
// onSubmit: Callback Function on Submit (Required)

const CaptchaConfirmation = props => {
  const {
    onSubmit,
  } = props;

  const recaptchaRef = React.createRef();

  const submitCaptcha = () => {
    onSubmit(recaptchaRef.current.getValue());
  };

  return (
    <>
      <h3>Please complete this captcha to continue.</h3>
      <div className="tour-notes">Just need to check if you are not a robot like me</div>
      <br />
      <Recaptcha
        ref={recaptchaRef}
        theme="dark"
        sitekey={process.env.RECAPTCHA_SITE_KEY}
        onChange={submitCaptcha}
      />
    </>
  );
};

CaptchaConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CaptchaConfirmation;
