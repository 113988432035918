import React from 'react'
import { Row } from 'wrappers/FlexTable'
import { AsyncProvider } from 'asyncHandlers/AsyncBase'
import SearchPopoverAsync from 'asyncHandlers/SearchPopoverAsync'
import ListingSearchBarButtons from 'components/listingSearchComponents/ListingSearchBarButtons'
import SearchPopoverDataFetching from 'components/listingSearchComponents/SearchPopoverDataFetching'

const ListingSearchBar = () => (
  <AsyncProvider handler={new SearchPopoverAsync()}>
    <Row className="col-sm-12 nklyn-listing-search-bar">
      <ListingSearchBarButtons />
      <SearchPopoverDataFetching />
    </Row>
  </AsyncProvider>
)

export default ListingSearchBar
