import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Column } from 'wrappers/FlexTable';
import { addPopoverRef, removePopoverRef } from 'reducers/search';
import reposition from '../searchPopovers/reposition';
import HoodPop from '../searchPopovers/HoodPop';
import HoodButton from '../searchbarButtons/HoodButton';
import RenderOutsideContainer from './RenderOutsideContainer';

class Hood extends Component {
  repositionPopover = reposition.bind(this)

  constructor(props) {
    super(props);
    this.references = {
      popoverWrapper: React.createRef(),
      popoverContainer: React.createRef(),
      popover: React.createRef(),
    };
  }

  componentDidMount() {
    // this.repositionPopover();
    const { _addPopoverRef } = this.props;
    _addPopoverRef(this);
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    const { _removePopoverRef } = this.props;
    _removePopoverRef(this);
  }

  render() {
    const { searchContext } = this.props;
    const { references: { popoverWrapper, popoverContainer, popover } } = this;
    return (
      <Column
        ref={popoverContainer}
        className="searchbar-col"
      >
        <RenderOutsideContainer>
          <HoodPop
            ref={{
              popoverWrapper,
              popover,
            }}
            searchContext={searchContext}
            displayProp="hoodDisplay"
          />
        </RenderOutsideContainer>
        <HoodButton />
      </Column>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  _addPopoverRef: payload => dispatch(addPopoverRef(payload)),
  _removePopoverRef: payload => dispatch(removePopoverRef(payload)),
});

Hood.propTypes = {
  searchContext: PropTypes.string.isRequired,
  _addPopoverRef: PropTypes.func.isRequired,
  _removePopoverRef: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Hood);
