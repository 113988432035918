import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';
import PpsfPop from '../searchPopovers/Ppsf';
import PpsfButton from '../searchbarButtons/PpsfButton';
import RenderOutsideContainer from './RenderOutsideContainer';
import Reposition from './Reposition';

class Ppsf extends Component {
  render() {
    const {
      popoverWrapper,
      popoverContainer,
      popover,
      searchContext,
    } = this.props;
    return (
      <Column
        ref={popoverContainer}
        className="searchbar-col"
      >
        <RenderOutsideContainer>
          <PpsfPop
            ref={{
              popoverWrapper,
              popover,
            }}
            searchContext={searchContext}
            displayProp="ppsfDisplay"
          />
        </RenderOutsideContainer>
        <PpsfButton />
      </Column>
    );
  }
}

Ppsf.defaultProps = {
  popoverWrapper: null,
  popoverContainer: null,
  popover: null,
};

Ppsf.propTypes = {
  popoverWrapper: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popoverContainer: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popover: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  searchContext: PropTypes.string.isRequired,
};

export default Reposition(Ppsf);
