import React from 'react';
import { useGlobalState } from 'components/listRoom/customEffects';

const Photos = () => {
  const [globalState] = useGlobalState();
  const { images } = globalState;

  return (
    <form className="summary-form">
      <div className="row submit-info-row">
        <div className="col">
          <h6 className="d-inline-block ml-1">
            Photos
            {images.length ? ` ( ${images.length} uploaded )` : ''}
          </h6>
          <span data-toggle="collapse" data-target="#photos" className="float-right pt-2">
            <i className="nklyn-2x nookons-caret-down-fill" />
          </span>
        </div>
      </div>
      <div className="border-box submit-collapse-box collapse" id="photos">
        {images.map(image => (
          <div className="row" key={image.id}>
            <img src={image.wide} className="img-fluid" />
          </div>
        ))}
      </div>
    </form>
  );
};

Photos.defaultProps = {
  images: [],
};

export default Photos;
