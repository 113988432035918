import styled from 'styled-components';

export const SimpleSearchStyles = styled.div`
  position: relative;
  input {
    /* Positioning */
    /* Box-model */
    display: inline-block;
    width: 100%;
    padding: 10px;
    /* Typography */
    word-wrap: break-word;
    white-space: normal;
    /* Visual */
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
    outline: none;
    /* Misc */
    cursor: text;
  }
`;

export const DropDownMenu = styled.ul`
  /* Positioning */
  position: absolute;
  left: 0;
  z-index: 11;
  /* Box-model */
  width: 100%;
  max-height: 20rem;
  padding: 0 !important;
  margin: 0;
  /* margin-top: 5px; */
  overflow-y: auto;
  overflow-x: hidden;
  /* Typography */
  /* Visual */
  background-color: #fff;
  border: 1px solid rgba(34, 36, 38, 0.35);
  border: ${props => (props.isOpen ? null : 'none')};
  border-radius: 0 0 0.28571429rem 0.28571429rem;
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  outline: 0;
  /* Misc */
  cursor: auto;
`;

export const DropDownItem = styled.div`
  /* Positioning */
  position: relative;
  /* Box-model */
  display: block;
  height: auto;
  padding: 0.5rem 1.1rem;
  /* Typography */
  line-height: 1em;
  color: rgba(0, 0, 0, 0.87);
  white-space: normal;
  word-wrap: normal;
  /* Visual */
  border-top: 1px solid #fafafa;
  box-shadow: none;
  /* Misc */
  cursor: pointer;
  color: ${props => {
    if (props.isActive) {
      return 'rgba(0, 0, 0, 0.95)';
    }
    if (props.isSelected) {
      return 'rgba(0, 0, 0, 0.95)';
    }
    return '';
  }};
  background: ${props => {
    if (props.isActive) {
      return 'rgba(0, 0, 0, 0.05)';
    }
    if (props.isSelected) {
      return 'rgba(0, 0, 0, 0.03)';
    }
    return '';
  }};
  font-weight: ${props => (props.isSelected ? 'bold' : '')};
  img {
    display: inline-block;
    vertical-align: top;
    width: auto;
    margin-top: -0.5em;
    margin-bottom: -0.5em;
    max-height: 2em;
    border-radius: 500rem;
    margin-right: 0.8em;
  }
  span {
    font-size: 12px;
  }
  &:hover,
  &:active,
  &:focus {
    background-color: #303030;
  }
`;

export const ControllerButton = styled.button`
  /* Positioning */
  position: absolute;
  right: 0;
  top: 0;
  /* Box-model */
  width: 47;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Typography */
  /* Visual */
  border: none;
  background-color: transparent;
  /* Misc */
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
`;
