import React, { useState, useEffect } from 'react';
import { ButtonSave, ButtonStepper } from '../Buttons';
import ContainerWrapper from '../ContainerWrapper';

const title = 'What are the amenities?';

const Amenities = (props) => {
  const amenityOptions = [
    'Balcony',
    'Bike storage',
    'Central A/C',
    'Common courtyard',
    'Dishwasher',
    'Doorman',
    'Duplex',
    'Elevator',
    'Gym',
    'Laundry in building',
    'Natural Light in Living Area',
    'Office area',
    'Outdoor Space',
    'Patio',
    'Private backyard',
    'Private parking',
    'Private roof deck',
    'Public Wifi',
    'Roof access',
    'Shared backyard',
    'Shared workspace',
    'Storage space',
    'Terrace',
    'Waterfront view from roof',
    'Waterfront view from unit',
    'Washer dryer hookup',
    'Washer dryer installed',
    'Wheelchair Accessible',
  ];
  const {
    amenities, setAmenities, userIsEditing, setUserIsEditing,
  } = props;
  const [searchOptions, setSearchOptions] = useState(amenityOptions);

  const handleChange = (amenity) => {
    if (amenities.includes(amenity)) {
      setAmenities(amenities.filter(u => u !== amenity));
    } else {
      setAmenities([amenity, ...amenities]);
    }
  };

  const handleSearch = (evt) => {
    const searchTerm = evt.target.value;
    if (searchTerm) {
      const searchRegex = new RegExp(searchTerm, 'i');
      setSearchOptions(amenityOptions.filter(option => searchRegex.test(option)));
    } else {
      setSearchOptions(amenityOptions);
    }
  };

  useEffect(() => {
    document.title = `Create: Apartments - ${title}`;
  }, []);

  return (
    <ContainerWrapper>
      <div>
        <div className="title">
          <h3>{title}</h3>
          <p>Tell us all about it?</p>
        </div>
        <form
          className="narrow"
          onKeyDown={(evt) => {
            if (evt.which === 13) evt.preventDefault();
          }}
        >
          <i className="nklyn-2x nookons-search" />
          <input
            className="btn-rounded btn-transparent btn-search-bar-size text-center mb-3"
            placeholder="search"
            onChange={handleSearch}
          />
          <div className="checkbox-container">
            {searchOptions.map(amenity => (
              <div key={amenity} className="checkbox-row" onClick={() => handleChange(amenity)}>
                <label className={amenities.includes(amenity) ? 'active' : ''}>
                  {amenity}
                  {amenities.includes(amenity) && (
                    <i className="nklyn-3x nookons-check-circle active" />
                  )}
                </label>
                <input type="checkbox" className="float-right" />
              </div>
            ))}
          </div>
          {userIsEditing ? (
            <ButtonSave
              handleJump={props.handleJump}
              setUserIsEditing={setUserIsEditing}
              jumpTo={props.jumpTo || "ApartmentReview"}
            />
          ) : (
            <ButtonStepper handleNext={props.handleNext} handleBack={props.handleBack} />
          )}
        </form>
      </div>
    </ContainerWrapper>
  );
};

export default Amenities;
