import React, { useState, Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Modal from '../../modal/Modal';
import { submitForm, submitted } from '../../../reducers/form';

class ListingPackageModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      rentType: 'month',
      netEffectiveRent: props.price,
      leaseTerm: 12,
      rentConcession: 0,
      apiError: '',
      submitting: false,
    };
  }

  handleSubmit = evt => {
    evt.preventDefault();
    this.setState({ submitting: true });

    const { id: listing_id } = this.props;
    const { leaseTerm: lease_term, rentType: unit_type, rentConcession: unit_free } = this.state;

    axios
      .post('/api/v2/listings.add_package', {
        listing_id,
        lease_term,
        unit_free,
        unit_type,
      }, {
        headers: {
          API_TOKEN: localStorage.getItem('apiToken'),
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch(err => {
        this.setState({ submitting: false });
        if (err.response && err.response.data) {
          this.setState({ apiError: err.response.data.error.friendlyMessage });
        }
      });
  };

  handleChange = evt => {
    evt.preventDefault();
    this.setState({ [evt.target.name]: evt.target.value }, () => {
      this.calculateNetEffectiveRent();
    });
  }

  calculateNetEffectiveRent = () => {
    const { leaseTerm, rentType, rentConcession } = this.state;
    const { price: monthlyLegalRent } = this.props;

    const annualLegalRent = +monthlyLegalRent * +leaseTerm;
    let concession = 0;
    if (rentType === 'dollar') {
      concession = +rentConcession;
    } else if (rentType === 'week') {
      concession = (+monthlyLegalRent * +rentConcession) / 4;
    } else if (rentType === 'month') {
      concession = +monthlyLegalRent * +rentConcession;
    }
    const calculatedNetEffectiveRent = (+annualLegalRent - +concession) / +leaseTerm;

    this.setState({ netEffectiveRent: calculatedNetEffectiveRent });
  }

  render() {
    const { price } = this.props;
    const {
      error,
      leaseTerm,
      rentType,
      rentConcession,
      apiError,
      submitting,
      netEffectiveRent,
    } = this.state;

    return (
      <div className="container invite-container">
        <h1>Concession</h1>
        <hr className="entire-width" />
        <form onSubmit={this.handleSubmit}>
          {apiError && <p className="error text-center">{apiError}</p>}
          <label>Legal Rent</label>
          <div className="mb-3">
            <div className="general-text-field" style={{ borderColor: '#404040'}}>{accounting.formatMoney(price, { precision: 0 })}</div>
            <div className="general-text-field-append"> per month</div>
          </div>
          <label>Rent Type</label>
          <div>
            <select
              className="custom-select mb-3"
              onChange={this.handleChange}
              name="rentType"
              value={rentType}
              required
            >
              <option value="">Select a rent type</option>
              <option value="month">Monthly</option>
              <option value="week">Weekly</option>
              <option value="dollar">Dollars</option>
            </select>
          </div>
          <label>Lease Term</label>
          <div>
            <input
              placeholder="12"
              className="general-text-field mb-3"
              type="number"
              value={leaseTerm}
              name="leaseTerm"
              onChange={this.handleChange}
              required
            />
            <div className="general-text-field-append"> months</div>
          </div>
          <label>Rent Concession</label>
          <div>
            <input
              placeholder="12"
              className="general-text-field mb-3"
              type="number"
              name="rentConcession"
              value={rentConcession}
              onChange={this.handleChange}
              required
            />
            <div className="general-text-field-append"> rent-free {rentType}s</div>
          </div>
          <label>Net Effective Rent</label>
          <div className="mb-3">
            <div className="general-text-field" style={{ borderColor: '#404040'}}>{accounting.formatMoney(netEffectiveRent, { precision: 0 })}</div>
            <div className="general-text-field-append"> per month</div>
          </div>
          <input
            type="submit"
            className="button btn-black-white btn-rounded mt-3"
            disabled={submitting}
          />
        </form>
      </div>
    );
  }
}

const ListingPackageModal = (props) => {
  const { children } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => { setModalOpen(false); };
  return (
    <>
      <div onClick={openModal}>{children}</div>
      <Modal
        className="Modal__Bootstrap modal-dialog dark-gray-container invite-modal listing-package-modal"
        closeTimeoutMS={150}
        isOpen={modalOpen}
      >
        <div className="modal-content modal-content-dark">
          <button type="button" className="close" onClick={closeModal}>
            <i className="nookons-close-circle-fill" />
          </button>
          <ListingPackageModalContent {...props} />
        </div>
      </Modal>
    </>
  );
};

ListingPackageModal.defaultProps = {
  signedIn: true,
};

const mapState = state => ({
  submitting: state.form.submitting,
});

const mapDispatch = dispatch => ({
  submitForm() {
    dispatch(submitForm());
  },
  submitted() {
    dispatch(submitted());
  },
});

export default connect(
  mapState,
  mapDispatch,
)(ListingPackageModal);
