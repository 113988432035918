import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class SubwayStopsButton extends Component {

  componentDidMount() {
    this.node.addEventListener('click', (e) => {
      e.stopPropagation();
    });
    this.node.addEventListener('mouseup', (e) => {
      e.stopPropagation();
    });
  }

  render() {

    const { togglePopover, selectedSubwayStops } = this.props;
    const value = selectedSubwayStops.length > 0 ? `Subway: (${selectedSubwayStops.length})` : 'Subway';
    const className = selectedSubwayStops.length > 0 ? 'btn-transparent-yellow' : 'btn-transparent-gray';
    return (
      <input
        ref={me => this.node = me}
        name='subwayStops'
        onMouseDown={togglePopover}
        type='button'
        className={`button btn-rounded ${className} btn-search-bar-size`}
        value={value}/>
    );
  }
}

const mapStateToProps = (state) =>{
  return ({
    selectedSubwayStops: state.search[state.search.context].selectedSubwayStops
  });
};

const mapDispatchToProps = (dispatch) => ({
  togglePopover: (e) => dispatch(togglePopover(e))
});

export default connect(mapStateToProps, mapDispatchToProps)(SubwayStopsButton);
