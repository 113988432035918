import { getMetaContent } from 'helpers/util';
import { __AsyncBase__ } from './AsyncBase';
import axios from 'axios';

export default class ChangeStatusAsync extends __AsyncBase__ {
  constructor(context) {
    super()
    this._context = context;
  }

  changeStatus = e => {
    const that = this._context;
    e.persist();
    let oldState = that.state.status;
    that.setState({
      status: e.target.value,
    });
    let newStatus = e.target.value;

    const { id: roomId } = that.props;

    axios.post(
      `/matrix/rooms/${roomId}/change_status.json`,
      {
        listing_room: {
          status: newStatus,
          room: that.props.roomId,
        },
        authenticity_token: getMetaContent('csrf-token'),
      },
      { cancelToken: this._source.token }
    )
      .catch(()=> that.setState({ status: oldState }));
  };
}
