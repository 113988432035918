import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateActiveSearch, generateNewSearch, clearSinglePopover, toggleAllFalse } from '../../../reducers/search';
// import { Row, Column } from 'wrappers/FlexTable';

class ApplyClear extends Component {
  clear = () => {
    this.props.clearSinglePopover(this.props.name);
    this.props.generateNewSearch();
    this.props.updateActiveSearch();
  }

  onApply = () => {
    this.props.toggleAllFalse();
    this.props.updateActiveSearch();
  }

  render() {
    const { style, children } = this.props;

    return (
      <div className="searchbar-apply-clear" style={{ ...style, padding: '0 10px' }}>
        <div className="clear-button" onClick={this.clear}>Clear</div>
        { children }
        <div className="apply-button" onClick={this.onApply}>Apply</div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateActiveSearch: () => dispatch(updateActiveSearch()),
  generateNewSearch: () => dispatch(generateNewSearch()),
  clearSinglePopover: name => dispatch(clearSinglePopover(name)),
  toggleAllFalse: () => dispatch(toggleAllFalse()),
});

export default connect(null, mapDispatchToProps)(ApplyClear);
