import React from 'react';
import { CSSTransition } from 'react-transition-group';

const ContainerWrapper = props => (
  <div className="dark-mega-container apply-dark-container">
    <div className={`${props.className ? props.className : ''} container nklyn-apply nklyn-apply-renter`}>
      <CSSTransition in={props.entered} timeout={300} classNames="stepper">
        {props.children}
      </CSSTransition>
    </div>
  </div>
);

export default ContainerWrapper;
