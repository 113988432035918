import React from 'react';
import { connect } from 'react-redux';
import { setMessage } from 'reducers/flashMessage';
import Fade from 'wrappers/Fade';
import detailIcon from 'images/icons/pro/edit-details@2x.png';
import viewListingIcon from 'images/icons/pro/view-listing@2x.png';
import statusIcon from 'images/icons/pro/mark-as-rented@2x.png';
import { Link, navigate } from '@reach/router';
import { useProContext } from 'pro/ProStore';

const ManageRoomPopover = (props) => {
  const { dispatch } = useProContext();

  const {
    room: {
      id: roomId,
      status: roomStatus,
      listingId,
    },
    display,
    onClick,
    popover,
    popoverWrapper,
    setFlashMessage,
  } = props;

  const deleteRoom = () => {
    dispatch.deleteRoom(roomId, setFlashMessage);
    navigate(`/pro/listings/${listingId}`);
  };

  const markRoomAsAvailable = () => {
    dispatch.markRoomAsAvailable(roomId, setFlashMessage);
  };

  const markRoomAsRented = () => {
    dispatch.markRoomAsRented(roomId, setFlashMessage);
  };

  const renderStatus = (status) => {
    switch (status) {
      case 'Rented':
        return (
          <a onClick={markRoomAsAvailable}>
            <img src={statusIcon} className="img-fluid mx-auto p-2" />
            Mark as Available
          </a>
        );
      case 'Available':
        return (
          <a onClick={markRoomAsRented}>
            <img src={statusIcon} className="img-fluid mx-auto p-2" />
              Mark as Rented
          </a>
        );
      case 'Draft':
        return (
          <a onClick={deleteRoom}>
            <img src={statusIcon} className="img-fluid mx-auto p-2" />
              Delete draft
          </a>
        );
      default:
        return (
          <a onClick={deleteRoom} style={{ opacity: '0.3' }}>
            <img src={statusIcon} className="img-fluid mx-auto p-2" style={{ opacity: '0.3' }} />
            {status}
          </a>
        );
    }
  };

  return (
    <div ref={popoverWrapper} className="popover show">
      <Fade fadeStart="expand-start" fadeEnd="expand-end" visible={display} customStyles={{ position: 'relative', right: '200px' }}>
        <div onClick={onClick} className="popover fade show">
          <i className="nookons-caret-up-fill" />
          <div
            ref={popover}
            className="popover matrix-pop dark-popover matrix-agent-popover"
            role="tooltip"
          >
            <div className="arrow" />
            <div className="popover-body">
              <div className="row no-gutters pro-popover-body">
                <div className="col-6">
                  {renderStatus(roomStatus)}
                </div>
                <div className="col-6">
                  <Link to={`/pro/listing_rooms/${roomId}/edit`} style={{ cursor: 'pointer' }}>
                    <img src={detailIcon} className="img-fluid mx-auto p-2" />
                    Edit
                  </Link>
                </div>
                <div className="col-6">
                  <a href={`/listing_rooms/${roomId}`}>
                    <img src={viewListingIcon} className="img-fluid mx-auto p-2" />
                    View Listing
                  </a>
                </div>
              </div>
            </div>
            <div />
          </div>
        </div>
      </Fade>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  setFlashMessage(message) {
    dispatch(setMessage(message));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(ManageRoomPopover);
