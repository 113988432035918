import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BaseInput from './BaseInput';
import emailImg from 'images/icons/svg/email.svg';

const EmailInput = props => {
  const {
    initialValue,
    onChange,
    error,
  } = props;

  return (
    <>
      <BaseInput
        initialValue={initialValue}
        onChange={onChange}
        inputType='email'
        inputName='email'
        labelText='Email Address'
        inputImg={emailImg}
        error={error}
      />
    </>
  );
};

EmailInput.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

EmailInput.defaultProps = {
  initialValue: '',
  error: false,
};

export default EmailInput;
