/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import s from './ClusterPoint.module.css'

function ClusterPoint({ pointCount, size, onClick }) {
  return (
    <div
      className={s.root}
      style={{
        width: `${10 + (pointCount / size) * 20}px`,
        height: `${10 + (pointCount / size) * 20}px`,
      }}
      onClick={onClick}
    >
      {pointCount}
    </div>
  )
}

ClusterPoint.propTypes = {
  pointCount: PropTypes.number,
  size: PropTypes.number,
  onClick: PropTypes.func,
}

ClusterPoint.defaultProps = {
  pointCount: null,
  size: null,
  onClick: () => {},
}

export default ClusterPoint
