import { useReducer, useContext, createContext } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'setStore':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

export const stateContext = createContext(null);

function checkForObject(obj, objectName) {
  if (typeof obj !== 'object') throw new Error(`${objectName} may only be passed an object`);
}

function isFunction(obj) {
  return typeof obj === 'function';
}

export function useGlobalState() {
  const [state, dispatch] = useContext(stateContext);

  function setStore(payload) {
    if (isFunction(payload)) return setStore(payload(state));
    checkForObject(payload);
    return dispatch({ type: 'setStore', payload });
  }

  return [state, setStore];
}

export function createReducer(initialState) {
  return useReducer(reducer, initialState);
}
