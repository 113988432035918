import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const InterestedMates = props => {
  const {
    residential,
    roommates,
  } = props;

  const [showRoomates, setShowRoomates] = useState(false);

  useEffect(() => {
    setShowRoomates(residential && roommates && roommates.length > 0);
  }, [residential, roommates]);

  if (!showRoomates) {
    return null;
  }

  return (
    <>
      <hr />
      <h5>Interested Roommates</h5>
      <ul className="list-unstyled list-inline">
        {roommates.map(mate => (
          <li key={mate.id} className="interested-mate-item list-inline-item">
            <a target="_blank" rel="noreferrer" href={`/agents/${mate.id}`}>
              <img className="rounded-circle img-fluid rounded-circle mx-auto" src={mate.avatarThumb} alt={`Profile Pic for Interested Roomate ${mate.firstName}`} />
              <h6>{mate.firstName}</h6>
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

InterestedMates.propTypes = {
  residential: PropTypes.bool.isRequired,
  roommates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    avatarThumb: PropTypes.string,
    firstName: PropTypes.string,
  })).isRequired,
};

export default InterestedMates;
