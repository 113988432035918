import React, { useEffect, useState } from 'react';
import { Row } from 'wrappers/FlexTable';
import LoadingSpinner from 'components/LoadingSpinner';
import loggedIn from 'helpers/loggedIn';
import { fetchUser } from 'components/renterForms/api';

const shareDropdown = props => {
  const { url } = props;
  const clipboard = new Clipboard('#share-dropdown-clipboard');
  const [shareUrl, setShareUrl] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const endOfUrl = url.replace('https://nooklyn.com', '');

    loggedIn()
      .then(res => {
        if (res === false) {
          setShareUrl(`https://nooklyn.com${endOfUrl}`.toLowerCase());
          setLoading(false);
        } else {
          fetchUser()
            .then(user => {
              const loggedInAgent = user.data.current;
              if (loggedInAgent.account_type === 'nooklyn_agent') {
                const paramsToShare = loggedInAgent.agent_id ? `?sales-agent=${loggedInAgent.agent_id}` : '';
                setShareUrl(`https://nooklyn.com${endOfUrl}${paramsToShare}`.toLowerCase());
                setLoading(false);
              } else {
                setShareUrl(`https://nooklyn.com${endOfUrl}`.toLowerCase());
                setLoading(false);
              }
            });
        }
      });
  }, [url]);

  const sp = e => {
    e.stopPropagation();
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.currentTarget.click();
    }
  };

  return (
      (loading === false)
        ? (
          <>
            <div
              onClick={sp}
              id="share-dropdown-clipboard"
              data-original-title="Copied"
              data-clipboard-text={shareUrl}
              className=" listing-collection-item"
              role="button"
              onKeyPress={handleKeyPress}
              tabIndex={0}
              title="Copy Link To Clipboard"
              style={{ cursor: 'copy' }}
            >
              <Row className="item">
                Copy to Clipboard
              </Row>
            </div>
            <a
              onClick={sp}
              target="_blank"
              rel="noreferrer"
              href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
            >
              <Row className="item">
                Share to Facebook
              </Row>
            </a>
            <a
              onClick={sp}
              target="_blank"
              rel="noreferrer"
              href={`https://twitter.com/intent/tweet?button_hashtag=nooklyn&amp;text=${shareUrl}`}
            >
              <Row className="item">
                Share to Twitter
              </Row>
            </a>
          </>
      )
      : (<LoadingSpinner />)
  );
};

export default shareDropdown;
