import React, { Component } from 'react';
import { getMetaContent } from 'helpers/util';
import axios from 'axios';
import AutofillTagForm from '../AutofillTagField/AutofillTagField';

export default class SubwayStopAutofill extends Component {
  source = axios.CancelToken.source();

  state = {
    stops: null,
    ids: null,
    prefill: [],
  }

  componentWillUnmount() {
    this.source.cancel('api call cancelled as component will unmount');
  }

  componentDidMount() {
    axios.get('/subway_stops_json.json', {
      authenticity_token: getMetaContent('csrf-token'),
      cancelToken: this.source.token,
    })
      .then(res => res.data)
      .then(subwayStops => {
        const stops = subwayStops.map(stop => ({ id: stop.id, name: `${stop.name} (${stop.lines.join(', ')})` }));
        let prefillstops;
        if (this.props.prefill) {
          // array of stop names
          const { prefill } = this.props;
          prefillstops = prefill.split(',').map(option => {
            const stop = subwayStops.find(stop => stop.id === option);
            return `${stop.name} (${stop.lines.join(', ')})`;
          });
        }
        this.setState({
          stops,
          prefill: prefillstops || [],
        });
      });
  }

  finalOption = (stops, ids) => ids;

  render() {
    const { stops, prefill } = this.state;
    return (
      <div>
        {stops ? (
          <AutofillTagForm
            prefill={prefill}
            options={stops}
            finalOption={this.finalOption}
            label="stations-dropdown"
            title="Nearby Stations"
            placeholder="Myrtle Ave (J/Z express, M), Morgan Ave (L)"
            fieldName={this.props.field_name}
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
}
