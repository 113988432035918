import React, { Component } from 'react';
import DraggableTag from './DraggableTag';
import ImmobileTag from './ImmobileTag';

export default class DraggableTags extends Component {

  state = {
    chosen: this.props.chosen,
    dragging: false,
    dragged: null,
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.chosen !== this.props.chosen) {
      this.setState({ chosen: nextProps.chosen });
    }
    return true;
  }

  shiftChosen = (targetIndex) => {
    const { chosen, draggedIndex } = this.state;
    let newChosen = new Array(...chosen);
    let realTargetIndex = targetIndex;
    if (targetIndex >= newChosen.length - 1) {
      realTargetIndex = newChosen.length - 1;
    } else if (targetIndex <= 0) {
      realTargetIndex = 0;
    }
    if (realTargetIndex !== draggedIndex) {
      newChosen.splice(realTargetIndex, 0, newChosen.splice(draggedIndex, 1)[0]);
      this.setState({
        chosen: newChosen,
        draggedIndex: realTargetIndex,
      });
    }
  }

  dragTagDown = (e) => {
    e.preventDefault();
    let el = e.target.parentElement;
    // el.style.visibility = 'hidden'
    let option = el.dataset.option;
    let index = el.dataset.key;
    let height = this.refs[option].getBoundingClientRect().height;
    let left = this.refs[option].parentElement.getBoundingClientRect().right / 50;
    let offset = height * (this.state.chosen.length - index);
    if (this.state.chosen.length > 1) {
      el.style.opacity = 0.5;
      this.setState({
        dragging: true,
        dragged: el,
        draggedOption: el.dataset.option,
        initialDraggedIndex: el.dataset.key,
        draggedIndex: el.dataset.key,
        startPosition: this.refs[option].getBoundingClientRect().top,
        offset: offset,
        height: height,
        left: left,
      });
    }
  }

  mouseUp = () => {
    this.state.dragged.style.opacity = 1;
    this.setState({ dragging: false, dragged: null });
  }

  render () {
    const {
      removeChosen,
      fieldName,
      finalOption
    } = this.props;
    const {
      chosen,
      draggedIndex,
      draggedOption,
      initialDraggedIndex,
      startPosition,
      dragging,
      offset,
      height,
      left } = this.state;
    const ids = chosen.map(choice => this.props.ids[choice]);
    return (
      <div id='tags' ref='me'>
        { !dragging ?
          chosen.map((option, index) =>
            <div key={option} ref={option}>
              <ImmobileTag
                id={index}
                removeChosen={removeChosen}
                dragTagDown={this.dragTagDown}
                option={option}
                 />
            </div>) : chosen.map((option, index) =>
          { const hide = draggedIndex === index ? 0.5 : 1;
            return <div key={option} ref={ option } style={{ opacity:`${hide}` }}>
              <ImmobileTag
                id={index}
                removeChosen={removeChosen}
                dragTagDown={this.dragTagDown}
                option={option}
                 />
            </div>
          })
        }
        {dragging ?
          <DraggableTag
            shiftChosen={this.shiftChosen}
            option={draggedOption}
            index={initialDraggedIndex}
            mouseUp={this.mouseUp}
            startPosition={startPosition}
            offset={offset}
            height={height}
            key='draggedTag'
            left={left} /> : ''}
        <input name={fieldName} type='hidden' value={finalOption(chosen, ids)}>
        </input>
      </div>
    );
  }
}
