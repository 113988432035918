import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Modal from '../modal/Modal';
import { VerifyIdentity, PhoneTextSent, StateIdApproved } from './Subway/IdVerificationFlow';
import VerifyPhotoId from './Subway/PhotoIdVerification';
import VerifyRealEstateId from './Subway/RealEstateLicenseVerification';

const ModalContent = props => {
  const [activeStep, setActiveStep] = useState(0);
  const apiToken = localStorage.getItem('apiToken');
  const steps = ['VerifyIdentity', 'VerifyPhotoId', 'PhoneTextSent', 'StateIdApproved', 'VerifyRealEstateId', 'RealEstateIdReceived', 'RealEstateIdApproved'];
  const [photoIdStatus, setPhotoIdStatus] = useState(props.state_id_status);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [realEstateLicenseStatus, setRealEstateLicenseStatus] = useState(props.real_estate_license_status);
  const { id: agentId, handleModalCloseRequest: closeModal } = props;
  const cancelSource = axios.CancelToken.source();

  useEffect(() => {
    axios
      .get('/api/v2/current.fetch', {
        headers: { API_TOKEN: localStorage.getItem('apiToken') },
        cancelToken: cancelSource.token,
      })
      .then(res => res.data)
      .then(({ current }) => {
        let relStatus = '';
        if (current.rel_verified) {
          relStatus = 'completed';
        } else if (current.rel_submitted) {
          relStatus = 'pending';
        }
        setPhotoIdStatus(current.verified === true ? 'completed' : '');
        setRealEstateLicenseStatus(relStatus);
      });
  }, []);

  const getStepContent = step => {
    switch (step) {
      case 'VerifyIdentity':
        return (
          <VerifyIdentity
            photoIdStatus={photoIdStatus}
            setPhotoIdStatus={setPhotoIdStatus}
            realEstateLicenseStatus={realEstateLicenseStatus}
            handleVerifyPhotoId={() => setActiveStep(steps.indexOf('VerifyPhotoId'))}
            handleVerifyRealEstateId={() => setActiveStep(steps.indexOf('VerifyRealEstateId'))}
          />
        );
      case 'VerifyPhotoId':
        return (
          <VerifyPhotoId
            agentId={agentId}
            apiToken={apiToken}
            handlePhontTextSent={() => setActiveStep(steps.indexOf('PhoneTextSent'))}
            handleStateIdApproved={() => setActiveStep(steps.indexOf('StateIdApproved'))}
            setPhotoIdStatus={setPhotoIdStatus}
            setPhoneNumber={setPhoneNumber}
          />
        );
      case 'PhoneTextSent':
        return (
          <PhoneTextSent
            phoneNumber={phoneNumber}
            handleVerifyIdentity={() => setActiveStep(steps.indexOf('VerifyIdentity'))}
            handleVerifyRealEstateId={() => setActiveStep(steps.indexOf('VerifyRealEstateId'))}
          />
        );
      case 'StateIdApproved':
        return (
          <StateIdApproved
            closeModal={closeModal}
            handleVerifyIdentity={() => setActiveStep(steps.indexOf('VerifyIdentity'))}
          />
        );
      case 'VerifyRealEstateId':
        return (
          <VerifyRealEstateId
            agentId={agentId}
            handleVerifyIdentity={() => setActiveStep(steps.indexOf('VerifyIdentity'))}
            setRealEstateLicenseStatus={setRealEstateLicenseStatus}
          />
        );
      default:
        return (
          <VerifyIdentity />
        );
    }
  };

  return (
    <div>
      {getStepContent(steps[activeStep])}
    </div>
  );
};

const IdVerification = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = evt => {
    evt.preventDefault();
    setModalIsOpen(true);
  };

  const closeModal = evt => {
    evt.preventDefault();
    setModalIsOpen(false);
  };

  const handleModalCloseRequest = () => {
    setModalIsOpen(false);
    window.location.reload();
  };

  return (
    <div style={{ display: 'inline' }}>
      <a href="#" role="button" onClick={openModal} onKeyDown={openModal}>
        <i className="nookons-chevron-right nklyn-2x" />
      </a>
      {modalIsOpen && (
      <Modal
        className="Modal__Bootstrap modal-dialog dark-gray-container invite-modal"
        closeTimeoutMS={150}
        isOpen={modalIsOpen}
      >
        <div className="modal-content modal-content-dark">
          <button className="close" onClick={closeModal}>
            <i className="nookons-close-circle-fill" />
          </button>
          <ModalContent {...props} handleModalCloseRequest={handleModalCloseRequest} />
        </div>
      </Modal>
      )}
    </div>
  );
};

IdVerification.propTypes = {
  id: PropTypes.number.isRequired,
};

IdVerification.defaultProps = {
  state_id_status: false,
  real_estate_license_status: false,
};

export default IdVerification;
