import React, { Component } from 'react';
import Fade from 'wrappers/Fade';
import { connect } from 'react-redux';

class ScreenOverlay extends Component {

  state = {
    fadeShow: false,
    show: false,
  }

  unsubscribeFromTransition = () => {
    window.removeEventListener('transitionend', this.callback);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.unsubscribeFromTransition();
      if (nextProps.show) {
        this.setState({
          fadeShow: true,
          show: true
        });
      } else {
        this.setState({
          fadeShow: false
        });
        this.callback = (e) => {
          if (e.target.id === 'popover-overlay') {
            this.setState({
              show: false,
            });
            this.unsubscribeFromTransition();
          }
        };
        window.addEventListener('transitionend', this.callback);
      }
    }
    return true;
  }

  componentWillUnmount() {
    this.unsubscribeFromTransition();
  }

  render() {
    const { fadeShow, show } = this.state;

    return (show && <Fade
      id="popover-overlay"
      customStyles = {{
        width: '100%',
        height: '100%',
        backgroundColor: '#111',
        position: 'fixed',
        zIndex: 100,
      }}
      ref={me => this.node = me}
      fadeStart='overlay-start'
      fadeEnd='overlay-end'
      visible={fadeShow}
    ></Fade>);
  }
}

const mapStateToProps = state => ({
  show: state.search[state.search.context].aPopoverIsOpen,
});

export default connect(mapStateToProps)(ScreenOverlay);
