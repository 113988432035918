import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Fade from 'wrappers/Fade';
import { connect } from 'react-redux';
import { setStore } from 'reducers/search';
import {
  HoodFilter,
  HoodList,
} from './hoodComponents/index';
import ApplyClear from './ApplyClear';

const HoodPop = forwardRef((props, ref) => {
  const { popover, popoverWrapper } = ref;
  const onMouseDown = (e) => {
    e.stopPropagation();
  };

  const {
    display,
    searchContext,
    selectedHoods,
  } = props;
  return (
    <div
      style={{ position: 'fixed', width: 0, border: 'none' }}
      className={display ? 'popover hoodpop' : 'popover hoodpop hide'}
      ref={popoverWrapper}
    >
      <Fade
        fadeStart="expand-start"
        fadeEnd="expand-end"
        visible={display}
        customStyles={{
          position: 'relative',
          top: '2px',
          right: '145px',
          flexWrap: 'wrap',
        }}
      >
        <div
          role="button"
          tabIndex={0}
          onMouseDown={onMouseDown}
          className="popover fade show"
          style={{
            backgroundColor: '#111',
            color: '#fff',
            willChange: 'transform',
            position: 'relative',
          }}
        >
          <div
            ref={popover}
            className="popover-body hood-popover"
            style={{
              position: 'relative',
              border: '1px solid rgb(64,64,64)',
              backgroundColor: '#111',
              color: '#fff',
              borderRadius: '21px',
              zIndex: '1004',
              fontSize: '12px',
            }}
          >
            <div
              style={{
                backgroundColor: '#111',
                color: '#fff',
              }}
              className="container-fluid"
            >
              <div
                style={{ paddingTop: '20px' }}
                className="tab-content"
              >
                <HoodFilter />
                <br />
                <HoodList />
              </div>
            </div>
            {searchContext === 'searchbar' && (
              <ApplyClear name="selectedHoods">
                <span>{!!selectedHoods.length && `${selectedHoods.length} Selected`}</span>
              </ApplyClear>
            )}
          </div>
        </div>
      </Fade>
    </div>
  );
});

const mapStateToProps = (state, ownProps) => {
  const display = state.search[state.search.context][ownProps.displayProp];
  return {
    display: display || false,
    selectedHoods: state.search[state.search.context].selectedHoods,
  };
};

const mapDispatchToProps = dispatch => ({
  setStore: payload => dispatch(setStore(payload)),
});

HoodPop.propTypes = {
  display: PropTypes.bool.isRequired,
  searchContext: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps, mapDispatchToProps, null, { forwardRef: true },
)(HoodPop);
