import React from 'react';
import { navigate, useMatch } from '@reach/router';
import apartmentIcon from 'images/icons/pro/apartment@3x.png';

const SidebarRow = props => {
  const {
    listing: {
      isFetching = true,
      data: listing = {}
    },
  } = props;

  const match = useMatch('/pro/listings/:listingId') || {};
  const { listingId } = match;

  const selected = listing.id === listingId;

  return isFetching ? (
    <h1>Loading</h1>
  ) : (
    <div
      className={`listing-sidebar ${selected ? 'active' : ''}`}
      onClick={() => navigate(`/pro/listings/${listing.id}`)}
    >
      <div className="sidebar-wrapper row">
        <div className="col-10">
          <div className="sidebar-icon">
            <img src={apartmentIcon} className="img-fluid mx-auto" />
            <span>Apartment</span>
          </div>
          <div className="nklyn-pro-sb-address" style={{ fontSize: '16px' }}>{listing.address.lineOne}</div>
        </div>
        <div className="col-2 mt-3">
          <i className="nklyn-3x nookons-chevron-right" />
        </div>
      </div>
    </div>
  );
};

export default SidebarRow;
