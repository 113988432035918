import axios from 'axios';
import { __AsyncBase__ } from './AsyncBase';

// Converted to api
export default class AgentListingsAsync extends __AsyncBase__ {
  constructor(listingIds) {
    super();
    this._listingIds = listingIds;
    this._token = localStorage.getItem('apiToken');
  }

  get listings() {
    if (!this._listings) {
      this._listings = this.fetchListings();
    }
    return this._listings;
  }

  fetchListings = () => axios
    .get(`/api/v2/listings.list?listing_ids=${this._listingIds}`, {
      headers: { API_TOKEN: this._token },
      cancelToken: this._source.token,
    })
    .then(res => res.data.listings)
}
