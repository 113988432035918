import React from 'react';
import PropTypes from 'prop-types';
import CustomCheckBox from 'components/CustomCheckBox';
import { Column } from 'wrappers/FlexTable';

const SearchThisArea = (props) => {
  const { onMouseDown } = props;
  return (
    <Column
      className="disable-select search-this-area"
      onMouseDown={onMouseDown}
    >
      <CustomCheckBox
        {...props}
        type="checkbox"
      />
    </Column>
  );
};

SearchThisArea.defaultProps = {
  checked: false,
  boxClass: 'map-checkbox fill-nklyn-cream',
  boxcheckedClass: 'map-checkbox fill-nklyn-blue',
  checkmarkClass: 'checkmark-white',
  children: (
    <Column
      className="search-this-area-content"
    >
      Search This Area
    </Column>),
};

SearchThisArea.propTypes = {
  checked: PropTypes.bool,
  onMouseDown: PropTypes.func.isRequired,
  boxClass: PropTypes.string,
  boxcheckedClass: PropTypes.string,
  checkmarkClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default SearchThisArea;
