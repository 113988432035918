import React from 'react';
import MembershipTypeModal from './MembershipTypeModal';

const MembershipTypeButton = props => {
  const { content } = props;

  const renderContent = () => {
    switch (content) {
      case 'ComparePlans':
        return <ComparePlansButton />;
      case 'ReviewContinue':
        return <ReviewContinueButton />;
      default:
        return '';
    }
  };

  return (
    <MembershipTypeModal {...props}>
      {content && renderContent()}
    </MembershipTypeModal>
  );
};

export default MembershipTypeButton;

const ComparePlansButton = () => (
  <div className="button btn-transparent-yellow btn-rounded">
    Compare Plans <i className="nookons-chevron-right" />
  </div>
);

const ReviewContinueButton = () => (
  <i className="nookons-chevron-right nklyn-2x" style={{color: '#ffc03a'}}/>
);
