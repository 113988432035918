/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'
import clsx from 'clsx'
import { appleResidentialImageMarker, appleCurrentImageMarker } from 'helpers/mapHelpers'
import { setSelectedItem, setStore } from 'reducers/search'
import { useClickOutside } from 'hooks/useClickOutside'

import { mapSelectedAndMarkerSelector } from 'selectors/map'
import s from './MarkerPoint.module.css'

export default function MarkerPoint({ marker, children }) {
  const divRef = useRef(null)
  const { selectedItem } = useSelector(mapSelectedAndMarkerSelector)
  const dispatch = useDispatch()
  const handleSelectIem = useCallback(() => dispatch(setSelectedItem(marker)), [dispatch, marker])
  // eslint-disable-next-line react/prop-types
  const isSelected = selectedItem?.id === marker?.id

  useEffect(() => {
    const parentNode = divRef?.current?.parentNode
    if (!parentNode) {
      return
    }

    parentNode.style.zIndex = isSelected ? 1 : ''
  }, [divRef.current, isSelected])

  useClickOutside(divRef, () => dispatch(setStore({ selectedItem: null })))

  return (
    <div
      ref={divRef}
      role="contentinfo"
      className={clsx(s.marker, marker.price && s.markerPrice, isSelected && s.markerSelected)}
      onClick={handleSelectIem}
    >
      {marker.price ? (
        <>
          <div className={s.triangle} />
          {window.accounting.formatMoney(marker.price / 100, { precision: 0 })}
        </>
      ) : (
        <img
          alt="map-point"
          className={s.icon}
          src={isSelected ? appleCurrentImageMarker['2'] : appleResidentialImageMarker['2']}
        />
      )}

      {isSelected && children}
    </div>
  )
}

MarkerPoint.propTypes = {
  marker: PropTypes.shape({
    price: PropTypes.number,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

MarkerPoint.defaultProps = {
  marker: null,
}
