import React, { Component } from 'react';

export default class ImmobileTag extends Component {

  render() {
    const { id, option, dragTagDown, removeChosen } = this.props;
    return (
      <div
        data-key={id}
        data-option={option}
        className='tag-container'
        style={{ position: 'relative' }}
      >
        <input
          onMouseEnter={this.onHover}
          onMouseDown={(e) => dragTagDown(e)}
          onTouchStart={(e) => dragTagDown(e)}
          type='button'
          className='option-tag'
          value={option}
          readOnly>
        </input>
        <button
          onClick={removeChosen}
          key={`${option}-x`}
          style={{ position: 'relative' }}
          className='round-x'>x
        </button>
      </div>
    );
  }
}
