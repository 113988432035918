import React, { Component } from 'react';
import { Column } from 'wrappers/FlexTable';
import { connect } from 'react-redux';
import { updateSelectedSubways, generateNewSearch } from 'reducers/search';

class IndividualBath extends Component {

  onSelect = (e) => {
    e.preventDefault();
    this.props.updateSelectedSubways(this.props.subway);
    this.props.generateNewSearch();
  }

  render () {
    const { subway, selected } = this.props;
    const opacitySettings = selected ? 'subway-selected' : 'subway-opacity';
    return (
      <Column
        className={`subway-lines ${opacitySettings}`}
        onMouseDown={this.onSelect}
        style={{ width: '10%', cursor: 'pointer', userSelect: 'none', margin: '5px 8px' }}>
        <img
          style={{ width: '30px' }}
          src={require(`images/subway/${subway}.png`)}
          srcSet={`${require(`images/subway/2x/${subway}.png`)} 1x, ${require(`images/subway/2x/${subway}.png`)} 2x`} 
        />
      </Column>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selected = state.search[state.search.context].selectedSubways.includes(ownProps.subway);
  return {
    selected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSelectedSubways: (payload) => dispatch(updateSelectedSubways(payload)),
  generateNewSearch: () => dispatch(generateNewSearch())
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualBath);
