import React, { Component } from 'react';
import { Row } from 'wrappers/FlexTable';

export default class HoodTag extends Component {
  render() {
    const { name, id, removeChosen, type } = this.props;
    return (
      <Row
        tabIndex='0'
        style={{
          position: 'relative',
          color: '#000',
          backgroundColor: 'rgb(255, 192, 58)',
          opacity: '1',
          height: '25px',
          padding: '0px',
          margin: '2px',
          zIndex: '10'
        }}
      >
        <input
          onFocus={(e) => e.stopPropagation()}
          type='button'
          className='option-tag'
          style={{
            backgroundColor: 'rgb(255, 192, 58)',
            opacity: '1',
            color: '#000'
          }}
          value={name}
          readOnly>
        </input>
        <button
          onFocus={(e) => e.stopPropagation()}
          data-name={name}
          data-id={id}
          data-type={type}
          style={{
            cursor: 'pointer',
            backgroundColor: 'rgb(255, 192, 58)',
            opacity: '1',
            color :'#000',
            position: 'relative',
            outline: 'none',
            border: '0px',
            lineHeight: '2.5px'
          }}
          onClick={removeChosen}
        >x
        </button>
      </Row>
    );
  }
}
