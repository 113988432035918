import React from 'react';
import { Column } from 'wrappers/FlexTable';
import CopyUrlButton from 'components/CopyUrlButton';
import PropTypes from 'prop-types';

const CopyBtn = props => {
  const {
    loading,
  } = props;

  return (
    <Column className="searchbar-col">
      {loading
        ? (
          <CopyUrlButton>
            <div
              className="button btn-transparent btn-block btn-rounded btn-block btn-search-bar-size"
            >
              Copy URL
            </div>
          </CopyUrlButton>
        )
        : (
          <div
            className="button btn-transparent btn-block btn-rounded btn-block btn-search-bar-size"
          >
            Copy URL
          </div>
        )}
    </Column>
  );
};

CopyBtn.defaultProps = {
  loading: false,
};

CopyBtn.propTypes = {
  loading: PropTypes.bool,
};

export default CopyBtn;
