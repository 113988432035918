import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Column, Row } from 'wrappers/FlexTable';
import Fade from 'wrappers/Fade';
import { connect } from 'react-redux';
import { MinSquareFeet, MaxSquareFeet, SquareFeetCheckBox } from './squareFeetComponents/index';
import ApplyClear from './ApplyClear';

const SquareFeetPop = forwardRef((props, { popover, popoverWrapper }) => {
  const onMouseDown = e => {
    e.stopPropagation();
  };

  const { display, searchContext } = props;
  const choices = [
    {
      value: 'Under 500sf',
      min: '0',
      max: '500',
    },
    {
      value: '500 - 1000',
      min: '500',
      max: '1000',
    },
    {
      value: '1000 - 1500',
      min: '1000',
      max: '1500',
    },
    {
      value: '1500sf +',
      min: '1500',
      max: '',
    },
  ];

  return (
    <div
      className={display ? 'popover square-feet-pop' : 'popover square-feet-pop hide'}
      style={{ position: 'fixed', border: 'none' }}
      ref={popoverWrapper}
    >
      <Fade
        fadeStart="expand-start"
        fadeEnd="expand-end"
        visible={display}
        customStyles={{
          position: 'relative',
          top: '2px',
          right: '180px',
          backgroundColor: 'rgb(17,17,17)',
          flexWrap: 'wrap',
        }}
      >
        <Column
          ref={popover}
          style={{
            border: '1px solid rgb(64,64,64)',
            padding: '5px',
            height: '250px',
            position: 'absolute',
            backgroundColor: 'rgb(17,17,17)',
          }}
          role="button"
          tabIndex={0}
          onMouseDown={onMouseDown}
          className="squareFeet"
        >
          <Row style={{ borderRight: '0px' }}>
            <MinSquareFeet />
            <MaxSquareFeet />
          </Row>
          <Column style={{ width: '100%' }}>
            {choices.map(c => (
              <SquareFeetCheckBox
                key={c.value}
                value={c.value}
                min={c.min}
                max={c.max}
              />
            ))}
          </Column>
          {searchContext === 'searchbar' && <ApplyClear style={{ top: '20px', border: 'none' }} name="squareFeet" />}
        </Column>
      </Fade>
    </div>
  );
});

const mapStateToProps = (state, ownProps) => {
  const display = state.search[state.search.context][ownProps.displayProp];
  return ({
    context: state.search.context,
    display: display || false,
  });
};

SquareFeetPop.propTypes = {
  display: PropTypes.bool.isRequired,
  searchContext: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps, null, null, { forwardRef: true },
)(SquareFeetPop);
