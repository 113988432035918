import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setSelectedItem, setStore, searchThisArea } from 'reducers/search'
import MapElement from 'components/AppleMap/index'
import LoadingSpinner from 'components/LoadingSpinner'
import ListingSearchThisArea from './ListingSearchThisArea'
import ListingSearchMarkers from './ListingSearchMarkers'
import { onRegionChangeEnd, onUserInteractionEnd } from './ListingMapHelpers'

class ListingIndexMap extends Component {
  disableAutoCenter = false

  state = {
    mapLoaded: false,
  }

  _onRegionChangeEnd = onRegionChangeEnd().bind(this)

  _onUserInteractionEnd = onUserInteractionEnd.bind(this)

  onMapLoaded = (e) => {
    if (e.status === 'Initialized') {
      this.setState({ mapLoaded: true })
    }
  }

  onSelectMarker = (e) => {
    const { _setSelectedItem } = this.props
    const selectedItem = e.annotation.data.id
    _setSelectedItem(selectedItem)
  }

  render() {
    const { markers } = this.props
    const { mapLoaded } = this.state
    const { onSelectMarker, onMapLoaded, disableAutoCenter, _onUserInteractionEnd, _onRegionChangeEnd } = this
    return (
      <div className="col p-0" id="nooklyn_listings_index_map">
        <MapElement
          height="calc(100vh - 106px)"
          width="100%"
          onConfigurationChange={onMapLoaded}
          onSelect={onSelectMarker}
          onScrollEnd={_onUserInteractionEnd}
          onZoomEnd={_onUserInteractionEnd}
          onRegionChangeEnd={_onRegionChangeEnd}
          disableAutoCenter={disableAutoCenter}
          showSubwayLines
        >
          {markers && <ListingSearchMarkers markers={markers} />}
        </MapElement>
        {!mapLoaded && <LoadingSpinner style={{ top: '37%', left: '43%', position: 'absolute' }} />}
        {<ListingSearchThisArea />}
      </div>
    )
  }
}

ListingIndexMap.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.object).isRequired,
  _searchThisArea: PropTypes.func.isRequired,
  _setSelectedItem: PropTypes.func.isRequired,
  _setStore: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const myState = state.search[state.search.context]
  return {
    markers: (myState && myState.items) || [],
  }
}

const mapDispatchToProps = (dispatch) => ({
  _searchThisArea: () => dispatch(searchThisArea()),
  _setSelectedItem: (payload) => dispatch(setSelectedItem(payload)),
  _setStore: (payload) => dispatch(setStore(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListingIndexMap)
