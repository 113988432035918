import axios from 'axios';
import { __AsyncBase__ } from './AsyncBase';

export default function LeaseMagicLinkAsync(lease_id) {
  const API_TOKEN = localStorage.getItem('apiToken');
  const cancelToken = this.source.token;
  const urlOrigin = window.location.origin;

  const fetch = () => axios({
    method: 'post',
    url: '/api/v2/leases.magic_link',
    data: { lease_id },
    headers: { API_TOKEN },
    cancelToken,
  }).then(res => `${urlOrigin}${res.data.magic_link}`);

  this.cancel = () => this.source.cancel('canceling');

  this.magicLink = this.magicLink ? this.magicLink : fetch();
}

LeaseMagicLinkAsync.prototype = new __AsyncBase__();
