import React from 'react';
import { useListings } from 'pro/ProStore';
import SidebarRow from './SidebarRow';

const Sidebar = props => {
  const { isFetching, listings } = useListings();

  return isFetching ? (
    <h1>Loading</h1>
  ) : (
    <>
      {listings.map(listing => <SidebarRow listing={listing} key={listing.storageId} />)}
    </>
  );
};

export default Sidebar;
