import React, { Component } from 'react';
import { toggleAgent, setStore } from 'reducers/agentTypeahead';
import { connect } from 'react-redux';

class AgentButton extends Component {

  onClick = (e) => {
    const { _toggleAgent, _setStoreSales, _setStoreListing, _setStorePropertyOwner, agentType } = this.props;
    e.stopPropagation();
    if (agentType === 'salesAgent') {
      _setStoreListing({ agentDisplay: false })
    } else if (agentType === 'listingAgent') {
      _setStoreSales({ agentDisplay: false })
    } else if (agentType === 'propertyOwner') {
      _setStorePropertyOwner ({ agentDisplay: false })
    }
    _toggleAgent();
  }

  computeAgentText = () => {
    const { agentType } = this.props;
    let agentText;
    switch (agentType) {
      case 'salesAgent':
      agentText = 'Sales Agents';
      break;
      case 'listingAgent':
      agentText = 'Listing Agents';
      break;
      case 'propertyOwner':
      agentText = 'Property Owners';
      break;
      case 'all':
      agentText = 'Agents';
      break;
    }
    return agentText;
  }

  render() {
    const { selectedAgentsCount, agentType } = this.props;
    const agentText = this.computeAgentText();
    const text = selectedAgentsCount > 0 ? `${agentText}: ${selectedAgentsCount} selected` : agentText
    return(
      <div
         onClick={this.onClick}
         className="button btn-transparent dropdown-toggle">
         {text}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { agentType } = ownProps;
  const { selectedAgents } = state.agentTypeahead[agentType];
  const selectedAgentsCount = selectedAgents.length;
  return {
    selectedAgentsCount
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { agentType } = ownProps;
  return {
      _toggleAgent: () => dispatch(toggleAgent(agentType)),
      _setStoreSales: (payload) => dispatch(setStore(payload, 'salesAgent')),
      _setStoreListing: (payload) => dispatch(setStore(payload, 'listingAgent')),
      _setStorePropertyOwner: (payload) => dispatch(setStore(payload, 'propertyOwner'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentButton)
