import React from 'react';
import LinkCreditReport from '../components/modal/LinkCreditReportModal';

const LinkCreditReportButton = props => (
  <LinkCreditReport {...props}>
    <a className="dropdown-item">Link Weimark Credit Report</a>
  </LinkCreditReport>
);

export default LinkCreditReportButton;
