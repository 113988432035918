import React, { Component } from 'react';
import Modal from '../modal/Modal';
import PaymentCreditPreview from './PaymentCreditPreview';

class PayModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  render() {
    const { children, payment, clientName } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <div>
        <div onClick={this.openModal}>{children}</div>
        <Modal
          className="Modal__Bootstrap modal-dialog dark-gray-container payment-modal"
          closeTimeoutMS={150}
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
        >
          <div className="modal-content">
            <div>
              <button className="close" onClick={this.closeModal}>
                <i className="nookons-close-circle-fill" />
              </button>
              <PaymentCreditPreview payment={payment} clientName={clientName} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default PayModal;
