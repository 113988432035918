import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setStore, generateNewSearch } from 'reducers/search';
import { Row, Column } from 'wrappers/FlexTable';
import CustomCheckBox from 'components/CustomCheckBox';

class SquareFeetCheckBox extends Component {
  onCheck = e => {
    e.preventDefault();
    if (this.props.checked) {
      this.props.setStore({
        minSquareFeet: '',
        maxSquareFeet: '',
      });
    } else {
      this.props.setStore({
        minSquareFeet: this.props.min,
        maxSquareFeet: this.props.max,
      });
    }
    this.props.generateNewSearch();
  }

  render () {
    const { value, checked } = this.props;
    return(
      <Row
        style={{
          border: '0px',
          width: '100%',
          paddingLeft: '10px',
          paddingTop: '10px', color: '#fff',
          fontSize: '13px',
          fontWeight: '500',
        }} >
        <Column
          onMouseDown={this.onCheck}
          style={{ width: '100%', cursor: 'pointer' }}>
          <CustomCheckBox
            checked={checked}
            boxClass='radio-box'
            boxcheckedClass='radio-box-selected'
            checkmarkClass='price-radio-button'
          >
            <Column
              style={{
                color: '#fff',
                fontSize: '13px',
                fontWeight:  '500',
                paddingLeft: '10px',
              }}
            >
              {value}
            </Column >
          </CustomCheckBox >
        </Column>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const maxSquareFeet = state.search[state.search.context].maxSquareFeet;
  const checked = maxSquareFeet === ownProps.max && state.search[state.search.context].minSquareFeet === ownProps.min;
  return {
    checked: checked
  };
};

const mapDispatchToProps = (dispatch) => ({
  setStore: (payload) => dispatch(setStore(payload)),
  generateNewSearch: () => dispatch(generateNewSearch())
});

export default connect(mapStateToProps, mapDispatchToProps)(SquareFeetCheckBox);
