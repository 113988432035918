import { createContext } from 'react';
// Context Factory to handle adding new contexts with different names
const createNamedContext = (name = '', initialValue = null) => {
  const context = createContext(initialValue);
  context.displayName = name;
  return context;
};
const __RouterContext__ = createNamedContext('Router');
const __ParamsContext__ = createNamedContext('Params');
const __RouteChangeContext__ = createNamedContext('RouteChange');
export {
  __RouterContext__,
  __ParamsContext__,
  __RouteChangeContext__,
};
