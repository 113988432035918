import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setStore } from 'reducers/matrixListings';
import axios from 'axios';
import CreateListingCollectionModal from 'components/modal/CreateListingCollectionModal';
import Fade from 'wrappers/Fade';

class AddToCollection extends Component {
  source = axios.CancelToken.source();

  state = {
    showCreateCollectionModal: false,
    fadeCollectionModal: false,
  }

  componentWillUnmount() {
    this.source.cancel('api call cancelled as component will unmount');
  }

  closeModal = () => {
    const callback = e => {
      if (e.propertyName === 'transform') {
        this.setState({ showCreateCollectionModal: false });
        window.removeEventListener('transitionend', callback);
      }
    };
    this.setState({ fadeCollectionModalContent: false }, () => {
      window.addEventListener('transitionend', callback);
      this.setState({ fadeCollectionModal: false });
    });
  }

  openModal = () => {
    this.setState({
      showCreateCollectionModal: true,
      fadeCollectionModal: true,
      fadeCollectionModalContent: true,
    });
  }

  addToCollection = e => {
    const listingCollectionId = e.target.dataset.id;
    const { listingId } = this.props;
    let url = `/listings/${listingId}/add_to_collection?`;
    url += `listing_collection_id=${listingCollectionId}&listing_id=${listingId}.json`;

    axios
      .get(url, { cancelToken: this.source.token })
      .then(res => {
        $('#collect-flash').fadeIn(600);
        $('#collect-flash')
          .delay(1500)
          .fadeOut(600);
      })
      .catch(err => console.log(err));
  };

  render() {
    const {
      showList, showCreateCollectionModal, fadeCollectionModal, fadeCollectionModalContent,
    } = this.state;
    const { listingCollections, listingId } = this.props;
    const { addToCollection, openModal, closeModal } = this;
    return (
      <>
        <li className="dropdown list-inline-item">
          <div className="listcollect-wrapper">
            <a
              role="menu"
              className="listcollect"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id={listingId}
              style={{ color: '#fff' }}
            >
              <i className="nookons-add mr-1" />
              Add to collection
            </a>
            <ul className="dropdown-menu nklynbar-nav-dark nklyn-listing-manage-dropdown dropdown-menu-force">
              <button
                style={{ fontSize: '12px', textAlign: 'center', margin: '0.25rem 1.5rem' }}
                onClick={openModal}
                href="javascript:void(0)"
                className="btn-black-white btn-rounded button"
                key="create-new-listing-collection"
              >
                New Collection
              </button>
              {listingCollections
                ? listingCollections.map(collection => (
                  <li className="dropdown-item" key={collection.id}>
                    <a
                      onClick={addToCollection}
                      data-id={collection.id}
                      href="javascript:void(0)"
                    >
                      {collection.name}
                    </a>
                  </li>
                ))
                : ''}
            </ul>
            {showCreateCollectionModal
            && (
            <Fade
              customStyles={{
                position: 'fixed', width: '100%', height: '100%', top: 0, left: 0,
              }}
              fadeStart="modal-combo-start"
              fadeEnd="element-end"
              visible={fadeCollectionModal}
            >
              <CreateListingCollectionModal
                visible={fadeCollectionModalContent}
                closeModal={closeModal}
              />
            </Fade>
            )}
          </div>
        </li>
      </>
    );
  }
}

const mapStateToProps = state => ({
  listingCollections: state.matrixListings.listingCollections,
});

const mapDispatchToProps = dispatch => ({
  setStore: payload => dispatch(setStore(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToCollection);
