import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class BedButton extends Component {

  render() {
    const { selectedBeds, togglePopover } = this.props;
    let value = selectedBeds.filter(option => option !== 'Studio').sort().join(', ');
    if (selectedBeds.includes('Studio')) {
      value = '0, ' + value;
    }
    return (
      <a
        name='sentenceBed'
        onMouseDown={togglePopover}
        tabIndex="0"
        className="button btn-transparent"
        role="button"
        title="">{value} beds
      </a>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedBeds: state.search[state.search.context].selectedBeds,
});

const mapDispatchToProps = (dispatch) => ({
  togglePopover: (e) => dispatch(togglePopover(e))
});

export default connect(mapStateToProps, mapDispatchToProps)(BedButton);
