import React from 'react';
import { useGlobalState } from 'components/listRoom/customEffects';
import { truncate } from '../../../helpers/util';

const ListingCard = (props) => {
  const { listing } = props;
  const [globalState] = useGlobalState();
  const { images } = globalState;

  return (
    <div className="row listing-card-row">
      <div className="nooklyn_listing_square nklyn-listing">
        <div className="listing-card-img">
          <img className="img-fluid mx-auto" src={(images.length && images[0].wide) || ''} />
        </div>
        <div className="nooklyn_room_square_price">
          <div className="col">
            <div className="monthly-rent">
              {accounting.formatMoney(listing.price / 100, { precision: 0 })}
              /month
            </div>
          </div>
        </div>
        <div className="listing-card-stub container-fluid">
          <div className="row">
            <div className="col-6">
              <i className="nookons-map-marker nklyn-white" />
              {truncate(listing.neighborhood.name, 27)}
            </div>
            <div className="col-3">
              <i className="nookons-bed nklyn-white" />
              {listing.beds} Bed
            </div>
            <div className="col-3">
              <i className="nookons-bath nklyn-white" />
              {listing.bathrooms} Bath
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ListingCard.defaultProps = {
  listing: {},
};

export default ListingCard;
