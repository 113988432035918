import React, {
  useCallback, useRef, useEffect,
} from 'react';
import { connect } from 'react-redux';
import { usePlaidLink } from 'react-plaid-link';
import {
  linkBankAccount, storeBankInfo, createPaymentErrors,
} from '../../reducers/payment';

const PlaidLinkWithLinkToken = props => {
  const {
    storeBankInfo, linkBankAccount, createPaymentErrors, achPaymentRequest, linkToken, oauthFlow, logPlaidInitiatedAfterOauth, oauthStateId,
  } = props;
  const buttonRef = useRef(null);

  const onSuccess = useCallback(
    (token, metadata) => {
      linkBankAccount();
      const plaidToken = token;
      const plaidBankName = metadata.institution.name;
      const plaidAccountName = metadata.accounts[0].name;
      const plaidAccountId = metadata.accounts[0].id;

      storeBankInfo({
        plaidToken, plaidBankName, plaidAccountName, plaidAccountId,
      });
    },
    [],
  );

  const onExit = useCallback(
    (err, metadata) => {
      if (err != null) {
        const plaid_error = err || '';
        const error_type = 'plaid_link_error';
        const plaid_metadata = metadata;
        const payment_charge_descriptor_signature = achPaymentRequest.signature;
        createPaymentErrors(payment_charge_descriptor_signature, error_type, plaid_error, plaid_metadata);
      }
      if (oauthFlow && metadata.status === 'requires_credentials' && (err && err.error_code !== 'OAUTH_STATE_ID_NOT_FOUND')) {
        setTimeout(() => {
          window.location.href = window.location.href.split('?')[0];
        }, 200);
      }
    },
    [],
  );

  const onLoad = () => {
    if (oauthFlow) {
      logPlaidInitiatedAfterOauth({ oauth_state_id: oauthStateId }, achPaymentRequest.signature);
    }
  };

  const config = {
    token: linkToken,
    env: process.env.RAILS_ENV === 'production' ? 'production' : 'sandbox',
    onSuccess,
    onExit,
    onLoad,
    receivedRedirectUri: oauthFlow ? window.location.href : null,
  };

  const { open, ready, error } = usePlaidLink(config);

  useEffect(() => {
    if (ready && oauthFlow) {
      open();
    }
  }, [ready, open, oauthFlow]);

  return (
    <>
      { (!oauthFlow)
      && (
      <button
        ref={buttonRef}
        id="plaid-button"
        type="button"
        className="button btn-white-black btn-rounded btn-block"
        onClick={() => open()}
        disabled={!ready || error}
      >
        Link Bank Account
      </button>
      )}
    </>
  );
};

const mapState = (state, ownProps) => ({
  signature: ownProps.signature,
});

const mapDispatch = dispatch => ({
  linkBankAccount() {
    dispatch(linkBankAccount());
  },
  storeBankInfo(payload) {
    dispatch(storeBankInfo(payload));
  },
  createPaymentErrors(payment_charge_descriptor_signature, error_type, plaid_error, plaid_metadata) {
    dispatch(createPaymentErrors(payment_charge_descriptor_signature, error_type, plaid_error, plaid_metadata));
  },
});

export default connect(mapState, mapDispatch)(PlaidLinkWithLinkToken);
