import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { initializeSelectedAgents, addPopoverRef } from 'reducers/agentTypeahead';
import reposition from 'components/listingSearchComponents/searchPopovers/reposition';
import AgentPopover from './AgentPopover';
import UpdateHandler from './UpdateHandler';
import AgentButton from './AgentButton';

class Agent extends Component {
  repositionPopover = reposition.bind(this)

  references = {
    popoverWrapper: React.createRef(),
    popoverContainer: React.createRef(),
    popover: React.createRef(),
  };

  componentDidMount() {
    const { _initializeSelectedAgents, agents } = this.props;
    _initializeSelectedAgents(agents);
    this.repositionPopover();
    const { _addPopoverRef } = this.props;
    _addPopoverRef(this);
  }

  render() {
    const {
      matrixContext,
      agentType,
    } = this.props;
    const {
      popover,
      popoverContainer,
      popoverWrapper,
    } = this.references;
    return (
      <div ref={popoverContainer} className="list-inline-item">
        <AgentButton agentType={agentType} />
        <AgentPopover
          popover={popover}
          popoverWrapper={popoverWrapper}
          matrixContext={matrixContext}
          agentType={agentType}
        />
        <UpdateHandler agentType={agentType} />
      </div>
    );
  }
}

Agent.propTypes = {
  matrixContext: PropTypes.oneOf(['listing', 'lease', 'check_request', 'listing_room']).isRequired,
  _initializeSelectedAgents: PropTypes.func.isRequired,
  agents: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  agentType: PropTypes.oneOf(['salesAgent', 'listingAgent', 'propertyOwner', 'all']).isRequired,
  _addPopoverRef: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { agentType: context } = ownProps;
  return {
    _initializeSelectedAgents: payload => dispatch(initializeSelectedAgents(payload, context)),
    _addPopoverRef: payload => dispatch(addPopoverRef(payload, context)),
  };
};

export default connect(null, mapDispatchToProps)(Agent);
