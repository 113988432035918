/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import GoogleMap from 'components/GoogleMap/listing/GoogleMap'
import PoiMarkerPoint from 'components/GoogleMap/nearby/MarkerPoint'
import PoiAnnotation from 'components/GoogleMap/nearby/Annotation'
import SearchMarkerPoint from 'components/GoogleMap/listing/search/MarkerPoint'
import SearchAnnotation from 'components/GoogleMap/listing/search/Annotation'
import ClusterPoint from 'components/GoogleMap/common/ClusterPoint'

import MultiMarker from 'components/GoogleMap/common/MultiMarker'

import useFitMarkerBounds from 'hooks/useFitMarkerBounds'
import useGoogleMapCluster from 'hooks/useGoogleMapCluster'

import { appleCurrentImageMarker } from 'helpers/mapHelpers'

import ListingSearchThisArea from './ListingSearchThisArea'

const Marker = ({ children }) => children

const ListingMapGoogle = (props) => {
  const { isSearch, markers, onMapBoundChange, selectedBounds, selectedListing, setSelectedListing } = props
  const [loadedMap, setLoadedMap] = useState(null)

  const [prevMapBounds, setPrevMapBounds] = useState({
    topLeft: undefined,
    bottomRight: undefined,
  })

  const [searchThisArea, setSearchThisArea] = useState(false)

  useEffect(() => {
    if (!!selectedBounds.topLeft && !!selectedBounds.bottomRight) {
      setPrevMapBounds({
        topLeft: selectedBounds.topLeft,
        bottomRight: selectedBounds.bottomRight,
      })
    }
    setSearchThisArea(!!selectedBounds.topLeft && !!selectedBounds.bottomRight)
  }, [selectedBounds])

  const handleSearchThisAreaChange = () => {
    onMapBoundChange(
      'map',
      !searchThisArea
        ? { topLeft: prevMapBounds.topLeft, bottomRight: prevMapBounds.bottomRight }
        : { topLeft: undefined, bottomRight: undefined }
    )
  }

  const {
    latitude: selectedLatitude,
    longitude: selectedLongitude,
    shortAddress,
    creator,
    network,
    id,
  } = selectedListing || {}

  const { creatorName } = creator || {}
  const platform = network === 'platform'

  const contextLat = parseFloat(selectedLatitude)
  const contextLon = parseFloat(selectedLongitude)

  useFitMarkerBounds(loadedMap, markers)

  const { clusters, supercluster, onClusterMapChange, points } = useGoogleMapCluster(markers)

  const handleMapChange = (data) => {
    const bounds = data?.bounds
    const coords = {
      topLeft: [bounds?.ne?.lat, bounds?.sw?.lng],
      bottomRight: [bounds?.sw?.lat, bounds?.ne?.lng],
    }

    setPrevMapBounds(coords)
    onClusterMapChange(data)

    if (searchThisArea) {
      onMapBoundChange('map', coords)
    }
  }

  const selectedMarker = {
    title: platform ? `${creatorName}'s home` : shortAddress,
    markerUrl: appleCurrentImageMarker,
  }

  return (
    <div
      className={isSearch ? 'col p-0 nooklyn_listings_index_map' : 'nearby-map'}
      style={
        isSearch
          ? {}
          : {
              height: '100%',
            }
      }
    >
      <GoogleMap
        onLoaded={(map) => setLoadedMap(map)}
        containerChildren={
          isSearch &&
          loadedMap && (
            <ListingSearchThisArea
              loading={!prevMapBounds.topLeft || !prevMapBounds.bottomRight}
              checked={searchThisArea}
              onChange={handleSearchThisAreaChange}
            />
          )
        }
        mapProps={{
          onChange: (data) => handleMapChange(data),
        }}
      >
        {clusters &&
          clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates
            const {
              cluster: isCluster,
              multilisting: isMultilisting,
              point_count: pointCount,
              markers: markersList,
            } = cluster.properties

            const marker = markersList?.[0]

            if (isCluster) {
              return (
                <Marker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
                  <ClusterPoint
                    onClick={() => {
                      const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 22)
                      loadedMap.setZoom(expansionZoom)
                      loadedMap.panTo({ lat: latitude, lng: longitude })
                    }}
                    pointCount={pointCount}
                    size={points.length}
                  />
                </Marker>
              )
            }

            if (isMultilisting) {
              return (
                <MultiMarker lat={latitude} lng={longitude} markersNum={markersList.length}>
                  {markersList.map((multiMarker) => {
                    if (isSearch) {
                      return <SearchAnnotation isMulti marker={multiMarker} />
                    }

                    return <PoiAnnotation isMulti marker={marker} />
                  })}
                </MultiMarker>
              )
            }

            if (isSearch) {
              return (
                <SearchMarkerPoint
                  selected={selectedListing ? id === marker.id : false}
                  setSelectedListing={setSelectedListing}
                  key={marker.id}
                  marker={marker}
                  lat={latitude}
                  lng={longitude}
                >
                  <SearchAnnotation marker={marker} />
                </SearchMarkerPoint>
              )
            }

            return (
              <PoiMarkerPoint key={marker.id} marker={marker} lat={marker.latitude} lng={marker.longitude}>
                <PoiAnnotation marker={marker} />
              </PoiMarkerPoint>
            )
          })}

        {!isSearch && contextLat && (
          <PoiMarkerPoint marker={selectedMarker} lat={contextLat} lng={contextLon} selected>
            <PoiAnnotation marker={selectedMarker} />
          </PoiMarkerPoint>
        )}
      </GoogleMap>
    </div>
  )
}

ListingMapGoogle.defaultProps = {
  isSearch: false,
  onMapBoundChange: () => {},
  selectedListing: null,
  selectedBounds: {
    topLeft: undefined,
    bottomRight: undefined,
  },
  markers: [],
  setSelectedListing: () => {},
}

ListingMapGoogle.propTypes = {
  isSearch: PropTypes.bool,
  markers: PropTypes.arrayOf(PropTypes.shape({})),
  onMapBoundChange: PropTypes.func,
  selectedBounds: PropTypes.shape({
    topLeft: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    bottomRight: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  }),
  selectedListing: PropTypes.shape({}),
  setSelectedListing: PropTypes.func,
}

export default ListingMapGoogle
