import React from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import strftime from 'strftime';
import missing from 'images/logo/gold-on-black-wide.png';
import { Link } from '@reach/router';

const RoomCard = (props) => {
  const { listing, room, setCurrentRoom } = props;

  return (
    <CSSTransition appear in timeout={300} classNames="stepper">
      <div className="nooklyn_room_card d-block">
        <div className="row">
          <div className="room-card-img p-0 border-0">
            <div className="status-tag">
              {(() => {
                switch (room.status) {
                  case 'Available':
                    return (
  <span>
    <span className="status-dot status-available" />
                        Available
  </span>
                    );
                  case 'Rentd':
                    return (
  <span>
    <span className="status-dot status-rented" />
                        Rented
  </span>
                    );
                  case 'Draft':
                    return (
  <span>
    <span className="status-dot status-draft" />
                        Draft
  </span>
                    );
                  case 'Awaiting Approval':
                    return (
  <span>
    <span className="status-dot status-review" />
                        Under Review
  </span>
                    );
                  default:
                    return (
  <span>
    <span className="status-dot status-draft" />
    {room.status}
  </span>
                    );
                }
              })()}
            </div>
            <Link to={`/pro/listing_rooms/${room.id}`}>
              {setCurrentRoom ? (
                <img
                  className="img-fluid mx-auto"
                  src={room.image.wide || missing}
                  onClick={() => setCurrentRoom(room)}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <img className="img-fluid mx-auto" src={room.image.wide || missing} />
              )}
            </Link>
            <div className="gradient" />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div>{window.accounting.formatMoney(room.price / 100, { precision: 0 })}</div>
          </div>
          <div className="col-6">
            <div>{strftime('%B %-d, %Y', new Date(listing.dateAvailable))}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div>{listing.neighborhood.name}</div>
          </div>
          <div className="col-6">
            <div>
              {listing.subwayLines.map((subway, idx) => (
                <img
                  key={idx}
                  alt={subway}
                  id="subway-img"
                  src={`${require(`images/subway/2x/${subway}.png`)}`}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col" style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
            <div>{`${listing.address.short}`}</div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

RoomCard.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  setCurrentRoom: PropTypes.func,
  edit: PropTypes.func,
};

RoomCard.defaultProps = {
  edit: null,
  setCurrentRoom: null,
};

export default RoomCard;
