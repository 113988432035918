import React from 'react';
import PropTypes from 'prop-types';
import { AsyncProvider } from 'asyncHandlers/AsyncBase';
import MateAsync from 'asyncHandlers/MateAsync';
import MateFavListings from './MateFavListings';

const MateFavListingsContainer = (props) => {
  const { id } = props;
  return (
    <AsyncProvider handler={new MateAsync(id)}>
      <MateFavListings />
    </AsyncProvider>
  );
};

MateFavListingsContainer.propTypes = {
  id: PropTypes.number.isRequired,
};
export default MateFavListingsContainer;
