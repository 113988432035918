import React from 'react';
import { useRooms } from 'pro/ProStore';
import { Link } from '@reach/router';
import RoomCard from './RoomCard';

const RoomSection = (props) => {
  const { listing } = props;

  const { dataLoaded, rooms } = useRooms(listing.id);

  return dataLoaded ? (
    <h1>Loading</h1>
  ) : (
    <>
      <div className="float-right">
        <Link to={`/pro/listings/${listing.id}/new_room`}>
          <i className="nookons-add nkyln-2x" />
          &nbsp; Add Room
        </Link>
      </div>
      <div className="row">
        {
          rooms.map(room => (
            <div className="border-box nklyn-pro-rooms-container col-sm-6" style={{ margin: '25px 0' }} key={room.storageId}>
              <RoomCard room={room.data} listing={listing} />
            </div>
          ))
        }
      </div>
    </>
  );
};

export default RoomSection;
