import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { present } from 'helpers/util';
import useDebounce from 'components/listRoom/customEffects/useDebounce';
import { ButtonSave, ButtonStepper } from '../Buttons';
import ContainerWrapper from '../ContainerWrapper';

const title = 'Describe the apartment.';
const maxCharCount = 500;

const Description = (props) => {
  const {
    description: prefillDescription,
    handleJump,
    userIsEditing,
    setUserIsEditing,
    handleNext,
    handleBack,
  } = props;
  const [description, setDescription] = useState(prefillDescription);
  const debouncedDescription = useDebounce(description, 500);

  useEffect(() => {
    if (present(debouncedDescription)) {
      props.setDescription(debouncedDescription);
    }
  }, [debouncedDescription]);

  useEffect(() => {
    document.title = `Create: Apartments - ${title}`;
  }, []);

  const remainingChar = maxCharCount - description.length;

  const onChange = (evt) => {
    if (evt.target.value.length <= maxCharCount) setDescription(evt.target.value);
  };

  return (
    <ContainerWrapper>
      <div>
        <div className="title">
          <h3>{title}</h3>
          <p>Tell renters why your place is awesome.</p>
        </div>
        <form>
          <div className="row">
            <textarea
              className="description-textarea general-text-field"
              value={description}
              onChange={onChange}
            />
          </div>
          <p className="chars-remaining-dark">
            <span id="remain">{remainingChar}</span>
            &nbsp;characters remaining
          </p>

          {userIsEditing ? (
            <ButtonSave
              handleJump={props.handleJump}
              setUserIsEditing={props.setUserIsEditing}
              jumpTo={props.jumpTo || "ApartmentReview"}
            />
          ) : (
            <ButtonStepper
              handleNext={handleNext}
              handleBack={handleBack}
              disabled={!prefillDescription}
            />
          )}
        </form>
      </div>
    </ContainerWrapper>
  );
};

Description.defaultProps = {
  description: '',
};

Description.propTypes = {
  description: PropTypes.string,
  setDescription: PropTypes.func.isRequired,
  handleJump: PropTypes.func.isRequired,
  userIsEditing: PropTypes.bool.isRequired,
  setUserIsEditing: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default Description;
