import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const BaseInput = props => {
  const {
    initialValue,
    onChange,
    inputType,
    inputName,
    labelText,
    inputImg,
    error,
  } = props;

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    onChange(value)
  }, [value]);

  return (
    <>
      { initialValue ? 
        (
          <div className='mb-3'>
            <div className='labelPrefilled' dangerouslySetInnerHTML={{ __html: labelText }} />
            <div className='inputTextPrefilled'>{initialValue}</div>
          </div>
        ) 
          : 
        (
          <div className='mb-3'>
            <label dangerouslySetInnerHTML={{ __html: labelText }} />
            <div className={`input-group ${error ? 'error' : ''}`}>
              <span className="input-image general-text-field-dark br-0" style={{ height: '45px', width: '45px' }}>
                <img
                  alt={`${inputName} image`}
                  src={inputImg}
                  className="img-fluid mx-auto"
                />
              </span>
              <input
                type={inputType}
                name={inputName}
                className="general-text-field-dark form-control"
                style={{ height: '45px', color: '#fff' }}
                value={value}
                onChange={evt => setValue(evt.target.value)}
                autoComplete={inputName}
              />
            </div>
          </div>
        )
      } 
    </>
  );
};

BaseInput.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  inputType: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  inputImg: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  error: PropTypes.bool,
};

BaseInput.defaultProps = {
  initialValue: '',
  labelText: '',
  error: false
};

export default BaseInput;
