import React, { Component } from 'react';
import axios from 'axios';
import { getMetaContent } from 'helpers/util';
// Change a matrix field div to MatrixField to add an onHover edit feature
export default class RoomField extends Component {
  state = {
    editing: false,
    myValue: this.props.myValue,
    hoverEdit: false,
  };

  shouldComponentUpdate(nextProps) {
    if (nextProps.myValue !== this.props.myValue) {
      this.setState(({ myValue }) => ({ myValue: nextProps.myValue }));
    }
    return true;
  }

  toggleInput = e => {
    this.setState(({ editing }) => ({ editing: !editing }));
  };

  changeField = e => {
    e.persist();
    this.setState(({ myValue }) => ({ myValue: e.target.value }));
  };

  mouseHoverEdit = e => {
    this.setState(({ hoverEdit }) => ({ hoverEdit: true }));
  };

  mouseLeaveEdit = e => {
    this.setState(({ hoverEdit }) => ({ hoverEdit: false }));
  };

  onEditSubmit = e => {
    e.preventDefault();
    this.setState(({ editing }) => ({ editing: false }));
    if (this.state.myValue !== this.props.myValue) {
      axios
        .patch(`/matrix/rooms/${this.props.roomId}.json`, {
          listing_room: { [this.props.editAttr]: this.state.myValue },
          authenticity_token: getMetaContent('csrf-token'),
        })
        .then(res => {
          if (!res.ok) {
            console.log(res.statusText);
          }
        })
        .catch(err => console.log(err));
      const { myValue } = this.state;
      const attr = this.convertAttr();
      this.props.changeField(myValue, attr);
    }
  };

  convertAttr = () => this.props.editAttr.split('_').map((word, index) => index > 0 ? word.charAt(0).toUpperCase() + word.substr(1) : word).join('')

  render() {
    const { editing, hoverEdit, myValue } = this.state;
    const { edit, children, myClass } = this.props;

    return (
      <div
        onMouseEnter={this.mouseHoverEdit}
        onMouseLeave={this.mouseLeaveEdit}
        className={myClass}
      >
        {!editing ? children : ''}
        {hoverEdit && !editing && edit ? (
          <i
            onClick={this.toggleInput}
            className="nookons-edit nklyn-white matrix-listing-edit-button"
          />
        ) : (
          ''
        )}
        {editing ? (
          <form>
            {myValue.toString().length < 25 ? (
              <input
                className="edit-matrix-listing-input"
                type="text"
                value={myValue}
                onChange={this.changeField}
              />
            ) : (
              <textarea
                className="edit-matrix-listing-text-area general-text-field"
                value={myValue}
                onChange={this.changeField}
              />
            )}
            <button
              className="button btn-rounded btn-1x btn-white-black submit-edit-matrix-button"
              type="submit"
              onClick={this.onEditSubmit}
            >
              Save
            </button>
          </form>
        ) : (
          ''
        )}
      </div>
    );
  }
}
