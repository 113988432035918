import React from 'react';
import PropTypes from 'prop-types';
import { __RouteChangeContext__ as RouteChangeContext } from './RouterContext';
import { __RouteChangeSubscriber__ as RouteChangeSubscriber } from './RouteChangeSubscriber';

/*
  RouteChangeHandler is a special wrapper
  for advanced use of the Router.
  It has one purpose,
  which is to fire off an event BEFORE the Router forcefully
  mounts, unmounts, and updates Route components.
  RouteChangeHandler accepts an event handler prop, onBeforeRouteChange,
  which is called whenever the Route changes.
  It can be placed anywhere in a tree.
  The event doesn't bubble like a normal event,
  so it should be treated like a window level event such as resize, transitionend, etc.

  Since css transitions are bound to be contextual,
  RouteChangeHandler also accepts a paths array
  that will deconstruct url params similarly to Route Component.
  It also receives a third boolean parameter 'historyInduced',
  which tells whether the route change was caused by back/forward button.

  paths = ['/listings/id=[a-zA-Z-0-9]+']

  Example usage:

  onBeforeRouteChange = (params, historyInduced) => {
    if (params.id && !historyInduced) {
      doWork()
    }
  }

  <RouteChangeHandler
    onBeforeRouteChange={this.onBeforeRouteChange}
    paths=['/listings/id=[a-zA-Z-0-9]+']
  />
*/

/* Pass down the route change event subscription object from the router.
This is the same way redux does it. */
const RouteChangeHandler = props => (
  <RouteChangeContext.Consumer>
    {
        beforeRouteChange => (
          <RouteChangeSubscriber
            beforeRouteChange={beforeRouteChange}
            onBeforeRouteChange={props.onBeforeRouteChange}
            paths={props.paths}
            data={props}
          >
            {props.children || null}
          </RouteChangeSubscriber>
        )
    }
  </RouteChangeContext.Consumer>
);

RouteChangeHandler.defaultProps = {
  children: [],
  paths: null,
};

RouteChangeHandler.propTypes = {
  onBeforeRouteChange: PropTypes.func.isRequired,
  paths: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default RouteChangeHandler;
