import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Column, Row } from 'wrappers/FlexTable';
import Fade from 'wrappers/Fade';
import IndividualBed from './IndividualBed';

const BedPop = forwardRef((props, { popover, popoverWrapper }) => {
  const options = ['Studio', '1', '2', '3', '4', '5+'];

  const onMouseDown = (e) => {
    e.stopPropagation();
  };

  const { display, searchContext } = props;
  return (
    <div
      className={display ? 'popover bed-bath-pop' : 'popover bed-bath-pop hide'}
      style={{ position: 'fixed', width: 0, border: 'none' }}
      ref={popoverWrapper}
    >
      <Fade
        fadeStart="expand-start"
        fadeEnd="expand-end"
        visible={display}
        customStyles={{
          position: 'relative',
          right: '138px',
          flexWrap: 'wrap',
          fontSize: '15px',
        }}
      >
        <Column
          ref={popover}
          role="button"
          tabIndex={0}
          onMouseDown={onMouseDown}
          style={{
            right: '25px',
            width: '320px',
            position: 'relative',
            transform: 'translate(0px, 0px)',
            background: '#000',
            border: '1px solid #404040',
            borderRadius: '21px',
          }}
          className="beds"
        >

          <Row
            style={{
              backgroundColor: '#000',
              height: '100%',
              margin: '10px',
              padding: '10px',
              fontWeight: '500',
              fontSize: '14px',
            }}
            className="rounded-row"
          >
            {
              options.map(
                val => <IndividualBed searchContext={searchContext} val={val} key={`bed-${val}`} />,
              )
            }
          </Row>
          <Row />
        </Column>
      </Fade>
    </div>
  );
});

const mapStateToProps = (state, ownProps) => {
  const display = state.search[state.search.context][ownProps.displayProp];
  return ({
    display: display || false,
  });
};

BedPop.propTypes = {
  display: PropTypes.bool.isRequired,
  searchContext: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true },
)(BedPop);
