import React from 'react';
import { useListings } from 'pro/ProStore';
import { navigate } from '@reach/router';

const Home = props => {
  const { isFetching, listings } = useListings();

  if (!isFetching) {
    const {
      isFetching: isListingFetching = true,
      data: {
        id: listingId,
      } = {},
    } = listings[0];

    if (!isListingFetching) {
      navigate(`/pro/listings/${listingId}`);
    }
  }

  return (
    <h1>Loading</h1>
  );
};

export default Home;
