import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListingIndexAnnotation from './ListingIndexAnnotation';

class ListingSearchMarkers extends Component {
  render() {
    const { markers, map } = this.props;
    return (
      markers && markers.map((marker) => {
        const {
          id: key,
        } = marker;

        return (
          <ListingIndexAnnotation
            map={map}
            key={key}
            {...marker}
          />
        );
      })
    );
  }
}

ListingSearchMarkers.defaultProps = {
  markers: null,
  map: null,
};

ListingSearchMarkers.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  map: PropTypes.shape({}),
};

export default ListingSearchMarkers;
