// INITIAL STATE
const initialState = {
  submitting: false,
  done: false,
};

//ACTION TYPES
const SUBMIT_FORM = 'SUBMIT_FORM';
const SUBMITTED = 'SUBMITTED';
const SET_FORM_STORE = 'SET_STORE';

//ACTION CREATORS
export const submitForm = () => ({ type: SUBMIT_FORM });
export const submitted = () => ({ type: SUBMITTED });
export const setStore = (payload) => ({ type: SET_FORM_STORE, payload });

//REDUCERS
export default function (state = initialState, action) {
  switch (action.type) {
  case SET_FORM_STORE:
    return { ...state, ...action.payload };
  case SUBMIT_FORM:
    return { ...state, submitting: true };
  case SUBMITTED:
    return { ...state, submitting: false, done: true };
  default:
    return state;
  }
}
