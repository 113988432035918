import React from 'react';
import { connect } from 'react-redux';
import AgreementModal from '../components/modal/AgreementModal';

const Jumbotron = props => (
  <AgreementModal {...props}>
    <a className="button btn-rounded btn-yellow-black" style={{ color: 'black' }}>
      Review
    </a>
  </AgreementModal>
);

export const AgreementButtonJumbotron = connect(
  null,
  null,
)(Jumbotron);

export const AgreementButtonPreview = props => (
  <AgreementModal client={props.client}>
    <a className="dropdown-item">Deposit Agreement</a>
  </AgreementModal>
);
