import React from 'react';

export const Heart = props => {
  const { buttonStatus, handleClick } = props;
  const className = buttonStatus === 'unlike-button' ? 'nookons-heart nooklyn_square_heart' : 'nookons-heart-fill nklyn-red nooklyn_square_heart nklyn-4x';
  return (
    <div className={buttonStatus} onClick={handleClick}>
      <i className={className} />
    </div>
  );
};
