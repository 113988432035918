import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MapElement from 'components/AppleMap';
import LoadingSpinner from 'components/LoadingSpinner';
import ListingNearbyPOIMarkers from './ListingNearbyPOIMarkers';
import ListingSearchMarkers from './ListingSearchMarkers';
import ListingSearchThisArea from './ListingSearchThisArea';

const ListingMap = props => {
  const {
    isSearch,
    markers,
    onMapBoundChange,
    selectedBounds,
    selectedListing,
    setSelectedListing,
  } = props;

  const [mapLoaded, setMapLoaded] = useState(false);
  const [prevMapBounds, setPrevMapBounds] = useState({
    topLeft: undefined,
    bottomRight: undefined,
  });
  const [searchThisArea, setSearchThisArea] = useState(false);
  const [disableAutoCenter, setDisableAutoCenter] = useState(false);

  useEffect(() => {
    if (!!selectedBounds.topLeft && !!selectedBounds.bottomRight) {
      setPrevMapBounds({
        topLeft: selectedBounds.topLeft,
        bottomRight: selectedBounds.bottomRight,
      });
    }
    setSearchThisArea(!!selectedBounds.topLeft && !!selectedBounds.bottomRight);
  }, [selectedBounds]);

  const getMapBounds = map => {
    const {
      region,
    } = map;
    const {
      eastLongitude,
      northLatitude,
      southLatitude,
      westLongitude,
    } = region.toBoundingRegion();
    const topLeft = [northLatitude, westLongitude];
    const bottomRight = [southLatitude, eastLongitude];
    setPrevMapBounds({ topLeft, bottomRight });
    return { topLeft, bottomRight };
  };

  const onMapLoaded = e => {
    if (e.status === 'Initialized') {
      setMapLoaded(true);
    }
  };

  const handleSelectMarker = e => {
    if (e.annotation.data) {
      const selectedItem = e.annotation.data;
      setSelectedListing(selectedItem);
    }
  };

  const handleSearchThisAreaChange = () => {
    onMapBoundChange('map', !searchThisArea
      ? { topLeft: prevMapBounds.topLeft, bottomRight: prevMapBounds.bottomRight }
      : { topLeft: undefined, bottomRight: undefined });
  };

  const handleRegionChangeEnd = () => {
    let firstTime = true;
    return function callback(e) {
      const map = e.target;
      if (firstTime && map.center.latitude) {
        firstTime = false;
        const { topLeft, bottomRight } = getMapBounds(map);
        if (searchThisArea) {
          onMapBoundChange('map', { topLeft, bottomRight });
        }
      }
    };
  };

  const onUserInteractionEnd = e => {
    const { topLeft, bottomRight } = getMapBounds(e.target);
    setDisableAutoCenter(true);
    if (searchThisArea) {
      onMapBoundChange('map', { topLeft, bottomRight });
    }
  };

  return (
    <div
      className={isSearch ? 'col p-0 nooklyn_listings_index_map' : 'nearby-map'}
      style={isSearch
        ? {}
        : {
          height: '100%',
        }}
    >
      <MapElement
        height="100%"
        width="100%"
        onSelect={isSearch ? handleSelectMarker : null}
        onScrollEnd={isSearch ? onUserInteractionEnd : undefined}
        onZoomEnd={isSearch ? onUserInteractionEnd : undefined}
        onRegionChangeEnd={isSearch ? handleRegionChangeEnd : undefined}
        onConfigurationChange={onMapLoaded}
        disableAutoCenter={disableAutoCenter}
        showSubwayLines
      >
        {
          isSearch
            ? (
              <ListingSearchMarkers
                map={null}
                markers={markers}
                selectedListing={selectedListing}
                isSearch
              />
            )
            : (
              <ListingNearbyPOIMarkers
                map={null}
                markers={markers}
                selectedListing={selectedListing}
              />
            )
        }
      </MapElement>
      {
        !mapLoaded && (
          <LoadingSpinner style={{ top: '37%', left: '43%', position: 'absolute' }} />
        )
      }
      {isSearch && (
      <ListingSearchThisArea
        loading={!mapLoaded || !prevMapBounds.topLeft || !prevMapBounds.bottomRight}
        checked={searchThisArea}
        onChange={handleSearchThisAreaChange}
      />
      )}
    </div>
  );
};

ListingMap.defaultProps = {
  isSearch: false,
  onMapBoundChange: () => {},
  selectedListing: null,
  selectedBounds: {
    topLeft: undefined,
    bottomRight: undefined,
  },
  markers: [],
  setSelectedListing: () => {},
};

ListingMap.propTypes = {
  isSearch: PropTypes.bool,
  markers: PropTypes.arrayOf(PropTypes.shape({})),
  onMapBoundChange: PropTypes.func,
  selectedBounds: PropTypes.shape({
    topLeft: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    bottomRight: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
  }),
  selectedListing: PropTypes.shape({}),
  setSelectedListing: PropTypes.func,
};

export default ListingMap;
