import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AsyncSubscribe } from 'asyncHandlers/AsyncBase';
import {
  setStore, updateAmenityMatches,
  updateHoodMatches, updateSubwayStopMatches, convertHoodSearchToSelectedValues,
  convertSubwayStopToSelectedValues,
  updateSubwayLines, updateSubwayLineMatches, updateRegionalHoods, updateRegionalHoodMatches,
} from 'reducers/search';

class SearchPopoverDataFetching extends Component {
  componentDidUpdate(prevProps) {
    const {
      amenities: prevAmenities,
      regions: prevRegions,
      hoods: prevHoods,
      subways: prevSubways,
      subwayStops: prevSubwayStops,
    } = prevProps;

    const {
      amenities,
      regions,
      hoods,
      subways,
      subwayStops,
      _setStore,
      _updateSubwayStopMatches,
      _updateSubwayLineMatches,
      _updateHoodMatches,
      _updateAmenityMatches,
      _convertHoodSearchToSelectedValues,
      _convertSubwayStopToSelectedValues,
      _updateSubwayLines,
      _updateRegionalHoods,
      _updateRegionalHoodMatches,
    } = this.props;

    if (subwayStops !== prevSubwayStops) {
      _setStore({ subwayStops });
      _updateSubwayStopMatches();
      _convertSubwayStopToSelectedValues();
      const event = new CustomEvent('paramsinitialized');
      window.dispatchEvent(event);
    }
    if (amenities !== prevAmenities) {
      _setStore({ amenities });
      _updateAmenityMatches();
    }
    if (regions !== prevRegions) {
      _setStore({ regions });
    }
    if (hoods !== prevHoods) {
      _setStore({ hoods });
      _updateHoodMatches();
      const event = new CustomEvent('paramsinitialized');
      window.dispatchEvent(event);
    }
    if (subways !== prevSubways) {
      _setStore({ subways });
    }
    if (subways !== prevSubways || subwayStops !== prevSubwayStops) {
      _updateSubwayLines();
      _updateSubwayLineMatches();
    }
    if ((regions !== prevRegions || hoods !== prevHoods) && regions && hoods) {
      _convertHoodSearchToSelectedValues();
      _updateRegionalHoods();
      _updateRegionalHoodMatches();
    }
  }

  render() {
    return null;
  }
}

SearchPopoverDataFetching.defaultProps = {
  amenities: null,
  subways: null,
  hoods: null,
  subwayStops: null,
  regions: null,
};

SearchPopoverDataFetching.propTypes = {
  amenities: PropTypes.arrayOf(PropTypes.string),
  subways: PropTypes.arrayOf(PropTypes.string),
  hoods: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  subwayStops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  _setStore: PropTypes.func.isRequired,
  _updateAmenityMatches: PropTypes.func.isRequired,
  _updateHoodMatches: PropTypes.func.isRequired,
  _updateSubwayStopMatches: PropTypes.func.isRequired,
  _convertHoodSearchToSelectedValues: PropTypes.func.isRequired,
  _convertSubwayStopToSelectedValues: PropTypes.func.isRequired,
  _updateSubwayLines: PropTypes.func.isRequired,
  _updateSubwayLineMatches: PropTypes.func.isRequired,
  _updateRegionalHoods: PropTypes.func.isRequired,
  _updateRegionalHoodMatches: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  _setStore: payload => dispatch(setStore(payload)),
  _updateAmenityMatches: () => dispatch(updateAmenityMatches()),
  _updateHoodMatches: () => dispatch(updateHoodMatches()),
  _updateSubwayStopMatches: () => dispatch(updateSubwayStopMatches()),
  _convertHoodSearchToSelectedValues: region => dispatch(convertHoodSearchToSelectedValues(region)),
  _convertSubwayStopToSelectedValues: () => dispatch(convertSubwayStopToSelectedValues()),
  _updateSubwayLines: () => dispatch(updateSubwayLines()),
  _updateSubwayLineMatches: () => dispatch(updateSubwayLineMatches()),
  _updateRegionalHoods: () => dispatch(updateRegionalHoods()),
  _updateRegionalHoodMatches: () => dispatch(updateRegionalHoodMatches()),
});

const subscriptions = [
  'amenities',
  'regions',
  'hoods',
  'subways',
  'subwayStops',
];

const AsyncSubscriber = AsyncSubscribe(subscriptions)(SearchPopoverDataFetching);
export default connect(null, mapDispatchToProps)(AsyncSubscriber);
