// INITIAL STATE
const initialState = {
  likedListingsFetch: false,
  likedRoommatesFetch: false,
}


//ACTION TYPES
const LIKED_LISTING_FETCHED = 'LIKED_LISTING_FETCHED';
const LIKED_ROOMMATES_FETCHED = 'LIKED_ROOMMATES_FETCHED';


//ACTION CREATORS
export const fetchListingLikesTrue = () => ({ type: LIKED_LISTING_FETCHED });
export const fetchRoomateLikesTrue = () => ({ type: LIKED_ROOMMATES_FETCHED });


//REDUCERS
export default function reducer( state = initialState, action){
  switch (action.type) {
    case LIKED_LISTING_FETCHED:
      return { ...state, likedListingsFetch: true };
    case LIKED_ROOMMATES_FETCHED:
      return { ...state, likedRoommatesFetch: true };
    default:
      return state;
  }
};
