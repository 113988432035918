import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BathPop from '../searchPopovers/Bath';
import BathButton from '../sentenceSearchButtons/BathButton';
import Reposition from '../searchBarComponents/Reposition';

class Bath extends Component {
  render() {
    const { popoverWrapper, popoverContainer, popover } = this.props;
    return (
      <div
        ref={popoverContainer}
        className="button btn-transparent"
        id="listing-baths-selector"
        style={{ border: 'none' }}
      >
        <BathPop
          ref={{
            popoverWrapper,
            popover,
          }}
          displayProp="sentenceBathDisplay"
          searchContext="sentenceSearch"
        />
        <BathButton />
      </div>
    );
  }
}

Bath.defaultProps = {
  popoverWrapper: null,
  popoverContainer: null,
  popover: null,
};

Bath.propTypes = {
  popoverWrapper: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popoverContainer: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popover: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

export default Reposition(Bath);
