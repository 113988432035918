import React, { useContext, useEffect, useState } from 'react';
import SearchableDropdown from 'components/generic/SearchableDropdown';
import PropTypes from 'prop-types';
import Agent from './Agent';
import AgentSelectorContext from '../AgentSelectorContext';

// Props:
// --------------------------------------------------
// type - Method of Dropdown showing: Popover or Modal (Required)

// Context:
// --------------------------------------------------
// agents - List of All Agents
// isLoading - Boolean Telling if Component is Loading Still
// setSelected - Funtion to Set Agents Selected
// selected - Agents Selected
// singleSelect - Single Selects Only

const AgentTypeahead = props => {
  const { type } = props;
  const [agentOptions, setAgentOptions] = useState([]);

  const {
    agents,
    isLoading,
    setSelected,
    selected,
    singleSelect,
  } = useContext(AgentSelectorContext);

  const handleUpdate = updatedSelected => {
    setSelected(updatedSelected);
  };

  useEffect(() => {
    if (!isLoading && agents) {
      // Maps Agents to Agent Component
      // ID, Value both needed for Searchable Dropdown
      const agentComponents = agents.map(agent => (
        <Agent
          key={agent.id}
          id={agent.id}
          name={agent.fullName}
          thumbUrl={agent.avatarThumb}
          value={agent.fullName}
        />
      ));

      setAgentOptions(agentComponents);
    }
  }, [isLoading, agents]);

  return (
    <SearchableDropdown
      placeholder="Search Agents"
      onUpdate={handleUpdate}
      initSelected={selected}
      loading={isLoading}
      noCheck
      singleSelect={singleSelect}
      showTagsInFilter={!singleSelect}
      type={type}
    >
      {agentOptions}
    </SearchableDropdown>
  );
};

AgentTypeahead.propTypes = {
  type: PropTypes.oneOf(['popover', 'modal']).isRequired,
};

export default AgentTypeahead;
