import React from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'helpers/util';
import LikeButton from 'components/listings/cards/components/buttons/LikeButton';
import ShareButton from 'components/listings/cards/components/buttons/ShareButton';
import TourButton from 'components/listings/cards/components/buttons/TourButton';
import SubwayRow from 'components/listings/cards/components/SubwayRow';

const ListingCardBottom = props => {
  const {
    listing,
    signedIn,
  } = props;

  const {
    id,
    url,
    price,
    subway_lines: subwayLines,
    bedrooms,
    bathrooms,
    neighborhood,
    short_address: shortAddress,
    residential,
    square_feet: sqFt,
    meta,
    rental,
    market_as: marketAs,
    concessions,
    sales_agent: salesAgent,
  } = listing || {};

  let lowestConcession;
  let lowestConcessionText;

  if (concessions && concessions.length) {
    lowestConcession = concessions
      .filter(concession => concession.unit_free > 0)
      .filter(concession => (concession.unit_free <= 12 && concession.unit_type === 'month')
        || (concession.unit_free <= 52 && concession.unit_type === 'week')
        || (concession.unit_free <= 365 && concession.unit_type === 'day'))
      .filter(concession => concession.net_effective_rent > 0
        && concession.net_effective_rent < (price / 100))
      .sort((firstItem, secondItem) => firstItem.net_effective_rent
        - secondItem.net_effective_rent)
      .shift();

    if (lowestConcession) {
      lowestConcessionText = `${lowestConcession.unit_free} ${lowestConcession.unit_type}${lowestConcession.unit_free > 1 ? 's' : ''} free`;
    }
  }

  return (
    <div>
      <a href={`${url}`} target="_blank">
        <div className="listing-card-stub container-fluid">
          <div className="row">
            <div className="col-6 text-left" style={{ fontSize: '14px', fontWeight: '600', borderRight: '0' }}>
              {(lowestConcession !== undefined)
                ? (
                  <span>
                    <span style={{ textDecorationLine: 'line-through' }}>{accounting.formatMoney(price / 100, { precision: 0 })}</span>
                    {' '}
                    <span style={{ color: 'rgb(255, 192, 58)' }}>{accounting.formatMoney(lowestConcession.net_effective_rent, { precision: 0 })}</span>
                  </span>
                ) : (
                  <span>
                    {accounting.formatMoney(price / 100, { precision: 0 })}
                  </span>
                )}
            </div>
            <div className="col-6 text-right" style={{ color: '#9c9c9c', fontSize: '12px', fontStyle: 'italic' }}>
              {lowestConcession !== undefined && (
                <div>
                  {lowestConcessionText}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="listing-card-stub container-fluid">
          <div className="row top-row">
            <div className="col-12" style={{ fontSize: '14px' }}>
              {truncate(neighborhood.name, 25)}
            </div>
          </div>
        </div>
        <div className="listing-card-stub container-fluid">
          <div className="row top-row">
            <div className="col-4 text-left">
              {residential && (
                bedrooms > 0 ? `${bedrooms} bed` : 'studio'
              )}
              {!residential && (
                price && sqFt
                  ? `${accounting.formatMoney(price * 12 / 100 / sqFt)} PPSF`
                  : 'No PPSF Provided'
              )}
            </div>
            <div className="col-4 text-left">
              {`${bathrooms} bath`}
            </div>
            <div className="col-4 text-left" style={{ paddingLeft: '10px' }}>
              {rental
                ? <SubwayRow subwayLines={subwayLines} /> : (!!sqFt && `${sqFt} sq.ft`)}
            </div>
          </div>
        </div>
        <div className="listing-card-stub container-fluid">
          <div className="row top-row">
            {rental
              ? (
                <div className="col-12 text-left">
                  {shortAddress}
                </div>
              ) : (
                <>
                  <div className="col-8 text-left">
                    {shortAddress}
                  </div>
                  <div className="col-4 text-left" style={{ paddingLeft: '10px' }}>
                    {marketAs}
                  </div>
                </>
              )}
          </div>
        </div>
      </a>
      <div className="listing-card-stub container-fluid card-bottom-row-border">
        <div style={{ padding: '0px', cursor: 'pointer' }} className="row top-row bottom-row">
          <LikeButton id={id} type="listing" liked={meta.liked} signedIn={signedIn} />
          <ShareButton type="col" url={url} />
          <TourButton propertyId={id} salesAgent={salesAgent} signedIn={signedIn} />
        </div>
      </div>
    </div>
  );
};

ListingCardBottom.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.number,
    subway_lines: PropTypes.arrayOf(PropTypes.string),
    url: PropTypes.string,
    price: PropTypes.number,
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    neighborhood: PropTypes.shape({
      name: PropTypes.string,
    }),
    meta: PropTypes.shape({
      liked: PropTypes.bool,
    }),
    concessions: PropTypes.array,
  }).isRequired,
  signedIn: PropTypes.bool.isRequired,
};

export default ListingCardBottom;
