import React from 'react';

const buildReducer = (reducer, initialState) => {
  const stateRef = React.createRef();
  const getState = () => stateRef.current;

  stateRef.current = initialState;

  const wrappedReducer = (state, action) => {
    const newState = reducer(state, action);

    stateRef.current = newState;

    return newState;
  };

  return [wrappedReducer, getState];
};

export default buildReducer;
