import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from 'components/LoadingSpinner';
import { isCurrency } from 'helpers/util';
import { createPaymentChargeDescriptors } from '../../reducers/payment';

class PaymentAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      disabled: true,
      amount: '',
    };
  }

  handleChange = evt => {
    const cleanedInput = parseFloat(evt.target.value.replace(/[^\d\.\-]/g, ''));
    if (Number.isNaN(cleanedInput) || cleanedInput === 0 || !isCurrency(cleanedInput)) {
      this.setState({
        error: 'amount must be a valid currency amount',
        disabled: true,
      });
    } else {
      this.setState({ error: '', disabled: false, amount: cleanedInput });
    }
  };

  handleSubmit = (evt, lease_id) => {
    evt.preventDefault();
    const amount = Math.ceil(this.state.amount * 100);
    this.props.createPaymentChargeDescriptors(lease_id, amount);
    this.props.submitForm();
  };

  render() {
    const { lease_id, submitting, asyncErrors } = this.props;
    const { error, disabled } = this.state;

    return (
      <div className="container payment-container">
        <h1>NOOKLYN PAY</h1>
        <hr className="entire-width" />
        <form
          className="payment-amount"
          onSubmit={evt => this.handleSubmit(evt, lease_id)}
        >
          <p> How much would you like to pay today? </p>
          <div className="row justify-content-center">
            <div className="col-sm-5">
              <input
                onChange={this.handleChange}
                className="general-text-field"
                placeholder="Amount"
                type="text"
                name="payment_amount"
                id="payment_amount"
                style={error ? { borderColor: 'red' } : {}}
              />
              {error && <p className="error">{String(error)}</p>}
              {asyncErrors && <p className="error">{String(asyncErrors)}</p>}
            </div>
            <div className="col-sm-2">
              <button
                type="submit"
                name="commit"
                value="Pay"
                className="button btn-request btn-rounded"
                disabled={disabled || submitting}
              >
                { submitting ? (
                  <LoadingSpinner />
                ) : <span>Pay</span>}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapState = state => ({
  asyncErrors: state.payment.errors,
  submitting: state.payment.submitting,
});

const mapDispatch = dispatch => ({
  createPaymentChargeDescriptors(lease_id, amount) {
    dispatch(createPaymentChargeDescriptors(lease_id, amount));
  },
});

export default connect(
  mapState,
  mapDispatch,
)(PaymentAmount);
