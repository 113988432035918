import {
  searchListings,
  searchSales,
  searchCommercials,
} from 'api/listing';
import { searchRooms } from 'api/room';
import { searchRoommates } from 'api/roommates';

const generateSearchResults = async (selectedFilters, type) => {
  const searchParams = {
    amenityList: selectedFilters.amenities || [],
    address: selectedFilters.address || '',
    minBaths: parseInt(selectedFilters.baths, 10) || 0,
    bedList: selectedFilters.bedrooms
      ? selectedFilters.bedrooms.map(bed => parseInt(bed, 10) || 0)
      : [],
    liked: selectedFilters.likedOnly || false,
    marketAs: selectedFilters.marketAs || '',
    moveIn: selectedFilters.moveBy || '',
    noFee: selectedFilters.noFeeOnly || false,
    order: selectedFilters.sortBy || '',
    pets: selectedFilters.pets || '',
    platformListing: selectedFilters.subletOnly || false,
    minPpsf: selectedFilters.ppsf
      ? parseInt(selectedFilters.ppsf[0], 10) || null
      : null,
    maxPpsf: selectedFilters.ppsf
      ? parseInt(selectedFilters.ppsf[1], 10) || null
      : null,
    minPrice: selectedFilters.price
      ? parseInt(selectedFilters.price[0], 10) || null
      : null,
    maxPrice: selectedFilters.price
      ? parseInt(selectedFilters.price[1], 10) || null
      : null,
    minSquareFeet: selectedFilters.squareFeet
      ? parseInt(selectedFilters.squareFeet[0], 10) || null
      : null,
    maxSquareFeet: selectedFilters.squareFeet
      ? parseInt(selectedFilters.squareFeet[1], 10) || null
      : null,
    network: selectedFilters.network || null,
    page: selectedFilters.page || 1,
    neighborhoodIdList: selectedFilters.neighborhoods || [],
    regionIdList: selectedFilters.regions || [],
    subwayIdList: selectedFilters.subways || [],
    subwayStopList: selectedFilters.subwayStops || [],
  };
  const bounds = (!!selectedFilters.topLeft && !!selectedFilters.bottomRight) ? {
    topLeft: selectedFilters.topLeft,
    bottomRight: selectedFilters.bottomRight,
  } : {};

  switch (type) {
    case 'sales':
      return searchSales({ ...searchParams, ...bounds });
    case 'commercials':
      return searchCommercials({ ...searchParams, ...bounds });
    case 'rooms':
      return searchRooms({ ...searchParams, ...bounds });
    case 'roommates':
      return searchRoommates({ ...searchParams, ...bounds });
    default:
      return searchListings({ ...searchParams, ...bounds });
  }
};

export default generateSearchResults;
