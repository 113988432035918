import React, { useState } from 'react';
import { connect } from 'react-redux';
import IndividualHood from './IndividualHood';

const IndividualRegion = props => {
  const [showHoods, setShowHoods] = useState(false);
  const { hood, handleSelectRegion, handleSelectHood, hoodFilter, selectedHoods } = props;
  const neighborhoodsCount = hood.neighborhoods.length;
  const selectedRegionalHoods = selectedHoods.filter(selectedHood => selectedHood.region === hood.region);
  const selected = selectedRegionalHoods.length === neighborhoodsCount;
  const partiallySelected = selectedRegionalHoods.length > 0 && selectedRegionalHoods.length < neighborhoodsCount;

  const handleHoodClick = e => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      setShowHoods(!showHoods);
    }
  };

  return (
    <>
      <div className="d-flex position-relative">
        <div
          data-name={hood.region}
          className={`${selected || partiallySelected ? "matrix-checkbox-checked" : "matrix-checkbox"} ${!!hoodFilter.length ? 'disabled' : '' } ${partiallySelected ? "" : ""}`}
          onMouseDown={e => handleSelectRegion(e, hood.region)}
        >
          <span className={selected ? 'checkmark' : ''} />
          <span className={partiallySelected ? 'partial-checkmark ' : ''} />
        </div>
        <div
          style={{ cursor: 'pointer', userSelect: 'none', margin: '3px 8px', width: '100%' }} onMouseDown={handleHoodClick}>
          <span style={{ fontSize: '14px' }} onMouseDown={e => handleSelectRegion(e, hood.region)}>{hood.region}</span>
          <div className="d-flex flex-wrap" style={{ marginLeft: '-0.5rem' }}>
            { showHoods && hood.neighborhoods && hood.neighborhoods.map(hood => <IndividualHood handleSelectHood={handleSelectHood} key={hood.id} id={hood.id} hood={hood} />)}
          </div>
        </div>
        { !showHoods ? (
          <div style={{ position: 'absolute', right: 0, margin: '8px 20px', cursor: 'pointer'}} onClick={() => setShowHoods(true)}><i className="nookons-chevron-down ml-2"/></div>
        ) : (
          <div style={{ position: 'absolute', right: 0, margin: '8px 20px', cursor: 'pointer'}} onClick={() => setShowHoods(false)}><i className="nookons-chevron-up ml-2"/></div>
        ) }
      </div>
      <hr style={{ margin: '5px 20px 5px 0' }} />
    </>
  );
};

const mapStateToProps = state => ({
  selectedRegions: state.search[state.search.context].selectedRegions,
  selectedHoods: state.search[state.search.context].selectedHoods,
  hoodFilter: state.search[state.search.context].hoodFilter,
});


export default connect(mapStateToProps, null)(IndividualRegion);
