import React, { useEffect } from 'react';
import ContainerWrapper from '../ContainerWrapper';

const title = "That's it for pricing";

const PricingReview = (props) => {
  const {
    price,
    moveInCosts,
    utilities,
    handleJump,
    handleBack,
    handleNext,
    setUserIsEditing,
  } = props;

  useEffect(() => {
    document.title = `Create: Apartments - ${title}`;
  }, []);

  return (
    <ContainerWrapper>
      <div>
        <div className="title">
          <h3>{title}</h3>
          <p>Is everything correct?</p>
        </div>
        <form
          className="summary-form"
          onKeyDown={(evt) => {
            if (evt.which === 13) evt.preventDefault();
          }}
        >
          <div className="border-box striped-box">
            <div className="row">
              <div className="col-11">
                <label className="m-1">Monthly Rent</label>
                <span className="float-right m-1">
                  {accounting.formatMoney(price / 100, { precision: 0 })}
                </span>
              </div>
              <div className="col-1">
                <i
                  className="nklyn-2x nookons-pencil edit"
                  onClick={() => {
                    handleJump('Rent');
                    setUserIsEditing(true);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-11">
                <label className="m-1">Move-In Costs</label>
                <span className="float-right m-1">{moveInCosts.join(', ')}</span>
              </div>
              <div className="col-1">
                <i
                  className="nklyn-2x nookons-pencil edit"
                  onClick={() => {
                    handleJump('MoveInCosts');
                    setUserIsEditing(true);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-11">
                <label className="m-1">Utilities</label>
                <span className="float-right m-1">{utilities.join(', ')}</span>
              </div>
              <div className="col-1">
                <i
                  className="nklyn-2x nookons-pencil edit"
                  onClick={() => {
                    handleJump('Utilities');
                    setUserIsEditing(true);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <button
                onClick={handleBack}
                className="button btn-transparent-white btn-rounded float-right"
              >
                Back
              </button>
            </div>
            <div className="col-6">
              <button onClick={handleNext} className="button btn-transparent-yellow btn-rounded">
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </ContainerWrapper>
  );
};

export default PricingReview;
