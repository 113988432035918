import React, { PureComponent } from 'react';

export default class CustomInput extends PureComponent {

  state = {
    value: this.props.value,
  }

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.input.blur();
      this.onBlur(e);
    }
    this.props.onKeyDown(e);
  }

  onChange = (e) => {
    const val = e.target.value;
    let final = val;
    if (val.startsWith(this.props.beforeValue)) {
      final = val.replace(this.props.beforeValue, '');
    }
    this.props.onChange(final);
  }

  render () {
    const {
      value,
      beforeValue,
      className,
      style,
      placeholder,
      onFocus,
      onClick,
      onMouseOver,
      onMouseEnter,
      onMouseLeave,
      onMouseDown,
      onTouchStart,
      onTouchEnd,
      onTouchMove,
      onBlur,
    } = this.props;
    const { onKeyDown } = this;

    return (
      <input
        onMouseDown={onMouseDown}
        onFocus={onFocus}
        onMouseOver={onMouseOver}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
        onClick={onClick}
        placeholder={placeholder}
        ref={me => this.input = me}
        style={{ ...style, userSelect: 'auto' }}
        className={className}
        value={value.length > 0 ? `${beforeValue}${value}` : ''}
        onKeyPress={onKeyDown}
        onChange={this.onChange}
        type='text'
        onBlur={onBlur}
      />
    );
  }
}

const defaultFunc = () => '';
CustomInput.defaultProps = {
  onBlur: defaultFunc,
  onChange: defaultFunc,
  onMouseDown: defaultFunc,
  onKeyDown: defaultFunc,
  onMouseOver: defaultFunc,
  onMouseEnter: defaultFunc,
  onMouseLeave: defaultFunc,
  onTouchStart: defaultFunc,
  onTouchEnd: defaultFunc,
  onTouchMove: defaultFunc,
  sendUpdatedValue: defaultFunc,
  onClick: defaultFunc,
  className: '',
  style: {},
  placeholder: '',
  value: '',
  beforeValue:'',
};
