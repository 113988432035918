import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Address,
  AddressSummary,
  Rent,
  MoveInCosts,
  Utilities,
  PricingReview,
  Beds,
  Bathrooms,
  SqFootage,
  Pets,
  MoveInDay,
  MoveOutDay,
  Amenities,
  Description,
  ApartmentReview,
} from './Apartment/index';

// getApartmentSteps establish the sequence of the steps
function getApartmentSteps() {
  return [
    'Address',
    'AddressSummary',
    'Rent',
    'MoveInCosts',
    'Utilities',
    'PricingReview',
    'Beds',
    'Bathrooms',
    'SqFootage',
    'Pets',
    'MoveInDay',
    'MoveOutDay',
    'Amenities',
    'Description',
    'ApartmentReview',
  ];
}

const Apartment = (props) => {
  const { listing, listingId } = props;
  const [address, setAddress] = useState(listing.address);
  const [leaseTerm, setLeaseTerm] = useState(listing.leaseTerm);
  const [utilities, setUtilities] = useState(listing.utilities);
  const [description, setDescription] = useState(listing.description);
  const [price, setPrice] = useState(listing.price); // api values in cents
  const [moveInCosts, setMoveInCosts] = useState(listing.moveInCosts);
  const [brokerFee, setBrokerFee] = useState(listing.brokerFee);
  const [beds, setBeds] = useState(listing.beds);
  const [fullBaths, setFullBaths] = useState(listing.fullBaths);
  const [halfBaths, setHalfBaths] = useState(listing.halfBaths);
  const [sqFootage, setSqFootage] = useState(listing.sqFootage);
  const [petPolicy, setPetPolicy] = useState(listing.petPolicy);
  const [amenities, setAmenities] = useState(listing.amenities);
  const [dateAvailable, setDateAvailable] = useState(listing.dateAvailable);
  const [moveOutDate, setMoveOutDate] = useState(listing.moveOutDate);
  const [userIsEditing, setUserIsEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const source = axios.CancelToken.source();

  const [activeStep, setActiveStep] = useState(0);
  const steps = getApartmentSteps();

  function dateFlip(date) {
    const [month, day, year] = date.split('/');
    return [day, month, year].join('/');
  }

  function handleSubmit(type) {
    setSubmitting(true);
    const apartment = {
      address,
      lease_term: leaseTerm,
      included_utilities: utilities,
      description,
      price: +price,
      move_in_costs: moveInCosts,
      broker_fee: brokerFee,
      beds,
      full_baths: fullBaths,
      half_baths: halfBaths,
      sq_footage: sqFootage,
      pet_policy: petPolicy,
      date_available: dateFlip(dateAvailable),
      move_out_date: dateFlip(moveOutDate),
      amenities,
    };

    const url = type === 'create'
      ? '/api/v2/listings.create'
      : `/api/v2/listings.edit?listing_id=${listingId}`;

    axios
      .post(
        url,
        {
          ...apartment,
        },
        { headers: { API_TOKEN: localStorage.getItem('apiToken') } },
        { cancelToken: source.token },
      )
      .then((res) => {
        setSubmitting(false);
        props.handleApartmentSubmit(res.data.listing_id);
      })
      .catch((err) => {
        setSubmitting(false);
        console.error(err);
      });
  }

  useEffect(
    () => () => {
      source.cancel('Operation canceled');
    },
    [],
  );

  function handleNext() {
    if (activeStep < steps.length - 1) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  }

  function handleBack() {
    if (activeStep > 0) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    } else {
      props.setShowSummary(true);
    }
  }

  function handleJump(step) {
    setActiveStep(steps.indexOf(step));
  }
  function getStepContent(step) {
    switch (step) {
      case 'Address':
        return (
          <Address
            address={address}
            setAddress={setAddress}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 'AddressSummary':
        return <AddressSummary address={address} handleNext={handleNext} handleBack={handleBack} />;
      case 'Rent':
        return (
          <Rent
            price={price}
            setPrice={setPrice}
            handleNext={handleNext}
            handleBack={handleBack}
            userIsEditing={userIsEditing}
            handleJump={handleJump}
            setUserIsEditing={setUserIsEditing}
          />
        );
      case 'MoveInCosts':
        return (
          <MoveInCosts
            moveInCosts={moveInCosts}
            setMoveInCosts={setMoveInCosts}
            userIsEditing={userIsEditing}
            handleNext={handleNext}
            handleBack={handleBack}
            handleJump={handleJump}
            setUserIsEditing={setUserIsEditing}
          />
        );
      case 'Utilities':
        return (
          <Utilities
            utilities={utilities}
            setUtilities={setUtilities}
            userIsEditing={userIsEditing}
            handleNext={handleNext}
            handleBack={handleBack}
            handleJump={handleJump}
            setUserIsEditing={setUserIsEditing}
          />
        );
      case 'PricingReview':
        return (
          <PricingReview
            price={price}
            moveInCosts={moveInCosts}
            utilities={utilities}
            setUserIsEditing={setUserIsEditing}
            handleNext={handleNext}
            handleBack={handleBack}
            handleJump={handleJump}
          />
        );
      case 'Beds':
        return (
          <Beds
            beds={beds}
            setBeds={setBeds}
            handleNext={handleNext}
            handleBack={handleBack}
            userIsEditing={userIsEditing}
            handleJump={handleJump}
            setUserIsEditing={setUserIsEditing}
          />
        );
      case 'Bathrooms':
        return (
          <Bathrooms
            halfBaths={halfBaths}
            fullBaths={fullBaths}
            setHalfBaths={setHalfBaths}
            setFullBaths={setFullBaths}
            handleNext={handleNext}
            handleBack={handleBack}
            userIsEditing={userIsEditing}
            handleJump={handleJump}
            setUserIsEditing={setUserIsEditing}
          />
        );
      case 'SqFootage':
        return (
          <SqFootage
            sqFootage={sqFootage}
            setSqFootage={setSqFootage}
            handleNext={handleNext}
            handleBack={handleBack}
            userIsEditing={userIsEditing}
            handleJump={handleJump}
            setUserIsEditing={setUserIsEditing}
          />
        );

      case 'Pets':
        return (
          <Pets
            petPolicy={petPolicy}
            setPetPolicy={setPetPolicy}
            handleNext={handleNext}
            handleBack={handleBack}
            userIsEditing={userIsEditing}
            handleJump={handleJump}
            setUserIsEditing={setUserIsEditing}
          />
        );
      case 'MoveInDay':
        return (
          <MoveInDay
            dateAvailable={dateAvailable}
            setDateAvailable={setDateAvailable}
            setMoveOutDate={setMoveOutDate}
            handleNext={handleNext}
            handleBack={handleBack}
            userIsEditing={userIsEditing}
            handleJump={handleJump}
            setUserIsEditing={setUserIsEditing}
          />
        );
      case 'MoveOutDay':
        return (
          <MoveOutDay
            moveOutDate={moveOutDate}
            setMoveOutDate={setMoveOutDate}
            handleNext={handleNext}
            handleBack={handleBack}
            userIsEditing={userIsEditing}
            handleJump={handleJump}
            setUserIsEditing={setUserIsEditing}
          />
        );
      case 'Amenities':
        return (
          <Amenities
            amenities={amenities}
            setAmenities={setAmenities}
            handleNext={handleNext}
            handleBack={handleBack}
            userIsEditing={userIsEditing}
            handleJump={handleJump}
            setUserIsEditing={setUserIsEditing}
          />
        );
      case 'Description':
        return (
          <Description
            description={description}
            setDescription={setDescription}
            handleNext={handleNext}
            handleBack={handleBack}
            userIsEditing={userIsEditing}
            handleJump={handleJump}
            setUserIsEditing={setUserIsEditing}
          />
        );
      case 'ApartmentReview':
        return (
          <ApartmentReview
            price={price}
            moveInCosts={moveInCosts}
            utilities={utilities}
            beds={beds}
            fullBaths={fullBaths}
            halfBaths={halfBaths}
            sqFootage={sqFootage}
            petPolicy={petPolicy}
            dateAvailable={dateAvailable}
            moveOutDate={moveOutDate}
            amenities={amenities}
            description={description}
            handleJump={handleJump}
            handleSubmit={handleSubmit}
            handleBack={handleBack}
            setUserIsEditing={setUserIsEditing}
            listingId={listingId}
            submitting={submitting}
          />
        );
      default:
        return <div />;
    }
  }

  return <div>{getStepContent(steps[activeStep])}</div>;
};

Apartment.defaultProps = {
  listing: {},
  listingId: '',
};

export default Apartment;
