import React, { forwardRef } from 'react';
import { Row } from 'wrappers/FlexTable';
import PropTypes from 'prop-types';
import TagList from './TagList';

const Filter = forwardRef((props, ref) => {
  const {
    className,
    onChange,
    placeholder,
    showTagsInFilter,
    style,
    tagClassName,
  } = props;

  return (
    <Row className={`searchable-dropdown-filter ${className}`} style={style}>
      {showTagsInFilter && <TagList className={tagClassName} />}
      <input
        placeholder={placeholder}
        onChange={onChange}
        ref={ref}
        type="search"
      />
    </Row>
  );
});
export default Filter;

Filter.propTypes = {
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  showTagsInFilter: PropTypes.bool.isRequired,
  style: PropTypes.shape({}).isRequired,
  tagClassName: PropTypes.string.isRequired,
};
