import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Column, Row } from 'wrappers/FlexTable';
import Checkbox from 'components/generic/formInputs/Checkbox';
import AgentSelectorContext from '../AgentSelectorContext';

// Props:
// --------------------------------------------------
// name - Agent Name (Required)
// selected - If this option is selected (Optional) (Default: false)
// thumbUrl - Url To Agent Photo (Optional) (Default: '')
// value - Option Value (Required)

const Agent = props => {
  const {
    id,
    name,
    thumbUrl,
  } = props;

  const {
    selected,
  } = useContext(AgentSelectorContext);

  return (
    <Row className="agent-selector-agents">
      <Column className="agent-selector-agents-checkbox">
        <Checkbox checked={selected.find(item => item[0] === id) !== undefined} readOnly />
      </Column>
      <Column className="agent-selector-agents-name">
        {name}
      </Column>
      <Column className="agent-selector-agents-image">
        <img alt={`${name}`} src={thumbUrl} />
      </Column>
    </Row>
  );
};

Agent.defaultProps = {
  thumbUrl: '',
};

Agent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  thumbUrl: PropTypes.string,
};

export default Agent;
