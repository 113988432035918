import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';
import Popover from 'components/generic/Popover';
import MarketAsPop from './MarketAsPopover';

const MarketAs = props => {
  const {
    selected,
    onChange,
    loading,
    options,
  } = props;

  const targetRef = useRef(null);
  const [value, setValue] = useState(selected);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  const className = value.length > 0 && !loading ? 'btn-transparent-yellow' : 'btn-transparent-gray';

  return (
    <Column className="searchbar-col">
      <Popover type="click" targetRef={targetRef}>
        <MarketAsPop value={value} onChange={onChange} options={options} loading={loading} />
      </Popover>
      <input
        ref={targetRef}
        name="marketAs"
        type="button"
        className={`button btn-rounded ${className} btn-search-bar-size`}
        value="Market As"
      />
    </Column>
  );
};

MarketAs.defaultProps = {
  selected: '',
  onChange: () => {},
  loading: false,
};

MarketAs.propTypes = {
  selected: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

export default MarketAs;
