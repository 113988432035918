import React from 'react';
import CopyUrlButton from './CopyUrlButton';

const CopyUrlListingCollection = () => (
  <CopyUrlButton>
    <div
      style={{
        cursor: 'pointer',
        color: '#fff',
      }}
    >
      Copy URL
    </div>
  </CopyUrlButton>
);
export default CopyUrlListingCollection;
