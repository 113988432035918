import React, { useState, useEffect, useRef } from 'react';
import ListMatrixListings from 'containers/ListMatrixListings';
import PropTypes from 'prop-types';

const Matrix = props => {
  const {
    currentUser,
    id,
    showMatrix,
  } = props;

  const [prevMatrixShow, setPrevMatrixShow] = useState(showMatrix);
  const [animation, setAnimation] = useState(-1);
  const matrixEl = useRef(null);
  const isEmployee = currentUser && currentUser.permissions && currentUser.permissions.length > 0;

  const expandMatrix = () => {
    const sectionHeight = matrixEl.current.scrollHeight;
    setAnimation(requestAnimationFrame(() => {
      matrixEl.current.style.height = `${sectionHeight}px`;
      matrixEl.current.style.transform = 'scale(1)';
      const callback = () => {
        matrixEl.current.style.height = 'auto';
        matrixEl.current.removeEventListener('transitionend', callback);
      };
      matrixEl.current.addEventListener('transitionend', callback);
    }));
    setPrevMatrixShow(showMatrix);
  };

  const hideMatrix = () => {
    const sectionHeight = matrixEl.current.scrollHeight;
    setAnimation(requestAnimationFrame(() => {
      matrixEl.current.style.height = `${sectionHeight}px`;
      matrixEl.current.style.transform = 'scale(0.5, 0)';
      const callback = () => {
        matrixEl.current.style.height = '0px';
        matrixEl.current.removeEventListener('transitionend', callback);
      };
      matrixEl.current.addEventListener('transitionend', callback);
    }));
    setPrevMatrixShow(showMatrix);
  };

  useEffect(() => {
    if (prevMatrixShow && !showMatrix) {
      cancelAnimationFrame(animation);
      hideMatrix();
    } if (!prevMatrixShow && showMatrix) {
      cancelAnimationFrame(animation);
      expandMatrix();
    }
  }, [showMatrix]);

  return (
    <div
      ref={matrixEl}
      style={{
        position: 'relative',
        height: 0,
        transform: 'scale(0.5, 0)',
        transformOrigin: 'top',
        transition: 'all 0.3s ease-in-out',
      }}
    >
      {isEmployee && <ListMatrixListings listingIds={[id]} />}
    </div>
  );
};

Matrix.propTypes = {
  showMatrix: PropTypes.bool.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currentUser: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default Matrix;
