import React, { Component } from 'react';
import axios from 'axios';

class ListingSyndicationModalContent extends Component {
  source = axios.CancelToken.source();

  state = {
    syndications: [],
    value: '',
    error: '',
  };

  componentDidMount() {
    axios
      .get(
        `/api/v1/listings.syndications.list?listing_id=${this.props.listingId}`,
        {
          headers: { API_TOKEN: localStorage.getItem('apiToken') },
          cancelToken: this.source.token,
        }
      )
      .then(res => {
        this.setState({ syndications: res.data.syndications });
      })
      .catch(err => console.dir(err));
  }

  componentWillUnmount() {
    this.source.cancel('api call cancelled as component will unmount');
  }

  handleAddSyndication = evt => {
    evt.preventDefault();

    const listing_id = this.props.listingId;
    const type = this.state.value;

    if (
      !this.state.syndications.find(syndication => syndication.type === type)
    ) {
      axios
        .post(
          '/api/v1/listings.syndications.add',
          { listing_id, type },
          { headers: { API_TOKEN: localStorage.getItem('apiToken') } },
          { cancelToken: this.source.token }
        )
        .then(res => {
          if (res.data.syndication) {
            this.setState(state => ({
              syndications: [...state.syndications, res.data.syndication],
              error: ''
            }));
          }
        })
        .catch(err => {
          if (err.response && err.response.data) {
            if (err.response.data.error.code === 'unsyndicatable_error') {
              this.setState({
                error:
                  'This listing was not syndicated. A syndicatable listing needs to be an available rental listing with thumbnail image. Please re-upload the cover image and try again.',
              });
            } else {
              this.setState({ error: err.response.data.error.friendlyMessage });
            }
          }
        });
    }
  };

  handleRemoveSyndication = (evt, type) => {
    evt.preventDefault();
    const listing_id = this.props.listingId;
    console.log('type', type);

    axios
      .post(
        '/api/v1/listings.syndications.remove',
        {
          listing_id,
          type,
        },
        { headers: { API_TOKEN: localStorage.getItem('apiToken') } },
        { cancelToken: this.source.token }
      )
      .then(res =>
        this.setState(state => ({
          syndications: state.syndications.filter(
            syndication => syndication.type !== type
          ),
        }))
      )
      .catch(err => {
        if (err.response && err.response.data) {
          this.setState({ error: err.response.data.error.friendlyMessage });
        }
      });
  };

  handleChange = evt => {
    this.setState({ value: evt.target.value });
  };

  render() {
    const { syndications, error, value } = this.state;

    return (
      <div className="container invite-container">
        <h1>Listing Syndications</h1>
        <hr className="entire-width" />
        {error && <p className="error text-center">{error}</p>}
        <div className="row justify-content-center">
          {syndications && !!syndications.length ? (
            syndications.map((syndication, idx) => (
              <div key={idx} className="col-3">
                <div className="label label-nklyn-blue p-2 m-2 rounded text-center">
                  {syndication.type}
                  {syndication.pending && "\n(pending)"}
                </div>
                <button
                  type="button"
                  className="button btn-1x btn-white-black"
                  onClick={evt => this.handleRemoveSyndication(evt, syndication.type)}
                >
                  Remove
                </button>
              </div>
            ))
          ) : (
            <div>
              {' '}
              Currently, there is no syndication associated with this listing{' '}
            </div>
          )}
        </div>
        <hr className="entire-width" />
        <form onSubmit={this.handleAddSyndication}>
          <select
            value={value}
            onChange={this.handleChange}
            className="custom-select mb-2"
          >
            <option value="">Select syndication to add</option>
            <option value="localize">Localize</option>
            <option value="costar">Apartments.com</option>
          </select>
          <input
            type="submit"
            value="Add Syndication"
            className="button btn-1x btn-yellow-black"
            disabled={!value}
          />
        </form>
      </div>
    );
  }
}

export default ListingSyndicationModalContent;
