import React from 'react';
import { AsyncSubscribe } from 'asyncHandlers/AsyncBase';
import MatePostCard from './MatePostCard';

const MateFaveMates = (props) => {
  const { favMates } = props;

  return (
    favMates && favMates.length > 0
      ? (
        <div className='favorite-locations-section'>
          <div>
            <h6>Favorite Roommates</h6>
            <div className='special-mates-container' style={{ textAlign: 'left' }}>
              { favMates.map(mate => (
                <MatePostCard key={`favMate-${mate.id}`} mate={mate} handleClick={() => { window.location.href = `/agents/${mate.agent.id}`; }} />
              ))
              }
            </div>
          </div>
        </div>
      ) : null
  );
};

const subscriptions = [
  'favMates',
];

export default AsyncSubscribe(subscriptions)(MateFaveMates);
