import React from 'react';
import PropTypes from 'prop-types';
import { AsyncProvider } from 'asyncHandlers/AsyncBase';
import MateAsync from 'asyncHandlers/MateAsync';
import MateFavMates from './MateFavMates';

const MateFavMatesContainer = (props) => {
  const { id } = props;
  return (
    <AsyncProvider handler={new MateAsync(null, id)}>
      <MateFavMates />
    </AsyncProvider>
  );
};

MateFavMatesContainer.propTypes = {
  id: PropTypes.number.isRequired,
};
export default MateFavMatesContainer;
