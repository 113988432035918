import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

function prefillDate(date) {
  let result = '';
  if (date) {
    const dateArray = date.split('-');
    result = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
  }
  return result;
}

function rearrangeDate(date) {
  let result = '';
  if (date) {
    const dateArray = date.split('/');
    result = `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
  }
  return result;
}


const DateOfBirth = (props) => {
  let { prefill } = props;
  prefill = prefill || '';
  const [formValue, setFormValue] = useState(prefill);
  const [value, setValue] = useState(prefillDate(prefill));
  function onChange(e) {
    const { value: newValue } = e.target;
    setValue(newValue);
    setFormValue(rearrangeDate(newValue));
  }
  return (
    <React.Fragment>
      <InputMask onChange={onChange} value={value} mask="99/99/9999">
        {inputProps => <input {...inputProps} type="" className="general-text-field-dark" />}
      </InputMask>
      <input type="hidden" {...props} value={formValue} />
    </React.Fragment>
  );
};

DateOfBirth.defaultProps = {
  prefill: '',
};

DateOfBirth.propTypes = {
  prefill: PropTypes.string,
};

export default DateOfBirth;
