import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class AmenitiesButton extends Component {

  render() {
    const { selectedAmenities, togglePopover } = this.props;
    let value = '';
    if (selectedAmenities.length === 0) {
      value = 'amenities:';
    } else if (selectedAmenities.length === 1) {
      value = `amenities: ${selectedAmenities[0]}`;
    } else {
      value = `amenities: ${selectedAmenities.length} selected`;
    }
    return (
      <a
        name='sentenceAmenity'
        onMouseDown={togglePopover}
        tabIndex="0"
        className="button btn-transparent"
        role="button"
        title="">{value}
      </a>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedAmenities: state.search[state.search.context].selectedAmenities,
});

const mapDispatchToProps = (dispatch) => ({
  togglePopover: (e) => dispatch(togglePopover(e))
});

export default connect(mapStateToProps, mapDispatchToProps)(AmenitiesButton);
