import React from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'helpers/util';

const IndividualNeighborhood = props => {
  const {
    id,
    neighborhood,
    regionSelected,
    handleSelect,
    selected,
  } = props;

  const {
    selectedNeighborhoods,
  } = selected;

  const checked = selectedNeighborhoods.some(selectedNeighborhood => (
    selectedNeighborhood.id === `${id}`
  )) || regionSelected === true;

  return (
    <div
      className="individual-regional-hood"
      data-id={id}
    >
      <div
        role="button"
        tabIndex={0}
        data-name={id}
        style={{ cursor: 'pointer' }}
        className={`${checked ? 'matrix-checkbox-checked' : 'matrix-checkbox'} d-flex`}
        onMouseDown={() => handleSelect(`${id}`, 'neighborhoods')}
      >
        <span className={checked ? 'checkmark' : ''} />
      </div>
      <div className="d-flex" data-name={neighborhood.name} style={{ userSelect: 'none' }}>
        <div style={{ fontWeight: '500', fontSize: '12px', paddingLeft: '10px' }}>{truncate(neighborhood.name, 32)}</div>
      </div>
    </div>
  );
};

IndividualNeighborhood.propTypes = {
  id: PropTypes.number.isRequired,
  neighborhood: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  regionSelected: PropTypes.bool.isRequired,
  selected: PropTypes.shape({
    selectedRegions: PropTypes.arrayOf(PropTypes.shape({})),
    selectedNeighborhoods: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
  }).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default IndividualNeighborhood;
