import React, { useEffect } from 'react';
import { ButtonSave, ButtonStepper } from '../Buttons';
import ContainerWrapper from '../ContainerWrapper';

const title = 'How many square feet?';

const SqFootage = (props) => {
  const {
    sqFootage, setSqFootage, userIsEditing, setUserIsEditing,
  } = props;

  useEffect(() => {
    document.title = `Create: Apartments - ${title}`;
  }, []);

  return (
    <ContainerWrapper>
      <div>
        <div className="title">
          <h3>{title}</h3>
          <p>You can skip this, but people really want to know.</p>
        </div>
        <form
          className="narrow"
          onKeyDown={(evt) => {
            if (evt.which === 13) evt.preventDefault();
          }}
        >
          <div className="row">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text general-text-field-dark">
                  <i className="nookons-square-footage" />
                </span>
              </div>
              <input
                type="number"
                className="form-control general-text-field-dark"
                value={sqFootage}
                onChange={evt => setSqFootage(evt.target.value)}
              />
            </div>
          </div>
          {userIsEditing ? (
            <ButtonSave
              handleJump={props.handleJump}
              setUserIsEditing={setUserIsEditing}
              jumpTo={props.jumpTo || "ApartmentReview"}
            />
          ) : (
            <ButtonStepper handleNext={props.handleNext} handleBack={props.handleBack} />
          )}
        </form>
      </div>
    </ContainerWrapper>
  );
};

SqFootage.defaultProps = {
  sqFootage: '',
};

export default SqFootage;
