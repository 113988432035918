import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { Column } from 'wrappers/FlexTable'
import Popover from 'components/generic/Popover'

import Filters from './Filters'

import icon from './icon.svg'
import iconYellow from './iconYellow.svg'

import s from './MobileFilter.module.css'

function MobileFilter(props) {
  const { loading, selected, onChange, options } = props

  const targetRef = useRef(null)

  const hasSelected =
    selected.baths?.length > 0 ||
    selected.bedrooms?.length > 0 ||
    selected.price?.length > 0 ||
    selected.amenities?.length > 0 ||
    selected?.pets !== undefined ||
    selected?.noFeeOnly !== undefined

  const isFilterActive = hasSelected && !loading
  const className = isFilterActive ? 'btn-transparent-yellow' : 'btn-transparent-gray'

  return (
    <Column className="searchbar-col">
      <Popover type="click" targetRef={targetRef}>
        <Filters loading={loading} selected={selected} onChange={onChange} options={options} />
      </Popover>

      <button
        ref={targetRef}
        name="mobileFilter"
        type="button"
        className={`button btn-rounded ${className} btn-search-bar-size`}
      >
        <img src={isFilterActive ? iconYellow : icon} alt="filter icon" className={s.filterIcon} />
      </button>
    </Column>
  )
}

MobileFilter.propTypes = {
  loading: PropTypes.bool.isRequired,
  selected: PropTypes.shape({
    price: PropTypes.arrayOf(PropTypes.string),
    bedrooms: PropTypes.arrayOf(PropTypes.string),
    baths: PropTypes.arrayOf(PropTypes.string),
    amenities: PropTypes.arrayOf(PropTypes.string),
    pets: PropTypes.string,
    noFeeOnly: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    bedrooms: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    baths: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    amenities: PropTypes.arrayOf(PropTypes.shape({})),
    pets: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default MobileFilter
