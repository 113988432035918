import React, { useEffect } from 'react';
import { ButtonSave, ButtonStepper } from '../Buttons';
import ContainerWrapper from '../ContainerWrapper';

const title = 'Which utilities are included?';

const Utilities = (props) => {
  const utilityOptions = [
    'Heat',
    'Hot Water',
    'Electricity',
    'Internet',
    'Gas',
    'No Utilities Included',
  ];
  const {
    utilities, setUtilities, userIsEditing, setUserIsEditing,
  } = props;

  const handleChange = (utility) => {
    // if No Utilities Included, remove all selection
    if (utility === 'No Utilities Included') {
      setUtilities([]);
    } else if (utilities.includes(utility)) {
      setUtilities(utilities.filter(u => u !== utility));
    } else {
      setUtilities([utility, ...utilities]);
    }
  };

  useEffect(() => {
    document.title = `Create: Apartments - ${title}`;
  }, []);

  return (
    <ContainerWrapper>
      <div>
        <div className="title">
          <h3>{title}</h3>
          <p>Everyone loves free stuff.</p>
        </div>
        <form className="narrow" id="utilties-form">
          {utilityOptions.map(utility => (
            <div key={utility} className="checkbox-row" onClick={() => handleChange(utility)}>
              <label className={utilities.includes(utility) ? 'active' : ''}>
                {utility}
                {utilities.includes(utility) && (
                  <i className="nklyn-3x nookons-check-circle active" />
                )}
                {!utilities.length && utility === 'No Utilities Included' && (
                  <i className="nklyn-3x nookons-check-circle active" />
                )}
              </label>
              <input type="checkbox" className="float-right" />
            </div>
          ))}
          {userIsEditing ? (
            <ButtonSave
              handleJump={props.handleJump}
              setUserIsEditing={setUserIsEditing}
              jumpTo={props.jumpTo || 'PricingReview'}
            />
          ) : (
            <ButtonStepper handleNext={props.handleNext} handleBack={props.handleBack} />
          )}
        </form>
      </div>
    </ContainerWrapper>
  );
};

export default Utilities;
