import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

function logErrorToRails(message) {
  axios.post(
    '/api/v2/javascript_exception.log',
    {
      message,
      data: {
        url: window.location && window.location.href,
        referrer: document.referrer,
        userAgent: window.navigator && window.navigator.userAgent,
        cookies: document.cookie,
        storage: window.localStorage,
      },
    },
  );
}

class ErrorBoundary extends Component {
  /* Error boundary is a pattern recommended in the React docs
     for handling component-level exceptions. */
  state = {
    hasError: false,
  }

  previousComponentStack = null;

  componentDidCatch(error, { componentStack }) {
    const { previousComponentStack } = this;
    if (previousComponentStack === componentStack) return;
    this.previousComponentStack = componentStack;
    // will trigger a fallback UI
    this.setState({ hasError: true });
    const jsOriginStack = error.stack || '';
    // Browser polyfill (some browsers repeat the error message in the stack, some don't)
    const message = jsOriginStack.startsWith(error) ? jsOriginStack : `${error}\n${jsOriginStack}`;
    logErrorToRails(message);
  }

  render() {
    const { hasError } = this.state;
    const { children, showFallbackUI, message } = this.props;

    if (hasError && showFallbackUI) {
      // You can render any custom fallback UI
      return (
        <div className="error-boundary">
          <div className="container">
            <img
              src="//nooklyn-files.s3.amazonaws.com/img/500-cat.png"
              alt="500-cat"
              className="img-fluid"
            />
            <p>
              {message}
            </p>
            <br />
            <h3>Recommendations</h3>
            <div className="text-center">
              <a href="https://nooklyn.com" className="btn btn-warning-outline">Home</a>
              <a href="https://nooklyn.com/support" className="btn btn-warning-outline">Report a Problem</a>
            </div>
          </div>
        </div>
      );
    }
    return children;
  }
}

ErrorBoundary.defaultProps = {
  showFallbackUI: true,
  message: "We’re sorry, but something went wrong. Please try reloading this page. If that doesn't work, please email us at hi@nooklyn.com and we will get back to you soon.",
};

ErrorBoundary.propTypes = {
  showFallbackUI: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  message: PropTypes.string,
};

export default ErrorBoundary;
