/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState, useCallback, useRef } from 'react'
import { FixedSizeList as List } from 'react-window'

import PropTypes from 'prop-types'

import ApplyClear from 'components/listings/search/SearchBar/SearchBarButtons/ApplyClear'
import s from './SubwaysList.module.css'

import SubwayStop from './SubwayStop'
import SearchInput from '../../common/SearchInput'

function Row({ index, data, style }) {
  const stop = data.items[index]

  return (
    <div style={style}>
      <SubwayStop stop={stop} selected={data.changes?.includes(stop.id)} handleSelect={data.handleChange} />
    </div>
  )
}

function SubwaysList({ selectedStops, onChange, stops }) {
  const [search, setSearch] = useState('')
  const [changes, setChanges] = useState({
    subwayStops: selectedStops,
  })

  const searchRef = useRef(null)

  const handleChange = (stopId) => {
    let result
    if (changes?.subwayStops?.includes(stopId)) {
      result = {
        subwayStops: changes?.subwayStops.filter((selectedStopId) => selectedStopId !== stopId),
      }
    } else {
      result = {
        subwayStops: [...(changes?.subwayStops || []), stopId],
      }
    }

    setChanges(result)
  }

  const filteredStops = useMemo(
    () => stops.filter((stop) => stop.name?.match(new RegExp(search, 'i'))),
    [stops, search]
  )

  const handleSearch = (value) => setSearch(value)

  // Temporary solution to close Popover
  const closePopover = () => {
    document.querySelector('input[name="subwayStops"]').click()
  }

  const handleUpdate = useCallback(
    (actionType) => {
      if (actionType === 'clear') {
        setChanges({
          subwayStops: [],
        })
        setSearch('')
        onChange({
          subwayStops: [],
        })
        searchRef.current.value = ''
        return
      }

      onChange(changes)
      closePopover()
    },
    [changes, onChange]
  )

  return (
    <div className={s.root}>
      <SearchInput ref={searchRef} onChange={handleSearch} placeholder="Subway Stops" />

      <List
        className={s.list}
        height={180}
        itemCount={filteredStops.length}
        itemSize={42}
        itemData={{
          items: filteredStops,
          changes: changes?.subwayStops,
          handleChange,
        }}
      >
        {Row}
      </List>
      <ApplyClear handleUpdate={handleUpdate} className={s.applyClear} />
    </div>
  )
}

SubwaysList.propTypes = {
  stops: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedStops: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default SubwaysList
