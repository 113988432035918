import axios from 'axios';
import keysToCamel from './util';
import { fetchListOfListings } from './listing';
import { fetchListOfRooms } from './room';

// API call for fetching current agent logged in
export const fetchCurrentAgent = async () => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  return axios.get('/api/v2/current.fetch', { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(res => res.data)
    .then(agent => keysToCamel(agent));
};

// API call for fetching a single agent
// Param(s):
// id - Agent ID for agent we want to fetch
const fetchAgent = async id => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  return axios.get(`/api/v2/agents.fetch?id=${id}`, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(res => res.data)
    .then(agent => keysToCamel(agent));
};

// API call for fetching a single agent's listing list
// Param(s):
// id - Agent ID for agent we want to fetch
export const fetchAgentListingsList = async id => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const agentToCheck = axios.get(`/api/v2/agents.fetch?id=${id}`, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(res => res.data)
    .then(agent => keysToCamel(agent));

  return fetchListOfListings(agentToCheck.listings);
};

// API call for fetching a single agent's room list
// Param(s):
// id - Agent ID for agent we want to fetch
export const fetchAgentRoomsList = async id => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const agentToCheck = axios.get(`/api/v2/agents.fetch?id=${id}`, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(res => res.data)
    .then(agent => keysToCamel(agent));

  return fetchListOfRooms(agentToCheck.rooms);
};

// API call for fetching list of all nooklyn agents
// Param(s):
// None
export const fetchAgentList = async () => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  return axios.get('/api/v2/agents.list', { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(res => res.data.agents)
    .then(agents => agents.map(agent => keysToCamel(agent)));
};

export default fetchAgent;
