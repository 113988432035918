import React, { Component } from 'react';
import { Row, Column } from 'wrappers/FlexTable';
import { connect } from 'react-redux';
import { setStore, generateNewSearch, updateActiveSearch } from 'reducers/search';
import CustomCheckBox from 'components/CustomCheckBox';

class IndividualPet extends Component {
  onSelect = e => {
    e.preventDefault();
    if (!this.props.selected) {
      this.props.setStore({ selectedPet: this.props.pet });
      this.props.generateNewSearch();
    }
  }

  render() {
    const { pet, selected } = this.props;
    return (
      <Row
        style={{
          border: '0px',
          width: '100%',
          marginTop: '14px',
          color: '#fff',
          fontSize: '13px',
          fontWeight: '500',

        }}>
        <Column
          onMouseDown={this.onSelect}
          style={{
            width: '100%',
            cursor: 'pointer',
            userSelect: 'none',
          }}>
          <CustomCheckBox
            checked={selected}
            boxClass='matrix-checkbox'
            boxcheckedClass='matrix-checkbox-checked'
            checkmarkClass='checkmark'
          >
            <Column
              style={{
                color: '#fff',
                fontSize: '13px',
                fontWeight: '500',
                paddingLeft: '10px',
              }}
            >
              {pet}
            </Column>
          </CustomCheckBox>
        </Column>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selected = ownProps.pet === state.search[state.search.context].selectedPet;
  return { selected };
};

const mapDispatchToProps = (dispatch) => ({
  setStore: (payload) => dispatch(setStore(payload)),
  generateNewSearch: () => dispatch(generateNewSearch()),
  updateActiveSearch: () => dispatch(updateActiveSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualPet);
