import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import CustomCheckBox from 'components/CustomCheckBox'
import { Column } from 'wrappers/FlexTable'

import s from './ListingSearchThisArea.module.css'

const ListingSearchThisArea = props => {
  const { loading, checked, onChange } = props

  const handleChange = () => {
    onChange(!checked)
  }

  return (
    <Column
      style={
        loading
          ? {
              cursor: 'not-allowed',
              opacity: 0.5,
            }
          : {}
      }
      className={clsx('disable-select search-this-area', s.root)}
      onMouseDown={!loading ? handleChange : () => {}}
    >
      <CustomCheckBox
        boxClass="map-checkbox fill-nklyn-cream"
        boxcheckedClass="map-checkbox fill-nklyn-blue"
        checkmarkClass="checkmark-white"
        checked={checked}
        onMouseDown={!loading ? handleChange : () => {}}
        type="checkbox"
      >
        <Column className="search-this-area-content">Search This Area</Column>
      </CustomCheckBox>
    </Column>
  )
}

ListingSearchThisArea.propTypes = {
  loading: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ListingSearchThisArea
