import 'polyfill';
import '../src/application.css';
import 'cropperjs/src/css/cropper';
import ErrorBoundary from 'errorLogging';
import React from 'react';
import ReactDOM from 'react-dom';
import { composeWithRedux } from 'reducers';
import RoomMatrixIndex from 'components/listings/RoomMatrixIndex';
import AgentContainer from 'components/agentTypeaheadComponents/AgentContainer';
import ListingSalesContainer from 'components/agentTypeaheadComponents/ListingSalesContainer';
import MateFavMatesContainer from 'components/matePosts/MateFavMatesContainer';
import MateFavListingsContainer from 'components/matePosts/MateFavListingsContainer';
import {
  BillingAccountPaymentAuthorization,
  BillingAccountPaymentMethod,
} from 'payments';
import DateOfBirth from 'components/DateOfBirth';
import CountryOfCitizenship from 'components/CountryOfCitizenship';
import CopyUrlListingCollection from 'components/CopyUrlListingCollection';
import NooklynPro from 'pro';
import PhoneNumber from 'components/PhoneNumber';
import { IdVerification, ManualVerification, PassbaseVerifyButton } from 'components/Verification';
import AgentListingsContainer from 'components/listings/AgentListingsContainer';
import { MembershipTypeButton } from 'components/Subway';
import AddListingPackageButton from 'components/listings/Package/AddListingPackageButton';
import RentCalculator from 'pages/RentCalculator';

// Buttons
import LikeTourShareRow from 'components/listings/cards/components/LikeTourShareRow';
import { LegacyScheduleTour } from 'components/listings/detailView/InfoPanel/components';
import LeaseMagicLinkButton from 'components/lease/LeaseMagicLinkButton';

// Homepage
import NotSoSlickSlider from 'components/NotSoSlickSlider';

// Forms
import ScheduleTour from 'components/renterForms/ScheduleTour';
import RenterApply from 'components/renterForms/RenterApply';

// Cards
import ListMatrixListings from 'containers/ListMatrixListings';

// Ruby To Listing Router
import ListingRouter from 'components/listings/Routes';

import ScheduleTourButton from 'components/buildings/ScheduleTourButton';

import ScrollButton from '../containers/ScrollToTop';
import ListingLikeButton from '../containers/LikeButtonListing';
import RoommateLikeButton from '../containers/LikeButtonMate';
import InviteButton from '../containers/InviteButton';
import InviteButtonAgent from '../containers/InviteButtonAgent';
import { LoginForm, ApplyButton, AuthWrapper } from '../components/Auth';
import RoomsWithContext from '../components/listings/pages/rooms/RoomsWithContext';
import {
  PayButton,
  PaymentPreview,
} from '../containers/PayButton';
import { AgreementButtonPreview } from '../containers/AgreementButtonJumbotron';
import RenterGuide from '../components/lease/RenterGuide';
import SubwayStopAutofill from '../components/listings/SubwayStopAutofill';
import RecordDepositButton from '../containers/RecordDepositButton';
import LeaseRefundModal from '../components/modal/LeaseRefundModal';
import LinkCreditReportButton from '../containers/LinkCreditReportButton';
import CreditReportStatus from '../containers/CreditReportStatus';
import Flash from '../components/flash/Flash';
import LeaseDashboardJumbotron from '../components/leaseDashboard/Jumbotron';
import LeaseSalesAgentModal from '../components/modal/LeaseSalesAgentModal';
import LeaseChangeListingModal from '../components/modal/LeaseChangeListingModal';
import LeaseRecordOwnerPayment from '../components/modal/LeaseRecordOwnerPayment';
import MatrixLeaseRowSalesTeam from '../components/lease/MatrixLeaseRowSalesTeam';
import CollectionMap from '../components/CollectionMap';
import NearbyMap from '../components/NearbyMap';
import ListARoom from '../containers/ListARoom';
import MatrixLocation from '../components/matrix/ListingFilters/Location';


const mountReactComponent = (Component, elementId, additionalData) => {
  const mountNode = document.getElementById(elementId);
  if (mountNode) {
    let data = JSON.parse(mountNode.getAttribute('data'));
    if (additionalData) data = { ...data, ...additionalData };
    const ReduxComponent = props => composeWithRedux(Component, props);
    ReactDOM.render(
      (<ErrorBoundary showFallbackUI={false}>
        <ReduxComponent {...data} />
       </ErrorBoundary>),
      mountNode,
    );
  }
};

const mountReactComponents = (Component, elementId, mountingId) => {
  const mountArr = Array.from(document.getElementsByClassName(elementId));
  if (mountArr.length) {
    mountArr.forEach(node => {
      let mountNode = node;
      const data = JSON.parse(mountNode.getAttribute('data'));
      if (mountingId && data.id) {
        mountNode = document.getElementById(`${mountingId}${data.id}`);
      }
      const ReduxComponent = props => composeWithRedux(Component, props);
      ReactDOM.render(
        (<ErrorBoundary showFallbackUI={false}>
          <ReduxComponent {...data} />
        </ErrorBoundary>),
        mountNode,
      );
    });
  }
};

const renderReactComponents = () => {
  // Auth
  mountReactComponent(ApplyButton, 'auth-modal');
  mountReactComponent(LoginForm, 'login-rails');
  mountReactComponents(
    AuthWrapper,
    'auth-wrapper',
    'authWrapper',
  );

  // Lease
  mountReactComponent(InviteButton, 'invite-modal');
  mountReactComponent(InviteButtonAgent, 'invite-modal-agent');
  mountReactComponents(
    AgreementButtonPreview,
    'agreement-modal-preview',
    'agreementPreview',
  );
  mountReactComponents(RecordDepositButton, 'record-deposit', 'recordDeposit');
  mountReactComponents(LeaseRefundModal, 'lease-refund', 'leaseRefund');
  mountReactComponents(LinkCreditReportButton, 'link-credit-report', 'linkCreditReport');
  mountReactComponents(CreditReportStatus, 'credit-report-status', 'creditReportStatus');
  mountReactComponent(LeaseDashboardJumbotron, 'lease-dashboard-jumbotron');
  mountReactComponents(
    LeaseSalesAgentModal,
    'lease-sales-team',
    'leaseSalesTeam',
  );
  mountReactComponents(
    MatrixLeaseRowSalesTeam,
    'lease-row-sales-team',
    'leaseRowSalesTeam',
  );
  mountReactComponents(
    LeaseChangeListingModal,
    'lease-change-listing',
    'leaseChangeListing',
  );
  mountReactComponents(
    LeaseRecordOwnerPayment,
    'record-owner-payment',
    'recordOwnerPayment',
  );

  // Payment
  mountReactComponent(PayButton, 'payment-modal');
  mountReactComponents(PaymentPreview, 'payment-preview', 'paymentPreview');

  // Agent Typeahead
  mountReactComponent(AgentContainer, 'agent-typeahead');
  mountReactComponent(ListingSalesContainer, 'agents-typeahead');


  // Matrix location filter
  mountReactComponent(MatrixLocation, 'matrix-location-filter');
  

  // Mate
  mountReactComponent(MateFavMatesContainer, 'fav-mates-container');
  mountReactComponent(MateFavListingsContainer, 'fav-listings-container');
  mountReactComponents(LikeTourShareRow, 'like-tour-share-row', 'likeTourShareRow');

  // Likes
  mountReactComponents(
    ListingLikeButton,
    'listing_heart nooklyn_square_heart',
    'listingLikeButton',
  );
  mountReactComponents(
    RoommateLikeButton,
    'roommate_heart nooklyn_square_heart',
    'roommateLikeButton',
  );

  // Misc
  mountReactComponent(ScrollButton, 'back_to_top');
  mountReactComponent(Flash, 'react-flash');
  mountReactComponent(CopyUrlListingCollection, 'copy-url-listing-collection');
  mountReactComponent(LeaseMagicLinkButton, 'lease-magic-link-button');
  mountReactComponent(RentCalculator, 'react-rent-calculator');

  // Verification
  mountReactComponents(
    ManualVerification,
    'manual-verification',
    'manualVerification',
  );
  mountReactComponents(
    IdVerification,
    'id-verification',
    'idVerification',
  );
  mountReactComponents(
    PassbaseVerifyButton,
    'react-identity-verify-button',
    'react-identity-verify-button',
  );

  // Subway
  mountReactComponents(
    MembershipTypeButton,
    'membership-type',
    'membershipType',
  );

  // Payments
  mountReactComponent(BillingAccountPaymentAuthorization, 'react-payments-billing-account-payment-authorization');
  mountReactComponent(BillingAccountPaymentMethod, 'react-payments-billing-account-payment-method');

  // Listings
  mountReactComponent(ListMatrixListings, 'list-matrix-listings');
  mountReactComponent(RoomsWithContext, 'room-index');
  mountReactComponent(RoomMatrixIndex, 'listing-room-matrix');
  mountReactComponent(ListARoom, 'list-a-room');
  mountReactComponent(NooklynPro, 'nooklyn-pro');
  mountReactComponent(AgentListingsContainer, 'agent-listings-container');
  mountReactComponents(
    AddListingPackageButton,
    'add-listing-package',
    'addListingPackage',
  );
  mountReactComponent(LegacyScheduleTour, 'listing-tour-button');

  // Ruby To Listing Router
  mountReactComponent(ListingRouter, 'listing-index');
  mountReactComponent(ListingRouter, 'commercial-index');
  mountReactComponent(ListingRouter, 'sales-index');

  mountReactComponent(ScheduleTourButton, 'building-tour-button');

  // Lease apply by renter
  mountReactComponent(RenterGuide, 'renter-guide');

  // Subway stop autofill on new listings
  mountReactComponent(SubwayStopAutofill, 'subway-stop-autofill');

  // Request a tour
  mountReactComponent(ScheduleTour, 'tour-by-renter');
  mountReactComponent(RenterApply, 'apply-by-renter');

  // React Map
  mountReactComponent(CollectionMap, 'component-collection-map');
  mountReactComponent(CollectionMap, 'renters-map');
  mountReactComponent(NearbyMap, 'nearby-map');

  // Custom Fields
  mountReactComponent(DateOfBirth, 'date-of-birth');
  mountReactComponent(CountryOfCitizenship, 'country-of-citizenship');
  mountReactComponent(PhoneNumber, 'phone-number');

  // Homepage
  mountReactComponents(NotSoSlickSlider, 'not-so-slick-slider');
};

document.addEventListener('DOMContentLoaded', renderReactComponents);
