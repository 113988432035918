import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Annotation from 'components/GoogleMap/nearby/Annotation'
import GoogleMap from 'components/GoogleMap/nearby/GoogleMap'
import MarkerPoint from 'components/GoogleMap/nearby/MarkerPoint'
import useFitMarkerBounds from 'hooks/useFitMarkerBounds'
import useGoogleMapCluster from 'hooks/useGoogleMapCluster'
import ClusterPoint from 'components/GoogleMap/common/ClusterPoint'
import MultiMarker from 'components/GoogleMap/common/MultiMarker'
import { appleResidentialImageMarker } from '../helpers/mapHelpers'
import AppleMap, { ImageAnnotation } from './AppleMap'

const Marker = ({ children }) => children

const CollectionMap = ({ points, call_to_action: callToAction }) => {
  const [map, setMap] = useState()

  useFitMarkerBounds(map, points)

  const { clusters, supercluster, onClusterMapChange, points: clusterPoints } = useGoogleMapCluster(points)

  if (process.env.MAP_PROVIDER !== 'apple') {
    return (
      <GoogleMap
        onLoaded={map => setMap(map)}
        mapProps={{
          onChange: data => onClusterMapChange(data),
        }}
      >
        {clusters &&
          clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates
            const {
              cluster: isCluster,
              point_count: pointCount,
              multilisting: isMultilisting,
              markers: multiList,
            } = cluster.properties

            const marker = multiList?.[0]

            if (isCluster) {
              return (
                <Marker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
                  <ClusterPoint
                    onClick={() => {
                      const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 22)
                      map.setZoom(expansionZoom)
                      map.panTo({ lat: latitude, lng: longitude })
                    }}
                    pointCount={pointCount}
                    size={clusterPoints.length}
                  />
                </Marker>
              )
            }

            if (isMultilisting) {
              return (
                <MultiMarker lat={latitude} lng={longitude} markersNum={multiList.length}>
                  {multiList.map(multiMarker => (
                    <Annotation isMulti marker={multiMarker} />
                  ))}
                </MultiMarker>
              )
            }

            return (
              <MarkerPoint
                key={marker.id}
                marker={{
                  ...marker,
                  markerUrl: appleResidentialImageMarker,
                }}
                lat={marker.latitude}
                lng={marker.longitude}
              >
                <Annotation marker={marker} />
              </MarkerPoint>
            )
          })}
      </GoogleMap>
    )
  }

  return (
    <AppleMap showSubwayLines>
      {points.map(point => {
        const { id: key, image_url: imageURL, latitude, longitude, title, subtitle, url } = point

        return (
          <ImageAnnotation
            key={key}
            url={appleResidentialImageMarker}
            latitude={latitude}
            longitude={longitude}
            title={title}
            subtitle={subtitle}
          >
            {url && (
              <div className="collection-callout-content">
                <a target="_blank" href={url} rel="noreferrer">
                  {imageURL && <img src={imageURL} className="collection-callout-img" />}
                  <div className="collection-callout-title">{title}</div>
                  <div className="collection-callout-subtitle">{subtitle}</div>
                </a>
              </div>
            )}
          </ImageAnnotation>
        )
      })}
    </AppleMap>
  )
}

CollectionMap.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  call_to_action: PropTypes.string,
}

CollectionMap.defaultProps = {
  points: undefined,
  call_to_action: undefined,
}

export default CollectionMap
