import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class AgentHiddenField extends Component {

  constructor(props) {
    super(props);
    this.node = document.getElementById('new_listing_search_form')
  }

  render() {
    const { children } = this.props;
    return (
      ReactDOM.createPortal(
        children,
        this.node,
      )
    );
  }
}
