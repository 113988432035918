import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addPopoverRef, removePopoverRef } from 'reducers/search';
import reposition from '../searchPopovers/reposition';

const mapDispatchToProps = dispatch => ({
  _addPopoverRef: payload => dispatch(addPopoverRef(payload)),
  _removePopoverRef: payload => dispatch(removePopoverRef(payload)),
});

export default function Reposition(WrappedComponent) {
  const childName = WrappedComponent.name;
  const camel = childName.charAt(0).toLowerCase() + childName.slice(1);
  const displayProp = `${camel}Display`;

  class __Reposition__ extends Component {
    repositionPopover = reposition.bind(this)

    constructor(props) {
      super(props);
      this.childName = childName;

      this.references = {
        popoverWrapper: React.createRef(),
        popoverContainer: React.createRef(),
        popover: React.createRef(),
      };
    }

    componentDidMount() {
      this.repositionPopover();
      const { _addPopoverRef } = this.props;
      _addPopoverRef(this);
    }

    shouldComponentUpdate() {
      return false;
    }

    componentWillUnmount() {
      const { _removePopoverRef } = this.props;
      _removePopoverRef(this);
    }

    render() {
      return (
        <WrappedComponent {...this.props} {...this.references} />
      );
    }
  }

  const mapStateToProps = (state) => {
    const display = state.search[state.search.context][displayProp];
    return {
      display: display || false,
    };
  };

  __Reposition__.propTypes = {
    _addPopoverRef: PropTypes.func.isRequired,
    _removePopoverRef: PropTypes.func.isRequired,
  };

  return connect(mapStateToProps, mapDispatchToProps)(__Reposition__);
}
