import React from 'react';
import PropTypes from 'prop-types';
import { AsyncSubscribe } from 'asyncHandlers/AsyncBase';
import AgentListingsContainer from '../listings/AgentListingsContainer';

const MateFavListings = ({ mate }) => (
  mate && mate.liked_listings && mate.liked_listings.length
    ? (
      <div className="favorite-listings-section">
        <div>
          <h6 className="text-left">Favorite Listings</h6>
          <div
            className="nooklyn_listings_index_container special-listings-container"
            style={{ justifyContent: 'left' }}
          >
            <AgentListingsContainer listingIds={mate.liked_listings} />
          </div>
        </div>
      </div>
    )
    : null
);

MateFavListings.defaultProps = {
  mate: null,
};

MateFavListings.propTypes = {
  mate: PropTypes.shape({
    liked_listings: PropTypes.arrayOf(
      PropTypes.number,
    ),
  }),
};

const subscriptions = [
  'mate',
];

export default AsyncSubscribe(subscriptions)(MateFavListings);
