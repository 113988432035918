import React, { useState } from 'react';
import { connect } from 'react-redux';
import IndividualSubwayStop from './IndividualSubwayStop';

const IndividualSubway = props => {
  const [showStops, setShowStops] = useState(false);
  const { subwayLine, selectedSubwayStops, selectedSubways, handleSelectSubwayLine, handleSelectSubwayStop, subwayStopFilter } = props;
  const stopCount = subwayLine.stops.length;
  const selectedStops = selectedSubwayStops.filter(selectedSubwayStop => selectedSubwayStop.lines.includes(subwayLine.line));
  const selected = selectedStops.length === stopCount;
  const partiallySelected = selectedStops.length > 0 && selectedStops.length < stopCount;

  return (
    <>
      <div className="d-flex position-relative">
        <div
          data-name={subwayLine.line}
          className={`${selected || partiallySelected ? "matrix-checkbox-checked" : "matrix-checkbox"} mt-2 ${!!subwayStopFilter.length ? 'disabled' : '' }`}
          onMouseDown={e => handleSelectSubwayLine(e, subwayLine.line)}
        >
          <span className={selected ? 'checkmark' : ''} />
          <span className={partiallySelected ? 'partial-checkmark ' : ''} />
        </div>
        <div
          style={{ cursor: 'pointer', userSelect: 'none', margin: '3px 8px' }}>
          <img
            style={{ width: '30px' }}
            src={require(`images/subway/${subwayLine.line}.png`)}
            srcSet={`${require(`images/subway/2x/${subwayLine.line}.png`)} 1x, ${require(`images/subway/2x/${subwayLine.line}.png`)} 2x`} 
          />
          { showStops && subwayLine.stops && subwayLine.stops.map(subwayStop => <IndividualSubwayStop handleSelectSubwayStop={handleSelectSubwayStop} key={`${subwayLine.line}-${subwayStop.id}`} id={subwayStop.id} subwayStop={subwayStop} />)}
        </div>
        { !showStops ? (
          <div style={{ position: 'absolute', right: 0, margin: '8px 20px', cursor: 'pointer'}} onClick={() => setShowStops(true)}>Show stops<i className="nookons-chevron-down ml-2"/></div>
        ) : (
          <div style={{ position: 'absolute', right: 0, margin: '8px 20px', cursor: 'pointer'}} onClick={() => setShowStops(false)}>Hide stops<i className="nookons-chevron-up ml-2"/></div>
        ) }
      </div>
      <hr style={{ margin: '5px 20px 5px 0' }} />
    </>
  );
};

const mapStateToProps = state => ({
  selectedSubways: state.search[state.search.context].selectedSubways,
  selectedSubwayStops: state.search[state.search.context].selectedSubwayStops,
  subwayStopFilter: state.search[state.search.context].subwayStopFilter,
});


export default connect(mapStateToProps, null)(IndividualSubway);
