const formatAmenities = amenities => {
  if (amenities.length < 1) {
    return '';
  }

  const amenitiesString = `With ${amenities.join(', ')}`;

  return amenitiesString;
};

export const formatBath = bath => {
  if (bath) {
    return `At Least ${bath} Bathrooms`;
  }

  return '';
};

export const formatBeds = beds => {
  if (beds.length < 1) {
    return '';
  }

  const listOfBeds = beds.map(bedroom => {
    if (bedroom === '0') {
      return ('Studios');
    }

    return (`${bedroom}-Bedroom Apartments`);
  });

  return listOfBeds.join(', ');
};

export const formatNeighborhoods = (regions, neighborhoods, context, filterData) => {
  let typeOfListing = context;
  const listOfRegions = [...regions];
  const listOfNeighborhoods = [...neighborhoods];

  if (context === 'listings') {
    typeOfListing = 'Apartments';
  }

  if (context === 'rooms') {
    typeOfListing = 'Rooms';
  }

  if (regions.length < 1 && neighborhoods.length < 1) {
    return `NYC ${typeOfListing} for Rent`;
  }

  filterData.neighborhoods.forEach(element => {
    if (listOfNeighborhoods.includes(element.id)) {
      if (!listOfRegions.includes(element.region)) {
        listOfRegions.push(element.region);
      }
    }
  });

  return (`${listOfRegions.join(', ')} ${typeOfListing} for Rent`);
};

export const formatNoFee = noFee => (noFee ? 'No Fee' : '');

export const formatPet = pets => {
  if (pets === 'All Pets Allowed') {
    return 'Pet-Friendly';
  }

  if (pets === 'Pets Upon Approval') {
    return 'Pets Upon Approval';
  }

  if (pets === 'No Pets Allowed') {
    return 'Not Pet-Friendly';
  }

  return '';
};

export const formatPrice = price => {
  if (price[0] && price[1]) {
    return (`Between $${price[0]} - $${price[1]}`);
  }

  if (price[0]) {
    return (`More than $${price[0]}`);
  }

  if (price[1]) {
    return (`Less than $${price[1]}`);
  }

  return '';
};

export const formatSublet = subletOnly => (subletOnly ? 'Sublet' : '');

export const formatSubway = (subway, subwayStops, filterData) => {
  const listOfSubways = [...subway];
  const listOfSubwayStops = [...subwayStops];

  if (subway.length < 1 && subwayStops.length < 1) {
    return '';
  }

  filterData.subwayStops.forEach(element => {
    if (listOfSubwayStops.includes(element.id)) {
      if (!element.lines.every(line => listOfSubways.includes(line))) {
        element.lines.forEach(line => {
          if (!listOfSubways.includes(line)) {
            listOfSubways.push(line);
          }
        });
      }
    }
  });

  const subwayString = `Near the ${listOfSubways.join(', ')} Subway`;

  return subwayString;
};

export default formatAmenities;
