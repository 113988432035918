import React, { useState } from 'react';
import { Link, useParams } from '@reach/router';
import { useListing } from 'pro/ProStore';
import RoomSection from './RoomSection';

const ListingDetail = (props) => {
  const { listingId } = useParams();

  const [showDetails, setShowDetails] = useState(false);

  const { dataLoaded, listing } = useListing(listingId);

  return dataLoaded ? (
    <>
      <div className="apartment-container" key={listing.id}>
        <div className="apartment-container-header">
          Apartment Details
          <div className="float-right">
            <Link to={`/pro/listings/${listing.id}/edit`}>
              <i className="nookons-pencil nkyln-2x" />
                &nbsp; Edit
            </Link>
          </div>
        </div>
        <div className="apartment-container-content">
          <h5>{listing.address.lineOne}</h5>
          <div className="apartment-container-content-subheadline">
            {listing.neighborhood && listing.neighborhood.name}
            {'  '}
            &#183;
            {'  '}
            {listing.beds}
            {'  '}
            bedrooms &#183;
            {'  '}
            {listing.fullBaths}
            {'  '}
            bathrooms &#183;
            {'  '}
            {listing.sqFootage}
            {'  '}
            sqft
          </div>
          { showDetails
            ? (
              <>
                <hr style={{ borderTop: '1px solid #404040' }} />
                <div className="row apartment-details-row">
                  <div className="col-sm-6">
                    <label>Move-in costs</label>
                    <div className="details">{listing.moveInCosts.join(', ')}</div>
                    <label>Utilities</label>
                    <div className="details">{listing.utilities.join(', ')}</div>
                    <label>pet policy</label>
                    <div className="details">{listing.petPolicy}</div>
                  </div>
                  <div className="col-sm-6">
                    <label>Amenities</label>
                    <div className="details">
                      <ul>
                        { listing.amenities && listing.amenities.map(amenity => <li key={amenity}>{amenity}</li>)}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="show-hide" onClick={() => setShowDetails(false)}>
                Hide
                  <i className="nookons-chevron-up nklyn-2x" style={{ verticalAlign: 'middle' }} />
                </div>
              </>
            )
            : (
              <div className="show-hide mt-4" onClick={() => setShowDetails(true)}>
              Show more &nbsp;
                <i className="nookons-chevron-down" style={{ verticalAlign: 'middle' }} />
              </div>
            )
      }
        </div>
      </div>
      <RoomSection listing={listing} />
    </>
  ) : (
    <h1>Loading</h1>
  );
};

export default ListingDetail;
