/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import close from './close.svg'

import s from './MultiContainer.module.css'

export default function MultiContainer({ children, title, onClose }) {
  return (
    <div className={s.container}>
      <div className={s.header}>
        {title}
        <img src={close} onClick={() => onClose()} alt="close" className={s.close} />
      </div>
      <div className={s.list}>{children}</div>
    </div>
  )
}

MultiContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}
