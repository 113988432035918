import React from 'react';

const PaymentSummary = (props) => {
  const { payment } = props

  return (
    <div className='row summary-container justify-content-between'>
      <div className='col-sm-5 summary-address'>
          <h6>Rental Address</h6>
          <p>{payment.description.slice(12)}</p>
      </div>
      <div className='col-sm-7'>
        <div className='container-fluid'>
          <table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Charge</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{payment.baseDescription}</td>
                <td>{accounting.formatMoney(payment.baseAmount / 100)}</td>
              </tr>
              <tr>
                <td>Convenience Fee</td>
                <td>{accounting.formatMoney(payment.convenienceFee / 100)}</td>
              </tr>
              <tr>
                <td>Total Amount</td>
                <td>{accounting.formatMoney(payment.totalAmount / 100)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default PaymentSummary;
