import React from 'react';
import PropTypes from 'prop-types';

const NoticeComponent = props => (
  <div
    className=""
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    <h1>
      {props.text}
    </h1>
  </div>
);

NoticeComponent.propTypes = {
  text: PropTypes.string.isRequired,
};
export default NoticeComponent;
