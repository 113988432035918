import React, { useRef, useContext } from 'react';
import Modal from 'components/generic/Modal';
import AgentTypeahead from './components/AgentTypeahead';
import DropDownButton from './components/DropDownButton';
import AgentSelectorContext from './AgentSelectorContext';

// Context:
// --------------------------------------------------
// selected - Selected Options (Optional) (Default: [])

const ButtonSelectContainer = props => {
  const {
    selected,
  } = useContext(AgentSelectorContext);

  const targetRef = useRef(null);
  const selectedAgentsCount = (selected) ? selected.length : 0;

  return (
    <>
      <DropDownButton
        ref={targetRef}
        selectedAgentsCount={selectedAgentsCount}
      />
      <Modal type="click" targetRef={targetRef}>
        <AgentTypeahead type="modal" />
      </Modal>
    </>
  );
};

export default ButtonSelectContainer;
