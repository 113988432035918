import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import LoadExternalScripts from 'LoadExternalScripts'
import ErrorBoundary from 'errorLogging'
import { ValidMapListeners, NonNativeProps } from './constants'

class MapElement extends Component {
  zoomConfig = {
    latitudeDelta: 0.01582847324528558,
    longitudeDelta: 0.03658359813084644,
  }

  state = {
    mapKitIsReady: false,
  }

  annotationsToRemove = []

  componentDidMount() {
    const { showSubwayLines, onConfigurationChange } = this.props
    if (onConfigurationChange) window.mapkit.addEventListener('configuration-change', onConfigurationChange)
    this.configMap()
    this.createMapObject()

    if (showSubwayLines) this.fetchGeoJson()
    // ValidMapListeners.forEach((prop) => {
    //   const { name, mapkitNativeName } = prop;
    //   const handler = this.props[name];
    //   if (handler) {
    //     this.map.addEventListener(mapkitNativeName, handler);
    //   }
    // });

    this.zoomSpan = new window.mapkit.CoordinateSpan(this.zoomConfig.latitudeDelta, this.zoomConfig.longitudeDelta)
    this.map.addEventListener('region-change-end', () => {
      this.removeAnnotations()
    })
  }

  componentDidUpdate(prevProps) {
    const { onConfigurationChange } = this.props
    if (onConfigurationChange !== prevProps.onConfigurationChange) {
      if (prevProps.onConfigurationChange)
        window.mapkit.removeEventListener('configuration-change', prevProps.onConfigurationChange)
      if (onConfigurationChange) window.mapkit.addEventListener('configuration-change', onConfigurationChange)
    }

    ValidMapListeners.forEach((prop) => {
      const { name, mapkitNativeName } = prop
      const handler = this.props[name]
      const prevHandler = prevProps[name]
      if (handler !== prevHandler) {
        if (prevHandler) this.map.removeEventListener(mapkitNativeName, prevHandler)
        if (handler) this.map.addEventListener(mapkitNativeName, handler)
      }
    })
    const remainingAnnotations = this.map.annotations.filter(
      (annotation) => !this.annotationsToRemove.includes(annotation)
    )
    const { disableAutoCenter } = this.props
    if (disableAutoCenter) {
      this.removeAnnotations()
      this.map.addItems(remainingAnnotations)
    } else {
      this.map.showItems(remainingAnnotations)
    }
  }

  scheduleAnnotationForRemoval = (annotation) => {
    this.annotationsToRemove.push(annotation)
  }

  removeAnnotations = () => {
    this.map.removeAnnotations(this.annotationsToRemove)
    this.annotationsToRemove = []
  }

  configMap = () => {
    window.mapkit.init({
      authorizationCallback(done) {
        axios
          .get('/apple_maps_jwt')
          .then((res) => res.data)
          .then(done)
      },
      language: 'en-US',
    })
  }

  extractConfigObject = () => {
    Object.keys(this.props)
      .filter((prop) => !NonNativeProps.includes(prop))
      .reduce((config, prop) => {
        config[prop] = this.props[prop]
        return config
      }, {})
  }

  createMapObject = () => {
    const config = this.extractConfigObject()
    const map = new window.mapkit.Map('apple-map', {
      showsPointsOfInterest: false,
      mapType: window.mapkit.Map.MapTypes.MutedStandard,
      ...config,
    })
    this.map = new Proxy(map, {
      get: (target, property) => {
        if (property === 'removeAnnotation') {
          return this.scheduleAnnotationForRemoval
        }
        return target[property]
      },
    })
    this.setState({ mapKitIsReady: true })
  }

  fetchGeoJson = () => {
    const geoJSONDelegate = {
      /* eslint-disable no-param-reassign */
      itemForFeature: (overlay, json) => {
        // We used to use the #FFFFFF backing in order to prevent clashing
        // against roadways on MapKit.js. Apple introduced the MutedStandard
        // MapType that allows us to hide the roadway emphasis. We can now ignore
        // this during rendering. We will remove this once its removed from the
        // generated file.
        if (json.properties.stroke === '#FFFFFF') {
          return overlay
        }
        overlay.style.strokeColor = json.properties.stroke
        overlay.style.lineWidth = 2.5
        overlay.enabled = false
        this.map.addOverlay(overlay)

        return overlay
      },
      geoJSONDidComplete: (result, geoJSON) => {
        console.log('mooo complete', result)
      },
      /* eslint-enable no-param-reassign */
    }
    axios
      .get('/subway_geo_json.json')
      .then((res) => res.data)
      .then((json) => {
        window.mapkit.importGeoJSON(json, geoJSONDelegate)
      })
  }

  renderAnnotations = () => {
    const { children } = this.props
    const { map } = this

    return React.Children.map(children, (child) => {
      if (!child) {
        return null
      }

      return React.cloneElement(child, {
        map,
      })
    })
  }

  render() {
    const { mapKitIsReady } = this.state
    const { width, height } = this.props
    return (
      <div style={{ width, height }} id="apple-map">
        {mapKitIsReady && this.renderAnnotations()}
      </div>
    )
  }
}

MapElement.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onDeselect: PropTypes.func,
  onMapTypeChange: PropTypes.func,
  onRegionChangeStart: PropTypes.func,
  onRegionChangeEnd: PropTypes.func,
  onScrollStart: PropTypes.func,
  onScrollEnd: PropTypes.func,
  onSelect: PropTypes.func,
  onUserLocationChange: PropTypes.func,
  onUserLocationError: PropTypes.func,
  onZoomStart: PropTypes.func,
  onZoomEnd: PropTypes.func,
  showSubwayLines: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableAutoCenter: PropTypes.bool,
  onConfigurationChange: PropTypes.func,
}

MapElement.defaultProps = {
  children: undefined,
  onDeselect: undefined,
  onMapTypeChange: undefined,
  onRegionChangeStart: undefined,
  onRegionChangeEnd: undefined,
  onScrollStart: undefined,
  onScrollEnd: undefined,
  onSelect: undefined,
  onUserLocationChange: undefined,
  onUserLocationError: undefined,
  onZoomStart: undefined,
  onZoomEnd: undefined,
  showSubwayLines: false,
  width: 'inherit',
  height: 'inherit',
  disableAutoCenter: false,
  onConfigurationChange: undefined,
}

const externalScripts = ['https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js']

const MapElementWithCatch = (props) => (
  <ErrorBoundary message="We're sorry, but Apple Maps' service is down right now. We are working hard to fix the issue.">
    <MapElement {...props} />
  </ErrorBoundary>
)

export default LoadExternalScripts(MapElementWithCatch, externalScripts)
