import React, { Component } from 'react';
import styled from 'styled-components';

const ErrorBoundaryStyles = styled.div`
  background-color: #222;
  padding-top: 100px;
  padding-bottom: 50px;
  color: #fff;
  img {
    width: 300px;
    margin: auto;
    display: block;
  }
  h3 {
    /* Positioning */
    /* Box-model */
    margin-bottom: 50px;
    /* Typography */
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 18px;
    /* Visual */
    color: #ffc03a;
    /* Misc */
  }
  p {
    padding-top: 50px;
    margin: 0 auto;
    font-size: 18px;
    text-align: justify;
    font-family: 'Gotham SSm A', 'Gotham SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.5;
    max-width: 277px;
  }
  .btn-warning-outline {
    color: #ffc03a;
    border-color: #ffc03a;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .btn-warning-outline:hover {
    color: #333;
    background-color: #ffc03a;
  }
`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // log Error to My Service(error, info)
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <ErrorBoundaryStyles>
          <img
            src="//nooklyn-files.s3.amazonaws.com/img/500-cat.png"
            alt="500-cat"
            className="img-fluid mx-auto"
            style={{ width: '300px' }}
          />
          <p>
            We’re sorry, but something went wrong. Please try reloading this page. If that doesn't
            work, please email us at hi@nooklyn.com and we will get back to you soon.
          </p>
          <br />
          <h3>Recommendations</h3>
          <div className="text-center">
            <a href="https://nooklyn.com" className="btn btn-warning-outline">
              Home
            </a>
            <a href="https://nooklyn.com/support" className="btn btn-warning-outline">
              Report a Problem
            </a>
          </div>
        </ErrorBoundaryStyles>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
