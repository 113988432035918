// STATE
const createInitialState = () => ({
  agents: [],
  selectedAgents: [],
  agentMatches: [],
  agentDisplay: false,
  filter: '',
  popoverRefs: [],
  initialSelectedAgents: [],
});

// ACTIONS

export const setStore = (payload, context) => ({
  type: 'SET_AGENT_TYPEAHEAD_STORE',
  payload,
  context,
});

export const updateSelectedAgents = (payload, context) => ({
  type: 'UPDATE_SELECTED_AGENTS',
  payload,
  context,
});

export const updateAgentMatches = (context) => ({
  type: 'UPDATE_AGENT_MATCHES',
  context,
});

export const toggleAgent = (context) => ({
  type: 'TOGGLE_AGENT',
  context,
});

export const addPopoverRef = (payload, context) => ({
  type: 'ADD_AGENT_POPOVER_REF',
  payload,
  context,
});

export const initializeSelectedAgents = (payload, context) => ({
  type: 'INITIALIZE_SELECTED_AGENTS',
  payload,
  context,
});

export const initializeAgents = (payload, context) => ({
  type: 'INITIALIZE_AGENTS',
  payload,
  context,
});
// HELPERS
const generateFilterRegExp = (filter) => {
  const string = filter.split('').map((char) => {
    if (char !== '\\') {
      return `[${char}]`;
    }
    return '\\\\';
  }).join('');
  return new RegExp(`${string}`, 'i');
};

// REDUCER

const methods = (state, action) => {
  const {
    selectedAgents, agents, filter, agentDisplay, popoverRefs, initialSelectedAgents,
  } = state;
  let agentId;
  let newSelectedAgents;
  let agentRegex;
  let newAgentMatches;
  let newPopoverRefs;
  return {
    initializeAgents: () => {
      newSelectedAgents = action.payload.filter(agent => initialSelectedAgents.some(id => id === agent.id));
      return {
        ...state,
        agents: agents.concat(action.payload),
        selectedAgents: selectedAgents.concat(newSelectedAgents),
      };
    },
    setAgentTypeaheadStore: () => ({ ...state, ...action.payload }),
    initializeSelectedAgents: () => ({ ...state, initialSelectedAgents: action.payload.map(function (id) { return parseInt(id); }) }),
    addAgentPopoverRef: () => {
      newPopoverRefs = [...popoverRefs, action.payload];
      return { ...state, popoverRefs: newPopoverRefs };
    },
    updateSelectedAgents: () => {
      agentId = action.payload;
      newSelectedAgents = [];
      if (selectedAgents.some(agent => agent.id === agentId)) {
        newSelectedAgents = selectedAgents.filter(agent => agent.id !== agentId);
      } else {
        newSelectedAgents = [...selectedAgents, agents.find(agent => agent.id === agentId)];
      }
      return {
        ...state,
        selectedAgents: newSelectedAgents,
        filter: '',
        agentMatches: agents,
      };
    },
    updateAgentMatches: () => {
      if (filter.length === 0) {
        if (agents) {
          return { ...state, agentMatches: agents };
        }
        return { ...state };
      }
      agentRegex = generateFilterRegExp(filter);
      newAgentMatches = agents.filter(option => option.name.match(agentRegex));
      return { ...state, agentMatches: newAgentMatches };
    },
    toggleAgent: () => ({ ...state, agentDisplay: !agentDisplay }),
  };
};

function reducerCreator() {
  return function subReducer(state = {}, action) {
    const a = methods(state, action);
    switch (action.type) {
      case 'INITIALIZE_AGENTS':
        return a.initializeAgents();
      case 'SET_AGENT_TYPEAHEAD_STORE':
        return a.setAgentTypeaheadStore();
      case 'INITIALIZE_SELECTED_AGENTS':
        return a.initializeSelectedAgents();
      case 'ADD_AGENT_POPOVER_REF':
        return a.addAgentPopoverRef();
      case 'UPDATE_SELECTED_AGENTS':
        return a.updateSelectedAgents();
      case 'UPDATE_AGENT_MATCHES':
        return a.updateAgentMatches();
      case 'TOGGLE_AGENT':
        return a.toggleAgent();
      default:
        return state;
    }
  };
}

const initialRootState = {
  listingAgent: createInitialState(),
  salesAgent: createInitialState(),
  salesTeamAgent: createInitialState(),
  propertyOwner: createInitialState(),
  all: createInitialState(),
};

const salesAgentReducer = reducerCreator();
const salesTeamAgentReducer = reducerCreator();
const listingAgentReducer = reducerCreator();
const propertyOwnerReducer = reducerCreator();
const agentReducer = reducerCreator();

export default function rootReducer(rootState = initialRootState, action) {
  switch (action.context) {
    case 'salesAgent':
      return {
        ...rootState,
        salesAgent: salesAgentReducer(rootState.salesAgent, action),
      };
    case 'listingAgent':
      return {
        ...rootState,
        listingAgent: listingAgentReducer(rootState.listingAgent, action),
      };
    case 'salesTeamAgent':
    return {
      ...rootState,
      salesTeamAgent: salesTeamAgentReducer(rootState.salesTeamAgent, action),
    };
    case 'propertyOwner':
      return {
        ...rootState,
        propertyOwner: propertyOwnerReducer(rootState.propertyOwner, action),
      };
    case 'all':
      return {
        ...rootState,
        all: agentReducer(rootState.all, action),
      };
    default:
      return {
        ...rootState,
        listingAgent: listingAgentReducer(rootState.listingAgent, action),
        salesAgent: salesAgentReducer(rootState.salesAgent, action),
        salesTeamAgent: salesTeamAgentReducer(rootState.salesTeamAgent, action),
        propertyOwner: salesAgentReducer(rootState.propertyOwner, action),
        all: agentReducer(rootState.all, action),
      };
  }
}
