import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Column } from 'wrappers/FlexTable';
import { setStore, updateActiveSearch, generateNewSearch } from 'reducers/search';
const strftime = require('strftime');

class MoveBy extends Component {

  componentDidMount() {
    $('.datepicker').pickadate({
      container: '#root-picker-outlet',
      min: [new Date()],
    });
    window.addEventListener('click', (e) => {
      const cn = e.target.className;
      if (cn === 'picker__day picker__day--infocus' || cn === 'picker__day picker__day--infocus picker__day--today') {
        this.props.setStore({ moveBy: e.target.attributes['aria-label'].value });
        this.props.generateNewSearch();
        this.props.updateActiveSearch();
      } else if (cn === "picker__button--today") {
        this.props.setStore({ moveBy: strftime('%B %e, %Y', new Date()) });
        this.props.generateNewSearch();
        this.props.updateActiveSearch();
      } else if (cn === 'picker__button--clear') {
        e.stopPropagation();
        this.props.setStore({moveBy: null});
        this.props.generateNewSearch();
        this.props.updateActiveSearch();
      }
    }, true);
  }

  render() {
    const { moveBy } = this.props;
    const className = moveBy ? 'btn-yellow-black btn-search-bar-size' : 'btn-transparent-yellow btn-search-bar-size';
    const value = !moveBy || moveBy.length === 0 ? 'Move By' : moveBy.length > 0 ? moveBy : '';
    return (
      <Column className='searchbar-col'>
        <div id='root-picker-outlet'/>
        <input
          name='moveBy'
          onMouseDown={this.onTouch}
          type='button'
          className={`datepicker button btn-rounded ${className} picker__input`}
          value={value}/>
      </Column>
    );
  }
}

const mapStateToProps = (state) => ({
  moveBy: state.search[state.search.context].moveBy,
});

const mapDispatchToProps = (dispatch) => ({
  setStore: (payload) => dispatch(setStore(payload)),
  updateActiveSearch: () => dispatch(updateActiveSearch()),
  generateNewSearch: () => dispatch(generateNewSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MoveBy);
