import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { setStore, updateAmenityMatches, generateNewSearch } from 'reducers/search';
import DivInput from 'components/DivInput';
import { Row } from 'wrappers/FlexTable.js';
import AmenityTag from './AmenityTag';

class AmenityFilter extends PureComponent {

  onChange = (e) => {
    const { setStore, updateAmenityMatches } = this.props;
    setStore({ amenityFilter: e.target.value });
    updateAmenityMatches();
  }

  removeChosen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { selectedAmenities, setStore, generateNewSearch } = this.props;
    const name = e.target.dataset.name;
    if (name === 'All Amenities') {
      setStore({
        selectedAmenities: [],
        allAmenitiesSelected: false,
      });
    } else {
      setStore({
        selectedAmenities: selectedAmenities.filter(amenity => amenity !== name)
      });
    }
    generateNewSearch();
  };

  onFocus = (e, inputDiv) => {
    inputDiv.scrollTop = inputDiv.scrollHeight;
  }

  render () {
    const { onChange } = this;
    const { amenityFilter, selectedAmenities, allAmenitiesSelected } = this.props;
    const amenityTags = allAmenitiesSelected ? ['All Amenities'] : selectedAmenities;
    return (
      <Row
        style={{
          border:'0px',
          paddingBottom: '10px',
        }}>
        <DivInput
          onFocus= {this.onFocus}
          ref = {me => this.input = me}
          className='btn-text-field'
          style={{
            overflowY: 'scroll',
            borderRadius: '10px',
            border: '1px solid #ffc03a',
            minHeight: '35px',
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            position: 'relative',
            padding: '5px',
            paddingTop: '7px',
            paddingLeft: '12px',
            paddingRight: '5px',
            fontWeight: 500,
            color: '#fff',
          }}
          focusClass='nothingness'
          placeholder={selectedAmenities.length > 0 ? '' : 'Filter Amenities'}
          value={amenityFilter}
          onChange={onChange}>
          {
            amenityTags.map(amenity => <AmenityTag
              type='amenity'
              key={amenity}
              removeChosen={this.removeChosen}
              name={amenity}/>)
          }
        </DivInput>
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setStore: (payload) => dispatch(setStore(payload)),
  updateAmenityMatches: () => dispatch(updateAmenityMatches()),
  generateNewSearch: () => dispatch(generateNewSearch())
});

const mapStateToProps = (state) => ({
  amenityFilter: state.search[state.search.context].amenityFilter,
  selectedAmenities: state.search[state.search.context].selectedAmenities,
  allAmenitiesSelected: state.search[state.search.context].allAmenitiesSelected,
});

export default connect(mapStateToProps, mapDispatchToProps)(AmenityFilter);
