import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import { submitForm, submitted } from '../../reducers/form';
import ModalContent from './ListingSyndicationModalContent';

class ListingSyndicationModal extends Component {
  state = {
    modalIsOpen: false,
    submitted: false,
  };

  openModal = evt => {
    evt.preventDefault();
    this.setState({ modalIsOpen: true });
  };

  closeModal = evt => {
    evt.preventDefault();
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { modalIsOpen } = this.state;

    return (
      <div>
        <a onClick={this.openModal}>
          <i className="nookons-globe" style={{ marginRight: '10px' }} />{' '}
          Syndication
        </a>
        {modalIsOpen && (
          <Modal
            className="Modal__Bootstrap modal-dialog dark-gray-container invite-modal"
            closeTimeoutMS={150}
            isOpen={modalIsOpen}
            onRequestClose={this.closeModal}
          >
            <div className="modal-content modal-content-dark">
              <button className="close" onClick={this.closeModal}>
                <i className="nookons-close-circle-fill" />
              </button>
              <ModalContent {...this.props} />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

const mapState = state => ({
  submitting: state.form.submitting,
});

const mapDispatch = dispatch => ({
  submitForm() {
    dispatch(submitForm());
  },
  submitted() {
    dispatch(submitted());
  },
});

export default connect(
  mapState,
  mapDispatch,
)(ListingSyndicationModal);
