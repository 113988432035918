import ReactDOM from 'react-dom';

const repositionPopover = function() {
  const {
    popoverWrapper: {
      current: popoverWrapper,
    },
    popoverContainer: {
      current: node,
    },
    popover: {
      current: popover,
    },
  } = this.references;
  //POSITIONAL MEASUREMENTS OF BUTTONS
  const position = node.getBoundingClientRect();
  const distanceFromTopOfPositionedParent = node.offsetTop;
  const parentLeftRelativeToGrandparent = this.props.searchContext !== 'searchbar' ? node.parentElement.offsetLeft : 0;
  const distanceFromTopOfWindow = position.top;
  const distanceFromLeftOfWindow = position.left;
  const actualHeight = node.offsetHeight;
  const actualWidth = position.width;
  //START OUT FROM SCRATCH
  popover.style.transform = 'none';
  // LEFT OFFSET NEEDED TO BRING POPOVER TO CENTER OF BUTTON
  let offsetLeft;
  if (this.props.searchContext === 'matrix') {
    offsetLeft = distanceFromLeftOfWindow + (actualWidth/2)
  } else {
    offsetLeft = distanceFromLeftOfWindow  + (actualWidth/2) - parentLeftRelativeToGrandparent;
  }
  // TOP OFFSET NEEDED TO BRING POPOVER UNDERNEATH BUTTON
  let offsetTop = 0;
  if (this.props.searchContext === 'searchbar') {
    offsetTop = distanceFromTopOfWindow + actualHeight + 1;
  } else {
    offsetTop = actualHeight + distanceFromTopOfPositionedParent + 10;
  }
  // APPLY OFFSET VALUES TO POPOVER CONTAINER
  popoverWrapper.style.transform = `translate(${offsetLeft}px, ${offsetTop}px)`;
  // NOW THAT THE CONTAINER IS CENTERED, THE POPOVER ITSELF NEEDS MORE OFFSET TO PREVENT OVERFLOW
  //POPOVER POSITIONAL DATA
  let popoverStyles = getComputedStyle(popover);
  let popoverWidth = parseInt(popoverStyles.width);
  let popoverLeft = 0 - ((popoverWidth / 2) - (position.left + (position.width / 2)));
  let popoverRight = (position.right - (position.width / 2)) + (popoverWidth / 2);
  let popoverHeight = parseInt(popoverStyles.height);
  let popoverBottom = position.bottom + (popoverHeight + 20);
  let offsetPopoverX = 0;
  let offsetPopoverY = 0;
  // OFFSET POPOVER RIGHT OR LEFT DEPENDING ON OVERFLOW
  if (popoverLeft < 0) {
    offsetPopoverX = 0 - popoverLeft;
  } else if (popoverRight > window.innerWidth) {
    offsetPopoverX = window.innerWidth - popoverRight;
  }
  //OFFSET POPOVER BOTTOM IF IT OVERFLOWS
  if (popoverBottom > window.innerHeight) {
    offsetPopoverY = window.innerHeight - (popoverBottom + 30);
  }
  // APPLY OFFSET VALUES
  let offset = `translate(${offsetPopoverX}px, ${offsetPopoverY}px)`;
  popover.style.transform = offset;
};

export default repositionPopover;
