const ValidListenerProps = [
  'onDeselect',
  'onMapTypeChange',
  'onRegionChangeStart',
  'onRegionChangeEnd',
  'onScrollStart',
  'onScrollEnd',
  'onSelect',
  'onUserLocationChange',
  'onUserLocationError',
  'onZoomStart',
  'onZoomEnd',
];

const ValidMapListeners = ValidListenerProps.map(
  prop => ({
    name: prop,
    mapkitNativeName: prop.split(/(?=[A-Z])/).slice(1).join('-').toLowerCase(),
  }),
);

const NonNativeProps = [
  ...ValidListenerProps,
  'children',
  'height',
  'showSubwayLines',
  'width',
  'onConfigurationChange',
];

export { ValidMapListeners, NonNativeProps };
