import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import updatePath from './updatePath';


/*
  Link can be used like a regular html link,
  though you can't override its default click behavior.
  Make sure you only use Link within a tree that has a Router at the top.
  Link will accept any props that an html link normally accepts;

  Example usage:

  <Link href='/listings' />
*/
export default class Link extends PureComponent {
  /* Clicking on a link dispatches path updates to the Router */
  onClick = (e) => {
    e.preventDefault();
    const { title, href } = this.props;
    updatePath(title, href);
    return true;
  }

  /* Link wraps a normal link but overrides
  the click behavior to dispatch the path to Router */
  renderLink = () => {
    const { children } = this.props;
    return (
      <a {...this.props} onClick={this.onClick}>
        {children}
      </a>
    );
  }

  render() {
    return this.renderLink();
  }
}

Link.defaultProps = {
  title: window.location.title,
};

Link.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
