import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Script from 'react-load-script';
import ContainerWrapper from '../ContainerWrapper';
import { ButtonStepper } from '../Buttons';

const title = 'What’s the Address?';

export default class Address extends Component {
  static defaultProps = {
    address: {
      line_one: '',
      city: '',
      state: '',
      zip_code: '',
      unit: '',
    },
    unit: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      address: props.address,
      unit: props.address.unit,
      autoComplete: null,
    };
    this.autocomplete = null;
    this.listener = null;
    this.currentAddressRef = React.createRef();
  }

  componentDidMount() {
    document.title = `Create: Apartments - ${title}`;
  }

  componentWillUnmount() {
    if (this.listener) this.listener.remove();
    if (this.observer) this.observer.disconnect();
  }

  onScriptLoaded = () => {
    this.initAutocomplete();
  };

  initAutocomplete = () => {
    this.autocomplete = new window.google.maps.places.Autocomplete(
      this.currentAddressRef.current,
      {
        componentRestrictions: { country: 'us' },
        fields: ['address_component', 'formatted_address', 'place_id', 'geometry.location'],
      },
    );
    this.listener = this.autocomplete.addListener('place_changed', this.onSelect);
    this.preventDefaultAutocomplete();
  };

  preventDefaultAutocomplete = () => {
    const currentAddress = this.currentAddressRef.current;
    const observeConfig = { attributes: true };
    const observeCallback = () => {
      if (currentAddress.getAttribute('autocomplete') !== 'new-address-field') {
        this.setState({ autoComplete: 'new-address-field' });
      }
    };
    this.observer = new MutationObserver(observeCallback);
    this.observer.observe(currentAddress, observeConfig);
  }

  onSelect = () => {
    const place = this.autocomplete.getPlace();
    const addressTemp = {};

    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name', // city
      sublocality_level_1: 'long_name', // new york city
      administrative_area_level_1: 'short_name', // state
      administrative_area_level_2: 'long_name', // county
      postal_code: 'short_name',
    };

    for (let i = 0; i < place.address_components.length; i += 1) {
      const addressType = place.address_components[i].types[0];
      if (componentForm[addressType]) {
        const val = place.address_components[i][componentForm[addressType]];
        addressTemp[addressType] = val;
      }
    }

    this.setState({
      address: {
        source: 'google',
        source_id: place.place_id,
        full_address: place.formatted_address,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        city:
          (addressTemp.sublocality_level_1
            ? addressTemp.sublocality_level_1
            : addressTemp.locality) || '',
        county: addressTemp.administrative_area_level_2 || '',
        state: addressTemp.administrative_area_level_1 || '',
        zip_code: addressTemp.postal_code || '',
        line_one:
          (addressTemp.street_number
            && addressTemp.route
            && `${addressTemp.street_number} ${addressTemp.route}`)
          || '',
      },
    });
  };

  handleUnitChange = (evt) => {
    const unit = evt.target.value;
    this.setState({ unit });
  };

  handleNext = (evt) => {
    evt.preventDefault();
    const { address, unit } = this.state;
    const { setAddress, handleNext } = this.props;
    setAddress({ ...address, unit });
    handleNext();
  };

  handleBack = (evt) => {
    evt.preventDefault();
    const { handleBack } = this.props;
    handleBack();
  };

  render() {
    const { address, unit, autoComplete } = this.state;

    return (
      <React.Fragment>
        <ContainerWrapper>
          <div>
            <div className="title">
              <h3>{title}</h3>
              <p>
                We won&apos;t display this info publicly, until you extend an offer to a renter.
              </p>
            </div>
            <form className="address-form">
              <div className="row">
                <div className="col-md-8">
                  <label htmlFor="current_address">
                    Current Address
                    <input
                      defaultValue={address.full_address}
                      type="text"
                      name="address"
                      className="general-text-field-dark"
                      id="current_address"
                      ref={this.currentAddressRef}
                      autoComplete={autoComplete}
                    />
                  </label>
                </div>
                <div className="col-md-4">
                  <label htmlFor="apt">
                    Apt/Unit/Suite #
                    <input
                      defaultValue={unit}
                      onChange={this.handleUnitChange}
                      type="text"
                      name="apt"
                      id="apt"
                      className="general-text-field-dark"
                    />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="city">
                    City
                    <input
                      defaultValue={address.city}
                      type="text"
                      name="city"
                      id="city"
                      className="general-text-field-dark"
                      readOnly
                    />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="state">
                    State
                    <input
                      defaultValue={address.state}
                      type="text"
                      name="state"
                      id="state"
                      className="general-text-field-dark"
                      readOnly
                    />
                  </label>
                </div>
                <div className="col-md-8">
                  <label htmlFor="zipcode">
                    Zipcode
                    <input
                      defaultValue={address.zip_code}
                      type="text"
                      name="zipcode"
                      id="zipcode"
                      className="general-text-field-dark"
                      readOnly
                    />
                  </label>
                </div>
              </div>
              <ButtonStepper
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                disabled={!address.full_address || !address.zip_code}
              />
            </form>
          </div>
        </ContainerWrapper>
        <Script
          url={`https://maps.googleapis.com/maps/api/js?key=${window.mapFormConfig.apiKey}&libraries=places`}
          onLoad={this.onScriptLoaded}
        />
      </React.Fragment>
    );
  }
}

Address.propTypes = {
  address: PropTypes.shape({
    line_one: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip_code: PropTypes.string,
    unit: PropTypes.string,
  }),
  unit: PropTypes.string,
  setAddress: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};
