import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class ImageAnnotation extends Component {
  componentDidMount() {
    this.renderAnnotation();
  }

  componentDidUpdate(prevProps) {
    const {
      selected, url, latitude, longitude, title, subtitle, data, map,
    } = this.props;
    if (prevProps.latitude !== latitude || prevProps.longitude !== longitude) {
      map.removeAnnotation(this.annotation);
      this.renderAnnotation();
      return;
    }
    if (prevProps.url !== url) {
      this.annotation.url = url;
    }
    if (prevProps.selected !== selected) {
      this.annotation.selected = selected;
    }
    if (prevProps.title !== title) {
      this.annotation.title = title;
    }
    if (prevProps.subtitle !== subtitle) {
      this.annotation.subtitle = subtitle;
    }
    if (prevProps.data !== data) {
      this.annotation.data = data;
    }
  }

  componentWillUnmount() {
    const { map } = this.props;
    const { annotation } = this;
    map.removeAnnotation(annotation);
  }

  renderAnnotation() {
    const {
      children,
      latitude,
      longitude,
      map,
      selected,
      subtitle,
      title,
      url,
      data,
    } = this.props;

    const calloutDelegate = {
      calloutContentForAnnotation: () => {
        const calloutContent = React.createElement('div', this.props);
        const container = document.createElement('div');
        ReactDOM.render(calloutContent, container);
        return container;
      },
    };

    const coordinate = new window.mapkit.Coordinate(latitude, longitude);

    this.annotation = new window.mapkit.ImageAnnotation(coordinate, {
      calloutEnabled: !!children || !!title,
      callout: children ? calloutDelegate : null,
      selected,
      subtitle,
      title,
      url,
      data,
    });

    map.addAnnotation(this.annotation);
  }

  render() {
    return null;
  }
}

ImageAnnotation.defaultProps = {
  children: undefined,
  latitude: 0,
  longitude: 0,
  title: '',
  subtitle: '',
  selected: false,
  data: undefined,
  map: undefined,
};

ImageAnnotation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  map: PropTypes.shape({
    addAnnotation: PropTypes.func,
    removeAnnotation: PropTypes.func,
  }),
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  selected: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.shape({
    1: PropTypes.string,
    2: PropTypes.string,
    3: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({}),
};

export default ImageAnnotation;
