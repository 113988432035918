import axios from 'axios';
import keysToCamel from './util';

// API Call for search filter data
export const fetchSearchFilterData = async () => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  return axios.get('/search_filter_data_json.json', { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data)
    .then(filterData => keysToCamel(filterData));
};

// Helper API Call to get all Neighborhoods from a Region
// Param(s):
// regionId - Region ID for region we want to fetch neighborhoods for
const fetchNeighborhoodData = async regionId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const filterDataUrl = `/api/v1/regions/${regionId}/neighborhoods`;

  return axios.get(filterDataUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.data)
    .then(data => (
      data.map(neighborhood => ({
        ...neighborhood.attributes,
        id: neighborhood.id,
      }))
    ))
    .then(filterData => keysToCamel(filterData));
};

// API call for fetching search filter data for Regions and Neighborhoods
export const fetchLocationData = async () => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const regionsRequest = axios.get('/api/v1/regions', { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.data)
    .then(data => (
      data.map(region => ({
        ...region.attributes,
        id: region.id,
      }))
    ))
    .then(filterData => keysToCamel(filterData));

  const neighborhoodsRequest = Promise.all([regionsRequest])
    .then(([regions]) => {
      const neighborhoodPromises = regions.map(region => fetchNeighborhoodData(region.id));
      return Promise.all(neighborhoodPromises).then(results => {
        const totalResults = [];
        results.map(result => totalResults.push(...result));
        return totalResults;
      });
    });

  return Promise.all([
    regionsRequest,
    neighborhoodsRequest,
  ])
    .then(([
      regions,
      neighborhoods,
    ]) => ({
      regions,
      neighborhoods,
    }));
};

// API Call for search filter data for Subways and SubwayStops
export const fetchSubwayData = async () => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const subwayLinesRequest = axios.get('/subway_lines.json', { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data)
    .then(filterData => keysToCamel(filterData));

  const subwayStopsRequest = axios.get('/subway_stops_json.json', { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data)
    .then(filterData => keysToCamel(filterData));

  return Promise.all([
    subwayLinesRequest,
    subwayStopsRequest,
  ])
    .then(([
      subwayLines,
      subwayStops,
    ]) => {
      const formatedSubwayLines = subwayLines.map(subwayLine => {
        const stops = [];
        subwayStops.forEach(stop => {
          if (stop.lines.includes(subwayLine)) {
            stops.push(stop);
          }
        });
        return {
          line: subwayLine,
          stops,
        };
      });

      return {
        subwayLines: formatedSubwayLines,
        subwayStops,
      };
    });
};

// API call for fetching all search filters data
const fetchFilterData = async () => {
  const subwayDataRequest = fetchSubwayData();
  const locationDataRequest = fetchLocationData();
  const searchFilterDataRequest = fetchSearchFilterData();

  return Promise.all([
    subwayDataRequest,
    locationDataRequest,
    searchFilterDataRequest,
  ])
    .then(([
      subwayData,
      locationData,
      searchFilterData,
    ]) => ({
      ...subwayData,
      ...locationData,
      ...searchFilterData,
    }));
};

export default fetchFilterData;
