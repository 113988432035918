import React, { useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { getMetaContent } from 'helpers/util';
import receivedSvg from 'images/icons/svg/received.svg';

const VerifyRealEstateId = props => {
  const { agentId, handleVerifyIdentity, setRealEstateLicenseStatus } = props;
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [licensedAs, setLicensedAs] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [licenseExpirationDate, setLicenseExpirationDate] = useState('');

  const rearrangeDate = date => {
    let result = '';
    if (date) {
      const dateArray = date.split('/');
      result = `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
    }
    return result;
  }

  const handleRealEstateIdSubmit = evt => {
    evt.preventDefault();
    const licensed_as = evt.target.licensedAs.value;
    const license_expires_on = rearrangeDate(evt.target.licenseExpirationDate.value);
    const license_number = evt.target.licenseNumber.value;
    setLicensedAs(licensed_as);
    setLicenseNumber(license_number);
    setLicenseExpirationDate(license_expires_on);

    axios.post(`/agents/${agentId}/id_verifications/submit_license.json `, {
      agent: { licensed_as, license_expires_on, license_number },
      authenticity_token: getMetaContent('csrf-token'),
    })
      .then(res => {
        if (res.data.submitted) {
          setRealEstateLicenseStatus('pending');
          setSubmitted(true);
        }
      })
      .catch(err => {
        if (err.response && err.response.data) {
          if (err.response.data.licensed_as) setError(`Licensed as ${err.response.data.licensed_as}`);
          if (err.response.data.license_expires_on) setError(`License expiration date ${err.response.data.license_expires_on}`);
          if (err.response.data.license_number) setError(`License number ${err.response.data.license_number}`);
        }
      });
  };

  return (
    <>
      { submitted
        ? (
          <RealEstateIdReceived
            handleVerifyIdentity={handleVerifyIdentity}
            licensedAs={licensedAs}
            licenseNumber={licenseNumber}
            licenseExpirationDate={licenseExpirationDate}
          />
        ) : (
          <div className="container id-verification-modal">
            <div className="title">
              Verify your real estate ID
            </div>
            <form onSubmit={handleRealEstateIdSubmit}>
              {error && <p className="error text-center" style={{color: '#ffc03a'}}>{error}</p>}
              <div className="label mx-auto mb-2" style={{ width: '440px' }}>
                Licensed as
              </div>
              <input
                className="general-text-field mb-3 d-flex mx-auto"
                name="licensedAs"
                placeholder="Monty Thecat"
                style={{ width: '440px' }}
                required
              />
              <div className="label mx-auto mb-2" style={{ width: '440px' }}>
                License ID number
              </div>
              <input
                className="general-text-field mb-3 d-flex mx-auto"
                name="licenseNumber"
                style={{ width: '440px' }}
                required
              />
              <div className="label mx-auto mb-2" style={{ width: '440px' }}>
                Expiration Date
              </div>
              <InputMask mask="99/99/9999">
                {inputProps => <input {...inputProps} className="general-text-field mb-3 d-flex mx-auto" style={{ width: '440px' }} name="licenseExpirationDate" required />}
              </InputMask>
              <input
                type="submit"
                name="commit"
                value="Submit for approval"
                className="button btn-yellow-black btn-rounded mt-3"
                style={{ minWidth: '200px' }}
              />
            </form>
          </div>
        )}
    </>
  );
};


export const RealEstateIdReceived = props => {
  const { handleVerifyIdentity, licensedAs, licenseNumber, licenseExpirationDate } = props;

  return (
    <div className="container id-verification-modal text-center">
      <img alt="message" src={receivedSvg} />
      <div className="title mt-3">Received!</div>
      <div className="description-notes" style={{ maxWidth: '400px'}}>
        Sit tight! We will send you a confirmation email when your ID has been approved.
      </div>
      <div className="license-info-block">
        <div className="license-info-row">
          <div className="license-info-label">Licensed as</div>
          <div>{licensedAs}</div>
        </div>
        <div className="license-info-row">
          <div className="license-info-label">ID number</div>
          <div>{licenseNumber}</div>
        </div>
        <div className="license-info-row">
          <div className="license-info-label">Expiration date</div>
          <div>{licenseExpirationDate}</div>
        </div>
      </div>
      <div>
        <button className="button btn-transparent btn-rounded" onClick={handleVerifyIdentity}>Go back</button>
      </div>
    </div>
  );
};

export default VerifyRealEstateId;
