import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fetchNewPage, setStore } from 'reducers/search';

function isInteger(value) {
  return (
    typeof value === 'number' && isFinite(value) && Math.floor(value) === value
  );
}

const Pager = props => {
  const { page, className, currentPage, onClick, onKeyPress } = props;
  let cls = 'page';
  if (currentPage) {
    cls = `${cls} current`;
  }

  if (className) {
    cls = `${cls} ${className}`;
  }

  const handleClick = () => {
    onClick(page);
  };

  const handleKeyPress = () => {
    // TODO: add keyboard listener
  };

  return (
    <span className={cls}>
      <a onClick={handleClick}>{page}</a>
    </span>
  );
};

class ListingPagination extends PureComponent {
  static defaultProps = {
    currentPage: 1,
    pageCount: 10,
  };

  constructor(props) {
    super(props);
  }

  handlePaginationChange = page => {
    const { currentPage, _setStore, _fetchNewPage } = this.props;
    if (page !== currentPage) {
      _setStore({
        currentPage: page,
      });
      _fetchNewPage(page);
    }
  };

  handleChange = page => {
    const onChange = this.handlePaginationChange;

    if (this.isValid(page)) {
      onChange(page);
    }
  };

  prev = () => {
    if (this.hasPrev()) {
      this.handleChange(this.props.currentPage - 1);
    }
  };

  next = () => {
    if (this.hasNext()) {
      this.handleChange(this.props.currentPage + 1);
    }
  };

  hasPrev = () => this.props.currentPage > 1;

  hasNext = () => this.props.currentPage < this.props.pageCount;

  isValid = page => isInteger(page) && page >= 1 && page !== this.props.currentPage;

  render() {
    const { pageCount, currentPage } = this.props;
    const pagerList = [];
    const pageBufferSize = 2;

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        const currentPage = this.props.currentPage === i;
        pagerList.push(
          <Pager
            onClick={this.handleChange}
            currentPage={currentPage}
            page={i}
            key={i}
          />
        );
      }
    } else {
      let left = Math.max(1, currentPage - pageBufferSize);
      let right = Math.min(currentPage + pageBufferSize, pageCount);

      if (currentPage - 1 <= pageBufferSize) {
        right = 1 + pageBufferSize * 2;
      }

      if (pageCount - currentPage <= pageBufferSize) {
        left = pageCount - pageBufferSize * 2;
      }
      for (let i = left; i <= right; i++) {
        const currentPage = this.props.currentPage === i;
        pagerList.push(
          <Pager
            onClick={this.handleChange}
            currentPage={currentPage}
            page={i}
            key={i}
          />
        );
      }
    }

    return (
      <div id="listing-paginator" className="text-center">
        <div id="listing-paginator" className="text-center">
          <nav className="pagination">
            <span className={`${this.hasPrev() ? 'prev' : 'prev disabled'}`}>
              <a onClick={this.prev}>
                <i className="nookons nookons-chevron-left" /> Prev
              </a>
            </span>
            {pagerList}
            <span className={`${this.hasNext() ? 'next' : 'next disabled'}`}>
              <a onClick={this.next}>
                Next <i className="nookons nookons-chevron-right" />
              </a>
            </span>
          </nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const myState = state.search[state.search.context];
  const { currentPage, pageCount } = myState;
  return ({
    currentPage,
    pageCount,
  })
}

const mapDispatchToProps = dispatch => ({
  _setStore: payload => dispatch(setStore(payload)),
  _fetchNewPage: page => dispatch(fetchNewPage(page)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListingPagination);
