import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';
import Popover from 'components/generic/Popover';
import MorePopover from './MorePopover';

const More = props => {
  const {
    selected,
    onChange,
    loading,
    options,
    address,
    amenities,
    likedOnly,
    pets,
  } = props;

  const targetRef = useRef(null);
  const [value, setValue] = useState(selected);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  const className = ((!!value.address
    || (value.amenities && !!value.amenities.length)
    || value.likedOnly
    || value.pets)
    && !loading)
    ? 'btn-transparent-yellow' : 'btn-transparent-gray';

  return (
    <Column className="searchbar-col">
      <Popover type="click" targetRef={targetRef}>
        <MorePopover
          value={value}
          onChange={onChange}
          options={options}
          loading={loading}
          address={address}
          amenities={amenities}
          likedOnly={likedOnly}
          pets={pets}
        />
      </Popover>
      <input
        ref={targetRef}
        name="more"
        type="button"
        className={`button btn-rounded ${className} btn-search-bar-size`}
        value="More filters"
      />
    </Column>
  );
};

More.defaultProps = {
  selected: {
    address: '',
    amenities: [],
    likedOnly: false,
    pets: '',
  },
  loading: false,
  options: {
    amenities: [],
    pets: [],
  },
  onChange: () => {},
  address: false,
  amenities: false,
  likedOnly: false,
  pets: false,
};

More.propTypes = {
  selected: PropTypes.shape({
    address: PropTypes.string,
    amenities: PropTypes.arrayOf(PropTypes.string),
    likedOnly: PropTypes.bool,
    pets: PropTypes.string,
  }),
  options: PropTypes.shape({
    amenities: PropTypes.arrayOf(PropTypes.shape({})),
    pets: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  address: PropTypes.bool,
  amenities: PropTypes.bool,
  likedOnly: PropTypes.bool,
  pets: PropTypes.bool,
};

export default More;
