export const deepCopy = objToCopy => JSON.parse(JSON.stringify(objToCopy));

export const getSearch = () => {
  const response = {};
  const query = window.location.search;
  (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .filter(param => (param.split('=')[0] !== ''))
    .forEach(param => {
      const [key, value] = param.split('=');
      response[key] = value
        ? decodeURIComponent(value.replace(/\+/g, ' '))
        : '';
    });
  return response;
};

export const setSearch = (query, replaceLinkWithSearch, type) => {
  let searchQuery = '';

  const itemPriority = {
    baths: 1,
    bedrooms: 1,
    ppsf: 2,
    price: 2,
    squareFeet: 3,
    pets: 4,
    likedOnly: 5,
    noFeeOnly: 5,
    subletOnly: 5,
    address: 6,
    marketAs: 6,
    moveBy: 6,
    amenities: 7,
    regions: 8,
    neighborhoods: 9,
    subways: 10,
    subwayStops: 11,
    topLeft: 12,
    bottomRight: 12,
    page: 13,
    sortBy: 14,
  };

  const searchFilterKeys = Object.entries(query).map(keyValuePair => keyValuePair[0]);
  searchFilterKeys.sort((a, b) => {
    const firstValue = itemPriority[a] || 0;
    const secondValue = itemPriority[b] || 0;
    return firstValue - secondValue;
  });

  searchFilterKeys.forEach(key => {
    const tempQueryFilterString = encodeURI(
      `${searchQuery !== '' ? '&' : ''}${key}=${query[key]}`,
    );
    searchQuery = searchQuery.concat(tempQueryFilterString);
  });

  let linkToReplaceWith = '/rentals';

  switch (type) {
    case 'sales':
      linkToReplaceWith = '/sales';
      break;

    case 'commercials':
      linkToReplaceWith = '/commercial/search';
      break;

    default:
      linkToReplaceWith = '/rentals';
      break;
  }

  window.history.pushState(
    {},
    document.title,
    `${replaceLinkWithSearch ? linkToReplaceWith : ''}${searchQuery && `?${searchQuery}`}`,
  );
};

export const isSearch = () => {
  const query = window.location.pathname.split('/');
  const filteredQuery = query.filter(n => n);

  if (['rentals', 'sales'].includes(filteredQuery[0]) && filteredQuery.length === 1) {
    return true;
  }

  if (filteredQuery[0] === 'commercial' && filteredQuery[1] === 'search' && filteredQuery.length === 2) {
    return true;
  }

  return false;
};
