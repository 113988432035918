import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Modal from './Modal';
import FlashMessage from '../FlashMessage';
import { submitForm, submitted } from '../../reducers/form';
import { getMetaContent } from '../../helpers/util';

class InviteContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      type: '',
      errors: '',
      invitee: '',
    };
  }

  submitted = false;

  handleSubmit = (evt, lease_id) => {
    evt.preventDefault();
    if (!this.submitted) {
      this.submitted = true;
      const email = this.state.email;
      const name = this.state.name;
      const applicant_type = this.state.type;
      this.props._submitForm();
      axios
        .post(`/lease_client_invites.json`, {
          lease_client_invite: { email, name, lease_id, applicant_type },
          authenticity_token: getMetaContent('csrf-token')
        })
        .then(res => {
          this.submitted = false;
          this.props._submitted();
          this.setState({ invitee: res.data.name, errors: '' });
          this.clearFields();
        })
        .catch(err => {
          this.submitted = false;
          this.props._submitted();
          this.setState({ errors: err.response.data, invitee: '' });
        });
    }
  };

  handleNameChange = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value, invitee: '' });
  };

  handleEmailChange = evt => {
    evt.preventDefault();
    this.setState({ email: evt.target.value, invitee: '' });
  };

  handleTypeChange = evt => {
    evt.preventDefault();
    this.setState({ type: evt.target.value, invitee: '' });
  };

  clearFields = () => {
    this.setState({ name: '', email: '', type: '', success: true });
  };

  render() {
    const { lease_id, submitting } = this.props;
    const { errors, invitee } = this.state;

    return (
      <div className="container invite-container">
        <h1>INVITE</h1>
        <hr className="entire-width" />
        {invitee && (
          <FlashMessage
            message={`Invitation successfully sent to ${invitee} !`}
          />
        )}
        <form
          onSubmit={evt => this.handleSubmit(evt, lease_id)}
          className="new_client_lease_invite"
          id="new_client_lease_invite"
        >
          <input name="utf8" type="hidden" value="✓" />
          <input type="hidden" name="authenticity_token" value="" />
          <div className="row justify-content-center mt-2">
            <div className="col-sm-3">
              <input
                onChange={this.handleNameChange}
                value={this.state.name}
                placeholder="Legal name"
                className="general-text-field"
                type="name"
                name="roommate[name]"
                id="roommate_name"
                style={errors && errors.name ? { borderColor: 'red' } : {}}
              />
              {errors && errors.name ? (
                <p className="error">{errors.name}</p>
              ) : (
                ''
              )}
            </div>
            <div className="col-sm-3">
              <input
                onChange={this.handleEmailChange}
                value={this.state.email}
                placeholder="Email address"
                className="general-text-field"
                type="email"
                name="roommate[email]"
                id="roommate_email"
                style={errors && errors.email ? { borderColor: 'red' } : {}}
              />
              {errors && errors.email ? (
                <p className="error">{errors.email}</p>
              ) : (
                ''
              )}
            </div>
            <div className="col-sm-3">
              <select
                value={this.state.type}
                onChange={this.handleTypeChange}
                className="custom-select"
                id="applicant_type_select"
                style={
                  errors && errors.applicant_type ? { borderColor: 'red' } : {}
                }
              >
                <option value="">Applicant type</option>
                <option value="tenant">Tenant</option>
                <option value="guarantor">Guarantor</option>
                <option value="occupant">Occupant</option>
              </select>
            </div>
            <div className="col-sm-2">
              <input
                type="submit"
                name="commit"
                value="Invite"
                className="button btn-black-white btn-rounded"
                disabled={submitting}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

class Invite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      submitted: false,
    };
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    if (this.props.done) {
      window.location.reload();
    }
    this.setState({ modalIsOpen: false });
  };

  handleModalCloseRequest = () => {
    if (this.props.done) {
      window.location.reload();
    }
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { error, submitted, submitting, className } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <div className={className}>
        <div onClick={this.openModal}>{this.props.children}</div>
        {modalIsOpen && (
          <Modal
            className="Modal__Bootstrap modal-dialog dark-gray-container invite-modal"
            closeTimeoutMS={150}
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.handleModalCloseRequest}
          >
            <div className="modal-content modal-content-dark">
              <button className="close" onClick={this.closeModal}>
                <i className="nookons-close-circle-fill" />
              </button>
              <InviteContent
                handleSubmit={this.props.handleSubmit}
                lease_id={this.props.lease_id}
                {...this.props}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

const mapState = state => ({
  submitting: state.form.submitting,
  done: state.form.done,
});

const mapDispatch = dispatch => ({
  _submitForm() {
    dispatch(submitForm());
  },
  _submitted() {
    dispatch(submitted());
  },
});

export default connect(
  mapState,
  mapDispatch,
)(Invite);
