import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { navigate } from '@reach/router';
import { useProContext } from 'pro/ProStore';

const ReviewEdit = (props) => {
  const {
    label, value, disabled, valueLineTwo, handleJump, jumpTo,
  } = props;
  const [lockHover, setLockHover] = useState(false);

  return (
    <div className="row">
      <label className="col-3">{label}</label>
      <div className={disabled ? 'disabled col-8' : 'col-8'}>
        <div>{value}</div>
        {valueLineTwo && <div>{valueLineTwo}</div>}
      </div>
      <div className="col-1">
        {disabled ? (
          <React.Fragment>
            <i className="nookons-lock-fill float-right mr-1" onMouseEnter={() => { setLockHover(true); }} onMouseLeave={() => { setLockHover(false); }}>
              { lockHover
              && (
              <div className="hover-text">
                To prevent fraud, you are unable to edit this section. You can mark this as rented and submit a new listing with another address.
              </div>
              )}
            </i>
          </React.Fragment>
        ) : (
          <i className="nookons-pencil edit float-right mr-1" onClick={() => handleJump(jumpTo)} />
        )}
      </div>
    </div>
  );
};

const PhotoEdit = props => {
  const { listing } = props;

  const handleEditPhotos = () => {
    navigate(`/pro/listings/${listing.id}/photos`);
  };

  return (
    <div className="row">
      <label className="col-3">Photos</label>
      <div className="col-8">
        <div>
          {listing.images.map(image => (
            <img 
              key={image.id}
              src={image.thumb}
              width="100"
              height="100"
            />
          ))}
        </div>
      </div>
      <div className="col-1">
        <i className="nookons-pencil edit float-right mr-1" onClick={handleEditPhotos} />
      </div>
    </div>
  );
}

const ListingEdit = (props) => {
  const { dispatch } = useProContext();
  const [showDetails, setShowDetails] = useState(true);

  const {
    listing,
    handleJump,
  } = props;

  const listingId = listing.id;

  const updateDescription = newDescription => {
    dispatch.updateListingAttribute(listingId, 'description', newDescription);
  };

  return (
    <CSSTransition appear in timeout={300} classNames="stepper">
      <div className="apartment-container">
        <div className="apartment-container-header row no-gutters">
          <div className="col-11">Apartment Details</div>
          <div className="show-hide col-1 text-right">
            { showDetails ? <i className="nookons-chevron-up nklyn-2x" style={{ verticalAlign: 'middle' }} onClick={() => setShowDetails(false)} /> : <i className="nookons-chevron-down nklyn-2x" style={{ verticalAlign: 'middle' }} onClick={() => setShowDetails(true)} />}
          </div>
        </div>
        { showDetails
        && (
        <form
          className="summary-form apartment-container-content"
          onKeyDown={(evt) => {
					  if (evt.which === 13) evt.preventDefault();
          }}
        >
          <ReviewEdit
            listingId={listingId}
            label="Address"
            value={listing.address.lineOne}
            valueLineTwo={`${listing.address.city},${listing.address.state}`}
            disabled
          />
          <ReviewEdit listingId={listingId} label="Unit" value={listing.address.unit} disabled />
          <ReviewEdit
            label="Gross Rent"
            value={accounting.formatMoney(listing.price / 100, { precision: 0 })}
            jumpTo="Rent"
            handleJump={handleJump}
          />
          <ReviewEdit
            label="Move-in Costs"
            value={listing.moveInCosts.join(', ')}
            jumpTo="MoveInCosts"
            handleJump={handleJump}
          />
          <ReviewEdit
            label="Utilities"
            value={listing.utilities.join(', ')}
            jumpTo="Utilities"
            handleJump={handleJump}
          />
          <ReviewEdit label="Bedrooms" value={listing.beds} jumpTo="Beds" handleJump={handleJump} />
          <ReviewEdit
            label="Bathrooms"
            value={listing.fullBaths}
            jumpTo="Bathrooms"
            handleJump={handleJump}
          />
          <ReviewEdit
            label="Half Bath"
            value={listing.halfBaths}
            jumpTo="Bathrooms"
            handleJump={handleJump}
          />
          <ReviewEdit
            label="Square Footage"
            value={listing.sqFootage}
            jumpTo="SqFootage"
            handleJump={handleJump}
          />
          <ReviewEdit label="Pet Policy" value={listing.petPolicy} jumpTo="Pets" handleJump={handleJump} />
          <ReviewEdit
            label="Date Available"
            value={listing.dateAvailable}
            jumpTo="MoveInDay"
            handleJump={handleJump}
          />
          <ReviewEdit
            label="Last Day"
            value={listing.moveOutDate}
            jumpTo="MoveOutDay"
            handleJump={handleJump}
          />
          <ReviewEdit
            label="Amenities"
            value={listing.amenities && listing.amenities.join(', ')}
            jumpTo="Amenities"
            handleJump={handleJump}
          />
          <PhotoEdit listing={listing} />
          <div className="row" style={{ border: 'none' }}>
            <div className="col">
              <label className="m-1">Description</label>
              <textarea
                className="description-textarea general-text-area-dark"
                value={listing.description}
                onChange={evt => updateDescription(evt.target.value)}
              />
            </div>
          </div>
        </form>
        ) }
      </div>
    </CSSTransition>
  );
};

export default ListingEdit;
