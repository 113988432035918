import React from 'react';
import { PayButtonJumbotron } from '../../containers/PayButton';

export default class JumbotronAgreement extends React.Component {
  render() {
    const { feedback, lease, updateLeaseClient } = this.props;

    return (
      <div>
        <h1>Pay</h1>
        <p>
          Congrats on completing your application! The remaining move in
          costs are payable at or before lease signing. If you have paid
          everything, you're good to go!
        </p>
        <PayButtonJumbotron
          lease_id={lease.token}
          className="d-inline-block"
          updateLeaseClient={updateLeaseClient}
        />
        <a
          className="button btn-rounded btn-transparent-yellow"
          href={`/support?feedback[message_context]=${feedback}`}
          target="_blank"
        >
          Help
        </a>
      </div>
    );
  }
}
