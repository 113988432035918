/* eslint-disable no-restricted-syntax */
import { useEffect } from 'react'

const useFitMarkerBounds = (map, markers) => {
  useEffect(() => {
    if (map && markers.length > 0) {
      const bounds = new window.google.maps.LatLngBounds()
      for (const marker of markers) {
        bounds.extend(new window.google.maps.LatLng(marker.latitude, marker.longitude))
      }

      map.fitBounds(bounds)
    }
  }, [markers, map])
}

export default useFitMarkerBounds
