import React from 'react';
import strftime from 'strftime';
import PropTypes from 'prop-types';
import missing from 'images/logo/gold-on-black-wide.png';
import { numberToCurrency } from 'helpers/railsMethods';
import { getTrueDate, truncate, present } from 'helpers/util';
import MatrixField from './MatrixField';
import MatrixFieldPinned from './MatrixFieldPinned';

const imageStyles = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
};

const MatrixListingFirstCol = props => {
  const {
    listing,
    edit,
    changeField,
    superAdmin,
  } = props;

  const buildingUrl = listing && listing.buildingId ? `/buildings/${listing.buildingId}/listings` : null;
  const listingUrl = `/listings/${listing.id}`;
  const subways = listing && listing.subwayLine && listing.subwayLine.length > 0
    ? listing.subwayLine.replace(/[^A-GJL-NQRSWZ1-7]/g, '').split('')
    : null;

  return (
    <div className="col-lg-4 listing-admin-area-agents-section">
      <div
        className="row"
        style={{ position: 'relative', paddingTop: '56.25%' }}
      >
        {(listing.image && listing.image.wide) ? (
          <a href={listingUrl} target="_blank" rel="noreferrer noopener">
            {listing.image.wide.match(/logo\/white-on-black-wide/) ? (
              <img
                alt="listing"
                className="lazy img-fluid mx-auto"
                width="960"
                height="540"
                src={listing.staticMapUrl}
                style={imageStyles}
              />
            ) : (
              <img
                alt="listing"
                className="lazy img-fluid mx-auto"
                width="960"
                height="540"
                src={listing.image.wide}
                style={imageStyles}
              />
            )}
          </a>
        ) : (
          <a href={listingUrl} target="_blank" rel="noreferrer noopener">
            <img
              alt="missing"
              className="lazy img-fluid-missing mx-auto"
              src={missing}
              style={imageStyles}
            />
          </a>
        )}
      </div>
      <div className="row">
        <div className="listing-card-stub container-fluid">
          <div className="row">
            <MatrixField
              edit={edit || null}
              changeField={changeField}
              myValue={present(listing.price) ? listing.price : ''}
              editAttr="price"
              listingId={listing.id}
              myClass="col-sm-6 price-field border-right-0"
            >
              <span id="input">
                {listing && present(listing.price)
                  ? `${numberToCurrency(listing.price)} `
                  : ''}
              </span>
            </MatrixField>
            {listing
                && listing.noFee && (
                  <div className="col-sm-6">
                    <span className="no-fee-tag">NO FEE</span>
                  </div>
            )}
          </div>
          {listing.residential ? (
            <div className="row">
              <MatrixField
                edit={edit || null}
                changeField={changeField}
                myValue={listing && present(listing.bedrooms) ? listing.bedrooms : ''}
                editAttr="bedrooms"
                listingId={listing.id}
                myClass="col-6"
              >
                {!!present(listing.bedrooms) && listing.bedrooms }
                {' '}
                {!!present(listing.bedrooms) && listing.bedrooms > 1 ? 'Beds' : 'Bed'}
              </MatrixField>
              <div
                className="col-6"
              >
                {!!present(listing.bathrooms) && listing.bathrooms }
                {' '}
                {!!present(listing.bathrooms) && listing.bathrooms > 1 ? 'Baths' : 'Bath'}
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-6">
                {listing.squareFeet && `${listing.squareFeet} SF`}
              </div>
              <div className="col-6">
                {listing.squareFeet && `${numberToCurrency((listing.price * 12)
                    / listing.squareFeet)} P/SF`}
              </div>
            </div>
          )}
          {present(listing.roomsAvailable)
              && present(listing.bedrooms)
              && (listing.roomsAvailable === 0 ? (
                ''
              ) : (
                <div className="row">
                  <div className="col-sm-12">
                    {listing.roomsAvailable}
                    {' '}
                    /
                    {' '}
                    {listing.bedrooms}
                    {' '}
                    rooms
                    available
                  </div>
                </div>
              ))}
          <div className="row">
            <div className="col-sm-12">
              {!!listing.shortAddress && !listing.buildingId ? (
                listing.shortAddress
              ) : (
                <a href={buildingUrl}>{listing.shortAddress}</a>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              {listing.neighborhood && listing.neighborhood.name
                  && truncate(listing.neighborhood.name, 23, null, '')}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 border-0">
              {subways
                  && subways.length > 0
                  && subways.map(train => (
                    <img
                      key={`${train}-img`}
                      src={require(`images/subway/2x/${train.toUpperCase()}.png`)}
                      id="subway-img"
                      alt="subway"
                    />
                  ))}
              {superAdmin
                  && !subways && (
                    <span className="label label-nklyn-red">
                      No subway information
                    </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="matrix-listing-card-section">
        <div className="matrix-listing-card-header">
          Meta
        </div>
        <div className="matrix-listing-card-row row">
          <div className="col-6 label">ID</div>
          <div className="col-6 value">{listing.id}</div>
        </div>
        <div className="matrix-listing-card-row row">
          <div className="col-6 label">Created</div>
          <div className="col-6 value">{strftime('%m/%d/%Y', getTrueDate(listing.createdAt))}</div>
        </div>
        <div className="matrix-listing-card-row row">
          <div className="col-6 label">Updated</div>
          <div className="col-6 value">{strftime('%m/%d/%Y', getTrueDate(listing.updatedAt))}</div>
        </div>
        <div className="matrix-listing-card-row row">
          <div className="col-6 label">Network</div>
          <div className="col-6 value">{listing.network}</div>
        </div>
        {superAdmin
            && (
              <>
                <div className="matrix-listing-card-row row align-items-center">
                  <div className="col-6 label">Pinned?</div>
                  <MatrixFieldPinned changeField={changeField} listing={listing} myClass="col-6 value d-flex align-items-center" />
                </div>
                <div className="matrix-listing-card-row row align-items-center">
                  <div className="col-6 label">Paid?</div>
                  { listing.paidAt ? (
                    <div className="col-6 value">
                      Yes @
                      {' '}
                      {listing.paidAt}
                    </div>
                  ) : (
                    <div className="col-6 value">No</div>
                  )}
                </div>
              </>
            )}
      </div>
    </div>
  );
};

MatrixListingFirstCol.defaultProps = {
  listing: null,
  edit: false,
  changeField: () => {},
};

MatrixListingFirstCol.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.number,
    buildingId: PropTypes.number,
    subways: PropTypes.arrayOf(PropTypes.string),
    subwayLine: PropTypes.string,
    image: PropTypes.shape({
      wide: PropTypes.string,
    }),
    staticMapUrl: PropTypes.string,
    price: PropTypes.number,
    noFee: PropTypes.bool,
    residential: PropTypes.bool,
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    squareFeet: PropTypes.number,
    roomsAvailable: PropTypes.number,
    shortAddress: PropTypes.string,
    neighborhood: PropTypes.shape({
      name: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    network: PropTypes.string,
    paidAt: PropTypes.string,
  }),
  edit: PropTypes.bool,
  changeField: PropTypes.func,
  superAdmin: PropTypes.bool,
};

export default MatrixListingFirstCol;
