import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SearchableDropdownContext from '../SearchableDropdownContext';

const TagList = props => {
  const {
    className,
  } = props;

  const {
    setSelected,
    selected,
    singleSelect,
  } = useContext(SearchableDropdownContext);

  const removeSelect = item => {
    const selectionArray = [].concat(selected);
    const indexInArray = selectionArray.findIndex(element => (element[0] === item[0]));

    if (indexInArray !== -1) {
      selectionArray.splice(indexInArray, 1);
    }

    setSelected(selectionArray);
  };

  return (
    <>
      {selected.map(item => (
        <div className={`searchable-dropdown-tag ${className}`} key={item[0]}>
          <input
            type="button"
            className="searchable-dropdown-tag-title"
            data-value={item[1]}
            data-id={item[0]}
            value={item[1]}
            readOnly
          />
          {!singleSelect
            && (
              <button
                type="button"
                className="searchable-dropdown-tag-btn"
                data-value={item[1]}
                data-id={item[0]}
                onClick={e => { removeSelect(item); e.stopPropagation(); }}
              >
                x
              </button>
            )}
        </div>
      ))}
    </>
  );
};
export default TagList;

TagList.propTypes = {
  className: PropTypes.string.isRequired,
};
