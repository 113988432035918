import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal/Modal';

const ModalContent = props => {
  const { advertising_tiers: membershipTiers, agent_slug: agentSlug } = props;
  const [selectedTier, setSelectTier] = useState('');

  const handleChooseSubscription = evt => {
    evt.preventDefault();

    window.location = `/agents/${agentSlug}/subscription_orders?billing_cycle=yearly&tier=${selectedTier}`;
  };

  console.log(selectedTier)

  return (
    <div className="container id-verification-modal membership-tier-modal">
      <div className="title">
        Membership plan
      </div>
      <div className="text-center mb-4" style={{ fontSize: '36px', fontFamily: 'Chronicle Display A, Chronicle Display B, serif' }}>Choose a membership plan</div>
      <div className="d-flex">
        { membershipTiers && !!membershipTiers.length && membershipTiers.map(membershipTier => (
          <div key={membershipTier.id} onClick={() => setSelectTier(membershipTier.id)} className={`nklyn-subscription-tier-card ${selectedTier === membershipTier.id ? 'selected-tier-card' : ''}`}>
            <div className="nklyn-subscription-tier-card-top">
              <h4 className="standard-type">{membershipTier.name}</h4>
              <h3 className="standard-type">{accounting.formatMoney(membershipTier.yearly_cost_cents / 100 / 12, {precision: 0})}/month</h3>
              <p className="standard-type">when billed annually or<br/>{accounting.formatMoney(membershipTier.monthly_cost_cents / 100, {precision: 0})} month-to-month</p>
              <hr/>
              <div className="nklyn-subscription-tier-card-middle">
                <h6 className="standard-type">Select If</h6>
                <p>You have 1 - {membershipTier.active_listings_cap } listings</p>
              </div>
              <hr/>
              <div className="nklyn-subscription-tier-card-bottom">
                <h6 className="standard-type">What&apos;s Included</h6>
                <ul className="list-unstyled">
                  <li><i className="nookons-check nklyn-green"></i> Best in class listing management tech</li>
                  <li><i className="nookons-check nklyn-green"></i> Collabrative leasing system (soon)</li>
                  <li><i className="nookons-check nklyn-green"></i> Ability to list individual rooms</li>
                  <li><i className="nookons-check nklyn-green"></i> 30%+ cheaper than cost of competitors</li>
                  <li><i className="nookons-check nklyn-green"></i> Collect monthly rent (soon)</li>
                  <li><i className="nookons-check nklyn-green"></i> PHoto and floorplan request system</li>
                  <li><i className="nookons-check nklyn-green"></i> 256-bit AES and SSL/TLS encryption</li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button onClick={handleChooseSubscription} className="button btn-rounded btn-block btn-yellow-black mx-auto mt-4" style={{width: '125px'}} disabled={!selectedTier}>
        Save
      </button>
    </div>
  );
};

const MembershipType = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = evt => {
    evt.preventDefault();
    setModalIsOpen(true);
  };

  const closeModal = evt => {
    evt.preventDefault();
    setModalIsOpen(false);
  };

  const handleModalCloseRequest = () => {
    setModalIsOpen(false);
    window.location.reload();
  };

  return (
    <div style={{ display: 'inline' }}>
      <div onClick={openModal} onKeyDown={openModal}>
        {props.children}
      </div>
      {modalIsOpen && (
      <Modal
        className="Modal__Bootstrap modal-dialog dark-gray-container invite-modal"
        closeTimeoutMS={150}
        isOpen={modalIsOpen}
      >
        <div className="modal-content modal-content-dark">
          <button className="close" onClick={closeModal}>
            <i className="nookons-close-circle-fill" />
          </button>
          <ModalContent {...props} handleModalCloseRequest={handleModalCloseRequest} />
        </div>
      </Modal>
      )}
    </div>
  );
};

MembershipType.propTypes = {
  id: PropTypes.number.isRequired,
};

MembershipType.defaultProps = {
  advertising_tiers: [],
  agent_slug: '',
};

export default MembershipType;
