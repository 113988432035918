

// ACTIONS

export const setStore = (payload) => ({
  type: 'SET_STORE',
  payload: payload
});

export const addCollection = (payload) => ({
  type: 'ADD_COLLECTION',
  payload: payload,
});

// STATE

const initialState = {
  listingCollections: [],
};

// REDUCER
export default function reducer( state = initialState, action){

  const { listingCollections } = state;

  switch (action.type) {
  case 'SET_STORE':
    return { ...state, ...action.payload };
  case 'ADD_COLLECTION':
    return {
      ...state, listingCollections: new Array(action.payload, ...listingCollections)
    };
  default:
    return state;
  }
}
