import React, { useState } from 'react';
import { connect } from 'react-redux';
import { navigate, useParams } from '@reach/router';
import { useListing, useProContext } from 'pro/ProStore';
import { setMessage } from 'reducers/flashMessage';
import Sort from './Sort'
import Upload from './Upload';

const ListingManagePhotos = (props) => {
  const { dispatch } = useProContext();
  const { listingId } = useParams();
  const [activeStep, setActiveStep] = useState('PhotoUpload');

  const { setFlashMessage } = props;

  const { dataLoaded, listing } = useListing(listingId);

  const handleBack = () => {
    navigate(`/pro/listings/${listingId}/edit`);
  };

  const handlePhotoSubmit = status => {
    if (status.ok || status.noPhoto) {
      dispatch.invalidateListing(listingId);
      handleBack();
      setFlashMessage('You have successfully submitted photos.');
    } else {
      setFlashMessage('Something went wrong. Please try again.');
    }
  };

  const getStepContent = step => {
    switch (step) {
      case 'PhotoUpload':
        return (
          <Upload
            images={listing.images}
            listingId={listingId}
            handleNext={() => setActiveStep('PhotoSort')}
            handleBack={handleBack}
          />
        );
      case 'PhotoSort':
        return (
          <Sort
            images={listing.images}
            listingId={listingId}
            handlePhotoSubmit={handlePhotoSubmit}
            handleBack={() => { setActiveStep('PhotoUpload'); }}
          />
        );
      default:
        return <div />;
    }
  };

  return dataLoaded ? (
    <div className="pro-container">
      <div className="create-listing-container mx-auto" style={{ maxWidth: '100%' }}>
        <div className="detail-container">
          <div className="active-step-container">
            {getStepContent(activeStep)}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <h1>Loading</h1>
  );
};

const mapDispatchToProps = dispatch => ({
  setFlashMessage(message) {
    dispatch(setMessage(message));
  },
});

export default connect(null, mapDispatchToProps)(ListingManagePhotos);
