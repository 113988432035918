/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import fetchData from 'components/NotSoSlickSlider/API';
import Slide from 'components/NotSoSlickSlider/Slide';

// Props:
// --------------------------------------------------
// childSize - Size of each slide (Required)
// currentIndex - currentIndex of Active Slide (Required)
// firstClick - If clicked for first time (Required)
// onLoad - Callback for when slides are loaded (Required)
// onTransition - Callback for when slides want to transition (Required)
// propData - List of Ids to API Call (Required)
// seeALLBtnLin - Link for See All Button (Optional)
// sliderLength - Amount of Active Slides to Show (Required)
// transition - If slider is in the middle of transition (Required)
// type - Type of Items being Called: rooms or listings (Required) (Default: listings)

// NotSoSlick's SlideManager:
// Somone has to manage all these not so slick slides
const SlideManager = props => {
  const {
    childSize,
    currentIndex,
    firstClick,
    onLoad,
    onTransition,
    propData,
    seeALLBtnLink,
    sliderLength,
    transition,
    type,
  } = props;

  // Setting Async Needed State Vars
  const [isLoading, setIsLoading] = useState(true);
  const [slideData, setSlideData] = useState([]);
  const [slidesToShow, setSlidesToShow] = useState([]);
  const [signedIn, setSignedIn] = useState(false);

  // Creates an array of indexes of slides to be shown
  const slidePreview = activeIndex => {
    const previewedSlides = [];

    if (slideData.length <= sliderLength) {
      // Handles case for when slider has less slideData then page size can fit
      for (let i = 0; i < slideData.length; i += 1) {
        previewedSlides.push(i);
      }
    } else {
      for (
        let i = activeIndex - (sliderLength * 2);
        i < (activeIndex + (sliderLength * 3));
        i += 1
      ) {
        // Gets slide to display by i % slideData.length
        // (remainder will always tell us which slide to show even if looped)
        // Gets reversed slides to display by doing -(Math.abs((slideData.length + i) % slideData.length))
        if (i < 0) {
          previewedSlides.push(-(Math.abs((slideData.length + i) % slideData.length)));
        } else if (Math.floor(i / slideData.length) > 0) {
          previewedSlides.push(-(i % slideData.length));
        } else {
          previewedSlides.push(i % slideData.length);
        }
      }
    }

    setSlidesToShow(previewedSlides);
  };

  // OnClickHandler For Slides
  const handleClick = (inactiveSlide, slideToDisplay) => {
    if (inactiveSlide) {
      if ((slideToDisplay >= (currentIndex + sliderLength))
        || (slideToDisplay === 0 && currentIndex === (slideData.length - sliderLength))) {
        onTransition('next');
      } else {
        onTransition('prev');
      }
    }
  };

  // Update Function Run Once on Start
  useEffect(() => {
    // Adds in See All Btn and Blank Spaces to balance slides to fit page length
    const balanceSlides = slides => {
      // Checks if we need to add a see all button
      if ((seeALLBtnLink !== '' && seeALLBtnLink)) {
        slides.push({ NSSOverride: seeALLBtnLink });
      }

      // Checks to see if we need to add empty spaces to even out page length
      if (sliderLength !== slides.length && sliderLength !== 1) {
        const emptySpaces = (sliderLength - (slides.length % sliderLength));

        for (let x = 0; x < emptySpaces; x += 1) {
          slides.push({ NSSOverride: `EmptySlot${x}` });
        }
      }

      setSlideData(slides);
    };

    // Retrieves If User Logged In then Fetches Listing
    if (sliderLength !== undefined && sliderLength !== 0 && isLoading === true) {
      fetchData(propData, type).then(apiData => {
        setSignedIn(apiData.signedIn);
        balanceSlides(apiData.data);
        setIsLoading(false);
        onLoad();
      });
    } else if (isLoading === false) {
      balanceSlides(slideData.slice(0, propData.length));
    }
  }, [sliderLength]);

  // Reloads slideArray based on loading, currentIndex, and page length
  useEffect(() => {
    if (!isLoading) {
      slidePreview(currentIndex);
    }
  }, [currentIndex, isLoading, sliderLength, slideData]);

  // Prints out slides or loading slide
  // Slides generated from slidesToShow array
  return (isLoading === true) ? (
    <>
      { (propData).map((z, slideIndex) => (
        <div className="slider-slides" style={{ width: childSize }} key={`Loading${slideIndex}`}>
          <Slide
            childSize={childSize}
            slideKey={`slide${slideIndex}`}
            isLoading={isLoading}
          />
        </div>
      ))}
    </>
  ) : (
    <>
      {slidesToShow.map((slideToDisplay, slideIndex) => (
        <Fragment key={`slideContainer${slideIndex}`}>
          <Slide
            childSize={childSize}
            currentIndex={currentIndex}
            data={slideData[Math.abs(slideToDisplay)]}
            firstClick={firstClick}
            handleClick={handleClick}
            isLoading={isLoading}
            signedIn={signedIn}
            slideKey={`slide${slideIndex}`}
            sliderLength={sliderLength}
            slideToDisplay={slideToDisplay}
            transition={transition}
            type={type}
          />
          <div
            className={(
              (Math.abs(slideToDisplay) === (propData.length - 1) && (seeALLBtnLink === '' || !seeALLBtnLink))
            && (firstClick === false || slideData.length <= sliderLength))
              ? 'slider-spacer-end'
              : 'slider-spacer'}
            key={`endSpacer${slideIndex}`}
          >
            &nbsp;
          </div>
        </Fragment>
      ))}
    </>
  );
};

SlideManager.propTypes = {
  childSize: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  firstClick: PropTypes.bool.isRequired,
  onLoad: PropTypes.func.isRequired,
  onTransition: PropTypes.func.isRequired,
  sliderLength: PropTypes.number.isRequired,
  propData: PropTypes.arrayOf(PropTypes.number).isRequired,
  seeALLBtnLink: PropTypes.string.isRequired,
  transition: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default SlideManager;
