import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { usePrevious, useDidUpdate } from 'components/typeaheadComponents/customEffects';
import { generateFilterRegExp } from 'reducers/search/helpers';
import Fade from 'wrappers/Fade';
import Dropdown from './Dropdown';
import Filter from './Filter';
import Option from './Option';

const Typeahead = (props) => {
  const fadeRef = useRef(null);
  const { children } = props;
  const flatChildren = React.Children.toArray(children);
  const childValues = flatChildren && flatChildren.map((c) => [c.props.value, c]) || null;
  const initialState = {
    currentlyRenderedChildren: flatChildren,
    filter: '',
    showDropdown: false,
    visible: false,
  };
  const [state, setState] = useState(initialState);
  const prevState = usePrevious(state);
  const prevProps = usePrevious(props);

  function calculateChildren(filter) {
    const filterRegex = generateFilterRegExp(filter);
    if (childValues) {
      const newMatches = childValues.filter(childValue => childValue[0].match(filterRegex));
      const newChildren = newMatches.map(childValue => childValue[1]);
      setState({ ...state, filter, currentlyRenderedChildren: newChildren });
    }
  }

  function onChange(e) {
    const newFilter = e.target.value;
    calculateChildren(newFilter);
  }

  function onFocus() {
    setState({ ...state, showDropdown: true });
  }

  useEffect(() => {
    function transCallback() {
      if (!state.visible)
        setState({ ...state, showDropdown: false });
    };
    function clickCallback(e) {
      setState({ ...state, visible: false });
    }
    window.addEventListener('click', clickCallback)
    fadeRef.current.el.addEventListener('transitionend', transCallback);
    return function cleanup() {
      window.removeEventListener('click', clickCallback)
      fadeRef.current.el.removeEventListener('transitionend', transCallback);
    };
  });

  useDidUpdate(() => {
    if (prevProps.children !== props.children) {
      calculateChildren(state.filter);
    }
    if (prevState.showDropdown !== state.showDropdown && state.showDropdown) {
      setState({ ...state, visible: true });
    }
  });
  const {
    filter, visible, currentlyRenderedChildren, showDropdown,
  } = state;
  const {
    filterStyle,
    filterClass,
    dropdownStyle,
    dropdownClass,
    containerStyle,
    containerClass,
    selectedOptions,
    removeChosen,
    placeholder,
  } = props;

  return (
    <div
      style={{ display: 'inline-block', ...containerStyle }}
      className={containerClass}
      onClick={ (e)=>{e.stopPropagation() }}
    >
      <Filter
        removeChosen={removeChosen}
        selectedOptions={selectedOptions}
        onChange={onChange}
        filter={filter}
        onFocus={onFocus}
        style={filterStyle}
        className={filterClass}
        placeholder={placeholder}
      />
          <Fade
            customStyles={{
              display: showDropdown ? 'block' : 'none',
            }}
            visible={visible}
            fadeStart='expand-start'
            fadeEnd='expand-end'
            ref={fadeRef}
          >
            <Dropdown
              role="listbox"
              style={dropdownStyle}
              className={dropdownClass}
            >
              {currentlyRenderedChildren}
            </Dropdown>
          </Fade>
    </div>
  );
};

Typeahead.defaultProps = {
  filterStyle: {},
  filterClass: '',
  dropdownStyle: {},
  dropdownClass: '',
  selectedOptions: [],
  removeChosen: null,
  placeholder: '',
};

Typeahead.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  dropdownStyle: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  dropdownClass: PropTypes.string,
  filterStyle: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  filterClass: PropTypes.string,
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    value: PropTypes.string,
  })),
  removeChosen: PropTypes.func,
  placeholder: PropTypes.string,
};

export default Typeahead;
