import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ContainerWrapper from './ContainerWrapper';
import {
  ApartmentDetails, ListingCard, Photos, Rooms,
} from './Submit/index';

const Submit = (props) => {
  const [agree, setAgree] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const {
    listing, listingId,
  } = props;

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setSubmitting(true);
    axios
      .post(
        '/api/v2/listings.submissions.create',
        { listing_id: listingId },
        { headers: { API_TOKEN: localStorage.getItem('apiToken') } },
      )
      .then((res) => {
        setSubmitting(false);
        window.location = '/create/listing/success';
      })
      .catch((err) => {
        console.error(err);
        setSubmitting(false);
      });
  };

  useEffect(() => {
    document.title = 'Create: Submit';
  }, []);

  return (
    <ContainerWrapper>
      <div className="nklyn-ra-summary-container list-room-summary-container">
        <ListingCard listing={listing} />
        <ApartmentDetails listing={listing} />
        <Rooms listing={listing} />
        <Photos listing={listing} />
        <form>
          <div className="row mt-5">
            <div style={{ marginLeft: '10px' }}>
              <input style={{ width: '20px' }} type="checkbox" onChange={() => setAgree(!agree)} />
            </div>
            <div className="col">
              <p>
                By checking this box, I agree that I have read, understood and will comply with
                Nooklyn’s
                {' '}
                <b>
                  Community Guidelines, Terms of Service, Privacy Policy, Federal Fair Housing Laws
                </b>
                {' '}
                and
                {' '}
                <b>NYC Human Rights Law</b>
. When you submit this apartment for approval, a
                human at Nooklyn will look it over to make sure it’s fit to publish.
              </p>
            </div>
          </div>
          <div className="row mx-auto">
            <button
              onClick={handleSubmit}
              className="button btn-transparent-yellow btn-rounded"
              disabled={!agree || submitting}
            >
              Submit for Review
            </button>
          </div>
        </form>
      </div>
    </ContainerWrapper>
  );
};

Submit.defaultProps = {
  listing: {},
  listingId: '',
  images: [],
};

export default Submit;
