import React, { useState } from 'react';
import { cleanCountryData } from '../helpers/util';
import Dropdown from './Dropdown/PaymentDropdown';

const CountryOfCitizenship = (props) => {
  let countryValue;
  const prefill = cleanCountryData.find(data => data.value === props.prefill);
  const [country, setCountry] = useState(prefill);
  if (country) {
    countryValue = country.value;
  }

  return (
    <React.Fragment>
      <Dropdown
        onChange={selection => setCountry(selection)}
        options={cleanCountryData}
        placeholder="Select Country"
        prefill={country}
        value={country}
        inputStyle={{ color: '#fff', fontWeight: '500' }}
        caretStyle={{ color: '#fff' }}
      />
      <input type="hidden" {...props} value={countryValue} />
    </React.Fragment>
  );
};

export default CountryOfCitizenship;
