import React, { Component } from 'react';
import missing from 'images/logo/gold-on-black-wide.png';

export default class FirstCol extends Component {
  render() {
    const { id: roomId, image, shortAddress, price, neighborhood, subwayLine, subwayStops } = this.props;
    const { wide: wideImage } = image || {};
    const { name: hoodName } = neighborhood || {};
    return (
      <div className="col-lg-4 text-center">
        <a
          target="_blank"
          href={`/listing_rooms/${roomId}`}>
          <div className='matrix-img-container'>
            <img
              className="img-fluid"
              src={wideImage !== 'images/logo/gold-on-black-wide.png' ? wideImage : missing}
              alt={shortAddress}/>
          </div>
        </a>
        <br/>
        <hr/>
        <div className="row listing-admin-area-data-row">
          <div className="col-sm-4 text-left">
            <b>Address</b>
          </div>
          <div className="col-sm-8 text-left">
            {shortAddress}
          </div>
        </div>
        <div className="row listing-admin-area-data-row">
          <div className="col-sm-4 text-left">
            <b>Price</b>
          </div>
          <div className="col-sm-8 text-left">
            {accounting.formatMoney(price / 100, { precision: 0 })}
          </div>
        </div>
        <div className="row listing-admin-area-data-row">
          <div className="col-sm-4 text-left">
            <b>Hood</b>
          </div>
          <div className="col-sm-8 text-left">
            {hoodName}
          </div>
        </div>
        <div className="row listing-admin-area-data-row">
          <div className="col-sm-4 text-left">
            <b>Trains</b>
          </div>
          <div className="col-sm-8 text-left">
            {subwayLine.map(line => (
              <img
                key={line}
                className="img-train-small"
                alt={`${line} train`}
                src={require(`images/subway/2x/${line}.png`)}
              />
            ))}
          </div>
        </div>
        <div className="row listing-admin-area-data-row">
          <div className="col-sm-4 text-left">
            <b>Stations</b>
          </div>
          <div className="col-sm-8 text-left">
            {subwayStops.map((station, index) => {
              if (index === 0) {
                return <a key={station.id} href={`/subway_stops/${station.id}`}>
                  {station.name} ({station.lines.join(', ')})
                </a>;
              } else {
                return <a key={station.id} href={`/subway_stops/${station.id}`}>,
                  {station.name} ({station.lines.join(', ')})
                </a>;
              }
            })}
          </div>
        </div>
      </div>
    );
  }
}
