import React, { useEffect, useState, useRef } from 'react';
import { keysToCamel } from 'pro/util/inflector';
import { numberToCurrency } from 'helpers/railsMethods';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import SimpleCardForm from './SimpleCardForm';

const PaymentDescription = props => {
  const {
    paymentDescription: {
      description,
      baseDescription,
      baseAmount,
      convenienceFee,
      totalAmount,
    },
  } = props;

  return (
    <div className="nklyn-ad-tier-summary-card">
      <ul className="list-unstyled">
        <h4 className="standard-type">{description}</h4>
        <br />
        <li>
          {baseDescription}
          {' '}
          <b>{numberToCurrency(baseAmount / 100.0)}</b>
        </li>
        { convenienceFee > 0 && (
          <li>
            Convenience Fee:
            {' '}
            <b>{numberToCurrency(convenienceFee / 100.0)}</b>
          </li>
        )}
        <hr />
        <li className="nklyn-pay-total">
          Due Today:
          {' '}
          <b>{numberToCurrency(totalAmount / 100.0)}</b>
        </li>
      </ul>
    </div>
  );
};

const AuthorizationLanguage = props => {
  const {
    paymentDescription: {
      authorizationLanguage,
    },
  } = props;

  return (
    <p className="nklyn-pay-para">
      {authorizationLanguage}
      {' '}
      Sales Tax, if applicable for your jurisdiction, is included in the price of your order. Use of the Nooklyn platform is subject to the Nooklyn
      {' '}
      <a href="/terms" target="_blank">Terms of Service</a>
      .
    </p>
  );
};

const BillingAccountPaymentAuthorization = props => {
  const { payment_descriptor: initialPaymentDescriptor } = props;

  const [paymentDescriptor, setPaymentDescriptor] = useState(null);
  const [paymentDescription, setPaymentDescription] = useState({});
  const [paymentCaptureToken, setPaymentCaptureToken] = useState('');
  const [error, setError] = useState(null);
  const [defaultPayment, setDefaultPayment] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasDefaultPayment, setHasDefaultPayment] = useState(false);
  const hiddenSubmit = useRef(null);
  const [setupIntent, setSetupIntent] = useState('');
  const [cardSubmitDisabled, setCardSubmitDisabled] = useState(false);

  const apiToken = localStorage.getItem('apiToken');
  const source = axios.CancelToken.source();

  const { billingAccountId } = paymentDescription;

  const generateStripeSetupIntent = () => {
    axios.post('/api/v2/payments.generate_stripe_setup_intent', {
      billing_account_id: billingAccountId,
    }, {
      headers: { API_TOKEN: apiToken },
      cancelToken: source.token,
    })
      .then(res => res.data)
      .then(res => keysToCamel(res))
      .then(res => {
        if (res.ok) {
          setSetupIntent(res.setupIntent);
        }
      });
  };

  const fetchBillingAccount = id => {
    fetch(`/api/v2/billing_accounts.fetch?billing_account_id=${id}`, {
      headers: {
        API_TOKEN: apiToken,
      },
    }).then(response => response.json())
      .then(response => keysToCamel(response))
      .then(response => {
        const {
          ok,
          billingAccount = {},
        } = response;

        if (ok) {
          const { defaultPayment } = billingAccount;

          if (defaultPayment === null) {
            setHasDefaultPayment(false);
          } else {
            setHasDefaultPayment(true);
            setDefaultPayment(defaultPayment);
          }
        }
      });
  };

  const fetchPaymentDescription = paymentDescriptor => {
    fetch('/api/v2/payments.describe_payment', {
      headers: {
        API_TOKEN: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ payment_descriptor: paymentDescriptor }),
    }).then(response => response.json())
      .then(response => keysToCamel(response))
      .then(response => {
        const {
          ok,
          paymentDescription = {},
        } = response;

        if (ok) {
          setPaymentDescription(paymentDescription);
        }
      });
  };

  const onCancel = () => {
    setHasDefaultPayment(true);
  };

  const cyclePaymentDescriptor = paymentDescriptor => {
    fetch('/api/v2/payments.cycle_payment', {
      headers: {
        API_TOKEN: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ payment_descriptor: paymentDescriptor }),
    }).then(response => response.json())
      .then(response => keysToCamel(response))
      .then(response => {
        const {
          ok,
          paymentDescriptor,
        } = response;

        if (ok) {
          setPaymentDescriptor(paymentDescriptor);
          generateStripeSetupIntent();
        }
      });
  };

  const SetupIntentConfirmed = paymentMethod => {
    const payload = {
      billing_account_id: billingAccountId,
      payment_method: paymentMethod,
    };

    setError(null);

    fetch('/api/v2/billing_accounts.update_default_payment', {
      method: 'post',
      headers: {
        API_TOKEN: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(response => keysToCamel(response))
      .then(response => {
        setCardSubmitDisabled(false);
        const {
          ok,
          error: {
            friendlyMessage: friendlyErrorMessage,
          } = {},
        } = response;

        if (ok) {
          setHasDefaultPayment(true);
          fetchBillingAccount(billingAccountId);
          generateStripeSetupIntent();
        } else {
          setError(friendlyErrorMessage);
        }
      });
  };

  const authorizePayment = evt => {
    evt.preventDefault();

    setIsSubmitting(true);
    setError(null);

    fetch('/api/v2/billing_accounts.authorize_payment', {
      headers: {
        API_TOKEN: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ payment_descriptor: paymentDescriptor }),
    }).then(response => response.json())
      .then(response => keysToCamel(response))
      .then(response => {
        const {
          ok,
          paymentAuthorization: {
            token: captureToken,
          } = {},
          error: {
            friendlyMessage: friendlyErrorMessage,
          } = {},
        } = response;

        if (ok) {
          // There might be a race condition here that React doesn't update the
          // view before to form is submitted. If we get complaints from users
          // that they've been authorized but the order doesn't proces, this may
          // be why. We haven't encountered it in testing though.
          setPaymentCaptureToken(captureToken);
          hiddenSubmit.current.click();
        } else {
          cyclePaymentDescriptor(paymentDescriptor);
          setError(friendlyErrorMessage);
          setHasDefaultPayment(false);
          setIsSubmitting(false);
        }
      });
  };

  const agreeAndContinue = evt => {
    evt.preventDefault();

    setIsSubmitting(true);
    setError(null);

    // Just in case there's a payment token here, let's not accidentally
    // pass it in.
    setPaymentCaptureToken('');
    hiddenSubmit.current.click();
  };

  useEffect(() => {
    setPaymentDescriptor(initialPaymentDescriptor);
  }, [initialPaymentDescriptor]);

  useEffect(() => {
    if (paymentDescriptor) {
      fetchPaymentDescription(paymentDescriptor);
    }
  }, [paymentDescriptor]);

  useEffect(() => {
    if (!billingAccountId) {
      return;
    }

    fetchBillingAccount(billingAccountId);
  }, [billingAccountId]);

  useEffect(() => {
    if (paymentDescriptor && billingAccountId) {
      generateStripeSetupIntent();
    }
  }, [paymentDescriptor, billingAccountId]);

  const isLoaded = Object.keys(paymentDescription).length > 0;

  const {
    brand,
    expMonth,
    expYear,
    last4,
  } = defaultPayment;

  const defaultPaymentOnFile = Object.keys(defaultPayment).length > 0;

  const {
    totalAmount = 0,
  } = paymentDescription;

  return isLoaded ? (
    <div>
      <PaymentDescription
        paymentDescription={paymentDescription}
      />
      <input type="hidden" name="payment_capture_token" value={paymentCaptureToken} />
      <input type="hidden" name="payment_descriptor" value={paymentDescriptor} />
      { error && (
        <div className="error-block">{error}</div>
      )}
      { isSubmitting ? (
        <>
          <br />
          <input className="button btn-yellow-black btn-rounded" type="submit" onClick={evt => evt.preventDefault()} value="Processing..." />
        </>
      ) : [
        hasDefaultPayment ? (
          <>
            <h6 className="standard-type nklyn-white">Payment Method</h6>
            <div className="nklyn-pay-method-card">
              <p className="nklyn-pay-method-card-summary">{`${brand} *${last4} Exp. ${expMonth}/${expYear}`}</p>
              <p className="nklyn-pay-method-card-button" onClick={() => setHasDefaultPayment(false)}><i className="nookons-edit" /></p>
            </div>
            <AuthorizationLanguage paymentDescription={paymentDescription} />
            { totalAmount > 0 ? (
              <input
                className="button btn-yellow-black btn-rounded"
                type="submit"
                onClick={authorizePayment}
                value="Pay and Continue"
              />
            ) : (
              <input
                className="button btn-yellow-black btn-rounded"
                type="submit"
                onClick={agreeAndContinue}
                value="Agree and Continue"
              />
            )}
          </>
        ) : (
          <Elements stripe={loadStripe(paymentDescription.stripeKey)}>
            <SimpleCardForm
              onSetupIntentConfirmed={SetupIntentConfirmed}
              onCancel={defaultPaymentOnFile ? onCancel : undefined}
              setupIntent={setupIntent}
              disabled={cardSubmitDisabled}
              setDisabled={setCardSubmitDisabled}
            />
          </Elements>
        ),
      ]}
      <input
        type="submit"
        ref={hiddenSubmit}
        value="Hidden Pay and Continue"
        style={{ display: 'none' }}
      />
    </div>
  ) : (
    <h1>Loading</h1>
  );
};

export default BillingAccountPaymentAuthorization;
