import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { setStore, updateHoodMatches, generateNewSearch, updateRegionalHoodMatches } from 'reducers/search';
import DivInput from 'components/DivInput';
import { Row } from 'wrappers/FlexTable';
import HoodTag from './HoodTag';

class HoodFilter extends PureComponent {

  onChange = (e) => {
    const { setStore, updateHoodMatches, updateRegionalHoodMatches } = this.props;
    setStore({ hoodFilter: e.target.value });
    updateHoodMatches();
    updateRegionalHoodMatches();
  }

  onFocus = (e, inputDiv) => {
    inputDiv.scrollTop = inputDiv.scrollHeight;
  }

  removeChosen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { selectedHoods, selectedRegions, setStore, generateNewSearch } = this.props;
    const type = e.target.dataset.type;
    const id = e.target.dataset.id;
    if (type === 'region') {
      setStore({
        selectedRegions: selectedRegions.filter(region => region.id !== id),
        selectedHoods: selectedHoods.filter(hood => hood.region !== id)
      });
    } else {
      setStore({
        selectedHoods: selectedHoods.filter(hood => hood.id !== id)
      });
    }
    generateNewSearch();
  };

  render() {
    const { onChange } = this;
    const { hoodFilter, selectedRegions, selectedHoods } = this.props;
    return (
      <Row
        style={{
          border:'0px',
          paddingBottom: '10px',
        }}>
        <DivInput
          onFocus={this.onFocus}
          className='btn-text-field'
          style={{
            overflowY: 'scroll',
            borderRadius: '10px',
            border: '1px solid #ffc03a',
            minHeight: '35px',
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            position: 'relative',
            padding: '5px',
            paddingTop: '7px',
            paddingLeft: '12px',
            paddingRight: '5px',
            fontWeight: 500,
            color: '#fff',
          }}
          focusClass='nothingness'
          placeholder={selectedHoods.length > 0 ? '' : `Filter ${selectedHoods}`}
          value={hoodFilter}
          onChange={onChange}>
        </DivInput>
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setStore: (payload) => dispatch(setStore(payload)),
  updateHoodMatches: () => dispatch(updateHoodMatches()),
  updateRegionalHoodMatches: () => dispatch(updateRegionalHoodMatches()),
  generateNewSearch: () => dispatch(generateNewSearch()),
});

const mapStateToProps = (state) => ({
  hoodFilter: state.search[state.search.context].hoodFilter,
  selectedRegions: state.search[state.search.context].selectedRegions || [],
  selectedHoods: state.search[state.search.context].selectedHoods || [],
});

export default connect(mapStateToProps, mapDispatchToProps)(HoodFilter);
