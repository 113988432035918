import React, { Component } from 'react';
import DraggableTags from './DraggableTags';
import PropTypes from 'prop-types';
import DivInput from './../DivInput'

export default class AutofillTagForm extends Component {

// options = [{key: value}, {key: value}]

  state = {
    options: this.props.options.filter(option => !this.props.prefill.some(prefillOption => prefillOption === option.name)).map(option => option.name),
    ids: this.props.options.reduce((acc, option, i) => {
      acc[option.name] = option.id;
      return acc;
    }, {}),
    matches: [],
    value: '',
    chosen: this.props.prefill,
    selected: -1,
    selectHeight: 200,
  }

  componentDidMount() {
    this.scrolling = false;
  }

  onChange = (e) => {
    const val = e.target.value;
    let string = val.split('').map(char => {
      if (char !== '\\') {
        return `[${char}]`
      } else {
        return '\\\\'
      }
    }).join('')
    const regex = new RegExp(`${string}`, 'i');
    const { options, matches } = this.state;
    let newMatches = options.filter(option => option.match(regex));
    let newLength = newMatches.length;
    if (newLength !== matches.length) {
      this.setState({
        overflowY: 'scroll',
        matches: newMatches,
        value: val,
        selected: -1,
        selectHeight: '200px' }, () => {
        if (newLength < 7) {
          this.setState({ selectHeight: (newLength * 30), overflowY: 'hidden' });
        }
      });
    } else {
        this.setState({
          value: e.target.value,
          selected: -1
        });
      }
  }

  onClick = (e) => {
    if (!this.scrolling) {
      let text = e.target.innerText;
      const { options, chosen } = this.state;
      let newOptions = options.filter((stop) => stop !== text);
      let newChosen = new Array(...chosen, text);
      this.setState({
        chosen: newChosen,
        matches: [],
        selected: -1,
        options: newOptions,
      });
    }
    this.scrolling = false;
  }

  removeChosen = (e) => {
    e.preventDefault();
    let value = e.target.previousSibling.value;
    let newChosen = new Array(...this.state.chosen).filter(option => option !== value);
    this.setState({ chosen: newChosen });
  }

  onStartSelection = (e) => {
    const { selected, matches } = this.state;
    if (e.key === 'ArrowDown' && selected < matches.length - 1) {
      this.setState({ selected: selected + 1 });
    } else if (e.key === 'ArrowUp' && selected > 0) {
      this.setState({ selected: selected - 1 });
    } else if (e.key === 'Enter' && selected > -1) {
      let ev = { target: this.refs[selected] };
      this.input.blur();
      this.onClick(ev);
    }
  }

  hoverChoice = (e) => {
      this.setState({ selected: parseInt(e.target.dataset.key) });
  }

  onInputFocus = (e) => {
    e.stopPropagation();
    const { options, value } = this.state;
    let string = value.split('').map(char => {
      if (char !== '\\') {
        return `[${char}]`
      } else {
        return '\\\\'
      }
    }).join('')
    const regex = new RegExp(`${string}`, 'i');
    var matches = options.filter(stop => stop.match(regex));
    if (matches.length === 0 && value.length === 0) {
     var newMatches = options;
   } else {
     var newMatches = matches
   }
   let selectHeight = 200;
   if (newMatches.length < 7) {
     selectHeight = (newMatches.length * 30);
   }
    this.setState({ matches: newMatches, selectHeight: selectHeight });
  }

  onInputBlur = () => {
    this.setState({ matches: [] });
  }

  onTouchMove = (e) => {
    this.scrolling = true;
  }

  render () {
    const { prefill, label, placeholder, title, finalOption, fieldName, options } = this.props;
    const keys = options.map(option => option.id)
    const { selectHeight, matches, selected, ids, chosen, value, overflowY } = this.state;
    return (
      <div style={{ width: '100vw' }} className="field col-md-6">
        <label htmlFor={label} className="general-text-label">{title}</label>
        <DivInput
          ref={input => this.input = input}
          name={label}
          onMouseDown={this.onInputFocus}
          onBlur={this.onInputBlur}
          onKeyDown={this.onStartSelection}
          onChange={this.onChange}
          value={value}
          style={{
            height: '34px',
          }}
          placeholder={placeholder}
          className='general-text-field-dark'
          focusClass='general-text-field-focus'
        >
      </DivInput>
        { matches.length > 0 ?
          <div
            ref={dropdown => this.dropdown = dropdown}
            style={{
              padding: '0px',
              backgroundColor: '#000',
              overflowY: overflowY,
              overflowX: 'hidden',
              position: 'absolute',
              height: selectHeight,
              maxHeight: '300px',
              width: 'auto',
              border: '1px solid rgba(0, 0, 0, 0.15)',
              zIndex: 100,
            }}>
            <ul
              style={{
                width: 'auto',
                backgroundColor: '#000',
                padding: '0px',
                border: '0px',
                overflow: 'hidden',
                position:'relative',
                cursor: 'pointer',
                top: '-1px',
                display:'block',
                zIndex: 100,
              }}
              className="dropdown-menu nklyn-listing-manage-dropdown">
              { matches.map((option, index) => {
                if (selected === index) {
                  return <li
                    ref={selected}
                    style={{
                      width:'auto',
                      height:'30px',
                      paddingTop: '6px',
                      color: '#fff',
                      fontWeight: '500'}}
                    data-key={keys[index]}
                    key={`${option}-${index}`}
                    onMouseDown={this.onClick}
                    onTouchEnd={this.onClick}
                    onTouchMove={this.onTouchMove}
                    className="dropdown-item selected-option"
                  >
                    {option}
                  </li>;
                } else {
                  return <li
                    style={{
                      width:'auto',
                      height: '30px',
                      paddingTop: '6px',
                      color: '#fff',
                      fontWeight: '500'}}
                    data-key={index}
                    key={`${option}-${index}`}
                    onMouseDown={this.onClick}
                    onTouchEnd={this.onClick}
                    onTouchMove={this.onTouchMove}
                    onMouseEnter={this.hoverChoice}
                    className="dropdown-item subway-option">
                    {option}
                  </li>;
                }
              }) }
            </ul></div> : ''}
        <DraggableTags
          chosen={chosen}
          ids={ids}
          removeChosen={this.removeChosen}
          finalOption={finalOption}
          fieldName={fieldName}/>
      </div>
    );
  }
}

AutofillTagForm.defaultProps = {
  prefill: [],
}

AutofillTagForm.propTypes = {
  prefill: PropTypes.array
}
