const appleResidentialImageMarker = {
  1: require('images/map/new/outline-32.png'),
  2: require('images/map/new/outline-64.png'),
  3: require('images/map/new/outline-96.png'),
};

const appleLocationImageMarker = {
  1: require('images/map/new/red-32.png'),
  2: require('images/map/new/red-64.png'),
  3: require('images/map/new/red-96.png'),
};

const appleCurrentImageMarker = {
  1: require('images/map/new/residential-32.png'),
  2: require('images/map/new/residential-64.png'),
  3: require('images/map/new/residential-96.png'),
};

export { appleResidentialImageMarker, appleLocationImageMarker, appleCurrentImageMarker };
