import { pushState, replaceState, pushParams } from './CustomEvents';
// helper methods for updating the url and dispatching custom events
export default function updatePath(title, path, data = {}) {
  window.history.pushState(data, title, path);
  window.dispatchEvent(pushState());
}

export function replacePath(path, paramsOnly = false) {
  window.history.replaceState(null, null, path);
  window.dispatchEvent(replaceState(paramsOnly));
}

export function updateParams(params) {
  const path = window.location.pathname + '?' + params;
  window.history.pushState(null, null, path);
  window.dispatchEvent(pushParams());
}
