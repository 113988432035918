import { useEffect, useRef } from 'react';

export default function useDidUpdate(func, inputs) {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current)
      func();
    else
      didMount.current = true;
  }, inputs);
}
