import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Column, Row } from 'wrappers/FlexTable';
import CustomInput from 'components/CustomInput';
import LoadingSpinner from 'components/LoadingSpinner';
import ApplyClear from '../ApplyClear';

const SquareFeetPopover = props => {
  const {
    loading,
    value,
    onChange,
    targetRef,
  } = props;

  const [queuedValue, setQueuedValue] = useState(value);
  const [warningText, setWarningText] = useState(null);

  const isNumeric = stringToCheck => /^\d+$/.test(stringToCheck);
  const handleClick = selected => setQueuedValue(selected);

  const handleUpdate = updateType => {
    if (updateType === 'apply') {
      if (parseInt(queuedValue[0], 10) > parseInt(queuedValue[1], 10)) {
        return setWarningText('Warning: Minimum is greater then Maximum');
      }
      if (queuedValue[0] === queuedValue[1] && queuedValue[0] !== '' && queuedValue[1] !== '') {
        return setWarningText('Warning: Minimum and Maximum are equal');
      }
      if (!isNumeric(queuedValue[0]) || !isNumeric(queuedValue[1])) {
        if (
          !(isNumeric(queuedValue[0]) && queuedValue[1] === '')
          && !(isNumeric(queuedValue[1]) && (queuedValue[0] === '' || queuedValue[0] === 0))
          && !(queuedValue[0] === '' && queuedValue[1] === '')
        ) {
          return setWarningText('Warning: Input must be positive whole numbers');
        }
      }

      if (warningText !== null) {
        setWarningText(null);
      }

      onChange(queuedValue);
      return targetRef.current ? targetRef.current.click() : null;
    }
    return setQueuedValue(['', '']);
  };

  return (
    <div className="square-feet-pop">
      <Column
        role="button"
        tabIndex={0}
        style={{
          border: '1px solid rgb(64,64,64)',
          padding: '5px',
          position: 'absolute',
          backgroundColor: 'rgb(17,17,17)',
        }}
        className="squareFeet"
      >
        {!loading
          ? (
            <>
              <Row style={{ borderRight: '0px' }}>
                <Column
                  style={{ width: '50%', padding: '10px' }}
                >
                  <CustomInput
                    className="btn-text-field btn-rounded btn-black-yellow btn-price-field"
                    style={{ border: '1px solid', fontSize: '14px', width: '130px' }}
                    placeholder="Min"
                    value={queuedValue[0]}
                    onChange={newMin => handleClick([newMin, queuedValue[1]])}
                  />
                </Column>
                <span className="text-white d-flex align-items-center"> - </span>
                <Column
                  style={{ width: '50%', padding: '10px' }}
                >
                  <CustomInput
                    className="btn-text-field btn-rounded btn-black-yellow btn-price-field"
                    style={{ border: '1px solid', fontSize: '14px', width: '130px' }}
                    placeholder="Max"
                    value={queuedValue[1]}
                    onChange={newMax => handleClick([queuedValue[0], newMax])}
                  />
                </Column>
              </Row>
              {warningText
                && (
                  <div
                    style={{
                      width: '100%',
                      padding: '12px 16px',
                      borderRadius: '4px',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      marginBottom: '12px',
                      backgroundColor: 'rgba(248, 215, 218, 1)',
                      borderColor: 'rgba(220, 53, 69, 1)',
                      color: 'rgba(114, 28, 36,1)',
                    }}
                  >
                    {warningText}
                  </div>
                )}
              <ApplyClear style={{ top: '20px', border: 'none' }} handleUpdate={handleUpdate} />
            </>
          )
          : <LoadingSpinner />}
      </Column>
    </div>
  );
};

SquareFeetPopover.propTypes = {
  loading: PropTypes.bool.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  targetRef: PropTypes.shape({
    current: PropTypes.shape({
      click: PropTypes.func,
    }),
  }).isRequired,
};

export default SquareFeetPopover;
