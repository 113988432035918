import axios from 'axios';
import keysToCamel from './util';

// API call for fetching a neighborhood
// Param(s):
// neighborhoodId - Neighborhood ID for neighborhood we want to fetch
const fetchNeighborhood = async neighborhoodId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const neighborhoodUrl = `/api/v1/neighborhoods/${neighborhoodId}`;
  const neighborhoodRegionUrl = `/api/v1/neighborhoods/${neighborhoodId}/region`;

  return axios.get(neighborhoodUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.data)
    .then(neighborhood => axios.get(
      neighborhoodRegionUrl,
      { headers: { API_TOKEN: apiToken }, cancelToken },
    ).then(regionResults => regionResults.data.data)
      .then(region => keysToCamel({
        id: neighborhood.id,
        ...neighborhood.attributes,
        region: {
          id: region.id,
          ...region.attributes,
        },
      })));
};

export default fetchNeighborhood;
