import React, { Component, forwardRef } from 'react';
import reposition from 'components/listingSearchComponents/searchPopovers/reposition';
import { debounce } from 'helpers/animations';
import ShareHOC from 'components/listings/cards/components/buttons/ShareHOC';
import ManagePopover from './ManagePopover';

const ShareButton = forwardRef((props, ref) => {
  const { onClick, children } = props;
  return (
    <div ref={ref} role="menu" onClick={onClick} className="d-inline-block">
      {children}
    </div>
  );
});

const EnhancedShareButton = ShareHOC(ShareButton);

class Nav extends Component {
  state = {
    display: false,
  }

  repositionPopover = reposition.bind(this)

  isDragging = false;

  references = {
    popoverWrapper: React.createRef(),
    popoverContainer: React.createRef(),
    popover: React.createRef(),
  };

  componentDidMount() {
    this.repositionPopover();
    window.currentlyRunningAnimation = false;
    this.wheelCB = debounce(() => {
      this.repositionPopover();
    }, 150);
    window.addEventListener('wheel', this.wheelCB);
    this.touchstartCB = () => {
      if (document.activeElement) {
        document.activeElement.blur();
      }
    };
    window.addEventListener('touchstart', this.touchstartCB);
    this.touchmoveCB = debounce(() => {
      if (!this.isDragging) {
        this.isDragging = true;
      }
    }, 150);
    window.addEventListener('touchmove', this.touchmoveCB);
    this.touchendCB = () => {
      if (this.isDragging) {
        this.isDragging = false;
        this.repositionPopover();
      }
    };
    window.addEventListener('touchend', this.touchendCB);
    this.resizeCB = debounce(() => {
      if (document.activeElement.type === 'text') {
        // Logic for while keyboard is shown
      } else {
        // Logic for while keyboard is hidden
        this.repositionPopover(true);
      }
    }, 150);
    window.addEventListener('resize', this.resizeCB);
    this.transitionendCB = () => {
      window.currentlyRunningAnimation = false;
    };
    window.addEventListener('transitionend', this.transitionendCB);
  }

  componentWillUnmount() {
    window.removeEventListener('paramsinitialized', this.paramsinitializedCB);
    window.removeEventListener('wheel', this.wheelCB);
    window.removeEventListener('touchstart', this.touchstartCB);
    window.removeEventListener('touchmove', this.touchmoveCB);
    window.removeEventListener('touchend', this.touchendCB);
    window.removeEventListener('resize', this.resizeCB);
    window.removeEventListener('transitionend', this.transitionendCB);
  }

  onButtonClick = (e) => {
    e.stopPropagation();
    this.setState(state => ({ display: !state.display }));
  }

  onClick = (e) => {
    e.stopPropagation();
  }


  render() {
    const { listing } = this.props;
    const { display } = this.state;
    const {
      popover,
      popoverContainer,
      popoverWrapper,
    } = this.references;

    return (
      <div ref={popoverContainer} className="pro-nav">
        <div onClick={this.onButtonClick} className="d-inline-block mr-5">
          <i className="nookons-settings-fill nkyln-2x" />
          Manage
        </div>
        <EnhancedShareButton url={listing.url} />
        <ManagePopover {...this.props} display={display} popover={popover} popoverWrapper={popoverWrapper} />
      </div>
    );
  }
}

export default Nav;
