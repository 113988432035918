import { useState, useMemo } from 'react'

import useSupercluster from 'use-supercluster'

function useGoogleMapCluster(markers) {
  const [clusterBounds, setClusterBounds] = useState(null)
  const [mapZoom, setMapZoom] = useState(null)

  const points = useMemo(() => {
    const markerGroups = markers.reduce((result, current) => {
      const groupingParam = current.address ? current.address : current.subtitle ? current.subtitle : current.title
      if (result[groupingParam]) {
        result[groupingParam].push(current)
      } else {
        result[groupingParam] = [current]
      }

      return result
    }, {})

    return Object.values(markerGroups).map((group) => ({
      type: 'Feature',
      properties: { cluster: false, multilisting: group.length > 1, markers: group },
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(group[0].longitude), parseFloat(group[0].latitude)],
      },
    }))
  }, [markers])

  const onClusterMapChange = (data) => {
    const bounds = data?.bounds

    setMapZoom(data.zoom)
    setClusterBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat])
  }

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: clusterBounds,
    zoom: mapZoom,
    options: { radius: 120, maxZoom: 24 },
  })

  return {
    clusters,
    supercluster,
    onClusterMapChange,
    points,
  }
}

export default useGoogleMapCluster
