import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import ListingStatusChangeModal from '../../modal/ListingStatusChangeModal';
import { setMessage } from '../../../reducers/flashMessage';
import { getMetaContent } from '../../../helpers/util';

class TopBar extends Component {
  state = {
    approved: false,
    rejected: false,
  };

  changeListingStatus = (status, display, notes = '') => {
    const { id, setFlashMessage, changeField, shortAddress } = this.props;
    axios.post(`/matrix/rooms/${id}/change_status.json`, { status, notes, authenticity_token: getMetaContent('csrf-token') }).then(() => {
      setFlashMessage(
        `You have successfully marked the room at ${shortAddress} as ${status}`,
      );
      changeField(display, 'status');
    });
  }

  handleApprove = evt => {
    const { approveOrReject } = this.props;
    this.markAsAvailable(evt);
    this.setState({ approved: true, rejected: false });
    approveOrReject();
  }

  handleReject= evt => {
    const { approveOrReject } = this.props;

    evt.preventDefault();
    const notes = evt.target.rejectedNotes.value;
    this.changeListingStatus("rejected", "Rejected", notes);
    this.setState({ approved: false, rejected: true });
    approveOrReject();
  }

  markAsAvailable = evt => {
    evt.preventDefault();
    this.changeListingStatus("available", "Available");
  }

  markAsAwaitingApproval = evt => {
    evt.preventDefault();
    this.changeListingStatus("awaiting_approval", "Awaiting Approval");
  }

  markAsPending = evt => {
    evt.preventDefault();
    const notes = evt.target.pendingNotes.value;
    this.changeListingStatus("pending", "Pending", notes);
  }

  markAsRented= evt => {
    evt.preventDefault();
    const notes = evt.target.rentedNotes.value;
    this.changeListingStatus("rented", "Rented", notes);
  }

  render() {
    const { id: roomId, shortAddress, listingId, feedbackUrl, status, superAdmin } = this.props;
    const { approved, rejected } = this.state;

    return (
      <div className="row listing-admin-area-title-row text-left">
        <div className="col-sm-12">
          <ul className="list-inline list-unstyled">
            <li className="list-inline-item">
              <a target="_blank" href={`/listing_rooms/${roomId}`}>
                {shortAddress}
              </a>
            </li>
            { status == 'Awaiting Approval' && (!approved && !rejected)
              && superAdmin && (
                <React.Fragment>
                  <li className="list-inline-item">|</li>
                  <li className="list-inline-item">
                    <a onClick={this.handleApprove}>
                      Approve
                    </a>
                  </li>
                  <li className="list-inline-item">|</li>
                  <li className="list-inline-item">
                    <ListingStatusChangeModal handleStatusChange={this.handleReject} status="rejected" style={{color: '#fff'}}>
                      Mark as Rejected
                    </ListingStatusChangeModal>
                  </li>
                </React.Fragment>
                ) }
            <li className="list-inline-item">|</li>
            <li className="dropdown list-inline-item">
              <a
                className="dropdown-toggle"
                href="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                role="button">Manage</a>
              <ul
                className="dropdown-menu nklyn-listing-manage-dropdown nklynbar-nav-dark"
                role="menu">
                <li
                  className="dropdown-item">
                  <a
                    href={`/listing_rooms/${roomId}/edit`}>
                    Edit Room
                  </a>
                </li>
                <li
                  className="dropdown-item">
                  <a href={`/matrix/listings/${listingId}/edit_rooms`}>
                    Manage Rooms
                  </a>
                </li>
              </ul>
            </li>
            <li className="list-inline-item">|</li>
            { status != 'Awaiting Approval' &&
            <React.Fragment>
              <li className="dropdown list-inline-item">
                <a
                  className="dropdown-toggle"
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  role="button"
                >
                  { status }
                  <span />
                </a>
                <ul
                  className="dropdown-menu nklynbar-nav-dark nklyn-listing-manage-dropdown"
                  role="menu"
                >
                  { status === 'Available' && (
                  <React.Fragment>
                    <li className="dropdown-item">
                      <ListingStatusChangeModal handleStatusChange={this.markAsPending} status="pending">
                        Mark as Pending
                      </ListingStatusChangeModal>
                    </li>
                    <li className="dropdown-item">
                      <ListingStatusChangeModal handleStatusChange={this.markAsRented} status="rented">
                        Mark as Rented
                      </ListingStatusChangeModal>
                    </li>
                  </React.Fragment>
                  )}
                  { status === 'Rented' && superAdmin && (
                  <li className="dropdown-item">
                    <a onClick={this.markAsAvailable}>
                      Mark as Available (admin)
                    </a>
                  </li>
                  )}
                  { status === 'Rented' && (
                  <li className="dropdown-item">
                    <a onClick={this.markAsAwaitingApproval}>
                      Mark as Available
                    </a>
                  </li>
                  )}
                  { status === 'Pending' && (
                  <li className="dropdown-item">
                    <ListingStatusChangeModal handleStatusChange={this.markAsRented} status="rented">
                      Mark as Rented
                    </ListingStatusChangeModal>
                  </li>
                  )}
                  { status === 'Pending' && superAdmin && (
                  <li className="dropdown-item">
                    <a onClick={this.markAsAvailable}>
                      Mark as Available (admin)
                    </a>
                  </li>
                  )}
                  { status === 'Pending' && (
                  <li className="dropdown-item">
                    <a onClick={this.markAsAwaitingApproval}>
                      Mark as Available
                    </a>
                  </li>
                  )}
                  { superAdmin && status !== 'Rejected' && (
                  <li className="dropdown-item">
                    <ListingStatusChangeModal handleStatusChange={this.handleReject} status="rejected">
                      Mark as Rejected
                    </ListingStatusChangeModal>
                  </li>
                  )}
                </ul>
              </li>
              <li className="list-inline-item">|</li>
            </React.Fragment>}
            <li className="list-inline-item">
              <a href={`/listings/${listingId}`}>
                Listing
              </a>
            </li>
            <li className="list-inline-item">|</li>
            <li className="list-inline-item">
              <a
                target="_blank"
                href={feedbackUrl}>
                Support
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setFlashMessage(message) {
    dispatch(setMessage(message));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(TopBar);