import React, { PureComponent } from 'react';

export default class BlinkingCursor extends PureComponent {
  render() {
    const { inFocus } = this.props;
    return(
      <div className='blinking-cursor'>{inFocus ? '|' : '' }</div>
    );
  }
}
