import React from 'react';
import { AsyncSubscribe } from 'asyncHandlers/AsyncBase';

const LeadModerationButton = props => {
  const { listing, currentUser } = props;
  const { id: listingId, network: listingNetwork = 'n/a' } = listing || {};
  const { permissions: userPermissions = [] } = currentUser || {};

  const hasPlatformPermission = userPermissions.includes('platform_moderator');
  const validNetwork = listingNetwork === 'platform' || listingNetwork === 'subway';

  return listing && listingId && hasPlatformPermission && validNetwork ? (
    <a
      href={`/listings/${listingId}/lead_moderations`}
      className="button btn-transparent btn-block btn-rounded btn-block btn-search-bar-size"
    >
      Leads
    </a>
  ) : null;
};

const subscriptions = ['currentUser', 'listing'];

export default AsyncSubscribe(subscriptions)(LeadModerationButton);
