import React from 'react';
import PropTypes from 'prop-types';
import AgentAsync from 'asyncHandlers/AgentAsync';
import { AsyncProvider } from 'asyncHandlers/AsyncBase';
import AgentTypeahead from './Agent';

const AgentContainer = (props) => {
  const { agents, matrix_context: matrixContext } = props;
  return (
    <AsyncProvider handler={new AgentAsync()}>
      <AgentTypeahead
        agents={agents}
        agentType="all"
        matrixContext={matrixContext}
        searchContext="matrix"
      />
    </AsyncProvider>
  );
};

AgentContainer.defaultProps = {
  agents: [],
};

AgentContainer.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.string),
  matrix_context: PropTypes.oneOf(['listing', 'lease', 'check_request', 'listing_room', 'commission_check']).isRequired,
};

export default AgentContainer;
