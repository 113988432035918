import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class PpsfButton extends Component {
  componentDidMount() {
    this.node.addEventListener('click', e => {
      e.stopPropagation();
    });
    this.node.addEventListener('mouseup', e => {
      e.stopPropagation();
    });
  }

  render() {
    const { togglePopover, min, max } = this.props;
    let value = 'Ppsf';
    let finalMin = '';
    let finalMax = '';
    if (!(min === '' && max === '')) {
      finalMin += min === '' ? `: ${'0'}` : `: $${min}`;
      finalMax += max === '' ? '+' : ` - $${max}`;
    }
    value += finalMin + finalMax;
    const className = min !== '' || max !== '' ? 'btn-transparent-yellow' : 'btn-transparent-gray';
    return (
      <input
        ref={me => this.node = me}
        name='ppsf'
        onMouseDown={togglePopover}
        type='button'
        className={`button btn-rounded ${className} btn-search-bar-size`}
        value={value}/>
    );
  }
}

const mapStateToProps = state => ({
  min: state.search[state.search.context].minPpsf,
  max: state.search[state.search.context].maxPpsf,
});

const mapDispatchToProps = dispatch => ({
  togglePopover: e => dispatch(togglePopover(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PpsfButton);
