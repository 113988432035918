import React, { Component } from 'react';
import AuthModal from '../components/modal/AuthModal';
import { Heart } from '../components/Heart';
import loggedIn from 'helpers/loggedIn';
import axios from 'axios';

export default class RoommateLikeButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liked: this.props.liked,
      signedIn: false,
    };
  }

  componentDidMount() {
    loggedIn()
      .then((signedIn) => {
        this.setState({ signedIn });
      });
  }

  toggleLike = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { agent_id } = this.props;
    const endpoint = this.state.liked ? 'unlike' : 'like';
    this.setState(({ liked }) => ({ liked: !liked }), () => {
      axios.get(`/mate_posts/${agent_id}/${endpoint}.json`)
        .catch(err => {
          console.dir(err);
          this.setState(({ liked  }) => ({ liked: !liked }));
        });
    });
  }

  render() {
    const { liked, signedIn } = this.state;
    const buttonStatus = liked ? 'like-button' : 'unlike-button';
    return (
      <div className="like-mate-wrapper">
        {signedIn ? (
          <Heart
            handleClick={this.toggleLike}
            buttonStatus={buttonStatus}
          />
        ) : (
          <AuthModal>
            <Heart buttonStatus="unlike-button" />
          </AuthModal>
        )}
      </div>
    );
  }
}
