import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';
import Popover from 'components/generic/Popover';
import SquareFeetPopover from './SquareFeetPopover';

const SquareFeet = props => {
  const {
    selected,
    onChange,
    loading,
  } = props;

  const targetRef = useRef(null);
  const [value, setValue] = useState(selected);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  let buttonTxt = 'Square Feet';
  if (loading) {
    if (value[0] === '' || value[0] === '0') {
      if (value[1] !== '') {
        buttonTxt += ' Under ';
      }
    } else {
      buttonTxt += (value[1] !== '')
        ? ` ${value[0]}sf - `
        : ` ${value[0]}sf`;
    }

    if (value[1] === '') {
      if (value[0] !== '') {
        buttonTxt += '+';
      }
    } else {
      buttonTxt += `${value[1]}sf`;
    }
  }

  const className = ((value[0] !== '' || value[1] !== '') && !loading) ? 'btn-transparent-yellow' : 'btn-transparent-gray';

  return (
    <Column className="searchbar-col">
      <Popover type="click" targetRef={targetRef}>
        <SquareFeetPopover
          loading={loading}
          value={value}
          onChange={onChange}
          targetRef={targetRef}
        />
      </Popover>
      <input
        ref={targetRef}
        name="squareFeet"
        type="button"
        className={`button btn-rounded ${className} btn-search-bar-size`}
        value={buttonTxt}
      />
    </Column>
  );
};

SquareFeet.defaultProps = {
  selected: ['', ''],
  onChange: () => {},
  loading: false,
};

SquareFeet.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default SquareFeet;
