/*
Filter Helper
*/

const generateFilterRegExp = (filter) => {
  let string = filter.split('').map(char => {
    if (char !== '\\') {
      return `[${char}]`;
    } else {
      return '\\\\';
    }
  }).join('');
  return new RegExp(`${string}`, 'i');
};

/* convert sort by string to actual for API */

const convertSortBy = {
  'price: low to high': 'price_asc',
  'price: high to low': 'price_desc',
  'roommate interest': 'roommate_interest',
  'unit number': 'unit_number',
  'date available': 'date_available',
  'recently updated': 'recently_updated',
  'recently created': 'recently_created',
  'relevance': '',
};

export { generateFilterRegExp, convertSortBy }
