import React from 'react';
import ErrorBoundary from 'errorLogging';
import RoomIndexContainer from 'components/listings/pages/rooms/RoomIndexContainer';

// ToDo: Make Listing and Room With Context into one wrapper
const RoomsWithContext = () => (
  <ErrorBoundary>
    <RoomIndexContainer reducer="rooms" />
  </ErrorBoundary>
);

export default RoomsWithContext;
