// INITIAL STATE
const initialState = {
  showMatrix: false,
};

// ACTION TYPES
const TOGGLE_MATRIX = 'TOGGLE_MATRIX';
const LISTING_SET_STORE = 'LISTING_SET_STORE';

// ACTIONS
export const toggleMatrix = () => ({
  type: TOGGLE_MATRIX,
});

export const setStore = payload => ({
  type: LISTING_SET_STORE,
  payload,
});

// REDUCER
export default function (state = initialState, action) {
  const { showMatrix } = state;
  switch (action.type) {
    case LISTING_SET_STORE:
      return { ...state, ...action.payload };
    case TOGGLE_MATRIX:
      return { ...state, showMatrix: !showMatrix };
    default:
      return state;
  }
}
