// Converts string to camelCase
const toCamel = s => s.replace(/([-_][a-z])/ig, $1 => (
  $1.toUpperCase().replace('-', '').replace('_', '')
));

// Converts string to snake_case
const toSnake = s => s.replace(/[A-Z]/g, (letter, index) => (index === 0 ? letter.toLowerCase() : `_${letter.toLowerCase()}`));

// Checks if JS item is an array
const isArray = a => Array.isArray(a);

// Checks if JS item is an object
const isObject = o => (
  o === Object(o) && !isArray(o) && typeof o !== 'function'
);

// Converts keys of an object to camelCase
const keysToCamel = o => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach(k => {
        n[toCamel(k)] = keysToCamel(o[k]);
      });

    return n;
  } if (isArray(o)) {
    return o.map(i => keysToCamel(i));
  }

  return o;
};

// Converts keys of an object to snake_case
export const keysToSnake = o => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach(k => {
        n[toSnake(k)] = keysToSnake(o[k]);
      });

    return n;
  } if (isArray(o)) {
    return o.map(i => keysToSnake(i));
  }

  return o;
};

export default keysToCamel;
