import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class BedButton extends Component {

  componentDidMount() {
    this.node.addEventListener('click', (e) => {
      e.stopPropagation();
    });
    this.node.addEventListener('mouseup', (e) => {
      e.stopPropagation();
    });
  }

  render() {

    const { togglePopover, selectedBeds } = this.props;
    const className = selectedBeds.length > 0 ? 'btn-transparent-yellow' : 'btn-transparent-gray';
    const convertedArray = selectedBeds.map(bed => {
      switch (bed) {
        case 'Studio': return '0';
        case '5': return '5+';
        default: return bed;
      }
    }).sort();

    const value = selectedBeds.length > 0 ? `Beds: ${convertedArray.join(',')}` : 'Beds';
    return (
      <input
        ref={me => this.node = me}
        name='bed'
        onMouseDown={togglePopover}
        type='button'
        className={`button btn-rounded ${className} btn-search-bar-size`}
        value={value}/>
    );
  }
}

const mapStateToProps = state => {
  return ({
    selectedBeds: state.search[state.search.context].selectedBeds,
  });
};

const mapDispatchToProps = (dispatch) => ({
  togglePopover: (e) => dispatch(togglePopover(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BedButton);
