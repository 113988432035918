import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setStore } from 'reducers/search';
import ListingCard from 'components/listings/cards/Listings';
import RoomCard from 'components/listings/cards/Rooms';

class SearchCard extends React.Component {
  componentDidMount() {
    const { selected } = this.props;
    if (selected && this.node) this.node.scrollIntoView();
  }

  onMouseOver = () => {
    const { _setStore, listing } = this.props;
    const selectedItem = listing;
    _setStore({ selectedItem });
  }

  render() {
    const { listing, signedIn, type } = this.props;
    const { onMouseOver } = this;

    switch (type) {
      case 'room':
        return (
          <RoomCard
            wrapperProps={{
              ref: (me) => { this.node = me; },
              onMouseOver,
              onFocus: onMouseOver,
            }}
            room={listing}
            signedIn={signedIn}
          />
        );

      default:
        return (
          <ListingCard
            wrapperProps={{
              ref: (me) => { this.node = me; },
              onMouseOver,
              onFocus: onMouseOver,
            }}
            listing={listing}
            signedIn={signedIn}
            useRouter
          />
        );
    }
  }
}

SearchCard.defaultProps = {
  selected: false,
  signedIn: false,
};

SearchCard.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.shape({
      wide: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool,
  _setStore: PropTypes.func.isRequired,
  signedIn: PropTypes.bool,
  type: PropTypes.oneOf([
    'listing', 'room',
  ]).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { selectedItem } = state.search[state.search.context];
  const selected = selectedItem && selectedItem.id === ownProps.listing.id;
  return {
    selected,
  };
};

const mapDispatchToProps = dispatch => ({
  _setStore: payload => dispatch(setStore(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCard);
