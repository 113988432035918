import axios from 'axios';
import { keysToCamel } from 'pro/util/inflector';
import { getMetaContent } from '../helpers/util';

// INITIAL STATE
const initialState = {
  bankAccountLinked: false,
  submitted: false,
  submitting: false,
  errors: '',
};

// ACTION TYPES
const LINK_BANK_ACCOUNT = 'LINK_BANK_ACCOUNT';
const STORE_BANK_INFO = 'STORE_BANK_INFO';
const GET_PAYMENT_INFO = 'GET_PAYMENT_INFO';
const CREATE_NEW_CC_PAYMENT = 'CREATE_NEW_CC_PAYMENT';
const CREATE_NEW_ACH_PAYMENT = 'CREATE_NEW_ACH_PAYMENT';
const CLEAR_PAYMENT = 'CLEAR_PAYMENT';
const SUBMIT_FORM = 'SUBMIT_FORM';
const SUBMITTED = 'SUBMITTED';
const RESET_BANK_ACCOUNT = 'RESET_BANK_ACCOUNT';
const GET_ERROR = 'GET_ERROR';
const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
const UPDATE_CC_SIGNATURE = 'UPDATE_CC_SIGNATURE';
const UPDATE_LINK_TOKEN = 'UPDATE_LINK_TOKEN';
const SET_PAYMENT_INTENT = 'SET_PAYMENT_INTENT';

// ACTION CREATORS
export const linkBankAccount = () => ({ type: LINK_BANK_ACCOUNT });
export const storeBankInfo = payload => ({ type: STORE_BANK_INFO, payload });
export const getPaymentInfo = payload => ({ type: GET_PAYMENT_INFO, payload });
export const createNewCreditPayment = payload => ({
  type: CREATE_NEW_CC_PAYMENT,
  payload,
});
export const createNewAchPayment = payload => ({
  type: CREATE_NEW_ACH_PAYMENT,
  payload,
});
export const clearPayment = () => ({ type: CLEAR_PAYMENT });
export const submitForm = () => ({ type: SUBMIT_FORM });
const submitted = () => ({ type: SUBMITTED });
export const resetBankAccount = () => ({ type: RESET_BANK_ACCOUNT });
export const getError = payload => ({ type: GET_ERROR, payload });
const clearErrorMessages = () => ({ type: CLEAR_ERROR_MESSAGE });
const updateCcSignature = payload => ({ type: UPDATE_CC_SIGNATURE, payload });
export const updateLinkToken = payload => ({ type: UPDATE_LINK_TOKEN, payload });
const setPaymentIntent = payload => ({ type: SET_PAYMENT_INTENT, payload });

// THUNK CREATORS

const describePayment = (payment_descriptor, paymentType) => dispatch => {
  const apiToken = localStorage.getItem('apiToken');
  const source = axios.CancelToken.source();

  axios.post('/api/v2/payments.describe_payment', { payment_descriptor }, {
    headers: { API_TOKEN: apiToken },
    cancelToken: source.token,
  })
    .then(res => res.data)
    .then(res => keysToCamel(res))
    .then(res => {
      const {
        ok,
        paymentDescription = {},
      } = res;

      if (ok) {
        paymentDescription.signature = paymentDescription.id;
        if (paymentType === 'ach') {
          dispatch(createNewAchPayment(paymentDescription));
        } else if (paymentType === 'credit') {
          dispatch(createNewCreditPayment(paymentDescription));
        }
      }
    })
    .catch(err => {
      if (err.response && err.response.status == 400) {
        dispatch(getError({ errors: err.response.data }));
      } else {
        dispatch(
          getError({ errors: 'Something went wrong, please refresh the page.' }),
        );
      }
    });
};

const cyclePaymentDescriptor = (payment_descriptor, paymentType) => dispatch => {
  const apiToken = localStorage.getItem('apiToken');
  const source = axios.CancelToken.source();

  axios.post('/api/v2/payments.cycle_payment', { payment_descriptor }, {
    headers: { API_TOKEN: apiToken },
    cancelToken: source.token,
  })
    .then(res => res.data)
    .then(res => keysToCamel(res))
    .then(res => {
      const {
        ok,
        paymentDescriptor,
      } = res;

      if (ok) {
        dispatch(describePayment(paymentDescriptor, paymentType));
      }
    });
};

export const cancelStripePaymentIntent = (paymentChargeDescriptorSignature, paymentIntentId) => dispatch => {
  const apiToken = localStorage.getItem('apiToken');
  const source = axios.CancelToken.source();

  axios.post('/api/v2/payments.cancel_stripe_payment_intent', {
    payment_intent_id: paymentIntentId,
    signature: paymentChargeDescriptorSignature,
  }, {
    headers: { API_TOKEN: apiToken },
    cancelToken: source.token,
  });
};

export const generateStripePaymentIntent = paymentChargeDescriptorSignature => dispatch => {
  const apiToken = localStorage.getItem('apiToken');
  const source = axios.CancelToken.source();

  axios.post('/api/v2/payments.generate_stripe_payment_intent', {
    signature: paymentChargeDescriptorSignature,
  }, {
    headers: { API_TOKEN: apiToken },
    cancelToken: source.token,
  })
    .then(res => res.data)
    .then(res => keysToCamel(res))
    .then(res => {
      const {
        ok,
        paymentIntent,
      } = res;

      if (ok) {
        dispatch(setPaymentIntent(paymentIntent));
      }
    });
};

export const createPayment = (
  payment_charge,
  payment_charge_descriptor_signature,
) => dispatch => {
  let paymentType;
  if (payment_charge.payment_intent_id) {
    paymentType = 'credit';
  } else if (payment_charge.plaid_token) {
    paymentType = 'ach';
  }

  return axios
    .post('/payments.json', {
      payment_charge,
      payment_charge_descriptor_signature,
      authenticity_token: getMetaContent('csrf-token'),
    })
    .then(res => {
      dispatch(submitted());
      if (res.data.ok) {
        dispatch(storeBankInfo({ ...res.data, errors: '' }));
        window.location = `${res.data.recieptUrl}`;
      } else {
        document
          .getElementsByClassName('ReactModal__Overlay--after-open')[0]
          .scroll(0, 0);
        dispatch(storeBankInfo({ ...res.data }));
        dispatch(updateCcSignature(res.data.paymentChargeDescriptorSignature));
        setTimeout(() => {
          dispatch(clearErrorMessages());
        }, 5000);
      }
    })
    .catch(err => {
      dispatch(cyclePaymentDescriptor(payment_charge_descriptor_signature, paymentType));
      dispatch(submitted());
      if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.messages) {
        dispatch(
          getError({ errors: err.response.data.errors.messages[0] }),
        );
      } else {
        dispatch(
          getError({ errors: 'There is an error with your payment. Please try again or contact hi@nooklyn.com.' }),
        );
      }
    });
};

export const updateAchPaymentChargeDescriptor = (leaseToken, signature) => dispatch => {
  axios
    .post(
      `/leases/${leaseToken}/payment_charge_descriptors/update_link_token`,
      { authenticity_token: getMetaContent('csrf-token'), signature },
    )
    .then(res => axios.post('/api/v2/payments.describe_payment', { payment_descriptor: res.data }, {
      headers: { API_TOKEN: localStorage.getItem('apiToken') },
    }))
    .then(res => res.data)
    .then(res => keysToCamel(res))
    .then(res => {
      const { ok, paymentDescription = {} } = res;
      if (ok) {
        paymentDescription.signature = paymentDescription.id;
        dispatch(createNewAchPayment(paymentDescription));
      }
      dispatch(updateLinkToken(paymentDescription.linkToken));
    })
    .catch(() => dispatch(
      getError({ errors: 'Something went wrong. Please try again or contact hi@nooklyn.com.' }),
    ));
};

export const createPaymentChargeDescriptors = (
  leaseToken,
  amount,
) => dispatch => {
  const source = axios.CancelToken.source();
  const describePaymentDescriptor = signature => axios.post('/api/v2/payments.describe_payment', { payment_descriptor: signature }, {
    headers: { API_TOKEN: localStorage.getItem('apiToken') },
    cancelToken: source.token,
  });
  const createAchPaymentDescriptor = () => axios
    .get(
      `/leases/${leaseToken}/payment_charge_descriptors`,
      { params: { amount, payment_type: 'ach' } },
      { authenticity_token: getMetaContent('csrf-token') },
    )
    .then(res => res.data)
    .then(achPaymentDescriptor => describePaymentDescriptor(achPaymentDescriptor.signature));
  const createCreditPaymentDescriptor = () => axios
    .get(
      `/leases/${leaseToken}/payment_charge_descriptors`,
      { params: { amount, payment_type: 'credit_card' } },
      { authenticity_token: getMetaContent('csrf-token') },
    )
    .then(res => res.data)
    .then(creditPaymentDescriptor => describePaymentDescriptor(creditPaymentDescriptor.signature));

  Promise.all([createAchPaymentDescriptor(), createCreditPaymentDescriptor()])
    .then(results => results.map(item => item.data))
    .then(results => results.map(item => keysToCamel(item)))
    .then(([achResult, creditResult]) => {
      dispatch(submitted());
      const { paymentDescription: achPaymentDescription = {} } = achResult;
      const { paymentDescription: creditPaymentDescription = {} } = creditResult;
      if (achResult.ok && creditResult.ok) {
        achPaymentDescription.signature = achPaymentDescription.id;
        dispatch(createNewAchPayment(achPaymentDescription));
        creditPaymentDescription.signature = creditPaymentDescription.id;
        dispatch(createNewCreditPayment(creditPaymentDescription));
      } else {
        dispatch(getError({ errors: 'Something went wrong. Please try again or contact hi@nooklyn.com.' }));
      }
    })
    .catch(err => {
      dispatch(submitted());
      if (err.response && err.response.status === 400 && err.response.data.error.friendlyMessage) {
        dispatch(getError({ errors: err.response.data.error.friendlyMessage }));
      } else {
        dispatch(
          getError({ errors: 'Something went wrong. Please try again or contact hi@nooklyn.com.' }),
        );
      }
    });
};

export const createPaymentErrors = (
  payment_charge_descriptor_signature,
  error_type,
  plaid_error,
  plaid_metadata,
) => dispatch => axios
  .post('/payment_errors.json', {
    payment_charge_descriptor_signature,
    error_type,
    plaid_error,
    plaid_metadata,
    authenticity_token: getMetaContent('csrf-token'),
  })
  .then(res => {})
  .catch(err => {
    console.dir(err);
  });

// REDUCERS
export default function (state = initialState, action) {
  switch (action.type) {
    case LINK_BANK_ACCOUNT:
      return { ...state, bankAccountLinked: true };
    case RESET_BANK_ACCOUNT:
      return {
        ...state,
        bankAccountLinked: false,
        plaidAccountId: '',
        plaidAccountName: '',
        plaidBankName: '',
        plaidToken: '',
      };
    case STORE_BANK_INFO:
      return { ...state, ...action.payload };
    case GET_PAYMENT_INFO:
      return { ...state, ...action.payload };
    case CREATE_NEW_CC_PAYMENT:
      return { ...state, creditPaymentRequest: action.payload };
    case CREATE_NEW_ACH_PAYMENT:
      return { ...state, achPaymentRequest: action.payload };
    case CLEAR_PAYMENT:
      return {};
    case SUBMIT_FORM:
      return { ...state, submitting: true };
    case SUBMITTED:
      return { ...state, submitting: false };
    case GET_ERROR:
      return { ...state, ...action.payload };
    case CLEAR_ERROR_MESSAGE:
      return { ...state, errors: { ...state.errors, messages: '' } };
    case UPDATE_CC_SIGNATURE:
      return {
        ...state,
        creditPaymentRequest: {
          ...state.creditPaymentRequest,
          signature: action.payload,
        },
      };
    case UPDATE_LINK_TOKEN:
      return {
        ...state,
        linkToken: action.payload,
      };
    case SET_PAYMENT_INTENT:
      return {
        ...state,
        paymentIntent: action.payload,
      };
    default:
      return state;
  }
}
