import React, { useEffect } from 'react';
import ContainerWrapper from '../ContainerWrapper';
import { ButtonStepper } from '../Buttons';
import FrozenMap from '../../AppleMap/FrozenMap';

const title = "That's it for location.";

const AddressSummary = (props) => {
  const { address, handleBack, handleNext } = props;

  useEffect(() => {
    document.title = `Create: Apartments - ${title}`;
  }, []);

  return (
    <ContainerWrapper>
      <div>
        <div className="title">
          <h3>{title}</h3>
          <p>We won&apos;t display this info publicly, until you extend an offer to a renter.</p>
        </div>
        <form
          className="summary-form"
          onKeyDown={(evt) => {
            if (evt.which === 13) evt.preventDefault();
          }}
        >
          <div className="row">
            <FrozenMap
              style={{ width: '100%', height: '360px' }}
              longitude={address.longitude}
              latitude={address.latitude}
            />
          </div>
          <div className="border-box">
            <div className="row">
              <div className="col-md-8">
                <label>Address</label>
                <div>{address.line_one}</div>
              </div>
              <div className="col-md-4">
                <label>Apt/Unit/Suite #</label>
                <div>{address.unit}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label>City</label>
                <div>{address.city}</div>
              </div>
              <div className="col-md-4">
                <label>State</label>
                <div>{address.state}</div>
              </div>
              <div className="col-md-4">
                <label>Zipcode</label>
                <div>{address.zip_code}</div>
              </div>
            </div>
          </div>
          <ButtonStepper handleNext={handleNext} handleBack={handleBack} />
        </form>
      </div>
    </ContainerWrapper>
  );
};

export default AddressSummary;
