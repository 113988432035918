import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import { LoginForm, SignupForm } from '../Auth';
import { clearErrorMessages } from '../../reducers/auth';

class AuthModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      signup: props.signup,
    };
  }

  openModal = e => {
    e.preventDefault();
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    document.activeElement.blur();
    this.setState({ modalIsOpen: false, signup: this.props.signup });
    this.props.clearErrorMessages();
  };

  handleLogin = () => {
    this.setState({ signup: false });
    this.props.clearErrorMessages();
  };

  handleSignup = () => {
    this.setState({ signup: true });
  };

  render() {
    const { redirect } = this.props;
    const { signup, modalIsOpen } = this.state;

    return (
      <div>
        <div onClick={this.openModal}>{this.props.children}</div>
        {modalIsOpen && (
          <Modal
            className="Modal__Bootstrap modal-dialog dark-gray-container auth-modal"
            closeTimeoutMS={150}
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
          >
            <div className="modal-content">
              {!signup ? (
                <LoginForm
                  closeModal={this.closeModal}
                  handleSignup={this.handleSignup}
                  redirect={redirect}
                />
              ) : (
                <SignupForm closeModal={this.closeModal} handleLogin={this.handleLogin} redirect={redirect} />
              )}
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

const mapDispatch = dispatch => ({
  clearErrorMessages() {
    dispatch(clearErrorMessages());
  },
});

AuthModal.defaultProps = {
  redirect: window.location.href,
  signup: true,
};

export default connect(
  null,
  mapDispatch,
)(AuthModal);
