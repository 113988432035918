import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fade from 'wrappers/Fade';
import { setStore, generateNewSearch, updateActiveSearch } from 'reducers/search';
import IndividualPet from './IndividualPet';
import IndividualBath from './IndividualBath';
import { AmenityList } from './amenitiesComponents/index';
import ApplyClear from './ApplyClear';

const MorePop = forwardRef((props, { popover, popoverWrapper }) => {
  const onMouseDown = e => {
    e.stopPropagation();
  };
  const bathOptions = ['Any', '1+', '2+', '3+', '4+'];

  const onAddressChange = e => {
    e.preventDefault();
    props.setStore({ address: e.target.value });
    props.generateNewSearch();
  };

  const onLikedOnlyChange = e => {
    e.stopPropagation();
    props.setStore({
      likedOnly: !props.likedOnly,
    });
    props.generateNewSearch();
  };

  const { display, pets, searchContext, petsFilter, amenitiesFilter, bathsFilter, addressFilter, likedOnlyFilter, address, likedOnly } = props;
  return (
    <div
      className={display ? 'popover more-pop searchbar-popover' : 'popover more-pop hide searchbar-popover'}
      style={{ position: 'fixed', width: 0, border: 'none' }}
      ref={popoverWrapper}
    >
      <Fade
        customStyles={{
          color: '#fff',
          position: 'relative',
          right: '125px',
        }}
        fadeStart="expand-start"
        fadeEnd="expand-end"
        visible={display}
      >
        <div
          role="button"
          tabIndex={0}
          onMouseDown={onMouseDown}
          className="popover fade show"
        >
          <div
            ref={popover}
            className="popover-body more-popover"
          >
            {bathsFilter && (
              <>
                <div className="more-filter-title">Baths</div>
                <div className="d-flex">
                  {bathOptions.map(
                    bathOption => <IndividualBath searchContext={searchContext} val={bathOption} key={`bath-${bathOption}`} />,
                  )}
                </div>
              </>
            )}
            {petsFilter && (
              <>
                <hr />
                <div className="more-filter-title">Pet Policy</div>
                <div className="tab-content">
                  {pets.map(pet => (
                    <IndividualPet
                      searchContext={searchContext}
                      pet={pet}
                      key={pet}
                    />
                  ))}
                </div>
              </>
            )}
            {amenitiesFilter && (
              <>
                <hr />
                <div className="more-filter-title">Amenities</div>
                <AmenityList />
              </>
            )}
            {addressFilter && (
              <>
                <hr />
                <div className="more-filter-title">Address</div>
                <input
                  onChange={onAddressChange}
                  className="btn-text-field btn-rounded btn-black-yellow btn-price-field w-100"
                  placeholder="Address"
                  value={address}
                />
              </>
            )}
            {likedOnlyFilter && (
              <>
                <hr />
                <div className="more-filter-title">Liked Only</div>
                <button
                  name="likedOnly"
                  onClick={onLikedOnlyChange}
                  type="button"
                  className={`button btn-rounded ${likedOnly ? 'btn-yellow-black btn-search-bar-size' : 'btn-transparent-yellow btn-search-bar-size'}`}
                >
                  Liked Only
                </button>
              </>
            )}
            <hr className="mb-0" />
            {searchContext === 'searchbar' && <ApplyClear style={{ top: '20px', border: 'none' }} name="more" />}
          </div>
        </div>
      </Fade>
    </div>
  );
});

const mapStateToProps = (state, ownProps) => {
  const display = state.search[state.search.context][ownProps.displayProp];
  return ({
    display: display || false,
    pets: state.search[state.search.context].pets,
    address: state.search[state.search.context].address,
    likedOnly: state.search[state.search.context].likedOnly,
  });
};

const mapDispatchToProps = dispatch => ({
  setStore: payload => dispatch(setStore(payload)),
  generateNewSearch: () => dispatch(generateNewSearch()),
  updateActiveSearch: () => dispatch(updateActiveSearch()),
});

MorePop.propTypes = {
  display: PropTypes.bool.isRequired,
  searchContext: PropTypes.string.isRequired,
  pets: PropTypes.arrayOf(PropTypes.string).isRequired,
  petsFilter: PropTypes.bool,
  amenitiesFilter: PropTypes.bool,
  bathsFilter: PropTypes.bool,
};

MorePop.defaultProps = {
  petsFilter: false,
  amenitiesFilter: false,
  bathsFilter: false,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MorePop);
