import React from "react";

const PaymentBankReset = props => {
  const { handleReset, bankName, accountName } = props;

  return (
    <div>
      <p className="form-help-block">
        You can only link checking accounts, not savings accounts.
      </p>
      <div className="container-fluid">
        <div id="plaid-selected-account" className="row bank-acct-row mb-4">
          <div className="col-sm-9">
            <span id="plaid-bank-name">{bankName}</span> -{" "}
            <span id="plaid-account-name">{accountName}</span>
          </div>
          <div className="col-sm-3">
            <span className="nklyn-green">Account Linked!</span>
          </div>
        </div>
        <button
          onClick={handleReset}
          id="plaid-reset-account"
          className="button btn-white-black btn-rounded btn-block"
        >
          Change Account
        </button>
      </div>
    </div>
  );
};

export default PaymentBankReset;
