import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'helpers/animations';

export default function WithRepositioning(WrappedComponent) {
  return class extends Component {

    isDragging = false;

    componentDidMount() {
      window.currentlyRunningAnimation = false;
      this.wheelCB = debounce(() => {
        this.repositionPopovers();
      }, 150);
      window.addEventListener('wheel', this.wheelCB);
      this.touchstartCB = () => {
        if (document.activeElement) {
          document.activeElement.blur();
        }
      };
      window.addEventListener('touchstart', this.touchstartCB);
      this.touchmoveCB = debounce(() => {
        if (!this.isDragging) {
          this.isDragging = true;
        }
      }, 150);
      window.addEventListener('touchmove', this.touchmoveCB);
      this.touchendCB = () => {
        if (this.isDragging) {
          this.isDragging = false;
          this.repositionPopovers();
        }
      };
      window.addEventListener('touchend', this.touchendCB);
      this.resizeCB = debounce(() => {
        if (document.activeElement.type === 'text') {
          // Logic for while keyboard is shown
        } else {
          // Logic for while keyboard is hidden
          this.repositionPopovers(true);
        }

      }, 150);
      window.addEventListener('resize', this.resizeCB);
      this.transitionendCB = () => {
        window.currentlyRunningAnimation = false;
      };
      window.addEventListener('transitionend', this.transitionendCB);
    }

    componentWillUnmount() {
      window.removeEventListener('paramsinitialized', this.paramsinitializedCB);
      window.removeEventListener('wheel', this.wheelCB);
      window.removeEventListener('touchstart', this.touchstartCB);
      window.removeEventListener('touchmove', this.touchmoveCB);
      window.removeEventListener('touchend', this.touchendCB);
      window.removeEventListener('resize', this.resizeCB);
      window.removeEventListener('transitionend', this.transitionendCB);
    }

    repositionPopovers = (firstTime = false) => {
      this.props.popoverRefs.forEach(popover => {
        if (popover && (!popover.props.display || firstTime)) {
          popover.repositionPopover();
        }
        if (popover.props.display && !firstTime) {
          // Set a listener to update the currently open popover as soon as it close
          const callback = function(e) {
            if (e.propertyName !== 'background-color') {
              this.repositionPopover();
              this.references.popoverContainer.current.removeEventListener('transitionend', callback);
            }
          }.bind(popover);
          popover.references.popoverWrapper.current.addEventListener('transitionend', callback);
        }
      });
    }

    render() {
      const { repositionPopovers } = this;
      return(
        <WrappedComponent {...this.props} repositionPopovers={repositionPopovers} />
      );
    }
  };
}
