import React, { useEffect, useState } from 'react';
import { ButtonSave, ButtonStepper } from '../Buttons';
import ContainerWrapper from '../ContainerWrapper';

const title = 'How many full bathrooms?';

const Bathrooms = (props) => {
  const [halfBathActive, setHalfBathActive] = useState(false);
  const {
    halfBaths,
    fullBaths,
    setHalfBaths,
    setFullBaths,
    userIsEditing,
    setUserIsEditing,
  } = props;

  useEffect(() => {
    document.title = `Create: Apartments - ${title}`;
  }, []);

  useEffect(() => {
    if (+halfBaths > 0) setHalfBathActive(true);
  }, []);

  return (
    <ContainerWrapper>
      <div>
        <div className="title">
          <h3>{title}</h3>
          <p>I&apos;m like Beyonce in the shower.</p>
        </div>
        <form
          className="narrow"
          onKeyDown={(evt) => {
            if (evt.which === 13) evt.preventDefault();
          }}
        >
          <div className="row">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text general-text-field-dark">
                  <i className="nookons-bath" />
                </span>
              </div>
              <input
                type="number"
                className="form-control general-text-field-dark"
                value={fullBaths}
                onChange={evt => setFullBaths(evt.target.value)}
              />
            </div>
          </div>
          <div>
            <p className="text-center">Are there any half bathrooms?</p>
            <div className="btn-group-toggle row">
              <label
                className={`btn general-text-field-dark col ${halfBathActive ? 'active' : ''}`}
              >
                <input
                  type="radio"
                  value="yes"
                  onClick={() => {
                    setHalfBaths('1');
                    setHalfBathActive(true);
                  }}
                />
                Yes
              </label>
              <label
                className={`btn general-text-field-dark col ${!halfBathActive ? 'active' : ''}`}
              >
                <input
                  type="radio"
                  value="no"
                  onClick={() => {
                    setHalfBaths('0');
                    setHalfBathActive(false);
                  }}
                />
                No
              </label>
            </div>
          </div>
          {halfBathActive && (
            <div>
              <p className="text-center">How many half bathrooms?</p>
              <div className="row">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text general-text-field-dark">
                      <i className="nookons-bath" />
                    </span>
                  </div>
                  <input
                    type="number"
                    className="form-control general-text-field-dark"
                    value={halfBaths}
                    onChange={evt => setHalfBaths(evt.target.value)}
                  />
                </div>
              </div>
            </div>
          )}
          {userIsEditing ? (
            <ButtonSave
              handleJump={props.handleJump}
              setUserIsEditing={setUserIsEditing}
              jumpTo={props.jumpTo || 'ApartmentReview'}
            />
          ) : (
            <ButtonStepper
              handleNext={props.handleNext}
              handleBack={props.handleBack}
              disabled={!fullBaths}
            />
          )}
        </form>
      </div>
    </ContainerWrapper>
  );
};

export default Bathrooms;
