import axios from 'axios';

export default function loggedIn(source = axios.CancelToken.source()) {
  return new Promise((resolve) => {
    axios.get('/auth/is_signed_in', {
      cancelToken: source.token,
    })
      .then(res => res.data)
      .then((data) => {
        if (data.signed_in) {
          if (data.api_token) localStorage.setItem('apiToken', data.api_token);
          if (data.agent) localStorage.setItem('agentId', data.agent.id);
          resolve(true);
        } else {
          localStorage.clear();
          resolve(false);
        }
      });
  });
}
