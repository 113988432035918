import React from 'react';
import PropTypes from 'prop-types';

const HomeGreeting = (props) => {
  const { firstName } = props;
  const hour = new Date().getHours();
  let greeting;
  if (hour < 12) {
    greeting = `Good morning, ${firstName}`;
  } else if (hour < 18) {
    greeting = `Good afternoon, ${firstName}`;
  } else {
    greeting = `Good evening, ${firstName}`;
  }

  return (
    <>
      {greeting}
    </>
  );
};

HomeGreeting.propTypes = {
  firstName: PropTypes.string,
};

HomeGreeting.defaultProps = {
  firstName: '',
};

export default HomeGreeting;
