import React, { useEffect } from 'react';
import { ButtonSave, ButtonStepper } from '../Buttons';
import ContainerWrapper from '../ContainerWrapper';

const title = "What's due upfront?";

const MoveInCosts = (props) => {
  const moveInCostOptions = ['First Month', 'Security'];
  const {
    moveInCosts, setMoveInCosts, userIsEditing, setUserIsEditing,
  } = props;

  const handleChange = (moveInCost) => {
    if (moveInCosts.includes(moveInCost)) {
      setMoveInCosts(moveInCosts.filter(m => m !== moveInCost));
    } else {
      setMoveInCosts([moveInCost, ...moveInCosts]);
    }
  };

  useEffect(() => {
    document.title = `Create: Apartments - ${title}`;
  }, []);

  return (
    <ContainerWrapper>
      <div>
        <div className="title">
          <h3>{title}</h3>
          <p>The troll underneath the bridge...</p>
        </div>
        <form
          onKeyDown={(evt) => {
            if (evt.which === 13) evt.preventDefault();
          }}
        >
          <div className="btn-group-toggle">
            {moveInCostOptions.map(moveInCost => (
              <label
                key={moveInCost}
                className={`btn general-text-field-dark ${
                  moveInCosts.includes(moveInCost) ? 'active' : ''
                }`}
              >
                <input
                  type="checkbox"
                  className="general-text-field-dark"
                  onChange={() => handleChange(moveInCost)}
                />
                {moveInCost}
              </label>
            ))}
          </div>
          {userIsEditing ? (
            <ButtonSave
              handleJump={props.handleJump}
              setUserIsEditing={setUserIsEditing}
              jumpTo={props.jumpTo || 'PricingReview'}
            />
          ) : (
            <ButtonStepper handleNext={props.handleNext} handleBack={props.handleBack} />
          )}
        </form>
      </div>
    </ContainerWrapper>
  );
};

export default MoveInCosts;
