import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import CropperModal from 'components/modal/CropperModal';
import LoadingSpinner from 'components/LoadingSpinner';

export default class PhotoButtons extends Component {
    source = axios.CancelToken.source()

    state = {
      cropRequestPending: false,
      croppingPhoto: null,
      deleted: false,
    }

  DeletePhoto = (imageId) => {
    const { listingId, deleteEndpoint, onDelete } = this.props;
    axios
      .post(
        deleteEndpoint,
        { listing_id: listingId, image_hash: imageId },
        {
          headers: { API_TOKEN: localStorage.getItem('apiToken') },
          cancelToken: this.source.token,
        },
      )
      .then(() => {
        if (onDelete) {
          onDelete(imageId);
        } else {
          this.setState({ deleted: true });
        }
      })
      .catch((err) => {
        const message = err.response.data.error.friendlyMessage;
        this.onError(message);
      });
  };

  startCropping = (croppingPhoto) => {
    this.setState({
      croppingPhoto,
    });
  }

  stopCropping = () => {
    this.setState({
      croppingPhoto: null,
    });
  }

  onCrop = (url) => {
    const {
      croppingPhoto: {
        id,
      },
      cropRequestPending,
    } = this.state;
    const { cropEndpoint, image, onCrop } = this.props;
    if (!cropRequestPending) {
      this.setState({
        cropRequestPending: true,
      });
      axios.post(
        cropEndpoint,
        {
          image_fingerprint: id,
          url,
        },
        {
          headers: { API_TOKEN: localStorage.getItem('apiToken') },
          cancelToken: this.source.token,
        },
      ).then((res) => {
        this.setState({
          croppingPhoto: null,
          cropRequestPending: false,
        });
        onCrop(image.id, res.data.result);
      }).catch((err) => {
        const message = err.response.data.error.friendlyMessage;
        this.setState({
          croppingPhoto: null,
          cropRequestPending: false,
        });
        this.onError(message);
      });
    }
  }

  onError = (e) => {
    const { onError } = this.props;
    if (onError) onError(e);
  }

  render() {
    const {
      DeletePhoto,
      startCropping,
      stopCropping,
      onCrop,
    } = this;
    const { deleted, croppingPhoto, cropRequestPending } = this.state;
    const { image, children } = this.props;
    return (
      deleted
        ? null
        : (
          <>
            <i
              role="button"
              tabIndex={0}
              className="nookons-trash nklyn-2x"
              onClick={() => DeletePhoto(image.id)}
              onKeyDown={(e) => { if (e.key === 'Enter') DeletePhoto(image.id); }}
            />
            {children}
            {croppingPhoto && (
              <CropperModal
                imageURL={croppingPhoto.uncropped}
                imageFileType={croppingPhoto.image_content_type}
                onCrop={onCrop}
                stopCropping={stopCropping}
                buttonContent={
                  cropRequestPending
                    ? <LoadingSpinner />
                    : 'Done Cropping'
                }
              />
            )}
          </>
        )
    );
  }
}
PhotoButtons.defaultProps = {
  onError: null,
  onDelete: null,
};

PhotoButtons.propTypes = {
  listingId: PropTypes.number.isRequired,
  onError: PropTypes.func,
  image: PropTypes.shape({
    id: PropTypes.string,

  }).isRequired,
  children: PropTypes.element.isRequired,
  cropEndpoint: PropTypes.string.isRequired,
  deleteEndpoint: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  onCrop: PropTypes.func.isRequired,
};
