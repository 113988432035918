import React, { useEffect } from 'react';

import axios from 'axios';

const PassbaseVerifyButton = props => {
  const cancelSource = axios.CancelToken.source();
  const apiToken = localStorage.getItem('apiToken');
  const { setPhotoIdStatus } = props;

  const logUserAuditRecord = (type, body) => {
    const cancelToken = cancelSource.token;
    axios.post('/api/v2/user.audit_record.log', { type, body }, { headers: { API_TOKEN: apiToken }, cancelToken });
  };

  useEffect(() => {
    if (process.env.PASSBASE_PUBLISHABLE_KEY) {
      logUserAuditRecord('passbase_button_loaded', { env_key: process.env.PASSBASE_PUBLISHABLE_KEY });
    }
  }, [process.env.PASSBASE_PUBLISHABLE_KEY]);

  const handleFinish = identityAccessKey => {
    const cancelToken = cancelSource.token;
    axios.post('/api/v2/user.state_id.verify', { passbase_id: identityAccessKey }, { headers: { API_TOKEN: apiToken }, cancelToken })
      .then(() => {
        if (setPhotoIdStatus) {
          setPhotoIdStatus('pending');
        } else {
          window.location.reload();
        }
      });
    logUserAuditRecord('passbase_verification_finished', { passbase_id: identityAccessKey });
    window.location.reload();
  };

  const handleError = err => {
    logUserAuditRecord('passbase_verification_error', { err });
    window.location.reload();
  };

  const handleStart = () => {
    logUserAuditRecord('passbase_verification_attempted');
  };

  return (
    <div>
      <VerifyButton
        apiKey={process.env.PASSBASE_PUBLISHABLE_KEY}
        onFinish={handleFinish}
        onError={handleError}
        onStart={handleStart}
      />
    </div>
  );
};

export default PassbaseVerifyButton;
