// ToDo: Fix this ESLint on Redesign of new component
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

const PaginationButton = props => {
  const {
    page,
    currentPage,
    handleClick,
  } = props;

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.currentTarget.click();
    }
  };

  return (
    <span className={`page ${currentPage ? 'current' : ''}`}>
      <a
        role="button"
        onClick={() => handleClick(page)}
        onKeyPress={handleKeyPress}
        tabIndex={0}
      >
        {page}
      </a>
    </span>
  );
};

const ListingPagination = props => {
  const {
    currentPage,
    onFilterChange,
    pageCount,
  } = props;

  const [paginationBtnsToShow, setPaginationBtnsToShow] = useState([]);
  const pageBufferSize = 2;

  const handlePrevious = () => {
    if (currentPage > 1) {
      onFilterChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < pageCount) {
      onFilterChange(currentPage + 1);
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.currentTarget.click();
    }
  };

  const handleCustomPageNumber = page => {
    if (page > 0 && page <= pageCount) {
      onFilterChange(page);
    }
  };

  useEffect(() => {
    let startPage = 1;
    let numberOfBtns = 5;

    if (pageCount <= 5) {
      numberOfBtns = pageCount;
    } else {
      //  Number of Btns:
      // [PageBuffer] CurrentPage [PageBuffer]
      numberOfBtns = pageBufferSize * 2 + 1;

      startPage = Math.max(1, currentPage - pageBufferSize);

      // Handles Start Edge Case
      if (currentPage - 1 <= pageBufferSize) {
        startPage = 1;
      }

      // Handles End Edge Case
      if (pageCount - currentPage <= pageBufferSize) {
        startPage = pageCount - pageBufferSize * 2;
      }
    }

    setPaginationBtnsToShow(Array.from(Array(numberOfBtns), (x, index) => startPage + index));
  }, [currentPage, pageCount]);

  return (
    <div id="listing-paginator" className="text-center">
      <div id="listing-paginator" className="text-center">
        <nav className="pagination">
          <span className={`${currentPage > 1 ? 'prev' : 'prev disabled'}`}>
            <a
              role="button"
              onClick={handlePrevious}
              onKeyPress={handleKeyPress}
              tabIndex={0}
            >
              <i className="nookons nookons-chevron-left" />
              {' '}
              Prev
            </a>
          </span>
          {paginationBtnsToShow.map(x => (
            <PaginationButton
              handleClick={i => { handleCustomPageNumber(i); }}
              currentPage={currentPage === x}
              page={x}
              key={`PaginationButton-${x}`}
            />
          ))}
          <span className={`${currentPage < pageCount ? 'next' : 'next disabled'}`}>
            <a
              role="button"
              onClick={handleNext}
              onKeyPress={handleKeyPress}
              tabIndex={0}
            >
              Next
              {' '}
              <i className="nookons nookons-chevron-right" />
            </a>
          </span>
        </nav>
      </div>
    </div>
  );
};

export default ListingPagination;
