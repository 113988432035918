import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'wrappers/Fade';

const LeaseDetails = props => {
  const { listing } = props;

  const {
    moveInCost,
    pets,
    utilities,
  } = listing;

  return (
    <Fade>
      <hr />
      <h5>Lease Details</h5>
      <div className="lease-details-card">
        <div className="row no-gutters">
          <div className="col-6">
            <div className="lease-details-label">
              Pet Policy
            </div>
            <div className="lease-details-value">
              {pets}
            </div>
          </div>
          <div className="col-6">
            <div className="lease-details-label">
              Utilities Included
            </div>
            <div className="lease-details-value">
              {utilities}
            </div>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-6">
            <div className="lease-details-label pt-3">
              Move In Costs
            </div>
            <div className="lease-details-value">
              {moveInCost && moveInCost.join(', ')}
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

LeaseDetails.propTypes = {
  listing: PropTypes.shape({
    moveInCost: PropTypes.arrayOf(PropTypes.string).isRequired,
    pets: PropTypes.string.isRequired,
    utilities: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]).isRequired,
  }).isRequired,
};

export default LeaseDetails;
