import axios from 'axios';

const like = async (id, type) => {
  let url = `/listings/${id}/like`;

  if (type === 'room') {
    url = `/listing_rooms/${id}/like`;
  }

  try {
    const likePromise = await axios
      .get(url);
    return likePromise;
  } catch (err) {
    return console.dir(err);
  }
};

export const unlike = async (id, type) => {
  let url = `/listings/${id}/unlike`;

  if (type === 'room') {
    url = `/listing_rooms/${id}/unlike`;
  }

  try {
    const unlikePromise = await axios
      .get(url);
    return unlikePromise;
  } catch (err) {
    return console.dir(err);
  }
};

export default like;
