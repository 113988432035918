import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import loggedIn from 'helpers/loggedIn';
import SearchParamDisplay from './SearchParamDisplay';
import ListingPagination from './ListingPagination';
import ListingCollection from './ListingCollection';
import generateSearchResults from './searchResultsHelper';

const SearchView = props => {
  const {
    onFilterChange,
    onListingSelect,
    onSearchUpdate,
    selectedFilters,
    type,
  } = props;

  const [searchResults, setSearchResults] = useState({});
  const [signedIn, setSignedIn] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleSearchResults = () => {
    generateSearchResults(selectedFilters, type).then(apiResponse => {
      setSearchResults(apiResponse.data);
      setLoading(false);
    });
  };

  const debouncedHandleSearchResults = useCallback(
    debounce(handleSearchResults, 1000),
    [selectedFilters, type],
  );

  useEffect(() => {
    loggedIn()
      .then(res => {
        setSignedIn(res);
        handleSearchResults();
      });
  }, []);

  useEffect(() => {
    if (signedIn !== null) {
      setLoading(true);
      debouncedHandleSearchResults();
    }
  }, [selectedFilters, type, signedIn]);

  useEffect(() => {
    if (loading === false) {
      onSearchUpdate(searchResults);
    }
  }, [searchResults, loading]);

  return (
    <div>
      <div>
        <SearchParamDisplay selectedFilters={selectedFilters} context={type} loading={loading} />
        <ListingCollection
          onListingSelect={onListingSelect}
          loading={loading}
          searchResults={searchResults[type] || []}
          signedIn={signedIn}
          totalCount={searchResults.totalCount || 0}
          type={type}
        />
        <div style={{ height: 50 }} />
      </div>
      <ListingPagination
        onFilterChange={x => {
          onFilterChange('page', x);
        }}
        currentPage={selectedFilters.page || 1}
        pageCount={searchResults.pageCount || 5}
      />
    </div>
  );
};

SearchView.defaultProps = {
  onListingSelect: () => {},
  onSearchUpdate: () => {},
};

SearchView.propTypes = {
  type: PropTypes.string.isRequired,
  selectedFilters: PropTypes.shape({
    amenities: PropTypes.arrayOf(PropTypes.string),
    address: PropTypes.string,
    baths: PropTypes.string,
    bedrooms: PropTypes.arrayOf(PropTypes.string),
    likedOnly: PropTypes.bool,
    marketAs: PropTypes.string,
    moveBy: PropTypes.string,
    noFeeOnly: PropTypes.bool,
    page: PropTypes.number,
    pets: PropTypes.string,
    ppsf: PropTypes.arrayOf(PropTypes.string),
    price: PropTypes.arrayOf(PropTypes.string),
    sortBy: PropTypes.string,
    squareFeet: PropTypes.arrayOf(PropTypes.string),
    subletOnly: PropTypes.bool,
    neighborhoods: PropTypes.arrayOf(PropTypes.string),
    regions: PropTypes.arrayOf(PropTypes.string),
    subways: PropTypes.arrayOf(PropTypes.string),
    subwayStops: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onListingSelect: PropTypes.func,
  onSearchUpdate: PropTypes.func,
};

export default SearchView;
