/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import Annotation from 'components/GoogleMap/nearby/Annotation'
import GoogleMap from 'components/GoogleMap/nearby/GoogleMap'
import MarkerPoint from 'components/GoogleMap/nearby/MarkerPoint'
import useFitMarkerBounds from 'hooks/useFitMarkerBounds'
import useGoogleMapCluster from 'hooks/useGoogleMapCluster'
import ClusterPoint from 'components/GoogleMap/common/ClusterPoint'
import MultiMarker from 'components/GoogleMap/common/MultiMarker'

import { appleCurrentImageMarker, appleLocationImageMarker, appleResidentialImageMarker } from 'helpers/mapHelpers'
import AppleMap, { ImageAnnotation } from './AppleMap'

const Marker = ({ children }) => children
function NearbyMap(props) {
  const [markersList, setMarkersList] = useState([])
  const [map, setMap] = useState()

  useEffect(() => {
    const fetchMarkers = (latitude, longitude) => {
      const { data_url: dataUrl } = props

      let url
      if (latitude && longitude) {
        url = `${dataUrl}?latitude=${encodeURIComponent(latitude)}&longitude=${encodeURIComponent(longitude)}`
      } else {
        url = dataUrl
      }

      axios.get(url).then((response) => {
        const markers = response.data
        setMarkersList(markers)
      })
    }

    fetchMarkers(props.latitude, props.longitude)
  }, [])

  const shouldShowSelected = () => {
    const { latitude, longitude } = props

    return typeof latitude !== 'undefined' || typeof longitude !== 'undefined'
  }

  let { latitude: contextLat, longitude: contextLon } = props
  const { alt_text: contextAltText } = props

  const showSelected = shouldShowSelected()

  contextLat = parseFloat(contextLat)
  contextLon = parseFloat(contextLon)

  useFitMarkerBounds(map, markersList)

  const { clusters, supercluster, onClusterMapChange, points } = useGoogleMapCluster(markersList)

  if (process.env.MAP_PROVIDER !== 'apple') {
    return (
      <GoogleMap
        onLoaded={(map) => setMap(map)}
        mapProps={{
          onChange: (data) => onClusterMapChange(data),
        }}
      >
        {clusters &&
          clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates
            const {
              cluster: isCluster,
              point_count: pointCount,
              multilisting: isMultilisting,
              markers: multiList,
            } = cluster.properties

            const marker = markersList?.[0]

            if (isCluster) {
              return (
                <Marker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
                  <ClusterPoint
                    onClick={() => {
                      const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 22)
                      map.setZoom(expansionZoom)
                      map.panTo({ lat: latitude, lng: longitude })
                    }}
                    pointCount={pointCount}
                    size={points.length}
                  />
                </Marker>
              )
            }

            if (isMultilisting) {
              return (
                <MultiMarker lat={latitude} lng={longitude} markersNum={multiList.length}>
                  {multiList.map((multiMarker) => (
                    <Annotation isMulti marker={multiMarker} />
                  ))}
                </MultiMarker>
              )
            }

            return (
              <MarkerPoint key={marker.id} marker={marker} lat={marker.latitude} lng={marker.longitude}>
                <Annotation marker={marker} />
              </MarkerPoint>
            )
          })}

        {showSelected && (
          <MarkerPoint
            marker={{ title: contextAltText, markerUrl: appleCurrentImageMarker }}
            lat={contextLat}
            lng={contextLon}
          >
            <Annotation marker={{ title: contextAltText, markerUrl: appleCurrentImageMarker }} />
          </MarkerPoint>
        )}
      </GoogleMap>
    )
  }

  return (
    <AppleMap showSubwayLines>
      {markersList
        ? markersList.map((marker) => {
            const { id: key, image_url: imageUrl, latitude, longitude, subtitle = '', title, type, url } = marker

            return (
              <ImageAnnotation
                key={key}
                url={
                  type === 'apartment' || type === 'building' ? appleResidentialImageMarker : appleLocationImageMarker
                }
                latitude={latitude}
                longitude={longitude}
                title={title}
                subtitle={subtitle}
              >
                <a href={url}>
                  <div className="nearby-callout-content">
                    <img src={imageUrl} className="nearby-callout-img img-fluid" />
                    <div className="nearby-callout-title">{title}</div>
                    <div className="nearby-callout-subtitle">{subtitle}</div>
                  </div>
                </a>
              </ImageAnnotation>
            )
          })
        : null}

      {showSelected && (
        <ImageAnnotation
          latitude={contextLat}
          longitude={contextLon}
          selected
          title={contextAltText}
          url={appleCurrentImageMarker}
        />
      )}
    </AppleMap>
  )
}

NearbyMap.propTypes = {
  alt_text: PropTypes.string,
  data_url: PropTypes.string.isRequired,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
}

NearbyMap.defaultProps = {
  alt_text: undefined,
  latitude: undefined,
  longitude: undefined,
}

export default NearbyMap
