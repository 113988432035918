import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setStore, generateNewSearch } from 'reducers/search';
import { Column } from 'wrappers/FlexTable';
import { SearchDropdown } from 'components/Dropdown';

class MinPrice extends Component {
  onChange = (value) => {
    this.props.setStore({ minPrice: value });
    this.props.generateNewSearch();
  }

  render() {
    const { minPrice, choices } = this.props;
    return (
      <Column
        style={{ width: '50%', padding: '10px' }}>
        <label className="max-min-label">Minimum</label>
        <SearchDropdown
          onChange={this.onChange}
          options={choices}
          placeholder={minPrice || 'No Min'}
          inputClass="btn-text-field btn-rounded btn-black-yellow btn-price-field text-left"
          inputStyle={{ border: '1px solid', fontSize: '14px', width: '130px' }}
          value={minPrice}
        />
      </Column>
    );
  }
}

const mapStateToProps = state => ({
  minPrice: state.search[state.search.context].minPrice,
});

const mapDispatchToProps = dispatch => ({
  setStore: payload => dispatch(setStore(payload)),
  generateNewSearch: () => dispatch(generateNewSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MinPrice);
