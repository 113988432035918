import React from "react";
import PaymentSummary from "./PaymentSummary";
import { cleanCountryData } from "../../helpers/util";

const PaymentRequestForm = props => {
  const { payment, clientName } = props;

  return (
    <form>
      <div className="payment-detail">
        <h1>PAY WITH CREDIT</h1>
        <hr className="entire-width" />
        <h2>PAYMENT INFO</h2>
        <label>Card Number</label>
        <div className="credit-card-icons">
          <li className="list-inline-item">
            <i className="nookons-pay-visa nklyn-2x" />
          </li>
          <li className="list-inline-item">
            <i className="nookons-pay-discover nklyn-2x" />
          </li>
          <li className="list-inline-item">
            <i className="nookons-pay-amex nklyn-2x" />
          </li>
          <li className="list-inline-item">
            <i className="nookons-pay-mastercard nklyn-2x" />
          </li>
        </div>
        <input
          className="general-text-field form-control"
          value={`xxxx-xxxx-xxxx-${payment.stripe_cc_last4}`}
          readOnly
        />
        <div className="row mt-3">
          <div className="col-sm-6">
            <label>Expiration Date</label>
            <input className="general-text-field form-control" readOnly />
          </div>
          <div className="col-sm-6">
            <label>CVC</label>
            <input className="general-text-field form-control" readOnly />
          </div>
        </div>
        <hr className="entire-width" />
        <h2>BILLING INFO</h2>
        <label>Country</label>
        <input
          className="general-text-field form-control"
          value={cleanCountryData.find(c => c.key === payment.country).text}
          readOnly
        />
        <label>Full Name</label>
        <input
          className="general-text-field form-control"
          value={clientName}
          readOnly
        />
        <div className="row">
          <div className="col-sm-8">
            <label>Billing Address</label>
            <input
              className="general-text-field form-control"
              value={payment.address_one}
              readOnly
            />
          </div>
          <div className="col-sm-4">
            <label>Unit Number (Optional)</label>
            <input
              className="general-text-field form-control"
              value={payment.address_two}
              readOnly
            />
          </div>
        </div>
        <label>Billing City</label>
        <input
          className="general-text-field form-control"
          value={payment.city}
          readOnly
        />
        <div className="row">
          <div className="col-sm-6">
            <label>Billing State / Region</label>
            <input
              className="general-text-field form-control"
              value={payment.state}
              readOnly
            />
          </div>
          <div className="col-sm-6">
            <label>Billing Zipcode</label>
            <input
              className="general-text-field form-control"
              value={payment.zip_code}
              readOnly
            />
          </div>
        </div>
        <label>Phone Number</label>
        <input
          className="general-text-field form-control"
          value={payment.telephone}
          readOnly
        />
        <hr className="entire-width" />
        <p>
          Nooklyn does not store any credit card or bank account information. I
          authorize Nooklyn NYC LLC to charge my credit card for the total
          amount listed.
        </p>
        <PaymentSummary payment={payment} />
        <div className="row justify-content-center">
          <div className="col-sm-3">
            <input
              type="submit"
              value="Pay"
              className="button btn-black-yellow btn-rounded btn-pay"
              disabled
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default PaymentRequestForm;
