import React from 'react'
import axios from 'axios'
import { getMetaContent } from '../../helpers/util'

function gtagReportConversion(url) {
  const callback = () => {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }
  // eslint-disable-next-line no-undef
  gtag('event', 'contractsigned', {
    send_to: 'AW-968054781/HW9wCMGMo7wYEP2vzc0D',
    event_callback: callback,
  })
  return false
}

export default class AgreementContent extends React.Component {
  source = axios.CancelToken.source()

  state = { name: '', error: '', submitError: '' }

  componentWillUnmount() {
    this.source.cancel()
  }

  handleChange = (evt) => {
    const fullName = evt.target.value
    const fullNameLength = fullName.split(' ').length
    if (fullNameLength < 2) {
      this.setState({ error: 'Please enter your full legal name', name: '' })
    } else {
      this.setState({ error: '', name: fullName })
    }
  }

  handleSubmit = (evt) => {
    evt.preventDefault()
    const { submitForm, lease_client_id, fetchLeaseClient, setMessage, submitted } = this.props
    const full_legal_name = evt.target['agreement[name]'].value
    const deposit_agreement_agreed_at = new Date()
    submitForm()

    axios
      .patch(`/lease_members/${lease_client_id}/agree_to_deposit_agreement.json`, {
        lease_member: { full_legal_name, deposit_agreement_agreed_at },
        authenticity_token: getMetaContent('csrf-token'),
        cancelToken: this.source.token,
      })
      .then((res) => {
        submitted()
        if (fetchLeaseClient) fetchLeaseClient(lease_client_id)
        if (setMessage) setMessage('You have successfully signed the Nooklyn deposit agreement.')

        gtagReportConversion()
      })
      .catch((err) => {
        submitted()
        this.setState({
          submitError: 'Please refresh the page, your session has expired.',
        })
        console.dir(err)
      })

    evt.target['agreement[name]'].value = ''
  }

  render() {
    const { submitting, client } = this.props
    const { error, name, submitError } = this.state

    return (
      <div className="container agreement-container">
        <h1>DEPOSIT AGREEMENT</h1>
        <hr className="entire-width" />
        <p className="mb-5">
          Nooklyn NYC LLC (Nooklyn) is a duly licensed real estate brokerage in the State of New York. Nooklyn is a dual
          agency that represents both the tenant and landlord. Your privacy and trust are incredibly important to us. We
          make every effort to ensure your documents are stored with the utmost care. Your digital signature represents
          a commitment to lease this property pending approval from the property owner. If you are not approved for this
          apartment, we will refund you everything after subtracting application and convenience fees. This property is
          represented in an “as is” condition. Any claim you may have arising from Nooklyn’s services shall be limited
          to the amount of the brokerage commission. The balance of all the move-in costs are due at lease signing.
          <strong>
            {' '}
            By digitally signing this agreement, you are representing that you have read, understand, and agree to the
            terms set forth in this agreement.
          </strong>
        </p>
        {submitError && <p className="error text-center">{submitError}</p>}
        <form onSubmit={this.handleSubmit} className="deposit_agreement_form" id="deposit_agreement_form">
          <input name="utf8" type="hidden" value="✓" />
          <input type="hidden" name="authenticity_token" value="" />
          <div className="row justify-content-center mt-2">
            <div className="col-sm-5">
              {!client ? (
                <input
                  onChange={this.handleChange}
                  placeholder="Full Legal Name"
                  className="general-text-field"
                  type="text"
                  name="agreement[name]"
                  id="agreement_name"
                  style={error ? { borderColor: 'red' } : {}}
                />
              ) : (
                <input value={client.full_legal_name} readOnly className="general-text-field form-control" />
              )}
              {error && <p className="error">{String(error)}</p>}
            </div>
            <div className="col-sm-2">
              <input
                type="submit"
                name="commit"
                value="Agree"
                className="button btn-black-white btn-rounded"
                disabled={submitting || !name || !!client}
              />
            </div>
          </div>
        </form>
        {client && (
          <div className="text-muted">
            <hr className="entire-width" />
            <div>
              Deposit Agreement signed on {moment(client.deposit_agreement_agreed_at).format('MMMM Do YYYY, h:mm:ss a')}
            </div>
            <div>IP address: {client.ip_address}</div>
            <div>User Agent: {client.user_agent}</div>
          </div>
        )}
      </div>
    )
  }
}
