import React, { Component } from 'react';
import axios from 'axios';
import ListingCard from 'components/listings/cards/Listings';
import Modal from './Modal';

class ModalContent extends Component {
  source = axios.CancelToken.source();

  state = { error: '', listing: {}, listingId: '', ok: false };

  componentWillUnmount() {
    this.source.cancel('api call cancelled as component will unmount');
  }

  handleListingIdChange = e => {
    e.preventDefault();
    this.setState({ listingId: e.target.value });
  };

  handleListingLoad = e => {
    e.preventDefault();
    const { listingId } = this.state;
    axios
      .get(`/api/v2/listings.fetch?listing_id=${listingId}`)
      .then(res => {
        this.setState({ listing: res.data.listing, error: '' });
      })
      .catch(err => {
        if (err.response && err.response.data) {
          this.setState({ error: err.response.data.error.friendlyMessage });
        }
      });
  };

  handleListingChange = e => {
    e.preventDefault();
    const lease_id = this.props.id;
    const listing_id = this.state.listing.id;

    axios
      .post(
        '/api/v1/leases.change_listing',
        { lease_id, listing_id },
        { headers: { API_TOKEN: localStorage.getItem('apiToken') } },
        { cancelToken: this.source.token }
      )
      .then(res => {
        if (res.data.ok) {
          // reload page
          this.setState({ ok: true });
          window.location.reload();
        }
      })
      .catch(err => {
        if (err.response && err.response.data) {
          this.setState({
            error: err.response.data.error.friendlyMessage,
            ok: false,
          });
        }
      });
  };

  render() {
    const { error, listing, listingId } = this.state;
    const { signedIn } = this.props;

    return (
      <div className="container change-listing-container">
        <h1>Lease Address</h1>
        <hr className="entire-width" />
        {error && <p className="error text-center">{error}</p>}
        {listing.id ? (
          <div className="mx-auto text-center">
            <h5 className="mb-4">{listing.address}</h5>
            <ListingCard listing={listing} signedIn={signedIn}/>
            <form onSubmit={this.handleListingChange}>
              <input
                type="submit"
                value="Confirm Listing Change"
                className="button btn-rounded btn-yellow-black btn-2x mt-3"
              />
            </form>
          </div>
        ) : (
          <form onSubmit={this.handleListingLoad}>
            <label>Listing ID</label>
            <input
              className="general-text-field mb-3"
              type="number"
              value={listingId}
              onChange={this.handleListingIdChange}
            />
            <input
              type="submit"
              value="Submit"
              className="button btn-black-white btn-rounded mt-3"
            />
          </form>
        )}
      </div>
    );
  }
}

class LeaseChangeListingModal extends Component {
  state = {
    modalIsOpen: false,
  };

  openModal = evt => {
    evt.preventDefault();
    this.setState({ modalIsOpen: true });
  };

  closeModal = evt => {
    evt.preventDefault();
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { modalIsOpen } = this.state;

    return (
      <div>
        <a onClick={this.openModal}>Change Listing</a>
        {modalIsOpen && (
          <Modal
            className="Modal__Bootstrap modal-dialog dark-gray-container invite-modal"
            closeTimeoutMS={150}
            isOpen={modalIsOpen}
            onRequestClose={this.closeModal}
          >
            <div className="modal-content modal-content-dark">
              <button className="close" onClick={this.closeModal}>
                <i className="nookons-close-circle-fill" />
              </button>
              <ModalContent {...this.props} />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default LeaseChangeListingModal;
