import React from 'react';
import RoomCard from 'components/listings/cards/Rooms';
import PropTypes from 'prop-types';

const Rooms = props => {
  const {
    rooms,
    listing,
    signedIn,
  } = props;

  if (!rooms) {
    return null;
  }

  if (rooms.length < 1) {
    return null;
  }

  return (
    <>
      <hr />
      <h5>Rooms</h5>
      <div className="special-rooms-container">
        {rooms.map(room => (
          <RoomCard key={room.id} signedIn={signedIn} room={room} listing={listing} />
        ))}
      </div>
    </>
  );
};

Rooms.propTypes = {
  rooms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })).isRequired,
  listing: PropTypes.shape({}).isRequired,
  signedIn: PropTypes.bool.isRequired,
};

export default Rooms;
