import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

export default class RenderOutsideContainer extends Component {
  popoverContainer = document.body

  el = document.createElement('div')

  componentDidMount() {
    const { className } = this.props;
    this.el.className = className;
    this.popoverContainer.appendChild(this.el);
  }

  componentWillUnmount() {
    this.popoverContainer.removeChild(this.el);
  }

  render() {
    const { children } = this.props;

    return (
      ReactDOM.createPortal(
        children,
        this.el,
      )
    );
  }
}

RenderOutsideContainer.defaultProps = {
  children: null,
  className: 'client-search-popover',
};

RenderOutsideContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};
