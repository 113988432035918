import React from 'react';
import PropTypes from 'prop-types';
import { present } from 'helpers/util';
import { numberToCurrency } from 'helpers/railsMethods';
import MatrixField from './MatrixField';

const MatrixListingThirdCol = props => {
  const {
    listing,
    edit,
    changeField,
    superAdmin,
  } = props;

  const {
    network,
  } = listing;

  const platform = (network === 'platform');
  const subway = (network === 'subway');

  const formattedAmenities = listing.amenities ? (Array.isArray(listing.amenities) ? listing.amenities : listing.amenities.split('\n')).join(', ') : '';

  const humanize = string => {
    let humanString = string.trim();
    humanString = humanString.replace(humanString[0], humanString[0].toUpperCase());
    if (humanString.endsWith('_id')) {
      const index = humanString.lastIndexOf('_id');
      humanString = humanString.substring(0, index);
    }

    humanString = humanString.replace(/_/g, ' ');
    return humanString;
  };

  const concessionText = concession => `${accounting.formatMoney(concession.netEffectiveRent, { precision: 0 })}, ${concession.leaseTerm}-month lease, ${concession.unitFree} ${concession.unitType}${concession.unitFree > 1 ? 's' : ''} free`;

  return (
    <div className="col-md-4 listing-second-data-col">
      <div className="matrix-listing-card-section">
        <div className="matrix-listing-card-header border-0">
          { subway ? 'Details' : 'Internal' }
        </div>
        {
          !platform && !subway
          && (
            <>
              <div className="matrix-listing-card-row row">
                <div className="col-6 label">Owner pays</div>
                <MatrixField
                  edit={edit || null}
                  changeField={changeField}
                  myValue={present(listing.ownerPays) ? listing.ownerPays : ''}
                  editAttr="owner_pays"
                  listingId={listing.id}
                  myClass="col-6 value"
                >
                  {present(listing.ownerPays) && `${listing.ownerPays}%`}
                </MatrixField>
              </div>
              <div className="matrix-listing-card-row row">
                <div className="col-6 label">Min. deposit</div>
                <div className="col-6 value">{present(listing.minDeposit) && `${numberToCurrency(listing.minDeposit / 100.0)}`}</div>
              </div>
              <div className="matrix-listing-card-row row">
                <div className="col-6 label">Landlord</div>
                <MatrixField
                  edit={edit || null}
                  changeField={changeField}
                  myValue={
                    listing.landlordContact ? listing.landlordContact : ''
                  }
                  editAttr="landlord_contact"
                  listingId={listing.id || ''}
                  myClass="col-6 value"
                >
                  {listing.landlordContact && listing.landlordContact}
                  {superAdmin
                    && listing
                    && !listing.landlordContact && (
                      <span className="label label-nklyn-red">
                        No landlord contact
                      </span>
                  )}
                </MatrixField>
              </div>
              <div className="matrix-listing-card-row row">
                <div className="col-6 label">Bonus</div>
                <MatrixField
                  edit={edit || null}
                  changeField={changeField}
                  myValue={listing.bonusAmount ? listing.bonusAmount : ''}
                  editAttr="bonus_amount"
                  listingId={listing.id}
                  myClass="col-6 value"
                >
                  {network ? (listing.bonusAmount || 'No Agent Bonus') : ''}
                </MatrixField>
              </div>
              <div className="matrix-listing-card-row row">
                <div className="col-6 label">Vacant</div>
                <div className="col-6 value">{listing.vacant && <span className="label label-outline pr-1">Vacant</span>}</div>
              </div>
              <div className="matrix-listing-card-row row">
                <MatrixField
                  edit={edit || null}
                  changeField={changeField}
                  myValue={listing.access ? listing.access : ''}
                  editAttr="access"
                  listingId={listing.id || ''}
                  myClass="col-12 value"
                >
                  <b>Access</b>
                  {' '}
                  <br />
                  <br />
                  {listing.access ? listing.access : ''}
                </MatrixField>
              </div>
            </>
          )
}
        { !subway && (
          <div className="matrix-listing-card-row row">
            <div className="col-6 label">Digital lease sig.</div>
            <div className="col-6 value">{listing.acceptsDigitalSignatures ? 'Yes' : 'No'}</div>
          </div>
        )}
        {
          !platform && !subway
          && (
            <div className="matrix-listing-card-row row">
              <MatrixField
                edit={edit || null}
                changeField={changeField}
                myValue={listing.agentNotes ? listing.agentNotes : ''}
                editAttr="agent_notes"
                listingId={listing.id}
                myClass="col-12 value"
              >
                <b>Notes</b>
                <br />
                <br />
                {listing.agentNotes && listing.agentNotes.trim().length > 0
                  ? humanize(listing.agentNotes)
                  : ''}
              </MatrixField>
            </div>
          )
}
        { subway
          && (
            <>
              <div className="matrix-listing-card-row row">
                <div className="col-12 label">
                  Description
                  {' '}
                  <br />
                  <br />
                  {listing.description}

                </div>
              </div>
              <div className="matrix-listing-card-row row">
                <div className="col-12 label">
                  Amenities
                  <br />
                  <br />
                  {formattedAmenities}
                </div>
              </div>
            </>
          )}
      </div>
      {
          !!listing.concessions && !!listing.concessions.length
          && (
            <div className="matrix-listing-card-section">
              <div className="matrix-listing-card-header">
                Concessions
              </div>
              <div className="matrix-listing-card-row row">
                { listing.concessions.map(concession => (
                  <div style={{ paddingLeft: '15px' }} key={concession.id}>
                    {concessionText(concession)}
                  </div>
                ))}
              </div>
            </div>
          )
        }
    </div>
  );
};

MatrixListingThirdCol.propTypes = {
  listing: PropTypes.shape({
    featured: PropTypes.bool,
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    listingAgent: PropTypes.shape({
      fullName: PropTypes.string,
    }),
    network: PropTypes.string,
    description: PropTypes.string,
    ownerPays: PropTypes.number,
    acceptsDigitalSignatures: PropTypes.bool,
    agentNotes: PropTypes.string,
    bonusAmount: PropTypes.string,
    pets: PropTypes.string,
    term: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    feedbackCount: PropTypes.number,
    status: PropTypes.string,
    youtubeUrl: PropTypes.string,
    concessions: PropTypes.arrayOf(PropTypes.shape({})),
    minDeposit: PropTypes.number,
    landlordContact: PropTypes.string,
    vacant: PropTypes.bool,
    access: PropTypes.string,
    amenities: PropTypes.arrayOf(PropTypes.string),
  }),
  changeField: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  superAdmin: PropTypes.bool,
};

MatrixListingThirdCol.defaultProps = {
  listing: null,
  edit: false,
};

export default MatrixListingThirdCol;
