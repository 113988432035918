/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect'

export const mapSearchItemsSelector = createSelector(
  state => state.search[state.search.context],
  markers => (markers && markers.items) || []
)

export const mapSelectedAndMarkerSelector = createSelector(
  state => state.search[state.search.context],
  ({ selectedItem, selectedMarker }) => ({
    selectedItem,
    selectedMarker,
  })
)
