import React, { Component } from 'react';
import { connect } from 'react-redux';
import WithRepositioning from 'wrappers/WithRepositioning';

class UpdateHandler extends Component {

  componentDidUpdate(prevProps) {
    const { repositionPopovers, selectedAgents, salesSelectedAgents, listingSelectedAgents, selectedPropertyOwners, allSelectedAgents } = this.props;
    if (prevProps.selectedAgents !== selectedAgents) {
      repositionPopovers();
    } else if (prevProps.salesSelectedAgents !== salesSelectedAgents) {
      repositionPopovers();
    } else if (prevProps.listingSelectedAgents !== listingSelectedAgents) {
      repositionPopovers();
    } else if (prevProps.selectedPropertyOwners !== selectedPropertyOwners) {
      repositionPopovers();
    } else if (prevProps.allSelectedAgents !== allSelectedAgents) {
      repositionPopovers();
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { agentType } = ownProps;
  const myState = state.agentTypeahead[agentType];
  return {
    popoverRefs: myState.popoverRefs,
    selectedAgents: myState.selectedAgents,
    salesSelectedAgents: state.agentTypeahead.salesAgent.selectedAgents,
    listingSelectedAgents: state.agentTypeahead.listingAgent.selectedAgents,
    selectedPropertyOwners: state.agentTypeahead.propertyOwner.selectedAgents,
    allSelectedAgents: state.agentTypeahead.all.selectedAgents,
  };
};

const HOC = WithRepositioning(UpdateHandler);
export default connect(mapStateToProps)(HOC);
