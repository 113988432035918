import React, { Component } from 'react';
import { Column } from 'wrappers/FlexTable';
import { connect } from 'react-redux';
import { setStore, generateNewSearch, updateActiveSearch } from 'reducers/search';

class IndividualBath extends Component {

  onMouseDown = (e) => {
    e.preventDefault();
    this.toggleSelect();
    this.props.generateNewSearch();
  }

  toggleSelect = () => {
    if (!this.props.selected) {
      this.props.setStore({ selectedBath: this.props.val });
    }
  }

  render() {
    const { val, selected } = this.props;
    return (
      <Column className={ selected ? 'paint-layer bed-div bed-div-selected' : ' paint-layer bed-div' } onMouseDown={this.onMouseDown} style={{minWidth: '60px'}}>
        {val}
      </Column>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selected = ownProps.val === state.search[state.search.context].selectedBath;
  return {
    selected: selected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setStore: (payload) => dispatch(setStore(payload)),
  generateNewSearch: () => dispatch(generateNewSearch()),
  updateActiveSearch: () => dispatch(updateActiveSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualBath);
