import React from 'react';
import { useGlobalState } from 'components/listRoom/customEffects';
import { RoomCard } from '../Rooms/index';

const Rooms = (props) => {
  const { listing } = props;
  const [globalState] = useGlobalState();
  const { rooms } = globalState;

  return (
    <form className="summary-form">
      <div className="row submit-info-row">
        <div className="col">
          <h6 className="d-inline-block ml-1">
            Rooms
            {rooms.length ? ` ( ${rooms.length}/${listing.beds} Available )` : ''}
          </h6>
          <span data-toggle="collapse" data-target="#rooms" className="float-right pt-2">
            <i className="nklyn-2x nookons-caret-down-fill" />
          </span>
        </div>
      </div>
      <div className="border-box submit-collapse-box collapse" id="rooms">
        {rooms.map(room => (
          <div className="row" key={room.id}>
            <RoomCard room={room} listing={listing} enableCropping={false} />
          </div>
        ))}
      </div>
    </form>
  );
};

Rooms.defaultProps = {
  listing: {},
};

export default Rooms;
