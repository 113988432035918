import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class LikedButton extends Component {
  render() {
    const { val, togglePopover } = this.props;
    return (
      <a
        name="sentenceLiked"
        onMouseDown={togglePopover}
        tabIndex="0"
        className="button btn-transparent"
        role="button"
        title=""
      >
        {val}
      </a>
    );
  }
}

const mapStateToProps = (state) => {
  const { likedOnly } = state.search[state.search.context];
  const val = likedOnly ? 'Liked' : 'Any';
  return ({
    val,
  });
};

const mapDispatchToProps = dispatch => ({
  togglePopover: e => dispatch(togglePopover(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LikedButton);
