import React, { useState, useEffect } from 'react';
import strftime from 'strftime';
import axios from 'axios';
import { Link, useParams } from '@reach/router';
import { useListing, useRoom } from 'pro/ProStore';
import ImageCarousel from './ImageCarousel';
import Nav from './Nav';

const RoomDetail = (props) => {
  const { params, listings } = props;

  const { roomId } = useParams();

  const { dataLoaded: roomDataLoaded, room } = useRoom(roomId);

  const { dataLoaded: listingDataLoaded, listing } = useListing(room.listingId);

  const source = axios.CancelToken.source();
  // const [images, setImages] = useState([room.image]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (typeof listing === 'undefined') {
      return;
    }

    if (listing.images && listing.images.length) {
      setImages([room.image, ...images, ...listing.images]);
    }
  }, [listing]);

  const filteredImages = images.filter(
    (image, index) => (images.map((i) => i.id).indexOf(image.id) === index),
  );

  return roomDataLoaded && listingDataLoaded ? (
    <>
      <div className="pro-header" style={{ cursor: 'pointer' }}>
        <Link to={`/pro/listings/${room.listingId}`}>
          <span className="pro-header-back">
            <i className="nookons-chevron-left nklyn-3x align-middle" />
            <span className="align-middle">Back</span>
          </span>
        </Link>
        {room.name}
        <Nav room={room} listing={listing} />
      </div>
      <div className="pro-container">
        <div className="row no-gutters" style={{ minHeight: 'calc(100vh - 200px)' }}>
          <div className="col-md-6 sidebar sidebar-room-card">
            <ImageCarousel images={filteredImages} />
            <div className="row room-row">
              <div className="col-3 room-col pl-0">
                <div className="label">Price</div>
                <div className="value">{window.accounting.formatMoney(room.price / 100, { precision: 0 })}</div>
              </div>
              <div className="col-5 room-col">
                <div className="label">Move-in Date</div>
                <div className="value">{strftime('%B %-d, %Y', new Date(listing.dateAvailable))}</div>
              </div>
              <div className="col-4 room-col" style={{borderBottom: '1px solid #404040'}}>
                <div className="label">Neighborhood</div>
                <div className="value">{listing.neighborhood.name}</div>
              </div>
            </div>
            <div className="row room-row">
              <div className="col room-col pl-0">
                <div className="label">Description</div>
                <div className="value"><p className="nklyn-pro-room-desc">{room.description}</p></div>
              </div>
            </div>
          </div>
          <div className="col-md-6 details-container">
            <div className="apartment-container">
              <div className="apartment-container-header" style={{ border: 'none' }}>
    Status
                <span className="status-tag">
                  {(() => {
                    switch (room.status) {
                      case 'Available':
                        return (
                          <span>
                            <span className="status-dot status-available" />
    Available
                          </span>
                        );
                      case 'Rentd':
                        return (
                          <span>
                            <span className="status-dot status-rented" />
    Rented
                          </span>
                        );
                      case 'Draft':
                        return (
                          <span>
                            <span className="status-dot status-draft" />
    Draft
                          </span>
                        );
                      case 'Awaiting Approval':
                        return (
                          <span>
                            <span className="status-dot status-review" />
    Under Review
                          </span>
                        );
                      default:
                        return (<span>
                        <span className="status-dot status-draft" />
    { room.status}
                      </span>);
                    }
                  })()}
                </span>
              </div>
            </div>
            <div className="apartment-container">
              <div className="apartment-container-header">Activity</div>
              <div className="row no-gutters border-box">
                <div className="col-6 activity-col">
                  <div className="label">Saved</div>
                  <div className="value">{room.meta.saved}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <h1>Loading</h1>
  );
};

export default RoomDetail;
