import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class MarketAsButton extends Component {
  componentDidMount() {
    this.node.addEventListener('click', e => {
      e.stopPropagation();
    });
    this.node.addEventListener('mouseup', e => {
      e.stopPropagation();
    });
  }

  render() {
    const { togglePopover, className } = this.props;

    return (
      <input
        ref={me => this.node = me}
        name='marketAs'
        onMouseDown={togglePopover}
        type='button'
        className={`button btn-rounded ${className} btn-search-bar-size`}
        value='Market As'/>
    );
  }
}

const mapStateToProps = state => {
  const selected = state.search[state.search.context].selectedMarketAs.length > 0;
  const className = selected ? 'btn-transparent-yellow' : 'btn-transparent-gray';
  return ({ className });
};

const mapDispatchToProps = dispatch => ({
  togglePopover: e => dispatch(togglePopover(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketAsButton);
