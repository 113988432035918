import React, { useEffect, useState } from 'react';
import { AsyncSubscribe } from 'asyncHandlers/AsyncBase';
import { getParamsFromLink } from 'helpers/util';

const ScheduleTour = props => {
  const { listing, signedIn } = props;
  const {
    id,
    sales_agent,
    status,
  } = listing || {};

  const [listingUrl, setListingUrl] = useState('');

  useEffect(() => {
    if (id !== undefined) {
      const params = getParamsFromLink();
      setListingUrl(`/listings/${id}/tour${params['sales-agent'] ? `?sales-agent=${params['sales-agent']}` : ''}`);
    }
  }, [sales_agent, id]);

  const handleClick = () => {
    window.location.href = listingUrl;
  };

  const handleKeyDown = () => evt => {
    if (evt.key === 'Enter') {
      handleClick();
    }
  };

  return (
    (status !== 'Rented'
      && (
        <div className="lead_schedule">
          <div
            onClick={handleClick}
            className="button btn-yellow-black btn-rounded btn-search-bar-size text-left d-flex "
            style={{ justifyContent: 'space-between' }}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex="0"
          >
            <span className="d-flex">Request a tour</span>
            <i className="nookons-arrow-right d-flex ml-2" id="nklyn-listing-tour-img" style={{ lineHeight: '1.7', fontWeight: '500' }} />
          </div>
        </div>
      ))
    || null
  );
};

const subscriptions = [
  'salesAgent', 'signedIn', 'listing',
];

export default AsyncSubscribe(subscriptions)(ScheduleTour);