import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fade from 'wrappers/Fade';
import { setStore, generateNewSearch } from 'reducers/search';
import { Column } from 'wrappers/FlexTable';
import CustomInput from 'components/CustomInput';
import ApplyClear from './ApplyClear';

const AddressPop = forwardRef((props, { popover, popoverWrapper }) => {
  const onMouseDown = e => {
    e.stopPropagation();
  };

  const onChange = value => {
    props.setStore({ address: value });
    props.generateNewSearch();
  };

  const { display, searchContext, address } = props;
  const value = address || '';

  return (
    <div
      className={display ? 'popover address-pop' : 'popover address-pop hide'}
      style={{ position: 'fixed', border: 'none' }}
      ref={popoverWrapper}
    >
      <Fade
        fadeStart="expand-start"
        fadeEnd="expand-end"
        visible={display}
        customStyles={{
          position: 'relative',
          top: '2px',
          right: '180px',
          backgroundColor: 'rgb(17,17,17)',
          flexWrap: 'wrap',
        }}
      >
        <Column
          ref={popover}
          style={{
            border: '1px solid rgb(64,64,64)',
            position: 'absolute',
            backgroundColor: 'rgb(17,17,17)',
            width: '200px',
            left: '80px',
          }}
          role="button"
          tabIndex={0}
          onMouseDown={onMouseDown}
          className="address popover-body"
        >
          <CustomInput
            onChange={onChange}
            className='btn-text-field btn-rounded btn-black-yellow btn-price-field'
            style={{ border: '1px solid', margin: '15px 5px' }}
            placeholder='Address'
            value={value}
          />
          {searchContext === 'searchbar' && <ApplyClear style={{ top: '20px', border: 'none' }} name="address" />}
        </Column>
      </Fade>
    </div>
  );
});

const mapStateToProps = (state, ownProps) => {
  const display = state.search[state.search.context][ownProps.displayProp];
  return ({
    display: display || false,
    address: state.search[state.search.context].address,
  });
};

const mapDispatchToProps = dispatch => ({
  setStore: payload => dispatch(setStore(payload)),
  generateNewSearch: () => dispatch(generateNewSearch()),
});

AddressPop.propTypes = {
  display: PropTypes.bool.isRequired,
  searchContext: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(AddressPop);
