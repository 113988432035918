import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ButtonStepper } from '../Buttons';
import ContainerWrapper from '../ContainerWrapper';
import PhotoButtons from './PhotoButtons';

const title = "That's it for photos.";

function reorder(list, startIdx, endIdx) {
  const [removed] = list.splice(startIdx, 1);
  list.splice(endIdx, 0, removed);
  return list;
}

const PhotoSort = (props) => {
  const { globalState: { images }, setGlobalState } = props;
  const [submitting, setSubmitting] = useState(false);
  const source = axios.CancelToken.source();
  const { listingId } = props;
  const sortImage = (evt) => {
    setSubmitting(true);
    evt.preventDefault();

    if (images.length) {
      axios
        .post(
          '/api/v2/listings.images.sort',
          {
            listing_id: listingId,
            sort_order: images.map(photo => photo.id),
          },
          { headers: { API_TOKEN: localStorage.getItem('apiToken') } },
          { cancelToken: source.token },
        )
        .then(() => {
          setSubmitting(false);
          props.handlePhotoSubmit();
        })
        .catch((err) => {
          console.error(err);
          setSubmitting(false);
        });
    } else {
      props.handlePhotoSubmit();
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    setGlobalState({ images: reorder(images, result.source.index, result.destination.index) })
  };

  useEffect(() => {
    axios
      .get(`/api/v2/listings.images?listing_id=${props.listingId}`, { cancelToken: source.token })
      .then((res) => {
        setGlobalState({ images: res.data.images });
      })
      .catch(err => console.error(err));
    return () => {
      source.cancel('Operation canceled');
    };
  }, []);

  useEffect(() => {
    document.title = `Create: Photos - ${title}`;
  }, []);

  function onDelete(imageId) {
    const newImages = images.filter(image => image.id !== imageId);
    setGlobalState({ images: newImages });
  }

  function onCrop(imageId, newImage) {
    const oldImageIndex = images.findIndex(image => image.id === imageId);
    images[oldImageIndex] = newImage;
    setGlobalState({images: [...images]});
  }

  return (
    <ContainerWrapper>
      <div>
        <div className="title">
          <h3>{title}</h3>
          <p>Drag &apos;em around to re-sort them.</p>
        </div>
        <form className="photo-sort-container">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div
                  {...provided.droppableProps}
                  className="droppable-container"
                  ref={provided.innerRef}
                >
                  {images.map((photo, index) => (
                    <Draggable key={photo.id} draggableId={photo.id} index={index}>
                      {provided => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          className="photo-buttons-wrapper"
                        >
                          {index === 0 && (
                            <div className="primary-label">
                              <label className="button btn-rounded btn-black-white">primary</label>
                            </div>
                          )}
                          <PhotoButtons
                            onDelete={onDelete}
                            onCrop={onCrop}
                            listingId={listingId}
                            image={photo}
                            cropEndpoint="/api/v2/listings.images.crop"
                            deleteEndpoint="/api/v2/listings.images.delete"
                          >
                            <img key={photo.id} src={photo.wide} className="img-fluid" alt="preview" />
                          </PhotoButtons>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <ButtonStepper
            handleNext={evt => sortImage(evt)}
            handleBack={props.handleBack}
            submitting={submitting}
          />
        </form>
      </div>
    </ContainerWrapper>
  );
};

PhotoSort.propTypes = {
  globalState: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        wide: PropTypes.string,
      }),
    ),
  }).isRequired,
  setGlobalState: PropTypes.func.isRequired,
};

export default PhotoSort;
