import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class MoreButton extends Component {
  componentDidMount() {
    this.node.addEventListener('click', e => {
      e.stopPropagation();
    });
    this.node.addEventListener('mouseup', e => {
      e.stopPropagation();
    });
  }

  render() {
    const { togglePopover, className } = this.props;

    return (
      <input
        ref={me => this.node = me}
        name="more"
        onMouseDown={togglePopover}
        type="button"
        className={`button btn-rounded ${className} btn-search-bar-size`}
        value="More filters"
      />
    );
  }
}

const mapStateToProps = state => {
  const selectedPet = state.search[state.search.context].selectedPet;
  const address = state.search[state.search.context].address;
  const likedOnly = state.search[state.search.context].likedOnly;
  const selectedAmenities = state.search[state.search.context].selectedAmenities;
  const selectedBath = state.search[state.search.context].selectedBath;
  const className = selectedPet || !!address || likedOnly || !!selectedAmenities.length || selectedBath ? 'btn-transparent-yellow' : 'btn-transparent-gray';
  return ({ className });
};

const mapDispatchToProps = dispatch => ({
  togglePopover: e => dispatch(togglePopover(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MoreButton);
