import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';
import MorePop from '../searchPopovers/MorePop';
import MoreButton from '../searchbarButtons/MoreButton';
import RenderOutsideContainer from './RenderOutsideContainer';
import Reposition from './Reposition';

class More extends Component {
  render() {
    const {
      popoverWrapper,
      popoverContainer,
      popover,
      searchContext,
      baths: bathsFilter,
      amenities: amenitiesFilter,
      pets: petsFilter,
      likedOnly: likedOnlyFilter,
      address: addressFilter,
    } = this.props;
    return (
      <Column
        ref={popoverContainer}
        className="searchbar-col"
      >
        <RenderOutsideContainer>
          <MorePop
            ref={{
              popoverWrapper,
              popover,
            }}
            searchContext={searchContext}
            displayProp="moreDisplay"
            bathsFilter={bathsFilter}
            amenitiesFilter={amenitiesFilter}
            petsFilter={petsFilter}
            likedOnlyFilter={likedOnlyFilter}
            addressFilter={addressFilter}
          />
        </RenderOutsideContainer>
        <MoreButton />
      </Column>
    );
  }
}

More.defaultProps = {
  popoverWrapper: null,
  popover: null,
  popoverContainer: null,
  baths: false,
  amenities: false,
  pets: false,
  likedOnly: false,
  address: false,
};

More.propTypes = {
  popoverWrapper: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popover: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popoverContainer: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  searchContext: PropTypes.string.isRequired,
  baths: PropTypes.bool,
  amenities: PropTypes.bool,
  pets: PropTypes.bool,
  address: PropTypes.bool,
  likedOnly: PropTypes.bool,
};

export default Reposition(More);
