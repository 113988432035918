import React, { StrictMode } from 'react';
import { Router, Redirect } from '@reach/router';

// Pages
import SearchPage from 'components/listings/pages/SearchPage';
import DetailPage from 'components/listings/pages/DetailPage';
import NotFound from 'components/listings/Routes/NotFound';

// Handlers
import ErrorBoundary from 'errorLogging';
import UniqueLinkSearchHandler from 'components/listings/Routes/UniqueLinkSearchHandler';

const ListingRouter = () => ((
  <StrictMode>
    {/* Handles Error Catching for Whole Router */}
    <ErrorBoundary>
      <Router>
        {/* Listing With Context */}
        <SearchPage path="/rentals" type="listings" />
        <UniqueLinkSearchHandler path="/rentals/:*" />

        {/* Sales With Context */}
        <SearchPage path="/sales" type="sales" />

        {/* Commercials With Context */}
        <SearchPage path="/commercial/search" type="commercials" />

        {/* View Page */}
        <Redirect from="/listings" to="/rentals" />
        <DetailPage path="/listings/:id" type="listings" />
        <DetailPage path="/listings/:id/tour" type="listings" openModal="TourRequest" />

        {/* Default 404 Page */}
        <NotFound default />
      </Router>
    </ErrorBoundary>
  </StrictMode>
));

export default ListingRouter;
