import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Fade from 'wrappers/Fade';

const About = props => {
  const {
    region,
    neighborhood,
    listing,
  } = props;

  const {
    bedrooms,
    bathrooms,
    buildingId,
    shortAddress,
    address,
    pets,
    rental,
    description,
    subwayStops,
    residential,
  } = listing;

  const [showRoomatesHelp, setShowRoomatesHelp] = useState(false);

  useEffect(() => {
    setShowRoomatesHelp(bedrooms > 1 && rental && residential);
  }, [bedrooms, rental, residential]);

  return (
    <Fade>
      <h5>About the Place</h5>
      <p>
        {residential && (
          <>
            {`${bedrooms} Bed / ${bathrooms} Bath `}
            {buildingId
              ? (
                <a href={`/buildings/${buildingId}`}>
                  {`at ${shortAddress}`}
                </a>
              )
              : address}
            {' in '}
            <a href={neighborhood.url}>{neighborhood.name}</a>
            {', '}
            <a href={region.url}>{region.name}</a>
            {`. ${pets}. `}
            {subwayStops.length > 0 && (
              <>
                {'Nearby subway stations include: '}
                {subwayStops.map((stop, index) => {
                  if (index === subwayStops.length - 1) {
                    return (<a key={stop.id} href={`/subway_stops/${stop.id}`}>{`${stop.name} (${stop.lines.join(', ')}).`}</a>);
                  }
                  return (
                    <a key={stop.id} href={`/subway_stops/${stop.id}`}>
                      {`${stop.name}(${stop.lines.join(', ')}), `}
                    </a>
                  );
                })}
              </>
            )}
          </>
        )}
        {description}
        {
          showRoomatesHelp && (
            <>
              {'We can help you '}
              <a href="/roommates">find roommates</a>
              {' on Nooklyn.'}
            </>
          )
        }
      </p>
      <hr />
    </Fade>
  );
};

About.propTypes = {
  listing: PropTypes.shape({
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    buildingId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    shortAddress: PropTypes.string,
    address: PropTypes.string,
    pets: PropTypes.string,
    rental: PropTypes.bool,
    description: PropTypes.string,
    subwayStops: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      name: PropTypes.string,
      lines: PropTypes.arrayOf(PropTypes.string),
    })),
    residential: PropTypes.bool,
  }).isRequired,
  neighborhood: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  region: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default About;
