import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import fetchFilterData from 'api/search';
import formatAmenities, {
  formatBath,
  formatBeds,
  formatNeighborhoods,
  formatNoFee,
  formatPet,
  formatPrice,
  formatSublet,
  formatSubway,
} from './searchParamFormatter';

const SearchParamDisplay = props => {
  const {
    context,
    selectedFilters,
    loading,
  } = props;

  const {
    amenities = selectedFilters.amenities || [],
    baths = selectedFilters.baths || '',
    bedrooms = selectedFilters.bedrooms || [],
    noFeeOnly = selectedFilters.noFeeOnly || false,
    pets = selectedFilters.pets || '',
    price = selectedFilters.price || [],
    subletOnly = selectedFilters.subletOnly || false,
    neighborhoods = selectedFilters.neighborhoods || [],
    regions = selectedFilters.regions || [],
    subways = selectedFilters.subways || [],
    subwayStops = selectedFilters.subwayStops || [],
  } = selectedFilters;

  const [filterData, setFilterData] = useState({});
  const [filtersSet, setFiltersSet] = useState(false);

  useEffect(() => {
    fetchFilterData().then(results => {
      setFilterData(results);
      setFiltersSet(true);
    });
  }, []);

  if (loading || !filtersSet) {
    return (
      <>
        <h4
          className="standard-type"
          style={{
            display: 'flex',
            color: '#fff',
            margin: '8px',
          }}
        >
          Your Search is Loading...
        </h4>
        <p
          className="standard-type"
          style={{
            display: 'flex',
            color: '#9c9c9c',
            margin: '8px',
          }}
        >
          {' • Nooklyn Apartments Coming Up  • '}
        </p>
      </>
    );
  }

  return (
    <>
      <h4
        className="standard-type"
        style={{
          display: 'flex',
          color: '#fff',
          margin: '8px',
        }}
      >
        {formatNeighborhoods(regions, neighborhoods, context, {
          regions: filterData.regions,
          neighborhoods: filterData.neighborhoods,
        })}
      </h4>
      <p
        className="standard-type"
        style={{
          display: 'flex',
          color: '#9c9c9c',
          margin: '8px',
        }}
      >
        {[
          formatAmenities(amenities),
          formatBath(baths),
          formatBeds(bedrooms),
          formatNoFee(noFeeOnly),
          formatPet(pets),
          formatPrice(price),
          formatSublet(subletOnly),
          formatSubway(subways, subwayStops, {
            subwayLines: filterData.subwayLines,
            subwayStops: filterData.subwayStops,
          }),
        ]
          .filter(item => item)
          .join(' • ')}
      </p>
    </>
  );
};

SearchParamDisplay.defaultProps = {
  loading: false,
};

SearchParamDisplay.propTypes = {
  context: PropTypes.string.isRequired,
  selectedFilters: PropTypes.shape({
    amenities: PropTypes.arrayOf(PropTypes.string),
    baths: PropTypes.string,
    bedrooms: PropTypes.arrayOf(PropTypes.string),
    noFeeOnly: PropTypes.bool,
    pets: PropTypes.string,
    price: PropTypes.arrayOf(PropTypes.string),
    subletOnly: PropTypes.bool,
    neighborhoods: PropTypes.arrayOf(PropTypes.string),
    regions: PropTypes.arrayOf(PropTypes.string),
    subways: PropTypes.arrayOf(PropTypes.string),
    subwayStops: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  loading: PropTypes.bool,
};

export default SearchParamDisplay;
