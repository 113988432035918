import React from 'react';
import PropTypes from 'prop-types';
import IndividualSubway from './IndividualSubway';

const SubwayStopList = props => {
  const {
    selected,
    onChange,
    options,
  } = props;

  const selectedSubways = options.subwayLines
    .filter(line => selected.subways.includes(line.line));
  const selectedSubwayStops = options.subwayStops
    .filter(stop => selected.subwayStops.includes(stop.id));

  const updateSelectedSubways = subway => {
    const originalSubways = selected.subways ? selected.subways : [];
    const stops = options.subwayStops.filter(stop => stop.lines.includes(subway));

    let newSelectedSubways = [];
    let stopIds = [];

    if (stops.length) {
      stopIds = stops.map(stop => stop.id);
    }

    if (selected.subways.includes(subway)) {
      newSelectedSubways = originalSubways.filter(value => value !== subway);
    } else {
      newSelectedSubways = [...originalSubways, subway];
    }
    return {
      subways: newSelectedSubways,
      subwayStops: [
        ...selectedSubwayStops
          .filter(stop => !stopIds.includes(stop.id)),
      ].map(stop => stop.id),
    };
  };

  const updateSelectedSubwayStops = subwayStop => {
    const originalSubwayStops = selected.subwayStops ? selected.subwayStops : [];
    const originalSubways = selected.subways ? selected.subways : [];

    let newSelectedSubways = [];
    let newSelectedSubwayStops = [];

    // Grab Info of SubwayStops and Associated Lines
    const selectedSubwayStopInfo = options.subwayStops
      .filter(_subwayStop => _subwayStop.id === subwayStop)[0];
    const selectedSubwayLinesInfo = options.subwayLines
      .filter(_subwayLine => selectedSubwayStopInfo.lines.includes(_subwayLine.line));

    // Checks for if subways are completely selected or subwaystop is just selected
    const subwayStopLinesSelected = selectedSubways
      .some(subway => selectedSubwayLinesInfo
        .some(selectedSubwayLines => selectedSubwayLines.line === subway.line));
    const subwayStopAlreadySelected = selectedSubwayStops
      .some(_subwayStop => _subwayStop.id === subwayStop);

    if (subwayStopAlreadySelected || subwayStopLinesSelected) {
      // Remove SubwayStop from selected
      newSelectedSubwayStops = selectedSubwayStops
        .filter(_subwayStop => _subwayStop.id !== subwayStop)
        .map(stop => stop.id);

      if (subwayStopLinesSelected) {
        selectedSubwayLinesInfo.forEach(selectedSubwayLines => {
          newSelectedSubwayStops = [
            ...newSelectedSubwayStops,
            ...selectedSubwayLines.stops
              .filter(_subwayStop => _subwayStop.id !== subwayStop)
              .map(_subwayStop => _subwayStop.id),
          ];

          // Cleanup Duplicates
          newSelectedSubwayStops = newSelectedSubwayStops
            .filter((item, pos) => newSelectedSubwayStops.indexOf(item) === pos);
        });
      }

      // If stop lines selected, filter it out else filter leaves everything
      newSelectedSubways = selectedSubways
        .filter(subway => (subwayStopLinesSelected
          ? selectedSubwayLinesInfo
            .some(selectedSubwayLines => selectedSubwayLines.line !== subway.line)
          : true
        ))
        .map(subway => subway.line);
    } else {
      newSelectedSubwayStops = [
        ...originalSubwayStops,
        selectedSubwayStopInfo.id,
      ];

      let filteredSubways = originalSubways;

      if (selectedSubwayStopInfo !== undefined) {
        const completedSubwayLines = selectedSubwayLinesInfo.filter(subwayLine => {
          const notFoundSubwayStops = subwayLine.stops
            .filter(_subwayStop => !newSelectedSubwayStops
              .some(newStop => _subwayStop.id === newStop));

          return notFoundSubwayStops.length === 0;
        }).map(subwayLine => subwayLine.line);

        if (completedSubwayLines.length !== 0) {
          filteredSubways = [...originalSubways, ...completedSubwayLines];
          newSelectedSubwayStops = newSelectedSubwayStops
            .filter(subwayStopId => !selectedSubwayLinesInfo
              .some(selectedSubwayLines => selectedSubwayLines.stops
                .some(selectedSubwayLinesStop => selectedSubwayLinesStop.id === subwayStopId)));

          const newSubways = [...originalSubways, ...completedSubwayLines];
          filteredSubways = newSubways.filter((item, pos) => newSubways.indexOf(item) === pos);
        }
      }

      newSelectedSubways = filteredSubways;
    }

    return ({
      subways: newSelectedSubways,
      subwayStops: newSelectedSubwayStops,
    });
  };

  const handleSelect = (value, type) => {
    switch (type) {
      case 'stops':
        onChange(updateSelectedSubwayStops(value));
        break;

      default:
        onChange(updateSelectedSubways(value));
        break;
    }
  };

  return (
    <div
      className="region-hood-content"
      style={{
        width: '110%',
        maxHeight: '250px',
        overflowY: 'scroll',
      }}
    >
      { options.subwayLines && options.subwayLines.map(subwayLine => (
        <IndividualSubway
          key={`SubwayStops-${subwayLine.line}`}
          subwayLine={subwayLine}
          selected={{
            selectedSubways,
            selectedSubwayStops,
          }}
          handleSelect={handleSelect}
        />
      ))}
    </div>
  );
};

SubwayStopList.propTypes = {
  selected: PropTypes.shape({
    subways: PropTypes.arrayOf(PropTypes.string),
    subwayStops: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    subwayLines: PropTypes.arrayOf(PropTypes.shape({})),
    subwayStops: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default SubwayStopList;
