import React from 'react';
import { AgreementButtonJumbotron } from '../../containers/AgreementButtonJumbotron';

export default function JumbotronAgreement(props) {
  const { feedback, lease_client } = props;

  return (
    <div>
      <h1>Deposit Agreement</h1>
      <p>
        OK, now its time to review and sign the Nooklyn deposit agreement. This
        agreement covers our refund policy and represents a firm commitment to
        take posession of the apartment pending approval from the property
        owner. This is required for both renters and guarantors.
      </p>
      <AgreementButtonJumbotron
        lease_client_id={lease_client.id}
        className="d-inline-block"
        {...props}
      />
      <a
        className="button btn-rounded btn-transparent-yellow"
        href={`/support?feedback[message_context]=${feedback}`}
        target="_blank"
      >
        Help
      </a>
    </div>
  );
}
