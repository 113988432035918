import React from 'react';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import LoadingSpinner from 'components/LoadingSpinner';
import { CSSTransition } from 'react-transition-group';
import { ButtonStepper } from './Buttons';

const title = 'Upload Photo Gallery';

class PhotoUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previews: [],
      uploading: false,
      images: props.images,
    };
    this.source = axios.CancelToken.source();
  }

  componentDidMount() {
    document.title = `Create: Photos - ${title}`;
  }

  componentWillUnmount() {
    this.state.previews.forEach(file => URL.revokeObjectURL(file));
    this.source.cancel('Operation canceled');
  }

  handleFileDrop = async (files) => {
    this.setState({ uploading: true });
    const { listingId } = this.props;
    if (!files.length) {
      return;
    }

    this.setState({ previews: files.map(file => URL.createObjectURL(file)) });

    const promises = files.map((file) => {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(file);
      });
    });

    const arrayOfBase64 = await Promise.all(promises);

    try {
      const resList = await Promise.all(
        arrayOfBase64.map(url => axios.post(
          '/api/v2/listings.images.upload',
          { listing_id: listingId, url },
          {
            headers: { API_TOKEN: localStorage.getItem('apiToken') },
            cancelToken: this.source.token,
          },
        )),
      );
      this.setState(prevState => ({
        uploading: false,
        previews: [],
        images: [...prevState.images, ...resList.map(res => res.data.result)],
      }));
    } catch (err) {
      this.setState({
        uploading: false,
        previews: [],
        error: 'An expected error occurred. please contact hi@nooklyn.com',
      });
    }
  };

  render() {
    const {
      previews, images, error, uploading,
    } = this.state;

    return (
      <CSSTransition in appear timeout={300} classNames="stepper">
        <div>
          <div className="title">
            <h3>{title}</h3>
            <p>We promised, we delivered.</p>
          </div>
          <Dropzone
            onDrop={acceptedFiles => this.handleFileDrop(acceptedFiles)}
            disabled={uploading}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="photo-upload-container">
                    <i className="nklyn-3x nookons-camera" />
                    <p>Drop photos here or click the camera.</p>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
          <aside className="preview-container">
            {error && <div>{error}</div>}
            {!!previews.length
              && previews.map(preview => (
                <div className="preview-card" key={preview}>
                  <LoadingSpinner />
                  <img src={preview} className="img-fluid uploading" />
                </div>
              ))}
          </aside>
          <aside className="preview-container">
            {!!images.length
              && images.map(image => (
                <div className="preview-card" key={image.id}>
                  <img src={image.thumb} className="img-fluid" />
                </div>
              ))}
          </aside>
          <ButtonStepper
            handleNext={this.props.handleNext}
            handleBack={this.props.handleBack}
            disabled={!images.length || uploading}
          />
        </div>
      </CSSTransition>
    );
  }
}

export default PhotoUpload;
