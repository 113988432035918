import React, { Component } from 'react';
import axios from 'axios';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { isCurrency } from 'helpers/util';

class LeaseAgentMarkPaidModalContent extends Component {
  state = {
    error: '',
    apiError: '',
    payment_amount: '',
    disabled: true,
    date: '',
    focused: false,
  };

  static defaultProps = {
    agentType: 'sales_agent',
  };

  handleSubmit = evt => {
    evt.preventDefault();

    const {
      lease_id,
      agent_id,
      updateSalesAgentOnModalClose,
      closeModal,
      agentType,
    } = this.props;
    const { payment_amount: amount } = this.state;
    const check_number = evt.target.checkNumber.value;
    const type = agentType;
    const notes = evt.target.notes.value;

    axios
      .post(
        `/api/v1/leases.agents.mark_as_paid`,
        {
          lease_id,
          agent_id,
          type,
          amount,
          check_number,
          notes,
        },
        { headers: { API_TOKEN: localStorage.getItem('apiToken') } }
      )
      .then(res => {
        updateSalesAgentOnModalClose();
        closeModal();
        // setMessage('Successfully maked as paid.');
      })
      .catch(err => {
        console.dir(err);
        if (err.response && err.response.data) {
          this.setState({ apiError: err.response.data.error.friendlyMessage });
        }
      });
  };

  handleAmountChange = evt => {
    const cleanedInput = parseFloat(evt.target.value.replace(/[^\d\.\-]/g, ''));
    if (Number.isNaN(cleanedInput) || cleanedInput === 0 || !isCurrency(cleanedInput)) {
      this.setState({
        error: 'amount must be a valid currency amount',
        disabled: true,
        payment_amount: cleanedInput,
      });
    } else {
      this.setState({
        error: '',
        disabled: false,
        payment_amount: cleanedInput,
      });
    }
  };

  render() {
    const { apiError, error, payment_amount, disabled } = this.state;

    return (
      <div className="container invite-container">
        <h1>Mark as Paid</h1>
        <hr className="entire-width" />
        {error && <p className="error text-center">{error}</p>}
        <div className="row" />
        <form onSubmit={this.handleSubmit}>
          {apiError && <p className="error text-center">{apiError}</p>}
          <label>Paid Amount</label>
          <input
            className="general-text-field mb-3"
            type="number"
            value={payment_amount}
            onChange={this.handleAmountChange}
          />
          <label>Check Request Number</label>
          <input
            className="general-text-field mb-3"
            name="checkNumber"
            type="number"
          />
          <label>Notes</label>
          <textarea
            name="notes"
            className="form-control mb-3 general-text-field"
          />
          <input
            type="submit"
            name="commit"
            value="Submit"
            className="button btn-black btn-rounded"
            disabled={!payment_amount || disabled}
          />
        </form>
      </div>
    );
  }
}

export default LeaseAgentMarkPaidModalContent;
