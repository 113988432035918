import React, { Component } from 'react';
import { Row, Column } from 'wrappers/FlexTable';
import { connect } from 'react-redux';
import { setStore, generateNewSearch, updateActiveSearch } from 'reducers/search';
import CustomCheckBox from 'components/CustomCheckBox';

class IndividualMarketAs extends Component {
  onSelect = e => {
    e.preventDefault();
    const { selected, setStore, generateNewSearch, updateActiveSearch, searchContext, marketAs } = this.props;

    if (!selected) {
      setStore({ selectedMarketAs: marketAs });
      generateNewSearch();
      if (searchContext === 'searchbar') {
        updateActiveSearch();
      }
    }
  }

  render() {
    const { marketAs, selected } = this.props;
    return (
      <Row
        style={{
          border: '0px',
          width: '100%',
          paddingLeft: '10px',
          paddingTop: '10px', color: '#fff',
          fontSize: '13px',
          fontWeight: '500',

        }}>
        <Column
          onMouseDown={this.onSelect}
          style={{
            width: '100%',
            cursor: 'pointer',
            userSelect: 'none'
          }}>
          <CustomCheckBox
            checked={selected}
            boxClass='radio-box'
            boxcheckedClass='radio-box-selected'
            checkmarkClass='radio-button'
          >
            <Column
              style={{
                color: '#fff',
                fontSize: '13px',
                fontWeight:  '500',
                paddingLeft: '10px',
              }}
            >
              {marketAs}
            </Column>
          </CustomCheckBox>
        </Column>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selected = ownProps.marketAs === state.search[state.search.context].selectedMarketAs;
  return { selected };
};

const mapDispatchToProps = dispatch => ({
  setStore: payload => dispatch(setStore(payload)),
  generateNewSearch: () => dispatch(generateNewSearch()),
  updateActiveSearch: () => dispatch(updateActiveSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualMarketAs);
