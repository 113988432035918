import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class HoodButton extends Component {

  componentDidMount() {
    this.node.addEventListener('click', (e) => {
      e.stopPropagation();
    });
    this.node.addEventListener('mouseup', (e) => {
      e.stopPropagation();
    });
  }

  render() {

    const { selectedHoods, togglePopover } = this.props;
    const className = selectedHoods.length > 0 ? 'btn-transparent-yellow' : 'btn-transparent-gray';
    const value = selectedHoods.length > 0 ? `Neighborhoods (${selectedHoods.length})` : 'Neighborhoods';
    return (
      <input
        ref={me => this.node = me}
        name='hood'
        onMouseDown={togglePopover}
        type='button'
        className={`button btn-rounded ${className} btn-search-bar-size`}
        value={value}/>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedHoods: state.search[state.search.context].selectedHoods,
});

const mapDispatchToProps = (dispatch) => ({
  togglePopover: (e) => dispatch(togglePopover(e))
});

export default connect(mapStateToProps, mapDispatchToProps)(HoodButton)
