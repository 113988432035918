import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomCheckBox from 'components/CustomCheckBox';
import { Column } from 'wrappers/FlexTable';
import { truncate } from 'helpers/util';

class IndividualAmenity extends Component {

  shouldComponentUpdate(nextProps) {
    if (nextProps.checked !== this.props.checked) {
      return true;
    }
    return false;
  }

  onCheck = (e) => {
    this.props.onCheck(e, this.props.amenity);
  }

  render() {
    const { checked, amenity } = this.props;
    return (
      <Column
        className='individual-amenity'
        data-name={amenity}
        style={{
          display: 'block',
          paddingTop: '2px',
          paddingBottom:'10px',
          paddingRight: '0px',
          paddingLeft:'0px',
        }}
      >
        <CustomCheckBox
          boxClass='matrix-checkbox'
          boxcheckedClass='matrix-checkbox-checked'
          checkmarkClass='checkmark'
          onMouseDown={this.onCheck}
          dataName={amenity}
          checked={checked}
          type='checkbox'
          name={amenity}
          value={amenity}>
          <Column
            onMouseDown={this.onCheck}
            data-name={amenity}
            style={{
              color: '#fff',
              fontSize: '13px',
              fontWeight:  '500',
              paddingLeft: '10px',
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            {truncate(amenity, 23)}
          </Column>
        </CustomCheckBox >
      </Column>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const checked = state.search[state.search.context].selectedAmenities.some(amenity => amenity === ownProps.amenity);
  return ({
    checked: checked,
  });
};

export default connect(mapStateToProps)(IndividualAmenity);
