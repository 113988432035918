import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Fade extends Component {

  // Wrap around any element you want to fade in and out of the DOM
  /*
   fadeStart prop is a css class containing the initial opacity (0) and transitions for fading out.
   fadeEnd prop is a css class containing the opacity of the fully rendered component
   and the transitions for fading in.
   customStyles prop is an object for top level styling
   such as z-index and positioning, which are affected by a wrapper div.
   visible prop is the current state of the element (true = visible, false = invisible)
  */

  shouldComponentUpdate(nextProps) {
    const el = this.el;
    const { fadeStart, fadeEnd, visible, children } = nextProps;
    if (visible !== this.props.visible) {
      if (visible) {
        this.frame = requestAnimationFrame(() => {
          el.classList.add(fadeEnd);
          this.el.addEventListener('transitionend', callback);
          let callback = () => {
            el.classList.remove(fadeStart);
            this.el.removeEventListener('transitionend', callback);
          };
        });
      } else {
        this.frame = requestAnimationFrame(() => {
          el.classList.remove(fadeEnd);
          el.classList.add(fadeStart);
        });
      }
      return false;
    }
    return true;
  }

  componentDidMount() {
    const { fadeStart, fadeEnd, visible } = this.props;
    const el = this.el;
    if (visible){
      requestAnimationFrame(() => {
        this.frame = requestAnimationFrame(() => {
          el.classList.add(fadeEnd);
          el.classList.remove(fadeStart);
        });
      });
    }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.frame);
  }

  render() {
    const { children, customStyles, fadeStart, id } = this.props;

    return (
      <div id={id} className={`${fadeStart} paint-layer`} style={customStyles} ref={el => this.el = el}>
        {children}
      </div>
    );
  }
}

Fade.propTypes = {
  customStyles: PropTypes.object,
  fadeStart: PropTypes.string,
  fadeEnd: PropTypes.string,
  visible: PropTypes.bool,
};

Fade.defaultProps = {
  id: '',
  customStyles: {},
  fadeStart: 'default-fade-start',
  fadeEnd: 'default-fade-end',
  visible: true,
};
