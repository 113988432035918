import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Column, Row } from 'wrappers/FlexTable'
import CustomInput from 'components/CustomInput'
import LoadingSpinner from 'components/LoadingSpinner'
import ApplyClear from '../ApplyClear'

import s from './PricePopover.module.css'
import getPriceWarningText from './getPriceWarningText'

const PricePopover = (props) => {
  const { loading, value, onChange, targetRef } = props

  const [queuedValue, setQueuedValue] = useState(value)
  const [warningText, setWarningText] = useState(null)

  const handleClick = (selected) => setQueuedValue(selected)

  const handleUpdate = (updateType) => {
    if (updateType === 'apply') {
      const warningTextByQueuedValue = getPriceWarningText(queuedValue)

      if (!warningTextByQueuedValue) {
        setWarningText(null)

        onChange(queuedValue)
        return targetRef.current ? targetRef.current.click() : null
      }

      setWarningText(warningTextByQueuedValue)
    }
    return setQueuedValue(['', ''])
  }

  return (
    <div className="fee-pop">
      <Column
        role="button"
        tabIndex={0}
        style={{
          position: 'absolute',
          border: '1px solid #404040',
          borderRadius: '0.3em',
          background: '#000',
          overflow: 'hidden',
        }}
        className="price"
      >
        {!loading ? (
          <>
            <Row style={{ borderRight: '0px' }}>
              <Column style={{ width: '50%', padding: '16px' }}>
                <CustomInput
                  className={s.input}
                  placeholder="Min"
                  value={queuedValue[0]}
                  beforeValue="$"
                  onChange={(newMin) => handleClick([newMin, queuedValue[1]])}
                />
              </Column>
              <span className="text-white d-flex align-items-center"> - </span>
              <Column style={{ width: '50%', padding: '16px' }}>
                <CustomInput
                  className={s.input}
                  placeholder="Max"
                  value={queuedValue[1]}
                  beforeValue="$"
                  onChange={(newMax) => handleClick([queuedValue[0], newMax])}
                />
              </Column>
            </Row>
            {warningText && (
              <div
                style={{
                  width: '100%',
                  padding: '12px 16px',
                  borderRadius: '4px',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  marginBottom: '12px',
                  backgroundColor: 'rgba(248, 215, 218, 1)',
                  borderColor: 'rgba(220, 53, 69, 1)',
                  color: 'rgba(114, 28, 36,1)',
                }}
              >
                {warningText}
              </div>
            )}
            <ApplyClear handleUpdate={handleUpdate} className={s.applyClear} />
          </>
        ) : (
          <LoadingSpinner />
        )}
      </Column>
    </div>
  )
}

PricePopover.propTypes = {
  loading: PropTypes.bool.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  targetRef: PropTypes.shape({
    current: PropTypes.shape({
      click: PropTypes.func,
    }),
  }).isRequired,
}

export default PricePopover
