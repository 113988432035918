/* ACTIONS */

export const addPopoverRef = payload => ({
  type: 'ADD_POPOVER_REF',
  payload,
});

export const amenitySelectAll = () => ({
  type: 'AMENITY_SELECT_ALL',
});

export const clearSinglePopover = name => ({
  type: 'CLEAR_SINGLE_POPOVER',
  name,
});

export const convertHoodSearchToSelectedValues = region => ({
  type: 'CONVERT_HOOD_SEARCH_TO_SELECTED_VALUES',
  region,
});

export const convertSavedSearchToSelectedValues = () => ({
  type: 'CONVERT_SAVED_SEARCH_TO_SELECTED_VALUES',
});

export const convertSubwayStopToSelectedValues = () => ({
  type: 'CONVERT_SUBWAY_STOP_TO_SELECTED_VALUES',
});

export const fetchNewPage = page => ({
  type: 'FETCH_NEW_PAGE',
  page,
});

export const generateNewSearch = () => ({
  type: 'GENERATE_NEW_SEARCH',
});

export const historyInducedSearch = source => ({
  type: 'HISTORY_INDUCED_SEARCH',
  source,
});

export const initializeSearch = signedIn => ({
  type: 'INITALIZE_SEARCH',
  signedIn,
});

export const removePopoverRef = payload => ({
  type: 'REMOVE_POPOVER_REF',
  payload,
});

export const resetSearch = () => ({
  type: 'RESET_SEARCH',
});

export const revertSelectedHoodsToPrevious = () => ({
  type: 'REVERT_SELECTED_HOODS_TO_PREVIOUS',
});

export const searchThisArea = () => ({
  type: 'SEARCH_THIS_AREA',
});

export const setReducer = payload => ({
  type: 'SET_REDUCER',
  context: payload,
});


export const setSelectedItem = selectedItem => ({
  type: 'SET_SELECTED_ITEM',
  selectedItem,
});

export const setStore = payload => ({
  type: 'SET_STORE',
  payload,
});

export const toggleAllFalse = () => ({
  type: 'TOGGLE_ALL_FALSE',
});

export const togglePopover = (e) => {
  e.stopPropagation();
  e.preventDefault();
  return ({
    type: 'TOGGLE_POPOVER',
    payload: { name: e.target.name },

  });
};

export const updateActiveSearch = () => ({
  type: 'UPDATE_ACTIVE_SEARCH',
});

export const updateAmenityMatches = () => ({
  type: 'UPDATE_AMENITY_MATCHES',
});


export const updateHoodMatches = () => ({
  type: 'UPDATE_HOOD_MATCHES',
});

export const updatePriceRange = payload => ({
  type: 'UPDATE_PRICE_RANGE',
  payload,
});

export const updateSelectedBeds = val => ({
  type: 'UPDATE_SELECTED_BEDS',
  payload: val,
});

export const updateSelectedHoods = id => ({
  type: 'UPDATE_SELECTED_HOODS',
  payload: {
    hood: id,
  },
});

export const updateSelectedSubways = subway => ({
  type: 'UPDATE_SELECTED_SUBWAYS',
  payload: subway,
});

export const updateSubwayStopMatches = () => ({
  type: 'UPDATE_SUBWAY_STOP_MATCHES',
});

export const updateSubwayLineMatches = () => ({
  type: 'UPDATE_SUBWAY_LINE_MATCHES',
});

export const updateSubwayLines = () => ({
  type: 'UPDATE_SUBWAY_LINES',
});

export const updateRegionalHoods = () => ({
  type: 'UPDATE_REGIONAL_HOODS',
});

export const updateRegionalHoodMatches = () => ({
  type: 'UPDATE_REGIONAL_HOOD_MATCHES',
});

export const updateSelectedRegions = region => ({
  type: 'UPDATE_SELECTED_REGIONS',
  payload: region,
});
