import React from 'react';
import { Column } from 'wrappers/FlexTable';
import PropTypes from 'prop-types';

const ToggleMatrixButton = props => {
  const {
    currentUser,
    showMatrix,
    onMatrixChange,
    listing,
    loading,
  } = props;

  if (loading) {
    return null;
  }

  const { network } = listing || {};
  const hasPlatformPermission = currentUser && currentUser.permissions && currentUser.permissions.includes('platform_moderator') && (network === 'platform' || network === 'feed');
  const hasBrokeragePermission = currentUser && currentUser.permissions && currentUser.permissions.includes('employee') && (network === 'brokerage');
  const hasSubwayPermission = currentUser && currentUser.permissions && currentUser.permissions.includes('super_admin') && (network === 'subway');

  const handleMatrixChange = () => {
    onMatrixChange(!showMatrix);
  };

  return (
    (hasPlatformPermission || hasBrokeragePermission || hasSubwayPermission) && (
      <Column className="searchbar-col">
        <input
          onClick={handleMatrixChange}
          type="button"
          className="button btn-rounded btn-transparent-yellow btn-search-bar-size"
          value={showMatrix ? 'Hide Details' : 'View Details'}
        />
      </Column>
    )
  );
};

ToggleMatrixButton.defaultProps = {
  currentUser: null,
  loading: false,
};

ToggleMatrixButton.propTypes = {
  listing: PropTypes.shape({
    network: PropTypes.string,
  }).isRequired,
  showMatrix: PropTypes.bool.isRequired,
  onMatrixChange: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  loading: PropTypes.bool,
};

export default ToggleMatrixButton;
