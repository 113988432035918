/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { useClickOutside } from 'hooks/useClickOutside'

import { appleResidentialImageMarker, appleCurrentImageMarker, appleLocationImageMarker } from 'helpers/mapHelpers'

import s from './MarkerPoint.module.css'

export default function MarkerPoint({ children, marker }) {
  const [selected, setSelected] = useState(false)
  const divRef = useRef(null)

  useEffect(() => {
    const parentNode = divRef?.current?.parentNode
    if (!parentNode) {
      return
    }

    parentNode.style.zIndex = selected ? 1 : ''
  }, [divRef.current, selected])

  const handleSelectIem = () => setSelected((state) => !state)

  useClickOutside(divRef, () => setSelected(false))

  function getImgSrc() {
    if (selected) {
      return appleCurrentImageMarker['2']
    }

    if (marker.markerUrl) {
      return marker.markerUrl['2']
    }

    if (marker.type === 'apartment' || marker.type === 'building') {
      return appleResidentialImageMarker['2']
    }

    return appleLocationImageMarker['2']
  }

  return (
    <div
      ref={divRef}
      role="contentinfo"
      className={clsx(s.marker, marker.price && s.markerPrice)}
      onClick={handleSelectIem}
    >
      {marker.price ? (
        <>
          <div className={s.triangle} />
          {window.accounting.formatMoney(marker.price / 100, { precision: 0 })}
        </>
      ) : (
        <img alt="map-point" className={s.icon} src={getImgSrc()} />
      )}

      {selected && children}
    </div>
  )
}

MarkerPoint.propTypes = {
  marker: PropTypes.shape({
    price: PropTypes.number,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

MarkerPoint.defaultProps = {
  marker: null,
}
