import React from 'react';
import PropTypes from 'prop-types';
import { ImageAnnotation } from 'components/AppleMap';
import {
  appleResidentialImageMarker,
  appleCurrentImageMarker,
} from 'helpers/mapHelpers';
import missing from 'images/logo/white-on-black-square.png';

const ListingSearchMarkers = props => {
  const {
    map,
    markers,
    selectedListing,
    isSearch,
  } = props;

  return (
    markers && markers.map(marker => {
      const showAnnotation = selectedListing ? selectedListing.id === marker.id : false;
      const title = isSearch
        ? `${marker.bedrooms} Bed / ${marker.bathrooms} Bath - ${window.accounting.formatMoney(marker.price / 100, { precision: 0 })}`
        : `Private Room - ${window.accounting.formatMoney(marker.price / 100, { precision: 0 })}`;

      let subtitle;
      if (marker.shortAddress) {
        if (!marker.shortAddress.includes('Room')) {
          subtitle = marker.shortAddress;
        } else if (!marker.platformListing) {
          subtitle = marker.shortAddress;
        } else {
          subtitle = marker.neighborhoodName;
        }
      } else {
        subtitle = marker.neighborhoodName;
      }

      return (
        <ImageAnnotation
          key={JSON.stringify(marker)}
          url={showAnnotation ? appleCurrentImageMarker : appleResidentialImageMarker}
          map={map}
          latitude={marker.latitude}
          longitude={marker.longitude}
          selected={showAnnotation}
          data={marker}
          title={marker.title}
          subtitle={marker.subtitle}
        >
          <a href={marker.url}>
            <div className="nearby-callout-content">
              <div className="nearby-callout-img-container">
                <img src={marker.image.medium} className="nearby-callout-img img-fluid" alt={subtitle} onError={e => { e.target.onerror = null; e.target.src = missing; }} />
              </div>
              <div className="nearby-callout-title">{title}</div>
              <div className="nearby-callout-subtitle">{subtitle}</div>
            </div>
          </a>
        </ImageAnnotation>
      );
    })
  );
};

ListingSearchMarkers.defaultProps = {
  map: null,
  selectedListing: null,
};

ListingSearchMarkers.propTypes = {
  map: PropTypes.shape({}),
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      image: PropTypes.shape({
        medium: PropTypes.string,
      }),
      bedrooms: PropTypes.number,
      bathrooms: PropTypes.number,
      price: PropTypes.number,
      shortAddress: PropTypes.string,
      neighborhood: PropTypes.shape({
        name: PropTypes.string,
      }),
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      platformListing: PropTypes.bool,
      id: PropTypes.number,
    }),
  ).isRequired,
  selectedListing: PropTypes.shape({
    id: PropTypes.number,
  }),
  isSearch: PropTypes.bool.isRequired,
};

export default ListingSearchMarkers;
