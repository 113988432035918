import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';
import AddressPop from '../searchPopovers/Address';
import AddressButton from '../searchbarButtons/AddressButton';
import RenderOutsideContainer from './RenderOutsideContainer';
import Reposition from './Reposition';

class Address extends Component {
  render() {
    const {
      popoverWrapper,
      popoverContainer,
      popover,
      searchContext,
    } = this.props;
    return (
      <Column
        ref={popoverContainer}
        className="searchbar-col"
      >
        <RenderOutsideContainer>
          <AddressPop
            ref={{
              popoverWrapper,
              popover,
            }}
            searchContext={searchContext}
            displayProp="addressDisplay"
          />
        </RenderOutsideContainer>
        <AddressButton />
      </Column>
    );
  }
}

Address.defaultProps = {
  popoverWrapper: null,
  popoverContainer: null,
  popover: null,
};

Address.propTypes = {
  popoverWrapper: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popoverContainer: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popover: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  searchContext: PropTypes.string.isRequired,
};

export default Reposition(Address);
