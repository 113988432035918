import React from 'react';

export const HeartListing = props => {
  const { buttonStatus, handleClick } = props;
  return (
    <a className={buttonStatus} onClick={handleClick}>
      <i className="nookons-heart-fill nklyn-red nooklyn_square_heart nklyn-4x" />
      <i className="nookons-heart-fill nklyn-gray nooklyn_square_heart" />
    </a>
  );
};
