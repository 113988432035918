import React from 'react';
import PropTypes from 'prop-types';
import catImage from 'images/img/404-cat-1.png';

const Cat404 = props => {
  const { context } = props;
  return (
    <div
      className="listing-empty-state"
      style={{ height: 'calc(100vh - 156px)' }}
    >
      <img
        className="img-fluid mx-auto"
        src={catImage}
        alt="404 cat 1"
      />
      <p className="lead">
        There are no
        {' '}
        {context}
        {' '}
        that fit your search.
      </p>
    </div>
  );
};

Cat404.defaultProps = {
  context: 'listings',
};

Cat404.propTypes = {
  context: PropTypes.string,
};

export default Cat404;
