import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BaseInput from './BaseInput';
import phoneImg from 'images/icons/svg/phone.svg';

const PhoneNumberInput = props => {
  const {
    initialValue,
    onChange,
  } = props;


  return (
    <>
      <BaseInput
        initialValue={initialValue}
        onChange={onChange}
        inputType='tel'
        inputName='phone'
        labelText="Phone Number <span class='detail'>(optional)</span>"
        inputImg={phoneImg}
      />
    </>
  );
};

PhoneNumberInput.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

PhoneNumberInput.defaultProps = {
  initialValue: '',
};

export default PhoneNumberInput;
