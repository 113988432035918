import React, { Component } from 'react';
import BlinkingCursor from './BlinkingCursor';

export default class DivInput extends Component {

  state = {
    inFocus: false,
    top,
  }

  componentDidMount() {
    this.preventDuplicate = false;
    let positions = this.input.getBoundingClientRect();
    let top = positions.top;
    this.setState({ top: top });
  }

  blur = () => {
    this.faker.blur();
    this.onBlur();
  }

  focus = () => {
    this.faker.focus();
    this.onFocus();
  }

  value = () => {
    this.text.innerText;
  }

  onBlur = (e = new CustomEvent('blur')) => {
    if (this.state.inFocus) {
      requestAnimationFrame(() => this.input.classList.remove(this.props.focusClass));
      this.setState({ inFocus: false });
      this.preventDuplicate = false;
      this.props.onBlur(e);
    }
  }

  onFocus = (e = new CustomEvent('focus')) => {
    e.stopPropagation();
    this.setState({ inFocus: true });
    requestAnimationFrame(() => this.input.classList.add(this.props.focusClass));
    this.faker.focus();
    this.props.onFocus(e, this.input);
  }

  render () {
    const {
      children,
      style,
      className,
      placeholder,
      onClick,
      onMouseUp,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseOver,
      onKeyPress,
      onKeyDown,
      onKeyUp,
      value,
    } = this.props;

    const { inFocus, top } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <div
          ref={input => this.input = input}
          tabIndex='0'
          onFocus={this.onFocus}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseOver}
          className={className}
          style={{ display:'flex', flexDirection:'row', overflow: 'hidden', ...style, }}>
          {children}
          <div
            style={{ display:'flex', flexDirection: 'column' }}
            ref={text => this.text = text}>
            { value.replace(/ /g, '\u00a0') }
          </div>
          <BlinkingCursor inFocus={inFocus} />
          {value.length === 0 ?
            <div style={{
              color:'rgb(138,138,138)',
              position: 'absolute',
              paddingLeft: '2px',
              top: '25%'
             }}>
              {placeholder}
            </div> : ''}
        </div>
        <input
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onBlur={this.onBlur}
          onFocus={(e) => e.preventDefault()}
          value={value}
          autoComplete='off'
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck='false'
          onChange={this.props.onChange}
          ref={faker => this.faker = faker}
          style={{ zIndex:'-1', position:'absolute', transform:'scale(0)', top: top }}/>
      </div>
    );
  }
}

const defaultHandler = () => '';
DivInput.defaultProps = {
  style: {},
  className: '',
  placeHolder: '',
  value: '',
  focusClass: '',
  onChange: defaultHandler,
  onKeyDown: defaultHandler,
  onKeyUp: defaultHandler,
  onKeyPress: defaultHandler,
  onClick: defaultHandler,
  onBlur: defaultHandler,
  onFocus: defaultHandler,
  onMouseUp: defaultHandler,
  onMouseDown: defaultHandler,
  onMouseEnter: defaultHandler,
  onMouseLeave: defaultHandler,
  onMouseOver: defaultHandler,
};
