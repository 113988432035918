import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AuthModal from 'components/modal/AuthModal'
import LoadingSpinner from 'components/LoadingSpinner'
import fetchListing, { likeListing, unlikeListing } from 'api/listing'

function gtagReportConversion(url) {
  var callback = function () {
    if (typeof url != 'undefined') {
      window.location = url
    }
  }
  // eslint-disable-next-line no-undef
  gtag('event', 'likelisting', {
    send_to: 'AW-968054781/WSr4CPT3orwYEP2vzc0D',
    event_callback: callback,
  })
  return false
}

const LikeButton = (props) => {
  const { listing, loading: parentLoading, signedIn } = props

  const [loading, setLoading] = useState(false)
  const [selectedListing, setSelectedListing] = useState(listing)

  useEffect(() => {
    setSelectedListing(listing)
  }, [listing])

  const { id, meta, heartsCount, status } = selectedListing

  if (status === 'Rented') {
    return null
  }

  const handleClick = () => {
    if (!loading && !parentLoading) {
      setLoading(true)
      if (meta && meta.liked) {
        unlikeListing(id).then(() => {
          fetchListing(id).then((pulledListing) => {
            setSelectedListing(pulledListing)
            setLoading(false)
          })
        })
      } else {
        likeListing(id).then(() => {
          fetchListing(id).then((pulledListing) => {
            setSelectedListing(pulledListing)
            setLoading(false)
          })
          gtagReportConversion()
        })
      }
    }
  }

  const handleKeyDown = () => (evt) => {
    if (evt.key === 'Enter') {
      handleClick()
    }
  }

  if (loading) {
    return (
      <div className="listing-like">
        <div className="button btn-transparent btn-block btn-rounded btn-search-bar-size" id={`listing_card_${id}`}>
          <LoadingSpinner style={{ width: '100%', margin: 0, overflow: 'hidden' }} />
        </div>
      </div>
    )
  }

  return (
    <div className="listing-like">
      <div className="button btn-transparent btn-block btn-rounded btn-search-bar-size" id={`listing_card_${id}`}>
        {signedIn || parentLoading ? (
          <div
            role="button"
            tabIndex={0}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            className={`${
              meta && meta.liked && !loading && !parentLoading ? 'like-button' : 'unlike-button'
            } btn-transparent`}
          >
            <div style={{ justifyContent: 'center' }} className="flex-row">
              <div style={{ padding: 2 }} className="flex-col">
                {meta && meta.liked && !loading && !parentLoading ? (
                  <i className="nookons-heart-fill nooklyn_square_heart" />
                ) : (
                  <i className="nookons-heart nooklyn_square_heart flex-col" />
                )}
              </div>
              <div className="flex-col">{heartsCount ? `${heartsCount} Likes` : '... Likes'}</div>
            </div>
          </div>
        ) : (
          <AuthModal>
            <div className="unlike-button btn-transparent">
              <div style={{ justifyContent: 'center' }} className="flex-row">
                <div style={{ padding: 2 }} className="flex-col">
                  <i className="nookons-heart nooklyn_square_heart" />
                </div>
                <div className="flex-col">{heartsCount ? `${heartsCount} Likes` : '... Likes'}</div>
              </div>
            </div>
          </AuthModal>
        )}
      </div>
    </div>
  )
}

LikeButton.defaultProps = {
  loading: false,
}

LikeButton.propTypes = {
  loading: PropTypes.bool,
  listing: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.string,
    meta: PropTypes.shape({
      liked: PropTypes.bool,
    }),
    heartsCount: PropTypes.number,
  }).isRequired,
  signedIn: PropTypes.bool.isRequired,
}

export default LikeButton
