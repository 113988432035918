import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Props:
// --------------------------------------------------
// handleClick - Callback function when element is clicked (Optional) (Default: () => {})
// selectedAgentsCount - Number of Selected Options on load (Required)

const DropDownButton = forwardRef((props, ref) => {
  const {
    handleClick,
    selectedAgentsCount,
  } = props;

  const [selectedAgentsText, setSelectedAgentsText] = useState('');

  useEffect(() => {
    if (selectedAgentsCount > 0) {
      setSelectedAgentsText(`: ${selectedAgentsCount} selected`);
    } else {
      setSelectedAgentsText('');
    }
  }, [selectedAgentsCount]);

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.currentTarget.click();
    }
  };

  return (
    <div
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      className="button btn-transparent dropdown-toggle"
      ref={ref}
      role="button"
      tabIndex={0}
      title="Agents Selector"
    >
      Agents
      {selectedAgentsText}
    </div>
  );
});

DropDownButton.defaultProps = {
  handleClick: () => { },
};

DropDownButton.propTypes = {
  handleClick: PropTypes.func,
  selectedAgentsCount: PropTypes.number.isRequired,
};

export default DropDownButton;
