import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group'; // ES6
import axios from 'axios';
import strftime from 'strftime';
import { useGlobalState } from 'components/listRoom/customEffects';
import Apartment from './Apartment';
import Photos from './Photos';
import Rooms from './Rooms';
import Submit from './Submit';
import Summary from './Summary';
import { Stepper, StepLabel } from './Stepper';

function getSteps() {
  return ['Verify', 'Apartment', 'Photos', 'Rooms', 'Submit'];
}

function formatDate(date) {
  const [year, month, day] = date.split('-');
  return [month, day, year].join('/');
}

const ProgressStepper = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [lastActiveStep, setLastActiveStep] = useState(0);
  const [listingId, setListingId] = useState(props.listingId);
  const [listing, setListing] = useState({
    address: {},
    leaseTerm: '',
    utilities: [],
    description: '',
    price: '', // api values in cents
    moveInCosts: [],
    brokerFee: '',
    beds: '',
    fullBaths: '',
    halfBaths: '0',
    bathrooms: '',
    sqFootage: '',
    petPolicy: 'No Pets Allowed',
    dateAvailable: strftime('%m/%d/%Y', new Date()),
    moveOutDate: strftime(
      '%m/%d/%Y',
      new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    ),
    subwayLines: [],
    amenities: [],
  });
  const [{ images }, setGlobalState] = useGlobalState();
  const steps = getSteps();
  const [showSummary, setShowSummary] = useState(true);
  const source = axios.CancelToken.source();
  const [summaryDisabled, setSummaryDisabled] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const fetchListing = (listingId) => {
    axios
        .all([
          axios.get(
            `/api/v2/listings.fetch?listing_id=${listingId}`,
            {
              headers: { API_TOKEN: localStorage.getItem('apiToken') },
            },
            { cancelToken: source.token },
          ),
          axios.get(
            `/api/v2/listings.images?listing_id=${listingId}`,
            {
              headers: { API_TOKEN: localStorage.getItem('apiToken') },
            },
            { cancelToken: source.token },
          ),
          axios.get(
            `/api/v1/listings.rooms.list?listing_id=${listingId}&active_only=false`,
            {
              headers: { API_TOKEN: localStorage.getItem('apiToken') },
            },
            { cancelToken: source.token },
          ),
        ])
        .then(
          axios.spread((listingRes, imagesRes, roomsRes) => {
            const { listing } = listingRes.data;
            if (listing.id) {
              setListing({
                address: {
                  latitude: listing.latitude,
                  longitude: listing.longitude,
                  full_address: listing.address,
                  line_one: listing.address_line_one,
                  unit: listing.unit,
                  city: listing.city,
                  state: listing.state,
                  zip_code: listing.zip_code,
                },
                leaseTerm: listing.term,
                utilities: listing.included_utilities,
                description: listing.description,
                price: listing.price,
                moveInCosts: listing.move_in_cost,
                brokerFee: listing.broker_fee,
                beds: listing.bedrooms,
                fullBaths: listing.full_baths,
                halfBaths: listing.half_baths,
                bathrooms: listing.bathrooms,
                sqFootage: listing.square_feet,
                subwayLines: listing.subway_lines,
                petPolicy: listing.pets,
                dateAvailable: listing.date_available && formatDate(listing.date_available),
                moveOutDate: listing.move_out_date && formatDate(listing.move_out_date),
                amenities: listing.amenities && listing.amenities.split('\n'),
                neighborhood: listing.neighborhood,
              });
            }
            if (imagesRes.data.images.length) {
              setGlobalState({ images: imagesRes.data.images });
            }
            if (roomsRes.data.rooms.length) {
              setGlobalState({ rooms: roomsRes.data.rooms });
            }
            // set active step
            let nextStep;
            if (roomsRes.data.rooms.length) {
              nextStep = steps.indexOf('Rooms') + 1;
            } else if (imagesRes.data.images.length) {
              nextStep = steps.indexOf('Photos') + 1;
            } else if (listing.id) {
              nextStep = steps.indexOf('Apartment') + 1;
            }
            if (nextStep >= lastActiveStep) {
              setActiveStep(nextStep);
              setLastActiveStep(nextStep);
            }
            setTimeout(() => {
              setSummaryDisabled(false);
            }, 500);
          }),
        )
        .catch(err => console.error(err));
  }

  useEffect(() => {
    if (!listingId) {
      axios
        .get('/api/v2/current.fetch', {
          headers: { API_TOKEN: localStorage.getItem('apiToken') },
          cancelToken: source.token,
        })
        .then(res => res.data)
        .then(({ current }) => {
          setCurrentUser(current);
          if (current.verified) {
            setActiveStep(1);
            setLastActiveStep(1);
          }
        });
    }

    if (listingId) {
      setSummaryDisabled(true);
      fetchListing(listingId)
    }
    return () => {
      source.cancel('Operation canceled');
    };
  }, [lastActiveStep]);

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setLastActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setLastActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleApartmentSubmit(listingId) {
    setListingId(listingId);
    fetchListing(listingId);
    const nextStep = steps.indexOf('Apartment') + 1;
    if (nextStep > lastActiveStep) {
      setActiveStep(nextStep);
      setLastActiveStep(nextStep);
    }
    setShowSummary(true);
  }

  function handlePhotoSubmit() {
    const nextStep = steps.indexOf('Photos') + 1;
    if (nextStep > lastActiveStep) {
      setActiveStep(nextStep);
      setLastActiveStep(nextStep);
    }
    setShowSummary(true);
  }

  function handleRoomSubmit() {
    const nextStep = steps.indexOf('Rooms') + 1;
    if (nextStep > lastActiveStep) {
      setActiveStep(nextStep);
      setLastActiveStep(nextStep);
    }
    setShowSummary(true);
  }

  function getStepContent(step) {
    switch (step) {
      case 'Apartment':
        return (
          <Apartment
            handleNext={handleNext}
            listingId={listingId}
            handleApartmentSubmit={handleApartmentSubmit}
            setShowSummary={setShowSummary}
            listing={listing}
          />
        );
      case 'Photos':
        return (
          <Photos
            handleNext={handleNext}
            handleBack={handleBack}
            listingId={listingId}
            handlePhotoSubmit={handlePhotoSubmit}
            setShowSummary={setShowSummary}
          />
        );
      case 'Rooms':
        return (
          <Rooms
            handleNext={handleNext}
            handleBack={handleBack}
            listingId={listingId}
            handleRoomSubmit={handleRoomSubmit}
            setShowSummary={setShowSummary}
            images={images}
            listing={listing}
          />
        );
      case 'Submit':
        return (
          <Submit
            listingId={listingId}
            setShowSummary={setShowSummary}
            listing={listing}
          />
        );
      default:
        return (
          <Summary
            activeStep={activeStep}
            lastActiveStep={lastActiveStep}
            steps={steps}
            setShowSummary={setShowSummary}
            setActiveStep={setActiveStep}
            summaryDisabled={summaryDisabled}
          />
        );
    }
  }

  return (
    <div className="create-listing-container">
      <CSSTransition appear in timeout={300} classNames="stepper">
        <Stepper activeStep={activeStep} lastActiveStep={lastActiveStep}>
          {steps.map((label, index) => (
            <StepLabel key={label}>{label}</StepLabel>
          ))}
        </Stepper>
      </CSSTransition>
      <div className="detail-container">
        {activeStep === steps.length || showSummary ? (
          <Summary
            activeStep={activeStep}
            lastActiveStep={lastActiveStep}
            steps={steps}
            setShowSummary={setShowSummary}
            setActiveStep={setActiveStep}
            summaryDisabled={summaryDisabled}
            currentUser={currentUser}
          />
        ) : (
          <div className="active-step-container">{getStepContent(steps[activeStep])}</div>
        )}
      </div>
    </div>
  );
};

ProgressStepper.defaultProps = {
  listingId: null,
};

export default ProgressStepper;
