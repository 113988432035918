// INITIAL STATE
const initialState = {
  message: '',
};

// ACTION TYPES
const SET_MESSAGE = 'SET_MESSAGE';
const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

// ACTION CREATORS
export const setMessage = payload => ({ type: SET_MESSAGE, payload });
export const removeMessage = payload => ({ type: REMOVE_MESSAGE, payload });

// REDUCERS
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MESSAGE:
      return { ...state, message: action.payload };
    case REMOVE_MESSAGE:
      return {
        ...state,
        message: '',
      };
    default:
      return state;
  }
}
