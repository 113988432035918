/* eslint-disable indent */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import axios from 'axios';
import application_id_screenshot from 'images/screenshots/application_id.png';
import billing_reference_screentshot from 'images/screenshots/billing_reference.png';
import Modal from './Modal';

class ModalContent extends Component {
  source = axios.CancelToken.source();

  state = {
    error: '',
    disabled: true,
    reason: '',
    message: '',
    txId: '',
    apiError: '',
    weimarkApplicationId: '',
    txIdEntered: false,
    submitting: false,
    success: false,
  };

  handleOverride = (evt) => {
    evt.preventDefault();
    this.setState({ submitting: true });

    const { lease_id, client_id } = this.props;
    const { reason: reason_type, message: reason_message } = this.state;

    axios
      .post(
        '/api/v1/leases.credit_reports.override',
        {
          lease_id,
          client_id,
          reason_type,
          reason_message,
        },
        { headers: { API_TOKEN: localStorage.getItem('apiToken') } },
        { cancelToken: this.source.token },
      )
      .then((res) => {
        this.setState({
          txId: res.data.credit_report.transaction_id,
          submitting: false,
        });
      })
      .catch((err) => {
        this.setState({ submitting: false });
        if (err.response && err.response.data) {
          this.setState({ apiError: err.response.data.error.friendlyMessage });
        }
      });
  };

  handleTxIdEntered = () => {
    this.setState({ txIdEntered: true });
  };

  handleWeimarkLink = (evt) => {
    evt.preventDefault();
    this.setState({ submitting: true });

    const { client_id } = this.props;
    const {
      txId: transaction_id,
      weimarkApplicationId: application_number,
    } = this.state;

    axios
      .post(
        '/api/v1/leases.credit_reports.link',
        {
          client_id,
          transaction_id,
          application_number,
        },
        {
          headers: { API_TOKEN: localStorage.getItem('apiToken') },
        },
      )
      .then((res) => {
        this.setState({ submitting: false });
        if (res.data.ok) {
          this.setState({ success: true, apiError: '' });
        }
      })
      .catch((err) => {
        console.dir(err);
        this.setState({ submitting: false });
        if (err.response && err.response.data) {
          this.setState({
            success: false,
            apiError: err.response.data.error.friendlyMessage,
          });
        }
      });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { client_name } = this.props;
    const {
      apiError,
      submitting,
      reason,
      message,
      txId,
      weimarkApplicationId,
      txIdEntered,
      success,
    } = this.state;

    return (
      <div className="container invite-container">
        <h1>Link Weimark Credit Report</h1>
        <hr className="entire-width" />
        {success && (
          <div className="text-center m-4">
            <b>You have successfully linked weimark credit report for {client_name}!</b>
          </div>
        )}
        {txIdEntered && !success && (
          <form onSubmit={this.handleWeimarkLink}>
            {apiError && <p className="error text-center">{apiError}</p>}
            <p>Please enter the Weimark Application ID:</p>
            <input
              className="general-text-field mb-3"
              name="weimarkApplicationId"
              value={weimarkApplicationId}
              onChange={this.handleChange}
            />
            <img className="img-fluid screenshot" src={application_id_screenshot} />
            <input
              type="submit"
              value="Submit"
              className="button btn-black-white btn-rounded mt-3"
              disabled={!weimarkApplicationId}
            />
          </form>
        )}
        {txId && !txIdEntered && !success && (
          <div>
            <p>
              Please enter the lease transaction number below in the Weimark &quot;Billing
              Reference&quot; column:
            </p>
            <h4 className="text-center m-4">
              <b>{txId}</b>
            </h4>
            <img className="img-fluid screenshot" src={billing_reference_screentshot} />
            <button
              type="button"
              className="button btn-rounded btn-yellow-black btn-2x"
              onClick={this.handleTxIdEntered}
            >
              Continue
            </button>
          </div>
        )}
        {!txId && !txIdEntered && !success && (
          <form onSubmit={this.handleOverride}>
            {apiError && <p className="error text-center">{apiError}</p>}
            <label>Please select the reason you are overriding a Credit Report:</label>
            <select
              className="custom-select mb-3"
              onChange={this.handleChange}
              name="reason"
              value={reason}
            >
              <option value="">Select a type</option>
              <option value="other">Other</option>
            </select>
            {reason === 'other' && (
              <textarea name="message" value={message} onChange={this.handleChange} />
            )}
            {reason && message && (
              <div className="warning">
                By clicking continue below, {client_name} will be charged for credit report.
              </div>
            )}
            <input
              type="submit"
              value="Continue"
              className="button btn-black-white btn-rounded mt-3"
              disabled={submitting || !reason || !message}
            />
          </form>
        )}
      </div>
    );
  }
}

export default class CreditReportOverrideModal extends Component {
  state = {
    modalIsOpen: false,
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
    window.location.reload();
  };

  render() {
    const { className, children } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <div className={className}>
        <div onClick={this.openModal}>{children}</div>
        <Modal
          className="Modal__Bootstrap modal-dialog dark-gray-container invite-modal"
          closeTimeoutMS={150}
          isOpen={modalIsOpen}
        >
          <div className="modal-content modal-content-dark">
            <button type="button" className="close" onClick={this.closeModal}>
              <i className="nookons-close-circle-fill" />
            </button>
            <ModalContent {...this.props} />
          </div>
        </Modal>
      </div>
    );
  }
}
