import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConfirmListing from 'components/renterForms/steps/ConfirmListing';
import ConfirmAgent from 'components/renterForms/steps/ConfirmAgent';
import ContainerWrapper from 'components/lease/ContainerWrapper';
import loggedIn from 'helpers/loggedIn';
import { setMessage } from 'reducers/flashMessage';
import fetchListing, { handleApplyingForLease, fetchAgent } from 'components/renterForms/api';
import { getParamsFromLink } from 'helpers/util';
import { fetchAgentList } from 'api/agents';

// Props:
// --------------------------------------------------
// property: Property object (only needs to pass id) (Required)
// setFlashMessage: Function for setting flash message on page (Brought in through Redux)

const RenterApply = props => {
  const {
    property,
    setFlashMessage,
  } = props;

  const [loading, setLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(false);
  const [applyingProperty, setApplyingProperty] = useState(undefined);
  const [referringAgent, setReferringAgent] = useState(undefined);
  const [agents, setAgents] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [allowed, setAllowed] = useState(false);

  const TOTAL_STEPS = 2;

  // Fetches if user is logged in anf information on listing
  useEffect(() => {
    loggedIn()
      .then(res => {
        setSignedIn(res);
        fetchAgentList()
        .then(res => {
          setAgents(res);
          fetchListing(property.id).then(listing => {
            setApplyingProperty(listing);
          });
        })
      });
  }, []);

  // Double Checks if property can be applied to and if user signed in
  useEffect(() => {
    if (applyingProperty) {
      setLoading(false);
      if (signedIn && applyingProperty.acceptsApply) {
        const params = getParamsFromLink();
        if (params['sales-agent'] !== undefined) {
          fetchAgent(params['sales-agent'])
            .then(res => {
              setReferringAgent(res.agent);
              setAllowed(true);
            })
            .catch(res => {
              console.log(`Sales Agent not Found: ${res}`);
              setReferringAgent(applyingProperty.salesAgent);
              setAllowed(true);
            });
        } else {
          setReferringAgent(applyingProperty.salesAgent);
          setAllowed(true);
        }
      } else {
        setFlashMessage(
          'Please signup/login to your account before submitting your application.',
        );
      }
    }
  }, [applyingProperty, signedIn]);

  // Handles applying for lease
  const handleSubmit = incomingAgent => {
    const salesAgentId = incomingAgent.id;
    const listingId = applyingProperty.id;

    handleApplyingForLease(salesAgentId, listingId)
      .then(res => {
        window.location = `/leases/${res.data.token}`;
      })
      .catch(err => {
        console.dir(err);
      });
  };

  const handlePagination = movement => {
    if ((pageNumber + movement) < 1) {
      setPageNumber(1);
    } else if ((pageNumber + movement) > TOTAL_STEPS) {
      setPageNumber(TOTAL_STEPS);
    } else {
      setPageNumber(pageNumber + movement);
    }
  };

  const handleConfirmListingContinue = () => {
    handlePagination(1);
  };

  const handleAgentContinue = incomingAgent => {
    handleSubmit(incomingAgent);
  };

  return (
    <ContainerWrapper entered={!loading} className="">
      <>
        {
          (pageNumber === 1 && allowed) && (
            <ConfirmListing
              onSubmit={handleConfirmListingContinue}
              property={applyingProperty}
            />
          )
        }
        {
          (pageNumber === 2 && allowed) && (
            <ConfirmAgent
              agents={agents}
              onSubmit={handleAgentContinue}
              salesAgent={referringAgent}
            />
          )
        }
      </>
    </ContainerWrapper>
  );
};

RenterApply.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.number,
  }),
  setFlashMessage: PropTypes.func.isRequired,
};

RenterApply.defaultProps = {
  property: {
    id: -1,
  },
};

const mapDispatchToProps = dispatch => ({
  setFlashMessage(message) {
    dispatch(setMessage(message));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(RenterApply);
