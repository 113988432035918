import React from 'react';
import PropTypes from 'prop-types';
import missing from 'images/logo/white-on-black-wide.png';

const RoomCardImage = props => {
  const {
    image,
    room,
    url,
  } = props;

  const {
    no_fee: noFee,
    platform_listing: platformListing,
    address,
  } = room || {};

  return (
    <a href={url}>
      <div className="listing-card-img-container">
        <img className="nklyn-listing-card-apartment-img" src={image.wide} alt={address} onError={e => { e.target.onerror = null; e.target.src = missing; }} />
        <div className="nooklyn_listing_tag">
          {noFee ? (
            <div className="nooklyn_listing_square_fee mr-1">No Fee</div>
          ) : (
            ''
          )}
          {platformListing ? (
            <div style={{ backgroundColor: 'rgb(179,147,196)' }} className="nooklyn_listing_square_room">
              Sublet
            </div>
          ) : (
            <div style={{ backgroundColor: 'rgb(220,73,55)' }} className="nooklyn_listing_square_room">
              Room
            </div>
          )}
        </div>
      </div>
    </a>
  );
};

export default RoomCardImage;
