import React from 'react';
import Sidebar from './Sidebar';
import HomeGreeting from './HomeGreeting';

const ListingSplitView = (props) => {
  const { firstName, children } = props;

  return children ? (
    <div className="pro-container">
      <div className="pro-header" style={{ cursor: 'pointer' }}>
        <HomeGreeting firstName={firstName} />
        <div className="pro-nav">
          <a href="/create/listing">
            <i className="nookons-add nkyln-2x" />
            New Apartment
          </a>
        </div>
      </div>
      <div className="row no-gutters" style={{ minHeight: 'calc(100vh - 200px)' }}>
        <div className="col-md-3 sidebar">
          <Sidebar />
        </div>
        <div className="col-md-9">
          <div className="details-container" style={{ height: '100%' }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  ) : (
    null
  );
};

export default ListingSplitView;
