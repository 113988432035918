import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AgentSelector from 'components/AgentSelector';

// Props:
// --------------------------------------------------
// onSubmit: Callback Function on Submit (Required)
// salesAgent: Current Agent for Listing (Required)

const ConfirmAgent = props => {
  const {
    agents,
    onSubmit,
    salesAgent,
  } = props;

  const [changeAgent, setChangeAgent] = useState(false);
  const [agent, setAgent] = useState(salesAgent);
  const [agentThumb, setAgentThumb] = useState(salesAgent?.avatarThumb);
  const [agentCard, setAgentCard] = useState(salesAgent.agentCard || { realEstateLicenseNumber: '' });

  const handleChangeAgent = () => {
    setChangeAgent(true);
  };

  const handleAgent = passedAgentList => {
    if (!passedAgentList[0]) return;
    const selectedAgent = passedAgentList[0];
    const newAgent = agents.find(a => a.id == selectedAgent[0]);

    setAgent(newAgent);
    setAgentThumb(newAgent.avatarThumb);
    setAgentCard(newAgent.agentCard);
  };

  return (
    <div>
      <h5 className="apply-steps">Confirm Agent</h5>
      <h1>
        Was
        {' '}
        <b>{agent.firstName}</b>
        {' '}
        your agent?
      </h1>
      <div className="agent-table row mx-auto">
        <div className="col-4 agent-image">
          <img
            alt="sales agent"
            src={agentThumb}
            className="img-fluid rounded-circle mx-auto"
            width="86px"
            height="86px"
          />
        </div>
        <div className="col-8 agent-cell">
          <p>
            {agent.firstName}
            {' '}
            {agent.lastName}
          </p>
          {agent.licensedAs && (
            <p>
              Licensed as:
              {' '}
              {agent.licensedAs}
            </p>
          )}
          {agentCard.realEstateLicenseNumber !== '' && (
            <p>
              Licensed #:
              {' '}
              {agentCard.realEstateLicenseNumber}
            </p>
          )}
          {agent.email && (
            <p>
              Email:
              {' '}
              {agent.email}
            </p>
          )}
        </div>
      </div>
      <button
        type="button"
        className="button btn-rounded btn-next"
        onClick={() => { onSubmit(agent); }}
      >
        Yes
      </button>
      <div
        className="btn-no"
        role="button"
        tabIndex="0"
        onClick={handleChangeAgent}
        onKeyDown={evt => {
          if (evt.key === 'Enter') {
            handleChangeAgent();
          }
        }}
      >
        No,
        {' '}
        <b>it was someone else</b>
        .
      </div>
      <div style={{ textAlign: 'center', marginTop: '12px'}}>
      {changeAgent
        && (
          <>
            <AgentSelector
              onUpdate={handleAgent}
              initSelected={[[salesAgent.id, `${agent.firstName} ${agent.lastName}`]]}
              singleSelect
            />
            {/* <AgentSelector
            onUpdate={res => { console.log(res); }}
            initSelected={[[salesAgent.id, `${agent.firstName} ${agent.lastName}`]]}
            button
          /> */}
          </>
          )}
      </div>
    </div>
  );
};

ConfirmAgent.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.shape({})),
  onSubmit: PropTypes.func.isRequired,
  salesAgent: PropTypes.shape({
    agentCard: PropTypes.shape({
      realEstateLicenseNumber: PropTypes.string,
    }),
    avatarThumb: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.number,
    lastName: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
};

export default ConfirmAgent;
