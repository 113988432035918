/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import s from './FilterCheckbox.module.css'

function FilterCheckbox({ item, selected, handleSelect, radioStyle }) {
  return (
    <div className={clsx(s.root, radioStyle && s.isRadio)} onClick={() => handleSelect(item.id, item.secondId)}>
      <div role="button" tabIndex={0} data-name={item.id} className={clsx(s.checkbox, selected && s.checkboxSelected)}>
        <span className={clsx(s.mark, selected && s.markSelected)} />
      </div>
      <span className={s.stopName}>{item.name}</span>
    </div>
  )
}

FilterCheckbox.defaultProps = {
  radioStyle: false,
}

FilterCheckbox.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    secondId: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
  radioStyle: PropTypes.bool,
}

export default FilterCheckbox
