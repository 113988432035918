import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import InputMask from 'react-input-mask';
import strftime from 'strftime';
import { ButtonSave, ButtonStepper } from '../Buttons';
import ContainerWrapper from '../ContainerWrapper';

const title = "What's the move in day?";

function getMoveOutDate(date) {
  return strftime(
    '%m/%d/%Y',
    new Date(new Date(date).setFullYear(new Date(date).getFullYear() + 1)),
  );
}

const MoveInDay = (props) => {
  const {
    dateAvailable,
    setDateAvailable,
    userIsEditing,
    setMoveOutDate,
    setUserIsEditing,
  } = props;
  const datepickerRef = useRef(null);

  useEffect(() => {
    $(ReactDOM.findDOMNode(datepickerRef.current)).pickadate({
      format: 'mm/dd/yyyy',
      min: true,
      onSet() {
        const val = this.get('select', 'mm/dd/yyyy');
        setDateAvailable(val);
        setMoveOutDate(getMoveOutDate(val));
      },
    });
  }, []);

  useEffect(() => {
    document.title = `Create: Apartments - ${title}`;
  }, []);

  return (
    <ContainerWrapper>
      <div>
        <div className="title">
          <h3>{title}</h3>
          <p>When is the first day someone can move in.</p>
        </div>
        <form
          className="narrow"
          onKeyDown={(evt) => {
            if (evt.which === 13) evt.preventDefault();
          }}
        >
          <div className="row">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text general-text-field-dark">
                  <i className="nookons-calendar" />
                  <div id="date-available-picker" />
                  <input
                    ref={datepickerRef}
                    className="datepicker general-text-field picker__input"
                    type="text"
                    value={dateAvailable}
                    readOnly
                  />
                </span>
              </div>
              <InputMask
                mask="99/99/9999"
                className="form-control general-text-field-dark"
                value={dateAvailable}
                onChange={(evt) => {
                  setDateAvailable(evt.target.value);
                  setMoveOutDate(getMoveOutDate(evt.target.value));
                }}
              />
            </div>
          </div>
          {userIsEditing ? (
            <ButtonSave
              handleJump={props.handleJump}
              setUserIsEditing={setUserIsEditing}
              jumpTo={props.jumpTo || 'ApartmentReview'}
            />
          ) : (
            <ButtonStepper
              handleNext={props.handleNext}
              handleBack={props.handleBack}
              disabled={!/(19|20)\d{2}/i.test(dateAvailable)}
            />
          )}
        </form>
      </div>
    </ContainerWrapper>
  );
};

export default MoveInDay;
