import React, { Component } from 'react';
import { Column } from 'wrappers/FlexTable';
import { connect } from 'react-redux';
import { setStore, generateNewSearch } from 'reducers/search';

class IndividualNoFee extends Component {

  onMouseDown = (e) => {
    e.preventDefault();
    this.toggleSelect();
  }

  toggleSelect = () => {
    if (!this.props.selected) {
      this.props.setStore({ noFeeOnly: this.props.toggler });
      this.props.generateNewSearch();
    }
  }

  render () {
    const { val, selected } = this.props;
    return (
      <Column className={ selected ? 'paint-layer bed-div bed-div-selected' : ' paint-layer bed-div' } onMouseDown={this.onMouseDown}>
        {val}
      </Column>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selected = ownProps.toggler === state.search[state.search.context].noFeeOnly;
  return {
    selected: selected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setStore: (payload) => dispatch(setStore(payload)),
  generateNewSearch: () => dispatch(generateNewSearch())
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualNoFee);
