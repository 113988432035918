import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BedPop from '../searchPopovers/Bed';
import BedButton from '../sentenceSearchButtons/BedButton';
import Reposition from '../searchBarComponents/Reposition';

class Bed extends Component {
  render() {
    const { popoverWrapper, popoverContainer, popover } = this.props;
    return (
      <div
        ref={popoverContainer}
        className="button btn-transparent"
        id="listing-beds-selector"
        style={{ border: 'none' }}
      >
        <BedPop
          ref={{
            popoverWrapper,
            popover,
          }}
          displayProp="sentenceBedDisplay"
          searchContext="sentenceSearch"
        />
        <BedButton />
      </div>
    );
  }
}

Bed.defaultProps = {
  popoverWrapper: null,
  popoverContainer: null,
  popover: null,
};

Bed.propTypes = {
  popoverWrapper: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popoverContainer: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popover: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

export default Reposition(Bed);
