import React from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'helpers/util';

const IndividualSubwayStop = props => {
  const {
    id,
    subwayStop,
    lineSelected,
    handleSelect,
    selected,
  } = props;

  const {
    selectedSubwayStops,
  } = selected;

  const checked = selectedSubwayStops.some(selectedSubwayStop => (
    selectedSubwayStop.id === id
  )) || lineSelected === true;

  return (
    <div
      className="individual-subway-stop p-1 d-flex align-items-center"
      data-id={id}
    >
      <div
        role="button"
        tabIndex={0}
        data-name={id}
        style={{ cursor: 'pointer' }}
        className={`${checked ? 'matrix-checkbox-checked' : 'matrix-checkbox'} d-flex`}
        onMouseDown={() => handleSelect(id, 'stops')}
      >
        <span className={checked ? 'checkmark' : ''} />
      </div>
      <div className="d-flex" data-name={subwayStop.name} style={{ userSelect: 'none' }}>
        <div style={{ fontWeight: '500', fontSize: '12px', paddingLeft: '10px' }}>{truncate(subwayStop.name, 32)}</div>
      </div>
    </div>
  );
};

IndividualSubwayStop.propTypes = {
  id: PropTypes.string.isRequired,
  subwayStop: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  lineSelected: PropTypes.bool.isRequired,
  selected: PropTypes.shape({
    selectedSubways: PropTypes.arrayOf(PropTypes.shape({})),
    selectedSubwayStops: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
  }).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default IndividualSubwayStop;
