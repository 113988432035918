import React, { useEffect, useState } from 'react';
import { QRCode } from 'react-qr-svg';
import axios from 'axios';
import PhoneNumber from 'components/PhoneNumber';

const startIdVerificationSession = (apiToken, cancelToken) => {
  return axios.post('/api/v2/user.state_id.start', {}, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(res => res.data);
};

const textIdVerificationLink = (idVerificationToken, phoneNumber, apiToken, cancelToken) => (
  axios
    .post(
      '/api/v2/user.state_id.text_link',
      { id_verification_token: idVerificationToken, phone_number: phoneNumber },
      {
        headers: { API_TOKEN: apiToken },
        cancelToken,
      },
    )
);

const checkVerificationStatus = (agentId, verificationToken, cancelToken, handleStateIdApproved, setPhotoIdStatus) => {
  axios.get(`/agents/${agentId}/id_verifications/${verificationToken}.json `, { cancelToken })
    .then(res => {
      if (res.data.status === false) {
        setPhotoIdStatus('completed');
        handleStateIdApproved();
      }
    });
};

const VerifyPhotoId = props => {
  const { agentId, apiToken, handlePhontTextSent, handleStateIdApproved, setPhotoIdStatus, setPhoneNumber } = props;
  const cancelSource = axios.CancelToken.source();

  const [verificationSession, setVerificationSession] = useState({ token: null, url: null });
  const [message, setMessage] = useState('Loading...');

  useEffect(() => {
    startIdVerificationSession(apiToken, cancelSource.token)
      .then(({ token, session_url: url }) => {
        setVerificationSession({ token, url });
        setMessage(null);
      })
      .catch(result => {
        const { response: { data } } = result;
        const { error: { detailedMessage, friendlyMessage } } = data;

        setMessage(`Error: ${detailedMessage}. ${friendlyMessage}`);
      });

    return () => {
      cancelSource.cancel('Modal was closed');
    };
  }, []);

  useEffect(() => {
    let intervalId = null;

    if (verificationSession.token) {
      intervalId = setInterval(checkVerificationStatus, 5000, agentId, verificationSession.token, cancelSource.token, handleStateIdApproved, setPhotoIdStatus);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [agentId, verificationSession.token]);

  const handleSubmit = evt => {
    evt.preventDefault();

    const phoneNumber = evt.target.phoneNumber.value;

    textIdVerificationLink(verificationSession.token, phoneNumber, apiToken, cancelSource.token)
      .then(result => {
        if (result.data.ok) {
          setPhoneNumber(phoneNumber);
          setPhotoIdStatus('pending');
          handlePhontTextSent();
        }
      })
      .catch(result => {
        const { response: { data } } = result;
        const { error: { friendlyMessage } } = data;

        setMessage(friendlyMessage);
      });
  };

  return verificationSession.token ? (
    <div className="container id-verification-modal">
      <div className="title">
        Verify with photo ID
      </div>
      <form onSubmit={handleSubmit}>
        <div className="description-notes">
          Scan this QR code with your smart device to open the verification camera
        </div>
        {verificationSession && verificationSession.url
          && (
            <div className="qr-code-canvas">
              <QRCode value={verificationSession.url} className="qr-code" />
            </div>
          )}
        <p className="or">or</p>
        <div className="description-notes">
          Enter your phone number so we can text you a link
        </div>
        <div className="input-group">
          <PhoneNumber
            name="phoneNumber"
            placeholder="Your Cell Phone Number"
            className="general-text-field mt-2 mb-4"
          />
          <div className="input-group-append">
            <input
              type="submit"
              name="commit"
              value="Send me the link"
            />
          </div>
        </div>
      </form>
      {message && <div className="text-center mx-auto mb-3">{message}</div>}
      <div className="description-notes text-muted">
        We do <b style={{ fontStyle: 'italic'}}>not</b> save your phone number. We use it only to text you a link.
      </div>
    </div>
  ) : (
    <div className="container id-verification-modal">
      <div className="title">
        Verify with photo ID
      </div>
      {message && <div className="text-center mx-auto mb-3">{message}</div>}
    </div>
  );
};

export default VerifyPhotoId;
