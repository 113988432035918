import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { initializeAgents, initializeSelectedAgents } from 'reducers/agentTypeahead';
import AgentTeamTypeahead from '../agentTypeaheadComponents/AgentTeamTypeahead';

class LeaseSalesAgentModalContent extends Component {
  source = axios.CancelToken.source();

  state = { salesAgents: [], error: '' };

  componentDidMount() {
    const lease_id = this.props.id;
    const type = 'sales_agent';

    axios
      .get(
        '/api/v1/leases.agents.list',
        { params: { lease_id, type, include_deleted: true } },
        {
          headers: { API_TOKEN: localStorage.getItem('apiToken') },
          cancelToken: this.source.token,
        }
      )
      .then(res => {
        const { _initializeSelectedAgents, _initializeAgents } = this.props;
        this.setState({ salesAgents: res.data.lease_agents });
        _initializeSelectedAgents(res.data.lease_agents.map(agent => agent.id));
        _initializeAgents([]);
      })
      .catch(err => console.dir(err));
  }

  componentWillUnmount() {
    this.source.cancel('api call cancelled as component will unmount');
  }

  handleAddAgent = agent => {
    const { salesAgents } = this.state;
    if (!agent) return;
    const { id: lease_id, updateSalesAgentOnModalClose } = this.props;
    const agent_id = agent.value;
    const type = 'sales_agent';
    if (!salesAgents.some(agent => agent.id == agent_id))
      axios
        .post(
          '/api/v1/leases.agents.add',
          { lease_id, agent_id, type },
          { headers: { API_TOKEN: localStorage.getItem('apiToken') } },
          { cancelToken: this.source.token }
        )
        .then(res => {
          if (res.data.sales_agent) {
            this.setState(state => {
              if (state.salesAgents) {
                return {
                  salesAgents: [...state.salesAgents, res.data.sales_agent],
                  error: '',
                };
              }
              return { salesAgents: [res.data.sales_agent], error: '' };
            });
          }
          updateSalesAgentOnModalClose();
        })
        .catch(err => {
          if (err.response && err.response.data) {
            this.setState({ error: err.response.data.error.friendlyMessage });
          }
        });
  };

  handleRemoveAgent = (evt, agent_id) => {
    let button;
    if (evt) {
      evt.preventDefault();
      button = evt.target;
      button.setAttribute('disabled', true);
    }
    const { id: lease_id, updateSalesAgentOnModalClose } = this.props;
    const type = 'sales_agent';

    axios
      .post(
        '/api/v1/leases.agents.remove',
        {
          lease_id,
          agent_id,
          type,
        },
        { headers: { API_TOKEN: localStorage.getItem('apiToken') } },
        { cancelToken: this.source.token }
      )
      .then(() => {
        if (button) button.removeAttribute('disabled');
        this.setState(({ salesAgents }) => {
          const newSalesAgents = salesAgents.map((agent) => {
            if (agent.id === agent_id) {
              return { ...agent, deleted: true };
            }
            return agent;
          });
          return {
            salesAgents: newSalesAgents,
            error: '',
          };
        });
        updateSalesAgentOnModalClose();
      })
      .catch(err => {
        if (button) button.removeAttribute('disabled');
        if (err.response && err.response.data) {
          this.setState({ error: err.response.data.error.friendlyMessage });
        }
      });
  };

  handleRestoreAgent = (evt, agent_id) => {
    let button;
    if (evt) {
      evt.preventDefault();
      button = evt.target;
      button.setAttribute('disabled', true);
    }
    const { id: lease_id, updateSalesAgentOnModalClose } = this.props;

    axios
      .post(
        '/api/v1/leases.agents.restore',
        {
          lease_id,
          agent_id,
        },
        {
          headers: { API_TOKEN: localStorage.getItem('apiToken') },
          cancelToken: this.source.token,
        },
      )
      .then(() => {
        if (button) button.removeAttribute('disabled');
        this.setState(({ salesAgents }) => {
          const newSalesAgents = salesAgents.map((agent) => {
            if (agent.id === agent_id) {
              return { ...agent, deleted: false };
            }
            return agent;
          });
          return {
            salesAgents: newSalesAgents,
            error: '',
          };
        });
        updateSalesAgentOnModalClose();
      })
      .catch(err => {
        if (button) button.removeAttribute('disabled');
        if (err.response && err.response.data) {
          this.setState({ error: err.response.data.error.friendlyMessage });
        }
      });
  };

  render() {
    const { salesAgents, error } = this.state;

    return (
      <div className="container invite-container">
        <h1>Lease Sales Team</h1>
        <hr className="entire-width" />
        {error && <p className="error text-center">{error}</p>}
        <div className="row">
          {salesAgents &&
            !!salesAgents.length &&
            salesAgents.map(agent => (
              <div key={agent.id} className="col-6 col-sm-2">
                <img
                  className="lazy rounded-circle img-fluid mx-auto"
                  width="60px"
                  height="60px"
                  src={agent.avatar_thumb}
                />
                <div className="text-center m-2">{agent.full_name}</div>
                <button
                  type="button"
                  className="button btn-1x btn-red"
                  onClick={evt => agent.deleted ? this.handleRestoreAgent(evt, agent.id) : this.handleRemoveAgent(evt, agent.id)}
                >
                  {agent.deleted ? 'Restore' : 'Remove'}
                </button>
              </div>
            ))}
        </div>
        <AgentTeamTypeahead
          handleAddAgent={this.handleAddAgent}
          handleRemoveAgent={this.handleRemoveAgent}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  _initializeSelectedAgents: agentIds => dispatch(initializeSelectedAgents(agentIds, "salesTeamAgent")),
  _initializeAgents: agent => dispatch(initializeAgents(agent, "salesTeamAgent"))
});

export default connect(null, mapDispatchToProps)(LeaseSalesAgentModalContent);
