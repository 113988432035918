import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SliderHeader from 'components/NotSoSlickSlider/SliderHeader';
import SlideManager from 'components/NotSoSlickSlider/SlideManager';
import TouchWrapper from 'components/NotSoSlickSlider/TouchWrapper';
import ResizeWrapper from './ResizeWrapper';

const SPACER_SIZE = 6;

// Props:
// --------------------------------------------------
// childSize - Size of each slide (Required)
// headerAlignment - Alignment of Titles and Button: left, center (Optional) (Default: left)
// lgOffset - Size of lg offset(Optional) (Default: 95)
// mdOffset - Size of md offset(Optional) (Default: 35)
// propData - List of Ids to API Call (Required)
// seeALLBtnLin - Link for See All Button (Optional)
// smOffset - Size of sm offset(Optional) (Default: 15)
// slideOrientation - Alignment of Slides: Left, Center (Optional) (Default: Left)
// subtitle - Subtitle to Print (Optional)
// subtitleUrl - Url To Redirect (Optional)
// title - Title to Print (Optional)
// titleSize - Size of title: lg, md, sm (Optional) (Default: sm)
// type - Type of Items being Called: rooms or listings (Required) (Default: listings)

// Planned Fixes:
// --------------------------------------------------
// Aria Hidden used in Touch Wrapper & Slides to be replaced with keypress events and better tabbing
// Invalid option error in ruby

// NotSoSlickSlider:
// Slick Slider had to go
const NotSoSlickSlider = props => {
  const {
    childSize,
    headerAlignment,
    lgOffset,
    mdOffset,
    propData,
    seeALLBtnLink,
    slideOrientation,
    smOffset,
    subtitle,
    subtitleUrl,
    title,
    titleSize,
    type,
  } = props;

  // Setting Async Needed State Vars
  const [isLoading, setIsLoading] = useState(true);

  // Setting States for Responsive Sizing
  const [sliderLength, setSliderLength] = useState(0);
  const [needSlider, setNeedSlider] = useState(true);
  const [sliderDimensions, setSliderDimensions] = useState({
    offset: 0,
    width: 0,
  });
  const [slideCount, setSlideCount] = useState(0);

  // Setting Slide Tracking States
  const [translate, setTranslate] = useState(0);
  const [transition, setTransition] = useState('');
  const [animating, setAnimating] = useState(false);
  const [animation, setAnimation] = useState('transform ease-out 0.5s');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [firstClick, setFirstClick] = useState(true);
  const slideTransitionRef = useRef(null);

  // Sets Slider active currentIndex to show current page that has # of slides of slideCount
  const setSlide = () => {
    const seeAllBtn = ((seeALLBtnLink !== '') && seeALLBtnLink);
    const wholeSlideSize = (childSize + SPACER_SIZE);
    const {
      offset,
      width,
    } = sliderDimensions;

    setAnimation('transform ease-out 0.5s');

    // Calculates Margin needed to flush slides to title
    let titleAlign = 0;

    if (slideOrientation === 'center') {
      titleAlign = ((width - (wholeSlideSize * slideCount)) / 2);
    } else {
      titleAlign = offset;
    }

    // Used to recalculate currentIndexes on resize
    if (slideCount !== sliderLength) {
      let resizeCurrentIndex = 0;
      if ((currentIndex % slideCount) <= Math.round(0.5 * (slideCount))) {
        resizeCurrentIndex = currentIndex - (currentIndex % slideCount);
      } else {
        resizeCurrentIndex = currentIndex + (slideCount - (currentIndex % slideCount));
      }

      setCurrentIndex(resizeCurrentIndex);
      setSliderLength(slideCount);
    }

    // Calculates how long our slider is with the new empty spaces and/or see all btn
    // If more than one slide:
    // Array length + SeaAllBtn + (slideCount - ((propData.length + (seeAllBtn ? 1 : 0)) % slideCount)
    // else:
    // Array length + SeaAllBtn
    const totalSlideLength = (propData.length + (seeAllBtn ? 1 : 0))
      + ((slideCount > 1)
        ? (slideCount - ((propData.length + (seeAllBtn ? 1 : 0)) % slideCount))
        : 0);

    let newSlide;

    // Cases:
    // Case 0: No Slider
    // Case 1: On Next Transition
    // Case 2: On Prev Transition
    // Case 3: On Init
    if (needSlider === false) {
      // Case 0
      setCurrentIndex(0);
      setTranslate(titleAlign);
      setTransition('');
    } else if (transition === 'next') {
      setAnimating(true);

      // Case 1
      setTranslate(-((wholeSlideSize * (3 * slideCount)) - titleAlign));

      if ((currentIndex) > (totalSlideLength - slideCount - 1)) {
        newSlide = 0;
      } else {
        newSlide = (currentIndex + slideCount);
      }
    } else if (transition === 'prev') {
      setAnimating(true);

      // Case 2
      setTranslate(-((wholeSlideSize * (slideCount)) - titleAlign));

      if ((currentIndex - slideCount) < 0) {
        newSlide = (totalSlideLength - slideCount);
      } else {
        newSlide = (currentIndex - slideCount);
      }
    } else {
      // Case 3
      setTranslate(-((wholeSlideSize * (2 * slideCount)) - titleAlign));
      setTransition('');
    }

    if (newSlide !== undefined && slideTransitionRef) {
      const resetSlides = () => {
        slideTransitionRef.current.removeEventListener('transitionend', resetSlides, { once: true });
        slideTransitionRef.current.removeEventListener('webkitTransitionEnd', resetSlides, { once: true });
        slideTransitionRef.current.removeEventListener('oTransitionEnd', resetSlides, { once: true });
        setAnimation('');
        setTranslate(-((wholeSlideSize * (2 * slideCount)) - titleAlign));
        setCurrentIndex(newSlide);
        setTransition('');
        setAnimating(false);
      };
      slideTransitionRef.current.addEventListener('transitionend', resetSlides, { once: true });
      slideTransitionRef.current.addEventListener('webkitTransitionEnd', resetSlides, { once: true });
      slideTransitionRef.current.addEventListener('oTransitionEnd', resetSlides, { once: true });
    }
  }; // end setSlide

  // Updates slides on transition state update
  useEffect(() => {
    if (transition === 'next' || transition === 'prev') {
      // Disables firstClick after moving slider for first time - this shows slides in beginning
      if (firstClick === true) {
        setFirstClick(false);
      }
      if (animating !== true) {
        setSlide();
      }
    }
  }, [transition, needSlider]);

  useEffect(() => {
    setSlide();
  }, [slideCount, needSlider, sliderDimensions]);

  // Dynamic Styling Applied to Slides
  const style = {
    transform: `translateX(${translate}px)`,
    transition: animation,
    height: '100%',
    width: 'fit-content',
    display: 'flex',
    padding: '0px 1px',
  };

  // Prints out listing or loading screen depending on whether data is loaded yet
  // Slider Header - Holds our Title, Subtitle, and Buttons
  // Not So Slick Touch Wrapper - Handles all touch events
  // Not So Slick Slides - Prints out all of our slides
  return (
    <ResizeWrapper
      childSize={childSize}
      offsets={{
        smOffset,
        mdOffset,
        lgOffset,
      }}
      sliderWidth={sliderDimensions.width}
      propData={propData}
      seeAllBtn={((seeALLBtnLink !== '') && (seeALLBtnLink !== undefined))}
      setNeedSlider={i => { setNeedSlider(i); }}
      setSliderDimensions={i => { setSliderDimensions(i); }}
      setSlideCount={i => { setSlideCount(i); }}
      slideOrientation={slideOrientation}
      slideSize={childSize + SPACER_SIZE}
    >
      <SliderHeader
        firstClick={firstClick}
        headerAlignment={headerAlignment}
        isLoading={isLoading}
        needSlider={needSlider}
        setTransition={i => { setTransition(i); }}
        subtitle={subtitle}
        subtitleUrl={subtitleUrl}
        title={title}
        titleSize={titleSize}
        sliderOffset={sliderDimensions.offset}
      />
      <TouchWrapper
        onTransition={i => { setTransition(i); }}
        childSize={childSize}
      >
        <div style={style} ref={slideTransitionRef}>
          <SlideManager
            childSize={childSize}
            currentIndex={currentIndex}
            firstClick={firstClick}
            onLoad={() => { setIsLoading(false); }}
            onTransition={i => { setTransition(i); }}
            propData={propData}
            seeALLBtnLink={seeALLBtnLink}
            sliderLength={sliderLength}
            transition={transition}
            type={type}
          />
        </div>
      </TouchWrapper>
    </ResizeWrapper>
  );
};

NotSoSlickSlider.propTypes = {
  childSize: PropTypes.number.isRequired,
  headerAlignment: PropTypes.string,
  lgOffset: PropTypes.number,
  mdOffset: PropTypes.number,
  propData: PropTypes.arrayOf(PropTypes.number).isRequired,
  seeALLBtnLink: PropTypes.string,
  slideOrientation: PropTypes.string,  smOffset: PropTypes.number,
  subtitle: PropTypes.string,
  subtitleUrl: PropTypes.string,
  title: PropTypes.string,
  titleSize: PropTypes.string,
  type: PropTypes.string,
};

// Offset default values pulled from responsive css spacing done in nooklyn homepage
NotSoSlickSlider.defaultProps = {
  headerAlignment: 'left',
  lgOffset: 80,
  mdOffset: 35,
  seeALLBtnLink: '',
  slideOrientation: 'left',
  smOffset: 15,
  subtitle: '',
  subtitleUrl: '',
  title: '',
  titleSize: 'sm',
  type: 'Listings',
};

export default NotSoSlickSlider;
