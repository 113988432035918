// ToDo: Implementation of subway photos needs to be reworked in next update.
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IndividualSubwayStop from './IndividualSubwayStop';

const IndividualSubway = props => {
  const [showStops, setShowStops] = useState(false);

  const {
    subwayLine,
    selected,
    handleSelect,
  } = props;

  const {
    selectedSubwayStops,
    selectedSubways,
  } = selected;

  const selectedLineStops = selectedSubwayStops
    .filter(selectedSubwayStop => selectedSubwayStop.lines.includes(subwayLine.line));
  const lineSelected = selectedSubways
    .filter(_subwayLine => _subwayLine.line === subwayLine.line).length > 0;
  const partiallySelected = selectedLineStops.length > 0;

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.currentTarget.click();
    }
  };

  return (
    <>
      <div className="d-flex position-relative">
        <div
          role="button"
          tabIndex={0}
          data-name={subwayLine.line}
          className={`${lineSelected || partiallySelected ? 'matrix-checkbox-checked' : 'matrix-checkbox'} mt-2`}
          onMouseDown={() => handleSelect(subwayLine.line, 'lines')}
        >
          <span className={lineSelected ? 'checkmark' : ''} />
          <span className={partiallySelected ? 'partial-checkmark ' : ''} />
        </div>
        <div
          style={{ cursor: 'pointer', userSelect: 'none', margin: '3px 8px' }}
        >
          <img
            style={{ width: '30px' }}
            src={require(`images/subway/${subwayLine.line}.png`)}
            srcSet={`${require(`images/subway/2x/${subwayLine.line}.png`)} 1x, ${require(`images/subway/2x/${subwayLine.line}.png`)} 2x`}
            alt={subwayLine.line}
          />
          {showStops
            && subwayLine.stops
            && subwayLine.stops.map(subwayStop => (
              <IndividualSubwayStop
                key={`${subwayLine.line}-${subwayStop.id}`}
                id={subwayStop.id}
                subwayStop={subwayStop}
                lineSelected={lineSelected}
                selected={selected}
                handleSelect={handleSelect}
              />
            ))}
        </div>
        { !showStops ? (
          <div
            role="button"
            tabIndex={0}
            style={{
              position: 'absolute', right: 0, margin: '8px 20px', cursor: 'pointer',
            }}
            onClick={() => setShowStops(true)}
            onKeyPress={handleKeyPress}
          >
            Show stops
            <i className="nookons-chevron-down ml-2" />
          </div>
        ) : (
          <div
            role="button"
            tabIndex={0}
            style={{
              position: 'absolute', right: 0, margin: '8px 20px', cursor: 'pointer',
            }}
            onClick={() => setShowStops(false)}
            onKeyPress={handleKeyPress}
          >
            Hide stops
            <i className="nookons-chevron-up ml-2" />
          </div>
        ) }
      </div>
      <hr style={{ margin: '5px 20px 5px 0' }} />
    </>
  );
};

IndividualSubway.propTypes = {
  subwayLine: PropTypes.shape({
    line: PropTypes.string,
    stops: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
  }).isRequired,
  selected: PropTypes.shape({
    selectedSubways: PropTypes.arrayOf(PropTypes.shape({})),
    selectedSubwayStops: PropTypes.arrayOf(PropTypes.shape({
      lines: PropTypes.arrayOf(PropTypes.string),
    })),
  }).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default IndividualSubway;
