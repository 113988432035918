import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { removeMessage } from '../../reducers/flashMessage';

const Flash = props => {
  const { message, removeMessage } = props;

  return !!message ? (
    <div className="alert">
      <button className="close" onClick={() => removeMessage(message)}>
        <i className="nookons-close-circle-fill pull-right" />
      </button>
      {message}
    </div>
  ) : null;
};

Flash.defaultProps = {
  message: '',
  removeMessage: null,
};

Flash.propTypes = {
  message: PropTypes.string,
  removeMessage: PropTypes.func,
};

const mapStateToProps = state => ({
  message: state.flashMessage.message,
});

const mapDispatchToProps = dispatch => ({
  removeMessage(message) {
    dispatch(removeMessage(message));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Flash);
