import React from 'react'

import PropTypes from 'prop-types'
import s from './Pets.module.css'
import FilterCheckbox from '../../common/FilterCheckbox'

function Pets(props) {
  const { pets, onSelect, options } = props

  return (
    <div className={s.root}>
      <div className={s.list}>
        {options?.map((pet) => {
          const isSelected = pets === pet.value

          return (
            <div className={s.listItem}>
              <FilterCheckbox
                radioStyle
                key={pet.value}
                handleSelect={onSelect}
                item={{
                  id: pet.value,
                  name: pet.name,
                }}
                selected={isSelected}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

Pets.propTypes = {
  pets: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default Pets
