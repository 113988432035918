import React from 'react';
import PropTypes from 'prop-types';

const LeadModerationButton = props => {
  const {
    listing,
    currentUser,
  } = props;

  const {
    id: listingId,
    network: listingNetwork,
  } = listing || {};

  const hasPlatformPermission = currentUser && currentUser.permissions && currentUser.permissions.includes('platform_moderator');
  const validNetwork = listingNetwork === 'platform' || listingNetwork === 'subway';

  return hasPlatformPermission && validNetwork ? (
    <a
      href={`/listings/${listingId}/lead_moderations`}
      className="button btn-transparent btn-block btn-rounded btn-block btn-search-bar-size"
    >
      Leads
    </a>
  ) : null;
};

LeadModerationButton.defaultProps = {
  currentUser: null,
};

LeadModerationButton.propTypes = {
  listing: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default LeadModerationButton;
