import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LikedPop from '../searchPopovers/Liked';
import LikedButton from '../sentenceSearchButtons/LikedButton';
import Reposition from '../searchBarComponents/Reposition';

class Liked extends Component {
  render() {
    const { popoverWrapper, popoverContainer, popover } = this.props;
    return (
      <div
        ref={popoverContainer}
        className="button btn-transparent"
        id="listing-no-fee-selector"
        style={{ border: 'none' }}
      >
        <LikedPop
          ref={{
            popoverWrapper,
            popover,
          }}
          displayProp="sentenceLikedDisplay"
          searchContext="sentenceSearch"
        />
        <LikedButton />
      </div>
    );
  }
}

Liked.defaultProps = {
  popoverWrapper: null,
  popoverContainer: null,
  popover: null,
};

Liked.propTypes = {
  popoverWrapper: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popoverContainer: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popover: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

export default Reposition(Liked);
