import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import LoadingSpinner from 'components/LoadingSpinner';
import { useGlobalState } from 'components/listRoom/customEffects';
import ContainerWrapper from '../ContainerWrapper';

const title = 'Time to Create Rooms!';

const Create = props => {
  const {
    setCreate, listingId, submitRoom, setGlobalState, fetchedImages,
  } = props;
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [description, setDescription] = useState('');
  const [rent, setRent] = useState('');
  const [selected, setSelected] = useState('');
  const source = axios.CancelToken.source();
  const [submitting, setSubmitting] = useState(false);
  const [selectImage, setSelectImage] = useState(true);
  const [photoUrl, setPhotoUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [roomId, setRoomId] = useState('');
  const [rooms, setRooms] = useState([]);
  let images = [];
  if (setGlobalState) [{ images }] = useGlobalState();
  if (fetchedImages) images = fetchedImages;

  const handleFileDrop = (acceptedFiles) => {
    if (!acceptedFiles.length) {
      return;
    }
    const acceptedFile = acceptedFiles[0]; // file can only be uploaded one at a time
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFile);

    reader.onload = (evt) => {
      setPhotoUrl(evt.target.result);
      setPreviewUrl(URL.createObjectURL(acceptedFile));
    };
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setSubmitting(true);

    const room = {
      listing_id: listingId,
      rent: +rent * 100,
      name,
      description,
      image_hash: selectImage ? selected : '',
    };

    axios
      .post(
        '/api/v2/rooms.create',
        {
          ...room,
        },
        { headers: { API_TOKEN: localStorage.getItem('apiToken') } },
        { cancelToken: source.token },
      )
      .then((res) => {
        setRoomId(res.data.rooms.id);
        if (!selectImage) {
          return axios.post(
            '/api/v2/rooms.images.upload',
            {
              room_id: res.data.rooms.id,
              url: photoUrl,
            },
            { headers: { API_TOKEN: localStorage.getItem('apiToken') } },
          );
        }

        return res;
      })
      .then(() => axios.get(`/api/v1/listings.rooms.list?listing_id=${listingId}&active_only=false`, {
        cancelToken: source.token,
      }))
      .then((res) => {
        setSubmitting(false);
        if (setGlobalState) {
          setGlobalState({ rooms: res.data.rooms });
          setCreate(false);
        }
        if (submitRoom) {
          setRooms(res.data.rooms);
        }
      })
      .catch((err) => {
        setSubmitting(false);

        const { response: { data: errData } = {} } = err;
        const { ok = false, error: { detailedMessage = 'An unexpected error occurred. Please contact hi@nooklyn.com' } } = errData;

        if (ok === false) {
          setErrorMessage(detailedMessage);
        }
      });
  };

  useEffect(() => {
    if (roomId && submitRoom && rooms.length) {
      submitRoom({ ok: true, room: rooms.find(r => +r.id === +roomId) });
    }
  }, [roomId, rooms]);

  useEffect(
    () => () => {
      source.cancel('Operation canceled');
    },
    [],
  );

  useEffect(() => {
    document.title = `Create: Rooms - ${title}`;
  }, []);

  const submitDisabled = !name || !description || !rent || (!selected && !photoUrl) || submitting;

  return (
    <ContainerWrapper>
      <form className="create-room-form" onSubmit={handleSubmit}>
        {!!images.length && (
          <>
            <div className="row">
              <label>
                <span
                  onClick={() => setSelectImage(true)}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  Select an image
                </span>
                {' '}
                from below or
                {' '}
                <span
                  onClick={() => setSelectImage(false)}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  upload a new image
                  {' '}
                </span>
                as cover image for the room.
              </label>
            </div>
            {selectImage ? (
              <div className="row">
                {images.map(image => (
                  <div className="col-4" key={image.id}>
                    <img
                      key={image.id}
                      src={image.thumb}
                      className="img-fluid uploading m-1"
                      onClick={() => {
                        setSelected(image.id);
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                    {selected === image.id && <i className="nookons-check-circle-fill nklyn-4x" />}
                  </div>
                ))}
              </div>
            ) : (
              <>
                <Dropzone onDrop={acceptedFiles => handleFileDrop(acceptedFiles)} multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="row mb-4">
                      <div {...getRootProps()} style={{ width: '100%' }}>
                        <input {...getInputProps()} />
                        <div className="photo-upload-container">
                          <i className="nklyn-3x nookons-camera" style={{top: 'auto'}} />
                          <p>Drop photos here or click the camera.</p>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <aside className="preview-container">
                  {previewUrl && (
                    <div className="preview-card">
                      <i
                        className="nookons-close nklyn-2x m-0"
                        onClick={() => {
                          setPhotoUrl('');
                          setPreviewUrl('');
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setPhotoUrl('');
                            setPreviewUrl('');
                          }
                        }}
                      />
                      <img src={previewUrl} className="img-fluid" />
                    </div>
                  )}
                </aside>
              </>
            )}
          </>
        )}
        <div className="row">
          <span style={{color: 'red' }}>{errorMessage}</span>
          <label htmlFor="room-name">
            Give the room a name
            <input
              value={name}
              type="text"
              id="room-name"
              className="general-text-field-dark"
              onChange={evt => setName(evt.target.value)}
            />
          </label>
        </div>
        <div className="row">
          <label className="mb-3" htmlFor="rent">
            What&apos;s the room&apos;s monthly rent?
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text general-text-field-dark">$</span>
              </div>
              <input
                value={rent}
                type="number"
                className="form-control general-text-field-dark"
                onChange={evt => setRent(evt.target.value)}
              />
            </div>
          </label>
        </div>
        <div className="row">
          <label htmlFor="description">
            Describe the room
            <textarea
              value={description}
              type="text"
              className="general-text-field-dark"
              onChange={evt => setDescription(evt.target.value)}
            />
          </label>
        </div>
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              className="button btn-transparent-yellow btn-rounded float-right"
              onClick={() => setCreate(false)}
            >
              Cancel
            </button>
          </div>
          <div className="col-6">
            <button
              className="button btn-transparent-yellow btn-rounded"
              type="submit"
              disabled={submitDisabled}
            >
              {submitting ? <LoadingSpinner /> : 'Done'}
            </button>
          </div>
        </div>
      </form>
    </ContainerWrapper>
  );
};

Create.propTypes = {
  setCreate: PropTypes.func.isRequired,
  listingId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  setGlobalState: PropTypes.func,
  submitRoom: PropTypes.func,
};

Create.defaultProps = {
  setGlobalState: null,
  submitRoom: null,
};

export default Create;
