import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class SortResultsByButton extends Component {

  componentDidMount() {
    this.node.addEventListener('click', (e) => {
      e.stopPropagation();
    });
    this.node.addEventListener('mouseup', (e) => {
      e.stopPropagation();
    });
  }

  render() {

    const { togglePopover, className } = this.props;

    return (
      <input
        ref={me => this.node = me}
        name='sortResultsBy'
        onMouseDown={togglePopover}
        type='button'
        className={`button btn-rounded ${className} btn-search-bar-size`}
        value='Sort results by'/>
    );
  }
}

const mapStateToProps = (state) =>{
  const selected = state.search[state.search.context].selectedSortResultsBy.length > 0 ? true : false;
  const className = selected ? 'btn-transparent-yellow' : 'btn-transparent-gray';
  return ({
    className: className
  });
};

const mapDispatchToProps = (dispatch) => ({
  togglePopover: (e) => dispatch(togglePopover(e))
});

export default connect(mapStateToProps, mapDispatchToProps)(SortResultsByButton);
