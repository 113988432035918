import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { getMetaContent, parseForSubtitle } from '../../helpers/util';

const TableOfContent = props => {
  const {
    section,
    idx,
    handleClick,
    activeChapter,
  } = props;

  return (
    <div
      role="button"
      tabIndex="0"
      onClick={evt => handleClick(evt, idx)}
      onKeyDown={evt => {
        if (evt.key === 'Enter') {
          handleClick(evt, idx);
        }
      }}
    >
      <h2
        className={idx === activeChapter ? 'title active' : 'title collapsed'}
        data-toggle="collapse"
        data-target={`#collapse${idx}`}
      >
        {section.chapter}
        .
        {section.title}
      </h2>
      <div
        className={
          idx === activeChapter ? 'show subtitle collapse' : 'subtitle collapse'
        }
        id={`collapse${idx}`}
        data-parent="#accordionTable"
      >
        {section.body.includes('#') && (
          <ul>
            {parseForSubtitle(section.body).map((subtitle, idx) => (
              <li key={`${section.chapter}.${idx}`}>
                <h3>{subtitle}</h3>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

class RenterGuide extends Component {
  state = {
    guideType: 'renter',
    guideContent: {},
    activeChapter: 0,
    entered: false,
    chapterCount: 0,
  };

  componentDidMount() {
    const { guideType } = this.state;

    axios
      .get(`/guide_chapters/${guideType}.json`, {
        authenticity_token: getMetaContent('csrf-token'),
      })
      .then(res => {
        this.setState({
          guideContent: res.data,
          chapterCount: res.data.length,
        });
      })
      .catch(err => {
        console.dir(err);
      });

    this.setState({ entered: true });
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = evt => {
    const { code } = evt;
    if (code === 'ArrowRight') this.handleNext(evt);
    if (code === 'ArrowLeft') this.handlePrevious(evt);
  };

  handleClick = (evt, idx) => {
    evt.preventDefault();
    this.setState({ activeChapter: idx });
  };

  handleNext = evt => {
    const { activeChapter, chapterCount } = this.state;
    evt.preventDefault();
    if (activeChapter < chapterCount - 1) {
      this.setState(state => ({ activeChapter: state.activeChapter + 1 }));
    }
  };

  handlePrevious = evt => {
    const { activeChapter } = this.state;
    evt.preventDefault();
    if (activeChapter > 0) {
      this.setState(state => ({ activeChapter: state.activeChapter - 1 }));
    }
  };

  render() {
    const { guideContent, activeChapter, chapterCount } = this.state;
    const { handleBackToApply } = this.props;

    return (
      <div className="renter-guide">
        <div className="background-dark">
          {handleBackToApply && (
            <button
              type="button"
              className="button btn-rounded btn-transparent back-to-apply"
              onClick={handleBackToApply}
            >
              {' '}
              {'< '}
              {' '}
              Back to apply
            </button>
          )}
          <h1>Nooklyn Renter Guide</h1>
          <p>
            Renting an apartment in NYC can be stressful, Nooklyn makes it easy.
            The Nooklyn Renter Guide should answer every question you may have.
            If it doesn’t,
            {' '}
            <strong>get in touch.</strong>
          </p>
          <hr />
          <h4>Table of contents</h4>
          <div className="accordion" id="accordionTable">
            {!!guideContent.length
              && guideContent.map((section, idx) => (
                <TableOfContent
                  key={idx}
                  section={section}
                  activeChapter={activeChapter}
                  idx={idx}
                  handleClick={this.handleClick}
                />
              ))}
          </div>
        </div>
        <div className="background-white">
          <h2 className="title">
            {!!guideContent.length
              && `${guideContent[activeChapter].title}`}
          </h2>
          <ReactMarkdown className="guide-mark-down">
            {guideContent.length ? guideContent[activeChapter].body : ''}
          </ReactMarkdown>
          {!!guideContent.length
            && guideContent[activeChapter].video_url && (
              <iframe
                title="guide-video"
                className="guide-video"
                src={guideContent[activeChapter].video_url}
                width="500"
                height="281"
                frameBorder="0"
                allowFullScreen
              />
          )}
          <ul className="list-inline list-unstyled text-center">
            {activeChapter > 0 && (
            <li className="list-inline-item">
              <button
                type="button"
                onClick={this.handlePrevious}
                className="button btn-rounded btn-white-black float-left guide-btn-prev"
              >
                {'<'}
                {' '}
                Previous
              </button>
            </li>
            )}
            {activeChapter < chapterCount - 1 && (
            <li className="list-inline-item">
              <button
                type="button"
                onClick={this.handleNext}
                className="button btn-rounded btn-white-black float-right guide-btn-next"
              >
                Next
                {' '}
                {'>'}
              </button>
            </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default RenterGuide;
