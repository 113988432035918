import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { ButtonStepper } from '../Buttons';
import ContainerWrapper from '../ContainerWrapper';
import Previews from './Previews';

const title = 'Upload Photo Gallery';

class PhotoUpload extends React.Component {
  defaultErrorMessage = 'An expected error occurred. please contact hi@nooklyn.com'

  constructor(props) {
    super(props);
    this.state = {
      previews: [],
      uploading: false,
    };
    this.source = axios.CancelToken.source();
  }

  componentDidMount() {
    const { listingId } = this.props;
    document.title = `Create: Photos - ${title}`;
    axios
      .get(
        `/api/v2/listings.images?listing_id=${listingId}`,
        {
          headers: { API_TOKEN: localStorage.getItem('apiToken') },
        },
        { cancelToken: this.source.token },
      )
      .then((res) => {
        const { setGlobalState } = this.props;
        setGlobalState({ images: res.data.images });
      });
  }

  componentWillUnmount() {
    const { previews } = this.state;
    previews.forEach(file => URL.revokeObjectURL(file));
    this.source.cancel('Operation canceled');
  }

  handleFileDrop = async (files) => {
    this.setState({ uploading: true });
    const { listingId } = this.props;
    if (!files.length) {
      return;
    }

    this.setState({ previews: files.map(file => URL.createObjectURL(file)) });
    const promises = files.map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(file);
      });
    });
    const arrayOfBase64 = await Promise.all(promises);
    Promise.all(
      arrayOfBase64.map(url => axios.post(
        '/api/v2/listings.images.upload',
        { listing_id: listingId, url },
        {
          headers: { API_TOKEN: localStorage.getItem('apiToken') },
          cancelToken: this.source.token,
        },
      )),
    )
      .then((resList) => {
        this.setState({
          uploading: false,
          previews: [],
        });
        const { globalState: { images }, setGlobalState } = this.props;
        setGlobalState({
          images: [...images, ...resList.map(res => res.data.result)],
        });
      })
      .catch((err) => {
        const message = err.response.data.error.friendlyMessage;
        this.setState({
          uploading: false,
          previews: [],
          error: message,
        });
      });
  };

  onError = (error) => {
    this.setState({
      error,
    });
  }

  onCrop = (imageId, newImage) => {
    const { globalState: { images }, setGlobalState } = this.props;
    const oldImageIndex = images.findIndex(image => image.id === imageId);
    images[oldImageIndex] = newImage;
    setGlobalState({
      images: [...images],
    });
  }

  render() {
    const {
      previews, error, uploading,
    } = this.state;
    const {
      globalState: { images },
      handleNext,
      handleBack,
      listingId,
    } = this.props;
    const { onError, onCrop } = this;

    return (
      <ContainerWrapper>
        <div>
          <div className="title">
            <h3>{title}</h3>
            <p>We promised, we delivered.</p>
          </div>
          <Dropzone
            onDrop={acceptedFiles => this.handleFileDrop(acceptedFiles)}
            disabled={uploading}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="photo-upload-container">
                    <i className="nklyn-3x nookons-camera" />
                    <p>Drop photos here or click the camera.</p>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
          <Previews
            listingId={listingId}
            previews={previews}
            images={images}
            error={error}
            onError={onError}
            onCrop={onCrop}
          />
          <ButtonStepper
            handleNext={handleNext}
            handleBack={handleBack}
            disabled={!images.length || uploading}
          />
        </div>
      </ContainerWrapper>
    );
  }
}

PhotoUpload.defaultProps = {
  globalState: {
    images: [],
  },
};

PhotoUpload.propTypes = {
  globalState: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        thumb: PropTypes.string,
      }),
    ),
  }),
  setGlobalState: PropTypes.func.isRequired,
  listingId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default PhotoUpload;
