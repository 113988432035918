import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class AddressButton extends Component {
  componentDidMount() {
    this.node.addEventListener('click', e => {
      e.stopPropagation();
    });
    this.node.addEventListener('mouseup', e => {
      e.stopPropagation();
    });
  }

  render() {
    const { togglePopover, address } = this.props;
    const className = address && address.length > 0 ? 'btn-transparent-yellow' : 'btn-transparent-gray';
    const value = address && address.length > 0 ? `Address: (${address.slice(0, 10)}...)` : 'Address';
    return (
      <input
        ref={me => this.node = me}
        name='address'
        onMouseDown={togglePopover}
        type='button'
        className={`button btn-rounded ${className} btn-search-bar-size`}
        value={value}/>
    );
  }
}

const mapStateToProps = state => {
  return ({
    address: state.search[state.search.context].address,
  });
};

const mapDispatchToProps = dispatch => ({
  togglePopover: e => dispatch(togglePopover(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressButton);
