import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  convertSubwayStopToSelectedValues,
  convertHoodSearchToSelectedValues,
  convertSavedSearchToSelectedValues } from 'reducers/search';
import { debounce } from 'helpers/animations';
import WithRepositioning from 'wrappers/WithRepositioning';

class TopLevelUpdateHandler extends Component {

  displayKeys = [
    'amenitiesDisplay',
    'subwayDisplay',
    'priceDisplay',
    'ppsfDisplay',
    'squareFeetDisplay',
  ]

  reversalNeeded = false;

  componentDidMount() {
    this.scrollCB = debounce(() => {
      this.props.repositionPopovers();
    }, 150);
    this.node.offsetParent.addEventListener('scroll', this.scrollCB);
  }

  componentWillUnmount() {
      this.node.offsetParent.removeEventListener('scroll', this.scrollCB);
  }

  componentDidUpdate(prevProps) {
    // after the first search returns, update position, even if the params haven't changed
    if (this.props.firstTime !== prevProps.firstTime) {
      this.props.repositionPopovers(true);
    }
    // if params changed, but they're not applied yet, we will need to reverse the params when the popover closes
    if (this.props.uiParams !== prevProps.uiParams) {
      this.reversalNeeded = true;
    }
    // changing applied params affects the button values. Update popover positions to compensate
    if (prevProps.params !== this.props.params) {
      if (!window.currentlyRunningAnimation) {
        this.props.repositionPopovers();
      } else {
        requestAnimationFrame(() => requestAnimationFrame(() => {
          this.props.repositionPopovers();
        }));
      }
    // Currently viewing sentence search and params change-- position should be updated, even if params not yet applied
    } else if (this.props.sentenceSearchDisplay && this.props.uiParams !== prevProps.uiParams){
      if (!window.currentlyRunningAnimation) {
        this.props.repositionPopovers();
      } else {
        requestAnimationFrame(() => requestAnimationFrame(() => {
          this.props.repositionPopovers();
        }));
      }
      // if not in sentence search and we close a popover, popover filters get reversed to previous state
    } else if (!this.props.sentenceSearchDisplay && this.reversalNeeded) {
      if (this.displayKeys.some(key => prevProps[key] !== this.props[key] && !this.props[key])) {
        this.props.convertSavedSearchToSelectedValues();
        this.reversalNeeded = false;
      }
      if (this.props.subwayStopsDisplay !== prevProps.subwayStopsDisplay && !this.props.subwayStopsDisplay) {
        this.props.convertSubwayStopToSelectedValues();
        this.reversalNeeded = false;
      }
      if (this.props.hoodDisplay !== prevProps.hoodDisplay && !this.props.hoodDisplay) {
        this.props.convertHoodSearchToSelectedValues();
        this.reversalNeeded = false;
      }
    }
    // if we close or open the sentence search, update popover positions
    if (prevProps.sentenceSearchDisplay !== this.props.sentenceSearchDisplay) {
      this.props.repositionPopovers();
    }
  }

  render() {
    return <div ref={me => this.node = me} />;
  }
}

const mapStateToProps = state => {
  let myState = state.search[state.search.context];
  return {
    params: myState.lastAppliedSearchParams,
    uiParams: myState.currentUIGeneratedSearchParams,
    sentenceSearchDisplay: myState.sentenceSearchDisplay,
    amenitiesDisplay: myState.amenitiesDisplay,
    subwayDisplay: myState.subwayDisplay,
    subwayStopsDisplay: myState.subwayStopsDisplay,
    hoodDisplay: myState.hoodDisplay,
    priceDisplay: myState.priceDisplay,
    ppsfDisplay: myState.ppsfDisplay,
    squareFeetDisplay: myState.squareFeetDisplay,
    popoverRefs: myState.popoverRefs,
    firstTime: myState.firstTime,
    addressDisplay: myState.addressDisplay,
  };
};

const mapDispatchToProps = dispatch => ({
  convertSavedSearchToSelectedValues: () => dispatch(convertSavedSearchToSelectedValues()),
  convertSubwayStopToSelectedValues: () => dispatch(convertSubwayStopToSelectedValues()),
  convertHoodSearchToSelectedValues: () => dispatch(convertHoodSearchToSelectedValues()),
});
//
const HOC = WithRepositioning(TopLevelUpdateHandler);
export default connect(mapStateToProps, mapDispatchToProps)(HOC);
