import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { __BeforeRouteChange__ } from './BeforeRouteChange';

// NEVER USE THIS OUTSIDE OF RouteChangeHandler
class __RouteChangeSubscriber__ extends PureComponent {
  // Subscribe to the beforeRouteChange event on mount
  componentDidMount() {
    this.subscribe();
  }

  // Reset the subscription if the component updates
  // We need to cache the onBeforeRouteChange handler so the equality check still works
  componentDidUpdate() {
    this.cacheSubscriptionReset();
  }

  // Unsubscribe if the component unmounts
  componentWillUnmount() {
    const { onBeforeRouteChange } = this.props;
    this.beforeRouteChange.tryUnsubscribe(onBeforeRouteChange);
  }

  // Just a helper so I don't have to deconstruct every time
  get beforeRouteChange() {
    const { beforeRouteChange } = this.props;
    return beforeRouteChange;
  }

  // Pass in a cache of the current onBeforeRouteChange prop so equality can still be tested
  resetSubscriptionHOF = propsCache => () => {
    this.resetSubscription(propsCache);
  };

  // Unsubscribe then subscibe again
  resetSubscription = (propsCache) => {
    this.beforeRouteChange.tryUnsubscribe(propsCache);
    this.subscribe();
  }

  /* Subscribe creates a new promise, a new reset subscription cache,
  and subscribes to the BeforeRouteChange event */
  subscribe = () => {
    const prom = new Promise((resolve) => {
      this.resolve = resolve;
    });
    const { onBeforeRouteChange, paths } = this.props;
    this.cacheSubscriptionReset = this.resetSubscriptionHOF(onBeforeRouteChange);
    this.beforeRouteChange.trySubscribe([
      onBeforeRouteChange,
      prom,
      this.resolve,
      this.cacheSubscriptionReset,
      paths,
    ]);
  }

  // Hold real props in data to separate from context
  render() {
    const { children } = this.props;
    return children || null;
  }
}
// beforeRouteChange prop should have type __BeforeRouteChange__
__RouteChangeSubscriber__.propTypes = {
  beforeRouteChange: PropTypes.instanceOf(__BeforeRouteChange__).isRequired,
  onBeforeRouteChange: PropTypes.func.isRequired,
  paths: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

__RouteChangeSubscriber__.defaultProps = {
  paths: null,
  children: [],
};

export { __RouteChangeSubscriber__ };
