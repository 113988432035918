import axios from 'axios';
import { getMetaContent } from 'helpers/util';
import loggedIn from 'helpers/loggedIn';
import formatListing, { formatAgent } from 'components/renterForms/api/dataFormating';

// API call for applying to a lease
// Property Types :[listings]
export const handleApplyingForLease = async (salesAgentId, listingId, referringAgent) => {
  const lease = {
    sales_agent_id: salesAgentId,
  };
  const data = {
    lease,
    listing_id: listingId,
    authenticity_token: getMetaContent('csrf-token'),
  };

  return axios
    .post('/leases.json', data).then(res => {
      return res;
    }).catch(err => {
      throw new Error(err);
    });
};

// API call for setting up tour through lead system
// Property Types :[listings, buildings]
export const handleSetupLead = async (
  listingType,
  touringPropertyId,
  notes,
  scheduleDate,
  firstName,
  lastName,
  phone,
  email,
  referringAgent,
) => {
  const types = ['listings', 'buildings'];

  // Error Checking for Proper Params
  if (!listingType || !types.includes(listingType)) {
    throw new Error('Property Type Error');
  }

  const lead = {
    notes,
    first_name: firstName,
    last_name: lastName,
    phone,
    email,
    schedule_date: scheduleDate
  }

  return axios
    .post(`/${listingType}/${touringPropertyId}/leads.json`, {
      lead,
      referring_agent: referringAgent,
      authenticity_token: getMetaContent('csrf-token'),
    })
    .then(res => res)
    .catch(err => {
      throw new Error(err);
    });
};

// API call for fetching a single agent
export const fetchAgent = async id => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  return axios.get(`/api/v2/agents.fetch?id=${id}`, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(res => res.data)
    .then(agent => formatAgent(agent));
};

// API call for fetching list of agents
export const fetchAgentList = async () => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  return axios.get('/api/v2/agents.list', { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(res => res.data.agents)
    .then(agents => agents.map(agent => formatAgent(agent)));
};

// API call for fetching information on listing with id == listingId
const fetchListing = async listingId => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  const listingUrl = `/api/v2/listings.fetch?listing_id=${listingId}`;
  const imageUrl = `/api/v2/listings.images?listing_id=${listingId}&active_only=false`;

  const listingRequest = axios.get(listingUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(result => result.data.listing)
    .then(listing => formatListing(listing));

  const imageRequest = axios.get(imageUrl, { headers: { API_TOKEN: apiToken }, cancelToken })
    .then(imagesResult => imagesResult.data.images);

  return Promise.all([listingRequest, imageRequest])
    .then(([listing, images]) => ({ ...listing, images }));
};

// API call for fetching user
export const fetchUser = async () => {
  const apiToken = localStorage.getItem('apiToken');
  const cancelToken = axios.CancelToken.source().token;

  return axios.get('/api/v2/current.fetch', { headers: { API_TOKEN: apiToken }, cancelToken })
    .catch(err => {
      throw new Error(err);
    });
};

export default fetchListing;
