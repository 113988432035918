import React from 'react';
import PropTypes from 'prop-types';

export const ButtonStepper = props => (
  <div className="row button-row">
    <div className="col-md-6 button-right">
      <button
        type="button"
        onClick={props.handleBack}
        className="button btn-transparent-white btn-rounded float-right"
        disabled={props.submitting || false}
      >
        Back
      </button>
    </div>
    <div className="col-md-6 button-left">
      <button
        type="button"
        onClick={props.handleNext}
        className="button btn-transparent-yellow btn-rounded"
        disabled={props.disabled || props.submitting || false}
      >
        Next
      </button>
    </div>
  </div>
);

export const ButtonSave = ({ handleJump, setUserIsEditing, jumpTo }) => (
  <div className="row">
    <div className="mx-auto">
      <button
        type="button"
        onClick={() => {
          handleJump(jumpTo);
          if (setUserIsEditing) setUserIsEditing(false);
        }}
        className="button btn-transparent-yellow btn-rounded"
      >
        Save
      </button>
    </div>
  </div>
);

ButtonSave.propTypes = {
  setUserIsEditing: PropTypes.func,
  handleJump: PropTypes.func.isRequired,
  jumpTo: PropTypes.string.isRequired,
};

ButtonSave.defaultProps = {
  setUserIsEditing: () => null,
};
