import React from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'helpers/util';
import LikeButton from 'components/listings/cards/components/buttons/LikeButton';
import ShareButton from 'components/listings/cards/components/buttons/ShareButton';
import TourButton from 'components/listings/cards/components/buttons/TourButton';
import SubwayRow from 'components/listings/cards/components/SubwayRow';

const RoomCardBottom = props => {
  const {
    room,
    signedIn,
  } = props;

  const {
    address,
    id,
    meta,
    name,
    neighborhood,
    platform_listing: platformListing,
    price,
    sales_agent: salesAgent,
    subway_line: subwayLine,
    url,
  } = room || {};

  return (
    <div>
      <a href={`${url}`}>
        <div className="listing-card-stub container-fluid">
          <div className="row top-row">
            <div className="col-6 text-left">
              {accounting.formatMoney(price / 100, { precision: 0 })}
            </div>
            <div className="col-6 text-left" style={{ textTransform: 'capitalize' }}>
              {truncate(name, 17)}
            </div>
          </div>
        </div>
        <div className="listing-card-stub container-fluid">
          <div className="row top-row">
            <div className="col-6 text-left" style={{ textTransform: 'capitalize' }}>
              {truncate(neighborhood.name, 23)}
            </div>
            <div className="col-6 text-left">
              <SubwayRow subwayLines={subwayLine} />
            </div>
          </div>
        </div>
        <div className="listing-card-stub container-fluid">
          <div className="row top-row">
            <div className="col-12 text-left">
              {(!platformListing && address) ? address.substr(0, address.indexOf(',')) : 'No Address Provided'}
            </div>
          </div>
        </div>
      </a>
      <div className="listing-card-stub container-fluid card-bottom-row-border">
        <div style={{ padding: '0px', cursor: 'pointer' }} className="row top-row bottom-row">
          <LikeButton id={id} type="room" liked={meta.liked} signedIn={signedIn} />
          <ShareButton type="col" url={url} />
          <TourButton propertyId={id} salesAgent={salesAgent} signedIn={signedIn} />
        </div>
      </div>
    </div>
  );
};

RoomCardBottom.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number,
    neighborhood: PropTypes.shape({
      name: PropTypes.string,
    }),
    meta: PropTypes.shape({
      liked: PropTypes.bool,
    }),
    platform_listing: PropTypes.bool,
    price: PropTypes.number,
    subway_line: PropTypes.arrayOf(PropTypes.string),
    url: PropTypes.string,
  }).isRequired,
  signedIn: PropTypes.bool.isRequired,
};

export default RoomCardBottom;
