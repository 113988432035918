import React, { Component } from 'react';
import { Row, Column } from 'wrappers/FlexTable';
import { connect } from 'react-redux';
import { setStore, generateNewSearch, updateActiveSearch } from 'reducers/search';
import CustomCheckBox from 'components/CustomCheckBox';

class IndividualSortResultsBy extends Component {

  onSelect = (e) => {
    e.preventDefault();
    if (!this.props.selected) {
      this.props.setStore({ selectedSortResultsBy: this.props.sortResultsBy });
      this.props.generateNewSearch();
      this.props.updateActiveSearch();
    }
  }

  render () {
    const { sortResultsBy, selected } = this.props;
    return (
      <Row
        style={{
          border: '0px',
          width: '100%',
          paddingLeft: '10px',
          paddingTop: '10px', color: '#fff',
          fontSize: '13px',
          fontWeight: '500',

        }} >
        <Column
          onMouseDown={this.onSelect}
          style={{
            width: '100%',
            cursor: 'pointer',
            userSelect: 'none'
          }}>
          <CustomCheckBox
            checked={selected}
            boxClass='radio-box'
            boxcheckedClass='radio-box-selected'
            checkmarkClass='radio-button'
          >
            <Column
              style={{
                color: '#fff',
                fontSize: '13px',
                fontWeight:  '500',
                paddingLeft: '10px',
              }}
            >
              {sortResultsBy}
            </Column>
          </CustomCheckBox>
        </Column>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selected = ownProps.sortResultsBy === state.search[state.search.context].selectedSortResultsBy;
  return {
    selected: selected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setStore: (payload) => dispatch(setStore(payload)),
  generateNewSearch: () => dispatch(generateNewSearch()),
  updateActiveSearch: () => dispatch(updateActiveSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualSortResultsBy);
