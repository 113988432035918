import React from 'react';
import strftime from 'strftime';
import { numberToCurrency } from 'helpers/railsMethods';
import PropTypes from 'prop-types';

const PriceHistory = props => {
  const { priceHistory } = props;

  if (!priceHistory) {
    return null;
  }

  if (priceHistory.length === 0) {
    return null;
  }

  return (
    <div className="price-history">
      <hr />
      <h5>Price History</h5>
      <div className="responsive-table" style={{ padding: '0 10px' }}>
        <table className="table nklyn-table nklyn-table-dark">
          <thead>
            <tr>
              <th>Price</th>
              <th>Date</th>
              <th>Change</th>
            </tr>
          </thead>
          <tbody>
            {priceHistory.map(history => (
              <tr className="text-center" key={history.id}>
                <td>{numberToCurrency(history.newPrice / 100)}</td>
                <td>{strftime('%B %d, %Y', new Date(history.date))}</td>
                <td>
                  {history.price_change === -1
                    ? 'Listed on Nooklyn'
                    : numberToCurrency(history.priceChange / 100)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

PriceHistory.propTypes = {
  priceHistory: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    date: PropTypes.string,
    newPrice: PropTypes.number,
    priceChange: PropTypes.number,
  })).isRequired,
};

export default PriceHistory;
