import axios from 'axios';
import { __AsyncBase__ } from './AsyncBase';

// Converted to api
export default class AgentAsync extends __AsyncBase__ {
  constructor() {
    super();
    this._token = localStorage.getItem('apiToken');
  }

  get agents() {
    if (!this._agents) {
      this._agents = this.fetchAgents();
    }
    return this._agents;
  }

  getAgentDataFormatted = a => ({
    id: a.id,
    email: a.email,
    thumbUrl: a.avatar_thumb,
    firstName: a.first_name,
    lastName: a.last_name,
    name: a.full_name,
    agentCard: a.agent_card,
    slug: a.slug,
  })

  fetchAgents = () => axios.get('/api/v2/agents.list?',
    {
      headers: { API_TOKEN: this._token },
      cancelToken: this._source.token,
    })
    .then(res => res.data.agents.map(a => this.getAgentDataFormatted(a)))
}
