import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setStore, updateActiveSearch, generateNewSearch } from 'reducers/search';
import { Column } from 'wrappers/FlexTable';

class LikedOnly extends Component {
  onTouch = (e) => {
    e.stopPropagation();
    this.props.setStore({
      likedOnly: !this.props.likedOnly,
    });
    this.props.generateNewSearch();
    this.props.updateActiveSearch();
  }

  render() {
    const { likedOnly } = this.props;
    const className = likedOnly ? 'btn-transparent-yellow btn-search-bar-size' : 'btn-transparent-gray btn-search-bar-size';

    return (
      <Column className="searchbar-col">
        <input
          name="likedOnly"
          onMouseDown={this.onTouch}
          type="button"
          className={`button btn-rounded ${className}`}
          value="Liked Only"
        />
      </Column>
    );
  }
}

const mapStateToProps = state => ({
  likedOnly: state.search[state.search.context].likedOnly,
});

const mapDispatchToProps = dispatch => ({
  setStore: payload => dispatch(setStore(payload)),
  updateActiveSearch: () => dispatch(updateActiveSearch()),
  generateNewSearch: () => dispatch(generateNewSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LikedOnly);
