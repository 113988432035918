import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from 'components/LoadingSpinner';
import SearchCard from 'components/listings/cards/SearchCard';
import Cat404 from 'components/listings/pages/Cat404';
import { keysToSnake } from 'api/util';

const ListingCollection = props => {
  const {
    loading,
    onListingSelect,
    signedIn,
    searchResults,
    totalCount,
    type,
  } = props;

  if (loading) {
    return (
      <div key="loading-icon" style={{ top: '250px', position: 'relative' }}>
        <LoadingSpinner />
      </div>
    );
  }

  // ToDo: Convert All Cards to not use SnakeVariables
  return searchResults && searchResults.length > 0
    ? (
      <>
        <p
          className="standard-type"
          style={{
            display: 'flex',
            color: '#fff',
            margin: '8px',
          }}
        >
          {totalCount.toLocaleString()}
          {' results'}
        </p>
        {searchResults.map((item, idx) => {
          if (idx === 0) {
            return (
              <React.Fragment key={`${type}-${item.id}`}>
                <SearchCard
                  type="listing"
                  handleChange={onListingSelect}
                  listing={keysToSnake(item)}
                  signedIn={signedIn}
                />
              </React.Fragment>
            );
          }
          return (
            <SearchCard
              type="listing"
              handleChange={onListingSelect}
              listing={keysToSnake(item)}
              key={`${type}-${item.id}`}
              signedIn={signedIn}
            />
          );
        })}
      </>
    ) : <Cat404 context={type} key="no-results" />;
};

ListingCollection.defaultProps = {
  signedIn: false,
  loading: true,
  totalCount: 0,
};

ListingCollection.propTypes = {
  signedIn: PropTypes.bool,
  onListingSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  searchResults: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalCount: PropTypes.number,
  type: PropTypes.string.isRequired,
};

export default ListingCollection;
