import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import LeaseAgentMarkPaidModal from '../modal/LeaseAgentMarkPaidModal';
import { leaseSalesAgentUpdated } from '../../reducers/lease';

const AgentCard = ({ agent, lease_id, agentType }) => (
  <div className="col-sm-6 mb-2" key={agent.id}>
    <a href={`${agent.profile_url}`}>
      <img
        className="rounded-circle img-fluid rounded-circle-small mx-auto m-3"
        src={agent.avatar_thumb}
        alt="agent"
      />
    </a>
    <div>{agent.full_name}</div>
    <div className="mb-1">
      <small>{agent.phone}</small>
    </div>
    {agent.paid_at ? (
      <span className="label label-outline">Paid</span>
    ) : (
      <LeaseAgentMarkPaidModal
        agent_id={agent.id}
        lease_id={lease_id}
        agentType={agentType}
      />
    )}
  </div>
);

class MatrixLeaseRowSalesTeam extends React.Component {
  source = axios.CancelToken.source();

  state = { salesAgents: [], listingAgent: [], error: '' };

  static defaultProps = {
    fetchSalesAgentUpdate: false,
  };

  componentDidMount() {
    this.fetchSalesAgents();
    this.fetchListingAgent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.id === this.props.leaseWithUpdatedSalesAgent &&
      prevProps.leaseWithUpdatedSalesAgent !==
        this.props.leaseWithUpdatedSalesAgent &&
      prevState.salesAgents.length === this.state.salesAgents.length
    ) {
      this.fetchSalesAgents();
      this.fetchListingAgent();
      this.props.leaseSalesAgentUpdated('');
    }
  }

  componentWillUnmount() {
    this.source.cancel('api call cancelled as component will unmount');
  }

  fetchListingAgent = () => {
    const lease_id = this.props.id;
    const type = 'listing_agent';

    axios
      .get(
        '/api/v1/leases.agents.list',
        { params: { lease_id, type } },
        {
          headers: { API_TOKEN: localStorage.getItem('apiToken') },
          cancelToken: this.source.token,
        }
      )
      .then(res => {
        this.setState({ listingAgent: res.data.lease_agents });
      })
      .catch(err => console.dir(err));
  };

  fetchSalesAgents = () => {
    const lease_id = this.props.id;
    const type = 'sales_agent';

    axios
      .get(
        '/api/v1/leases.agents.list',
        { params: { lease_id, type } },
        {
          headers: { API_TOKEN: localStorage.getItem('apiToken') },
          cancelToken: this.source.token,
        }
      )
      .then(res => {
        this.setState({ salesAgents: res.data.lease_agents });
      })
      .catch(err => console.dir(err));
  };

  render() {
    const { salesAgents, listingAgent } = this.state;

    return (
      <div className="text-center mt-3">
        {!!listingAgent.length && <b>Listing Agent</b>}
        <div className="row">
          {!!listingAgent.length &&
            listingAgent.map(agent => (
              <AgentCard
                key={agent.id}
                agent={agent}
                lease_id={this.props.id}
                agentType="listing_agent"
              />
            ))}
        </div>
        {!!salesAgents.length && <b>Sales Team</b>}
        <div className="row">
          {!!salesAgents.length &&
            salesAgents.map(agent => (
              <AgentCard
                key={agent.id}
                agent={agent}
                lease_id={this.props.id}
                agentType="sales_agent"
              />
            ))}
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  leaseWithUpdatedSalesAgent: state.lease.leaseWithUpdatedSalesAgent,
});

const mapDispatch = dispatch => ({
  leaseSalesAgentUpdated(lease_id) {
    dispatch(leaseSalesAgentUpdated(lease_id));
  },
});

export default connect(
  mapState,
  mapDispatch,
)(MatrixLeaseRowSalesTeam);
