import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setStore, updateActiveSearch, generateNewSearch } from 'reducers/search';
import { Column } from 'wrappers/FlexTable';

class SubletOnly extends Component {

  onTouch = (e) => {
    e.stopPropagation();
    this.props.setStore({
      subletOnly: !this.props.subletOnly
    });
    this.props.generateNewSearch();
    this.props.updateActiveSearch();
  }

  render() {
    const { subletOnly } = this.props;
    const className = subletOnly ? 'btn-transparent-yellow btn-search-bar-size' : 'btn-transparent-gray btn-search-bar-size';
    return (
      <Column className='searchbar-col'>
        <input
          name='subletOnly'
          onMouseDown={this.onTouch}
          type='button'
          className={`button btn-rounded ${className}`}
          value='Sublet Only'/>
      </Column>
    );
  }
}

const mapStateToProps = (state) => ({
  subletOnly: state.search[state.search.context].subletOnly,
});

const mapDispatchToProps = (dispatch) => ({
  setStore: (payload) => dispatch(setStore(payload)),
  updateActiveSearch: () => dispatch(updateActiveSearch()),
  generateNewSearch: () => dispatch(generateNewSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubletOnly);
