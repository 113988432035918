import React, { useState } from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from 'components/LoadingSpinner'
import HoodList from './HoodList'
import Neighborhoods from './Neighborhoods'
import normalizeRegions from './Neighborhoods/normalizeRegions'

const LocationPopover = (props) => {
  const { loading, value, onChange, options } = props

  const [hoodFilterTxt, setHoodFilterTxt] = useState('')

  const handleChange = (selected) => onChange({ ...selected })

  return (
    <div
      style={{
        border: '1px solid rgb(64,64,64)',
        backgroundColor: '#111',
        color: '#fff',
        borderRadius: '0.3em',
        fontSize: '12px',
        width: '350px',
        maxHeight: '700px',
        overflow: 'hidden',
        paddingBottom: '15px',
      }}
    >
      <div className="container-fluid">
        <div className="tab-content">
          <br />
          {options.regions.length && options.neighborhoods.length && !loading ? (
            <Neighborhoods
              selected={{
                regions: value.regions || [],
                neighborhoods: value.neighborhoods || [],
              }}
              onChange={(newValue) => handleChange(newValue)}
              options={{
                ...options,
                regions: normalizeRegions(options.regions),
              }}
            />
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </div>
    </div>
  )
}

LocationPopover.propTypes = {
  loading: PropTypes.bool.isRequired,
  value: PropTypes.shape({
    regions: PropTypes.arrayOf(PropTypes.string),
    neighborhoods: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    regions: PropTypes.arrayOf(PropTypes.shape({})),
    neighborhoods: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default LocationPopover
