import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SubwayPop from '../searchPopovers/Subway';
import SubwayButton from '../sentenceSearchButtons/SubwayButton';
import Reposition from '../searchBarComponents/Reposition';

class Subway extends Component {
  render() {
    const { popoverWrapper, popoverContainer, popover } = this.props;
    return (
      <div
        ref={popoverContainer}
        className="button btn-transparent"
        id="listing-transit-selector"
        style={{ border: 'none' }}
      >
        <SubwayPop
          ref={{
            popoverWrapper,
            popover,
          }}
          displayProp="sentenceSubwayDisplay"
          searchContext="sentenceSearch"
        />
        <SubwayButton />
      </div>
    );
  }
}

Subway.defaultProps = {
  popoverWrapper: null,
  popoverContainer: null,
  popover: null,
};

Subway.propTypes = {
  popoverWrapper: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popoverContainer: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  popover: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

export default Reposition(Subway);
