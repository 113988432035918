import React from 'react';

class ScrollButton extends React.Component {
  constructor() {
    super();
    this.scrollTop = this.scrollTop.bind(this);
  }

  scrollTop() {
    $('#nooklyn_listings_index_container')[0].scrollTop = 0
  }

  render() {
    return (
      <button
        title="Back to top"
        className='button btn-black-white'
        style={{position: 'fixed', bottom: '50px', left: '5px'}}
        onClick={this.scrollTop}
      >
        ▲ Top
      </button>
    );
  }
}

export default ScrollButton;
