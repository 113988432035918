import React from 'react';
import PropTypes from 'prop-types';
import { Column } from 'wrappers/FlexTable';

const NoFeeOnly = props => {
  const {
    selected,
    onChange,
    loading,
  } = props;

  const handleClick = newValue => {
    if (!loading) {
      onChange(newValue);
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.currentTarget.click();
    }
  };

  const className = selected && !loading ? 'btn-transparent-yellow btn-search-bar-size' : 'btn-transparent-gray btn-search-bar-size';
  return (
    <Column className="searchbar-col">
      <input
        name="noFeeOnly"
        onClick={() => handleClick(!selected)}
        onKeyPress={handleKeyPress}
        type="button"
        className={`button btn-rounded ${className}`}
        value="No Fee Only"
      />
    </Column>
  );
};

NoFeeOnly.defaultProps = {
  selected: false,
  onChange: () => {},
  loading: false,
};

NoFeeOnly.propTypes = {
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default NoFeeOnly;
