import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Props:
// --------------------------------------------------
// firstClick - If clicked for first time (Required)
// headerAlignment - Text Alignment of header: Left, Right, Center (Required)
// isLoading - Tells whether the rest of slider is still loading (Required)
// needSlider - Tells if slider needs controls or not (Required)
// setTransition - Callback function for moving slider (Required)
// subtitle - Subtitle Text (Required)
// subtitleUrl - Hyperlink for subtitle to redirect on click (Required)
// title - Title Text (Required)
// titleSize - Size of title: lg, md, sm (Required)
// sliderOffset - Offset for header to be properly space from sides of page (Required)

// NotSoSlick's SliderHeader:
// Header to announce what our slider is about
const SliderHeader = props => {
  const {
    firstClick,
    headerAlignment,
    isLoading,
    needSlider,
    setTransition,
    subtitle,
    subtitleUrl,
    title,
    titleSize,
    sliderOffset,
  } = props;

  const [style, setStyle] = useState({});
  const [CustomTag, setCustomTag] = useState('h6');

  // Update Header on prop change or window size update
  useEffect(() => {
    if (headerAlignment === 'left') {
      setStyle({ paddingLeft: sliderOffset });
    }

    if (titleSize === 'lg') {
      setCustomTag('h2');
    } else if (titleSize === 'md') {
      setCustomTag('h4');
    } else {
      setCustomTag('h6');
    }
  }, [titleSize, sliderOffset, headerAlignment]);

  return (
    <div className={`slider-header ${headerAlignment}`}>
      <div className="slider-title-holder">
        <CustomTag className="standard-type nklyn-white slider-title" style={style}>{title}</CustomTag>
        <h6 className="standard-type nklyn-white slider-subtitle" style={style}>
          {(subtitleUrl !== '')
            ? (
              <a href={subtitleUrl}>
                {subtitle}
                <i className="nookons-chevron-right" />
              </a>
            )
            : subtitle}
        </h6>
      </div>
      { needSlider && (
        <div className="slider-control">
          <span aria-hidden role="button" className={(firstClick || isLoading) ? 'slider-button btn-circle disabled' : 'slider-button btn-circle'} onClick={() => { if (!firstClick && !isLoading) { setTransition('prev'); } }}>
            <i className="nookons-chevron-left" />
          </span>
          <span aria-hidden role="button" className={isLoading ? 'slider-button btn-circle disabled' : 'slider-button btn-circle'} onClick={() => { setTransition('next'); }}>
            <i className="nookons-chevron-right" />
          </span>
        </div>
      )}
    </div>
  );
};

SliderHeader.propTypes = {
  firstClick: PropTypes.bool.isRequired,
  headerAlignment: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  needSlider: PropTypes.bool.isRequired,
  setTransition: PropTypes.func.isRequired,
  subtitle: PropTypes.string.isRequired,
  subtitleUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleSize: PropTypes.string.isRequired,
  sliderOffset: PropTypes.number.isRequired,
};

export default SliderHeader;
