import React, { Component } from 'react';
import { connect } from 'react-redux';
import { togglePopover } from 'reducers/search';

class PetsButton extends Component {

  render() {
    const { selectedPet, togglePopover } = this.props;
    const value = selectedPet === '' ? 'Any' : selectedPet;
    return (
      <a
        name='sentencePets'
        onMouseDown={togglePopover}
        tabIndex="0"
        className="button btn-transparent"
        role="button"
      >{value}</a>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedPet: state.search[state.search.context].selectedPet,
});

const mapDispatchToProps = (dispatch) => ({
  togglePopover: (e) => dispatch(togglePopover(e))
});

export default connect(mapStateToProps, mapDispatchToProps)(PetsButton);
