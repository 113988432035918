import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from 'components/LoadingSpinner';
import Dropdown from '../Dropdown/PaymentDropdown';
import PaymentSummary from './PaymentSummary';
import PaymentBankReset from './PaymentBankReset';
import PlaidLinkWithLinkToken from './PlaidLinkWithLinkToken';
import {
  createPayment,
  submitForm,
  resetBankAccount,
} from '../../reducers/payment';
import { stateOptions } from '../../lib/common';

class Bank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: '',
    };
  }

  handleStateChange = item => {
    if (item) {
      this.setState({ state: item.value });
    } else {
      this.setState({ state: '' });
    }
  };

  render() {
    const {
      handleSubmit,
      handleReset,
      bankAccountLinked,
      accountName,
      bankName,
      plaidToken,
      plaidAccountId,
      achPaymentRequest,
      errors,
      submitting,
      lease_id,
      persistPaymentDetails,
      linkToken,
      oauthFlow,
      oauthStateId,
      logPlaidInitiatedAfterOauth,
    } = this.props;
    const { state } = this.state;

    return (
      <div className="payment-detail">
        <h1>PAY WITH BANK</h1>
        <hr className="entire-width" />
        {!bankAccountLinked && (
          linkToken
            ? (
              <PlaidLinkWithLinkToken achPaymentRequest={achPaymentRequest} lease_id={lease_id} linkToken={linkToken} persistPaymentDetails={persistPaymentDetails} oauthFlow={oauthFlow} oauthStateId={oauthStateId} logPlaidInitiatedAfterOauth={logPlaidInitiatedAfterOauth} />
            ) : (
              <LoadingSpinner className="text-center mb-2" />
            )
        )}
        {bankAccountLinked && (
          <PaymentBankReset
            handleReset={handleReset}
            accountName={accountName}
            bankName={bankName}
          />
        )}
        {plaidToken && (
          <form
            onSubmit={evt => handleSubmit(
              evt,
              plaidToken,
              plaidAccountId,
              achPaymentRequest.signature,
              state,
            )}
          >
            <hr className="entire-width" />
            <input name="utf8" type="hidden" value="✓" />
            <input type="hidden" name="authenticity_token" value="" />
            <input
              type="hidden"
              name="payment_detail[plaid_token]"
              id="payment_detail_plaid_token"
            />
            <input
              type="hidden"
              name="payment_detail[plaid_account_id]"
              id="payment_detail_plaid_account_id"
            />
            <input
              type="hidden"
              value="US"
              name="payment_detail[country]"
              id="payment_detail_country"
            />
            <h2>BILLING INFO</h2>
            <label>Full Name</label>
            <input
              type="text"
              name="payment_detail[name]"
              id="payment_detail_name"
              className="general-text-field form-control"
              style={
                errors && errors.fields && errors.fields.includes('name')
                  ? { borderColor: 'red' }
                  : {}
              }
              required
            />
            <div className="row">
              <div className="col-sm-8">
                <label>Billing Address</label>
                <input
                  type="text"
                  name="payment_detail[address_one]"
                  className="general-text-field form-control"
                  id="payment_detail_address_one"
                  style={
                    errors && errors.fields && errors.fields.includes('address_one')
                      ? { borderColor: 'red' }
                      : {}
                  }
                />
              </div>
              <div className="col-sm-4">
                <label>Unit Number (Optional)</label>
                <input
                  type="text"
                  name="payment_detail[address_two]"
                  className="general-text-field form-control"
                  id="payment_detail_address_two"
                />
              </div>
            </div>
            <label>Billing City</label>
            <input
              type="text"
              name="payment_detail[city]"
              className="general-text-field form-control"
              id="payment_detail_city"
              style={
                errors && errors.fields && errors.fields.includes('city')
                  ? { borderColor: 'red' }
                  : {}
              }
            />
            <div className="row">
              <div className="col-sm-6">
                <label>Billing State</label>
                <Dropdown
                  onChange={this.handleStateChange}
                  options={stateOptions}
                  placeholder="Select State"
                  value={state}
                  style={
                    errors && errors.fields && errors.fields.includes('state')
                      ? { borderColor: 'red' }
                      : {}
                  }
                />
              </div>
              <div className="col-sm-6">
                <label>Billing Zipcode</label>
                <input
                  type="text"
                  name="payment_detail[zip_code]"
                  className="general-text-field form-control"
                  id="payment_detail_address_zip_code"
                  style={
                    errors && errors.fields && errors.fields.includes('zip_code')
                      ? { borderColor: 'red' }
                      : {}
                  }
                />
              </div>
            </div>
            <label>Phone Number</label>
            <input
              type="text"
              name="payment_detail[phone_number]"
              className="general-text-field form-control"
              id="payment_detail_address_phone_number"
              style={
                errors && errors.fields && errors.fields.includes('telephone')
                  ? { borderColor: 'red' }
                  : {}
              }
            />
            <hr className="entire-width" />
            <p>
              Nooklyn does not store any credit card or bank account
              information. I authorize Nooklyn NYC LLC to electronically debit
              my account and, if necessary, electronically credit my account to
              correct erroneous debits.
            </p>
            <PaymentSummary payment={achPaymentRequest} />
            <div className="row justify-content-center">
              <div className="col-sm-3">
                <input
                  type="submit"
                  name="commit"
                  value="Pay"
                  className="button btn-black-yellow btn-rounded btn-pay"
                  disabled={submitting || !plaidToken}
                />
              </div>
            </div>
          </form>
        )}
        { errors && !errors.fields && <p className="error mt-0 mb-2">{errors}</p>}
      </div>
    );
  }
}

const mapState = state => ({
  bankAccountLinked: state.payment.bankAccountLinked,
  accountName: state.payment.plaidAccountName,
  bankName: state.payment.plaidBankName,
  plaidToken: state.payment.plaidToken,
  plaidAccountId: state.payment.plaidAccountId,
  errors: state.payment.errors,
  submitting: state.payment.submitting,
  leaseClient: state.lease.leaseClient,
});

const mapDispatch = dispatch => ({
  handleSubmit(
    evt,
    plaid_token,
    plaid_account_id,
    payment_charge_descriptor_signature,
    state,
  ) {
    evt.preventDefault();
    const address_one = evt.target['payment_detail[address_one]'].value;
    const address_two = evt.target['payment_detail[address_two]'].value;
    const city = evt.target['payment_detail[city]'].value;
    const country = evt.target['payment_detail[country]'].value;
    const name = evt.target['payment_detail[name]'].value;
    const telephone = evt.target['payment_detail[phone_number]'].value;
    const zip_code = evt.target['payment_detail[zip_code]'].value;
    dispatch(submitForm());
    dispatch(
      createPayment(
        {
          plaid_token,
          plaid_account_id,
          address_one,
          address_two,
          city,
          country,
          name,
          state,
          telephone,
          zip_code,
        },
        payment_charge_descriptor_signature,
      ),
    );
    evt.target['payment_detail[address_one]'].value = '';
    evt.target['payment_detail[address_two]'].value = '';
    evt.target['payment_detail[city]'].value = '';
    evt.target['payment_detail[country]'].value = '';
    evt.target['payment_detail[name]'].value = '';
    evt.target['payment_detail[phone_number]'].value = '';
    evt.target['payment_detail[zip_code]'].value = '';
  },
  handleReset(evt) {
    evt.preventDefault();
    dispatch(resetBankAccount());
  },
});

export default connect(
  mapState,
  mapDispatch,
)(Bank);
