import React from 'react';
import Downshift from 'downshift';
import matchSorter from 'match-sorter';

import {
  DropDownMenu,
  DropDownItem,
  SimpleSearchStyles,
  ControllerButton,
} from './DropdownStyles';

function getFilteredOptions(filter, allItems) {
  return filter
    ? matchSorter(allItems, filter, {
        keys: ['text'],
      })
    : allItems;
}

const PaymentDropdown = props => {
  const { onChange, placeholder, options, inputStyle, caretStyle, prefill } = props;
  return (
    <SimpleSearchStyles>
      <Downshift
        onChange={onChange}
        itemToString={item => (item === null ? '' : item.text)}
        defaultHighlightedIndex={0}
        initialSelectedItem={prefill || null}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          highlightedIndex,
          getMenuProps,
          selectedItem,
          clearSelection,
          getToggleButtonProps,
          inputValue,
        }) => (
          <div className="react-dropdown">
            <div style={{ position: 'relative' }}>
              <input
                style={{ font: 'inherit', ...inputStyle }}
                {...getInputProps({
                  placeholder: `${placeholder}`,
                  onChange,
                })}
              />
              {selectedItem ? (
                <ControllerButton
                  onClick={clearSelection}
                  style={{ paddingBottom: '25px' }}
                  tabIndex="-1"
                >
                  <i className="nookons-close-circle-fill" style={caretStyle} />
                </ControllerButton>
              ) : (
                <ControllerButton
                  {...getToggleButtonProps()}
                  style={{ paddingBottom: '25px' }}
                  tabIndex="-1"
                >
                  {isOpen ? (
                    <i className="nookons-caret-up-fill" style={caretStyle} />
                  ) : (
                    <i className="nookons-caret-down" style={caretStyle}  />
                  )}
                </ControllerButton>
              )}
            </div>
            <div className="dropdown-menu-container" style={{ position: 'relative', top: '-25px' }}>
              {isOpen && (
                <DropDownMenu {...getMenuProps({ isOpen })}>
                  {getFilteredOptions(inputValue, options).map(
                    (item, index) => (
                      <DropDownItem
                        {...getItemProps({
                          item,
                          index,
                          isActive: highlightedIndex === index,
                          isSelected: selectedItem === item,
                        })}
                        key={item.key}
                      >
                        {item.text}
                      </DropDownItem>
                    )
                  )}
                </DropDownMenu>
              )}
            </div>
          </div>
        )}
      </Downshift>
    </SimpleSearchStyles>
  );
};

export default PaymentDropdown;
