function normalizeRegions(regions) {
  return regions?.map(region => ({
    name: `${region.name}, ${region.borough}`,
    id: String(region.id),
    secondId: String(region.borough),
    type: 'neighborhood',
    searchName: region.name,
  }))
}

export default normalizeRegions
