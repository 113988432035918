import React, { useMemo, useRef, useCallback, useState } from 'react'

import PropTypes from 'prop-types'
import ApplyClear from 'components/listings/search/SearchBar/SearchBarButtons/ApplyClear'
import s from './Neighborhoods.module.css'
import SearchInput from '../../common/SearchInput'
import FilterCheckbox from '../../common/FilterCheckbox'

function Neighborhoods(props) {
  const { selected, onChange, options, onApply } = props

  const [changes, setChanges] = useState(selected)
  const [search, setSearch] = useState('')

  const searchRef = useRef(null)

  const regionsToShow = useMemo(() => {
    return options?.regions?.filter((item) => item.searchName?.match(new RegExp(search, 'i')))
  }, [options, search])

  const handleChooseNeighborhood = useCallback(
    (id, regionName) => {
      const { regions, neighborhoods } = changes

      let newNeighborhoods
      let newRegions
      if (neighborhoods?.includes(id)) {
        newNeighborhoods = neighborhoods?.filter((neighborhoodId) => neighborhoodId !== id)
        newRegions = changes.regions
      } else {
        newNeighborhoods = [...(neighborhoods || []), String(id)]
        newRegions = regions.filter((region) => region !== regionName)
      }

      setChanges({
        regions: newRegions,
        neighborhoods: newNeighborhoods,
      })
    },
    [changes]
  )

  const handleChooseRegion = useCallback(
    (id) => {
      const regions = changes?.regions || []
      const neighborhoods = changes?.neighborhoods || []

      let newRegions
      let newNeighborhoods

      const regionNeighborhoodsIds = options?.regions
        ?.find((region) => region.name === id)
        ?.neighborhoods?.map((neighborhood) => String(neighborhood.id))

      if (regions?.includes(id)) {
        newRegions = regions?.filter((regionId) => regionId !== id)
        newNeighborhoods = neighborhoods?.filter((neighborhood) => !regionNeighborhoodsIds.includes(neighborhood))
      } else {
        newRegions = [...(regions || []), String(id)]
        newNeighborhoods = [...neighborhoods, ...regionNeighborhoodsIds]
      }

      setChanges({
        regions: newRegions,
        neighborhoods: newNeighborhoods,
      })
    },
    [changes, options?.regions]
  )

  // Temporary solution to close Popover
  const closePopover = () => {
    document.querySelector('input[name="hood"]').click()
  }

  const handleUpdate = useCallback(
    (actionType) => {
      if (actionType === 'clear') {
        setChanges({
          regions: [],
          neighborhoods: [],
        })
        setSearch('')
        onChange({
          regions: [],
          neighborhoods: [],
        })
        searchRef.current.value = ''
        return
      }

      onChange(changes)

      if (onApply) {
        onApply()
      }

      closePopover()
    },
    [changes, onApply, onChange]
  )

  const handleSearch = (value) => setSearch(value)

  return (
    <div className={s.root}>
      <SearchInput ref={searchRef} onChange={handleSearch} placeholder="Neighborhoods or Boroughs" />

      <div className={s.list}>
        {regionsToShow?.map((region) => {
          let handleSelect = handleChooseRegion

          if (region.type === 'neighborhood') {
            handleSelect = handleChooseNeighborhood
          }

          const isSelected =
            region.type === 'neighborhood'
              ? changes.neighborhoods?.includes(region.id)
              : changes.regions?.includes(region.id)

          return (
            <div className={s.listItem}>
              <FilterCheckbox key={region.id} handleSelect={handleSelect} item={region} selected={isSelected} />
            </div>
          )
        })}
      </div>

      <ApplyClear className={s.applyClear} handleUpdate={handleUpdate} />
    </div>
  )
}

Neighborhoods.propTypes = {
  selected: PropTypes.shape({
    regions: PropTypes.arrayOf(PropTypes.string),
    neighborhoods: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  options: PropTypes.shape({
    regions: PropTypes.arrayOf(PropTypes.shape({})),
    neighborhoods: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default Neighborhoods
