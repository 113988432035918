import axios from 'axios';
import { convertSnakeObjectToCamel } from 'helpers/util';
import { __AsyncBase__ } from './AsyncBase';

export default class RoomMatrixAsync extends __AsyncBase__ {
  constructor(id) {
    super();
    this._id = id;
  }

  get room() {
    if (!this._room) {
      this._room = this.fetchRoom();
    }
    return this._room;
  }

  fetchRoom = () => axios
    .get(`/api/v2/rooms.fetch?room_id=${this._id}&context=matrix`, {
      headers: { API_TOKEN: localStorage.getItem('apiToken') },
      cancelToken: this._source.token,
    })
    .then(res => convertSnakeObjectToCamel(res.data.room))
}
