import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AuthModal from 'components/modal/AuthModal';
import { getParamsFromLink } from 'helpers/util';
import applyImg from 'images/icons/svg/listing_apply.svg';

const Apply = props => {
  const { listing, salesAgentID, signedIn } = props;

  const {
    residential,
    id,
    status,
    acceptsApply,
    network,
  } = listing;

  const [applyUrl, setApplyUrl] = useState('');

  useEffect(() => {
    if (id !== undefined && salesAgentID !== -1) {
      const params = getParamsFromLink();
      setApplyUrl(`/listings/${id}/apply${params['sales-agent'] ? `?sales-agent=${params['sales-agent']}` : ''}`);
    }
  }, [salesAgentID, id, signedIn]);

  if (!(
    salesAgentID !== -1
    && listing
    && residential
    && status !== 'Rented'
    && acceptsApply
    && network !== 'subway'
  )) {
    return null;
  }

  return (
    (signedIn ? (
      <a
        target="_blank"
        rel="noreferrer"
        className="button btn-transparent btn-rounded btn-search-bar-size text-left d-flex"
        href={applyUrl}
        style={{ justifyContent: 'space-between' }}
      >
        <span className="d-flex">Apply</span>
        <img
          alt="apply"
          src={applyImg}
          className="d-flex ml-2"
          style={{ height: '16px' }}
        />
      </a>
    ) : (
      <AuthModal redirect={applyUrl}>
        <a
          className="button btn-transparent btn-rounded btn-search-bar-size text-left d-flex"
          href={applyUrl}
          style={{ justifyContent: 'space-between' }}
        >
          <span className="d-flex">Apply</span>
          <img
            alt="apply"
            src={applyImg}
            className="d-flex ml-2"
            style={{ height: '16px' }}
          />
        </a>
      </AuthModal>
    )));
};

Apply.defaultProps = {
  salesAgentID: -1,
};

Apply.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    status: PropTypes.string,
    acceptsApply: PropTypes.bool,
    network: PropTypes.string,
    residential: PropTypes.bool,
  }).isRequired,
  salesAgentID: PropTypes.number,
  signedIn: PropTypes.bool.isRequired,
};

export default Apply;
