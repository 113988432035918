import React, { Component } from 'react';
import AuthModal from 'components/modal/AuthModal';
import LikeAsync from 'asyncHandlers/LikeAsync';
import { AsyncSubscribe } from 'asyncHandlers/AsyncBase';

class LikeButton extends Component {

  state = {

  }

  shouldComponentUpdate(nextProps) {
    const { listing } = nextProps;
    if (listing !== this.props.listing) {
      const { liked, heartsCount } = listing || {};
      this.setState({
        liked,
        heartsCount
      });
      return false;
    }
    return true;
  }

  asyncHandler = new LikeAsync(this, '/listings', 'listing')

  render() {
    const { listing, signedIn } = this.props;
    const { status } = listing || {}
    const { heartsCount, liked } = this.state;
    const { asyncHandler } = this;
    return(
      listing && status !== "Rented" &&
          <div className="listing-like">
            <div className="button btn-transparent btn-block btn-rounded btn-search-bar-size" id="listing_card_6727">
              {
                signedIn
                ? <a
                    onClick={asyncHandler.toggleLike}
                    href="#"
                    className={
                      `${liked ? 'like-button' : 'unlike-button'} btn-transparent`
                    }
                  >
                    <div
                      style={{justifyContent: 'center'}}
                      className="flex-row"
                    >
                      <div
                        style={{padding: 2}}
                        className="flex-col"
                      >
                      {
                        liked
                        ? <i className="nookons-heart-fill nooklyn_square_heart"></i>
                        : <i className="nookons-heart nooklyn_square_heart flex-col"></i>
                      }
                      </div>
                      <div className="flex-col">
                        {heartsCount} Likes
                      </div>
              </div>
            </a>
            : <AuthModal>
                <a
                  href="#"
                  className='unlike-button btn-transparent'
                >
                  <div
                    style={{justifyContent: 'center'}}
                    className="flex-row"
                  >
                    <div
                      style={{padding: 2}}
                      className="flex-col"
                    >
                      <i className="nookons-heart nooklyn_square_heart"></i>
                    </div>
                    <div className="flex-col">
                      {heartsCount} Likes
                    </div>
                  </div>
                </a>
              </AuthModal>
            }
          </div>
        </div> || null
    );
  }
}

const subscriptions = [
  'listing', 'signedIn',
];

export default AsyncSubscribe(subscriptions)(LikeButton);
