import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal/Modal';
import CropperModalContent from './CropperModalContent';

const CropperModal = (props) => {
  const { imageURL, stopCropping } = props;

  return (
    <Modal
      className="Modal__Bootstrap modal-dialog dark-gray-container agreement-modal cropper-modal-wrapper"
      closeTimeoutMS={150}
      isOpen={imageURL}
      onRequestClose={stopCropping}
    >
      <CropperModalContent {...props} />
    </Modal>
  );
};

CropperModal.propTypes = {
  imageURL: PropTypes.string.isRequired,
  stopCropping: PropTypes.func.isRequired,
};

export default CropperModal;
