import React from 'react';
import useAsyncReducer from 'pro/util/useAsyncReducer';
import reducer, { getState, initialState } from './reducers';
import context from './context';

const Provider = (props) => {
  const { apiToken, children } = props;

  const [state, dispatch] = useAsyncReducer(reducer, initialState, apiToken, getState);

  const value = {
    state,
    dispatch,
    apiToken,
  };

  return (
    <context.Provider value={value}>
      {children}
    </context.Provider>

  );
};

export default Provider;
