import React from 'react';
import PropTypes from 'prop-types';
import { useSetState } from 'components/listRoom/customEffects';
import axios from 'axios';
import LoadingSpinner from 'components/LoadingSpinner';
import CropperModal from 'components/modal/CropperModal';
import { Row, Column } from '../../../wrappers/FlexTable';
import { truncate, importAll } from '../../../helpers/util';
import ContainerWrapper from '../ContainerWrapper';

const subwayImages = importAll(require.context('images/subway/2x/', true, /\.png/));

const RoomCard = (props) => {
  const {
    room,
    listing,
    enableCropping,
    setGlobalState,
  } = props;
  const [state, setState] = useSetState({
    cropRequestPending: false,
    croppingPhoto: null,
  });
  const {
    cropRequestPending,
    croppingPhoto,
  } = state;

  function startCropping(_croppingPhoto) {
    setState({
      croppingPhoto: _croppingPhoto,
    });
  }

  function stopCropping() {
    setState({
      croppingPhoto: null,
    });
  }

  function onCrop(url) {
    const {
      croppingPhoto: {
        id,
      },
    } = state;
    if (!cropRequestPending) {
      setState({
        cropRequestPending: true,
      });
      axios.post(
        '/api/v2/rooms.images.crop',
        {
          image_fingerprint: id,
          url,
        },
        {
          headers: { API_TOKEN: localStorage.getItem('apiToken') },
        },
      ).then((res) => {
        setState({
          croppingPhoto: null,
          cropRequestPending: false,
        });

        setGlobalState((globalState) => {
          const { rooms } = globalState;
          room.image = res.data.result;
          const roomIndex = rooms.findIndex(_room => _room.id === room.id);
          rooms[roomIndex] = { ...room };
          return {
            rooms: [...rooms],
          };
        });
      }).catch(() => {
        setState({
          cropRequestPending: false,
        });
      });
    }
  }

  return (
    <ContainerWrapper>
      <div className="nooklyn_listing_square nklyn-listing d-block" id={`rooms_card_${room.id}`}>
        <div className="listing-card-img">
          <img className="img-fluid mx-auto" src={room.image.wide} alt="room" />
        </div>
        <Row style={{ flexWrap: 'wrap' }} className="nooklyn_room_square_price">
          <Column>
            <div>{window.accounting.formatMoney(room.price / 100.0, { precision: 0 })}</div>
          </Column>
          <Column className="nooklyn_listing_square_subway">
            <Row style={{ flexWrap: 'wrap' }}>
              {listing.subwayLines
                && listing.subwayLines.map(train => (
                  <img
                    className="nooklyn_listing_square_subway-img"
                    key={`${train}-img`}
                    src={subwayImages[`./${train.toUpperCase()}.png`]}
                    alt="subway"
                  />
                ))}
            </Row>
          </Column>
        </Row>
        <div className="nooklyn_listing_tag">
          <div className="nooklyn_listing_square_fee mr-1">No Fee</div>
          <div
            style={{ backgroundColor: 'rgb(220,73,55)' }}
            className="nooklyn_listing_square_room"
          >
            Room
          </div>
        </div>
        <div className="listing-card-stub container-fluid">
          <div style={{ padding: '0px' }} className="row top-row">
            <div style={{ padding: '7px' }} className="col-4 text-center">
              Private Room
            </div>
            <div style={{ padding: '7px' }} className="col-8 text-center">
              {listing.neighborhood && truncate(listing.neighborhood.name, 23)}
            </div>
          </div>
        </div>
        <div className="listing-card-stub container-fluid">
          <div style={{ padding: '0px' }} className="row">
            <div style={{ padding: '7px' }} className="col-4 text-center">
              <i className="nookons-heart" id="nklyn-listing-like-img" />
              Like
            </div>
            <div style={{ padding: '7px' }} className="col-4 text-center">
              <i className="nookons-logout" id="nklyn-listing-tour-img" />
              Tour
            </div>
            <div
              style={{
                padding: '7px',
                position: 'relative',
                cursor: 'pointer',
                zIndex: 10,
              }}
              className="col-4 text-center"
            >
              Share
            </div>
          </div>
        </div>
        {croppingPhoto && (
          <CropperModal
            imageURL={croppingPhoto.uncropped}
            imageFileType={croppingPhoto.image_content_type}
            onCrop={onCrop}
            stopCropping={stopCropping}
            buttonContent={
              cropRequestPending
                ? <LoadingSpinner />
                : 'Done Cropping'
            }
          />
        )}
      </div>
    </ContainerWrapper>
  );
};

RoomCard.defaultProps = {
  enableCropping: true,
  setGlobalState: null,
};

RoomCard.propTypes = {
  room: PropTypes.shape({
    image: PropTypes.shape({
      image_content_type: PropTypes.oneOf([
        'image/png',
        'image/jpeg',
      ]),
      uncropped: PropTypes.string,
    }),
    id: PropTypes.number,
    price: PropTypes.number,
  }).isRequired,
  listing: PropTypes.shape({
    neighborhood: PropTypes.shape({
      name: PropTypes.string,
    }),
    subwayLines: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  enableCropping: PropTypes.bool,
  setGlobalState: PropTypes.func,
};

export default RoomCard;
