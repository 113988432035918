import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Column, Row } from 'wrappers/FlexTable';
import { updateSelectedAgents } from 'reducers/agentTypeahead';

class SingleAgent extends PureComponent {
  onClick = () => {
    const {
      _updateSelectedAgents,
      id,
    } = this.props;
    _updateSelectedAgents(id);
  }

  render() {
    const {
      selected,
      firstName,
      lastName,
      thumbUrl,
      noCheck,
    } = this.props;
    const opacity = selected && !noCheck ? 1 : 0;
    return (
      <div
        aria-selected={selected}
        role="option"
        tabIndex="0"
        ref={(me) => { this.me = me; }}
        onClick={this.onClick}
        style={{
          cursor: 'pointer',
          height: '65px',
          position: 'relative',
          borderTop: '1px solid #404040',
        }}
      >
        <Row className="single-agent">
          <div
            style={{
              opacity,
              position: 'absolute',
              right: '0px',
              width: '0px',
              height: '0px',
              borderTop: '50px solid #ffc03a',
              borderLeft: '50px solid transparent',
            }}
          >
            <i
              className="nookons-check nklyn-2x"
              style={{
                right: '20px',
                bottom: '45px',
                position: 'relative',
                color: '#000',
                zIndex: '1000',
              }}
            />
          </div>
          <Column
            style={{
              width: '260px',
              height: '100%',
              color: '#fff',
              fontWeight: '500',
              fontSize: '12px',
              paddingLeft: '24px',
              alignSelf: 'center',
            }}
          >
            { firstName }
            &nbsp;
            { lastName }
          </Column>
          <Column style={{ width: '65px', height: '100%', alignItems: 'flex-end' }}>
            <img alt={`${firstName} ${lastName}`} style={{ width: '65px', height: '65px' }} src={thumbUrl} />
          </Column>
        </Row>
      </div>
    );
  }
}

SingleAgent.propTypes = {
  _updateSelectedAgents: PropTypes.func,
  selected: PropTypes.bool,
  id: PropTypes.number,
  thumbUrl: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

SingleAgent.defaultProps = {
  _updateSelectedAgents: () => {},
  selected: false,
  id: 0,
  thumbUrl: '',
};

const mapStateToProps = (state, ownProps) => {
  const { agentType } = ownProps;
  const selected = state.agentTypeahead[agentType].selectedAgents
    .some(agent => agent.id === ownProps.id);
  return {
    selected,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  _updateSelectedAgents: agent => dispatch(updateSelectedAgents(agent, ownProps.agentType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleAgent);
