import 'intl'
import 'intl/locale-data/jsonp/en';

export const truncate = (text, length=30, separator=null, omission='...') => {
  let content = '';
  if (text) {
    content = text.substring(0, length);
    if (separator && content.lastIndexOf(separator)) {
      content = content.substring(0, content.lastIndexOf(separator));
    }
  }

  return content += omission;
};

export const numberToCurrency = (number, accuracy=0) => new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: accuracy,
    }).format(number);

export const numberToPhone = (number) => {
      if (typeof number === 'number') {
        number = number.toString();
      }

      let country = null;
      if (number.length === 12) {
        number = number.split('');
        country = number[1];
        number = number.slice(2, number.length).join('');
      }

      number = number.replace(/[^0-9]/g, '');
      number = number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      if (country) {
        return number;
      }

      return number;
    };

export const numberToPercent = (number, accuracy=0) =>  new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: accuracy,
    }
  ).format(number);

export const generateAgentSlug = (firstName, lastName, id) =>
  { let formattedFirstname = firstName.toLowerCase().trim().replace(/[^a-z0-9\s]/i, '').replace(/\s/i, '-')
    let formattedLastname = lastName.toLowerCase().trim().replace(/[^a-z0-9\s]/i, '').replace(/\s/i, '-')
    let formattedAgentId = id
    return `${formattedAgentId}-${formattedFirstname}-${formattedLastname}`
  }
