import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setStore, searchThisArea } from 'reducers/search';
import { Column } from 'wrappers/FlexTable';
import LoadingSpinner from 'components/LoadingSpinner';
import SearchThisArea from './SearchThisArea';

const ListingSearchThisArea = props => {
  const {
    _setStore, _searchThisArea, searchThisAreaChecked, loading,
  } = props;

  function onSearchThisArea() {
    _setStore({ searchThisArea: !searchThisAreaChecked });
  }

  useEffect(() => {
    if (searchThisAreaChecked) {
      _searchThisArea();
    }
  }, [searchThisAreaChecked]);

  return (
    loading && searchThisAreaChecked ? (
      <Column
        className="disable-select search-this-area"
      >
        <LoadingSpinner />
      </Column>
    ) : (
      <SearchThisArea
        {...props}
        onMouseDown={onSearchThisArea}
        checked={searchThisAreaChecked}
      />
    )
  );
};

ListingSearchThisArea.propTypes = {
  _setStore: PropTypes.func.isRequired,
  _searchThisArea: PropTypes.func.isRequired,
  searchThisAreaChecked: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const myState = state.search[state.search.context];
  return {
    searchThisAreaChecked: !!(myState && myState.searchThisArea),
    loading: !!(myState && myState.loading),
  };
};

const mapDispatchToProps = dispatch => ({
  _setStore: payload => dispatch(setStore(payload)),
  _searchThisArea: () => dispatch(searchThisArea()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingSearchThisArea);
